/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetCountryListInterface {}

export const useGetCountryList = () => {
  const mutate = useMutation(({}: useGetCountryListInterface) => {
    return get(`/Country`)
  })

  return mutate
}

export default useGetCountryList
