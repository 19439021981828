import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditClientContactProps {
  clientId?: any
  contactDetails?: any
  contactId?: any
}

export const useEditClientContact = () => {
  const mutate = useMutation(
    ({ contactDetails, clientId, contactId }: EditClientContactProps) => {
      return putAPI(`/facade/Client/${clientId}/Contact/${contactId}`, {
        workLocationId: contactDetails?.workLocationId,
        consultantId: contactDetails?.consultantId,
        firstName: contactDetails?.firstName,
        lastName: contactDetails?.lastName,
        phoneNo: contactDetails?.phoneNo,
        email: contactDetails?.email,
        position: contactDetails?.position,
      })
    },
  )

  return mutate
}

export default useEditClientContact
