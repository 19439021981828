import React, { FC, useState } from 'react'
import {
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import { useAddClient } from 'framework/api/methods'
import { accountSizes } from 'static-data/clients/accountSizes'

import { PageLoader } from 'components/atoms'

export interface AddNewClientProps {
  [x: string]: any
}

export const AddNewClient: FC<AddNewClientProps> = ({
  getTableData,
  isActive,
  notify,
  setIsOpenAddNewClientDrawer,
}: AddNewClientProps) => {
  const [selectedAccountSize, setSelectedAccountSize] = useState<any>()
  const [clientDetails, setClientDetails] = useState({
    name: '',
    website: '',
    abn: '',
    industry: '',
    source: '',
    size: '',
    about: '',
    isActive: true,
  })

  const [isErrorField, setIsErrorField] = useState({
    name: false,
    size: false,
  })

  /** APi call for the add clients */
  const { mutate: addClientMutate, isLoading: addClientIsLoading } =
    useAddClient()

  /** Process the add new client */
  const addClient = () => {
    addClientMutate(
      {
        clientDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
          getTableData()

          resetInputFields()

          setIsOpenAddNewClientDrawer(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation for name and account size input fields */
  const validateInputs = () => {
    const { name, size } = clientDetails

    setIsErrorField({
      ...isErrorField,
      name: name ? false : true,
      size: size ? false : true,
    })

    const isAllValidFields = name && size

    return isAllValidFields
  }

  /** handle add new client button */
  const onAddNewClient = () => {
    const valid = validateInputs()

    if (valid) {
      addClient()
    } else {
      if (isErrorField.name) {
        notify({
          alertHeader: 'Error...!',
          alertBody: 'You cannot leave name fields empty.',
          status: 'Error',
        })
      } else if (isErrorField.size) {
        notify({
          alertHeader: 'Error...!',
          alertBody: 'You cannot leave account size fields empty.',
          status: 'Error',
        })
      } else {
        notify({
          alertHeader: 'Error...!',
          alertBody: 'You cannot leave name or account size fields empty.',
          status: 'Error',
        })
      }
    }
  }

  /** reset input fields */
  const resetInputFields = () => {
    setClientDetails({
      ...clientDetails,
      name: '',
      website: '',
      abn: '',
      industry: '',
      source: '',
      size: '',
      about: '',
      isActive: true,
    })
    setSelectedAccountSize('')
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Add New Client'
      headerSubtitle='Add new client here'
      primaryButtonTitle='Add Client'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={false}
      onFooterAddButtonClick={() => {
        onAddNewClient()
      }}
      onHeaderCloseButtonClick={() => {
        resetInputFields()
        setIsOpenAddNewClientDrawer(false)
      }}
      onFooterCancelButtonClick={() => {
        resetInputFields()
        setIsOpenAddNewClientDrawer(false)
      }}>
      {addClientIsLoading && <PageLoader size='xxs' />}

      <div className='flex flex-col w-full px-6 pt-5'>
        <TextField
          label='Client Name'
          value={clientDetails.name}
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              name: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.name}
        />

        <TextField
          label='Website'
          className='mt-5'
          value={clientDetails.website}
          helperText='Ex: www.website.com'
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              website: e.target.value,
            })
          }}
          placeholder=''
        />

        <div className='flex w-full mt-5'>
          <TextField
            label='ABN'
            className='w-1/2'
            value={clientDetails.abn}
            onChange={(e: any) => {
              const re = /^[0-9\b+ ]+$/

              // if value is not blank, then test the regex
              if (e.target.value === '' || re.test(e.target.value)) {
                setClientDetails({
                  ...clientDetails,
                  abn: e.target.value,
                })
              }
            }}
            placeholder=''
          />

          <TextField
            label='Industry'
            className='w-1/2 ml-5'
            value={clientDetails.industry}
            onChange={(e: any) => {
              setClientDetails({
                ...clientDetails,
                industry: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>

        <div className='flex w-full mt-5'>
          <TextField
            label='Source'
            className='w-1/2'
            value={clientDetails.source}
            onChange={(e: any) => {
              setClientDetails({
                ...clientDetails,
                source: e.target.value,
              })
            }}
            placeholder=''
          />

          <SimpleSelect
            label='Account Size'
            value={selectedAccountSize}
            className='w-1/2 ml-5'
            onChange={(selectedItem: any) => {
              setSelectedAccountSize(selectedItem)
              setClientDetails({
                ...clientDetails,
                size: selectedItem.value,
              })
            }}
            options={accountSizes}
            isErrored={isErrorField.size}
          />
        </div>

        <TextArea
          label='About'
          className='mt-5'
          textAreaClassName='h-[114px]'
          value={clientDetails.about}
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              about: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* backend issue (variable name) : use isActive value for active disable status based on true false. and don't use status variable which
        is related to something else. refused to change by backend.  */}
        <Toggle
          label={clientDetails.isActive ? 'Active' : 'Disable'}
          className='mt-5'
          setValue={clientDetails.isActive}
          onToggleClick={(e: any) => {
            setClientDetails({
              ...clientDetails,
              isActive: e.target.checked,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

AddNewClient.defaultProps = {}

export default AddNewClient
