import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getWaitingForDocumentList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/DocumentAudit/WaitingDocument?Take=${reqBody.take}&Skip=${reqBody.skip}&Category=${reqBody.documentCategory}&Search=${reqBody.search}&Type=${reqBody.documentType}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetWaitingForDocumentList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['WaitingDocument', reqBody], getWaitingForDocumentList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
