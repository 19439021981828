import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deleteUserPermissionsProps {
  Id?: any
  permission?: any
}

export const useDeleteUserPermissions = () => {
  const mutate = useMutation(
    ({ Id, permission }: deleteUserPermissionsProps) => {
      return deleteAPI(
        `/identity/user/${Id}/permission?Permission=${permission}`,
        {},
      )
    },
  )

  return mutate
}

export default useDeleteUserPermissions
