import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface createWebRefEmailInterface {
  candidateId?: string
  customMessage?: string
  refereeIds?: string[]
  templateId?: string
  isStaffRequest?: boolean
  isCareerInterest?: boolean
}

export const useCreateWebRefEmail = () => {
  const mutate = useMutation(
    ({
      candidateId,
      customMessage,
      refereeIds,
      templateId,
      isStaffRequest,
      isCareerInterest,
    }: createWebRefEmailInterface) => {
      return postAPI(
        `/facade/candidate/${candidateId}/referee/sendWebRefEmail`,
        {
          refereeIds,
          templateId,
          customMessage,
          isStaffRequest,
          isCareerInterest,
        },
      )
    },
  )

  return mutate
}

export default useCreateWebRefEmail
