import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import CN from 'classnames'

export interface DocumentStatusIconProps {
  status: string
  auditRequired: boolean
  [x: string]: any
}

export const DocumentStatusIcon: FC<DocumentStatusIconProps> = ({
  status,
  auditRequired,
}: DocumentStatusIconProps) => {
  const getTooltipText = (status: string, auditRequired: boolean): string => {
    let tooltipText = ''

    switch (status) {
      case 'Pending_Audit':
        tooltipText = 'Audit Pending'
        break
      case 'Request_Submission':
        tooltipText = 'Waiting for Documents'
        break
      case 'Audit_Failed':
        tooltipText = 'Audit Failed'
        break
      case 'Expired':
        tooltipText = 'Expired'
        break
      case 'Archived':
        tooltipText = 'Archived'
        break
      case 'Active':
        if (auditRequired) {
          tooltipText = 'Audit Passed'
        } else {
          tooltipText = 'Active'
        }
        break
    }

    return tooltipText
  }

  return (
    <>
      <div
        className={CN(
          'flex justify-center items-center w-6 h-6 rounded-xl p-[6px] ml-2',
          {
            'bg-Yellow-50': status === 'Pending_Audit',
            'bg-Cobalt-50':
              status === 'Request_Submission' || status === 'Archived',
            'bg-Red-50': status === 'Audit_Failed',
            'bg-TerraCotta-50': status === 'Expired',
            'bg-Green-50': status === 'Active' && auditRequired,
            'bg-Gray-50': status === 'Active' && !auditRequired,
          },
        )}>
        <i
          className={CN('text-small', {
            'ri-indeterminate-circle-fill text-Yellow-600':
              status === 'Pending_Audit',
            'ri-time-fill text-Cobalt-500': status === 'Request_Submission',
            'ri-close-circle-fill text-TerraCotta-600':
              status === 'Audit_Failed',
            'ri-error-warning-fill text-TerraCotta-500': status === 'Expired',
            'ri-archive-line text-Cobalt-400': status === 'Archived',
            'ri-checkbox-circle-fill text-Green-600':
              status === 'Active' && auditRequired,
            'ri-flashlight-fill text-Gray-500':
              status === 'Active' && !auditRequired,
          })}
          data-tip={getTooltipText(status, auditRequired)}
          data-for={getTooltipText(status, auditRequired)}
        />
      </div>

      <ReactTooltip
        place='right'
        id={getTooltipText(status, auditRequired)}
        effect='solid'
        textColor='#FFFFFF'
        backgroundColor='#4B5563'
      />
    </>
  )
}

DocumentStatusIcon.defaultProps = {}

export default DocumentStatusIcon
