import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteClientContactAvatarInterface {
  Id?: string
}

export const useDeleteClientContactAvatar = () => {
  const mutate = useMutation(({ Id }: DeleteClientContactAvatarInterface) => {
    return deleteAPI(`/Contact/${Id}/Avatar`)
  })

  return mutate
}

export default useDeleteClientContactAvatar
