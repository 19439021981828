/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetSuburbListInterface {}

export const useGetSuburbList = () => {
  const mutate = useMutation(({}: useGetSuburbListInterface) => {
    return get(`/Suburb`, {})
  })

  return mutate
}

export default useGetSuburbList
