import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getBankAccountData = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`Candidate/${reqBody.candidateId}/BankAccount`)
  return res.data
}

export const useGetBankAccountData = ({ ...reqBody }) => {
  return useQuery(['BankAccount', reqBody], getBankAccountData, {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
      const errorCode = error.response?.status
      if (errorCode === 401 || errorCode === 404) {
        return false
      }

      return failureCount < 3
    },
  })
}
