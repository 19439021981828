/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
  Toast,
  Toggle,
} from '@labourhub/labour-hub-ds'
import {
  useAddBank,
  useAddSuperName,
  useAddSuperProvider,
  useEditFinancialInformation,
  useGetBank,
  useGetCountryList,
  useGetFinancialInformationByCandidateId,
  useGetSuperName,
  useGetSuperProvider,
} from 'framework/api/methods'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import { CreatableInputSelect, PageLoader } from 'components/atoms'
import { isLetters } from 'utils'

export interface EditSuperFundDocsProps {
  isActive?: boolean
  [x: string]: any
}

export const EditFinancialInformation: FC<EditSuperFundDocsProps> = ({
  fetchDocumentList,
  isActive,
  ...restProps
}: EditSuperFundDocsProps) => {
  const dispatch = useDispatch()

  /** getting candidateId from redux store */
  const { selectedCandidate } = useSelector((state: any) => state.audit)

  /** super provider list state */
  const [superProviderList, setSuperProviderList] = useState<any[]>([])

  /**country list state */
  const [countryList, setCountryList] = useState<any[]>([])

  /**bank list state */
  const [bankList, setBankList] = useState<any[]>([])

  /** super name list  */
  const [superNameList, setSuperNameList] = useState<any[]>([])

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [isErrorField, setIsErrorField] = useState<any>({
    accountNumber: false,
    bsbNumber: false,
    accountName: false,
    bankName: false,
    membershipNumber: false,
    abn: false,
    usi: false,
  })

  const { mutate: getCountryListMutate, isLoading: getCountryListIsLoading } =
    useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
  }, [])

  /** API Handling request for getting candidate financial information*/
  const { data: candidateFinancialInformation } =
    useGetFinancialInformationByCandidateId(selectedCandidate?.id)

  /** updated financial details state */
  const [updatedFinancialDetails, setUpdatedFinancialDetails] = useState<any>({
    candidateId: '',
    bankAccountId: '',
    accountName: '',
    bankName: '',
    bankId: '',
    bsbNumber: '',
    accountNumber: '',
    hasSuper: null,
    superProviderName: '',
    superName: '',
    superFundId: '',
    superProviderId: '',
    superNameId: '',
    membershipNumber: '',
    abn: '',
    usi: '',
    country: '',
    countryCode: '',
  })

  useEffect(() => {
    const {
      accountName,
      bankName,
      bsbNumber,
      accountNumber,
      hasSuper,
      superProviderName,
      superName,
      superProviderId,
      superNameId,
      membershipNumber,
      abn,
      usi,
      country,
      countryCode,
    } = candidateFinancialInformation?.financialDetailsModel || {}

    setUpdatedFinancialDetails({
      ...updatedFinancialDetails,
      candidateId: selectedCandidate?.id ? selectedCandidate?.id : '',
      bankAccountId: candidateFinancialInformation?.financialDetailsModel
        ?.bankAccountId
        ? candidateFinancialInformation?.financialDetailsModel?.bankAccountId
        : '',
      accountName: accountName ? accountName : '',
      bankName: bankName ? bankName : '',
      bankId: candidateFinancialInformation?.financialDetailsModel?.bankId
        ? candidateFinancialInformation?.financialDetailsModel?.bankId
        : '',
      bsbNumber: bsbNumber ? bsbNumber : '',
      accountNumber: accountNumber ? accountNumber : '',
      hasSuper: hasSuper ? hasSuper : false,
      superProviderName: superProviderName ? superProviderName : '',
      superName: superName ? superName : '',
      superFundId: candidateFinancialInformation?.financialDetailsModel
        ?.superFundId
        ? candidateFinancialInformation?.financialDetailsModel?.superFundId
        : '',
      superProviderId: superProviderId ? superProviderId : '',
      superNameId: superNameId ? superNameId : '',
      membershipNumber: membershipNumber ? membershipNumber : '',
      abn: abn ? abn : '',
      usi: usi ? usi : '',
      country: country ? country : '',
      countryCode: countryCode ? countryCode : '',
    })
  }, [candidateFinancialInformation?.financialDetailsModel])

  /** front end validation for input fields */
  const validateInputs = () => {
    const {
      accountNumber,
      bsbNumber,
      accountName,
      bankName,
      membershipNumber,
      abn,
      usi,
      hasSuper,
    } = updatedFinancialDetails

    if (hasSuper) {
      setIsErrorField({
        ...isErrorField,
        accountNumber: accountNumber ? false : true,
        bsbNumber: bsbNumber ? false : true,
        accountName: isLetters(accountName) ? false : true,
        bankName: isLetters(bankName) ? false : true,
        membershipNumber: membershipNumber ? false : true,
        abn: abn ? false : true,
        usi: usi ? false : true,
      })

      const isAllValidFields =
        accountNumber &&
        bsbNumber &&
        isLetters(accountName) &&
        isLetters(bankName) &&
        membershipNumber &&
        abn &&
        usi
      return isAllValidFields
    } else {
      setIsErrorField({
        ...isErrorField,
        accountNumber: accountNumber ? false : true,
        bsbNumber: bsbNumber ? false : true,
        accountName: isLetters(accountName) ? false : true,
        bankName: isLetters(bankName) ? false : true,
      })

      const isAllValidFields =
        accountNumber &&
        bsbNumber &&
        isLetters(accountName) &&
        isLetters(bankName)
      return isAllValidFields
    }
  }

  /** handle edit candidate finance information button */
  const onEditFinanceInformation = () => {
    const valid = validateInputs()

    if (valid) {
      editFinanceInformation()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** super provider list on success */
  const onGetSuperProviderListSuccess = (data: any) => {
    const tempSuperProviderList = data?.superProviders?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setSuperProviderList(tempSuperProviderList)
  }

  /**on error */
  const onError = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Super Provider List*/
  const {
    refetch: getSuperProviderList,
    isLoading: getSuperProviderIsLoading,
    isFetching: getSuperProviderIsFetching,
  } = useGetSuperProvider(onGetSuperProviderListSuccess, onError)

  /** super name list on success */
  const onGetSuperNameListSuccess = (data: any) => {
    const tempSuperNameList = data?.superNames?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setSuperNameList(tempSuperNameList)
  }

  /** API Handling request for Super Name List */
  const {
    refetch: getSuperNameList,
    isLoading: getSuperNameListIsLoading,
    isFetching: getSuperNameListIsFetching,
  } = useGetSuperName(
    {
      superProviderId: updatedFinancialDetails.superProviderId,
    },
    onGetSuperNameListSuccess,
    onError,
  )

  const {
    mutate: addSuperProviderMutate,
    isLoading: addSuperProviderIsLoading,
  } = useAddSuperProvider()

  /** add super provider */
  async function addSuperProvider(name: string) {
    addSuperProviderMutate(
      {
        superProviderData: {
          name: name,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setUpdatedFinancialDetails({
            ...updatedFinancialDetails,
            superProviderId: successData.superProvider.id,
          })
          getSuperProviderList()
          Toast({
            alertBody: 'Super Provider added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding super provider!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle super provider change */
  const handleSuperProviderChange = (
    data: any,
    actionMeta: ActionMeta<any>,
  ) => {
    if (actionMeta.action == 'create-option') {
      addSuperProvider(data.label)
    } else {
      setUpdatedFinancialDetails({
        ...updatedFinancialDetails,
        superProviderId: data.value,
        superNameId: null,
      })
    }
  }

  const { mutate: addSuperNameMutate, isLoading: addSuperNameIsLoading } =
    useAddSuperName()

  /** add super name */
  async function addSuperName(name: string) {
    addSuperNameMutate(
      {
        superNameData: {
          superProviderId: updatedFinancialDetails?.superProviderId,
          name: name,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          getSuperNameList()
          setUpdatedFinancialDetails({
            ...updatedFinancialDetails,
            superNameId: successData.superName.id,
          })
          Toast({
            alertBody: 'Super Name added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding super name!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle super name change */
  const handleSuperNameChange = (data: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuperName(data.label)
    } else {
      setUpdatedFinancialDetails({
        ...updatedFinancialDetails,
        superNameId: data.value,
      })
    }
  }

  /** APi call for the edit candidate finance information */
  const {
    mutate: editFinanceInformationMutate,
    isLoading: editFinanceInformationIsLoading,
  } = useEditFinancialInformation()

  /** Process the edit candidate finance information*/
  const editFinanceInformation = () => {
    editFinanceInformationMutate(
      {
        updatedFinancialDetails,
        candidateId: selectedCandidate?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          fetchDocumentList()
          dispatch(setEditDrawer(null))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle primary button disable */
  useEffect(() => {
    const {
      accountNumber,
      bsbNumber,
      accountName,
      bankName,
      membershipNumber,
      abn,
      usi,
      hasSuper,
      superNameId,
    } = updatedFinancialDetails || {}

    if (hasSuper) {
      accountNumber &&
      bsbNumber &&
      accountName &&
      bankName &&
      /** Super Details */
      superNameId &&
      membershipNumber &&
      abn &&
      usi
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      accountNumber && bsbNumber && accountName && bankName
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [updatedFinancialDetails])

  useEffect(() => {
    if (updatedFinancialDetails?.superProviderId) {
      getSuperNameList()
    }
  }, [updatedFinancialDetails?.superProviderId])

  const { mutate: addBankMutate } = useAddBank()

  /** add bank */
  async function addBank(name: string) {
    addBankMutate(
      {
        bankData: {
          name: name,
          countryCode: updatedFinancialDetails.countryCode,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          getBankList()
          setUpdatedFinancialDetails({
            ...updatedFinancialDetails,
            bankId: successData.bankModel.id,
            bankName: successData.bankModel.name,
          })
          Toast({
            alertBody: 'Bank added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding bank!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle bank change */
  const handleBankChange = (data: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addBank(data.label)
    } else {
      setUpdatedFinancialDetails({
        ...updatedFinancialDetails,
        bankId: data.value,
        bankName: data.label,
      })
    }
  }

  /** bank list on success */
  const onGetBankListSuccess = (data: any) => {
    const tempBankList = data?.banks?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setBankList(tempBankList)
  }

  /**on error */
  const onErrorBankList = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get Bank List */
  const {
    refetch: getBankList,
    isLoading: getBankListIsLoading,
    isFetching: getBankListIsFetching,
  } = useGetBank(
    {
      countryCode: updatedFinancialDetails.countryCode,
    },
    onGetBankListSuccess,
    onErrorBankList,
  )

  useEffect(() => {
    getBankList()
  }, [updatedFinancialDetails.countryCode])

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle='Financials'
      headerSubtitle='You can audit candidate’s personal information'
      primaryButtonTitle='Update Financials'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        dispatch(setEditDrawer(null))
      }}
      onFooterAddButtonClick={() => {
        onEditFinanceInformation()
      }}
      onFooterCancelButtonClick={() => {
        dispatch(setEditDrawer(null))
      }}
      {...restProps}>
      {(editFinanceInformationIsLoading ||
        getSuperProviderIsFetching ||
        getSuperProviderIsLoading ||
        addSuperProviderIsLoading ||
        addSuperNameIsLoading) && <PageLoader size='xxs' />}

      <div className='w-full h-fit py-5 px-6'>
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2'>
          Bank Account Details
        </div>

        {/* account no section */}
        <TextField
          label='Account No'
          className='mt-5'
          value={updatedFinancialDetails?.accountNumber}
          onChange={(e: any) => {
            setUpdatedFinancialDetails({
              ...updatedFinancialDetails,
              accountNumber: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.accountNumber}
        />

        {/* BBS no section */}
        <TextField
          label='BSB No'
          className='mt-5'
          value={updatedFinancialDetails?.bsbNumber}
          onChange={(e: any) => {
            setUpdatedFinancialDetails({
              ...updatedFinancialDetails,
              bsbNumber: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.bsbNumber}
        />

        {/* Account name section */}
        <TextField
          label='Account Name'
          className='mt-5'
          value={updatedFinancialDetails?.accountName}
          onChange={(e: any) => {
            setUpdatedFinancialDetails({
              ...updatedFinancialDetails,
              accountName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.accountName}
        />

        {/* country section */}
        <SimpleSelect
          disabled={getCountryListIsLoading}
          label='Country'
          className='my-5'
          placeholder=''
          value={
            countryList?.filter((item: any) => {
              return item.value === updatedFinancialDetails.countryCode
            })[0]
          }
          options={countryList}
          onChange={(data) => {
            setUpdatedFinancialDetails({
              ...updatedFinancialDetails,
              countryCode: data.value,
              country: data.label,
              bankId: null,
              bankName: null,
            })
          }}
        />

        {/* Bank name section */}
        <CreatableInputSelect
          isDisabled={getBankListIsLoading || getBankListIsFetching}
          label='Bank Name'
          className='my-5'
          placeholder='Select Bank'
          value={
            updatedFinancialDetails.bankId
              ? bankList?.filter((item: any) => {
                  return item.value === updatedFinancialDetails.bankId
                })[0]
              : null
          }
          options={bankList}
          onChange={handleBankChange}
          isErrored={isErrorField?.bankName}
        />

        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2'>
          Super Details
        </div>

        {/* Has super section */}
        <Toggle
          label='Has Super'
          className='pt-5'
          setValue={updatedFinancialDetails?.hasSuper}
          onToggleClick={() => {
            setUpdatedFinancialDetails({
              ...updatedFinancialDetails,
              hasSuper: !updatedFinancialDetails?.hasSuper,
            })
          }}
        />

        {updatedFinancialDetails?.hasSuper && (
          <>
            {/** Super Provider section */}
            <CreatableInputSelect
              isDisabled={
                getSuperProviderIsFetching || getSuperProviderIsLoading
              }
              label='Super Provider'
              className='my-5'
              placeholder=''
              value={
                superProviderList?.find((item: any) => {
                  return (
                    item?.value === updatedFinancialDetails?.superProviderId
                  )
                }) || null
              }
              options={superProviderList}
              onChange={handleSuperProviderChange}
            />

            {/** Super Name section */}
            <CreatableInputSelect
              isDisabled={
                getSuperProviderIsFetching ||
                getSuperProviderIsLoading ||
                getSuperNameListIsLoading ||
                getSuperNameListIsFetching ||
                updatedFinancialDetails.superProviderId === null
              }
              label='Super Name'
              className='mb-5'
              placeholder=''
              value={
                superNameList?.filter((item: any) => {
                  return item?.value === updatedFinancialDetails?.superNameId
                }).length > 0
                  ? superNameList?.filter((item: any) => {
                      return (
                        item?.value === updatedFinancialDetails?.superNameId
                      )
                    })[0]
                  : null
              }
              options={superNameList}
              onChange={handleSuperNameChange}
            />

            {/* Membership no section */}
            <TextField
              label='Membership No'
              className='my-5'
              value={updatedFinancialDetails?.membershipNumber}
              onChange={(e: any) => {
                setUpdatedFinancialDetails({
                  ...updatedFinancialDetails,
                  membershipNumber: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.membershipNumber}
            />

            {/* Super ABN section */}
            <TextField
              label='Super ABN'
              className='my-5'
              value={updatedFinancialDetails?.abn}
              onChange={(e: any) => {
                setUpdatedFinancialDetails({
                  ...updatedFinancialDetails,
                  abn: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.abn}
            />

            {/* Super USI section */}
            <TextField
              label='Super USI'
              className='my-5'
              value={updatedFinancialDetails?.usi}
              onChange={(e: any) => {
                setUpdatedFinancialDetails({
                  ...updatedFinancialDetails,
                  usi: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.usi}
            />
          </>
        )}
      </div>
    </RightSideModal>
  )
}

EditFinancialInformation.defaultProps = {
  isActive: false,
}

export default EditFinancialInformation
