export const breadcrumbData = {
  'candidate-list': {
    text: 'Candidates',
    subText: 'candidate_name',
    hasQueryParam: true,
  },
  'client-list': {
    text: 'Clients',
    subText: 'client_name',
    hasQueryParam: true,
  },
  'user-groups': {
    text: 'User Groups',
    subText: 'user_role',
    hasQueryParam: true,
  },
  'user-list': {
    text: 'Users',
    subText: 'Permissions',
    hasQueryParam: false,
  },
}
