import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditDocumentInterface {
  documentDetails: any
  attachments: any
}

export const useEditDocument = () => {
  const mutate = useMutation(
    ({ documentDetails, attachments }: EditDocumentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', documentDetails?.categoryId)
      bodyFormData.append('TypeId', documentDetails?.typeId)
      bodyFormData.append('Title', documentDetails?.title)

      documentDetails?.expiryDate &&
        bodyFormData.append('ExpiryOn', documentDetails?.expiryDate)
      documentDetails?.dueDate &&
        bodyFormData.append('DueOn', documentDetails?.dueDate)

      bodyFormData.append('Notes', documentDetails?.notes)
      bodyFormData.append('AuditRequired', documentDetails?.auditCheck)

      attachments?.map((item) => {
        const newAttachments = item?.isExistingFile ? null : item

        newAttachments && bodyFormData.append('Attachments', item)
      })

      return putAPIwithFormData(
        `/Document/${documentDetails?.id}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditDocument
