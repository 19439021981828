import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface useGetCandidateDocumentIdsType {
  documentIds?: string[] | null
}

export const useGetCandidateDocumentIds = (candidateId: string) => {
  return useQuery(
    ['GET_CANDIDATE_AUDIT_DOC_IDS'],
    async () => {
      const res = await get(`/facade/Candidate/${candidateId}/PerformAudit`)
      return res.data.documentIds as useGetCandidateDocumentIdsType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document ids',
          status: 'Error',
        })
      },
    },
  )
}
