/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetAuditQueueList } from 'framework/api/methods'
import moment from 'moment'

import { CalendarRangePicker } from 'components/atoms/CalendarRangePicker'
import { TableHeader, UITable } from 'components/common/UITable'

import { AuditQueueListColumn } from './AuditQueueListColumn'

export interface AuditQueueListProps {
  [x: string]: any
}

export const AuditQueueList: FC<AuditQueueListProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  managingConsultantList,
  ...restProps
}: AuditQueueListProps) => {
  const dispatch = useDispatch()

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const { isAuditModal } = useSelector((state: any) => state.audit)
  const [auditQueueTableColumn, setAuditQueueTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState<any>(
    {
      categoryName: '',
      categoryId: '',
    },
  )

  const [selectedDocumentType, setSelectedDocumentType] = useState<any>({
    typeName: '',
    typeId: '',
  })

  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    from: '',
    to: '',
  })

  const [selectedManagingConsultant, setSelectedManagingConsultant] =
    useState<any>({
      name: '',
      id: '',
    })
  const [selectedAuditStatus, setSelectedAuditStatus] = useState<any>()
  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState<any>()

  const [filterVariables, setFilterVariables] = useState<any>({
    documentCategory: '',
    documentType: '',
    auditStatus: '',
    submittedFromDate: '',
    submittedToDate: '',
    managingConsultant: '',
    candidateStatus: '',
  })

  const notify = (props: any) => Toast(props)

  // drop down data of audit status filter
  const auditStatus = [
    {
      value: 'Waiting for documents',
      label: 'Waiting for documents',
    },
    {
      value: 'Audit pending',
      label: 'Audit pending',
    },
    {
      value: 'Audit failed',
      label: 'Audit failed',
    },
  ]

  // drop down data of candidate status filter
  const candidateStatus = [
    {
      value: 'Active',
      label: 'Active',
    },
    {
      value: 'Inactive',
      label: 'Inactive',
    },
  ]

  /** audit queue List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** audit queue List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All audit queue List */
  const {
    refetch: getTableData,
    isLoading: getAuditQueueListIsLoading,
    isFetching: getAuditQueueListIsFetching,
  } = useGetAuditQueueList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      documentCategory: filterVariables?.documentCategory || '',
      search: paginationVariables.search,
      documentType: filterVariables?.documentType || '',
      auditStatus: filterVariables?.auditStatus || '',
      submittedFromDate: filterVariables?.submittedFromDate || '',
      submittedToDate: filterVariables?.submittedToDate || '',
      managingConsultant: filterVariables?.managingConsultant || '',
      candidateStatus: filterVariables?.candidateStatus || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    getTableData()
  }, [paginationVariables])

  /** for refetch table list when close audit check modal */
  useEffect(() => {
    !isAuditModal?.isOpen && getTableData()
  }, [isAuditModal])

  /** audit queue list table column handling */
  useEffect(() => {
    if (tableData?.auditQueues && tableData?.auditQueues?.length > 0) {
      const columns: any = AuditQueueListColumn(dispatch)
      setAuditQueueTableColumn(columns)
    }
  }, [tableData])

  const applyFilter = () => {
    setFilterActive(true)
    getTableData()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      documentCategory: selectedDocumentCategory?.categoryName || '',
      documentType: selectedDocumentType?.typeName || '',
      auditStatus:
        selectedAuditStatus?.value === 'Waiting for documents'
          ? 'Request_Submission'
          : selectedAuditStatus?.value === 'Audit pending'
          ? 'Pending_Audit'
          : selectedAuditStatus?.value === 'Audit failed'
          ? 'Audit_Failed'
          : '',
      managingConsultant: selectedManagingConsultant?.name || '',
      candidateStatus:
        selectedCandidateStatus?.value === 'Active'
          ? 'Active'
          : selectedCandidateStatus?.value === 'Inactive'
          ? 'Deactive'
          : '',
      submittedFromDate: selectedDateRange?.from || '',
      submittedToDate: selectedDateRange?.to || '',
    })
  }

  const resetFilter = () => {
    setSelectedDocumentCategory('')
    setSelectedDocumentType('')
    setSelectedAuditStatus('')
    setSelectedManagingConsultant('')
    setSelectedCandidateStatus('')
    setSelectedDateRange('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setFilterVariables({
      documentCategory: '',
      documentType: '',
      auditStatus: '',
      submittedFromDate: '',
      submittedToDate: '',
      managingConsultant: '',
      candidateStatus: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const AuditQueueListClasses = CN(`audit-queue-list w-full h-full`, className)

  return (
    <div className={AuditQueueListClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={'Search by candidate and email'}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}></TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex items-end px-4 w-full pt-3 pb-4 z-[10]'>
                <SimpleSelect
                  label='By Document Category'
                  placeholder='All'
                  value={
                    selectedDocumentCategory?.categoryName
                      ? {
                          label: selectedDocumentCategory?.categoryName,
                          value: selectedDocumentCategory?.categoryId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentCategory({
                      ...selectedDocumentCategory,
                      categoryId: selectedItem.id,
                      categoryName: selectedItem.name,
                    })
                  }}
                  options={documentCategoryList}
                />

                <SimpleSelect
                  label='By Document Type'
                  placeholder='All'
                  value={
                    selectedDocumentType?.typeName
                      ? {
                          label: selectedDocumentType?.typeName,
                          value: selectedDocumentType?.typeId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentType({
                      ...selectedDocumentType,
                      typeId: selectedItem.id,
                      typeName: selectedItem.name,
                    })
                  }}
                  options={documentTypeList}
                />

                <div className='flex flex-col items-start justify-center'>
                  <span className='text-Gray-800 font-Medium text-small mb-1'>
                    Documents Submitted On
                  </span>
                  <CalendarRangePicker
                    className='mr-5'
                    defaultValue={
                      selectedDateRange?.from
                        ? [
                            new Date(selectedDateRange?.from),
                            new Date(selectedDateRange?.to),
                          ]
                        : undefined
                    }
                    onChange={(selectedRange) => {
                      if (
                        selectedRange[0] !== null &&
                        selectedRange[1] !== null
                      ) {
                        setSelectedDateRange({
                          ...selectedDateRange,
                          from: moment(selectedRange[0])
                            .format('YYYY-MM-DD')
                            .toString(),
                          to: moment(selectedRange[1])
                            .format('YYYY-MM-DD')
                            .toString(),
                        })
                      }
                    }}
                  />
                </div>

                <SimpleSelect
                  label='By Audit Status'
                  placeholder='All'
                  value={selectedAuditStatus}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedAuditStatus(selectedItem)
                  }}
                  options={auditStatus}
                />
              </div>

              <div className='flex justify-start items-end pl-3 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='Managing Consultant'
                  placeholder='All'
                  value={
                    selectedManagingConsultant?.name
                      ? {
                          label: selectedManagingConsultant?.name,
                          value: selectedManagingConsultant?.id,
                        }
                      : null
                  }
                  onChange={(selectedItem: any) => {
                    setSelectedManagingConsultant({
                      ...selectedManagingConsultant,
                      name: selectedItem.label,
                      id: selectedItem.Value,
                    })
                  }}
                  className='w-1/4 mr-5'
                  options={managingConsultantList}
                />

                <SimpleSelect
                  label='Candidates Status'
                  placeholder='All'
                  value={selectedCandidateStatus}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedCandidateStatus(selectedItem)
                  }}
                  options={candidateStatus}
                />

                <Button
                  className='mr-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedDocumentCategory?.categoryName &&
                    !selectedDocumentType?.typeName &&
                    !selectedAuditStatus?.value &&
                    !selectedManagingConsultant?.name &&
                    !selectedCandidateStatus?.value &&
                    !selectedDateRange?.from &&
                    !selectedDateRange?.to
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(64vh-70px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.auditQueues || []}
            isLoading={
              getAuditQueueListIsLoading || getAuditQueueListIsFetching
            }
            className=''
            columns={auditQueueTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}

AuditQueueList.defaultProps = {}

export default AuditQueueList
