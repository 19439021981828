/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddBank,
  useAddBankAccount,
  useEditBankAccount,
  useGetBank,
  useGetCountryList,
} from 'framework/api/methods'

import { CreatableInputSelect, PageLoader } from 'components/atoms'

export interface BankAccountDetailsProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const BankAccountDetails: FC<BankAccountDetailsProps> = ({
  onDrawerClose,
  headerTitle,
  headerSubtitle,
  primaryButtonTitle,
  isActive,
  data,
  isAddMode,
  notify,
  candidateId,
  getBankAccountData,
  ...restProps
}: BankAccountDetailsProps) => {
  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [bankList, setBankList] = useState<any[]>([])
  const [countryList, setCountryList] = useState<any[]>([])
  const [formData, setFormData] = useState({
    name: isAddMode ? null : data.name,
    bankAccountNumber: isAddMode ? null : data.accountNumber,
    countryCode: isAddMode ? 'AU' : data.countryCode,
    bsbNumber: isAddMode ? null : data.bsbNumber,
    bankId: isAddMode ? null : data.bankId,
  })

  const { mutate: getCountryListMutate, isLoading: getCountryListIsLoading } =
    useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  const {
    mutate: updateBankAccountMutate,
    isLoading: updateBankAccountIsLoading,
  } = useEditBankAccount()

  /** Process the get country list */
  async function updateBankAccount() {
    updateBankAccountMutate(
      {
        candidateId: candidateId,
        id: data.id,
        bankAccountData: formData,
      },
      {
        onSuccess: () => {
          getBankAccountData()
          notify({
            alertBody: 'Bank account details updated successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating bank account!',
            status: 'Error',
          })
        },
      },
    )
  }

  const { mutate: addBankMutate, isLoading: addBankIsLoading } = useAddBank()

  /** add bank */
  async function addBank(name: string) {
    addBankMutate(
      {
        bankData: {
          name: name,
          countryCode: formData.countryCode,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          getBankList()
          setFormData({
            ...formData,
            bankId: successData.bankModel.id,
          })
          notify({
            alertBody: 'Bank added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding bank!',
            status: 'Error',
          })
        },
      },
    )
  }

  const { mutate: addBankAccountMutate, isLoading: addBankAccountIsLoading } =
    useAddBankAccount()

  /** add bank account */
  async function addBankAccount() {
    addBankAccountMutate(
      {
        candidateId: candidateId,
        bankAccountData: formData,
      },
      {
        onSuccess: () => {
          getBankAccountData()
          notify({
            alertBody: 'Bank account details added successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating bank account!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** bank list on success */
  const onGetBankListSuccess = (data: any) => {
    const tempBankList = data?.banks?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setBankList(tempBankList)
  }

  /**on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get Bank List */
  const {
    refetch: getBankList,
    isLoading: getBankListIsLoading,
    isFetching: getBankListIsFetching,
  } = useGetBank(
    {
      countryCode: formData.countryCode,
    },
    onGetBankListSuccess,
    onError,
  )

  useEffect(() => {
    getBankList()
  }, [formData.countryCode])

  const validateForm = () => {
    const { name, bankAccountNumber, bsbNumber, bankId } = formData

    let buttonDisabled = false

    if (!name || !bankAccountNumber || !bsbNumber || !bankId) {
      buttonDisabled = true
    }

    /**
     * remove validation as requested by client
     * 
     const tempValidation = { ...validation }

    if (bsbNumber) {
      if (!bsbNumber.match(/^\d{3}-\d{3}$/g)) {
        setIsPrimaryButtonDisabled(true)
        tempValidation.bsbNumber.isErrored = true
        buttonDisabled = true
      } else {
        tempValidation.bsbNumber.isErrored = false
      }
    }

    if (bankAccountNumber) {
      if (!bankAccountNumber.match(/^\d{3}\s\d{4}\s\d{5}$/g)) {
        setIsPrimaryButtonDisabled(true)
        tempValidation.bankAccountNumber.isErrored = true
        buttonDisabled = true
      } else {
        tempValidation.bankAccountNumber.isErrored = false
      }
    }

    setValidation(tempValidation)**/
    setIsPrimaryButtonDisabled(buttonDisabled)
  }

  const resetInputFields = () => {
    setFormData({
      name: null,
      bankAccountNumber: null,
      countryCode: 'AU',
      bsbNumber: null,
      bankId: null,
    })
  }

  useEffect(() => {
    validateForm()
  }, [formData])

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
  }, [])

  /** handle bank change */
  const handleBankChange = (data: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addBank(data.label)
    } else {
      setFormData({
        ...formData,
        bankId: data.value,
      })
    }
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        if (isAddMode) {
          addBankAccount()
        } else {
          updateBankAccount()
        }
      }}
      onFooterCancelButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      {...restProps}>
      {(updateBankAccountIsLoading ||
        getBankListIsFetching ||
        getCountryListIsLoading ||
        addBankAccountIsLoading ||
        addBankIsLoading) && <PageLoader size='xxs' />}
      <div className='px-6 py-5 flex-1'>
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData.name}
          label='Account Name'
          onChange={(e) => {
            setFormData({
              ...formData,
              name: e.target.value,
            })
          }}
        />
        <SimpleSelect
          disabled={getCountryListIsLoading}
          isRequired
          label='Country'
          className='mb-5'
          placeholder=''
          value={
            countryList?.filter((item: any) => {
              return item.value === formData.countryCode
            })[0]
          }
          options={countryList}
          onChange={(data) => {
            setFormData({
              ...formData,
              countryCode: data.value,
              bankId: null,
            })
          }}
        />
        <CreatableInputSelect
          isDisabled={getBankListIsLoading || getBankListIsFetching}
          isRequired
          label='Bank'
          className='mb-5'
          placeholder='Select Bank'
          value={
            formData.bankId
              ? bankList?.filter((item: any) => {
                  return item.value === formData.bankId
                })[0]
              : null
          }
          options={bankList}
          onChange={handleBankChange}
        />
        <TextField
          isRequired
          className='mb-5'
          placeholder='Ex: XXX-XXX'
          label='BSB No'
          value={formData.bsbNumber}
          onChange={(e) => {
            setFormData({
              ...formData,
              bsbNumber: e.target.value,
            })
          }}
        />
        <TextField
          isRequired
          placeholder='Ex: XXX XXXX XXXXX'
          label='Account No'
          value={formData.bankAccountNumber}
          onChange={(e) => {
            setFormData({
              ...formData,
              bankAccountNumber: e.target.value,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

BankAccountDetails.defaultProps = {}

export default BankAccountDetails
