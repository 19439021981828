import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getVisaTypeList = async () => {
  const res = await get(`/WorkRights`)
  return res
}

export const useGetVisaTypeList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['policeCheck', reqBody], getVisaTypeList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
