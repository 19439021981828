import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightSideModal, SimpleSelect } from '@labourhub/labour-hub-ds'
import { useGetDynamicForm } from 'framework/api/methods'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

import { PageLoader } from 'components/atoms'
import { FormBuilder } from 'components/molecules/FormBuilder'

export interface HealthDeclarationTemplateDrawerProps {
  headerCloseButton?: () => void | undefined
  headerSubtitle: string
  headerTitle: string
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const HealthDeclarationTemplateDrawer: FC<
  HealthDeclarationTemplateDrawerProps
> = ({
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  healthDeclarationTemplateList,
  onOverlayClick,
  regPackSections,
  selectedHealthDeclarationTemplate,
  setSelectedHealthDeclarationTemplate,
  setSelectedMedicalHistoryTile,
  showHealthDeclarationTemplateDrawer,
  ...restProps
}: HealthDeclarationTemplateDrawerProps) => {
  /** get dynamic form */
  const {
    data: dynamicForm,
    refetch: getDynamicForm,
    isLoading: getDynamicFormIsLoading,
    isFetching: getDynamicFormIsFetching,
  } = useGetDynamicForm(selectedHealthDeclarationTemplate?.value)

  const dispatch = useDispatch()

  const { templateDetails } = useSelector((state: any) => state.regPack)

  useEffect(() => {
    showHealthDeclarationTemplateDrawer && getDynamicForm()
  }, [showHealthDeclarationTemplateDrawer])

  return (
    <RightSideModal
      isActive={showHealthDeclarationTemplateDrawer}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      onHeaderCloseButtonClick={() => {
        headerCloseButton && headerCloseButton()

        regPackSections?.length > 0 &&
          regPackSections?.map((item: any) => {
            if (item?.title === 'Medical History') {
              dispatch(
                setTemplateDetails({
                  ...templateDetails,
                  sectionDetails: [
                    ...templateDetails.sectionDetails,

                    {
                      regPackSectionId: item?.id,
                      title: item?.title,
                      isMandatory: true,
                      metaData: {
                        healthDeclarationId:
                          selectedHealthDeclarationTemplate?.value,
                      },
                    },
                  ],
                }),
              )
            }
          })
      }}
      onOverlayClick={onOverlayClick}
      {...restProps}>
      <div className='w-full px-6'>
        <SimpleSelect
          label='Health Declaration Template'
          value={healthDeclarationTemplateList?.filter((item: any) => {
            return item?.value === selectedHealthDeclarationTemplate?.value
          })}
          className='mt-5'
          onChange={(selectedItem: any) => {
            setSelectedMedicalHistoryTile(true)
            setSelectedHealthDeclarationTemplate({
              ...selectedHealthDeclarationTemplate,
              label: selectedItem?.label,
              value: selectedItem?.value,
            })
            getDynamicForm()
          }}
          options={healthDeclarationTemplateList}
        />

        <div className='flex flex-col mt-10 bg-Cobalt-50 rounded-lg'>
          <span className='my-2 ml-4 text-small text-Gray-800 font-SemiBold'>
            Template Preview
          </span>

          {(getDynamicFormIsLoading || getDynamicFormIsFetching) && (
            <PageLoader size='xxs' />
          )}

          <div className='bg-white pt-5'>
            {dynamicForm && (
              <FormBuilder
                schema={JSON.parse(dynamicForm.jsonSchema)}
                uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
                readonly={true}
              />
            )}
          </div>
        </div>
      </div>
    </RightSideModal>
  )
}

HealthDeclarationTemplateDrawer.defaultProps = {
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  onOverlayClick: undefined,
}

export default HealthDeclarationTemplateDrawer
