/** client list account sizes */
export const accountSizes = [
  {
    value: 'Large',
    label: 'Large',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'Small',
    label: 'Small',
  },
  {
    value: 'Multinational',
    label: 'Multinational',
  },
]
