import React, { FC } from 'react'
import CN from 'classnames'

import { WaitingForActivationList } from './WaitingForActivationList'

export interface WaitingForActivationTabViewProps {
  [x: string]: any
}

export const WaitingForActivationTabView: FC<
  WaitingForActivationTabViewProps
> = ({
  auditorsList,
  className,
  documentCategoryList,
  documentTypeList,
  ...restProps
}: WaitingForActivationTabViewProps) => {
  const ActivatedTabViewClasses = CN(
    `activated-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={ActivatedTabViewClasses} {...restProps}>
      <span className='text-Gray-800 font-Medium'>Waiting for Activation</span>

      <span className='font-Regular text-small text-Gray-800 mt-1'>
        All the candidates waiting to be activated are listed down here. No one
        will be on the roster unless they are activated in the first place.
      </span>

      <div className='pt-8'>
        <WaitingForActivationList
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      </div>
    </div>
  )
}

WaitingForActivationTabView.defaultProps = {}

export default WaitingForActivationTabView
