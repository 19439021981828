import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface SetAuditFailedInterface {
  candidateId: string
  categoryId: string
  documentId: string
  isNotifyConsultant: boolean
  note: string
}

export const useSetAuditFailed = () => {
  const mutate = useMutation(
    ({
      candidateId,
      documentId,
      isNotifyConsultant,
      note,
      categoryId,
    }: SetAuditFailedInterface) => {
      return postAPI(
        `/facade/Candidate/${candidateId}/DocumentAudit/${documentId}/AuditFailed`,
        {
          isNotifyConsultant,
          note,
          categoryId,
        },
      )
    },
  )

  return mutate
}

export default useSetAuditFailed
