import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteClientLogoInterface {
  Id?: string
}

export const useDeleteClientLogo = () => {
  const mutate = useMutation(({ Id }: DeleteClientLogoInterface) => {
    return deleteAPI(`/Client/${Id}/Logo`)
  })

  return mutate
}

export default useDeleteClientLogo
