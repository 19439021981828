import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetClientList } from 'framework/api/methods'
import { accountSizes } from 'static-data/clients/accountSizes'
import { statusCodeList } from 'static-data/clients/statusCode'

import { TableHeader, UITable } from 'components/common/UITable'
import { AddNewClient } from 'components/Drawers/client'

import { ClientListColumn } from './ClientListColumn'

export interface ClientListProps {
  [x: string]: any
}

export const ClientList: FC<ClientListProps> = ({
  className,
  ...restProps
}: ClientListProps) => {
  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [clientTableColumn, setClientTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [selectedAccountSize, setSelectedAccountSize] = useState<any>()
  const [selectedStatus, setSelectedStatus] = useState<any>()
  const [filterActive, setFilterActive] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    status: '',
    size: '',
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')

  /** add new client drawer states */
  const [isOpenAddNewClientDrawer, setIsOpenAddNewClientDrawer] =
    useState(false)

  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  /** client List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** client List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All clients List */
  const {
    refetch: getTableData,
    isLoading: getClientListIsLoading,
    isFetching: getClientListIsFetching,
  } = useGetClientList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      status: filterVariables?.status || '',
      search: paginationVariables.search,
      size: filterVariables?.size || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  const applyFilter = () => {
    setFilterActive(true)
    setIsFilterMenuOpen(false)

    setFilterVariables({
      status: selectedStatus?.value || '',
      size: selectedAccountSize?.value || '',
    })
  }

  /** Client list table column handling */
  useEffect(() => {
    if (tableData?.client && tableData?.client?.length > 0) {
      const columns: any = ClientListColumn(navigate)
      setClientTableColumn(columns)
    }
  }, [tableData])

  const resetFilter = () => {
    setSearchKeyWord('')
    setSelectedStatus('')
    setSelectedAccountSize('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setFilterVariables({
      status: '',
      size: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const ClientListClasses = CN(`client-list w-full h-full`, className)

  return (
    <div className={ClientListClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        {/* table header */}
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          searchFieldPlaceholder={'Search Client'}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}>
          <Button
            className='ml-2'
            onClick={() => {
              setIsOpenAddNewClientDrawer(true)
            }}
            iconLeft={<i className='ri-add-line' />}
            theme='gradient'>
            Add New Client
          </Button>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              <div className='flex justify-center items-end px-4 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='By Status'
                  value={selectedStatus}
                  className='w-[249px] mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedStatus(selectedItem)
                  }}
                  options={statusCodeList}
                />

                <SimpleSelect
                  label='By Account Size'
                  value={selectedAccountSize}
                  className='w-[249px]'
                  onChange={(selectedItem: any) => {
                    setSelectedAccountSize(selectedItem)
                  }}
                  options={accountSizes}
                />

                <Button
                  className='ml-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedAccountSize?.value && !selectedStatus?.value
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={
              tableData?.client?.map((item: any) => {
                return {
                  ...item,
                  isActiveString: item?.isActive ? 'Active' : 'Disable',
                }
              }) || []
            }
            isLoading={getClientListIsLoading || getClientListIsFetching}
            className=''
            columns={clientTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {/* add new client panel */}
      <AddNewClient
        isActive={isOpenAddNewClientDrawer}
        setIsOpenAddNewClientDrawer={setIsOpenAddNewClientDrawer}
        getTableData={getTableData}
        notify={notify}
      />
    </div>
  )
}

ClientList.defaultProps = {}

export default ClientList
