import React, { FC, useEffect, useState } from 'react'
import { Button, Radio, TextArea, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'

import { CalendarInput, PhoneInputField } from 'components/atoms'

export interface UserDetailViewAndEditSectionProps {
  className?: string | undefined
  defaultCheckedGender?: string | undefined
  editableMode?: boolean | undefined
  helperText?: string | undefined
  inputFieldValue?: string | undefined
  onChangeDatePicker?: (e: any) => void | undefined
  onChangeInputField?: (e: any) => void | undefined
  onKeyPressInputField?: (e: any) => void | undefined
  onRadioGroupValueChange?: (e: any) => void | undefined
  onSaveButtonClick?: (e: any) => void | undefined
  placeholder?: string | undefined
  saveIsLoading?: boolean | undefined
  saveButtonIsDisabled?: boolean | undefined
  sectionTitle?: string | undefined
  sectionValue?: string | undefined
  selectedDate?: string | undefined
  textFieldLabel?: string | undefined
  onCancel?: () => void | undefined
  type?: 'text' | 'textarea' | 'date' | 'radio' | 'phone' | undefined
  [x: string]: any
}

export const UserDetailViewAndEditSection: FC<
  UserDetailViewAndEditSectionProps
> = ({
  className,
  defaultCheckedGender,
  editableMode,
  helperText,
  inputFieldValue,
  onChangeDatePicker,
  onChangeInputField,
  onKeyPressInputField,
  onRadioGroupValueChange,
  onSaveButtonClick,
  placeholder,
  saveIsLoading,
  saveButtonIsDisabled,
  sectionTitle,
  sectionValue,
  selectedDate,
  textFieldLabel,
  type,
  onCancel,
  ...restProps
}: UserDetailViewAndEditSectionProps) => {
  const [enableEditMode, setEnableEditMode] = useState(false)

  const [defaultChecked, setDefaultChecked] = useState({
    male: false,
    female: false,
    other: false,
  })

  useEffect(() => {
    if (defaultCheckedGender == 'Male') {
      setDefaultChecked({
        ...defaultChecked,
        male: true,
      })
    } else if (defaultCheckedGender == 'Female') {
      setDefaultChecked({
        ...defaultChecked,
        female: true,
      })
    } else if (defaultCheckedGender == 'Other') {
      setDefaultChecked({
        ...defaultChecked,
        other: true,
      })
    } else {
      setDefaultChecked({
        male: false,
        female: false,
        other: false,
      })
    }
  }, [defaultCheckedGender])

  const UserDetailViewAndEditSectionClasses = CN(
    `user-detail-view-and-edit-section w-full flex justify-between py-5 bg-white`,
    {
      'items-center': !enableEditMode,
      'items-end': enableEditMode,
    },
    className,
  )

  return (
    <div className={UserDetailViewAndEditSectionClasses} {...restProps}>
      {/* left side of the section */}

      {/* default mode */}
      {!enableEditMode && (
        <div className='flex flex-col w-full'>
          <span className='text-small font-Medium text-Gray-500'>
            {sectionTitle}
          </span>
          <span className='w-11/12 text-Gray-800 font-Regular pt-2 break-all'>
            {sectionValue ? sectionValue : 'N/A'}
          </span>
        </div>
      )}

      {/* when edit mode enable and text type  */}
      {enableEditMode && type === 'text' && (
        <TextField
          label={textFieldLabel}
          className='w-[340px]'
          helperText={helperText}
          onChange={onChangeInputField}
          onKeyPress={onKeyPressInputField}
          value={inputFieldValue}
          placeholder={placeholder}
        />
      )}

      {/* when edit mode enable and text type  */}
      {enableEditMode && type === 'textarea' && (
        <TextArea
          label={textFieldLabel}
          className='w-[340px]'
          onChange={onChangeInputField}
          onKeyPress={onKeyPressInputField}
          value={inputFieldValue}
          placeholder={placeholder}
        />
      )}

      {/* when edit mode enable and text type  */}
      {enableEditMode && type === 'date' && (
        <div className='flex flex-col'>
          <span className='flex text-Gray-800 font-Medium text-small pb-1'>
            Date of birth
          </span>

          <CalendarInput
            value={selectedDate}
            formatDate={(date) => format(date, 'dd/MM/yyyy')}
            parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
            className='mt-1'
            disableFutureDates={true}
            onChange={onChangeDatePicker}
          />
        </div>
      )}

      {/* when edit mode enable and text type  */}
      {enableEditMode && type === 'radio' && (
        <div className='flex flex-col'>
          <span className='flex text-Gray-800 font-Medium text-small pb-1'>
            Gender
          </span>
          <div className='flex gap-5'>
            <Radio
              id='Male'
              defaultChecked={defaultChecked.male}
              labelText='Male'
              helperText=''
              name='gender'
              onChange={onRadioGroupValueChange}
            />

            <Radio
              id='Female'
              defaultChecked={defaultChecked.female}
              labelText='Female'
              helperText=''
              name='gender'
              onChange={onRadioGroupValueChange}
            />

            <Radio
              id='Other'
              defaultChecked={defaultChecked.other}
              labelText='Other'
              helperText=''
              name='gender'
              onChange={onRadioGroupValueChange}
            />
          </div>
        </div>
      )}

      {/* when edit mode enable and text type  */}
      {enableEditMode && type === 'phone' && (
        <PhoneInputField
          label='Phone number'
          value={inputFieldValue}
          onChange={onChangeInputField}
          className='w-[340px]'
        />
      )}

      {/* right side of the section */}
      {enableEditMode ? (
        <div
          className={CN('flex', {
            'mb-6': helperText,
          })}>
          <Button
            className='mr-2'
            onClick={() => {
              setEnableEditMode(false)
              onCancel && onCancel()
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>

          <Button
            onClick={onSaveButtonClick}
            theme='gradient'
            isDisabled={saveButtonIsDisabled}
            isLoading={saveIsLoading}>
            Save
          </Button>
        </div>
      ) : (
        <>
          {editableMode && (
            <Button
              onClick={() => {
                setEnableEditMode(true)
              }}
              textTheme='blue'
              theme='link'>
              Change
            </Button>
          )}
        </>
      )}
    </div>
  )
}

UserDetailViewAndEditSection.defaultProps = {
  className: undefined,
  defaultCheckedGender: undefined,
  editableMode: true,
  helperText: undefined,
  inputFieldValue: undefined,
  onChangeDatePicker: undefined,
  onChangeInputField: undefined,
  onKeyPressInputField: undefined,
  onRadioGroupValueChange: undefined,
  onSaveButtonClick: undefined,
  placeholder: undefined,
  saveIsLoading: undefined,
  sectionTitle: undefined,
  sectionValue: 'N/A',
  selectedDate: undefined,
  textFieldLabel: undefined,
  type: 'text',
}

export default UserDetailViewAndEditSection
