/* eslint-disable no-case-declarations */
import React, { FC, useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetQuestionnaireBuildById } from 'framework/api/methods/questionnaires/useGetQuestionnaireBuildById'
import { useQuestionnaireBuild } from 'framework/api/methods/questionnaires/useQuestionnaireBuild'
import { useQuestionnaireBuilderUpdate } from 'framework/api/methods/questionnaires/useQuestionnaireBuilderUpdate'
import arrayObjectCompare from 'framework/utils/arrayObjectCompare'
import { questionsLibraryItemList } from 'static-data/questionnaires/questionnaireTemplateCategory'
import { v4 as uuid } from 'uuid'

import { QuestionLibraryCardItem } from 'components/atoms'
import {
  QuestionnaireBuilderHeaderSection,
  WebRefCheckPreviewModal,
} from 'components/molecules'

import { QuestionnaireComponents } from './QuestionnaireComponents'

/** questions library available components list */

const columnsFromBackend: any = {
  draggableColum: {
    title: 'Questions Library',
    subTitle: 'Drag & drop question types to create a template.',
    width: 'w-1/3 h-fit',
    colStyle: 'grid grid-cols-2',
    items: questionsLibraryItemList,
    isDropDisabled: true,
  },
  droppableColum: {
    width: 'w-2/3',
    colStyle: 'flex flex-shrink flex-grow flex-col gap-y-5',
    items: [],
    isDropDisabled: false,
  },
}

export interface QuestionnaireBuilderProps {
  [x: string]: any
}

export const QuestionnaireBuilder: FC<QuestionnaireBuilderProps> = ({
  className,
  ...restProps
}: QuestionnaireBuilderProps) => {
  const notify = (props: any) => Toast(props)
  const navigation = useNavigate()

  const { questionnaireId } = useParams()

  /** header component states */
  const [formData, setFormData] = useState<any>({ name: '', category: '' })

  /** main style */
  const QuestionnaireBuilderClasses = CN(`questionnaire-builder`, className)

  const [columns, setColumns] = useState(columnsFromBackend)
  const [destinationColumn, setDestinationColumn] = useState<any>([])
  const [editableFields, setEditableFields] = useState<any>([])
  const [editableRow, setEditableRow] = useState<any>([])
  const [editableQuestionnaire, setEditableQuestionnaire] = useState<any>([])
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('')
  const [qId, setQId] = useState<any>(questionnaireId)
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false)
  const [showDeleteQuestionaryModal, setShowDeleteQuestionaryModal] =
    useState<boolean>(false)
  const [deletedRestCols, setDeletedRestCols] = useState<any>([])
  const [deleteRowIdx, setDeleteRowIdx] = useState<any>()

  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)

  /** Get Questionary builder values By API */
  const { refetch: getQuestionnaire } = useGetQuestionnaireBuildById(
    {
      qId,
    },
    (data: any) => {
      setLastUpdatedBy(data.questionnaire?.updatedOn)
      setIsDraft(data.questionnaire?.isActive)
      setFormData({
        name: data?.questionnaire?.name,
        category: data?.questionnaire?.type,
      })
      setEditableFields({
        name: data?.questionnaire?.name,
        category: data?.questionnaire?.type,
      })
      const editableDestinationColumnTemp: any = []
      data?.questionnaire?.questions.map((question: any) => {
        switch (question.type) {
          case 'text':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-text-spacing',
              itemName: 'Short Answer',
              isRequired: question.isRequired,
              type: 'text',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'text_area':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-text-wrap',
              itemName: 'Long Answer',
              isRequired: question.isRequired,
              type: 'text_area',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'select':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-star-line',
              itemName: 'Select Box',
              isRequired: question.isRequired,
              type: 'select',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'rating':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-star-line',
              itemName: 'Rating',
              isRequired: question.isRequired,
              type: 'rating',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'checkbox':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-checkbox-multiple-line',
              itemName: 'Multi Choice',
              isRequired: question.isRequired,
              type: 'checkbox',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'radio':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-radio-button-line',
              itemName: 'Yes/No',
              isRequired: question.isRequired,
              type: 'radio',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'radio_pos_detail':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-checkbox-circle-line',
              itemName: 'Detailed Yes',
              isRequired: question.isRequired,
              type: 'radio_pos_detail',
              title: question.title,
              meta: [...question.meta.enum],
            })
            break
          case 'radio_neg_detail':
            editableDestinationColumnTemp.push({
              id: question.id,
              iconName: 'ri-checkbox-circle-line',
              itemName: 'Detailed No',
              isRequired: question.isRequired,
              type: 'radio_neg_detail',
              title: question.title,
              meta: [...question.meta.enum],
            })
        }
      })
      columnsFromBackend.droppableColum.items.push(
        ...editableDestinationColumnTemp,
      )
      setEditableQuestionnaire(editableDestinationColumnTemp)
      setDestinationColumn(editableDestinationColumnTemp)
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  /** Questionary builder mutate */
  const { mutate: createQuestionnaire, isLoading: questionsCreateLoading } =
    useQuestionnaireBuild()

  /** Questionary builder update */

  const {
    mutate: createQuestionnaireUpdate,
    isLoading: questionsUpdateLoading,
  } = useQuestionnaireBuilderUpdate()

  useEffect(() => {
    if (qId) {
      getQuestionnaire()
    } else {
      setDestinationColumn(columnsFromBackend)
    }
  }, [qId])

  useEffect(() => {
    if (arrayObjectCompare(destinationColumn, editableQuestionnaire)) {
      setIsSaveBtnEnabled(true)
    } else {
      setIsSaveBtnEnabled(false)
    }
  }, [editableQuestionnaire, destinationColumn])

  useEffect(() => {
    const destinationColumnsTemp = [...destinationColumn]

    const editableColumns = destinationColumnsTemp.map((column, idx) => {
      if (idx === editableRow.index - 1) {
        const { isRequired, meta, title } = editableRow
        return {
          ...column,
          isRequired: isRequired,
          meta: [...meta],
          title: title,
        }
      } else {
        return {
          ...column,
        }
      }
    })
    setDestinationColumn(editableColumns)
  }, [editableRow])

  useEffect(() => {
    return () => {
      columnsFromBackend.droppableColum.items = []
      setColumns(columnsFromBackend)
      setDestinationColumn([])
      setEditableFields([])
    }
  }, [])

  /** questionnaire builder save or publish */
  const onSaveClick = ({ isPublished }) => {
    const { name, category }: any = formData
    const questions = destinationColumn.map((column) => {
      const { title, type, isRequired, meta } = column
      return {
        title,
        type,
        isRequired,
        meta,
      }
    })
    if (qId) {
      createQuestionnaireUpdate(
        {
          QId: qId,
          name: name,
          type: category,
          questions: questions,
          isActive: isPublished,
        },
        {
          onSettled: (data: any) => {
            notify({
              alertHeader: isPublished
                ? 'Template published successfully'
                : 'Template saved as a draft successfully',
              alertBody: '',
              status: 'Success',
            })
            if (data?.data?.questionnaire?.isActive)
              navigation('/questionnaires')
            else {
              setLastUpdatedBy(data?.data?.questionnaire?.updatedOn)
              setIsDraft(data?.data?.questionnaire?.isActive)
              setQId(data?.data?.questionnaire?.id)
            }
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    } else {
      createQuestionnaire(
        {
          name: name,
          type: category,
          questions: questions,
          isActive: isPublished,
        },
        {
          onSettled: (data: any) => {
            notify({
              alertHeader: isPublished
                ? 'Template published successfully'
                : 'Template saved as a draft successfully',
              alertBody: '',
              status: 'Success',
            })
            if (data?.data?.questionnaire?.isActive) {
              setEditableFields({
                name: data?.data?.questionnaire?.name,
                category: data?.data?.questionnaire?.type,
              })
              navigation('/questionnaires')
            } else {
              setLastUpdatedBy(data?.data?.questionnaire?.updatedOn)
              setIsDraft(data?.data?.questionnaire?.isActive)
              setQId(data?.data?.questionnaire?.id)
            }
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    }
  }

  const handleDeleteQuestionnaire = (column: any, index: number) => {
    destinationColumn.splice(index, 1)
    setDestinationColumn(destinationColumn)
    setColumns({
      ...columns,
      droppableColum: {
        ...column,
        items: destinationColumn,
      },
    })
    setShowDeleteQuestionaryModal(!showDeleteQuestionaryModal)
  }

  const onDragEnd = (
    result: any,
    columns: any,
    setColumns: any,
    isScrollToBottom?: boolean,
  ) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destinationColumnTemp = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems: any = [...destinationColumnTemp.items]
      const [removed] = Array.from(sourceItems).splice(source.index, 1)
      destItems.splice(destination.index, 0, {
        ...removed,
        id: uuid(),
      })

      destinationColumn.map((column: any) => {
        const idx = destItems.findIndex((item: any) => item.id === column.id)
        destItems[idx] = column
      })

      setDestinationColumn(destItems)

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destinationColumnTemp,
          items: destItems,
          isScrollToBottom: isScrollToBottom || false,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setDestinationColumn(copiedItems)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
    }
  }
  const bottomRef = useRef<any>(null)

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    columns?.droppableColum?.isScrollToBottom && scrollToBottom()
  }, [columns])

  return (
    <div className={QuestionnaireBuilderClasses} {...restProps}>
      {/* header section */}
      <QuestionnaireBuilderHeaderSection
        formData={formData}
        destinationColumn={destinationColumn}
        lastUpdatedBy={lastUpdatedBy}
        isDraft={isDraft}
        setFormData={setFormData}
        editableFields={editableFields}
        isSaveBtnEnabled={isSaveBtnEnabled}
        onSaveClick={() => onSaveClick({ isPublished: false })}
        onPublishClick={() => onSaveClick({ isPublished: true })}
        onPreviewClick={() => setIsShowPreviewModal(true)}
        isLoading={questionsCreateLoading || questionsUpdateLoading}
        questionnaireId={qId}
      />

      {/* bottom section*/}
      <div className='flex w-full h-auto mt-5 gap-x-5'>
        {/* questions library section*/}
        <div className='flex flex-row w-full gap-5'>
          {/** Drag & drop section */}
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([id, column]: any) => {
              return (
                <div
                  className={CN(
                    `${column.width} flex flex-col px-5 pt-4 bg-white border rounded-md gap-y-5 questions-library border-Gray-200 pb-9`,
                    {
                      'sticky top-3': column.title === 'Questions Library',
                    },
                  )}>
                  {column.title && column.subTitle && (
                    <div className='flex flex-col'>
                      <span className='header-txt text-heading-5 font-Bold text-Gray-800'>
                        Questions Library
                      </span>
                      <span className='sub-header-txt text-small font-Regular text-Gray-600'>
                        Drag & drop question types to create a template.
                      </span>
                    </div>
                  )}

                  <div className='w-full h-full'>
                    <Droppable
                      droppableId={id}
                      key={id}
                      isDropDisabled={column.isDropDisabled}>
                      {(provided) => {
                        const { name, category } = formData
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={CN(
                              `w-full h-full gap-5 ${column.colStyle}`,
                              {
                                'cursor-not-allowed': !name || !category,
                              },
                            )}>
                            {column.items.length !== 0 ? (
                              column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    isDragDisabled={!name || !category}
                                    index={index}>
                                    {(provided, snapshot) => {
                                      const optionList: any = []
                                      let starCount: number
                                      if (item.title === 'Rating')
                                        starCount = item?.meta?.length
                                      else starCount = 10

                                      item.meta.map((a: any) => {
                                        optionList.push({
                                          label: a,
                                        })
                                      })

                                      return (
                                        <>
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            data-isDragging={
                                              snapshot.isDragging
                                            }>
                                            {id === 'droppableColum' ? (
                                              <div>
                                                <QuestionnaireComponents
                                                  key={index}
                                                  type={item.itemName}
                                                  questionTitle={item.title}
                                                  isRequired={item.isRequired}
                                                  optionList={optionList}
                                                  starCount={starCount}
                                                  onDeleteClick={() => {
                                                    setShowDeleteQuestionaryModal(
                                                      !showDeleteQuestionaryModal,
                                                    )
                                                    setDeletedRestCols(column)
                                                    setDeleteRowIdx(index)
                                                  }}
                                                  setEditableRow={
                                                    setEditableRow
                                                  }
                                                  index={index + 1}
                                                />
                                              </div>
                                            ) : (
                                              <QuestionLibraryCardItem
                                                key={index}
                                                onDoubleClick={() => {
                                                  const result = {
                                                    destination: {
                                                      droppableId:
                                                        'droppableColum',
                                                      index:
                                                        columns?.droppableColum
                                                          ?.items?.length || 0,
                                                    },
                                                    source: {
                                                      index: index,
                                                      droppableId:
                                                        'draggableColum',
                                                    },
                                                  }

                                                  if (name && category) {
                                                    onDragEnd(
                                                      result,
                                                      columns,
                                                      setColumns,
                                                      true,
                                                    )
                                                  }
                                                }}
                                                iconName={item.iconName}
                                                itemName={item.itemName}
                                              />
                                            )}
                                          </div>
                                          {snapshot.isDragging &&
                                            id !== 'droppableColum' && (
                                              <div>
                                                <QuestionLibraryCardItem
                                                  iconName={item.iconName}
                                                  itemName={item.itemName}
                                                />
                                              </div>
                                            )}
                                        </>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })
                            ) : (
                              <div className='flex flex-col items-center justify-center py-5 border rounded-lg gap-y-2 border-Gray-200 bg-Gray-50'>
                                <span className='text-Gray-800 font-Medium text-[16px] '>
                                  Drop Zone
                                </span>
                                <span className='text-Gray-500 text-[12px] font-Regular'>
                                  Drag and drop form elements to create a
                                  template
                                </span>
                              </div>
                            )}
                            {provided.placeholder}
                            <div ref={bottomRef} />
                          </div>
                        )
                      }}
                    </Droppable>
                  </div>
                </div>
              )
            })}
          </DragDropContext>
        </div>
      </div>

      {isShowPreviewModal && (
        <WebRefCheckPreviewModal
          isActive={isShowPreviewModal}
          modalClose={() => {
            setIsShowPreviewModal(false)
          }}
          templateId={questionnaireId || qId}
          templateName={formData.name}
          notify={notify}
        />
      )}

      {/* delete user profile picture modal */}
      <Modal
        isActive={showDeleteQuestionaryModal}
        onClickPrimaryBtn={() => {
          handleDeleteQuestionnaire(deletedRestCols, deleteRowIdx)
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteQuestionaryModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteQuestionaryModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            {`Delete ${destinationColumn[deleteRowIdx]?.itemName}?`}
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            {`Are you sure you want to proceed?`}
          </span>
        </div>
      </Modal>
    </div>
  )
}

QuestionnaireBuilder.defaultProps = {}

export default QuestionnaireBuilder
