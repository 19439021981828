import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface setUnarchiveDocumentByIdProps {
  selectedDocumentId?: any
}

export const useSetUnarchiveDocumentById = () => {
  const mutate = useMutation(
    ({ selectedDocumentId }: setUnarchiveDocumentByIdProps) => {
      return postAPI(`/Document/${selectedDocumentId}/UnArchived`, {})
    },
  )

  return mutate
}

export default useSetUnarchiveDocumentById
