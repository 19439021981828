/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import certificateIcon from 'assets/images/CertificationIcon.svg'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface VisaAttachmentsCardProps {
  onAddNewClick?: any | undefined
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  [x: string]: any
}

export const VisaAttachmentsCard: FC<VisaAttachmentsCardProps> = ({
  isLoading,
  className,
  onAddNewClick,
  onDeleteClick,
  onEditClick,
  onViewAttachmentClick,
  visaDetails,
  showVisaAttachmentsMoreButtonOption,
  setShowVisaAttachmentsMoreButtonOption,
  ...restProps
}: VisaAttachmentsCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  const VisaAttachmentsCardClasses = CN(
    `px-5 py-4 bg-white border-[1px] bg-white border-Gray-200 rounded-md relative`,
    className,
  )

  return (
    <div className={VisaAttachmentsCardClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Visa Related Attachments
        </span>

        <Button
          onClick={onAddNewClick}
          textTheme='blue'
          size='sm'
          theme='white'>
          Add
        </Button>
      </div>

      {!visaDetails || visaDetails?.length === 0 ? (
        <div className='flex flex-col justify-center items-center mt-6'>
          <i className='ri-file-list-2-line text-[33px] text-Gray-500' />
          <span className='text-[20px] text-Gray-800 mt-[10px] font-medium'>
            No Visa Related Attachments Found
          </span>
          <span className='text-[14px] text-Gray-600 mt-1 mb-3 font-normal'>
            You can add by clicking below button
          </span>
          <Button
            onClick={() => {
              onAddNewClick()
            }}
            iconLeft={<i className='ri-add-line' />}
            size='sm'
            theme='cobalt'>
            Add Visa Related Attachments
          </Button>
        </div>
      ) : (
        <div>
          {visaDetails?.map((item, index) => (
            <div
              key={index}
              className='flex justify-between pl-[6px] items-start pt-4'>
              <div className='flex'>
                <img
                  src={certificateIcon}
                  alt=''
                  className='w-10 h-10 mr-4 mt-2'
                />
                <div>
                  <div className='flex items-center mb-[6px]'>
                    <span className='mr-2 font-SemiBold text-Gray-800'>
                      {item.type}
                    </span>
                    {item?.status && (
                      <DocumentStatusIcon
                        status={item?.status}
                        auditRequired={item?.auditRequired}
                      />
                    )}
                  </div>
                  <div className='flex flex-col'>
                    <span
                      className={CN('font-Medium text-small mb-1', {
                        'text-Red-500': item?.status === 'Expired',
                        'text-Gray-500': item?.status !== 'Expired',
                      })}>
                      Expires on:{' '}
                      {item?.expiryOn
                        ? dateToReadableString(item?.expiryOn)
                        : ' --- '}
                    </span>
                    {item?.notes && (
                      <span className='text-Gray-500 font-Medium text-small'>
                        {item?.notes}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowVisaAttachmentsMoreButtonOption({
                      index: index,
                    })
                    setShowVisaAttachmentsMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showVisaAttachmentsMoreButtonOption.index
                          ? !showVisaAttachmentsMoreButtonOption.isEnable
                          : true,
                    })
                  }}
                />

                {/** More button DropDown =================================================>>>>>>*/}
                {showVisaAttachmentsMoreButtonOption.isEnable &&
                  index === showVisaAttachmentsMoreButtonOption.index && (
                    <div className='flex flex-col absolute right-0 top-[42px] w-[169px] pt-2 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40 font-Regular'>
                      <span
                        className='text-Gray-800 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowVisaAttachmentsMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onViewAttachmentClick(item)
                        }}>
                        View Attachments
                      </span>

                      <span
                        className='text-Gray-800 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowVisaAttachmentsMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onEditClick(item)
                        }}>
                        Edit
                      </span>

                      <span
                        className={CN('text-Gray-800 pl-3 mb-4 ', {
                          'hover:bg-Gray-100':
                            (item?.auditRequired &&
                              item?.status === 'Request_Submission') ||
                            item?.status === 'Pending_Audit',

                          'opacity-50 hover:bg-Gray-white':
                            !item?.auditRequired ||
                            item?.status === 'Active' ||
                            item?.status === 'Audit_Failed' ||
                            item?.status === 'Expired' ||
                            item?.status === 'Archived' ||
                            (!item?.auditRequired &&
                              item?.status === 'Request_Submission'),
                        })}
                        onClick={(e: any) => {
                          if (
                            (item?.auditRequired &&
                              item?.status === 'Request_Submission') ||
                            item?.status === 'Pending_Audit'
                          ) {
                            e.stopPropagation()

                            setShowVisaAttachmentsMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })

                            dispatch(setSelectedDocumentId(item?.visaId))

                            /** selected candidate states set to store */
                            dispatch(
                              setSelectedCandidate({
                                name: candidateName,
                                id: candidateId,
                              }),
                            )

                            /** audit check modal open handle from state */
                            dispatch(
                              setIsAuditModal({
                                isOpen: true,
                                isFooterShow: false,
                                isActiveButton: false,
                                tab: null,
                              }),
                            )
                          }
                        }}>
                        Perform Audit
                      </span>

                      <span
                        className='text-Red-500 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowVisaAttachmentsMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onDeleteClick(item)
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

VisaAttachmentsCard.defaultProps = {}

export default VisaAttachmentsCard
