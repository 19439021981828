/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetCandidateStateListInterface {}

export const useGetCandidateStateList = () => {
  const mutate = useMutation(({}: useGetCandidateStateListInterface) => {
    return get(`/State`, {})
  })

  return mutate
}

export default useGetCandidateStateList
