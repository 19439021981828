/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { MoreButton, PageLoader } from 'components/atoms'

export interface SuperFundDetailsCardProps {
  onEditClick: () => void
  onAddClick: () => void
  [x: string]: any
}

export const SuperFundDetailsCard: FC<SuperFundDetailsCardProps> = ({
  className,
  isLoading,
  onEditClick,
  onAddClick,
  data,
  showSuperFundMoreOption,
  setShowSuperFundMoreOption,
  ...restProps
}: SuperFundDetailsCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  const SuperFundDetailsCardClasses = CN(
    `px-5 py-4 bg-white border bg-white border-Gray-200 rounded-md mt-[18px] relative`,
    className,
  )

  return (
    <div className={SuperFundDetailsCardClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      {/** Heading and ADD Button =========================================>>>>> */}
      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Super Fund
        </span>

        {data && (
          <div className='relative'>
            <MoreButton
              onClick={(e: any) => {
                e.stopPropagation()
                setShowSuperFundMoreOption(!showSuperFundMoreOption)
              }}
            />

            {/** More button drop down options */}
            {showSuperFundMoreOption && (
              <div className='flex flex-col absolute top-[42px] right-[0px] w-[169px] py-1 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40'>
                <div
                  className='flex font-Regular text-Gray-800 pl-4 py-2 hover:bg-Gray-50'
                  onClick={(e: any) => {
                    e.stopPropagation()
                    onEditClick && onEditClick()
                    setShowSuperFundMoreOption(false)
                  }}>
                  <span>Edit</span>
                </div>

                <div
                  className={CN('flex font-Regular text-Gray-800 pl-4 py-2', {
                    'hover:bg-Gray-50':
                      (data?.auditRequired &&
                        data?.status === 'Request_Submission') ||
                      data?.status === 'Pending_Audit',

                    'opacity-50 hover:bg-Gray-white':
                      !data?.auditRequired ||
                      data?.status === 'Active' ||
                      data?.status === 'Audit_Failed' ||
                      data?.status === 'Expired' ||
                      data?.status === 'Archived' ||
                      (!data?.auditRequired &&
                        data?.status === 'Request_Submission'),
                  })}
                  onClick={(e: any) => {
                    if (
                      (data?.auditRequired &&
                        data?.status === 'Request_Submission') ||
                      data?.status === 'Pending_Audit'
                    ) {
                      e.stopPropagation()

                      setShowSuperFundMoreOption(false)

                      dispatch(setSelectedDocumentId(data?.id))

                      /** selected candidate states set to store */
                      dispatch(
                        setSelectedCandidate({
                          name: candidateName,
                          id: candidateId,
                        }),
                      )

                      /** audit check modal open handle from state */
                      dispatch(
                        setIsAuditModal({
                          isOpen: true,
                          isFooterShow: false,
                          isActiveButton: false,
                          tab: null,
                        }),
                      )
                    }
                  }}>
                  <span>Perform Audit</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {!data ? (
        <div className='flex flex-col justify-center items-center mt-6'>
          <i className='ri-refund-2-line text-[20px] text-Gray-500' />
          <span className='text-[20px] text-Gray-800 mt-[10px] font-medium'>
            No Super Fund
          </span>
          <span className='text-[14px] text-Gray-600 mt-1 mb-2 font-normal'>
            Please Add Super Fund Details
          </span>
          <Button
            onClick={() => {
              onAddClick()
            }}
            iconLeft={<i className='ri-add-line' />}
            size='sm'
            theme='cobalt'>
            Add Super Fund Details
          </Button>
        </div>
      ) : (
        <div className='flex flex-col mt-6'>
          <div className='flex mb-3 items-center'>
            <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
              Super Provider
            </span>
            <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
              {data?.superProviderName ? data?.superProviderName : `N/A`}
            </span>
          </div>
          <div className='flex mb-3 items-center'>
            <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
              Super Name
            </span>
            <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
              {data?.superName ? data?.superName : 'N/A'}
            </span>
          </div>
          <div className='flex mb-3 items-center'>
            <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
              Membership No
            </span>
            <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
              {data?.membershipNumber ? data?.membershipNumber : 'N/A'}
            </span>
          </div>
          <div className='flex mb-3 items-center'>
            <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
              Super ABN
            </span>
            <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
              {data?.abn ? data?.abn : 'N/A'}
            </span>
          </div>
          <div className='flex mb-3 items-center'>
            <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
              Super USI
            </span>
            <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
              {data?.usi ? data?.usi : 'N/A'}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

SuperFundDetailsCard.defaultProps = {}

export default SuperFundDetailsCard
