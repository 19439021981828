import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getSuperProviderList = async () => {
  const res = await get(`/SuperProvider`)
  return res.data
}

export const useGetSuperProvider = (onSuccess: any, onError: any) => {
  return useQuery(['SuperProviderList'], getSuperProviderList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
