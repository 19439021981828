import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CN from 'classnames'
import {
  setCurrentRoute as acSetCurrentRoute,
  setMenu as acSetMenu,
  setToggleMenu,
} from 'store/reducers/side-menu/mainSideMenuReducer'

import MenuItem from 'components/common/Sidebar/MenuItem'

interface SidebarProps {
  className: string
}

/** this is the main components of main side bar */
export const MainSideBar: FC<SidebarProps> = ({ className }: SidebarProps) => {
  const {
    menuItems: allItems,
    currentRoute,
    toggleMenu,
  } = useSelector((state: any) => state.sideMenuList)
  const menuItems = allItems.filter((item: any) => item.menuPosition === 'left')
  const [menuKey, setMenuKey] = useState(Math.random())

  const dispatch = useDispatch()
  const location = useLocation()

  /** handle current route */
  const setCurrentRoute = () => {
    const { pathname } = location
    dispatch(acSetCurrentRoute(pathname))
  }

  /** dispatch current route when select new tab */
  useEffect(() => {
    setCurrentRoute()
  }, [location])

  /** handle active tab when page reload */
  useEffect(() => {
    const { pathname } = location
    const firstRoute = pathname.split('/')[1]
    const secondRoute = pathname.split('/')[2]
    const thirdRoute = pathname.split('/')[3]

    if (menuItems.length > 0) {
      const itemsToUpdate = menuItems.map((menuItem: any) => {
        return {
          ...menuItem,
          subMenu: menuItem.subMenu.map((subMenuItem: any) => {
            return {
              ...subMenuItem,
              isActive: false,
              subMenu: subMenuItem.subMenu.map((innerMenuItem: any) => {
                return { ...innerMenuItem, isActive: false }
              }),
            }
          }),
          isActive: false,
        }
      })
      const cloneItemsToUpdate = JSON.parse(JSON.stringify(itemsToUpdate))

      // First Menu
      if (
        firstRoute ||
        `${firstRoute}` === '' ||
        firstRoute === '/' ||
        firstRoute === ' '
      ) {
        const selectedIndex = cloneItemsToUpdate?.findIndex((item: any) => {
          return item.link === `/${firstRoute}`
        })
        if (selectedIndex > -1) {
          cloneItemsToUpdate[selectedIndex].isActive = true

          // Second Menu .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (secondRoute) {
            const selectedSubMenuIndex = cloneItemsToUpdate[
              selectedIndex
            ]?.subMenu.findIndex((subItem: any) => {
              return subItem.link === `/${firstRoute}/${secondRoute}`
            })

            if (selectedIndex > -1 && selectedSubMenuIndex > -1) {
              cloneItemsToUpdate[selectedIndex].subMenu[
                selectedSubMenuIndex
              ].isActive = true
            }

            // Third Menu .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
            if (thirdRoute) {
              const selectedInnerMenuIndex = cloneItemsToUpdate[
                selectedIndex
              ]?.subMenu?.[selectedSubMenuIndex]?.subMenu?.findIndex(
                (subItem: any) => {
                  return (
                    subItem.link ===
                    `/${firstRoute}/${secondRoute}/${thirdRoute}`
                  )
                },
              )

              if (
                selectedIndex > -1 &&
                selectedSubMenuIndex > -1 &&
                selectedInnerMenuIndex > -1
              ) {
                cloneItemsToUpdate[selectedIndex].subMenu[
                  selectedSubMenuIndex
                ].subMenu[selectedInnerMenuIndex].isActive = true
              }
            }
            // .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          }
          // .....................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

          dispatch(acSetMenu(cloneItemsToUpdate.slice()))
        }
      }
    }
    setMenuKey(Math.random())
  }, [currentRoute])

  /** side bar main styles */
  const SidebarClasses = CN(
    `sidebar flex flex-col h-full flex-shrink-0 bg-white shadow relative`,
    className,
  )

  return (
    <div className={SidebarClasses}>
      <nav className='flex flex-col w-full relative px-4 h-full' key={menuKey}>
        {menuItems.map((mainMenuitem: any, idx: number) => (
          <MenuItem
            menuItems={menuItems}
            item={mainMenuitem}
            key={idx}
            index={idx}
            currentRoute={currentRoute}
            setToggleMenu={setToggleMenu}
            toggleMenu={toggleMenu}
          />
        ))}
      </nav>

      {/* side bar footer */}
      <div className='flex w-full justify-center items-center absolute bottom-0 mb-6'>
        <i className='ri-add-line flex items-center justify-center h-10 w-10 rounded-lg border-[1px] border-[#E57062] text-heading-4 text-[#E57062]' />
      </div>
    </div>
  )
}

export default MainSideBar
