/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, ToastContainer } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetHeaderBarDetails, useUserDetails } from 'framework/api/methods'
import { setLayoutClickValue } from 'store/reducers/layout/layoutSlice'
import {
  setHeaderBarDetails,
  setUserDetails,
} from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import { Breadcrumb } from 'components/common/Breadcrumb'
import { Header } from 'components/common/Header'
import { MainSideBar } from 'components/common/Sidebar'
import { AuditCheckModal } from 'components/pages/Audit'

export interface MainLayoutProps {
  [x: string]: any
}

export const MainLayout: FC<MainLayoutProps> = ({
  hasSidebar,
  heading,
  isBackButton,
  children,
}: MainLayoutProps) => {
  const { isBreadCrumbVisible } = useSelector((state: any) => state.breadcrumbs)
  const { isLoading, backFunction } = useSelector((state: any) => state.layout)
  const { isAuditModal } = useSelector((state: any) => state.audit)

  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  /** API Call for Login function using react-query */
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = localStorage.getItem('token')

  // /** Process the Login submission */
  const tokenDataOnSuccess = (data: any) => {
    dispatch(setUserDetails(data.profile))
  }
  const tokenDataOnError = () => {
    localStorage.removeItem('token')
    navigate('/auth/login')
  }
  const { refetch } = useUserDetails({}, tokenDataOnSuccess, tokenDataOnError)

  // /** Process the header bar success */
  const headerBarDetailsOnSuccess = (data: any) => {
    dispatch(setHeaderBarDetails(data.toolBarDetails))
  }

  // /** handle api for get the header bar  details and logos */
  const { refetch: getHeaderBarDetails } = useGetHeaderBarDetails(
    {},
    headerBarDetailsOnSuccess,
  )

  useEffect(() => {
    if (!token) {
      navigate('/auth/login')
    }
  }, [token])

  useEffect(() => {
    token && refetch()
  }, [token])

  useEffect(() => {
    token && getHeaderBarDetails()
  }, [token, isHeaderBarDetailsUpdated])

  const renderLayoutInner = () => {
    return (
      <div className={CN('flex flex-col h-full relative px-8 py-5', {})}>
        {heading && (
          <div className='flex justify-between items-center'>
            <div className={CN('flex w-full items-left flex-col', {})}>
              {isBreadCrumbVisible && <Breadcrumb />}
              <p className='font-Medium text-heading-4 text-Gray-800 mt-1'>
                {heading}
              </p>
              {/* {subHeading && (
            <span className='font-Regular text-small text-Gray-600 mt-1'>
              {subHeading}
            </span>
          )} */}
            </div>

            {isBackButton && (
              <div className='w-full flex justify-end items-center'>
                <Button
                  iconLeft={<i className='ri-arrow-left-line text-[18px]' />}
                  className='card-edit-button w-auto'
                  onClick={backFunction}
                  textTheme='blue'
                  theme='white'>
                  {isBackButton}
                </Button>
              </div>
            )}

            <AuditCheckModal
              isActive={isAuditModal?.isOpen}
              isFooterShow={isAuditModal?.isFooterShow}
              isActiveButton={isAuditModal?.isActiveButton}
            />
          </div>
        )}

        <div
          className={CN('main-layout__inner flex flex-col h-full w-full mt-6')}>
          {children}
        </div>
        <ToastContainer className='mt-10 w-full max-w-[420px] min-w-[350px]' />
      </div>
    )
  }

  const renderLayout = () => {
    // Render with sidebar
    if (hasSidebar) {
      return (
        <>
          <MainSideBar className='top-[0px] left-0' />
          <div className='flex flex-col w-full h-full overflow-y-auto styled-scroll'>
            {/* {hasSubHeading && <SubHeader />} overflow-auto */}
            {renderLayoutInner()}
          </div>
        </>
      )
    }

    // Render without sidebar
    return (
      <div className='container'>
        {/* {hasSubHeading && <SubHeader />} */}
        {renderLayoutInner()}
      </div>
    )
  }

  return (
    <>
      <Header className='fixed left-0 top-0 h-[56px]' />
      {isLoading && <PageLoader size='xxs' />}
      <div
        onClick={() => {
          dispatch(setLayoutClickValue())
        }}
        className={CN(
          'main-layout__container fixed flex top-[56px] w-full h-[calc(100%-56px)] bg-Gray-100',
        )}>
        {renderLayout()}
      </div>
    </>
  )
}

export default MainLayout
