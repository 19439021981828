import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deleteAgencyLocationProps {
  Id?: any
  LID?: any
}

export const useDeleteAgencyLocation = () => {
  const mutate = useMutation(({ Id, LID }: deleteAgencyLocationProps) => {
    return deleteAPI(`/AgencyProfile/${Id}/AgencydLocation/${LID}`, {})
  })

  return mutate
}

export default useDeleteAgencyLocation
