import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addUserPermissionsProps {
  Id?: any
  permission?: any
}

export const useAddUserPermissions = () => {
  const mutate = useMutation(({ Id, permission }: addUserPermissionsProps) => {
    return postAPI(
      `/identity/user/${Id}/permission?Permission=${permission}`,
      {},
    )
  })

  return mutate
}

export default useAddUserPermissions
