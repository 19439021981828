import React, { FC } from 'react'
import CN from 'classnames'
import moment from 'moment'

export enum RefCheckStateToTextEnum {
  RESPONSE_RECEVIED = 'Response Received',
  AWAITING_RESPONSE = 'Awaiting for Response',
  REQUEST_PHONE_REF = 'Requested Phone Ref Check',
  REQUEST_DECLINED = 'Request Declined',
  NOT_SENT = 'Not Sent',
}

export interface CandidateRefereeStatusCardProps {
  [x: string]: any
  sentDate?: string | null
  updatedDate?: string | null
  declinedDate?: Date
  respondedDate?: Date
  status: 'Failed' | 'Incomplete' | 'Passed' | null
  state:
    | 'RESPONSE_RECEVIED'
    | 'AWAITING_RESPONSE'
    | 'REQUEST_PHONE_REF'
    | 'REQUEST_DECLINED'
    | 'NOT_SENT'
    | null
}

export const CandidateRefereeStatusCard: FC<
  CandidateRefereeStatusCardProps
> = ({
  status,
  state,
  sentDate,
  updatedDate,
  ...restProps
}: CandidateRefereeStatusCardProps) => {
  return (
    <div
      {...restProps}
      className={CN(
        'rounded-[8px] p-5 max-w-[246px] h-fit ml-auto border-[1px]',
        {
          'bg-Green-50 border-Green-200': state === 'RESPONSE_RECEVIED',
          'bg-Red-50 border-Red-200': state === 'REQUEST_DECLINED',
          'bg-Yellow-50 border-Yellow-200': state === 'AWAITING_RESPONSE',
          'bg-Blue-100 border-Blue-500': state === 'REQUEST_PHONE_REF',
          'bg-Gray-50 border-Gray-200': status === null,
        },
      )}>
      <span
        className={CN(
          'flex w-max flex-row items-center border-[1px] rounded-[10px] px-2.5 py-.ml-2 mb-5',
          {
            'bg-Green-100 border-Green-500': state === 'RESPONSE_RECEVIED',
            'bg-Red-100 border-Red-500': state === 'REQUEST_DECLINED',
            'bg-Yellow-100 border-Yellow-500': state === 'AWAITING_RESPONSE',
            'bg-Blue-100 border-Blue-500': state === 'REQUEST_PHONE_REF',
            'bg-Gray-100 border-Gray-500': state === null,
          },
        )}>
        <span
          className={CN('w-[8px] h-[8px] rounded-full mr-2', {
            'bg-Green-500': state === 'RESPONSE_RECEVIED',
            'bg-Red-500': state === 'REQUEST_DECLINED',
            'bg-Yellow-500': state === 'AWAITING_RESPONSE',
            'bg-Blue-500': state === 'REQUEST_PHONE_REF',
            'bg-Gray-500': state === null || state === 'NOT_SENT',
          })}
        />

        <span className='text-extra-small text-Gray-800 font-Medium'>
          {state === null ? 'Not Sent' : RefCheckStateToTextEnum[state]}
        </span>
      </span>

      <div className='flex mt-2.5 justify-between'>
        <div className='flex flex-col min-w-max'>
          <span className='text-small text-Gray-500 leading-3 mb-2'>Sent:</span>

          <span className='text-small font-SemiBold'>
            {sentDate ? moment(sentDate).format('DD/MM/YYYY') : 'Pending'}
          </span>
        </div>

        <div className='flex flex-col'>
          <span className='text-small text-Gray-500 leading-3 mb-2'>
            {status === 'Failed' ? 'Declined:' : 'Responded:'}
          </span>

          <span className='text-small font-SemiBold text-Gray-800'>
            {updatedDate ? moment(updatedDate).format('DD/MM/YYYY') : 'Pending'}
          </span>
        </div>
      </div>
    </div>
  )
}

CandidateRefereeStatusCard.defaultProps = {}

export default CandidateRefereeStatusCard
