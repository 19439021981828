import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddMedicalIncidentInterface {
  candidateId: string
  incidentDetails: any
  incidentAttachments: any
  employeeClaimAttachments: any
  cocTypeIdAttachments: any
  medicalReportAttachments: any
  otherTypeIdtAttachments: any
  restrictions: any
}

export const useAddMedicalIncident = () => {
  const mutate = useMutation(
    ({
      candidateId,
      incidentDetails,
      incidentAttachments,
      employeeClaimAttachments,
      cocTypeIdAttachments,
      medicalReportAttachments,
      otherTypeIdtAttachments,
      restrictions,
    }: AddMedicalIncidentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', incidentDetails?.categoryId)
      bodyFormData.append('LocationId', incidentDetails?.locationId)
      bodyFormData.append('Title', incidentDetails?.title)

      incidentDetails?.date &&
        bodyFormData.append('Date', incidentDetails?.date)

      incidentDetails?.validFrom &&
        bodyFormData.append('ValidFrom', incidentDetails?.validFrom)

      incidentDetails?.expiryOn &&
        bodyFormData.append('ExpiryOn', incidentDetails?.expiryOn)

      bodyFormData.append('Notes', incidentDetails?.notes)
      bodyFormData.append('AuditRequired', incidentDetails?.auditCheck)
      bodyFormData.append('IsWorkCover', incidentDetails?.isWorkCover)
      incidentDetails?.isWorkCover &&
        bodyFormData.append('IsNotify', incidentDetails?.isNotify)

      restrictions?.map((item: any) => {
        bodyFormData.append('Restrictions', item)
      })

      incidentAttachments?.map((item) => {
        bodyFormData.append('IncidentAttachments', item)
      })

      bodyFormData.append('IncidentTypeId', incidentDetails?.incidentTypeId)

      medicalReportAttachments?.length > 0 &&
        medicalReportAttachments?.map((item) => {
          bodyFormData.append('MedicalReportAttachments', item)
        })

      incidentDetails?.isWorkCover &&
        bodyFormData.append(
          'MedicalReportTypeId',
          incidentDetails?.medicalReportTypeId,
        )

      employeeClaimAttachments?.length > 0 &&
        employeeClaimAttachments?.map((item) => {
          bodyFormData.append('EmployeeClaimAttachments', item)
        })

      incidentDetails?.isWorkCover &&
        bodyFormData.append(
          'EmployeeClaimTypeId',
          incidentDetails?.employeeClaimTypeId,
        )

      otherTypeIdtAttachments?.length > 0 &&
        otherTypeIdtAttachments?.map((item) => {
          bodyFormData.append('OtherAttachments', item)
        })

      incidentDetails?.isWorkCover &&
        bodyFormData.append('OtherTypeId', incidentDetails?.otherTypeId)

      cocTypeIdAttachments?.length > 0 &&
        cocTypeIdAttachments?.map((item) => {
          bodyFormData.append('COCAttachments', item)
        })

      incidentDetails?.isWorkCover &&
        bodyFormData.append('COCTypeId', incidentDetails?.cocTypeId)

      return postAPIwithFormData(
        `/facade/Candidate/${candidateId}/Incident`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddMedicalIncident
