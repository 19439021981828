import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditClientLocationProps {
  LocationId?: any
  clientId?: any
  clientLocationDetails?: any
}

export const useEditClientLocation = () => {
  const mutate = useMutation(
    ({
      clientLocationDetails,
      clientId,
      LocationId,
    }: EditClientLocationProps) => {
      /** Backend Issue : sending non usable attribute line two and city. refuse to remove . told send empty string to lineTwo
       * and use suburb value for city.
       */

      return putAPI(`/facade/Client/${clientId}/Location/${LocationId}`, {
        primaryContactId: clientLocationDetails?.primaryContactPerson,
        name: clientLocationDetails?.name,
        address: {
          lineOne: clientLocationDetails?.streetName,
          lineTwo: '',
          city: clientLocationDetails?.suburb,
          suburb: clientLocationDetails?.suburb,
          state: clientLocationDetails?.state,
          stateCode: clientLocationDetails?.stateCode,
          country: clientLocationDetails?.country,
          countryCode: clientLocationDetails?.countryCode,
          postCode: clientLocationDetails?.postCode,
          latitude:
            clientLocationDetails?.latitude !== ''
              ? clientLocationDetails?.latitude
              : 0,
          longitude:
            clientLocationDetails?.longitude !== ''
              ? clientLocationDetails?.longitude
              : 0,
        },
        phoneNumber: clientLocationDetails?.phoneNumber,
        email: clientLocationDetails?.email,
      })
    },
  )

  return mutate
}

export default useEditClientLocation
