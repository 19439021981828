import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addClientProps {
  clientDetails?: any
}

export const useAddClient = () => {
  const mutate = useMutation(({ clientDetails }: addClientProps) => {
    return postAPI(`/Client`, {
      name: clientDetails.name,
      about: clientDetails.about,
      website: clientDetails.website,
      abn: clientDetails.abn,
      industry: clientDetails.industry,
      size: clientDetails.size,
      status: '',
      source: clientDetails.source,
      isActive: clientDetails.isActive,
    })
  })

  return mutate
}

export default useAddClient
