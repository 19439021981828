import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCandidateVaccinationListById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/facade/Candidate/${reqBody.Id}/Vaccination`)
  return res
}

export const useGetCandidateVaccinationListById = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(
    ['CandidateVaccinationList', reqBody],
    getCandidateVaccinationListById,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
