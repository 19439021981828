/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddSuburb,
  useGetStateList,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail } from 'utils'

export interface EditAndDeleteAgencyLocationProps {
  buttonTitle: string
  footerAddButton?: () => void | undefined
  footerCancelButton?: (e: any) => void | undefined
  footerUpdateButton?: () => void | undefined
  headerCloseButton?: () => void | undefined
  headerTitle: string
  isButtonDisabled?: boolean | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const EditAndDeleteAgencyLocation: FC<
  EditAndDeleteAgencyLocationProps
> = ({
  addLocationModalShow,
  buttonTitle,
  countryItemList,
  footerCancelButton,
  footerDeleteButton,
  footerUpdateButton,
  headerCloseButton,
  headerTitle,
  isLoading,
  locationDetails,
  notify,
  onOverlayClick,
  selectedLocationCard,
  setLocationDetails,
  ...restProps
}: EditAndDeleteAgencyLocationProps) => {
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  useEffect(() => {
    const { id, name, address, phoneNo, email } = selectedLocationCard || {}
    setLocationDetails({
      ...locationDetails,
      locationId: id,
      locationName: name,
      streetName: address?.lineOne,
      suburb: address?.suburb,
      state: address?.state,
      stateCode: address?.stateCode,
      postCode: address?.postCode,
      latitude: address?.latitude,
      longitude: address?.longitude,
      country: address?.country,
      countryCode: address?.countryCode,
      phoneNo: phoneNo,
      email: email,
    })

    getSuburbsByStateCode(address?.stateCode)
    getStateListByCountryCode(address?.countryCode)
  }, [selectedLocationCard])

  /** API Call for get state list by country code using react-query */
  const {
    mutate: getStateListByCountryCodeMutate,
    isLoading: getStateListByCountryCodeIsLoading,
  } = useGetStateList()

  /** Process get state list */
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountryCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting state list.',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onSuccess: () => {
          // notify({
          //   alertHeader: 'New suburb added',
          //   alertBody: '',
          //   status: 'Success',
          // })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    const {
      locationName,
      streetName,
      suburb,
      state,
      country,
      postCode,
      email,
    } = locationDetails || {}
    ;(
      locationName &&
      streetName &&
      state &&
      suburb &&
      postCode &&
      country &&
      isEmail(email)
        ? false
        : true
    )
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [locationDetails])

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(locationDetails.stateCode, actionMeta?.option?.label)
    }

    setLocationDetails({
      ...locationDetails,
      suburb: newValue?.label,
    })
  }

  return (
    <RightSideModal
      isActive={addLocationModalShow}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={selectedLocationCard?.name}
      isCustomFooterLeftButton={true}
      onFooterLeftButtonClick={() => {
        footerDeleteButton && footerDeleteButton()
      }}
      footerLeftButtonIcon={<i className='ri-delete-bin-6-line'></i>}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      primaryButtonTitle={buttonTitle}
      onHeaderCloseButtonClick={() => {
        headerCloseButton && headerCloseButton()
      }}
      onFooterAddButtonClick={() => {
        footerUpdateButton && footerUpdateButton()
      }}
      onOverlayClick={onOverlayClick}
      onFooterCancelButtonClick={footerCancelButton}
      {...restProps}>
      {/* This is Right side modal */}
      <div className='w-full px-6'>
        {getStateListByCountryCodeIsLoading ||
          (isLoading && <PageLoader size='xxs' />)}
        <TextField
          className='agency-location pt-5'
          value={locationDetails.locationName}
          onChange={(e: any) => {
            setLocationDetails({
              ...locationDetails,
              locationName: e.target.value,
            })
          }}
          label='Name of this location'
          placeholder='Eg: Headquarters'
        />

        <TextField
          className='street-name pt-5'
          value={locationDetails.streetName}
          onChange={(e: any) => {
            setLocationDetails({
              ...locationDetails,
              streetName: e.target.value,
            })
          }}
          label='Street Name'
          placeholder=''
        />

        <SimpleSelect
          className='state pt-5'
          value={{
            label: locationDetails.state,
            value: locationDetails.stateCode,
          }}
          onChange={(selectedItem: any) => {
            if (locationDetails?.stateCode !== selectedItem?.value) {
              setLocationDetails({
                ...locationDetails,
                state: selectedItem.label,
                stateCode: selectedItem.value,
                suburb: '',
              })

              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          label='State'
          options={stateList}
          placeholder='Select State'
        />

        <div className='flex w-full mt-5 gap-5'>
          <CreatableInputSelect
            label='Suburb'
            className='w-2/3'
            value={
              locationDetails.suburb
                ? {
                    label: locationDetails.suburb,
                    value: locationDetails.suburb,
                  }
                : ''
            }
            options={suburbList}
            onChange={handleSuburbChange}
            isDisabled={!locationDetails.stateCode}
          />

          <TextField
            className='post-code w-1/3'
            value={locationDetails.postCode}
            onChange={(e: any) => {
              setLocationDetails({
                ...locationDetails,
                postCode: e.target.value,
              })
            }}
            label='Post Code'
            placeholder=''
          />
        </div>

        <TextField
          className='latitude pt-5'
          value={locationDetails.latitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setLocationDetails({
                ...locationDetails,
                latitude: e.target.value,
              })
            }
          }}
          label='Latitude (optional)'
          placeholder=''
        />

        <TextField
          className='longitude pt-5'
          value={locationDetails.longitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setLocationDetails({
                ...locationDetails,
                longitude: e.target.value,
              })
            }
          }}
          label='Longitude (optional)'
          placeholder=''
        />

        <SimpleSelect
          className='country pt-5'
          value={{
            label: locationDetails?.country,
            value: locationDetails?.countryCode,
          }}
          onChange={(selectedItem: any) => {
            if (locationDetails?.countryCode !== selectedItem?.value) {
              setLocationDetails({
                ...locationDetails,
                country: selectedItem.label,
                countryCode: selectedItem.value,
                state: '',
                stateCode: '',
                suburb: '',
              })
              getStateListByCountryCode(selectedItem.value)
            }
          }}
          label='Country'
          options={countryItemList}
          placeholder='Country'
        />

        <PhoneInputField
          className='phone-number pt-6'
          value={locationDetails.phoneNo}
          onChange={(number: any) => {
            setLocationDetails({
              ...locationDetails,
              phoneNo: number,
            })
          }}
          label='Phone number'
        />

        <TextField
          className='email pt-5 pb-3'
          value={locationDetails.email}
          onChange={(e: any) => {
            setLocationDetails({
              ...locationDetails,
              email: e.target.value,
            })
          }}
          label='Email'
          placeholder=''
        />
      </div>
    </RightSideModal>
  )
}

EditAndDeleteAgencyLocation.defaultProps = {
  buttonTitle: undefined,
  footerAddButton: undefined,
  footerCancelButton: undefined,
  footerUpdateButton: undefined,
  headerCloseButton: undefined,
  headerTitle: undefined,
  isButtonDisabled: false,
  onOverlayClick: undefined,
}

export default EditAndDeleteAgencyLocation
