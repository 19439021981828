import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alerts, Button, TextField } from '@labourhub/labour-hub-ds'
import { useForgetPassword } from 'framework/api/methods'

import { isEmail } from 'utils'

export interface ForgotPasswordProps {
  [x: string]: any
}

export const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const navigate = useNavigate()

  const [emailFieldValue, setEmailFieldValue] = useState('')
  const [isEmailErrorField, setIsEmailErrorField] = useState(false)
  const [showRequestSentScreen, setShowRequestSentScreen] = useState(false)
  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })

  /** forget password API Call ------> Mutate */
  const { mutate: forgetPasswordMutate, isLoading: forgetPasswordIsLoading } =
    useForgetPassword()

  /** Process the Forget password submission */
  async function forgetPassword(email: any) {
    forgetPasswordMutate(
      {
        username: email,
      },
      {
        onSuccess: () => {
          setShowRequestSentScreen(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          setAlertDetails({
            ...alertDetails,
            isActive: true,
            messageHeader: errData.messageTitle
              ? errData.messageTitle
              : 'Error',
            messageBody: errData.message ? errData.message : 'Error',
          })
        },
      },
    )
  }

  /** validate input fields */
  const validateFormInputs = () => {
    setIsEmailErrorField(isEmail(emailFieldValue) ? false : true)

    const isAllValidFields = isEmail(emailFieldValue)
    return isAllValidFields
  }

  /** handle reset password  */
  const handleResetPasswordClick = () => {
    const valid = validateFormInputs()
    /** check valid field */
    if (valid) {
      forgetPassword(emailFieldValue)
    } else {
      setAlertDetails({
        ...alertDetails,
        isActive: true,
        messageHeader: 'Invalid input',
        messageBody: 'The field contains invalid input',
      })
    }
  }

  return (
    <>
      {!showRequestSentScreen ? (
        <div className='w-[380px] flex flex-col items-center pt-16 relative'>
          {/** Heading */}
          <span className='flex w-full justify-center font-Medium text-heading-2 text-Gray-800'>
            Forgot password?
          </span>

          {/** Text */}
          <span className='flex justify-center font-Regular text-small text-Gray-700 pt-3'>
            No worries, we'll send you the reset link via mail.
          </span>

          {/** Input field with the label */}
          <TextField
            label='email'
            className='w-full pt-10'
            onChange={(event: any) => {
              setAlertDetails({
                ...alertDetails,
                isActive: false,
              })
              setEmailFieldValue(event.target.value)
            }}
            value={emailFieldValue}
            placeholder='your@email.com'
            isErrored={isEmailErrorField}
          />

          {/** Reset password button */}
          <Button
            isBlock
            className='mt-6'
            isLoading={forgetPasswordIsLoading}
            isDisabled={!emailFieldValue}
            onClick={() => {
              handleResetPasswordClick()
            }}
            theme='gradient'>
            Reset password
          </Button>

          {/** Back button with the icon */}
          <Button
            iconLeft={<i className='ri-arrow-left-line text-[14px]' />}
            className='pt-6'
            onClick={() => {
              navigate('/')
            }}
            textTheme='blue'
            theme='link'>
            Back to log in
          </Button>

          {/** Popping up the error message when the request failed */}
          {alertDetails.isActive && (
            <Alerts
              alertBody={alertDetails.messageBody}
              alertHeader={alertDetails.messageHeader}
              className='bottom-[-120px] absolute'
              status='Error'
              onCloseIconClick={() => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
              }}
            />
          )}
        </div>
      ) : (
        <div className='flex flex-col w-[448px] pt-16'>
          {/** Showing success Icon */}
          <div className='flex justify-center'>
            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Green-300 border-[6px] border-Green-100 hover:bg-Green-400 hover:border-Green-200'>
              <i className='ri-check-fill text-white text-[22px]'></i>
            </div>
          </div>

          {/** Showing email sending success message */}
          <span className='flex justify-center pt-8 font-Medium text-heading-2 text-Gray-800'>
            Reset password email sent
          </span>

          {/** Text */}
          <span className='flex justify-center pt-3 font-Regular text-small text-Gray-700 text-center'>
            {`To reset your password, we’ve sent an email to ${emailFieldValue}`}
          </span>

          {/** Back button with the icon */}
          <div className='flex justify-center pt-6'>
            <Button
              iconLeft={<i className='ri-arrow-left-line text-small' />}
              onClick={() => {
                navigate('/forget-password')
              }}
              textTheme='blue'
              theme='link'>
              Back to log in
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

ForgotPassword.defaultProps = {}

export default ForgotPassword
