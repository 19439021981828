import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface SetArchiveDocumentByIdProps {
  selectedDocumentId?: any
}

export const useSetArchiveDocumentById = () => {
  const mutate = useMutation(
    ({ selectedDocumentId }: SetArchiveDocumentByIdProps) => {
      return postAPI(`/Document/${selectedDocumentId}/Archived`, {})
    },
  )

  return mutate
}

export default useSetArchiveDocumentById
