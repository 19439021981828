/* eslint-disable no-empty-pattern */

import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getRegPackSections = async ({}: any) => {
  const res = await get(`/RegPackSection`, {})
  return res
}

export const useGetRegPackSections = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['RegPackSections', reqBody], getRegPackSections, {
    enabled: false,
    retry: 2,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
