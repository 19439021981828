import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAudioClipProps {
  refereeId?: any
}

export const useGetAudioClipByRefereeId = () => {
  const mutate = useMutation(({ refereeId }: GetAudioClipProps) => {
    return get(`/referee/${refereeId}/getaudio`, {})
  })

  return mutate
}

export default useGetAudioClipByRefereeId
