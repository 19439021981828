import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface CreateClientLogoInterface {
  Id: string
  avatarObject: string
}

export const useCreateClientLogo = () => {
  const mutate = useMutation(
    ({ Id, avatarObject }: CreateClientLogoInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Logo', avatarObject)

      return postAPIwithFormData(`/Client/${Id}/Logo`, bodyFormData, {})
    },
  )

  return mutate
}

export default useCreateClientLogo
