import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getDocumentByCandidateId = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    /** backend typing mistake of the url */
    `/Document?CadidateId=${reqBody.candidateId}&Take=${reqBody.take}&Skip=${reqBody.skip}&Search=${reqBody.search}&Type=${reqBody.type}&Category=${reqBody.category}&AuditState=${reqBody.state}&IsArchived=${reqBody.archived}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetDocumentByCandidateId = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['CandidatesDocument', reqBody], getDocumentByCandidateId, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
