/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  Button,
  Radio,
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import {
  useDeleteCandidateDocumentAttachmentById,
  useDeleteMedicalIncidentRestriction,
  useEditMedicalIncident,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'

import { CalendarInput, PageLoader } from 'components/atoms'

export interface EditMedicalIncidentProps {
  footerCancelButton?: () => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const EditMedicalIncident: FC<EditMedicalIncidentProps> = ({
  candidateId,
  fetchData,
  footerCancelButton,
  getMedicalIncidentCountById,
  getRestrictionsCountById,
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  isActive,
  medicalIncidentLocationList,
  notify,
  primaryButtonTitle,
  selectedMedicalIncidentDetails,
  setSelectedMedicalIncidentDetails,
  setShowEditMedicalIncidentDrawer,
  ...restProps
}: EditMedicalIncidentProps) => {
  const { documentCategory } = useSelector((state: any) => state.document)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** Restriction states  */
  const [restrictions, setRestrictions] = useState<any>([])

  /** upload file states */
  const [incidentAttachments, setIncidentAttachments] = useState<any>([])
  const [medicalReportAttachments, setMedicalReportAttachments] = useState<any>(
    [],
  )
  const [employeeClaimAttachments, setEmployeeClaimAttachments] = useState<any>(
    [],
  )
  const [otherTypeIdtAttachments, setOtherTypeIdtAttachments] = useState<any>(
    [],
  )
  const [cocTypeIdAttachments, setCocTypeIdAttachment] = useState<any>([])

  const [incidentDocId, setIncidentDocId] = useState<any>()
  const [medicalDocId, setMedicalDocId] = useState<any>()
  const [claimDocId, setClaimDocId] = useState<any>()
  const [otherDocId, setOtherDocId] = useState<any>()
  const [cocDocId, setCocDocId] = useState<any>()

  /** Incident details states */
  const [incidentDetails, setIncidentDetails] = useState<any>({
    categoryId: documentCategory?.id,
    id: '',
    locationId: '',
    incidentTypeId: '',
    medicalReportTypeId: '',
    employeeClaimTypeId: '',
    otherTypeId: '',
    cocTypeId: '',
    title: '',
    locationName: '',
    date: null,
    validFrom: null,
    expiryOn: null,
    restrictionsCount: '',
    notes: '',
    auditCheck: true,
    isNotify: true,
    isWorkCover: false,
  })

  useEffect(() => {
    const {
      categoryId,
      locationId,
      incidentTypeId,
      medicalReportTypeId,
      employeeClaimTypeId,
      otherTypeId,
      cocTypeId,
      title,
      locationName,
      date,
      documentByTypes,
      restrictionsCount,
      restrictions,
      note,
      isWorkCover,
    } = selectedMedicalIncidentDetails || {}

    const validFrom = documentByTypes?.filter(
      (documentByType: any) =>
        documentByType?.type === 'Certificate Of Capacity',
    )[0]?.validFrom

    const expiryOn = documentByTypes?.filter(
      (documentByType: any) =>
        documentByType?.type === 'Certificate Of Capacity',
    )[0]?.expiryOn

    const isNotify = documentByTypes?.filter(
      (documentByType: any) =>
        documentByType?.type === 'Certificate Of Capacity',
    )[0]?.isNotify

    const auditRequired = documentByTypes?.[0]?.auditRequired

    setIncidentDetails({
      ...incidentDetails,
      id: selectedMedicalIncidentDetails?.medicalIncidentId,
      categoryId: categoryId,
      locationId: locationId,
      incidentTypeId: incidentTypeId,
      medicalReportTypeId: medicalReportTypeId,
      employeeClaimTypeId: employeeClaimTypeId,
      otherTypeId: otherTypeId,
      cocTypeId: cocTypeId,
      title: title,
      locationName: locationName,
      date: date,
      restrictionsCount: restrictionsCount,
      restrictions: restrictions,
      note: note,
      validFrom: isWorkCover ? validFrom : null,
      expiryOn: isWorkCover ? expiryOn : null,
      isNotify: isNotify || false,
      isWorkCover: isWorkCover,
      auditCheck: auditRequired,
    })

    categoryId && getDocumentTypeByCategoryId(categoryId)

    selectedMedicalIncidentDetails?.documentByTypes?.map((item: any) => {
      if (item?.type === 'Incident') {
        if (item?.attachmentUrls && item?.attachmentUrls.length > 0) {
          const tempArray = item?.attachmentUrls?.map((itm) => {
            return { path: itm, isExistingFile: true }
          })

          setIncidentDocId(item?.documentId)

          setIncidentAttachments(tempArray)
        }
      }

      if (item?.type === 'Employee Claim') {
        if (item?.attachmentUrls && item?.attachmentUrls.length > 0) {
          const tempArray = item?.attachmentUrls?.map((itm) => {
            return { path: itm, isExistingFile: true }
          })

          setClaimDocId(item?.documentId)

          setEmployeeClaimAttachments(tempArray)
        }
      }

      if (item?.type === 'Certificate Of Capacity') {
        if (item?.attachmentUrls && item?.attachmentUrls.length > 0) {
          const tempArray = item?.attachmentUrls?.map((itm) => {
            return { path: itm, isExistingFile: true }
          })

          setCocDocId(item?.documentId)
          setCocTypeIdAttachment(tempArray)
        }
      }

      if (item?.type === 'Medical Report') {
        if (item?.attachmentUrls && item?.attachmentUrls.length > 0) {
          const tempArray = item?.attachmentUrls?.map((itm) => {
            return { path: itm, isExistingFile: true }
          })

          setMedicalDocId(item?.documentId)
          setMedicalReportAttachments(tempArray)
        }
      }

      if (item?.type === 'Other') {
        if (item?.attachmentUrls && item?.attachmentUrls.length > 0) {
          const tempArray = item?.attachmentUrls?.map((itm) => {
            return { path: itm, isExistingFile: true }
          })

          setOtherDocId(item?.documentId)

          setOtherTypeIdtAttachments(tempArray)
        }
      }
    })
  }, [selectedMedicalIncidentDetails, isActive])

  /** handle on drop method  */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setIncidentAttachments([...incidentAttachments, ...acceptedFiles])
    },
    [incidentAttachments],
  )

  /** on file drop file size and type validate for incident type attachments */
  const {
    getRootProps: getIncidentRootProps,
    getInputProps: getIncidentInputProps,
  } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** on file drop file size and type validate for medical report type attachments */
  const {
    getRootProps: getMedicalReportRootProps,
    getInputProps: getMedicalReportProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setMedicalReportAttachments([
        ...medicalReportAttachments,
        ...acceptedFiles,
      ])
    },
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** on file drop file size and type validate for employee claim type attachments */
  const {
    getRootProps: getEmployeeClaimRootProps,
    getInputProps: getEmployeeClaimProps,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      setEmployeeClaimAttachments([
        ...employeeClaimAttachments,
        ...acceptedFiles,
      ])
    },
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** on file drop file size and type validate for other type attachments */
  const { getRootProps: getOtherRootProps, getInputProps: getOtherProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setOtherTypeIdtAttachments([
          ...otherTypeIdtAttachments,
          ...acceptedFiles,
        ])
      },
      accept: {
        'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
      },
      maxSize: 5000000,
    })

  /** on file drop file size and type validate for coc type attachments */
  const { getRootProps: getCocRootProps, getInputProps: getCocProps } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setCocTypeIdAttachment([...cocTypeIdAttachments, ...acceptedFiles])
      },
      accept: {
        'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
      },
      maxSize: 5000000,
    })

  /** APi call for the remove existing attachment */
  const { mutate: deleteCandidateDocumentAttachmentByIdMutate } =
    useDeleteCandidateDocumentAttachmentById()

  /** Process the delete existing attachment */
  const deleteCandidateDocumentAttachmentById = (documentId, attachmentUrl) => {
    deleteCandidateDocumentAttachmentByIdMutate(
      {
        documentId,
        attachmentUrl,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })

          fetchData()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** remove uploaded incident file */
  const removeIncidentFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(incidentDocId, file?.path)
    }

    const newIncidentFiles = [...incidentAttachments]
    newIncidentFiles.splice(newIncidentFiles.indexOf(file), 1)
    setIncidentAttachments(newIncidentFiles)
  }

  /** remove uploaded medical report file */
  const removeMedicalReportFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(medicalDocId, file?.path)
    }

    const newMedicalReportFiles = [...medicalReportAttachments]
    newMedicalReportFiles.splice(newMedicalReportFiles.indexOf(file), 1)
    setMedicalReportAttachments(newMedicalReportFiles)
  }

  /** remove uploaded employee claim file */
  const removeEmployeeClaimFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(claimDocId, file?.path)
    }

    const newEmployeeClaimFiles = [...employeeClaimAttachments]
    newEmployeeClaimFiles.splice(newEmployeeClaimFiles.indexOf(file), 1)
    setEmployeeClaimAttachments(newEmployeeClaimFiles)
  }

  /** remove uploaded other file */
  const removeOtherFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(otherDocId, file?.path)
    }

    const newOtherFiles = [...otherTypeIdtAttachments]
    newOtherFiles.splice(newOtherFiles.indexOf(file), 1)
    setOtherTypeIdtAttachments(newOtherFiles)
  }

  /** remove uploaded coc file */
  const removeCocFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(cocDocId, file?.path)
    }

    const newCocFiles = [...cocTypeIdAttachments]
    newCocFiles.splice(newCocFiles.indexOf(file), 1)
    setCocTypeIdAttachment(newCocFiles)
  }

  /** uploaded incident file list item  */
  const incidentFiles = incidentAttachments.map((file) => {
    return (
      <li
        key={file.path}
        className='flex items-center justify-between w-full px-3 py-1 bg-white border rounded-md border-Gray-200'>
        <div className='flex items-center justify-start w-4/5'>
          <i className='mr-1 ri-attachment-2 text-Gray-400' />

          <span
            className={CN(
              'flex text-small font-Medium line-clamp-1 break-all',
              {
                'text-Gray-800 ': !file?.isExistingFile,
                'text-Green-600': file?.isExistingFile,
              },
            )}>
            {file.isExistingFile
              ? file.path.split('/')[2].split('_').slice(1)
              : file.path}
          </span>
        </div>

        <span
          className='flex justify-end w-1/5'
          onClick={removeIncidentFile(file)}>
          <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
        </span>
      </li>
    )
  })

  /** uploaded employee claim file list item  */
  const employeeClaimFiles = employeeClaimAttachments.map((file) => (
    <li
      key={file.path}
      className='flex items-center justify-between w-full px-3 py-1 bg-white border rounded-md border-Gray-200'>
      <div className='flex items-center justify-start w-4/5'>
        <i className='mr-1 ri-attachment-2 text-Gray-400' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span
        className='flex justify-end w-1/5'
        onClick={removeEmployeeClaimFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** uploaded coc file list item  */
  const cocFiles = cocTypeIdAttachments.map((file) => (
    <li
      key={file.path}
      className='flex items-center justify-between w-full px-3 py-1 bg-white border rounded-md border-Gray-200'>
      <div className='flex items-center justify-start w-4/5'>
        <i className='mr-1 ri-attachment-2 text-Gray-400' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span className='flex justify-end w-1/5' onClick={removeCocFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** uploaded medical report file list item  */
  const medicalReportFiles = medicalReportAttachments.map((file) => (
    <li
      key={file.path}
      className='flex items-center justify-between w-full px-3 py-1 bg-white border rounded-md border-Gray-200'>
      <div className='flex items-center justify-start w-4/5'>
        <i className='mr-1 ri-attachment-2 text-Gray-400' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span
        className='flex justify-end w-1/5'
        onClick={removeMedicalReportFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** uploaded other file list item  */
  const otherFiles = otherTypeIdtAttachments.map((file) => (
    <li
      key={file.path}
      className='flex items-center justify-between w-full px-3 py-1 bg-white border rounded-md border-Gray-200'>
      <div className='flex items-center justify-start w-4/5'>
        <i className='mr-1 ri-attachment-2 text-Gray-400' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span className='flex justify-end w-1/5' onClick={removeOtherFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setIncidentDetails({
            ...incidentDetails,
            categoryId: documentCategory?.id,
            incidentTypeId: successData?.documentTypes[0]?.id,
            employeeClaimTypeId: successData?.documentTypes[1]?.id,
            cocTypeId: successData?.documentTypes[2]?.id,
            medicalReportTypeId: successData?.documentTypes[3]?.id,
            otherTypeId: successData?.documentTypes[4]?.id,
          })
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    documentCategory && getDocumentTypeByCategoryId(documentCategory?.value)
  }, [documentCategory?.id, isActive])

  /** API Handling request for medical incident restriction delete */
  const { mutate: deleteMedicalIncidentRestrictionMutate } =
    useDeleteMedicalIncidentRestriction()

  /** Process for delete medical incidents */
  async function deleteMedicalIncidentRestriction(restrictionName: string) {
    deleteMedicalIncidentRestrictionMutate(
      {
        candidateId: candidateId,
        medicalIncidentId: selectedMedicalIncidentDetails?.medicalIncidentId,
        restrictionName: restrictionName,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setIncidentDetails({
            ...incidentDetails,
            restrictions: incidentDetails.restrictions.filter(
              (restriction) => restriction !== restrictionName,
            ),
          })
          fetchData()
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting medical incident!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (restrictions.filter((restriction) => restriction === '').length > 0) {
      setIsPrimaryButtonDisabled(true)
      return
    }

    const { title, date, locationName, validFrom, expiryOn, isWorkCover } =
      incidentDetails || {}
    title &&
    date &&
    locationName &&
    incidentFiles?.length > 0 &&
    (!isWorkCover ||
      (isWorkCover &&
      medicalReportFiles?.length > 0 &&
      employeeClaimFiles?.length > 0 &&
      cocFiles?.length > 0 &&
      validFrom &&
      expiryOn
        ? true
        : false))
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [
    incidentDetails,
    incidentAttachments,
    medicalReportAttachments,
    employeeClaimAttachments,
    cocTypeIdAttachments,
    restrictions,
  ])

  const handleAddRestrictions = () => {
    setRestrictions((prev) => [...prev, ''])
  }

  const handleRemoveFields = (index) => {
    deleteMedicalIncidentRestriction(incidentDetails.restrictions[index])
  }

  const handleRemoveNewFields = (index) => {
    setRestrictions(restrictions.filter((restriction, i) => i !== index))
  }

  /** edit medical incident */
  const {
    mutate: editMedicalIncidentMutate,
    isLoading: editMedicalConditionIsLoading,
  } = useEditMedicalIncident()

  /** Process the edit document */
  async function editMedicalIncident() {
    editMedicalIncidentMutate(
      {
        candidateId,
        incidentDetails: incidentDetails,
        incidentAttachments,
        medicalReportAttachments,
        employeeClaimAttachments,
        otherTypeIdtAttachments,
        cocTypeIdAttachments,
        restrictions,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            status: 'Success',
          })
          fetchData()
          setIncidentAttachments([])
          setEmployeeClaimAttachments([])
          setCocTypeIdAttachment([])
          setOtherTypeIdtAttachments([])
          setMedicalReportAttachments([])
          setRestrictions([])
          setShowEditMedicalIncidentDrawer(false)
          getRestrictionsCountById()
          getMedicalIncidentCountById()
        },
        onError: ({ response: { data: errData } }: any) => {
          if (errData?.message?.includes('Infrastructure :')) {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message.split(':').slice(1),
              status: 'Error',
            })
          } else {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  /** reset input fields */
  const resetInputFields = () => {
    setIncidentDetails({
      ...incidentDetails,
      candidateId: '',
      locationId: '',
      incidentTypeId: '',
      employeeClaimTypeId: '',
      cocTypeId: '',
      title: '',
      medicalReportTypeId: '',
      otherTypeId: '',
      locationName: '',
      date: null,
      validFrom: null,
      expiryOn: null,
      notes: '',
      auditCheck: true,
      isNotify: true,
      isWorkCover: false,
      incidentLocationItem: '',
    })

    setIncidentAttachments([])
    setEmployeeClaimAttachments([])
    setCocTypeIdAttachment([])
    setOtherTypeIdtAttachments([])
    setMedicalReportAttachments([])
    setRestrictions([])
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        headerCloseButton && headerCloseButton()
        setSelectedMedicalIncidentDetails(null)
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        editMedicalIncident()
      }}
      onFooterCancelButtonClick={() => {
        footerCancelButton && footerCancelButton()
        setSelectedMedicalIncidentDetails(null)
        resetInputFields()
      }}
      {...restProps}>
      {/** drawer loader */}
      {editMedicalConditionIsLoading && <PageLoader size='xxs' />}

      {
        <div className='w-full px-6 pt-5'>
          <TextField
            className='document-title'
            value={incidentDetails.title}
            onChange={(e: any) => {
              setIncidentDetails({
                ...incidentDetails,
                title: e.target.value,
              })
            }}
            label='Incident'
            placeholder=''
          />

          <div className='flex flex-col pt-3'>
            <span className='text-small text-Gray-800 font-Medium'>
              Incident Date
            </span>

            <CalendarInput
              value={incidentDetails?.date}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setIncidentDetails({
                    ...incidentDetails,
                    date: date,
                  })
                }
              }}
            />

            <SimpleSelect
              label='Incident Location'
              placeholder='Select Incident Location'
              value={{
                label: incidentDetails?.locationName,
                value: incidentDetails?.locationId,
              }}
              className='w-full py-5'
              onChange={(selectedItem: any) => {
                setIncidentDetails({
                  ...incidentDetails,
                  locationName: selectedItem?.label,
                  locationId: selectedItem?.value,
                })
              }}
              options={medicalIncidentLocationList}
            />

            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                Incident Report
              </span>
            </div>

            <div className='flex flex-col pt-5 pb-3'>
              <span className='font-Medium text-small text-Gray-800'>
                Attach Incident Report
              </span>

              <span className='font-Regular text-extra-small text-Gray-600'>
                Max file size 5MB - supports docx, pdf, jpg, jpeg and png,
                multiple uploads
              </span>
            </div>

            {/* Upload Document section ---------------------------------------------------------------- */}
            <section className='container pb-5'>
              <div {...getIncidentRootProps({ className: 'dropzone' })}>
                <input {...getIncidentInputProps()} />

                <div className='flex flex-col'>
                  <div className='flex items-center justify-center py-2 mt-1 bg-white border rounded-md text-Cobalt-600 border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                    <i className='pr-2 ri-upload-2-line' />

                    <span className='text-small font-Medium'>Upload File</span>
                  </div>
                </div>
              </div>

              {incidentFiles.length > 0 && (
                <div className='flex w-full mt-2'>
                  <ul className='flex flex-col w-full gap-2'>
                    {incidentFiles}
                  </ul>
                </div>
              )}
            </section>

            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                Restrictions
              </span>
            </div>

            {/** Already existing restrictions input field */}
            {incidentDetails.restrictionsCount > 0 &&
              incidentDetails?.restrictions?.map((itm: any, index: any) => (
                <div key={index} className='flex pt-5'>
                  <TextField
                    isDisabled={true}
                    id={`restrictions-${index}`}
                    className='document-title w-[418px]'
                    value={itm}
                    onChange={() => {
                      //disabled
                    }}
                    placeholder=''
                    label='Restriction'
                  />

                  <div
                    className=' flex justify-center pl-[14px] pt-8'
                    onClick={() => handleRemoveFields(index)}>
                    <i className='ri-close-fill text-heading-5 text-Red-600'></i>
                  </div>
                </div>
              ))}

            <div className='pb-5'>
              {restrictions?.map((item: any, index: any) => {
                return (
                  <div key={index} className='flex pt-5'>
                    <TextField
                      className='document-title w-[418px]'
                      value={item?.restrictions}
                      onChange={(e: any) => {
                        const tempArray = [...restrictions]
                        tempArray[index] = e.target.value
                        setRestrictions(tempArray)
                      }}
                      placeholder=''
                      label='Restriction'
                    />

                    <div
                      className=' flex justify-center pl-[14px] pt-8'
                      onClick={() => handleRemoveNewFields(index)}>
                      <i className='ri-close-fill text-heading-5 text-Red-600'></i>
                    </div>
                  </div>
                )
              })}
              <div className='pt-5'>
                <Button
                  className='w-[162px]'
                  textTheme='blue'
                  theme='white'
                  iconLeft={<i className='ri-add-circle-line' />}
                  onClick={() => {
                    handleAddRestrictions()
                  }}>
                  Add Restriction
                </Button>
              </div>
            </div>

            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                Work Cover Claim
              </span>
            </div>

            <span className='pt-5 font-Medium text-small text-Gray-800'>
              Is this a work cover claim?
            </span>

            <div className='flex pt-[10px] gap-6'>
              <Radio
                checked={incidentDetails?.isWorkCover}
                id='yes'
                className='cursor-pointer select-none'
                onRadioClick={() => {
                  setIncidentDetails({
                    ...incidentDetails,
                    isWorkCover: true,
                  })
                }}
                name='workCoverYes'
                helperText=''
                labelText='Yes'
              />

              <Radio
                className={CN('select-none', {
                  'cursor-pointer':
                    !selectedMedicalIncidentDetails?.isWorkCover,
                  'cursor-not-allowed':
                    selectedMedicalIncidentDetails?.isWorkCover,
                })}
                disabled={selectedMedicalIncidentDetails?.isWorkCover}
                checked={!incidentDetails?.isWorkCover}
                id='no'
                onRadioClick={() => {
                  setIncidentDetails({
                    ...incidentDetails,
                    isWorkCover: false,
                  })
                }}
                name='workCoverNo'
                helperText=''
                labelText='No'
              />
            </div>

            {incidentDetails.isWorkCover && (
              <div className='flex flex-col pt-5'>
                <div className='flex flex-col'>
                  <span className='font-Medium text-small text-Gray-800'>
                    Attach Incident Report
                  </span>

                  <span className='pb-3 font-Regular text-extra-small text-Gray-600'>
                    Max file size 5MB - supports docx, pdf, jpg, jpeg and png,
                    multiple uploads
                  </span>
                </div>

                <section className='container'>
                  <div
                    {...getMedicalReportRootProps({ className: 'dropzone' })}>
                    <input {...getMedicalReportProps()} />

                    <div className='flex flex-col'>
                      <span className='text-small text-Gray-800 font-Medium'>
                        Medical Report
                      </span>

                      <div className='flex items-center justify-center py-2 mt-1 bg-white border rounded-md text-Cobalt-600 border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                        <i className='pr-2 ri-upload-2-line' />

                        <span className='text-small font-Medium'>
                          Upload File
                        </span>
                      </div>
                    </div>
                  </div>

                  {medicalReportFiles.length > 0 && (
                    <div className='flex w-full mt-2'>
                      <ul className='flex flex-col w-full gap-2'>
                        {medicalReportFiles}
                      </ul>
                    </div>
                  )}
                </section>

                <section className='container'>
                  <div
                    {...getEmployeeClaimRootProps({ className: 'dropzone' })}>
                    <input {...getEmployeeClaimProps()} />

                    <div className='flex flex-col pt-5'>
                      <span className='text-small text-Gray-800 font-Medium'>
                        Employee Claim Form
                      </span>

                      <div className='flex items-center justify-center py-2 mt-1 bg-white border rounded-md text-Cobalt-600 border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                        <i className='pr-2 ri-upload-2-line' />

                        <span className='text-small font-Medium'>
                          Upload File
                        </span>
                      </div>
                    </div>
                  </div>

                  {employeeClaimFiles.length > 0 && (
                    <div className='flex w-full mt-2'>
                      <ul className='flex flex-col w-full gap-2'>
                        {employeeClaimFiles}
                      </ul>
                    </div>
                  )}
                </section>

                <section className='container border-b-[2px] border-Gray-200 pb-5'>
                  <div {...getOtherRootProps({ className: 'dropzone' })}>
                    <input {...getOtherProps()} />

                    <div className='flex flex-col pt-5'>
                      <span className='text-small text-Gray-800 font-Medium'>
                        Other Documents
                      </span>

                      <div className='flex items-center justify-center py-2 mt-1 bg-white border rounded-md text-Cobalt-600 border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                        <i className='pr-2 ri-upload-2-line' />

                        <span className='text-small font-Medium'>
                          Upload File
                        </span>
                      </div>
                    </div>
                  </div>

                  {otherFiles.length > 0 && (
                    <div className='flex w-full mt-2'>
                      <ul className='flex flex-col w-full gap-2'>
                        {otherFiles}
                      </ul>
                    </div>
                  )}
                </section>

                <section className='container'>
                  <div {...getCocRootProps({ className: 'dropzone' })}>
                    <input {...getCocProps()} />

                    <div className='flex flex-col pt-5'>
                      <span className='text-small text-Gray-800 font-Medium'>
                        Certificate of Capacity (COC)
                      </span>

                      <div className='flex items-center justify-center py-2 mt-1 bg-white border rounded-md text-Cobalt-600 border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                        <i className='pr-2 ri-upload-2-line' />

                        <span className='text-small font-Medium'>
                          Upload File
                        </span>
                      </div>
                    </div>
                  </div>

                  {cocFiles.length > 0 && (
                    <div className='flex w-full mt-2'>
                      <ul className='flex flex-col w-full gap-2'>{cocFiles}</ul>
                    </div>
                  )}
                </section>

                <div className='flex gap-4 pt-5'>
                  <div className=''>
                    <span className='text-small text-Gray-800 font-Medium'>
                      Valid from
                    </span>

                    <CalendarInput
                      value={incidentDetails?.validFrom}
                      formatDate={(date) => format(date, 'dd/MM/yyyy')}
                      parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                      className='mt-1'
                      onChange={(date) => {
                        if (date) {
                          setIncidentDetails({
                            ...incidentDetails,
                            validFrom: date,
                          })
                        }
                      }}
                    />
                  </div>

                  <div>
                    <span className='text-small text-Gray-800 font-Medium'>
                      Expires on
                    </span>

                    <CalendarInput
                      value={incidentDetails?.expiryOn}
                      formatDate={(date) => format(date, 'dd/MM/yyyy')}
                      parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                      className='mt-1'
                      onChange={(date) => {
                        if (date) {
                          setIncidentDetails({
                            ...incidentDetails,
                            expiryOn: date,
                          })
                        }
                      }}
                    />
                  </div>
                </div>

                <Toggle
                  label='Notify me on expiry'
                  className='mt-5'
                  setValue={incidentDetails.isNotify}
                  onToggleClick={(e: any) => {
                    setIncidentDetails({
                      ...incidentDetails,
                      isNotify: e.target.checked,
                    })
                  }}
                />
              </div>
            )}

            <TextArea
              className='py-5 notes'
              value={incidentDetails.note}
              onChange={(e: any) => {
                setIncidentDetails({
                  ...incidentDetails,
                  note: e.target.value,
                })
              }}
              label='Add a Note'
              placeholder='Extra Details'
              rows={4}
            />

            <Toggle
              label='Audit Check'
              className='my-2'
              setValue={incidentDetails?.auditCheck}
              onToggleClick={(e: any) => {
                setIncidentDetails({
                  ...incidentDetails,
                  auditCheck: e.target.checked,
                })
              }}
            />
          </div>
        </div>
      }
    </RightSideModal>
  )
}

EditMedicalIncident.defaultProps = {
  footerCancelButton: undefined,
  headerCloseButton: undefined,
}

export default EditMedicalIncident
