/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface AgencyProfileCardProps {
  abn?: string | undefined
  industry?: string | undefined
  isImage?: boolean
  isSecondaryImage?: boolean
  logoUrl?: string | undefined
  name?: string | undefined
  noOfClients?: number | undefined
  onBasicInfoEditClick?: () => void | undefined
  onChangePhotoClick?: () => void | undefined
  website?: string | undefined
  [x: string]: any
}

export const AgencyProfileCard: FC<AgencyProfileCardProps> = ({
  abn,
  agencyProfileDetails,
  industry,
  isImage,
  isSecondaryImage,
  logoUrl,
  name,
  noOfClients,
  onBasicInfoEditClick,
  onChangePhotoClick,
  setIsSecondaryLogoModalOpen,
  website,
  ...restProps
}: AgencyProfileCardProps) => {
  const navigate = useNavigate()

  const [optionDropDown, setOptionDropDown] = useState(false)

  return (
    <div
      className='flex flex-col w-[314px] h-fit border-[1px] border-Gray-200 rounded-md bg-white'
      {...restProps}>
      <div className='flex justify-end pt-5 pr-4 gap-2'>
        <div className='pl-[92px] relative'>
          {/** Agency Logo Section */}
          <div className='flex justify-center items-center w-[132px] h-[132px] rounded-full object-cover overflow-hidden'>
            {isImage ? (
              <img src={logoUrl} alt='' className='w-full object-contain' />
            ) : (
              <div className='flex justify-center items-center rounded-full overflow-hidden w-[132px] h-[132px] bg-Gray-200'>
                <i className='ri-image-2-fill text-[100px] text-Gray-400'></i>
              </div>
            )}

            <div className='pl-[84px] pt-[84px] absolute'>
              {/** Agency Logo Edit Button */}
              <div
                className='flex justify-center items-center w-10 h-10 rounded-full shadow-md bg-white cursor-pointer'
                onClick={() => {
                  setOptionDropDown(!optionDropDown)
                }}>
                <i className='ri-pencil-line text-Cobalt-500 text-heading-5' />
              </div>
            </div>
          </div>

          {/** Logo changing and removing option */}
          {optionDropDown && (
            <div
              className={CN(
                'flex flex-col justify-start  shadow-md border-[2px] border-Gray-100 rounded-md py-1 z-40 bg-white absolute ',
                {
                  'w-[230px] left-2': isSecondaryImage,
                  'w-[200px] left-6': !isSecondaryImage,
                },
              )}>
              {!isImage && (
                <div
                  className='cursor-pointer py-2 px-3 hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(false)
                  }}>
                  Add Primary Photo
                </div>
              )}

              {isImage && (
                <div
                  className='cursor-pointer py-2 px-3 hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(false)
                  }}>
                  Change Primary Photo
                </div>
              )}

              {!isSecondaryImage && (
                <div
                  className='cursor-pointer py-2 px-3 hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(true)
                  }}>
                  Add Secondary Photo
                </div>
              )}

              {isSecondaryImage && (
                <div
                  className='cursor-pointer py-2 px-3 hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(true)
                  }}>
                  Change Secondary Photo
                </div>
              )}
            </div>
          )}
        </div>

        <div className='edit-button'>
          <Button
            iconLeft={
              <i className='ri-edit-line text-extra-small text-Cobalt-600' />
            }
            size='sm'
            theme='white'
            onClick={() => {
              onBasicInfoEditClick && onBasicInfoEditClick()
            }}
            textTheme='blue'>
            Edit
          </Button>
        </div>
      </div>

      {/** Agency Name */}
      <span className='flex justify-center w-full px-4 pt-[10px] font-Bold text-heading-3 text-Gray-800 break-all'>
        {name}
      </span>

      {/** ABN  */}
      <div className='flex justify-between px-4 pt-6'>
        <div className='font-Regular text-small text-Gray-600'>ABN</div>

        <div className='font-SemiBold text-small text-Gray-600'>{abn}</div>
      </div>

      {/** Industry */}
      <div className='flex justify-between px-4 pt-3'>
        <div className='font-Regular text-small text-Gray-600'>Industry</div>

        <div className='font-SemiBold text-small text-Gray-600'>{industry}</div>
      </div>

      {/** Website */}
      <div className='flex justify-between px-4 pt-3'>
        <div className='font-Regular text-small text-Gray-600 mr-12'>
          Website
        </div>

        <a
          href={`//${agencyProfileDetails.website}`}
          target='_blank'
          className='font-SemiBold text-small text-Cobalt-500 w-[full] line-clamp-1 break-words'
          data-tip={agencyProfileDetails.website}
          data-for='WebSite'>
          {website}
        </a>
        <ReactTooltip
          place='top'
          id='WebSite'
          effect='solid'
          textColor='#FFFFFF'
          backgroundColor='#4B5563'
        />
      </div>

      {/** Count of clients */}
      <div className='flex justify-between px-4 pt-3 pb-[34px]'>
        <div className='font-Regular text-small text-Gray-600'>
          No of Clients
        </div>

        <div
          className='font-SemiBold text-small text-Cobalt-500 cursor-pointer hover:text-Cobalt-600 select-none'
          onClick={() => {
            navigate(`/client-list`)
          }}>
          {noOfClients}
        </div>
      </div>
    </div>
  )
}

AgencyProfileCard.defaultProps = {
  abn: undefined,
  industry: undefined,
  isImage: undefined,
  isSecondaryImage: undefined,
  logoUrl: undefined,
  name: undefined,
  noOfClients: undefined,
  onBasicInfoEditClick: undefined,
  onChangePhotoClick: undefined,
  website: undefined,
}

export default AgencyProfileCard
