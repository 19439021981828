import React, { FC } from 'react'
import empty from 'assets/animations/empty.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface DashboardProps {
  [x: string]: any
}

export const Dashboard: FC<DashboardProps> = ({
  className,
  ...restProps
}: DashboardProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: empty,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  const DashboardClasses = CN(
    'dashboard flex items-center justify-center p-5',
    className,
    {},
  )

  return (
    <div className={DashboardClasses} {...restProps}>
      <div className='flex flex-col w-[350px] pt-[40px]'>
        <AnimatedView />

        <span className='font-Medium text-heading-2 text-Gray-500 pl-[60px]'>
          No Data Found!...
        </span>
      </div>
    </div>
  )
}

Dashboard.defaultProps = {}

export default Dashboard
