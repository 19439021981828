import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCandidateMedicalConditionListById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/facade/Candidate/${reqBody.Id}/MedicalCondition`)
  return res
}

export const useGetCandidateMedicalConditionListById = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(
    ['CandidateMedicalConditionList', reqBody],
    getCandidateMedicalConditionListById,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
