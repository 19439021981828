import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditFinancialInformationProps {
  candidateId?: any
  updatedFinancialDetails?: any
}

export const useEditFinancialInformation = () => {
  const mutate = useMutation(
    ({
      updatedFinancialDetails,
      candidateId,
    }: EditFinancialInformationProps) => {
      const {
        bankAccountId,
        accountName,
        bankId,
        bsbNumber,
        accountNumber,
        hasSuper,
        superFundId,
        superProviderId,
        superNameId,
        membershipNumber,
        abn,
        usi,
        countryCode,
      } = updatedFinancialDetails || {}

      return putAPI(`/facade/DocumentAudit/FinacialDetails`, {
        cId: candidateId,
        bankAccountId,
        accountName,
        bankId,
        bsbNumber,
        accountNumber,
        hasSuper,
        superFundId: hasSuper ? superFundId : null,
        superProviderId: hasSuper ? superProviderId : null,
        superNameId: hasSuper ? superNameId : null,
        membershipNumber: hasSuper ? membershipNumber : null,
        abn: hasSuper ? abn : null,
        usi: hasSuper ? usi : null,
        countryCode: countryCode ? countryCode : null,
      })
    },
  )

  return mutate
}

export default useEditFinancialInformation
