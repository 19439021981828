import { createSlice } from '@reduxjs/toolkit'
import { MainMenuTypes, menuItems } from 'static-data/mainSideMenu'

const initialState = {
  initialMenu: menuItems as MainMenuTypes,
  menuItems: menuItems as MainMenuTypes,
  currentRoute: '' as string,
  toggleMenu: false,
}

const sideMenuReducer = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    resetToInitialState(state) {
      state.initialMenu = menuItems as MainMenuTypes
      state.menuItems = menuItems as MainMenuTypes
      state.currentRoute = ''
      state.toggleMenu = false
    },
    setMenuState(state, { payload }) {
      state.menuItems = payload
    },
    setToggleMenuState(state, { payload }) {
      state.toggleMenu = payload
    },
    setCurrentRouteState(state, { payload }) {
      state.currentRoute = payload
    },
  },
})

export const {
  setMenuState,
  setCurrentRouteState,
  setToggleMenuState,
  resetToInitialState,
} = sideMenuReducer.actions

export const resetToInitial = () => (dispatch: any) => {
  dispatch(resetToInitialState())
}

export const setMenu = (sideMenuItems: any) => (dispatch: any) => {
  dispatch(setMenuState(sideMenuItems))
}

export const setToggleMenu = (sideMenuItems: any) => (dispatch: any) => {
  dispatch(setToggleMenuState(sideMenuItems))
}

export const setCurrentRoute = (currentRoute: string) => (dispatch: any) => {
  dispatch(setCurrentRouteState(currentRoute))
}

export default sideMenuReducer.reducer
