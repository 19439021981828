/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetOtherDocumentCategoryListInterface {}

export const useGetOtherDocumentCategoryList = () => {
  const mutate = useMutation(({}: useGetOtherDocumentCategoryListInterface) => {
    return get(`/Category?OtherDocuments=true`, {})
  })

  return mutate
}

export default useGetOtherDocumentCategoryList
