import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface userAddProps {
  userDetails?: any
}

export const useUserAdd = () => {
  const mutate = useMutation(({ userDetails }: userAddProps) => {
    return postAPI(`/identity/AssignRole`, {
      roleId: userDetails.roleId,
      roleName: userDetails.roleName,
      userId: userDetails.userId,
    })
  })

  return mutate
}

export default useUserAdd
