/* eslint-disable prefer-const */
import { useQuery } from 'react-query'
import { postAPI } from 'framework/api/http'

const getCandidateList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]

  let tempSelectedTags: any = []

  reqBody.tags?.map((item: any) => {
    tempSelectedTags.push(item.name)
  })

  const res = await postAPI(`/Candidate/List`, {
    take: reqBody.take,
    skip: reqBody.skip,
    search: reqBody.search,
    status: reqBody.status,
    suburb: reqBody.suburb,
    state: reqBody.state,
    primaryClient: reqBody.primaryClient,
    tags: tempSelectedTags,
    sortColumnName: reqBody.sortColumnName,
    sortDirection: reqBody.sortDirection,
  })

  return res.data
}

export const useGetCandidateList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Candidates', reqBody], getCandidateList, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
