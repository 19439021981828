import React, { FC, useEffect, useState } from 'react'
import { Button, RightSideModal, TextArea } from '@labourhub/labour-hub-ds'
import EmptyTable from 'assets/images/emptyTable.svg'
import { useCreateCandidateNote } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'
import { CandidateNoteDetailsCard } from 'components/molecules'

export interface AddDeleteCandidateNotesProps {
  [x: string]: any
}

export const AddDeleteCandidateNotes: FC<AddDeleteCandidateNotesProps> = ({
  isActive,
  setIsOpenNotesDrawer,
  candidateDetails,
  candidateNoteData,
  loggedUserId,
  notify,
  getCandidateNotes,
  deleteNoteModal,
  setDeleteNoteModal,
  deleteCandidateNoteIsLoading,
}: AddDeleteCandidateNotesProps) => {
  const [noteDetails, setNoteDetails] = useState({
    consultantId: '',
    body: '',
  })

  /** set consultant id to current logged user Id */
  useEffect(() => {
    setNoteDetails({
      ...noteDetails,
      consultantId: loggedUserId,
    })
  }, [loggedUserId])

  /** add candidate note */
  const {
    mutate: createCandidateNoteMutate,
    isLoading: createCandidateNoteIsLoading,
  } = useCreateCandidateNote()

  /** Process the add candidate note */
  async function addCandidateNote() {
    createCandidateNoteMutate(
      {
        Id: candidateDetails?.id,
        noteDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          getCandidateNotes(candidateDetails?.id)
          resetField()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** reset fields after add notes */
  const resetField = () => {
    setNoteDetails({
      ...noteDetails,
      body: '',
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle={`Notes - ${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      headerSubtitle='Add notes here'
      isFooterShow={false}
      primaryButtonTitle=''
      secondaryButtonTitle=''
      isPrimaryButtonDisable={false}
      onHeaderCloseButtonClick={() => {
        resetField()
        setIsOpenNotesDrawer(false)
      }}>
      {(createCandidateNoteIsLoading || deleteCandidateNoteIsLoading) && (
        <PageLoader size='xxs' />
      )}

      {/* main containers */}
      <div className='flex w-full flex-col h-fit'>
        {/* notes body container */}
        <div className='flex flex-col w-full mb-[100px] pt-5 pl-[22px] pr-6'>
          {candidateNoteData?.length > 0 ? (
            candidateNoteData?.map((item: any, idx: any) => (
              <div className='flex flex-col w-full pb-[33px]' key={idx}>
                <CandidateNoteDetailsCard
                  noteDetails={item}
                  onDeleteClick={() => {
                    setDeleteNoteModal({
                      ...deleteNoteModal,
                      show: true,
                      selectedNoteId: item?.id,
                    })
                  }}
                />
              </div>
            ))
          ) : (
            <div className='w-full flex flex-col justify-center items-center mt-5 pl-[6px] pr-1'>
              <img src={EmptyTable} alt='empty-table' className='h-[120px]' />

              <span className='text-heading-4 font-Medium text-Gray-800 pt-4'>
                No notes found.
              </span>

              <span className='font-Regular text-Gray-600 text-center pt-2'>
                Sorry! We could not find any notes. You can always add new notes
                from below
              </span>
            </div>
          )}
        </div>

        {/* notes footer container */}
        <div className='flex w-full justify-center items-center absolute bottom-0 bg-white border-t border-t-Gray-200 px-5 py-6 '>
          <TextArea
            className='w-full relative'
            textAreaClassName='h-[67px] pr-[85px] resize-none'
            value={noteDetails?.body}
            onChange={(e: any) => {
              setNoteDetails({
                ...noteDetails,
                body: e.target.value,
              })
            }}
            placeholder='Type your notes here'
          />

          <Button
            className='absolute right-0 mr-[30px]'
            theme='gradient'
            isDisabled={noteDetails?.body ? false : true}
            onClick={() => {
              addCandidateNote()
            }}>
            Save
          </Button>
        </div>
      </div>
    </RightSideModal>
  )
}

AddDeleteCandidateNotes.defaultProps = {}

export default AddDeleteCandidateNotes
