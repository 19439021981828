import { FC } from 'react'

export interface FooterProps {
  [x: string]: any
}

export const Footer: FC<FooterProps> = ({ ...restProps }: FooterProps) => {
  return (
    <div
      className='main-layout__Footer absolute bottom-0 pb-8 flex justify-center items-center'
      {...restProps}>
      {/* footer year */}
      <span className='flex items-center text-extra-small'>
        <p className='items-center text-Gray-500'>
          © {new Date().getFullYear()} Labourhub. All rights reserved.
        </p>
        <div className='flex items-center pl-2 divide-x divide-Gray-200'>
          <a className='text-Cobalt-500 pr-2' href='www.google.lk'>
            Terms and conditions
          </a>
          <a className='text-Cobalt-500 pl-2' href='www.google.lk'>
            Help
          </a>
        </div>
      </span>
    </div>
  )
}

Footer.defaultProps = {}

export default Footer
