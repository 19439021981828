import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCriminalHistoryLIstById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/Candidate/${reqBody.Id}/CriminalHistory`)
  return res
}

export const useGetCriminalHistoryLIstById = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['criminalHistory', reqBody], getCriminalHistoryLIstById, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
