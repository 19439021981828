import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCandidateDetailsByIdProps {
  Id?: any
}

export interface CandidateProfileCardType {
  id: string
  candidateNo: string
  title: string
  firstName: string
  middleName: string
  lastName: string
  status: string
  email: string
  phone: string
  address: {
    lineOne: string
    lineTwo: string
    city: string
    suburb: string
    state: string
    stateCode: string
    country: string
    countryCode: string
    postCode: string
    latitude: number
    longitude: number
  }
  avatarUrl: string
  gender: string
  dateOfBirth: string
  age: number
  mainClientId: string
  mainClient: string
  emergencyContact: null | string
  socialLinks: {
    facebook: string
    twitter: string
    linkedIn: string
  }
  joinedOn: string
  isWarning: boolean
  isMedicalNotification: boolean
  hideRoster: boolean
  showMedical: boolean
  warnings: Array<string>
  medicalNotifications: Array<string>
  regPackSendOn?: any
  regPackTempalteId?: string | null
}

export const useGetCandidateDetailsById = () => {
  const mutate = useMutation(({ Id }: GetCandidateDetailsByIdProps) => {
    return get(`/facade/Candidate/${Id}/ProfileCard`, {})
  })

  return mutate
}

export default useGetCandidateDetailsById
