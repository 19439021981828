import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetUserProfileDetailsByIdInterface {
  Id: string
}

export const useGetUserProfileDetailsById = () => {
  const mutate = useMutation(
    ({ Id }: useGetUserProfileDetailsByIdInterface) => {
      return get(`/UserProfile/${Id}`)
    },
  )

  return mutate
}

export default useGetUserProfileDetailsById
