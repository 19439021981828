import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetSuperFundByIdType {
  id?: string | null
  documentId?: string | null
  candidateId?: string | null
  superProviderId?: string | null
  superProviderName?: string | null
  superNameId?: string | null
  superName?: string | null
  membershipNumber?: string | null
  abn?: string | null
  usi?: string | null
  attachmentUrl?: string | null
  preSignedUrl?: string | null
  auditRequired?: boolean
  isSuperFundAccountAvailable?: boolean
  isSuperFundInList?: boolean
  candidateSignature?: string | null
}

export const useGetSuperFundById = (
  candidateId: string,
  documentId: string,
) => {
  return useQuery(
    ['GET_SUPER_FUND_BY_ID'],
    async () => {
      const res = await get(`/facade/Candidate/${candidateId}/SuperFund`)
      return res.data.superFundModel as GetSuperFundByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
