/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface CandidateRefereeTabEmptyStateProps {
  onClickAddNewReferee?: any | undefined
  [x: string]: any
}

export const CandidateRefereeTabEmptyState: FC<
  CandidateRefereeTabEmptyStateProps
> = ({
  setIsOpenRequestRefereeModal,
  onClickAddNewReferee,
  candidateReferees,
  ...restProps
}: CandidateRefereeTabEmptyStateProps) => {
  const RefereesTabViewClasses = CN(
    `empty-state flex flex-col w-full border-[1px] border-Gray-200 rounded-lg bg-white`,
  )

  return (
    <div className={RefereesTabViewClasses} {...restProps}>
      <i className='ri-team-line flex justify-center text-[43px] text-Gray-500 pt-[73px]'></i>

      <span className='flex justify-center font-Medium text-heading-4 text-Gray-800'>
        No referees found!
      </span>

      <span className='flex justify-center font-Regular text-small text-Gray-600 pt-2'>
        Request referee details from candidates or add them manually
      </span>

      <div className='flex justify-center gap-6 pt-6 pb-[100px]'>
        {/** Request Referee Button*/}
        {candidateReferees?.requestRefereeSentOn !== null ? null : (
          <Button
            iconLeft={<i className='ri-send-plane-line' />}
            onClick={() => {
              setIsOpenRequestRefereeModal(true)
            }}>
            Request Referees
          </Button>
        )}

        {/** Add New Referee Button */}
        <Button
          iconLeft={<i className='ri-add-fill' />}
          textTheme='blue'
          theme='white'
          onClick={() => {
            onClickAddNewReferee && onClickAddNewReferee()
          }}>
          Add New Referee
        </Button>
      </div>
    </div>
  )
}

CandidateRefereeTabEmptyState.defaultProps = {
  onClickAddNewReferee: undefined,
}

export default CandidateRefereeTabEmptyState
