/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import educationIcon from 'assets/images/EducationIcon.png'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface CandidateEducationCardProps {
  onAddNewClick?: any | undefined
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  [x: string]: any
}

export const CandidateEducationCard: FC<CandidateEducationCardProps> = ({
  candidateEducationList,
  onAddNewClick,
  onDeleteClick,
  onEditClick,
  setSelectedDocumentDetails,
  getDocumentSignedUrlsById,
  isLoading,
  setDocumentTitles,
  setIsShowDocumentViewModal,
  setShowEducationMoreButtonOption,
  setShowLicenseMoreButtonOption,
  showEducationMoreButtonOption,
  ...restProps
}: CandidateEducationCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  return (
    <div
      className='w-1/2 h-fit border-[1px] bg-white border-Gray-200 rounded-md pb-9 relative'
      {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      {/** Heading and ADD Button =========================================>>>>> */}
      <div className='flex justify-between pt-[19px] pl-5 pr-4'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Education
        </span>

        <Button
          onClick={onAddNewClick}
          textTheme='blue'
          size='sm'
          theme='white'>
          Add New
        </Button>
      </div>

      <div className='flex flex-col'>
        {candidateEducationList?.length > 0 &&
          candidateEducationList?.map((itm: any, index: any) => (
            <div
              className='flex justify-between items-start px-6 pt-4'
              key={index}>
              <div className='flex'>
                <div className='flex justify-center items-center w-10 h-10 rounded-full bg-Cobalt-50'>
                  <img src={educationIcon} alt='' className='w-7 h-4' />
                </div>

                <div className='pl-4'>
                  <div className='flex flex-col'>
                    {/** Document Type =========================================>>>>>>>>> */}
                    <div className='flex'>
                      <span className='font-SemiBold text-Gray-800 max-w-[300px] line-clamp-1'>
                        {itm?.title}
                      </span>

                      {/** Document Status with react tooltip ======================>>>>>>>*/}
                      {itm?.status && (
                        <DocumentStatusIcon
                          status={itm?.status}
                          auditRequired={itm?.auditRequired}
                        />
                      )}
                    </div>

                    {/** Uploaded Date ==================================>>>>>>> */}
                    <span className='font-Medium text-extra-small text-Gray-500 pt-[6px]'>
                      Uploaded:{' '}
                      {itm?.createdOn
                        ? dateToReadableString(itm?.createdOn)
                        : ' --- '}
                    </span>
                  </div>
                </div>
              </div>

              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowLicenseMoreButtonOption({
                      index: index,
                    })
                    setShowEducationMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showEducationMoreButtonOption.index
                          ? !showEducationMoreButtonOption.isEnable
                          : true,
                    })
                    /** set selected item details to state for edit and delete*/
                    setSelectedDocumentDetails(itm)
                  }}
                />

                {/** More button DropDown =================================================>>>>>>*/}
                {showEducationMoreButtonOption.isEnable &&
                  index === showEducationMoreButtonOption.index && (
                    <div className='flex flex-col absolute right-0 top-[42px] w-[169px] pt-2 bg-white border border-Gray-200 shadow-lg  shadow-Gray-200 rounded-md cursor-pointer z-40'>
                      <span
                        className={CN('font-Regular text-Gray-800 pl-3 mb-4', {
                          'opacity-50 hover:bg-Gray-white':
                            itm?.attachmentTypeSignedUrls?.length === 0,
                          'hover:bg-Gray-100':
                            itm?.attachmentTypeSignedUrls?.length > 0,
                        })}
                        onClick={(e: any) => {
                          if (itm?.attachmentTypeSignedUrls?.length > 0) {
                            e.stopPropagation()
                            getDocumentSignedUrlsById(itm?.documentId)
                            setIsShowDocumentViewModal(true)
                            setDocumentTitles({
                              license: '',
                              education: itm?.title,
                            })
                            setShowEducationMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })
                          }
                        }}>
                        View Attachments
                      </span>
                      <span
                        className='font-Regular text-Gray-800 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowEducationMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onEditClick && onEditClick()
                        }}>
                        Edit
                      </span>

                      <span
                        className={CN('font-Regular text-Gray-800 pl-3 mb-4', {
                          'hover:bg-Gray-100':
                            (itm?.auditRequired &&
                              itm?.status === 'Request_Submission') ||
                            itm?.status === 'Pending_Audit',

                          'opacity-50 hover:bg-Gray-white':
                            !itm?.auditRequired ||
                            itm?.status === 'Active' ||
                            itm?.status === 'Audit_Failed' ||
                            itm?.status === 'Expired' ||
                            itm?.status === 'Archived' ||
                            (!itm?.auditRequired &&
                              itm?.status === 'Request_Submission'),
                        })}
                        onClick={(e: any) => {
                          if (
                            (itm?.auditRequired &&
                              itm?.status === 'Request_Submission') ||
                            itm?.status === 'Pending_Audit'
                          ) {
                            e.stopPropagation()

                            setShowEducationMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })

                            dispatch(setSelectedDocumentId(itm?.documentId))

                            /** selected candidate states set to store */
                            dispatch(
                              setSelectedCandidate({
                                name: candidateName,
                                id: candidateId,
                              }),
                            )

                            /** audit check modal open handle from state */
                            dispatch(
                              setIsAuditModal({
                                isOpen: true,
                                isFooterShow: false,
                                isActiveButton: false,
                                tab: null,
                              }),
                            )
                          }
                        }}>
                        Perform Audit
                      </span>

                      <span
                        className='font-Regular text-Red-500 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowEducationMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onDeleteClick && onDeleteClick()
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}

        {candidateEducationList?.length <= 0 && (
          <div className='flex flex-col justify-center items-center'>
            <i className='ri-file-search-line text-[100px] text-Gray-300'></i>

            <span className='font-Medium text-heading-3 text-Gray-500'>
              Not Documents Found
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

CandidateEducationCard.defaultProps = {
  onAddNewClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default CandidateEducationCard
