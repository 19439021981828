import React, { FC } from 'react'
import CN from 'classnames'

import { ActivatedList } from './ActivatedList'

export interface ActivatedTabViewProps {
  [x: string]: any
}

export const ActivatedTabView: FC<ActivatedTabViewProps> = ({
  auditorsList,
  className,
  documentCategoryList,
  documentTypeList,
  ...restProps
}: ActivatedTabViewProps) => {
  const ActivatedTabViewClasses = CN(
    `activated-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={ActivatedTabViewClasses} {...restProps}>
      <span className='text-Gray-800 font-Medium'>Audit Completed</span>

      <span className='font-Regular text-small text-Gray-800 mt-1'>
        Candidates who have passed the audit check are listed below. Only admins
        can change the audit status and activate them.
      </span>

      <div className='pt-8'>
        <ActivatedList
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      </div>
    </div>
  )
}

ActivatedTabView.defaultProps = {}

export default ActivatedTabView
