import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditTaxInformationProps {
  id?: any
  taxInformation?: any
  candidateId?: any
}

export const useEditTaxInformation = () => {
  const mutate = useMutation(
    ({ taxInformation, id, candidateId }: EditTaxInformationProps) => {
      return putAPI(`/Candidate/${candidateId}/TaxInfromation/${id}`, {
        taxFileNo: taxInformation.taxFileNo,
        onPaid: taxInformation.onPaid,
        taxResidencyTaxPurposes: taxInformation.taxResidencyTaxPurposes,
        taxThresholdClaim: taxInformation.taxThresholdClaim,
        taxThresholdHigherEducation: taxInformation.taxThresholdHigherEducation,
      })
    },
  )

  return mutate
}

export default useEditTaxInformation
