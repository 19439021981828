/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Button,
  RightSideModal,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetAssignUser, useUserAdd } from 'framework/api/methods'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

export interface AssignNewUserProps {
  buttonTitle: string
  footerCancelButton?: any
  headerCloseButton?: (e: any) => void | undefined
  headerSubtitle: string
  headerTitle: string
  onClickInviteNewUser?: any
  isButtonDisabled: boolean
  [x: string]: any
}

export const AssignNewUser: FC<AssignNewUserProps> = ({
  addUserModalShow,
  buttonTitle,
  footerCancelButton,
  headerCloseButton,
  headerSubtitle,
  onClickInviteNewUser,
  headerTitle,
  notify,
  roleName,
  roleId,
  getTableData,
}: AssignNewUserProps) => {
  const dispatch = useDispatch()
  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )
  const [name, setName] = useState<any>()
  const [userList, setUserList] = useState<any>()

  const [isErrorField] = useState<any>({
    name: false,
  })
  const [addButtonDisabled, setAddButtonDisabled] = useState(true)
  const [selectedUser, setSelectedUser] = useState<any>()

  /** API Call for get user list by name */
  const { mutate: userListByNameMutate, isLoading: userListByNameIsLoading } =
    useGetAssignUser()

  /** Process the get User list by name */
  async function getUserListByName(name: any) {
    userListByNameMutate(
      { searchValue: name },
      {
        onSuccess: ({ data: successData }: any) => {
          const toBeUpdated = successData.users.map((item: any) => {
            return {
              ...item,
              isSelected: false,
            }
          })
          setUserList(toBeUpdated)
        },
      },
    )
  }

  /** APi call for the add new user */
  const { mutate: userAddMutate } = useUserAdd()

  const userAdd = () => {
    const userDetails = {
      userId: selectedUser.userId,
      roleId: roleId,
      roleName: roleName,
    }
    userAddMutate(
      {
        userDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: 'Updated Successfully',
            alertBody: successData?.message,
            status: 'Success',
          })
          getTableData()
          footerCancelButton()

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message.split(':').slice(1),
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      name && getUserListByName(name)
    }, 1000)
    return () => clearTimeout(timer)
  }, [name])

  const onClickUser = (user: any) => {
    const toBeUpdatedUsers = userList.map((item: any) => {
      if (item.userId === user.userId) {
        return { ...item, isSelected: true }
      }
      return { ...item, isSelected: false }
    })
    setUserList(toBeUpdatedUsers)
  }

  useEffect(() => {
    if (userList && userList.length > 0) {
      const isSelected = userList?.filter((item: any) => item.isSelected)
      if (isSelected.length > 0) {
        setAddButtonDisabled(false)
        setSelectedUser(isSelected[0])
      } else {
        setAddButtonDisabled(true)
        setSelectedUser({})
      }
    }
  }, [userList])

  return (
    <RightSideModal
      isActive={addUserModalShow}
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      isPrimaryButtonDisable={addButtonDisabled}
      primaryButtonTitle={buttonTitle}
      onHeaderCloseButtonClick={headerCloseButton}
      onFooterCancelButtonClick={footerCancelButton}
      onFooterAddButtonClick={userAdd}>
      {
        <div className='w-full px-6'>
          {/** Search input */}
          <TextField
            iconBefore={<i className='ri-search-line' />}
            placeholder='Search user with name or email'
            className='pt-6 pb-2'
            onChange={(e: any) => {
              setName(e.target.value)
            }}
            isErrored={isErrorField.name}
          />

          {/** user list */}
          {userList?.length > 0 && name.length > 0 && (
            <div className='flex flex-col pt-2'>
              {userList.map((item: any, index: any) => (
                <div
                  key={index}
                  onClick={() => onClickUser(item)}
                  className={CN(
                    'py-4 pl-6 border-[1px] border-Gray-200 hover:bg-Gray-50 cursor-pointer active:bg-Gray-100',
                    {
                      'border-Gray-600': item.isSelected,
                    },
                    {
                      'rounded-t-md': index === 0,
                    },
                    {
                      'rounded-b-md': index === userList?.length - 1,
                    },
                  )}>
                  <Avatar
                    title={item.name}
                    tag={item.email}
                    imageURL={item.avatar ? item.avatar : undefined}
                  />
                </div>
              ))}
            </div>
          )}

          {/** When empty */}
          {userList &&
            userList?.length === 0 &&
            name?.length > 0 &&
            !userListByNameIsLoading && (
              <div className='flex flex-col items-center justify-center h-full'>
                <i className='ri-team-fill text-[60px] text-Gray-300'></i>

                <span className='flex justify-center font-Regular text-small text-Gray-600 pl-[96px] pt-[22px]'>
                  We couldn't find the user. You can send invite requests to new
                  users via mail.
                </span>

                <div className='pt-[33px]'>
                  <Button
                    isDisabled={false}
                    className='ml-2'
                    size='sm'
                    textTheme='blue'
                    iconLeft={<i className='ri-user-shared-line' />}
                    theme='white'
                    onClick={onClickInviteNewUser}>
                    Invite new user
                  </Button>
                </div>
              </div>
            )}
        </div>
      }
    </RightSideModal>
  )
}

AssignNewUser.defaultProps = {
  buttonTitle: undefined,
  footerCancelButton: undefined,
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  isButtonDisabled: undefined,
}

export default AssignNewUser
