/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { Avatar, Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { MoreButton } from 'components/atoms'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export interface ClientProfileContactCardProps {
  onEditClick?: any
  onRemoveClick?: any
  [x: string]: any
}

export const ClientProfileContactCard: FC<ClientProfileContactCardProps> = ({
  className,
  onEditClick,
  onRemoveClick,
  contactItem,
  notify,
  isMoreOptionOpen,
  setIsMoreOptionOpen,
  index,
  ...restProps
}: ClientProfileContactCardProps) => {
  const ClientProfileContactCardClasses = CN(
    `client-profile-contact-card flex bg-white rounded-lg border border-Gray-200 mb-5 pl-5 pt-[18px] pb-[17px]`,
    className,
  )

  return (
    <div className={ClientProfileContactCardClasses} {...restProps}>
      {/* avatar,notes and contact detail section */}
      <div className='flex w-[55%]'>
        {/* avatar and notes section */}
        <div className='flex flex-col pr-[35px]'>
          {/* avatar section */}
          <Avatar
            color='customer'
            imageURL={
              contactItem?.avatarUrl ? contactItem?.avatarUrl : undefined
            }
            isIcon={contactItem?.avatarUrl ? false : true}
            tagClassName='max-w-[200px] line-clamp-2 words-break'
            titleClassName='flex w-[200px] max-w-[200px] line-clamp-1'
            tag={capitalizeFirstLetter(contactItem?.position)}
            size='xl'
            title={`${capitalizeFirstLetter(
              contactItem?.firstName,
            )} ${capitalizeFirstLetter(contactItem?.lastName)} `}
            // data-tip={contactItem.firstName}
            // data-for='contact-name'
          />

          {/* <ReactTooltip
            place='right'
            id='contact-name'
            effect='solid'
            textColor='#FFFFFF'
            backgroundColor='#4B5563'
          /> */}

          {/* notes button section */}
          <div
            className='flex w-[235px] items-center justify-center bg-Cobalt-50 text-Cobalt-600 hover:bg-Cobalt-100 active:bg-Cobalt-200 rounded mt-4 py-[11px]'
            onClick={() => {
              notify({
                alertHeader: 'working in progress...',
                status: 'Warning',
              })
            }}>
            <i className='ri-file-text-line text-[12px] mr-2' />

            <span className='text-extra-small font-Medium'>Notes</span>
          </div>
        </div>

        {/* contact details section */}
        <div className='flex flex-col'>
          <span className='text-extra-small text-Gray-400 font-Bold'>
            CONTACT DETAILS
          </span>

          {/* phone section */}
          <div className='phone-number-field flex pt-3 text-Cobalt-500'>
            <i className='ri-phone-line text-[14px] mr-[7px]' />

            {contactItem?.phoneNo ? (
              <a
                href={`tel:${contactItem?.phoneNo}`}
                className='text-small font-Medium'>
                {contactItem?.phoneNo}
              </a>
            ) : (
              `-`
            )}
          </div>

          {/* email section */}
          <div className='email-field flex pt-2 text-Cobalt-500'>
            <i className='ri-mail-line text-[14px] mr-[7px]' />

            {contactItem?.email ? (
              <>
                <a
                  href={`mailto:${contactItem?.email}`}
                  className='text-small font-Medium line-clamp-1 break-words  max-w-[180px]'
                  data-tip={contactItem.email}
                  data-for='Email'>
                  {contactItem?.email}
                </a>

                <ReactTooltip
                  place='bottom'
                  id='Email'
                  effect='solid'
                  textColor='#FFFFFF'
                  backgroundColor='#4B5563'
                />
              </>
            ) : (
              `-`
            )}
          </div>
        </div>
      </div>

      {/* work detail section */}
      <div className='flex w-[45%] justify-between items-start relative '>
        <div className='flex flex-col w-full'>
          <span className='text-extra-small text-Gray-400 font-Bold'>
            Work Details
          </span>

          <div className='flex flex-col pt-[14px] text-Gray-600 divide-y divide-Gray-200 w-fit'>
            <div className='flex justify-start items-center pb-2'>
              <span className='text-small font-Regular'>Work Location</span>

              <span className='text-small font-SemiBold leading-6 ml-2 line-clamp-1 break-words'>
                {contactItem?.workLocationName
                  ? contactItem?.workLocationName
                  : 'N/A'}
              </span>

              {contactItem?.isPrimary && (
                <Badge className='ml-2' theme='primary'>
                  PRIMARY
                </Badge>
              )}
            </div>

            <div className='flex justify-start items-center text-Gray-600 pt-2'>
              <span className='text-small font-Regular'>
                Primary Consultant
              </span>

              <span className='text-small font-SemiBold leading-6 ml-2'>
                {contactItem?.consultantName
                  ? contactItem?.consultantName
                  : 'N/A'}
              </span>
            </div>
          </div>
        </div>

        <MoreButton
          className='mr-4'
          onClick={(e: any) => {
            e.stopPropagation()
            setIsMoreOptionOpen({
              index: index,
              isEnable:
                index === isMoreOptionOpen.index
                  ? !isMoreOptionOpen.isEnable
                  : true,
            })
          }}
        />

        {isMoreOptionOpen.isEnable && index === isMoreOptionOpen.index && (
          <div className='absolute flex flex-col w-[200px] bg-white right-0 mr-[15px] mt-[42px] py-1 border border-Gray-200 rounded-md shadow-xl'>
            <span
              className='text-Gray-800 font-Regular hover:bg-Gray-50 py-2 px-4'
              onClick={(e: any) => {
                e.stopPropagation()
                setIsMoreOptionOpen({
                  index: -1,
                  isEnable: false,
                })
                onEditClick && onEditClick()
              }}>
              Edit Contact
            </span>

            <span
              className='text-Red-500 font-Regular hover:bg-Gray-50 py-2 px-4'
              onClick={(e: any) => {
                e.stopPropagation()
                setIsMoreOptionOpen({
                  index: -1,
                  isEnable: false,
                })
                onRemoveClick && onRemoveClick()
              }}>
              Remove Contact
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

ClientProfileContactCard.defaultProps = {
  onEditClick: undefined,
  onRemoveClick: undefined,
}

export default ClientProfileContactCard
