import React, { FC } from 'react'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useRequestRefereesReminder } from 'framework/api/methods'

import { Modal, ModalFooter } from 'components/molecules'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface RequestRefereeReminderModalProps {
  isActive: boolean
  setIsActive: (value: boolean) => void
  [x: string]: any
}

export const RequestRefereeReminderModal: FC<
  RequestRefereeReminderModalProps
> = ({
  candidateId,
  candidateReferees,
  getCandidateRefereesList,
  isActive,
  setIsActive,
}: RequestRefereeReminderModalProps) => {
  const {
    mutate: requestRefereesReminderMutate,
    isLoading: requestRefereesReminderIsLoading,
  } = useRequestRefereesReminder()

  /** request referee reminder */
  async function requestRefereesReminder() {
    requestRefereesReminderMutate(
      {
        candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle
              ? successData?.messageTitle
              : 'Referee request reminder sent successfully',
            alertBody: '',
            status: 'Success',
          })

          getCandidateRefereesList()
          setIsActive(false)
        },

        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error sending request referee reminder!',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      subHeaderTitle='Remind candidate to submit referee details'
      headerTitle='Send Reminder to Candidate'
      isFooterShow={true}
      size='md'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}>
      <div className='flex flex-col p-6 w-full text-Gray-500 text-small'>
        <div className='flex'>
          <span>No of reminders sent:</span>

          <span className='ml-1 font-SemiBold'>
            {candidateReferees?.noOfRemindersSent}
          </span>
        </div>

        <div className='flex mt-4'>
          <span>Last reminder sent on:</span>

          <span className='ml-1 font-SemiBold'>
            {candidateReferees?.lastReminderSentOn
              ? dateToReadableString(candidateReferees?.lastReminderSentOn)
              : 'Never'}
          </span>
        </div>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              requestRefereesReminder()
            }}
            isLoading={requestRefereesReminderIsLoading}
            className='mr-2'
            theme='cobalt'>
            Send Reminder
          </Button>
          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

RequestRefereeReminderModal.defaultProps = {}

export default RequestRefereeReminderModal
