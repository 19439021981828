import React, { FC, useEffect, useState } from 'react'
import {
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import { useEditClient } from 'framework/api/methods'
import { accountSizes } from 'static-data/clients/accountSizes'

import { PageLoader } from 'components/atoms'

export interface EditClientProps {
  [x: string]: any
}

export const EditClient: FC<EditClientProps> = ({
  clientData,
  clientName,
  getClientById,
  isActive,
  notify,
  setIsOpenEditClientDrawer,
}: EditClientProps) => {
  const [selectedAccountSize, setSelectedAccountSize] = useState<any>()
  const [clientDetails, setClientDetails] = useState({
    name: '',
    website: '',
    abn: '',
    industry: '',
    source: '',
    size: '',
    about: '',
    isActive: true,
  })

  const [isErrorField, setIsErrorField] = useState({
    name: false,
  })

  /** set client data in edit drawer */
  useEffect(() => {
    const { name, website, abn, industry, source, about, isActive } =
      clientData || {}

    setClientDetails({
      ...clientDetails,
      name: name,
      website: website,
      abn: abn,
      industry: industry,
      source: source,
      about: about,
      isActive: isActive,
    })

    /** set client account size  */
    const clientAccountSize = accountSizes?.find((itm: any) => {
      return itm.value == clientData?.size
    })

    setSelectedAccountSize(clientAccountSize)
  }, [clientData])

  /** APi call for the edit clients */
  const { mutate: editClientMutate, isLoading: editClientIsLoading } =
    useEditClient()

  /** Process the edit client */
  const editClient = () => {
    editClientMutate(
      {
        Id: clientData?.id,
        clientDetails,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Client information successfully updated.',
            alertBody: '',
            status: 'Success',
          })
          getClientById()
          setIsOpenEditClientDrawer(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }
  /** front end validation for name and account size input fields */
  const validateInputs = () => {
    const { name } = clientDetails

    setIsErrorField({
      ...isErrorField,
      name: name ? false : true,
    })

    const isAllValidFields = name

    return isAllValidFields
  }

  /** handle edit client button */
  const onEditClient = () => {
    const valid = validateInputs()

    if (valid) {
      editClient()
    } else {
      notify({
        alertHeader: 'Error...!',
        alertBody: 'You cannot leave name fields empty.',
        status: 'Error',
      })
    }
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle={`Edit Client- ${clientName}`}
      headerSubtitle='Edit client information'
      primaryButtonTitle='Update'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={false}
      onFooterAddButtonClick={() => {
        onEditClient()
      }}
      onHeaderCloseButtonClick={() => {
        setIsOpenEditClientDrawer(false)
      }}
      onFooterCancelButtonClick={() => {
        setIsOpenEditClientDrawer(false)
      }}>
      {editClientIsLoading && <PageLoader size='xxs' />}
      <div className='flex flex-col w-full px-6 pt-5'>
        <TextField
          label='Client Name'
          value={clientDetails.name}
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              name: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.name}
        />

        <TextField
          label='Website'
          className='mt-5'
          value={clientDetails.website}
          helperText='Ex: www.website.com'
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              website: e.target.value,
            })
          }}
          placeholder=''
        />

        <div className='flex w-full mt-5'>
          <TextField
            label='ABN'
            className='w-1/2'
            value={clientDetails.abn}
            onChange={(e: any) => {
              const re = /^[0-9\b+ ]+$/

              // if value is not blank, then test the regex
              if (e.target.value === '' || re.test(e.target.value)) {
                setClientDetails({
                  ...clientDetails,
                  abn: e.target.value,
                })
              }
            }}
            placeholder=''
          />
          <TextField
            label='Industry'
            className='w-1/2 ml-5'
            value={clientDetails.industry}
            onChange={(e: any) => {
              setClientDetails({
                ...clientDetails,
                industry: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>

        <div className='flex w-full mt-5'>
          <TextField
            label='Source'
            className='w-1/2'
            value={clientDetails.source}
            onChange={(e: any) => {
              setClientDetails({
                ...clientDetails,
                source: e.target.value,
              })
            }}
            placeholder=''
          />

          <SimpleSelect
            label='Account Size'
            value={selectedAccountSize}
            className='w-1/2 ml-5'
            onChange={(selectedItem: any) => {
              setSelectedAccountSize(selectedItem)
              setClientDetails({
                ...clientDetails,
                size: selectedItem.value,
              })
            }}
            options={accountSizes}
          />
        </div>

        <TextArea
          label='About'
          className='mt-5'
          textAreaClassName='h-[114px]'
          value={clientDetails.about}
          onChange={(e: any) => {
            setClientDetails({
              ...clientDetails,
              about: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* backend issue (variable name) : use isActive value for active disable status based on true false. and don't use status variable which
        is related to something else. refused to change by backend.  */}
        <Toggle
          label={clientDetails.isActive ? 'Active' : 'Disable'}
          className='mt-5'
          setValue={clientDetails.isActive}
          onToggleClick={(e: any) => {
            setClientDetails({
              ...clientDetails,
              isActive: e.target.checked,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

EditClient.defaultProps = {}

export default EditClient
