/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { ProfileAvatar } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface CandidateProfileDetailSectionProps {
  onAddNewPhotoClick?: () => void
  onDeletePhotoClick?: () => void
  onEditPhotoClick?: () => void
  onNotesClick?: () => void
  onSendRegistrationPackBtnClick?: () => void
  [x: string]: any
}

export const CandidateProfileDetailSection: FC<
  CandidateProfileDetailSectionProps
> = ({
  candidateDetails,
  className,
  contactButtonOnClick,
  onAddNewPhotoClick,
  onCandidateDetailsEditButtonClick,
  onDeletePhotoClick,
  onEditPhotoClick,
  onNotesClick,
  onSendRegistrationPackBtnClick,
  noteCount,
  setShowResendRegPackModal,
  ...restProps
}: CandidateProfileDetailSectionProps) => {
  /** candidate profile card main styles */
  const CandidateProfileDetailSectionClasses = CN(
    `candidate-profile-detail-section flex bg-white border border-Gray-200 rounded-lg p-5`,
    className,
  )

  /** candidate contact options styles */
  const ContactOptionsClasses = CN(
    'flex h-[38px] w-[38px] justify-center items-center rounded-full border border-Gray-300 hover:bg-Gray-50 cursor-pointer',
  )

  const { lineOne, suburb, state, postCode, country, latitude, longitude } =
    candidateDetails?.address || {}

  return (
    <div className={CandidateProfileDetailSectionClasses} {...restProps}>
      {/* left side profile picture and contact buttons section  */}
      <div className='flex flex-col justify-start items-center'>
        {/* profile picture */}
        <div className='relative'>
          <ProfileAvatar
            isProfilePictureAvailable={
              candidateDetails?.avatarUrl ? true : false
            }
            imageSource={
              candidateDetails?.avatarUrl
                ? candidateDetails?.avatarUrl
                : undefined
            }
            imageContainerClassName='w-[132px] h-[132px]'
            onAddClick={onAddNewPhotoClick}
            onDeleteClick={onDeletePhotoClick}
            onEditClick={onEditPhotoClick}
            isStatusDisable={
              candidateDetails?.status === 'Disabled' ? true : false
            }
          />

          {/** warning Icon */}
          {candidateDetails?.isWarning && (
            <div
              className={CN('absolute ', {
                'top-0 right-0': candidateDetails?.status === 'Disabled',
                'top-1 right-1': candidateDetails?.status !== 'Disabled',
              })}>
              <i
                className={CN(
                  'ri-error-warning-fill text-[26px] h-[27px] w-[27px] text-Red-500 hover:text-Red-400 cursor-pointer',
                  {
                    'bg-white/75 rounded-full':
                      candidateDetails?.status === 'Disabled',
                  },
                )}
                data-tip='candidate-warnings'
                data-for='candidate-warnings'
              />

              <ReactTooltip
                id='candidate-warnings'
                place='top'
                effect='solid'
                textColor='#E5E7EB'
                backgroundColor='#1F2937'>
                <ul className='list-disc'>
                  {candidateDetails?.warnings?.map((item: any, idx: any) => (
                    <li className='' key={idx}>
                      {item}
                    </li>
                  ))}
                </ul>
              </ReactTooltip>
            </div>
          )}
        </div>

        <div className='flex w-full justify-center items-center gap-x-[14px] mt-4'>
          <div
            className={ContactOptionsClasses}
            onClick={contactButtonOnClick}
            data-tip='Call'
            data-for='candidate-contact-phone'>
            <i className='ri-phone-line text-[17px] text-Cobalt-600' />
          </div>

          <ReactTooltip
            id='candidate-contact-phone'
            place='top'
            effect='solid'
            textColor='#E5E7EB'
            backgroundColor='#1F2937'
          />

          <div
            className={ContactOptionsClasses}
            onClick={contactButtonOnClick}
            data-tip='Send Text'
            data-for='candidate-contact-msg'>
            <i className='ri-message-2-line text-[17px] text-Cobalt-600' />
          </div>

          <ReactTooltip
            id='candidate-contact-msg'
            place='top'
            effect='solid'
            textColor='#E5E7EB'
            backgroundColor='#1F2937'
          />

          <div
            className={ContactOptionsClasses}
            onClick={contactButtonOnClick}
            data-tip='Send Email'
            data-for='candidate-contact-mail'>
            <i className='ri-mail-add-line text-[17px] text-Cobalt-600' />
          </div>

          <ReactTooltip
            id='candidate-contact-mail'
            place='top'
            effect='solid'
            textColor='#E5E7EB'
            backgroundColor='#1F2937'
          />
        </div>
      </div>

      {/* right side detail section */}
      <div className='flex flex-col w-full items-start pl-10'>
        {/* header candidate name and disable button section */}
        <div className='header-section flex w-full justify-between items-center'>
          <div className='flex items-center w-3/4'>
            <span
              className='text-heading-4 text-Gray-800 font-Bold line-clamp-1'
              data-tip={
                candidateDetails?.middleName
                  ? `${candidateDetails?.firstName} ${candidateDetails?.middleName} ${candidateDetails?.lastName}`
                  : `${candidateDetails?.firstName} ${candidateDetails?.lastName}`
              }
              data-for='candidate-name'>
              {candidateDetails?.middleName
                ? `${candidateDetails?.firstName} ${candidateDetails?.middleName} ${candidateDetails?.lastName}`
                : `${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
            </span>

            <ReactTooltip
              id='candidate-name'
              place='right'
              effect='solid'
              textColor='#E5E7EB'
              backgroundColor='#1F2937'
            />

            {/* badge only display in pending status */}
            {candidateDetails?.status === 'Pending' && (
              <Badge size='small' theme='yellow' className='ml-4'>
                Pending
              </Badge>
            )}
          </div>

          <Button
            size='sm'
            onClick={onCandidateDetailsEditButtonClick}
            textTheme='blue'
            theme='white'>
            Edit
          </Button>
        </div>

        {/* detail section */}
        <div className='flex w-full pt-2 pb-3 border-b border-b-Gray-200'>
          {/* details left section */}
          <div className='flex flex-col'>
            {/* id section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[68px] text-extra-small font-Medium text-Gray-600'>
                ID
              </span>

              <span
                className='max-w-[300px] text-small font-Medium text-Gray-800 break-words line-clamp-1'
                data-tip={candidateDetails?.candidateNo}
                data-for='candidate-id'>
                {candidateDetails?.candidateNo
                  ? candidateDetails?.candidateNo
                  : '-'}

                <ReactTooltip
                  id='candidate-id'
                  place='right'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'
                />
              </span>
            </div>

            {/* email section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[68px] text-extra-small font-Medium text-Gray-600'>
                Email
              </span>

              <span
                className='max-w-[300px] text-small font-Medium text-Gray-800 break-words line-clamp-1'
                data-tip={candidateDetails?.email}
                data-for='candidate-email'>
                {candidateDetails?.email ? candidateDetails?.email : `-`}

                <ReactTooltip
                  id='candidate-email'
                  place='right'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'
                />
              </span>
            </div>

            {/* phone  section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[68px] text-extra-small font-Medium text-Gray-600'>
                Phone
              </span>

              <span className='text-small font-Medium text-Gray-800'>
                {candidateDetails?.phone ? candidateDetails?.phone : `-`}
              </span>
            </div>

            {/* gender  section */}
            <div className='flex justify-start items-center'>
              <span className='w-[68px] text-extra-small font-Medium text-Gray-600'>
                Gender
              </span>

              <span className='text-small font-Medium text-Gray-800'>
                {candidateDetails?.gender ? candidateDetails?.gender : `-`}
              </span>
            </div>
          </div>

          {/* details right section */}
          <div className='flex flex-col pl-[72px]'>
            {/* address section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[100px] text-extra-small font-Medium text-Gray-600'>
                Address
              </span>

              {candidateDetails?.address ? (
                <div className='flex w-auto relative'>
                  <span
                    className='max-w-[250px] min-w-auto w-fit text-small font-Medium text-Gray-800 break-all line-clamp-1'
                    data-tip={`${lineOne}, ${suburb}, ${state}, ${country}.`}
                    data-for='candidate-address'>
                    {lineOne && `${lineOne},`}

                    {suburb && `${suburb},`}

                    {state && `${state},`}

                    {postCode && `${postCode},`}

                    {country && `${country}`}
                  </span>

                  <a
                    href={
                      latitude && longitude
                        ? `https://www.google.com/maps/place/${latitude},${longitude}/@${latitude},${longitude},12z`
                        : `http://maps.google.com/?q=${lineOne}+${suburb}+${state}+${country}`
                    }
                    target='_blank'
                    className='ml-[9px] absolute w-fit -right-6 -mt-1'>
                    <i
                      className='ri-direction-line text-[18px] font-Medium text-Cobalt-600 mb-3'
                      data-tip='Direction'
                      data-for='candidate-direction'
                    />
                  </a>

                  <ReactTooltip
                    id='candidate-address'
                    place='top'
                    effect='solid'
                    textColor='#E5E7EB'
                    backgroundColor='#1F2937'
                  />

                  <ReactTooltip
                    id='candidate-direction'
                    place='right'
                    effect='solid'
                    textColor='#E5E7EB'
                    backgroundColor='#1F2937'
                  />
                </div>
              ) : (
                '-'
              )}
            </div>

            {/* age  section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[100px] text-extra-small font-Medium text-Gray-600'>
                Age
              </span>

              <span className='text-small font-Medium text-Gray-800'>
                {candidateDetails?.age ? candidateDetails?.age : `-`}
              </span>
            </div>

            {/* joined on date  section */}
            <div className='flex justify-start items-center pb-2'>
              <span className='w-[100px] text-extra-small font-Medium text-Gray-600'>
                Joined On
              </span>

              <span className='text-small font-Medium text-Gray-800'>
                {candidateDetails?.joinedOn
                  ? dateToReadableString(candidateDetails?.joinedOn)
                  : `-`}
              </span>
            </div>

            {/* main Employer section */}
            <div className='flex justify-start items-center'>
              <span className='w-[100px] text-extra-small font-Medium text-Gray-600'>
                Main Employer
              </span>

              <span className='text-small font-Medium text-Gray-800'>
                {candidateDetails?.mainClient
                  ? candidateDetails?.mainClient
                  : `-`}
              </span>
            </div>
          </div>
        </div>

        {/* bottom section */}
        <div className='flex w-full justify-between items-start'>
          {/* Send Registration Pack button */}
          <div className='flex gap-x-3 justify-start items-start'>
            <div className='flex flex-col mt-3'>
              <Button
                className='h-[30px]'
                iconLeft={<i className='ri-mail-check-line' />}
                onClick={() => {
                  if (candidateDetails?.regPackSendOn) {
                    setShowResendRegPackModal(true)
                  } else {
                    onSendRegistrationPackBtnClick &&
                      onSendRegistrationPackBtnClick()
                  }
                }}
                size='sm'>
                {candidateDetails?.regPackSendOn
                  ? `Resend Reg Pack`
                  : `Send Reg Pack`}
              </Button>

              {candidateDetails?.regPackSendOn && (
                <span className='text-extra-small text-Gray-500 mt-1'>
                  Sent on:{' '}
                  {dateToReadableString(candidateDetails?.regPackSendOn)}
                </span>
              )}
            </div>

            <Button
              className='mt-3 h-[30px]'
              iconLeft={<i className='ri-file-list-2-line' />}
              iconRight={
                <div className='bg-Blue-50 text-Blue-600 text-extra-small rounded-full px-2'>
                  {noteCount}
                </div>
              }
              onClick={onNotesClick}
              textTheme='blue'
              size='sm'
              theme='white'>
              Notes
            </Button>
          </div>

          {/* social button */}
          <div className='flex gap-x-2 mt-3 '>
            {candidateDetails?.socialLinks?.linkedIn && (
              <a href={candidateDetails?.socialLinks?.linkedIn} target='_blank'>
                <div className='flex w-8 h-8 justify-center items-center rounded-lg border border-Gray-300 text-Cobalt-600 hover:text-white hover:bg-[#0A66C2] cursor-pointer'>
                  <i className='ri-linkedin-fill text-[14.5px]' />
                </div>
              </a>
            )}

            {candidateDetails?.socialLinks?.facebook && (
              <a href={candidateDetails?.socialLinks?.facebook} target='_blank'>
                <div className='flex w-8 h-8 justify-center items-center rounded-lg border border-Gray-300 text-Cobalt-600 hover:text-white hover:bg-[#4267B2] cursor-pointer'>
                  <i className='ri-facebook-fill text-[15px] ' />
                </div>
              </a>
            )}

            {candidateDetails?.socialLinks?.twitter && (
              <a href={candidateDetails?.socialLinks?.twitter} target='_blank'>
                <div className='flex w-8 h-8 justify-center items-center rounded-lg border border-Gray-300 text-Cobalt-600 hover:text-white hover:bg-[#1DA1F2] cursor-pointer'>
                  <i className='ri-twitter-fill text-[15.5px]' />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

CandidateProfileDetailSection.defaultProps = {
  onAddNewPhotoClick: undefined,
  onCandidateDetailsEditButtonClick: undefined,
  onDeletePhotoClick: undefined,
  onEditPhotoClick: undefined,
  onNotesClick: undefined,
  onSendRegistrationPackBtnClick: undefined,
}

export default CandidateProfileDetailSection
