import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getIncidentSignedUrls = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/Candidate/${reqBody.candidateId}/Incident/${reqBody.incidentId}/SignedUrls`,
  )
  return res.data
}

export const useGetIncidentSignedUrls = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['IncidentSignedUrls', reqBody], getIncidentSignedUrls, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
