import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetDocumentDetailsByIdType {
  candidateId?: string | null
  documentId?: string | null
  categoryId?: string | null
  typeId?: string | null
  title?: string | null
  type?: string | null
  category?: string | null
  expiryOn?: any
  dueOn?: any
  notes?: string | null
  auditRequired?: boolean
  status?: string | null
  isArchived?: boolean
  createdOn?: any
  updatedOn?: any
  attachmentUrls?: string[] | undefined | null
  attachmentTypeSignedUrls?: any
}

export const useGetDocumentDetailsById = (documentId: string) => {
  return useQuery(
    ['GET_DOC_DETAILS_BY_ID'],
    async () => {
      const res = await get(`/Document/${documentId}`)
      return res.data
        .documentDetailsWithAttachments as GetDocumentDetailsByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
