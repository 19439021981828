import React, { FC } from 'react'
import { Popover, PopoverProps } from 'react-tiny-popover'

export interface PopOverMenuProps extends PopoverProps {
  [x: string]: any
}

export const PopOverMenu: FC<PopOverMenuProps> = ({
  children,
  ...restProps
}: PopOverMenuProps) => {
  return <Popover {...restProps}>{children}</Popover>
}

PopOverMenu.defaultProps = {}

export default PopOverMenu
