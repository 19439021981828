import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CN from 'classnames'
import {
  useGetBankAccountData,
  useGetSuperFund,
  useGetTaxInformation,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import { CandidateTabTileCard } from 'components/atoms'
import {
  BankAccountDetails,
  SuperFundDetails,
  TaxInformation,
} from 'components/Drawers/Candidate'
import { BankAccountDetailsCard } from 'components/molecules/BankAccountDetailsCard'
import { SuperFundDetailsCard } from 'components/molecules/SuperFundDetailsCard'
import { TaxInformationDetailsCard } from 'components/molecules/TaxInformationDetailsCard'

export interface PayrollTabViewProps {
  [x: string]: any
}

export const PayrollTabView: FC<PayrollTabViewProps> = ({
  candidateId,
  notify,
  className,
  getCandidateAuditDocumentIds,
  ...restProps
}: PayrollTabViewProps) => {
  const dispatch = useDispatch()

  const { isAuditModal } = useSelector((state: any) => state.audit)
  const { documentCategoriesList } = useSelector((state: any) => state.document)
  const { clickValue } = useSelector((state: any) => state.layout)

  const [bankAccountDrawerData, setBankAccountDrawerData] = useState({
    isAddMode: true,
    isShowBankAccountDrawer: false,
    headerTitle: 'Add Bank Account',
    headerSubtitle: 'Add bank account details',
    primaryButtonTitle: 'Save Bank Account',
    onDrawerClose: () => {
      setBankAccountDrawerData({
        ...bankAccountDrawerData,
        isShowBankAccountDrawer: false,
      })
    },
  })
  const [superFundDrawerData, setSuperFundDrawerData] = useState({
    isAddMode: true,
    isShowSuperFundDrawer: false,
    headerTitle: 'Add Super Fund Details',
    headerSubtitle: 'Add super fund details',
    primaryButtonTitle: 'Save Super Fund',
    onDrawerClose: () => {
      setSuperFundDrawerData({
        ...superFundDrawerData,
        isShowSuperFundDrawer: false,
      })
    },
  })
  const [taxInformationDrawerData, setTaxInformationDrawerData] = useState({
    isAddMode: true,
    isShowTaxInformationDrawer: false,
    headerTitle: 'Add Tax Details',
    headerSubtitle: 'Add candidate’s tax details',
    primaryButtonTitle: 'Save TaxInformation',
    onDrawerClose: () => {
      setTaxInformationDrawerData({
        ...taxInformationDrawerData,
        isShowTaxInformationDrawer: false,
      })
    },
  })

  const PayrollTabViewClasses = CN(`payroll-tab-view`, className)

  const [showSuperFundMoreOption, setShowSuperFundMoreOption] =
    useState<any>(false)

  useEffect(() => {
    setShowSuperFundMoreOption(false)
  }, [clickValue])

  /** API Handling request for Get Bank Account Data */
  const {
    data: bankAccountData,
    refetch: getBankAccountData,
    isLoading: getBankAccountDataIsLoading,
    isFetching: getBankAccountDataIsFetching,
  } = useGetBankAccountData({
    candidateId: candidateId,
  })

  /** API Handling request for Get Tax Information */
  const {
    data: taxInformation,
    refetch: getTaxInformation,
    isLoading: getTaxInformationIsLoading,
    isFetching: getTaxInformationIsFetching,
  } = useGetTaxInformation({
    candidateId: candidateId,
  })

  /** API Handling request for Get Super Fund */
  const {
    data: superFundData,
    refetch: getSuperFundData,
    isLoading: getSuperFundIsLoading,
    isFetching: getSuperFundIsFetching,
  } = useGetSuperFund({
    candidateId: candidateId,
  })

  /** handle get category Id for document type list */
  const handleDocumentTypeList = (type) => {
    const superFundDocObj = documentCategoriesList?.find((item) => {
      return item.label === type
    })

    dispatch(setDocumentCategory(superFundDocObj))
  }

  useEffect(() => {
    !isAuditModal?.isOpen && getSuperFundData
  }, [isAuditModal?.isOpen])

  useEffect(() => {
    getCandidateAuditDocumentIds()
  }, [superFundData, bankAccountData])

  return (
    <div className={PayrollTabViewClasses} {...restProps}>
      <div className='flex'>
        <div className='w-1/4 min-w-max'>
          <BankAccountDetailsCard
            data={bankAccountData?.bankAccountModel}
            isLoading={
              getBankAccountDataIsLoading || getBankAccountDataIsFetching
            }
            onAddClick={() => {
              setBankAccountDrawerData({
                ...bankAccountDrawerData,
                isAddMode: true,
                headerSubtitle: 'Add bank account details',
                primaryButtonTitle: 'Save Bank Account',
                headerTitle: 'Add Bank Account',
                isShowBankAccountDrawer: true,
              })
            }}
            onEditClick={() => {
              setBankAccountDrawerData({
                ...bankAccountDrawerData,
                isAddMode: false,
                headerSubtitle: 'Edit candidate’s bank account details',
                primaryButtonTitle: 'Update Bank Account',
                headerTitle: 'Edit Bank Account',
                isShowBankAccountDrawer: true,
              })
            }}
          />
          <SuperFundDetailsCard
            onAddClick={() => {
              handleDocumentTypeList('Super Fund')
              setSuperFundDrawerData({
                ...superFundDrawerData,
                isAddMode: true,
                isShowSuperFundDrawer: true,
                headerTitle: 'Add Super Fund Details',
                headerSubtitle: 'Add super fund details',
                primaryButtonTitle: 'Save Super Fund',
              })
            }}
            onEditClick={() => {
              handleDocumentTypeList('Super Fund')
              setSuperFundDrawerData({
                ...superFundDrawerData,
                isAddMode: false,
                isShowSuperFundDrawer: true,
                headerSubtitle: 'Edit candidate’s super details',
                headerTitle: 'Edit Super Details',
                primaryButtonTitle: 'Edit Super Details',
              })
            }}
            data={superFundData?.superFundModel}
            isLoading={getSuperFundIsFetching || getSuperFundIsLoading}
            setShowSuperFundMoreOption={setShowSuperFundMoreOption}
            showSuperFundMoreOption={showSuperFundMoreOption}
          />
        </div>
        <div className='ml-5 flex-1'>
          <div className='flex'>
            <CandidateTabTileCard
              isLoading={
                getTaxInformationIsLoading || getTaxInformationIsFetching
              }
              className='w-[300px] bg-gradient-to-r from-[#4191DC] to-[#1195C5] mr-5'
              leftElement={
                <div className='flex flex-col'>
                  <span className='text-white font-[700]'>Hourly Rate</span>
                  <span className='text-white text-[14px]'>
                    Forklift Driver
                  </span>
                  <span className='text-white text-[12px]'>Woolsworth</span>
                </div>
              }
              rightElement={
                <div className='flex items-baseline z-[1]'>
                  <span className='text-small font-Bold text-white mr-1'>
                    $
                  </span>
                  <span className='font-Bold text-[24px] text-white'>28.</span>
                  <span className='text-small font-Bold text-white'>45</span>
                </div>
              }
              icon={
                <i className='ri-money-dollar-circle-line text-[83px] text-Cobalt-600 opacity-20'></i>
              }
            />
            <CandidateTabTileCard
              isLoading={
                getTaxInformationIsLoading || getTaxInformationIsFetching
              }
              className='w-[300px] bg-gradient-to-r from-[#E66962] to-[#DD7F0B]'
              leftElement={
                <div className='flex flex-col'>
                  <span className='text-white text-[16px] font-[700]'>
                    Worked Hours
                  </span>
                  <span className='text-white text-[14px]'>This Week</span>
                </div>
              }
              rightElement={
                <div className='flex flex-col items-end z-[1]'>
                  <span className='text-[24px] font-Bold text-white'>27h</span>
                  <span className='text-white font-SemiBold text-extra-small'>
                    (3h overtime)
                  </span>
                </div>
              }
              icon={
                <i className='ri-time-line text-[83px] text-Yellow-600 opacity-20'></i>
              }
            />
          </div>
          <div className='mt-5 flex-1'>
            <TaxInformationDetailsCard
              onAddClick={() => {
                setTaxInformationDrawerData({
                  ...taxInformationDrawerData,
                  isAddMode: true,
                  isShowTaxInformationDrawer: true,
                  headerSubtitle: 'Add candidate’s tax details',
                  headerTitle: 'Add Tax Details',
                  primaryButtonTitle: 'Save Tax Information',
                })
              }}
              onEditClick={() => {
                setTaxInformationDrawerData({
                  ...taxInformationDrawerData,
                  isAddMode: false,
                  isShowTaxInformationDrawer: true,
                  headerSubtitle: 'Edit candidate’s tax details',
                  headerTitle: 'Edit Tax Details',
                  primaryButtonTitle: 'Update Tax Information',
                })
              }}
              data={taxInformation?.infromation}
              isLoading={
                getTaxInformationIsLoading || getTaxInformationIsFetching
              }
            />
          </div>
        </div>
      </div>
      {bankAccountDrawerData.isShowBankAccountDrawer && (
        <BankAccountDetails
          getBankAccountData={getBankAccountData}
          candidateId={candidateId}
          notify={notify}
          data={bankAccountData?.bankAccountModel}
          isAddMode={bankAccountDrawerData.isAddMode}
          isActive={bankAccountDrawerData.isShowBankAccountDrawer}
          onDrawerClose={bankAccountDrawerData.onDrawerClose}
          headerTitle={bankAccountDrawerData.headerTitle}
          primaryButtonTitle={bankAccountDrawerData.primaryButtonTitle}
          headerSubtitle={bankAccountDrawerData.headerSubtitle}
        />
      )}

      {superFundDrawerData.isShowSuperFundDrawer && (
        <SuperFundDetails
          getSuperFundData={getSuperFundData}
          candidateId={candidateId}
          notify={notify}
          data={superFundData?.superFundModel}
          isAddMode={superFundDrawerData.isAddMode}
          isActive={superFundDrawerData.isShowSuperFundDrawer}
          onDrawerClose={superFundDrawerData.onDrawerClose}
          headerTitle={superFundDrawerData.headerTitle}
          primaryButtonTitle={superFundDrawerData.primaryButtonTitle}
          headerSubtitle={superFundDrawerData.headerSubtitle}
        />
      )}

      {taxInformationDrawerData.isShowTaxInformationDrawer && (
        <TaxInformation
          getTaxInformation={getTaxInformation}
          candidateId={candidateId}
          notify={notify}
          data={taxInformation?.infromation}
          isAddMode={taxInformationDrawerData.isAddMode}
          isActive={taxInformationDrawerData.isShowTaxInformationDrawer}
          onDrawerClose={taxInformationDrawerData.onDrawerClose}
          headerTitle={taxInformationDrawerData.headerTitle}
          primaryButtonTitle={taxInformationDrawerData.primaryButtonTitle}
          headerSubtitle={taxInformationDrawerData.headerSubtitle}
        />
      )}
    </div>
  )
}

PayrollTabView.defaultProps = {}

export default PayrollTabView
