import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deleteCriminalHistoryInterface {
  criminalHistoryId?: any
  candidateId?: string
}

export const useDeleteCriminalHistory = () => {
  const mutate = useMutation(
    ({ candidateId, criminalHistoryId }: deleteCriminalHistoryInterface) => {
      return deleteAPI(
        `/Candidate/${candidateId}/CriminalHistory/${criminalHistoryId}`,
      )
    },
  )

  return mutate
}

export default useDeleteCriminalHistory
