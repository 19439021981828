/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  Alerts,
  Checkbox,
  Radio,
  RightSideModal,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useEditPoliceCheck,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'

import { PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface EditPoliceCheckProps {
  [x: string]: any
}

export const EditPoliceCheck: FC<EditPoliceCheckProps> = ({
  policeCheckCardData,
  candidateId,
  isActive,
  getPoliceCheckCard,
  notify,
  setIsShowEditPoliceCheck,
}: EditPoliceCheckProps) => {
  const { documentCategory } = useSelector((state: any) => state.document)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** police check details state */
  const [policeCheckDetails, setPoliceCheckDetails] = useState<any>({
    status: false,
    policeCheckId: '',
    statutoryUrl: '',
  })

  const [policeCheckIds, setPoliceCheckIds] = useState<any>({
    categoryId: '',
    policeCheckReportId: '',
  })

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>()

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** get police check and  statutory declaration details and set Id */
          const PoliceCheckReport = successData?.documentTypes?.find(
            (item) => item?.name === 'Police Check Report',
          )

          setPoliceCheckIds({
            ...policeCheckIds,
            categoryId: documentCategory?.id,
            policeCheckReportId: PoliceCheckReport?.id,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** get document category */
  useEffect(() => {
    documentCategory && getDocumentTypeByCategoryId(documentCategory?.value)
  }, [documentCategory, isActive])

  /** set police check card data to drawer */
  useEffect(() => {
    if (isActive) {
      setAttachments(null)

      const { isPoliceClearance, statutoryUrl, attachmentUrls, policeCheckId } =
        policeCheckCardData || {}

      setPoliceCheckDetails({
        ...policeCheckDetails,
        status: isPoliceClearance,
        statutoryUrl: statutoryUrl,
        policeCheckId: policeCheckId,
      })

      /** map selected document files array and set to my files */
      if (attachmentUrls) {
        const tempObj = { path: attachmentUrls, isExistingFile: true }

        setAttachments(tempObj)
      }
    }
  }, [policeCheckCardData, isActive])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setAttachments({ ...acceptedFiles })
    },
    [attachments],
  )

  /** on file drop file size and type validate */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** edit police check  */
  const { mutate: editPoliceCheckMutate, isLoading: editPoliceCheckIsLoading } =
    useEditPoliceCheck()

  /** Process the edit police check */
  async function editPoliceCheck() {
    editPoliceCheckMutate(
      {
        candidateId,
        documentDetails: policeCheckDetails,
        attachments,
        policeCheckIds,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            status: 'Success',
          })
          getPoliceCheckCard()
          setIsShowEditPoliceCheck(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          if (errData?.message?.includes('Infrastructure :')) {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message.split(':').slice(1),
              status: 'Error',
            })
          } else {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  /** handle primary button disable */
  useEffect(() => {
    const { status } = policeCheckDetails || {}

    ;(status && attachments) || !status
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [policeCheckDetails, attachments])

  const resetFields = () => {
    setAttachments(null)
    setPoliceCheckDetails({
      ...policeCheckDetails,
      status: policeCheckCardData?.isPoliceClearance,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Edit Police Check Status'
      headerSubtitle='Edit police check status of the candidate'
      primaryButtonTitle='Edit Police Check Status'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      secondaryButtonTitle='Cancel'
      onHeaderCloseButtonClick={() => {
        setIsShowEditPoliceCheck(false)
        resetFields()
      }}
      onFooterAddButtonClick={() => {
        editPoliceCheck()
      }}
      onFooterCancelButtonClick={() => {
        setIsShowEditPoliceCheck(false)
        resetFields()
      }}>
      {/* Loader */}
      {editPoliceCheckIsLoading && <PageLoader size='xxs' />}

      {/* main body */}
      <div className='flex w-full flex-col h-fit py-[22px] px-6'>
        <span className='text-small font-Medium text-Gray-800'>
          Do you have an Australian police clearance?
        </span>

        <div className='flex pt-[10px] gap-6'>
          <Radio
            checked={policeCheckDetails?.status}
            id='yes'
            className='cursor-pointer select-none'
            onChange={() => {
              setPoliceCheckDetails({
                ...policeCheckDetails,
                status: true,
              })
            }}
            name='statusYes'
            helperText=''
            labelText='Yes'
          />

          <Radio
            checked={!policeCheckDetails?.status}
            disabled={policeCheckCardData?.status}
            className={CN('select-none', {
              'cursor-pointer': !policeCheckCardData?.status,
              'cursor-not-allowed': policeCheckCardData?.status,
            })}
            id='no'
            onChange={() => {
              setPoliceCheckDetails({
                ...policeCheckDetails,
                status: false,
              })
            }}
            name='statusNo'
            helperText=''
            labelText='No'
          />
        </div>

        {/* add police check report section  */}
        {policeCheckDetails?.status && (
          <div className='flex flex-col'>
            <div className='flex flex-col pt-5'>
              <span className='font-Medium text-small text-Gray-800'>
                Attachments
              </span>

              <span className='font-Regular text-extra-small text-Gray-600'>
                Max file size 5MB - supports docx, pdf, jpg, jpeg and png,
                multiple uploads
              </span>
            </div>

            {/* Upload Document section ---------------------------------------------------------------- */}
            <section className='container mt-4'>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                <div className='flex flex-col'>
                  <span className='text-small text-Gray-800 font-Medium'>
                    Upload Document
                  </span>

                  <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 mt-1 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                    <i className='ri-upload-2-line pr-2' />

                    <span className='text-small font-Medium'>Upload File</span>
                  </div>
                </div>
              </div>

              {attachments && (
                <div className='flex w-full mt-2'>
                  <ul className='flex flex-col w-full gap-2'>
                    <li className='flex justify-between items-center bg-white rounded-md border border-Gray-200 w-full px-3 py-1'>
                      <div className='flex justify-start items-center w-4/5'>
                        <i className='ri-attachment-2 text-Gray-400 mr-1' />

                        <span
                          className={CN(
                            'flex text-small font-Medium line-clamp-1 break-all',
                            {
                              'text-Green-500': attachments?.isExistingFile,
                              'text-Gray-800': !attachments?.isExistingFile,
                            },
                          )}>
                          {attachments?.isExistingFile
                            ? attachments?.path
                                .split('/')[2]
                                .split('_')
                                .slice(1)
                            : attachments[0]?.path}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </section>
          </div>
        )}

        {!policeCheckDetails?.status &&
          policeCheckCardData?.statutoryUrl !== null && (
            <div className='flex flex-col mt-5'>
              <span className='font-SemiBold text-Gray-800'>
                Statutory Declaration
              </span>

              {/* Statutory declaration section */}
              <div className='flex flex-col mt-5'>
                <div className='flex justify-start items-end'>
                  <span className='font-Regular text-Gray-800'>I, of </span>

                  <TextField
                    className='mx-2'
                    value={policeCheckCardData?.jobTitle}
                    onChange={() => {
                      //
                    }}
                    placeholder=''
                    isDisabled
                  />
                </div>

                <span className='font-Regular text-Gray-800 pt-2'>
                  , do solemnly and sincerely declare that:
                </span>

                <Checkbox
                  labelText='I have never been convicted of a criminal offense in Australia'
                  isDisabled
                  defaultChecked={policeCheckCardData?.isCriminalAustralia}
                  className='mt-1'
                  labelTextClassName='font-Regular'
                  checkboxClassName='ml-0'
                />

                <Checkbox
                  labelText='I have never been convicted of a criminal offense and/or sentenced to imprisonment in any country other than Australia'
                  isDisabled
                  defaultChecked={policeCheckCardData?.isCriminalOther}
                  className='mt-1'
                  labelTextClassName='font-Regular'
                  checkboxClassName='ml-0'
                />

                <span className='mt-2 font-Regular text-Gray-800'>
                  I acknowledge that this declaration is true and correct, and I
                  make it with the understanding and belief that a person who
                  makes a false declaration is liable to the penalties of
                  perjury.
                </span>

                <span className='mt-4 text-extra-small font-Medium text-Gray-800'>
                  Candidate Signature
                </span>

                {/* signature image */}
                <img
                  src={policeCheckCardData?.statutoryUrl}
                  alt='sign'
                  className='w-[105px] h-11 object-contain  mt-1'
                />

                <div className='flex flex-col mt-4 font-Medium text-Gray-500'>
                  <span className='text-extra-small'>Declared at,</span>

                  <span className='text-small'>
                    Level 9, 10 Queen St, Melbourne
                  </span>

                  <span className='text-small'>
                    {policeCheckCardData?.createdOn
                      ? dateToReadableString(policeCheckCardData?.createdOn)
                      : `N/A`}
                  </span>
                </div>
              </div>
            </div>
          )}

        {/* alert shows when no police or statutory declaration */}
        {!policeCheckDetails?.status &&
          policeCheckCardData?.statutoryUrl === null && (
            <div className='flex flex-col mt-5'>
              <span className='font-SemiBold text-Gray-800'>
                Statutory Declaration
              </span>

              <Alerts
                alertBody='Candidate has not yet given the consent to the statutory declaration'
                alertHeader='Warning'
                isCloseIcon={false}
                isIcon={false}
                status='Warning'
                className='w-[450px] mt-5'
              />
            </div>
          )}
      </div>
    </RightSideModal>
  )
}

EditPoliceCheck.defaultProps = {}

export default EditPoliceCheck
