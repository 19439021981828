/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  documentTitle: '',
  documentCategory: null,
  documentType: null,
  documentCategoriesList: [],
}

const documentSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false),
        (state.documentTitle = ''),
        (state.documentCategory = null),
        (state.documentType = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setDocumentTitleState(state, { payload }) {
      state.documentTitle = payload
    },

    setDocumentCategoryState(state, { payload }) {
      state.documentCategory = payload
    },

    setDocumentTypeState(state, { payload }) {
      state.documentType = payload
    },

    setDocumentCategoriesListState(state, { payload }) {
      state.documentCategoriesList = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setDocumentTitleState,
  setDocumentCategoryState,
  setDocumentTypeState,
  setDocumentCategoriesListState,
} = documentSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setDocumentTitle =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentTitleState(value))
  }

export const setDocumentCategory =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentCategoryState(value))
  }

export const setDocumentType =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentTypeState(value))
  }

export const setDocumentCategoriesList =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentCategoriesListState(value))
  }

export default documentSlice.reducer
