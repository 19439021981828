/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import certificateIcon from 'assets/images/CertificationIcon.svg'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface CandidateLicenseAndCertificationsCardProps {
  onAddNewClick?: any | undefined
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  [x: string]: any
}

export const CandidateLicenseAndCertificationsCard: FC<
  CandidateLicenseAndCertificationsCardProps
> = ({
  candidateCertificationList,
  onAddNewClick,
  onDeleteClick,
  onEditClick,
  setSelectedDocumentDetails,
  getDocumentSignedUrlsById,
  isLoading,
  setDocumentTitles,
  setIsShowDocumentViewModal,
  setShowEducationMoreButtonOption,
  setShowLicenseMoreButtonOption,
  showLicenseMoreButtonOption,
  ...restProps
}: CandidateLicenseAndCertificationsCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  return (
    <div
      className='w-1/2 h-fit border-[1px] bg-white border-Gray-200 rounded-md pb-9 relative'
      {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      {/** Heading and ADD Button =========================================>>>>> */}
      <div className='flex justify-between pt-[19px] pl-5 pr-4'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          License & Certifications
        </span>

        <Button
          onClick={onAddNewClick}
          textTheme='blue'
          size='sm'
          theme='white'>
          Add New
        </Button>
      </div>

      <div className='flex flex-col'>
        {candidateCertificationList?.length > 0 &&
          candidateCertificationList?.map((itm: any, index: any) => (
            <div
              className='flex justify-between items-start px-6 pt-4'
              key={index}>
              <div className='flex'>
                <img src={certificateIcon} alt='' className='w-10 h-10' />

                <div className='pl-4'>
                  <div className='flex flex-col'>
                    {/** Document Type =========================================>>>>>>>>> */}
                    <div className='flex'>
                      <span className='font-SemiBold text-Gray-800 max-w-[300px] line-clamp-1'>
                        {itm?.type}
                      </span>

                      {/** Document Status with react tooltip ======================>>>>>>>*/}
                      {itm?.status && (
                        <DocumentStatusIcon
                          status={itm?.status}
                          auditRequired={itm?.auditRequired}
                        />
                      )}
                    </div>

                    {/** Uploaded, Expired and Due Dates ==================================>>>>>>> */}
                    <div className='flex divide-x-[1px] divide-Gray-200'>
                      <span className='font-Medium text-extra-small text-Gray-500 pt-[6px] pr-2'>
                        Uploaded:{' '}
                        {itm?.createdOn
                          ? dateToReadableString(itm?.createdOn)
                          : ' ---'}
                      </span>

                      <div className='flex'>
                        {itm?.status === 'Expired' && (
                          <i className='ri-error-warning-fill text-extra-small text-TerraCotta-500 pt-[6px] pl-2'></i>
                        )}

                        <span
                          className={CN(
                            'font-Medium text-extra-small pt-[6px] px-2',
                            {
                              'text-Red-500': itm?.status === 'Expired',
                              'text-Gray-500': itm?.status !== 'Expired',
                            },
                          )}>
                          Expiry:{' '}
                          {itm?.expiryOn
                            ? dateToReadableString(itm?.expiryOn)
                            : ' --- '}
                        </span>
                      </div>

                      {itm?.status === 'Expired' && (
                        <span className='font-Medium text-extra-small text-Gray-500 pt-[6px] px-2'>
                          Due date:{' '}
                          {itm?.dueOn
                            ? dateToReadableString(itm?.dueOn)
                            : ' ---'}
                        </span>
                      )}
                    </div>

                    {/** Notes ==================================================>>>>>>>>>*/}
                    <span className='font-Regular text-small text-Gray-600 pt-1'>
                      {itm?.notes}
                    </span>
                  </div>
                </div>
              </div>

              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowEducationMoreButtonOption({
                      index: index,
                    })
                    setShowLicenseMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showLicenseMoreButtonOption.index
                          ? !showLicenseMoreButtonOption.isEnable
                          : true,
                    })

                    /** set selected item details to state for edit and delete*/
                    setSelectedDocumentDetails(itm)
                  }}
                />

                {/** More button DropDown =================================================>>>>>>*/}
                {showLicenseMoreButtonOption.isEnable &&
                  index === showLicenseMoreButtonOption.index && (
                    <div className='flex flex-col absolute right-0 top-[42px] w-[169px] pt-2 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40'>
                      <span
                        className={CN('font-Regular text-Gray-800 pl-3 mb-4', {
                          'opacity-50 hover:bg-Gray-white':
                            itm?.attachmentTypeSignedUrls?.length === 0,
                          'hover:bg-Gray-100':
                            itm?.attachmentTypeSignedUrls?.length > 0,
                        })}
                        onClick={(e: any) => {
                          if (itm?.attachmentTypeSignedUrls?.length > 0) {
                            e.stopPropagation()
                            getDocumentSignedUrlsById(itm?.documentId)
                            setDocumentTitles({
                              license: itm?.type,
                              education: '',
                            })
                            setIsShowDocumentViewModal(true)
                            setShowLicenseMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })
                          }
                        }}>
                        View Attachments
                      </span>

                      <span
                        className='font-Regular text-Gray-800 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowLicenseMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onEditClick && onEditClick()
                        }}>
                        Edit
                      </span>

                      <span
                        className={CN(
                          'font-Regular text-Gray-800 pl-3 mb-4 hover:bg-Gray-100',
                          {
                            'hover:bg-Gray-100':
                              (itm?.auditRequired &&
                                itm?.status === 'Request_Submission') ||
                              itm?.status === 'Pending_Audit',

                            'opacity-50 hover:bg-Gray-white':
                              !itm?.auditRequired ||
                              itm?.status === 'Active' ||
                              itm?.status === 'Audit_Failed' ||
                              itm?.status === 'Expired' ||
                              itm?.status === 'Archived' ||
                              (!itm?.auditRequired &&
                                itm?.status === 'Request_Submission'),
                          },
                        )}
                        onClick={(e: any) => {
                          if (
                            (itm?.auditRequired &&
                              itm?.status === 'Request_Submission') ||
                            itm?.status === 'Pending_Audit'
                          ) {
                            e.stopPropagation()

                            setShowLicenseMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })

                            dispatch(setSelectedDocumentId(itm?.documentId))

                            /** selected candidate states set to store */
                            dispatch(
                              setSelectedCandidate({
                                name: candidateName,
                                id: candidateId,
                              }),
                            )

                            /** audit check modal open handle from state */
                            dispatch(
                              setIsAuditModal({
                                isOpen: true,
                                isFooterShow: false,
                                isActiveButton: false,
                                tab: null,
                              }),
                            )
                          }
                        }}>
                        Perform Audit
                      </span>

                      <span
                        className='font-Regular text-Red-500 pl-3 mb-4 hover:bg-Gray-100'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setShowLicenseMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                          onDeleteClick && onDeleteClick()
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}

        {candidateCertificationList?.length <= 0 && (
          <div className='flex flex-col justify-center items-center'>
            <i className='ri-file-search-line text-[100px] text-Gray-300'></i>

            <span className='font-Medium text-heading-3 text-Gray-500'>
              Not Documents Found
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

CandidateLicenseAndCertificationsCard.defaultProps = {
  onAddNewClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default CandidateLicenseAndCertificationsCard
