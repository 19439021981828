import React, { FC, useState } from 'react'
import { Avatar, Button, Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format } from 'date-fns'
import {
  CandidateProfileCardType,
  CandidateReferee,
  useDeleteCandidateReferee,
} from 'framework/api/methods'

import { MoreMenuButton, PageLoader } from 'components/atoms'
import {
  CandidateRefereeStatusCard,
  CandidateReferenceCheckSnack,
  PhoneRefCheck,
  SendWebRefCheckReminderModal,
} from 'components/molecules'
import { timeGap } from 'utils/timeGap'

export interface CandidateRefereeCardProps {
  candidate: CandidateProfileCardType
  getCandidateRefereesList: () => void
  onCallRecordingsClick?: () => void | undefined
  onDownloadReportClick?: () => void
  onViewReportClick?: () => void
  referee: CandidateReferee
  refereeIndex: number
  onEditClick?: () => void | undefined
  onClickWebRefCheck?: () => void | undefined
  [x: string]: any
}

export const CandidateRefereeCard: FC<CandidateRefereeCardProps> = ({
  candidate,
  className,
  getCandidateRefereesList,
  onCallRecordingsClick,
  onClickWebRefCheck,
  onDownloadReportClick,
  onEditClick,
  refereeCount,
  onViewReportClick,
  referee,
  refereeIndex,
  setSelectedRefereeDetails,
  ...restProps
}: CandidateRefereeCardProps) => {
  const RefereeCardClasses = CN(
    `flex flex-col bg-white border border-Gray-200 rounded-lg p-5`,
    className,
  )

  const [isOpenPhoneRefCheck, setIsOpenPhoneRefCheck] = useState(false)
  const [isOpenRefereeDelete, setIsOpenRefereeDelete] = useState(false)
  const [
    isSendWebRefCheckReminderModalOpen,
    setIsSendWebRefCheckReminderModalOpen,
  ] = useState(false)

  const {
    mutateAsync: deleteCandidateReferee,
    isLoading: isLoadingDeleteCandidateReferee,
  } = useDeleteCandidateReferee(candidate.id, referee.id, refereeIndex)

  return (
    <div className={RefereeCardClasses} {...restProps} key={refereeIndex}>
      <div className='flex mb-[33px] box-border'>
        <div className='flex items-center'>
          <span className='text-heading-4 text-Gray-800 font-Bold mr-2'>
            Referee {refereeIndex.toString().padStart(2, '0')}
          </span>

          <CandidateReferenceCheckSnack status={referee.status} />
        </div>

        {isLoadingDeleteCandidateReferee && <PageLoader size='xxs' />}
        {/* Phone Ref Check */}
        <PhoneRefCheck
          referee={referee}
          candidate={candidate}
          isOpen={isOpenPhoneRefCheck}
          hideModal={() => setIsOpenPhoneRefCheck(false)}
          getCandidateRefereesList={getCandidateRefereesList}
        />

        <SendWebRefCheckReminderModal
          referee={referee}
          refereeIndex={refereeIndex}
          isActive={isSendWebRefCheckReminderModalOpen}
          setIsActive={setIsSendWebRefCheckReminderModalOpen}
          getCandidateRefereesList={getCandidateRefereesList}
        />

        {/* Delete Referee modal */}
        <Modal
          isActive={isOpenRefereeDelete}
          onClickPrimaryBtn={() => {
            deleteCandidateReferee().finally(() => {
              setIsOpenRefereeDelete(false)
              getCandidateRefereesList()
            })
          }}
          onClickSecondaryBtn={() => {
            setIsOpenRefereeDelete(false)
          }}
          primaryButtonProps={{
            style: { width: '100%', marginRight: '12px' },
            // isLoading: deleteClientContactAvatarIsLoading,
          }}
          secondaryButtonProps={{
            style: { width: '100%', color: '#1F2937' },
          }}
          onOverlayClick={() => {
            setIsOpenRefereeDelete(false)
          }}
          modalProps={{
            style: { width: '466px' },
          }}
          footerProps={{
            style: {
              justifyContent: 'space-between',
              paddingLeft: '23px',
              paddingRight: '23px',
            },
          }}
          isHeaderShow={false}
          primaryButtonTitle='Yes, I’m Sure'
          secondaryButtonTitle='Close'>
          <div className='flex flex-col justify-center items-center p-6'>
            <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
              <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
            </div>

            <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
              Delete Referee?
            </span>

            <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
              Are you sure you want to delete referee?
            </span>
          </div>
        </Modal>

        {/* Ref Check types */}
        <div className='flex flex-auto justify-end absolute right-[60px]'>
          <Button
            textTheme='blue'
            size='sm'
            theme='white'
            className='mx-2'
            isDisabled={referee?.state !== 'NOT_SENT'}
            onClick={() => {
              onClickWebRefCheck && onClickWebRefCheck()
            }}
            iconLeft={<i className='ri-global-line text-small' />}>
            <span className='text-small font-Medium'>Web Ref Check</span>
          </Button>

          <Button
            textTheme='blue'
            size='sm'
            theme='white'
            isDisabled={referee.refereeResponse?.responseType === 'PHONE_CHECK'}
            iconLeft={<i className='ri-phone-line text-small' />}
            onClick={() => {
              setIsOpenPhoneRefCheck(true)
            }}>
            <span className='text-small font-Medium'>Phone Ref Check</span>
          </Button>

          <div className='h-[38px] w-[38px]'>
            <MoreMenuButton
              className='ml-2'
              position={
                refereeCount <= 1 || refereeIndex !== refereeCount
                  ? 'bottom'
                  : 'left'
              }
              actionButtons={[
                {
                  iconClass: 'ri-eye-line',
                  onClick: () => {
                    setSelectedRefereeDetails(referee)
                    onViewReportClick && onViewReportClick()
                  },
                  title: 'View Report',
                  status: 'normal',
                  disabled: !referee?.refereeResponse,
                },
                {
                  iconClass: 'ri-download-line',
                  onClick: () => {
                    onDownloadReportClick && onDownloadReportClick()
                  },
                  title: 'Download Report',
                  status: 'normal',
                  disabled: !referee?.refereeResponse,
                },
                {
                  iconClass: 'ri-pencil-line',
                  onClick: () => {
                    onEditClick && onEditClick()
                  },
                  title: 'Edit Referee',
                  status: 'normal',
                },
                {
                  iconClass: 'ri-notification-4-line',
                  onClick: () => {
                    setIsSendWebRefCheckReminderModalOpen(true)
                  },
                  title: 'Send Web Ref Check Reminder',
                  status: 'normal',
                  disabled: referee?.state !== 'AWAITING_RESPONSE',
                },
                {
                  iconClass: 'ri-record-mail-line',
                  onClick: () => {
                    onCallRecordingsClick && onCallRecordingsClick()
                  },
                  title: 'Call Recordings',
                  status: 'normal',
                },
                {
                  iconClass: 'ri-delete-bin-line',
                  onClick: () => {
                    setIsOpenRefereeDelete(true)
                  },
                  title: 'Delete',
                  status: 'danger',
                },
              ]}
              onClickMoreButton={() => {
                referee && setSelectedRefereeDetails(referee)
              }}
            />
          </div>
        </div>
      </div>

      <div className='flex items-start justify-between'>
        <div className='relative mr-4 flex-none'>
          <Avatar
            isHeader={false}
            title={referee.firstName + ' ' + referee.lastName}
            color={'four'}
          />
        </div>
        <div className='flex flex-col w-full'>
          {/* Name with form type status */}
          <div className='flex flex-col'>
            <div className='flex items-center'>
              <span className='text-heading-5 text-Gray-800 mr-2 font-Medium'>
                {referee.firstName + ' ' + referee.lastName}
              </span>

              {/* Status either web or phone ref check sent */}
              {referee.refereeResponse?.responseType === 'PHONE_CHECK' && (
                <span className='flex items-center bg-Gray-100 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                  <i className='flex items-center ri-phone-line text-extra-small text-Cobalt-600' />

                  <span className='text-extra-small font-Medium text-Gray-800'>
                    Phone
                  </span>
                </span>
              )}
              {referee.refereeResponse?.responseType === 'WEB_CHECK' && (
                <span className='flex items-center bg-Gray-100 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                  <i className='flex items-center ri-global-line text-extra-small text-Cobalt-600' />

                  <span className='text-extra-small font-Medium text-Gray-800'>
                    Web Form
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className='flex mt-2'>
            <div className='flex w-1/3'>
              <div className='flex flex-col flex-auto gap-2'>
                <div className='flex items-center'>
                  <i className='flex items-center ri-phone-line text-small text-Cobalt-600 mr-2' />
                  <a
                    href={`tel:${referee?.phone}`}
                    className='text-small font-Medium text-Gray-600'>
                    {referee.phone}
                  </a>
                </div>

                <div className='flex items-center'>
                  <i className='flex items-center ri-mail-line text-small text-Cobalt-600 mr-2' />
                  <a
                    href={`mailto:${referee?.email}`}
                    className='text-small font-Medium text-Gray-600'>
                    {referee.email}
                  </a>
                </div>

                <div className='flex items-center'>
                  <i className='flex items-center ri-suitcase-line text-small text-Cobalt-600 mr-2' />

                  <span className='text-small font-Medium text-Gray-600'>
                    {referee.jobTitle} at {referee.company}
                  </span>
                </div>
              </div>
            </div>

            {/* Referee details section */}
            <div className='flex gap-2 w-1/2'>
              <div className='flex flex-col  gap-2 w-1/2'>
                <span className='text-small text-Gray-500 mr-[10px] font-Regular'>
                  Relationship to candidate:
                </span>

                <span className='text-small text-Gray-500 mr-[10px] font-Regular'>
                  Candidate job position:
                </span>

                <span className='text-small text-Gray-500 mr-[10px] font-Regular'>
                  Work Period:
                </span>
              </div>

              <div className='flex flex-col  gap-2 w-1/2'>
                <span className='text-small font-Medium text-Gray-600'>
                  {referee.candidateRelation}
                </span>

                <span className='text-small font-Medium text-Gray-600'>
                  {referee.candidatePosition}
                </span>

                <span className='text-small font-Medium text-Gray-600'>
                  {referee.endDate && referee.startDate
                    ? timeGap(referee.endDate, referee.startDate)
                    : format(new Date(referee.startDate), 'MM/dd/yyyy') +
                      ` - ` +
                      format(new Date(referee.endDate), 'MM/dd/yyyy')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-auto items-end min-w-[246px]'>
          <CandidateRefereeStatusCard
            status={referee.status}
            state={referee.state}
            sentDate={referee?.requestSendDate}
            updatedDate={
              referee.status === 'Failed'
                ? referee.updatedOn
                : referee?.refereeResponse?.updatedOn
            }
          />
        </div>

        <SendWebRefCheckReminderModal
          referee={referee}
          refereeIndex={refereeIndex}
          isActive={isSendWebRefCheckReminderModalOpen}
          setIsActive={setIsSendWebRefCheckReminderModalOpen}
          getCandidateRefereesList={getCandidateRefereesList}
        />
      </div>
    </div>
  )
}

CandidateRefereeCard.defaultProps = {
  onClickWebRefCheck: undefined,
}

export default CandidateRefereeCard
