/** candidate's document state  */
export const Request_Submission = 'Request_Submission'
export const Pending_Audit = 'Pending_Audit'
export const Audit_Failed = 'Audit_Failed'
export const Active = 'Active'
export const Expired = 'Expired'
export const Archived = 'Archived'

export const documentStateList = [
  {
    value: 'Request_Submission',
    label: 'Waiting for Documents',
  },
  {
    value: 'Pending_Audit',
    label: 'Audit Pending',
  },
  {
    value: 'Audit_Failed',
    label: 'Audit Failed',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Expired',
    label: 'Expired',
  },
]

/** candidate's document status constants  */
export const documentHistoryStatusTitle = {
  AuditFailed: 'Audit failed',
  AuditPassed: 'Audit passed',
  DocumentActivated: 'Document activated',
  DocumentArchived: 'Document archived',
  DocumentExpired: 'Document expired',
  DocumentRemoved: 'Document removed',
  DocumentUnArchived: 'Document unarchived',
  NewDocumentCreated: 'New document created',
  PendingAuditCheck: 'Pending audit check',
  RequestedNewDocuments: 'Requested new documents',
  ReuploadedTheDocument: 'Reuploaded documents',
}

export const documentCategoryName = {
  Education: 'Education',
  CertificationsAndLicense: 'Certifications/License',
  Vaccinations: 'Vaccinations',
  PrescriptionsAndMedicalConditions: 'Prescriptions/Medical Conditions',
  MedicalIncidents: 'Medical Incidents',
  Visa: 'Visa',
  PoliceCheck: 'Police Check',
  SuperFund: 'Super Fund',
  Other: 'Other',
  Contract: 'Employment Contract',
}
