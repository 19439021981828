/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar, Badge } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'

import { dateToReadableString } from 'utils/dateToReadableString'

export const CandidateListColumn = (navigate: any) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'firstName',
      forSorting: 'firstname',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[212px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='w-full cursor-pointer'
            onClick={() => {
              navigate(
                `/candidate-list/candidate-profile?&candidate_id=${original.id}&candidate_name=${original.firstName} ${original.lastName}`,
              )
            }}>
            <Avatar
              imageURL={
                original?.avatarUrl ? original?.avatarUrl : candidateDefaultLogo
              }
              titleClassName='flex max-w-[200px] line-clamp-1'
              size='xs'
              color='one'
              title={`${original?.firstName} ${original?.lastName}`}
              /** Showing warning and medical notification icons */
              titleIcons={
                <div className='flex'>
                  {original.isWarning && (
                    <i className='ri-error-warning-fill text-Red-500 pl-1'></i>
                  )}

                  {original.isMedicalNotification && (
                    <i className='ri-shield-cross-fill text-Cobalt-500 pl-1'></i>
                  )}
                </div>
              }
              tag={original?.email}
            />
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[112px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge
              size='small'
              theme={
                original?.status === 'Active'
                  ? 'green'
                  : original?.status === 'Pending'
                  ? 'yellow'
                  : original?.status === 'Disabled'
                  ? 'red'
                  : undefined
              }>
              {original?.status}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
      forSorting: 'phone',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.phone}</div>
      },
    },
    {
      Header: 'MAIN EMPLOYER',
      accessor: 'mainClientName',
      forSorting: 'mainclientname',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.mainClientName ? original?.mainClientName : ' - '}
          </div>
        )
      },
    },
    {
      Header: 'AGE',
      accessor: 'age',
      forSorting: 'age',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[70px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.age}</div>
      },
    },
    {
      Header: 'JOINED DATE',
      accessor: 'createdOn',
      forSorting: 'createdOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[130px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{dateToReadableString(original?.createdOn)}</div>
      },
    },
    {
      Header: 'SUBURB & STATE',
      accessor: 'suburb',
      forSorting: 'suburb',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[220px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='w-full line-clamp-1'>{`${
            original?.address?.suburb ? original?.address?.suburb : ' - '
          }, ${
            original?.address?.state ? original?.address?.state : ' - '
          }`}</div>
        )
      },
    },
    {
      Header: 'LAST WORKED',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[171px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: () => {
        /** Since data is not yet finalized by BE team, here used N/A  */
        return <div>{'N/A'}</div>
      },
    },
  ]

  return COLUMNS
}

export default CandidateListColumn
