import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addCriminalHistoryProps {
  candidateId?: any
  formData?: any
}

export const useAddCriminalHistory = () => {
  const mutate = useMutation(
    ({ candidateId, formData }: addCriminalHistoryProps) => {
      return postAPI(`Candidate/${candidateId}/CriminalHistory`, {
        recordName: formData?.name,
        chargedDate: formData?.chargedDate,
        notes: formData?.note,
      })
    },
  )

  return mutate
}

export default useAddCriminalHistory
