import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateAgencyProfileDetailsInterface {
  id?: string
  obj?: any
}

export const useUpdateAgencyProfileDetails = () => {
  const mutate = useMutation(
    ({ obj, id }: UpdateAgencyProfileDetailsInterface) => {
      let reqBody = {}
      reqBody = {
        name: obj.name,
        abn: obj.abn,
        industry: obj.industry,
        website: obj.website,
        description: obj.description,
      }
      return putAPI(`/AgencyProfile/${id}`, {
        ...reqBody,
      })
    },
  )

  return mutate
}

export default useUpdateAgencyProfileDetails
