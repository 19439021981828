/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export const DocumentsTabListColumn = (
  getDocumentSignedUrlsById,
  setIsDownloadEnable,
  setIsShowDocumentArchiveModal,
  isShowDocumentArchiveModal,
  setIsShowDocumentDeleteModal,
  setIsShowDocumentHistoryDrawer,
  setIsShowDocumentViewModal,
  setSelectedDocument,
  useSelector,
  useState,
  useEffect,
  tableData,
  setTableData,
  dispatch,
  candidateName,
  candidateId,
) => {
  const COLUMNS = [
    {
      Header: 'DOCUMENT TITLE',
      accessor: 'title',
      forSorting: 'title',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[300px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex max-w-[350px] line-clamp-1 pt-[12px]',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='flex'>
            {original?.title}
            <div className='pl-2'>
              {original?.isArchived ? (
                <div className='flex justify-center items-center w-[20px] h-[20px] rounded-full border-[1px] border-Cobalt-100 bg-Cobalt-50'>
                  <i
                    className='ri-archive-line text-[10px] text-Cobalt-400'
                    data-tip='Archived'
                    data-for='Archived'
                  />

                  <ReactTooltip
                    place='right'
                    id='Archived'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </div>
              ) : undefined}
            </div>
          </div>
        )
      },
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      forSorting: 'type',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[280px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.type}</div>
      },
    },
    {
      Header: 'Category',
      accessor: 'category',
      forSorting: 'category',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[232px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.category}</div>
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[127px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <div className='pr-[10px]'>
              {original?.status ? (
                <DocumentStatusIcon
                  status={original?.status}
                  auditRequired={original?.auditRequired}
                />
              ) : (
                ' - '
              )}
            </div>
          </div>
        )
      },
    },
    {
      Header: 'Due Date',
      accessor: 'dueOn',
      forSorting: 'dueon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[124px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.dueOn ? dateToReadableString(original?.dueOn) : ' - '}
          </div>
        )
      },
    },
    {
      Header: 'Expiry',
      accessor: 'expiryOn',
      forSorting: 'expiryon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[130px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div
            className={
              original?.status === 'Expired'
                ? 'text-TerraCotta-600'
                : 'text-Gray-900'
            }>
            {original?.expiryOn
              ? dateToReadableString(original?.expiryOn)
              : ' - '}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[65px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row,
          row: { original },
        } = props || {}

        const { status, showMore, isArchived } = original

        const toggleShowMore = (rowData: any) => {
          const toBeUpdateTableData = {
            ...tableData,
            documentDetails: tableData?.documentDetails?.map((item: any) => {
              if (item.documentId === rowData.documentId) {
                return {
                  ...item,
                  showMore: !item?.showMore,
                }
              } else {
                return {
                  ...item,
                  showMore: false,
                }
              }
            }),
          }
          setTableData(toBeUpdateTableData)
        }

        return (
          <>
            <div className='relative'>
              <MoreButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  toggleShowMore(original)
                  setSelectedDocument(original)
                  setIsDownloadEnable(false)
                }}
                className={CN({
                  'bg-Gray-100': showMore,
                })}
              />
            </div>

            {showMore && (
              <div
                className={CN(
                  'absolute  right-[55px] z-40 flex flex-col justify-start w-[152px] shadow-xl border-[2px] border-Gray-200 rounded-md py-3 cursor-pointer bg-white',
                  {
                    'top-[25px]': row.index < 5,
                    'top-[-170px]': row.index >= 5,
                  },
                )}
                key={original.documentId}>
                <span
                  className={CN(
                    'font-Regular text-Gray-800 text-heading-5 pl-3 mb-2 ',
                    {
                      'opacity-50 hover:bg-Gray-white':
                        original?.status === 'Request_Submission' ||
                        status === 'New',
                      'hover:bg-Gray-100':
                        original?.status !== 'Request_Submission' ||
                        status !== 'New',
                    },
                  )}
                  onClick={() => {
                    if (status !== 'Request_Submission' && status !== 'New') {
                      toggleShowMore(original)
                      getDocumentSignedUrlsById(original?.documentId)
                      setIsShowDocumentViewModal(true)
                    }
                  }}>
                  View
                </span>

                <span
                  className='flex items-center font-Regular text-Gray-800 text-heading-5 pl-3 mb-2 hover:bg-Gray-100'
                  onClick={() => {
                    toggleShowMore(original)
                    setIsShowDocumentHistoryDrawer(true)
                  }}>
                  History
                </span>

                <span
                  className={CN(
                    'flex items-center font-Regular text-Gray-800 text-heading-5 pl-3 mb-2 hover:bg-Gray-100',
                    {
                      'opacity-50 hover:bg-Gray-white':
                        original?.attachmentUrls?.length === 0,
                    },
                  )}
                  // attachmentUrls
                  onClick={() => {
                    if (original?.attachmentUrls?.length > 0) {
                      toggleShowMore(original)
                      getDocumentSignedUrlsById(original?.documentId)
                      setIsDownloadEnable(true)
                    }
                  }}>
                  Download
                </span>

                {original?.category === 'Other' &&
                  original?.auditRequired &&
                  (original?.status === 'Pending_Audit' ||
                    original?.status === 'Request_Submission') && (
                    <span
                      className='flex items-center font-Regular text-Gray-800 text-heading-5 pl-3 mb-2 hover:bg-Gray-100'
                      onClick={(e: any) => {
                        e.stopPropagation()

                        toggleShowMore(original)

                        dispatch(setSelectedDocumentId(original?.documentId))

                        /** selected candidate states set to store */
                        dispatch(
                          setSelectedCandidate({
                            name: candidateName,
                            id: candidateId,
                          }),
                        )

                        /** audit check modal open handle from state */
                        dispatch(
                          setIsAuditModal({
                            isOpen: true,
                            isFooterShow: false,
                            isActiveButton: false,
                            tab: null,
                          }),
                        )
                      }}>
                      Perform Audit
                    </span>
                  )}

                <span
                  className='flex items-center font-Regular text-Gray-800 text-heading-5 pl-3 mb-2 hover:bg-Gray-100'
                  onClick={() => {
                    toggleShowMore(original)

                    setIsShowDocumentArchiveModal({
                      ...isShowDocumentArchiveModal,
                      enable: true,
                      type: isArchived ? 'unarchive' : 'archive',
                    })
                  }}>
                  {isArchived ? `Unarchive` : `Archive`}
                </span>

                <span
                  className='flex items-center font-Regular text-Red-500 text-heading-5 pl-3 hover:bg-Gray-100'
                  onClick={() => {
                    toggleShowMore(original)
                    setIsShowDocumentDeleteModal(true)
                  }}>
                  Delete
                </span>
              </div>
            )}
          </>
        )
      },
    },
  ]

  return COLUMNS
}

export default DocumentsTabListColumn
