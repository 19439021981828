import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getPoliceCheckDetails = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/Candidate/${reqBody.Id}/PoliceCheckDetail`)
  return res
}

export const useGetPoliceCheckDetails = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['policeCheck', reqBody], getPoliceCheckDetails, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
