/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { Avatar } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

interface UserDropDownProps {
  className?: string | undefined
  imageUrl?: string | undefined
  isCustomStyles?: boolean
  onMyAccountClick?: any | undefined
  onLogoutClick?: any | undefined
  jobRole?: string | undefined
  userName?: string | undefined
  [x: string]: any
}

export const UserDropDown: FC<UserDropDownProps> = ({
  className,
  imageUrl,
  isCustomStyles,
  onMyAccountClick,
  onLogoutClick,
  jobRole,
  userName,
  ...restProps
}: UserDropDownProps) => {
  /** user drop down main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN(
        'absolute top-2 right-[80px] flex flex-col w-[195px] rounded-[8px] z-20 bg-white shadow',
        className,
      )
    : className

  return (
    <div className={textFieldMainClassName} {...restProps}>
      <div className='flex pl-3 py-[11px] border-b border-Gray-200'>
        {/** Profile picture */}
        <Avatar
          titleClassName='flex max-w-[160px] line-clamp-1'
          title={userName}
          tag={jobRole}
          imageURL={imageUrl ? imageUrl : undefined}
          size='sm'
        />
      </div>

      {/** Drop Down Body */}
      <div className='flex flex-col cursor-pointer pb-[13px]'>
        <div className='my-account flex text-Gray-500 pt-3 hover:text-Gray-900'>
          <i className='ri-user-3-line pl-[15px]'></i>

          <span
            className='font-Medium text-Gray-500 pl-[10px] hover:text-Gray-900'
            onClick={() => {
              onMyAccountClick && onMyAccountClick()
            }}>
            My Account
          </span>
        </div>

        <div className='logout flex text-[#F36E2A] pt-[14px] hover:text-Red-700'>
          <i className='ri-logout-box-r-line pl-[15px]'></i>

          <span
            className='font-Medium text-[#F36E2A] pl-[10px] hover:text-Red-700'
            onClick={() => {
              onLogoutClick && onLogoutClick()
            }}>
            Logout
          </span>
        </div>
      </div>
    </div>
  )
}

export default UserDropDown

UserDropDown.defaultProps = {
  className: undefined,
  imageUrl: undefined,
  isCustomStyles: false,
  onMyAccountClick: undefined,
  onLogoutClick: undefined,
  jobRole: 'Consultant',
  userName: 'Jason Stathom',
}
