import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'

export interface TaxInformationDetailsCardProps {
  onEditClick: () => void
  onAddClick: () => void
  [x: string]: any
}

export const TaxInformationDetailsCard: FC<TaxInformationDetailsCardProps> = ({
  className,
  isLoading,
  onEditClick,
  onAddClick,
  data,
  ...restProps
}: TaxInformationDetailsCardProps) => {
  const TaxInformationDetailsCardClasses = CN(
    `px-5 py-4 bg-white border-[1px] bg-white border-Gray-200 rounded-md relative`,
    className,
  )

  return (
    <div className={TaxInformationDetailsCardClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      {/** Heading and ADD Button =========================================>>>>> */}
      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Tax Information
        </span>

        {data && (
          <Button
            onClick={() => {
              onEditClick()
            }}
            textTheme='blue'
            size='sm'
            theme='white'>
            Edit
          </Button>
        )}
      </div>
      {!data ? (
        <div className='flex flex-col justify-center items-center mt-6'>
          <i className='ri-refund-2-line text-[33px] text-Gray-500' />
          <span className='text-[20px] text-Gray-800 mt-[10px] font-medium'>
            No Tax Details Found
          </span>
          <span className='text-[14px] text-Gray-600 mt-1 mb-2 font-normal'>
            You can add by clicking below button
          </span>

          <Button
            className='mb-5'
            onClick={() => {
              onAddClick()
            }}
            iconLeft={<i className='ri-add-line' />}
            size='sm'
            theme='cobalt'>
            Add Tax Information
          </Button>
        </div>
      ) : (
        <>
          <div className='flex items-center bg-Gray-100 py-2 px-4 rounded-md mt-5 w-fit'>
            <span className='text-small text-Gray-600 mr-2'>Tax File No:</span>
            <span className='text-Gray-800 font-Medium'>{data.taxFileNo}</span>
          </div>
          <div>
            <div className='flex flex-col mt-5'>
              <span className='text-Gray-800 text-small mb-1'>
                On what basis are you paid?
              </span>
              <span className='text-Cobalt-600'>{data?.onPaid}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-Gray-800 text-small mb-1'>
                Tax Residency - Are you a resident for tax purposes?
              </span>
              <span className='text-Cobalt-600'>
                {data?.taxResidencyTaxPurposes}
              </span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-Gray-800 text-small mb-1'>
                Tax Threshold - Do you want to claim the tax-free threshold from
                this payer?
              </span>
              <span className='text-Cobalt-600'>{data?.taxThresholdClaim}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-Gray-800 text-small mb-1'>
                Tax Threshold - Do you have a Higher Education Loan Program
                (HELP), VET Student Loan (VSL), Financial Supplement (FS),
                Student Start-up Loan (SSL) or Trade Support Loan (TSL) debt?
              </span>
              <span className='text-Cobalt-600'>
                {data?.taxThresholdHigherEducation}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

TaxInformationDetailsCard.defaultProps = {}

export default TaxInformationDetailsCard
