/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Avatar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'
import { AuditFailedTab } from 'static-data/audit/auditTabs'
import {
  setDocumentIdsList,
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedSection,
} from 'store/reducers/audit/auditSlice'

import { dateToAEST } from 'utils/dateToAEST'

export const AuditFailedListColumn = (dispatch: any) => {
  const COLUMNS = [
    {
      Header: 'USER',
      accessor: 'user',
      forSorting: 'firstname',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[320px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='flex w-full'>
            <Avatar
              imageURL={
                original?.imgUrl ? original?.imgUrl : candidateDefaultLogo
              }
              titleClassName='flex max-w-[200px] line-clamp-1'
              size='xs'
              color='one'
              title={original?.fullName}
              tag={original?.email}
            />

            {/* Showing link to candidate profile icons */}
            <div
              className='link-icon cursor-pointer'
              onClick={() => {
                window.open(
                  `/candidate-list/candidate-profile?&candidate_id=${original.candidateId}&candidate_name=${original.fullName}`,
                )
              }}>
              <i className='ri-external-link-fill text-Cobalt-600 pl-1'></i>
            </div>
          </div>
        )
      },
    },
    {
      Header: 'FAILED DOCS',
      accessor: 'failedDocs',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-end',
      cellClassName: 'flex items-center justify-end',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.failedDocumentCount}</div>
      },
    },
    {
      Header: 'LAST FAILED BY',
      accessor: 'lastFailedBy',
      forSorting: 'lastFailedBy',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[227px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>{original?.lastFailedBy ? original?.lastFailedBy : '-'}</div>
        )
      },
    },
    {
      Header: 'LAST FAILED ON',
      accessor: 'lastFailedOn',
      forSorting: 'lastFailedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[252px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.lastFailedOn ? dateToAEST(original?.lastFailedOn) : '-'}
          </div>
        )
      },
    },
    {
      Header: 'LAST DOC SUBMITTED ON',
      accessor: 'lastSubmittedOn',
      forSorting: 'lastSubmittedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[282px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.lastSubmittedOn
              ? dateToAEST(original?.lastSubmittedOn)
              : '-'}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[64px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div
            onClick={() => {
              /** selected candidate states set to store */
              dispatch(
                setSelectedCandidate({
                  name: original?.fullName,
                  id: original?.candidateId,
                }),
              )

              /** selected document id list  set to store */
              dispatch(setDocumentIdsList(original?.documentIds))

              /** clear selected section from state */
              dispatch(setSelectedSection(null))

              /** audit check modal open handle from state */
              dispatch(
                setIsAuditModal({
                  isOpen: true,
                  isFooterShow: true,
                  isActiveButton: false,
                  tab: AuditFailedTab,
                }),
              )
            }}>
            <i
              className='ri-close-circle-line text-heading-5 text-Red-500 cursor-pointer'
              data-tip='Deactivate'
              data-for='Deactivate'
            />

            <ReactTooltip
              place='top'
              id='Deactivate'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
            />
          </div>
        )
      },
    },
  ]

  return COLUMNS
}

export default AuditFailedListColumn
