/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar, Badge } from '@labourhub/labour-hub-ds'
import clientDefaultLogo from 'assets/images/clientDefaultLogo.svg'

import { dateToReadableString } from 'utils/dateToReadableString'

export const ClientListColumn = (navigate: any) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'name',
      forSorting: 'name',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[641px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='w-full cursor-pointer'
            onClick={() => {
              navigate(
                `/client-list/client-profile?&client_id=${original.id}&client_name=${original.name}`,
              )
            }}>
            <Avatar
              imageURL={
                original?.logoUrl ? original?.logoUrl : clientDefaultLogo
              }
              titleClassName='w-full line-clamp-1'
              size='xs'
              title={original?.name}
              tag=''
            />
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'isActiveString',
      forSorting: 'isactive',
      isSortable: true,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[141px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        /** backend issue (variable name) : use  isActive value for active disable status based on true false. and don't use status variable which is related to something else. refused to change by backend. */
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge size='large' theme={original?.isActive ? 'green' : 'red'}>
              {original?.isActiveString}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'CREATED ON',
      accessor: 'createdOn',
      forSorting: 'createdon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[170px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{dateToReadableString(original?.createdOn)}</div>
      },
    },
    {
      Header: 'LAST UPDATED ON',
      accessor: 'updatedOn',
      forSorting: 'updatedon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[171px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{dateToReadableString(original?.updatedOn)}</div>
      },
    },
    {
      Header: 'ACCOUNT SIZE',
      accessor: 'size',
      forSorting: 'size',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[180px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.size}</div>
      },
    },
  ]

  return COLUMNS
}

export default ClientListColumn
