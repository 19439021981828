import React, { FC } from 'react'
import CN from 'classnames'

import { WaitingForDocumentList } from './WaitingForDocumentList'

export interface WaitingForDocumentTabViewProps {
  [x: string]: any
}

export const WaitingForDocumentTabView: FC<WaitingForDocumentTabViewProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  ...restProps
}: WaitingForDocumentTabViewProps) => {
  const ActivatedTabViewClasses = CN(
    `activated-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={ActivatedTabViewClasses} {...restProps}>
      <span className='text-Gray-800 font-Medium'>Waiting for Document</span>

      <span className='font-Regular text-small text-Gray-800 mt-1'>
        All the candidates pending documents are listed down below
      </span>

      <div className='pt-8'>
        <WaitingForDocumentList
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
        />
      </div>
    </div>
  )
}

WaitingForDocumentTabView.defaultProps = {}

export default WaitingForDocumentTabView
