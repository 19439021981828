import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddDocumentInterface {
  candidateId: string
  documentDetails: any
  attachments: any
}

export const useAddDocument = () => {
  const mutate = useMutation(
    ({ candidateId, documentDetails, attachments }: AddDocumentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CandidateId', candidateId)
      bodyFormData.append('CategoryId', documentDetails?.categoryId)
      bodyFormData.append('TypeId', documentDetails?.typeId)
      bodyFormData.append('Title', documentDetails?.title)

      documentDetails?.expiryDate &&
        bodyFormData.append('ExpiryOn', documentDetails?.expiryDate)
      documentDetails?.dueDate &&
        bodyFormData.append('DueOn', documentDetails?.dueDate)

      bodyFormData.append('Notes', documentDetails?.notes)
      bodyFormData.append('AuditRequired', documentDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachments', item)
      })

      return postAPIwithFormData(`/Document/WithAttachments`, bodyFormData, {})
    },
  )

  return mutate
}

export default useAddDocument
