import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditVisaDocumentInterface {
  candidateId: string
  visaDocDetails: any
  attachments: any
  visaId: any
}

export const useEditVisaDocument = () => {
  const mutate = useMutation(
    ({
      candidateId,
      visaDocDetails,
      attachments,
      visaId,
    }: EditVisaDocumentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', visaDocDetails?.categoryId)
      bodyFormData.append('TypeId', visaDocDetails?.docType)
      bodyFormData.append('Title', visaDocDetails?.docName)
      visaDocDetails?.hasExpiryDate
        ? bodyFormData.append('ExpiryOn', visaDocDetails?.expiryOn)
        : bodyFormData.append('ExpiryOn', '')
      // visaDocDetails?.dueOn &&
      //   bodyFormData.append('DueOn', ISOTimeFormat(visaDocDetails?.dueOn))
      visaDocDetails?.hasDueDate
        ? bodyFormData.append('DueOn', visaDocDetails?.dueOn)
        : bodyFormData.append('DueOn', '')
      bodyFormData.append('Notes', visaDocDetails?.notes)
      bodyFormData.append('AuditRequired', visaDocDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachments', item)
      })

      return putAPIwithFormData(
        `/facade/Candidate/${candidateId}/Visa/${visaId}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditVisaDocument
