import React, { FC } from 'react'
import CN from 'classnames'

export interface CandidateReferenceCheckSnackProps {
  [x: string]: any
  status: null | 'Failed' | 'Incomplete' | 'Passed'
}

export const CandidateReferenceCheckSnack: FC<
  CandidateReferenceCheckSnackProps
> = ({ status, ...restProps }: CandidateReferenceCheckSnackProps) => {
  return (
    <span
      {...restProps}
      className={CN(
        'flex items-center justify-center py-[2px] pr-[10px] pl-[8px] gap-[6px] border-2 rounded-[10px] h-[20px] box-border',
        {
          'bg-Green-50 border-Green-500': status === 'Passed',
          'bg-Red-50 border-Red-500': status === 'Failed',
          'bg-Yellow-50 border-Yellow-500': status === 'Incomplete',
          'bg-Gray-50 border-Gray-500': status === null,
        },
      )}>
      <i
        className={CN('flex items-center justify-center text-extra-small', {
          'ri-checkbox-circle-line text-Green-600': status === 'Passed',
          'ri-close-circle-line text-Red-600': status === 'Failed',
          'ri-indeterminate-circle-line text-Yellow-600':
            status === 'Incomplete',
          'ri-mail-close-line text-Gray-600': status === null,
        })}
      />

      <span
        className={CN('text-extra-small font-Medium', {
          'text-Green-600': status === 'Passed',
          'text-Red-600': status === 'Failed',
          'text-Yellow-600': status === 'Incomplete',
          'text-Gray-600': status === null,
        })}>
        {status === null ? 'Not Sent' : status}
      </span>
    </span>
  )
}

CandidateReferenceCheckSnack.defaultProps = {}

export default CandidateReferenceCheckSnack
