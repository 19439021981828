import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alerts, Button, Checkbox, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useLogin } from 'framework/api/methods'
import { setToInitial, setUserDetails } from 'store/reducers/user/userSlice'

export interface LogInFormProps {
  [x: string]: any
}

export const LogInForm: FC<LogInFormProps> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })
  const [userFormDetails, setUserFormDetails] = useState({
    username: '',
    password: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMeStatus, setRememberMeStatus] = useState(true)

  /** Get Local Token */
  const LocalStorageToken = localStorage.getItem('token')
  const sessionStorageToken = sessionStorage.getItem('token')
  const token = LocalStorageToken || sessionStorageToken

  /** Redirect user to Homepage if Token exists */
  useEffect(() => {
    if (token) {
      navigate('/', { replace: true })
    }
  })

  /** Log In User API Call ------> Mutate */
  const { mutate: logInMutate, isLoading: logInIsLoading } = useLogin()

  /** Process the Login submission */
  async function logIn(userData: any) {
    logInMutate(userData, {
      onSuccess: ({ data: successData }: any) => {
        localStorage.setItem('token', successData.token)

        if (rememberMeStatus) {
          localStorage.setItem('refreshToken', successData.refreshToken)
        }
        localStorage.setItem('rememberMe', String(rememberMeStatus))
        dispatch(setUserDetails(successData.userProfile))
      },
      onError: ({ response: { data: errData } }: any) => {
        setAlertDetails({
          ...alertDetails,
          isActive: true,
          messageHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
          messageBody: errData.message,
        })
        dispatch(setToInitial())
      },
    })
  }

  /** handle login button click */
  const handleLogInClick = () => {
    logIn(userFormDetails)
  }

  const rememberMeAction = (status: boolean) => {
    setRememberMeStatus(status)
  }

  return (
    <div className='login-form w-[400px] flex flex-col relative'>
      {/* Log in header */}
      <div className='header-text text-heading-2 font-Medium text-Gray-800'>
        Log in
      </div>

      {/* Log in header sub text */}
      <div className='header-sub-text text-small font-Regular text-Gray-700 pt-3'>
        Welcome back! Please enter your details.
      </div>

      {/* username form field */}
      <TextField
        type='text'
        label='Username or email'
        placeholder='your@email.com'
        className='pt-10'
        onChange={(e: any) => {
          setUserFormDetails({
            ...userFormDetails,
            username: e.target.value,
          })
        }}
      />

      {/* password form field */}
      <TextField
        type={showPassword ? 'text' : 'password'}
        label='Password'
        className='pt-6'
        placeholder='••••••••'
        onChange={(e: any) => {
          setUserFormDetails({
            ...userFormDetails,
            password: e.target.value,
          })
        }}
        iconAfter={
          userFormDetails.password !== '' ? (
            <i
              className={CN('hover:cursor-pointer', {
                'ri-eye-off-line': showPassword == false,
                'ri-eye-line': showPassword == true,
              })}
            />
          ) : (
            <></>
          )
        }
        iconAfterOnClick={() => {
          setShowPassword(!showPassword)
        }}
      />

      {/* options */}
      <div className='flex items-center justify-between pt-5'>
        {/* remember me check box */}
        <Checkbox
          labelText='Remember me'
          helperText=''
          checked={rememberMeStatus}
          onChange={(e) => {
            rememberMeAction(e.target.checked)
          }}
        />

        {/* forget password link */}
        <Button
          onClick={() => {
            navigate('/auth/forgot-password')
          }}
          textTheme='blue'
          theme='link'>
          Forgot password?
        </Button>
      </div>

      {/* login button */}
      <Button
        isBlock
        isDisabled={!userFormDetails.username || !userFormDetails.password}
        isLoading={logInIsLoading}
        onClick={() => {
          handleLogInClick()
        }}
        className='mt-6'
        theme='gradient'>
        Log in
      </Button>

      {/* error alert */}
      {alertDetails.isActive && (
        <div className='-bottom-[170px] absolute'>
          <Alerts
            alertBody={alertDetails.messageBody}
            alertHeader={alertDetails.messageHeader}
            onCloseIconClick={() => {
              setAlertDetails({
                ...alertDetails,
                isActive: false,
              })
            }}
            status='Error'
          />
        </div>
      )}
    </div>
  )
}

LogInForm.defaultProps = {}

export default LogInForm
