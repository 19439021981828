import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddTaxInformationProps {
  taxInformation?: any
  candidateId?: any
}

export const useAddTaxInformation = () => {
  const mutate = useMutation(
    ({ taxInformation, candidateId }: AddTaxInformationProps) => {
      return postAPI(`/Candidate/${candidateId}/TaxInfromation`, {
        taxFileNo: taxInformation.taxFileNo,
        onPaid: taxInformation.onPaid,
        taxResidencyTaxPurposes: taxInformation.taxResidencyTaxPurposes,
        taxThresholdClaim: taxInformation.taxThresholdClaim,
        taxThresholdHigherEducation: taxInformation.taxThresholdHigherEducation,
      })
    },
  )

  return mutate
}

export default useAddTaxInformation
