import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface createCandidateTagProps {
  tagName?: any
  Id?: any
}

export const useCreateCandidateTag = () => {
  const mutate = useMutation(({ tagName, Id }: createCandidateTagProps) => {
    return postAPI(`/Candidate/${Id}/Tag`, {
      tagName,
    })
  })

  return mutate
}

export default useCreateCandidateTag
