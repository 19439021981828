import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Alerts,
  Button,
  Checkbox,
  TextArea,
  Toast,
} from '@labourhub/labour-hub-ds'
import { useSetAuditFailed } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'

import { Modal, ModalFooter } from '../../../molecules/Modal'

export interface AuditFailModalProps {
  isActive?: boolean
  [x: string]: any
}

export const AuditFailModal: FC<AuditFailModalProps> = ({
  isActive,
  setIsOpenAuditFailModal,
  getAuditCheckDocumentList,
  setAttachmentsList,
  ...restProps
}: AuditFailModalProps) => {
  const { selectedSection } = useSelector((state: any) => state.audit)

  const [auditFailProps, setAuditFailProps] = useState({
    isNotifyConsultant: false,
    isNotifyCandidate: false,
    note: '',
  })

  /** audit Fail API ------> Mutate */
  const { mutate: setAuditFailedMutate, isLoading: setAuditFailedIsLoading } =
    useSetAuditFailed()

  /** Process the audit Fail  */
  async function setAuditFail() {
    setAuditFailedMutate(
      {
        candidateId: selectedSection?.candidateId,
        categoryId: selectedSection?.categoryId,
        documentId: selectedSection?.documentId,
        isNotifyConsultant: auditFailProps?.isNotifyConsultant,
        note: auditFailProps?.note,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setAttachmentsList([])
          getAuditCheckDocumentList()
          setIsOpenAuditFailModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }
  return (
    <Modal
      isActive={isActive}
      headerTitle='Audit Failure'
      isFooterShow={false}
      onHeaderCloseButtonClick={() => {
        setIsOpenAuditFailModal(false)
      }}
      isCustomHeaderCloseButton={true}
      modalProps={{
        style: { width: '45%' },
      }}>
      <div className='flex flex-col w-full h-full p-6'>
        {setAuditFailedIsLoading && <PageLoader size='xxs' />}

        <Alerts
          alertBody='This will remove the candidate from the roster and will stop consultants from placing them at work. This will also stop the candidate from applying for future roles with the company.'
          alertHeader='Warning'
          isCloseIcon={false}
          isIcon={false}
          status='Warning'
        />

        <Checkbox
          className='mt-8'
          labelText='Notify Consultant'
          checked={auditFailProps?.isNotifyConsultant}
          labelTextClassName='font-Regular'
          checkboxClassName='ml-0'
          onChange={(e: any) => {
            setAuditFailProps({
              ...auditFailProps,
              isNotifyConsultant: e.target.checked,
            })
          }}
        />

        <TextArea
          className='mt-8'
          description='This will be shared with the above selected individuals'
          helperText=''
          value={auditFailProps?.note}
          label='Notes'
          onChange={(e: any) => {
            setAuditFailProps({
              ...auditFailProps,
              note: e.target.value,
            })
          }}
          placeholder='Reason for the failure or any other remarks'
          rows={3}
        />
      </div>

      <ModalFooter className='py-3'>
        <div className='flex items-center justify-end'>
          <Button
            onClick={() => {
              setIsOpenAuditFailModal(false)
            }}
            textTheme='black'
            theme='white'>
            Cancel
          </Button>

          <Button
            onClick={() => {
              setAuditFail()
            }}
            className='ml-2'
            theme='cobalt'>
            Audit Fail the Document
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

AuditFailModal.defaultProps = {
  isActive: false,
}

export default AuditFailModal
