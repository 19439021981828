import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deletePoliceCheckReportInterface {
  policeCheckId?: any
  candidateId?: string
}

export const useDeletePoliceCheckReport = () => {
  const mutate = useMutation(
    ({ candidateId, policeCheckId }: deletePoliceCheckReportInterface) => {
      return deleteAPI(
        `/facade/Candidate/${candidateId}/PoliceCheck/${policeCheckId}`,
      )
    },
  )

  return mutate
}

export default useDeletePoliceCheckReport
