import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddDocumentTypeProps {
  categoryId?: any
  name?: any
}

export const useAddDocumentType = () => {
  const mutate = useMutation(({ categoryId, name }: AddDocumentTypeProps) => {
    return postAPI(`/Type`, {
      categoryId,
      name,
    })
  })

  return mutate
}

export default useAddDocumentType
