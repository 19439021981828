import React, { FC, useEffect, useState } from 'react'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddClientContact,
  useAddClientContactAvatar,
} from 'framework/api/methods'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { CustomizableEditProfilePictureModal } from 'components/molecules'
import { isEmail, isLetters } from 'utils'

export interface AddNewClientContactProps {
  [x: string]: any
}

export const AddNewClientContact: FC<AddNewClientContactProps> = ({
  isActive,
  notify,
  clientId,
  clientLocations,
  getClientContactsList,
  setIsOpenAddNewClientContactDrawer,
  primaryConsultantList,
  getPrimaryConsultantList,
}: AddNewClientContactProps) => {
  /** client details and error fields states */
  const [contactDetails, setContactDetails] = useState<any>({
    firstName: '',
    lastName: '',
    position: '',
    workLocation: '',
    workLocationId: null,
    consultant: '',
    consultantId: null,
    phoneNo: '',
    email: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    position: false,
    email: false,
  })

  const [workLocationList, setWorkLocationList] = useState<any>()

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** image upload states */
  const [addedContactId, setAddedContactId] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>('')
  const [files, setFiles] = useState<any>()
  const [showAddAvatarModal, setShowAddAvatarModal] = useState(false)

  /** APi call for the add client contact */
  const {
    mutate: addClientContactMutate,
    isLoading: addClientContactIsLoading,
  } = useAddClientContact()

  /** Process the add new client contact */
  const addClientContact = () => {
    addClientContactMutate(
      {
        contactDetails,
        clientId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: 'New contact added successfully.',
            alertBody: '',
            status: 'Success',
          })
          getClientContactsList()
          resetInputFields()

          /** set contact id for update profile picture */
          successData?.clientContact?.contactId &&
            setAddedContactId(successData?.clientContact?.contactId)

          setIsOpenAddNewClientContactDrawer(false)
          setShowAddAvatarModal(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** mapping work location list to drop down and get primary consultant list */
  useEffect(() => {
    getPrimaryConsultantList()

    const tempWorkLocationList = clientLocations?.map((item: any) => {
      return {
        ...item,
        value: item?.clientLocationId,
        label: item?.name,
      }
    })
    setWorkLocationList(tempWorkLocationList)
  }, [clientLocations])

  /** reset input fields */
  const resetInputFields = () => {
    setContactDetails({
      ...contactDetails,
      firstName: '',
      lastName: '',
      position: '',
      workLocation: '',
      workLocationId: null,
      consultant: '',
      consultantId: null,
      phoneNo: '',
      email: '',
    })
  }

  /** front end validation for input fields */
  const validateInputs = () => {
    const { firstName, lastName, phoneNo, email } = contactDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      phoneNo: phoneNo ? false : true,
      email: isEmail(email) ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) && isLetters(lastName) && phoneNo && isEmail(email)

    return isAllValidFields
  }

  /** handle add new client contact */
  const handleAddNewClient = () => {
    const valid = validateInputs()
    if (valid) {
      addClientContact()
    }
  }

  /** upload client contact avatar */
  const {
    mutate: addClientContactAvatarMutate,
    isLoading: addClientContactAvatarIsLoading,
  } = useAddClientContactAvatar()

  /** Process the upload client contact avatar */
  async function uploadClientContactAvatar(avatarObject: any) {
    addClientContactAvatarMutate(
      {
        Id: addedContactId,
        avatarObject,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Contact avatar saved successfully.',
            alertBody: '',
            status: 'Success',
          })
          setShowAddAvatarModal(false)
          getClientContactsList()
          setImageUrl('')
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowAddAvatarModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle primary button disable */
  useEffect(() => {
    const { firstName, lastName, consultantId, phoneNo, email } =
      contactDetails || {}

    ;(firstName && lastName && consultantId && phoneNo && email ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [contactDetails])

  return (
    <>
      <RightSideModal
        isActive={isActive}
        headerTitle='Add New Contact Person'
        headerSubtitle='Add new contact person here'
        primaryButtonTitle='Save Contact'
        secondaryButtonTitle='Cancel'
        isPrimaryButtonDisable={isPrimaryButtonDisabled}
        onFooterAddButtonClick={() => {
          handleAddNewClient()
        }}
        onHeaderCloseButtonClick={() => {
          resetInputFields()
          setIsOpenAddNewClientContactDrawer(false)
        }}
        onFooterCancelButtonClick={() => {
          resetInputFields()
          setIsOpenAddNewClientContactDrawer(false)
        }}>
        {addClientContactIsLoading && <PageLoader size='xxs' />}

        <div className='flex flex-col w-full px-6 pt-5'>
          {/* name section */}
          <div className='flex gap-x-5'>
            <TextField
              label='First Name'
              className='w-1/2'
              value={contactDetails?.firstName}
              onChange={(e: any) => {
                setContactDetails({
                  ...contactDetails,
                  firstName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.firstName}
            />

            <TextField
              label='Last Name'
              className='w-1/2'
              value={contactDetails?.lastName}
              onChange={(e: any) => {
                setContactDetails({
                  ...contactDetails,
                  lastName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.lastName}
            />
          </div>

          {/* position section */}
          <TextField
            label='Position'
            className='mt-5'
            value={contactDetails?.position}
            onChange={(e: any) => {
              setContactDetails({
                ...contactDetails,
                position: e.target.value,
              })
            }}
            placeholder=''
          />

          {/* work location section */}
          <SimpleSelect
            label='Work Location'
            value={{
              label: contactDetails?.workLocation,
              value: contactDetails?.workLocationId,
            }}
            className='mt-5'
            onChange={(selectedItem: any) => {
              setContactDetails({
                ...contactDetails,
                workLocation: selectedItem.label,
                workLocationId: selectedItem.value,
              })
            }}
            options={workLocationList}
          />

          {/* primary consultant section */}
          <SimpleSelect
            label='Primary Consultant'
            value={{
              label: contactDetails?.consultant,
              value: contactDetails?.consultantId,
            }}
            className='mt-5'
            onChange={(selectedItem: any) => {
              setContactDetails({
                ...contactDetails,
                consultant: selectedItem.label,
                consultantId: selectedItem.value,
              })
            }}
            options={primaryConsultantList}
          />

          {/** Phone number section */}
          <PhoneInputField
            label='Phone No'
            value={contactDetails?.phoneNo}
            onChange={(number: any) =>
              setContactDetails({
                ...contactDetails,
                phoneNo: number,
              })
            }
            className='mt-5'
            isErrored={isErrorField?.phoneNo}
          />

          {/* email section */}
          <TextField
            label='Email'
            className='mt-5'
            value={contactDetails.email}
            onChange={(e: any) => {
              setContactDetails({
                ...contactDetails,
                email: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField?.email}
          />
        </div>
      </RightSideModal>

      {/* add new avatar section */}
      <CustomizableEditProfilePictureModal
        imageSource={undefined}
        isActive={showAddAvatarModal}
        onHeaderCloseButtonClick={() => {
          setShowAddAvatarModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowAddAvatarModal(false)
        }}
        onOverlayClick={() => {
          setShowAddAvatarModal(false)
        }}
        onClickPrimaryBtn={() => {
          uploadClientContactAvatar(files)
        }}
        primaryButtonIsLoading={addClientContactAvatarIsLoading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={'Add New Add Contact Profile Photo'}
        headerSubTitle='This is optional. You can skip this if you like'
      />
    </>
  )
}

AddNewClientContact.defaultProps = {}

export default AddNewClientContact
