import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  Radio,
  RightSideModal,
  SimpleSelect,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import {
  useAddSuburb,
  useCreateCandidate,
  useGetCountryList,
  useGetStateListByCountyCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CalendarInput,
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail, isLetters, isURL } from 'utils'

export interface AddNewCandidateProps {
  [x: string]: any
}

export const AddNewCandidate: FC<AddNewCandidateProps> = ({
  getTableData,
  isActive,
  notify,
  primaryClientList,
  setIsAddCandidateDrawerOpen,
}: AddNewCandidateProps) => {
  /** candidate details and error fields state */
  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    gender: '',
    mainClientId: '',
    mainClientName: '',
    streetName: '',
    suburb: '',
    state: '',
    stateCode: '',
    postCode: '',
    country: 'Australia',
    countryCode: 'AU',
    latitude: '',
    longitude: '',
    hideRoster: false,
    showMedical: false,
    eContactFirstName: '',
    eContactLastName: '',
    eContactRelationship: '',
    eContactState: '',
    eContactMobilePhone: '',
    eContactHomePhone: '',
    linkedin: '',
    facebook: '',
    twitter: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    middleName: false,
    lastName: false,
    email: false,
    eContactFirstName: false,
    eContactLastName: false,
    eContactRelationship: false,
    linkedin: false,
    facebook: false,
    twitter: false,
  })

  const [renderKeys, setRenderKeys] = useState<any>({ gender: 0, dob: 0 })

  /** dropdown lists */
  const [countryList, setCountryItemList] = useState<any>()
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** Get country list */
  const { mutate: getCountryListMutate, isLoading: getCountryListIsLoading } =
    useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
  }, [])

  /** Get state list by country code */
  const {
    mutate: getStateListByCountyCodeMutate,
    isLoading: getStateListByCountyCodeIsLoading,
  } = useGetStateListByCountyCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    getStateListByCountryCode(candidateDetails?.countryCode)
  }, [candidateDetails?.countryCode])

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add candidate */
  const { mutate: createCandidateMutate, isLoading: createCandidateIsLoading } =
    useCreateCandidate()

  /** Process the add new candidate */
  const addNewCandidate = () => {
    createCandidateMutate(
      {
        candidateDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          resetInputFields()

          setIsAddCandidateDrawerOpen(false)
          getTableData()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation input fields */
  const validateInputs = () => {
    const {
      firstName,
      middleName,
      lastName,
      email,
      eContactFirstName,
      eContactLastName,
      eContactRelationship,
      linkedin,
      facebook,
      twitter,
    } = candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      middleName: isLetters(middleName) || middleName === '' ? false : true,
      lastName: isLetters(lastName) || lastName === '' ? false : true,
      email: isEmail(email) ? false : true,
      eContactFirstName:
        isLetters(eContactFirstName) || eContactFirstName === '' ? false : true,
      eContactLastName:
        isLetters(eContactLastName) || eContactLastName === '' ? false : true,
      eContactRelationship:
        isLetters(eContactRelationship) || eContactRelationship === ''
          ? false
          : true,
      linkedin: isURL(linkedin) || linkedin === '' ? false : true,
      facebook: isURL(facebook) || facebook === '' ? false : true,
      twitter: isURL(twitter) || twitter === '' ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      (isLetters(middleName) || middleName === '') &&
      (isLetters(lastName) || lastName === '') &&
      isEmail(email) &&
      (isLetters(eContactFirstName) || eContactFirstName === '') &&
      (isLetters(eContactLastName) || eContactLastName === '') &&
      (isLetters(eContactRelationship) || eContactRelationship === '') &&
      (isURL(linkedin) || linkedin === '') &&
      (isURL(facebook) || facebook === '') &&
      (isURL(twitter) || twitter === '')

    return isAllValidFields
  }

  /** handle add new candidate button */
  const onAddNewCandidate = () => {
    const valid = validateInputs()

    if (valid) {
      addNewCandidate()
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** reset input fields */
  const resetInputFields = () => {
    setCandidateDetails({
      ...candidateDetails,
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      dateOfBirth: '',
      gender: '',
      streetName: '',
      mainClientId: '',
      mainClientName: '',
      suburb: '',
      state: '',
      stateCode: '',
      postCode: '',
      country: 'Australia',
      countryCode: 'AU',
      latitude: '',
      longitude: '',
      hideRoster: false,
      showMedical: false,
      eContactFirstName: '',
      eContactLastName: '',
      eContactRelationship: '',
      eContactState: '',
      eContactMobilePhone: '',
      eContactHomePhone: '',
      linkedin: '',
      facebook: '',
      twitter: '',
    })

    setRenderKeys({
      ...renderKeys,
      gender: Math.random(),
      dob: Math.random(),
    })
  }

  /** handle primary button disable */
  useEffect(() => {
    const { title, firstName, email } = candidateDetails || {}
    title && firstName && email
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [candidateDetails])

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(candidateDetails.stateCode, actionMeta?.option?.label)
    }

    setCandidateDetails({
      ...candidateDetails,
      suburb: newValue?.label,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Add Candidate'
      headerSubtitle='Add new candidate'
      primaryButtonTitle='Add Candidate'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onFooterAddButtonClick={() => {
        onAddNewCandidate()
      }}
      onHeaderCloseButtonClick={() => {
        setIsAddCandidateDrawerOpen(false)
        resetInputFields()
      }}
      onFooterCancelButtonClick={() => {
        setIsAddCandidateDrawerOpen(false)
        resetInputFields()
      }}>
      {(getCountryListIsLoading ||
        getStateListByCountyCodeIsLoading ||
        createCandidateIsLoading) && <PageLoader size='xxs' />}

      <div className='w-full h-fit py-5 px-6'>
        {/* personal details section ------------------------------------------------------------------------------*/}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2'>
          Personal Details
        </div>

        {/* title section */}
        <SimpleSelect
          label='Title'
          isRequired
          value={{
            label: candidateDetails?.title,
            value: candidateDetails?.title,
          }}
          className='w-1/2 mt-5'
          onChange={(selectedItem: any) => {
            setCandidateDetails({
              ...candidateDetails,
              title: selectedItem?.value,
            })
          }}
          options={[
            { label: 'Mr', value: 'Mr' },
            { label: 'Mrs', value: 'Mrs' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Ms', value: 'Ms' },
          ]}
        />

        {/* first name section */}
        <TextField
          label='First Name'
          className='mt-5'
          isRequired
          value={candidateDetails?.firstName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              firstName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.firstName}
        />

        {/* middle name section */}
        <TextField
          label='Middle Name'
          className='mt-5'
          value={candidateDetails?.middleName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              middleName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.middleName}
        />

        {/* last name section */}
        <TextField
          label='Last Name'
          className='mt-5'
          value={candidateDetails?.lastName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              lastName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.lastName}
        />

        {/* email section */}
        <TextField
          label='Email'
          isRequired
          className='mt-5'
          value={candidateDetails?.email}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.email}
        />

        {/** Phone number section */}
        <PhoneInputField
          label='Phone'
          value={candidateDetails?.phone}
          onChange={(number: any) =>
            setCandidateDetails({
              ...candidateDetails,
              phone: number,
            })
          }
          className='mt-5'
        />

        {/** dob section */}
        <span className='flex mt-5 text-small text-Gray-800 font-Medium'>
          Date of Birth
        </span>

        <CalendarInput
          key={renderKeys?.dob}
          defaultValue={candidateDetails?.dateOfBirth}
          formatDate={(date) => format(date, 'dd/MM/yyyy')}
          parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
          className='mt-1'
          disableFutureDates={true}
          onChange={(date) => {
            if (date) {
              setCandidateDetails({
                ...candidateDetails,
                dateOfBirth: date,
              })
            }
          }}
        />

        {/** gender section */}
        <div className='flex flex-col mt-5'>
          <span className='flex text-Gray-800 font-Medium text-small pb-1'>
            Gender
          </span>
          <div className='flex gap-5' key={renderKeys?.gender}>
            <Radio
              id='Male'
              labelText='Male'
              helperText=''
              name='gender'
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />

            <Radio
              id='Female'
              labelText='Female'
              helperText=''
              name='gender'
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />

            <Radio
              id='Other'
              labelText='Other'
              helperText=''
              name='gender'
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />
          </div>
        </div>

        {/** Main Employer section */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Main Employer
        </div>

        <SimpleSelect
          label='Main Employer'
          className='mt-5'
          value={{
            label: candidateDetails?.mainClientName,
            value: candidateDetails?.mainClientId,
          }}
          onChange={(selectedItem: any) => {
            setCandidateDetails({
              ...candidateDetails,
              mainClientName: selectedItem?.label,
              mainClientId: selectedItem?.id,
            })
          }}
          options={primaryClientList}
        />

        {/* address details section ------------------------------------------------------------------------------ */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Address
        </div>

        {/* street name section */}
        <TextField
          label='Street Name'
          className='mt-5'
          value={candidateDetails?.streetName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              streetName: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* state section */}
        <SimpleSelect
          label='State'
          className='mt-5'
          value={{
            label: candidateDetails?.state,
            value: candidateDetails?.stateCode,
          }}
          onChange={(selectedItem: any) => {
            if (candidateDetails?.stateCode !== selectedItem?.value) {
              setCandidateDetails({
                ...candidateDetails,
                state: selectedItem?.label,
                stateCode: selectedItem?.value,
                suburb: '',
              })
              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          options={stateList}
        />

        {/* state and post code section */}
        <div className='flex w-full mt-5 gap-5'>
          <CreatableInputSelect
            label='Suburb'
            className='w-2/3'
            value={{
              label: candidateDetails.suburb,
              value: candidateDetails.suburb,
            }}
            options={suburbList}
            onChange={handleSuburbChange}
            isDisabled={!candidateDetails.stateCode}
            isErrored={isErrorField.state === '' ? true : false}
          />

          <TextField
            label='Post Code'
            className='w-1/3'
            value={candidateDetails.postCode}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                postCode: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>

        {/* country section */}
        <SimpleSelect
          label='Country'
          value={{
            label: candidateDetails?.country,
            value: candidateDetails?.countryCode,
          }}
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (candidateDetails?.countryCode !== selectedItem?.value) {
              setCandidateDetails({
                ...candidateDetails,
                country: selectedItem?.label,
                countryCode: selectedItem?.value,
                state: '',
                stateCode: '',
                suburb: '',
              })

              getStateListByCountryCode(selectedItem.value)
            }
          }}
          options={countryList}
        />

        {/* latitude section */}
        <TextField
          label='Latitude (Optional)'
          className='mt-5'
          value={candidateDetails.latitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setCandidateDetails({
                ...candidateDetails,
                latitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* longitude section */}
        <TextField
          label='Longitude (Optional)'
          className='mt-5'
          value={candidateDetails.longitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setCandidateDetails({
                ...candidateDetails,
                longitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* other section ------------------------------------------------------------------------------ */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Other
        </div>

        {/* hide from roster section */}
        <Toggle
          label='Hide from Roster'
          className='mt-5'
          setValue={candidateDetails.hideRoster}
          onToggleClick={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              hideRoster: e.target.checked,
            })
          }}
        />

        {/* show medical notifications on profile section */}
        <Toggle
          label='Show Medical Notifications on Profile'
          className='mt-5'
          setValue={candidateDetails.showMedical}
          onToggleClick={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              showMedical: e.target.checked,
            })
          }}
        />

        {/* emergency contact section ------------------------------------------------------------------------------ */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Emergency Contact
        </div>

        {/* emergency contact first name and last name section */}
        <div className='flex w-full mt-5 gap-5'>
          <TextField
            label='First Name'
            className='w-1/2'
            value={candidateDetails.eContactFirstName}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                eContactFirstName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.eContactFirstName}
          />

          <TextField
            label='Last Name'
            className='w-1/2'
            value={candidateDetails.eContactLastName}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                eContactLastName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.eContactLastName}
          />
        </div>

        {/* emergency contact relationship section */}
        <TextField
          label='Relationship'
          className='mt-5'
          value={candidateDetails.eContactRelationship}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              eContactRelationship: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.eContactRelationship}
        />

        {/* emergency contact state section */}
        <TextField
          label='State'
          className='mt-5'
          value={candidateDetails.eContactState}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              eContactState: e.target.value,
            })
          }}
          placeholder=''
        />

        {/** emergency contact phone number section */}
        <PhoneInputField
          label='Mobile Phone'
          value={candidateDetails?.eContactMobilePhone}
          onChange={(number: any) =>
            setCandidateDetails({
              ...candidateDetails,
              eContactMobilePhone: number,
            })
          }
          className='mt-5'
        />

        {/** emergency contact home phone number section */}
        <PhoneInputField
          label='Home Phone'
          value={candidateDetails?.eContactHomePhone}
          onChange={(number: any) =>
            setCandidateDetails({
              ...candidateDetails,
              eContactHomePhone: number,
            })
          }
          className='mt-5'
        />

        {/* social media section ------------------------------------------------------------------------------ */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Social Media
        </div>

        {/* Linkedin section */}
        <TextField
          label='Linkedin'
          helperText='Ex: https://www.linkedin.com/in/john-doe/'
          className='mt-5'
          value={candidateDetails.linkedin}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              linkedin: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.linkedin}
        />

        {/* Facebook section */}
        <TextField
          label='Facebook'
          helperText='Ex: https://www.facebook.com/johnDoe/'
          className='mt-5'
          value={candidateDetails.facebook}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              facebook: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.facebook}
        />

        {/* Twitter section */}
        <TextField
          label='Twitter'
          helperText='Ex: https://twitter.com/johndoe'
          className='mt-5'
          value={candidateDetails.twitter}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              twitter: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.twitter}
        />
      </div>
    </RightSideModal>
  )
}

AddNewCandidate.defaultProps = {}

export default AddNewCandidate
