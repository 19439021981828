import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetDocumentTypeByCategoryIdInterface {
  categoryId?: any
}

export const useGetDocumentTypeByCategoryId = () => {
  const mutate = useMutation(
    ({ categoryId }: GetDocumentTypeByCategoryIdInterface) => {
      return get(`Type/Category/${categoryId}`, {})
    },
  )

  return mutate
}

export default useGetDocumentTypeByCategoryId
