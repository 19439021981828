import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, SimpleSelect, Toggle } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

import { PageLoader } from 'components/atoms'
import { QuestionnairePreviewModal } from 'components/molecules'

export interface CustomQuestionnaireTabViewProps {
  [x: string]: any
}

export const CustomQuestionnaireTabView: FC<
  CustomQuestionnaireTabViewProps
> = ({
  qTemplateList,
  selectedQTemplate,
  setSelectedQTemplate,
  className,
  getQuestionnairesListIsLoading,
  isCustomQuestionnaireEnable,
  setIsCustomQuestionnaireEnable,
  ...restProps
}: CustomQuestionnaireTabViewProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { templateDetails } = useSelector((state: any) => state.regPack)
  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)
  const [
    isShowNewTemplateNavigationConfirmationModal,
    setIsShowNewTemplateNavigationConfirmationModal,
  ] = useState(false)

  /** handle states when questionnaire templates available in selected reg pack template */
  useEffect(() => {
    if (templateDetails?.customQuestionnaireId) {
      setIsCustomQuestionnaireEnable(true)

      const selectedTemplateDetails = qTemplateList?.find(
        (item) => item.id === templateDetails?.customQuestionnaireId,
      )

      setSelectedQTemplate({
        label: selectedTemplateDetails?.name,
        value: selectedTemplateDetails?.id,
      })
    }
  }, [templateDetails, qTemplateList])

  const CustomQuestionnaireTabViewClasses = CN(
    `custom-questionnaire-tab-view w-full flex flex-col p-5 relative`,
    className,
  )

  return (
    <div className={CustomQuestionnaireTabViewClasses} {...restProps}>
      {getQuestionnairesListIsLoading && <PageLoader size='xxs' />}

      <span className='text-heading-4 text-Gray-800 font-Medium'>
        Custom Questionnaire
      </span>

      <span className='text-small text-Gray-500 pt-1'>
        You can send a custom questionnaire to the candidate if you desire
      </span>

      <div className='flex items-center mt-8'>
        <Toggle
          setValue={isCustomQuestionnaireEnable}
          onToggleClick={(e: any) => {
            setIsCustomQuestionnaireEnable(e.target.checked)
            if (e.target.checked === false) {
              dispatch(
                setTemplateDetails({
                  ...templateDetails,
                  customQuestionnaireId: '',
                }),
              )

              setSelectedQTemplate(null)
            }
          }}
        />

        <span className='text-Gray-800 ml-3'>
          I need to add a custom questionnaire for this reg pack
        </span>
      </div>

      {isCustomQuestionnaireEnable && (
        <div className='flex flex-col w-full mt-[14px]'>
          <div className='flex w-full bg-Blue-50 border border-Blue-300 rounded items-start p-4'>
            <i className='ri-error-warning-fill text-Blue-600 text-[18px]' />

            <span className='text-small text-Blue-700 font-Regular ml-2'>
              You can either select one of the created template or create a new
              one in the questionnaire builder. If you do, please make sure you
              save this as a template before you leave the page.
            </span>
          </div>

          <span className='flex text-small font-Medium mt-8'>
            Select Reg Pack Questionnaire Templates
          </span>

          <div className='flex items-center mt-1'>
            <SimpleSelect
              className='w-[330px] h-[40px]'
              value={
                selectedQTemplate
                  ? {
                      label: selectedQTemplate?.label,
                      value: selectedQTemplate?.value,
                    }
                  : null
              }
              onChange={(selectedItem: any) => {
                setSelectedQTemplate({
                  label: selectedItem?.name,
                  value: selectedItem?.id,
                })

                dispatch(
                  setTemplateDetails({
                    ...templateDetails,
                    customQuestionnaireId: selectedItem?.id,
                  }),
                )
              }}
              placeholder='Select a Template'
              options={qTemplateList}
            />

            <span className='flex mx-4 text-small text-Gray-400'>OR</span>

            <Button
              className='w-[330px] h-[40px]'
              textTheme='blue'
              size='sm'
              theme='white'
              onClick={() => {
                setIsShowNewTemplateNavigationConfirmationModal(true)
              }}>
              Create New Template
            </Button>
          </div>

          <Button
            iconRight={<i className='ri-external-link-fill text-[14px]' />}
            onClick={() => {
              setIsShowPreviewModal(true)
            }}
            isDisabled={!selectedQTemplate?.value}
            className='w-fit mt-2'
            size='sm'
            textTheme='blue'
            theme='link'>
            Preview Questions
          </Button>

          {isShowPreviewModal && (
            <QuestionnairePreviewModal
              isActive={isShowPreviewModal}
              modalClose={() => {
                setIsShowPreviewModal(false)
              }}
              templateId={selectedQTemplate?.value}
              templateName={selectedQTemplate?.label}
            />
          )}

          {/** Create new template confirmation modal */}
          <Modal
            isActive={isShowNewTemplateNavigationConfirmationModal}
            onClickPrimaryBtn={() => {
              navigate('/questionnaires/build-new-questionnaire')
            }}
            onClickSecondaryBtn={() => {
              setIsShowNewTemplateNavigationConfirmationModal(false)
            }}
            primaryButtonProps={{
              style: { width: '100%', marginRight: '12px' },
              theme: 'cobalt',
            }}
            secondaryButtonProps={{
              style: { width: '100%', color: '#1F2937' },
            }}
            onOverlayClick={() => {
              setIsShowNewTemplateNavigationConfirmationModal(false)
            }}
            modalProps={{
              style: { width: '466px' },
            }}
            footerProps={{
              style: {
                justifyContent: 'space-between',
                paddingLeft: '23px',
                paddingRight: '23px',
              },
            }}
            isHeaderShow={false}
            primaryButtonTitle='Leave Page'
            secondaryButtonTitle='Cancel'>
            <div className='flex flex-col justify-center items-center p-6'>
              <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Yellow-50'>
                <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
              </div>

              <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
                Warning
              </span>

              <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
                Please save this as a new template before you leave the page, if
                you need to use this reg pack in the future.
              </span>
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}

CustomQuestionnaireTabView.defaultProps = {}

export default CustomQuestionnaireTabView
