/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  TextField,
  Toast,
  Toggle,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useAddSuperName,
  useAddSuperProvider,
  useDeleteCandidateDocumentAttachmentById,
  useEditSuperFund,
  useGetSuperFundById,
  useGetSuperName,
  useGetSuperProvider,
} from 'framework/api/methods'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import { CreatableInputSelect, PageLoader } from 'components/atoms'

export interface EditSuperFundDocsProps {
  isActive?: boolean
  [x: string]: any
}

export const EditSuperFundDocs: FC<EditSuperFundDocsProps> = ({
  isActive,
  fetchDocumentList,
  ...restProps
}: EditSuperFundDocsProps) => {
  const dispatch = useDispatch()
  const { selectedSection } = useSelector((state: any) => state.audit)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [superProviderList, setSuperProviderList] = useState<any[]>([])
  const [superNameList, setSuperNameList] = useState<any[]>([])
  const [formData, setFormData] = useState<any>({
    superProvider: null,
    superName: null,
    membershipNo: null,
    superABN: null,
    superUSI: null,
    auditCheck: false,
    categoryId: selectedSection?.categoryId,
    attachmentUrl: null,
    documentId: null,
  })

  /** API Handling for get vaccination document list*/
  const {
    refetch: getDocumentDetailsById,
    data: selectedDocumentDetails,
    isFetching: getDocumentDetailsByIdIsFetching,
  } = useGetSuperFundById(
    selectedSection?.candidateId,
    selectedSection?.documentId,
  )

  useEffect(() => {
    isActive && getDocumentDetailsById()
  }, [isActive])

  /** set selected document details to field */
  useEffect(() => {
    if (isActive) {
      const {
        superProviderId,
        superNameId,
        membershipNumber,
        abn,
        usi,
        auditRequired,
        attachmentUrl,
        documentId,
      } = selectedDocumentDetails || {}

      setFormData({
        ...formData,
        superProvider: superProviderId,
        superName: superNameId,
        membershipNo: membershipNumber ? membershipNumber : '',
        superABN: abn ? abn : '',
        superUSI: usi ? usi : '',
        auditCheck: auditRequired ? auditRequired : false,
        categoryId: selectedSection?.categoryId,
        attachmentUrl: attachmentUrl,
        documentId,
      })
    }
  }, [selectedDocumentDetails, isActive])

  /** super provider list on success */
  const onGetSuperProviderListSuccess = (data: any) => {
    const tempSuperProviderList = data?.superProviders?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setSuperProviderList(tempSuperProviderList)
  }

  /**on error */
  const onError = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Super Provider List*/
  const {
    refetch: getSuperProviderList,
    isLoading: getSuperProviderIsLoading,
    isFetching: getSuperProviderIsFetching,
  } = useGetSuperProvider(onGetSuperProviderListSuccess, onError)

  /** bank list on success */
  const onGetSuperNameListSuccess = (data: any) => {
    const tempSuperNameList = data?.superNames?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setSuperNameList(tempSuperNameList)
  }

  /** API Handling request for Super Name List */
  const {
    refetch: getSuperNameList,
    isLoading: getSuperNameListIsLoading,
    isFetching: getSuperNameListIsFetching,
  } = useGetSuperName(
    {
      superProviderId: formData.superProvider,
    },
    onGetSuperNameListSuccess,
    onError,
  )

  useEffect(() => {
    if (formData.superProvider && isActive) {
      getSuperNameList()
    }
  }, [formData.superProvider, isActive])

  const {
    mutate: addSuperProviderMutate,
    isLoading: addSuperProviderIsLoading,
  } = useAddSuperProvider()

  /** add super provider */
  async function addSuperProvider(name: string) {
    addSuperProviderMutate(
      {
        superProviderData: {
          name: name,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setFormData({
            ...formData,
            superProvider: successData.superProvider.id,
          })
          getSuperProviderList()
          Toast({
            alertBody: 'Super Provider added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding super provider!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle super provider change */
  const handleSuperProviderChange = (
    data: any,
    actionMeta: ActionMeta<any>,
  ) => {
    if (actionMeta.action == 'create-option') {
      addSuperProvider(data.label)
    } else {
      setFormData({
        ...formData,
        superProvider: data.value,
        superName: '',
      })
    }
  }

  const { mutate: addSuperNameMutate, isLoading: addSuperNameIsLoading } =
    useAddSuperName()

  /** add super name */
  async function addSuperName(name: string) {
    addSuperNameMutate(
      {
        superNameData: {
          superProviderId: formData.superProvider,
          name: name,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          getSuperNameList()
          setFormData({
            ...formData,
            superName: successData.superName.id,
          })
          Toast({
            alertBody: 'Super Name added successfully',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding super name!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle super name change */
  const handleSuperNameChange = (data: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuperName(data.label)
    } else {
      setFormData({
        ...formData,
        superName: data.value,
      })
    }
  }

  const validateForm = () => {
    const { superProvider, superName, membershipNo, superABN, superUSI } =
      formData

    let buttonDisabled = false

    if (
      !superProvider ||
      !superName ||
      !membershipNo ||
      !superABN ||
      !superUSI
    ) {
      buttonDisabled = true
    }

    setIsPrimaryButtonDisabled(buttonDisabled)
  }

  // const resetInputFields = () => {
  //   setFormData({
  //     superProvider: null,
  //     superName: null,
  //     membershipNo: null,
  //     superABN: null,
  //     superUSI: null,
  //     auditCheck: false,
  //     categoryId: selectedSection?.categoryId,
  //     typeId: null,
  //     attachmentUrl: null,
  //   })
  // }

  useEffect(() => {
    validateForm()
  }, [formData])

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>([])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (attachments.length > 0 && attachments[0].isExistingFile) {
        deleteCandidateDocumentAttachmentById(
          formData?.documentId,
          attachments[0]?.path,
          true,
        )
      }
      setAttachments([...acceptedFiles])
    },
    [attachments],
  )

  /** on file drop file size and type validate*/
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
    maxFiles: 1,
  })

  useEffect(() => {
    if (isActive && formData?.attachmentUrl) {
      setAttachments([{ path: formData?.attachmentUrl, isExistingFile: true }])
    } else {
      setAttachments([])
    }
  }, [isActive, formData?.attachmentUrl])

  /** APi call for the remove existing attachment */
  const { mutate: deleteCandidateDocumentAttachmentByIdMutate } =
    useDeleteCandidateDocumentAttachmentById()

  /** Process the delete existing attachment */
  const deleteCandidateDocumentAttachmentById = (
    documentId,
    attachmentUrl,
    isExistingFile,
  ) => {
    deleteCandidateDocumentAttachmentByIdMutate(
      {
        documentId,
        attachmentUrl,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          if (!isExistingFile) {
            Toast({
              alertHeader: successData?.message,
              status: 'Success',
            })
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** remove uploaded file */
  const removeFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(
        formData?.documentId,
        file?.path,
        false,
      )
    }

    const newFiles = [...attachments]
    newFiles.splice(newFiles.indexOf(file), 1)
    setAttachments(newFiles)
  }

  /** uploaded file list item  */
  const files = attachments.map((file) => (
    <li
      key={file.path}
      className='flex justify-between items-center  rounded-md border bg-white border-Gray-200 w-full px-3 py-1'>
      <div className='flex justify-start items-center w-4/5'>
        <i className='ri-attachment-2 text-Gray-400 mr-1' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span className='w-1/5 flex justify-end' onClick={removeFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** update super fund */
  const { mutate: updateSuperFundMutate, isLoading: updateSuperFundIsLoading } =
    useEditSuperFund()

  /** Process the super fund */
  async function updateSuperFund() {
    updateSuperFundMutate(
      {
        documentId: formData?.documentId,
        superFundId: selectedSection.documentId,
        candidateId: selectedSection?.candidateId,
        superFundDetails: formData,
        attachments: attachments,
      },
      {
        onSuccess: () => {
          fetchDocumentList()
          Toast({
            alertBody: 'Super fund details updated successfully',
            status: 'Success',
          })

          dispatch(setEditDrawer(null))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating super fund!',
            status: 'Error',
          })
        },
      },
    )
  }

  const onSuperFundEditClick = () => {
    if (
      formData?.attachmentUrl &&
      attachments.filter((file) => file?.isExistingFile).length === 0
    ) {
      removeFile({ path: formData?.attachmentUrl, isExistingFile: true })
    }
    updateSuperFund()
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle='Edit Super Details'
      headerSubtitle='Edit candidate’s super details'
      primaryButtonTitle='Edit Super Details'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        dispatch(setEditDrawer(null))
        // resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        onSuperFundEditClick()
      }}
      onFooterCancelButtonClick={() => {
        dispatch(setEditDrawer(null))
        // resetInputFields()
      }}
      {...restProps}>
      {(getSuperProviderIsFetching ||
        getSuperProviderIsLoading ||
        addSuperProviderIsLoading ||
        addSuperNameIsLoading ||
        updateSuperFundIsLoading ||
        getDocumentDetailsByIdIsFetching) && <PageLoader size='xxs' />}

      <div className='px-6 py-5 flex-1'>
        <CreatableInputSelect
          isDisabled={getSuperProviderIsFetching || getSuperProviderIsLoading}
          isRequired
          label='Super Provider'
          className='mb-5'
          placeholder=''
          value={
            superProviderList?.find((item: any) => {
              return item.value === formData.superProvider
            }) || null
          }
          options={superProviderList}
          onChange={handleSuperProviderChange}
        />
        <CreatableInputSelect
          isDisabled={
            getSuperProviderIsFetching ||
            getSuperProviderIsLoading ||
            getSuperNameListIsLoading ||
            getSuperNameListIsFetching ||
            formData.superProvider === null
          }
          isRequired
          label='Super Name'
          className='mb-5'
          placeholder=''
          value={
            superNameList?.filter((item: any) => {
              return item.value === formData.superName
            }).length > 0
              ? superNameList?.filter((item: any) => {
                  return item.value === formData.superName
                })[0]
              : null
          }
          options={superNameList}
          onChange={handleSuperNameChange}
        />
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData.membershipNo}
          label='Membership No'
          onChange={(e) => {
            setFormData({
              ...formData,
              membershipNo: e.target.value,
            })
          }}
        />
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData.superABN}
          label='Super ABN'
          onChange={(e) => {
            setFormData({
              ...formData,
              superABN: e.target.value,
            })
          }}
        />
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData.superUSI}
          label='Super USI'
          onChange={(e) => {
            setFormData({
              ...formData,
              superUSI: e.target.value,
            })
          }}
        />
        <div className='flex flex-col'>
          <span className='font-Medium text-small text-Gray-800'>
            Super Fund Statement (If any)
          </span>

          <span className='font-Regular text-extra-small text-Gray-600'>
            Max file size 5MB - supports docx, pdf, jpg, jpeg and png
          </span>
        </div>

        <section className='container mt-3'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <div className='flex flex-col'>
              <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                <i className='ri-upload-2-line pr-2' />

                <span className='text-small font-Medium'>Upload File</span>
              </div>
            </div>
          </div>

          {files.length > 0 && (
            <div className='flex w-full mt-2'>
              <ul className='flex flex-col w-full gap-2'>{files}</ul>
            </div>
          )}
        </section>

        {/* audit check section */}
        <Toggle
          label='Audit Check'
          className='py-5'
          setValue={formData?.auditCheck}
          onToggleClick={(e: any) => {
            setFormData({
              ...formData,
              auditCheck: e.target.checked,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

EditSuperFundDocs.defaultProps = {
  isActive: false,
}

export default EditSuperFundDocs
