import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddVisaDocumentInterface {
  candidateId: string
  visaDocDetails: any
  attachments: any
}

export const useAddVisaDocument = () => {
  const mutate = useMutation(
    ({
      candidateId,
      visaDocDetails,
      attachments,
    }: AddVisaDocumentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', visaDocDetails?.categoryId)
      bodyFormData.append('TypeId', visaDocDetails?.docType)
      bodyFormData.append('Title', visaDocDetails?.docName)
      visaDocDetails?.expiryOn &&
        bodyFormData.append('ExpiryOn', visaDocDetails?.expiryOn)
      visaDocDetails?.dueOn &&
        bodyFormData.append('DueOn', visaDocDetails?.dueOn)
      bodyFormData.append('Notes', visaDocDetails?.notes)
      bodyFormData.append('AuditRequired', visaDocDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachments', item)
      })

      return postAPIwithFormData(
        `/facade/Candidate/${candidateId}/Visa`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddVisaDocument
