import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteClientContactInterface {
  clientId?: any
  contactId?: any
}

export const useDeleteClientContact = () => {
  const mutate = useMutation(
    ({ clientId, contactId }: DeleteClientContactInterface) => {
      return deleteAPI(`/facade/Client/${clientId}/Contact/${contactId}`)
    },
  )

  return mutate
}

export default useDeleteClientContact
