import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getDocumentSignedUrlsByIdInterface {
  documentId?: any
}

export const useGetDocumentSignedUrlsById = () => {
  const mutate = useMutation(
    ({ documentId }: getDocumentSignedUrlsByIdInterface) => {
      return get(`/Document/${documentId}/SignedUrls`, {})
    },
  )

  return mutate
}

export default useGetDocumentSignedUrlsById
