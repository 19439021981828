import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditRefereeProps {
  candidateId?: any
  refereeData?: any
}

export const useEditReferee = () => {
  const mutate = useMutation(
    ({ candidateId, refereeData }: EditRefereeProps) => {
      return putAPI(`/Candidate/${candidateId}/Referee/${refereeData?.refId}`, {
        firstName: refereeData?.firstName,
        lastName: refereeData?.lastName,
        phone: refereeData?.phone,
        email: refereeData?.email,
        company: refereeData?.company,
        jobTitle: refereeData?.jobTitle,
        candidateRelation: refereeData?.relationshipToCandidate,
        candidateCompany: refereeData?.preCompanyName,
        candidatePosition: refereeData?.preCandidatePosition,
        startDate: refereeData?.preJobStartDate,
        endDate: refereeData?.preJobFinishDate,
      })
    },
  )

  return mutate
}

export default useEditReferee
