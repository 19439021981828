import React, { FC, useEffect, useState } from 'react'
import { RightSideModal, TextArea, TextField } from '@labourhub/labour-hub-ds'

import { PageLoader } from 'components/atoms'

export interface EditAgencyProfileInfoProps {
  buttonTitle: string
  footerCancelButton?: (e: any) => void | undefined
  footerSubmitButton?: () => void | undefined
  headerCloseButton?: () => void | undefined
  headerSubtitle: string
  headerTitle: string
  isButtonDisabled: boolean
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const EditAgencyProfileInfo: FC<EditAgencyProfileInfoProps> = ({
  agencyProfileCardDetails,
  agencyProfileDetails,
  buttonTitle,
  editBasicInfoModalShow,
  footerCancelButton,
  footerSubmitButton,
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  isLoading,
  onOverlayClick,
  setAgencyProfileCardDetails,
  ...restProps
}: EditAgencyProfileInfoProps) => {
  const [isErrorField, setIsErrorField] = useState<any>({
    name: false,
    abn: false,
    industry: false,
    website: false,
    description: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { name, abn, industry, website, description } =
      agencyProfileCardDetails

    setIsErrorField({
      ...isErrorField,
      name: name ? false : true,
      abn: abn ? false : true,
      industry: industry ? false : true,
      website: website ? false : true,
      description: description ? false : true,
    })

    const isAllValidFields = name && abn && industry && website && description

    return isAllValidFields
  }

  useEffect(() => {
    setAgencyProfileCardDetails({
      ...agencyProfileCardDetails,
      name: agencyProfileDetails.name,
      abn: agencyProfileDetails.abn,
      industry: agencyProfileDetails.industry,
      website: agencyProfileDetails.website,
      description: agencyProfileDetails.description,
    })
  }, [agencyProfileDetails])

  const handleEditProfileInfo = () => {
    const valid = validateInputs()

    if (valid) {
      footerSubmitButton && footerSubmitButton()
    }
  }

  useEffect(() => {
    const { name, abn, industry, website, description } =
      agencyProfileCardDetails || {}
    ;(name && abn && industry && website && description ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [agencyProfileCardDetails])

  return (
    <RightSideModal
      isActive={editBasicInfoModalShow}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      primaryButtonTitle={buttonTitle}
      onHeaderCloseButtonClick={() => {
        headerCloseButton && headerCloseButton()
      }}
      onFooterAddButtonClick={handleEditProfileInfo}
      onOverlayClick={onOverlayClick}
      onFooterCancelButtonClick={footerCancelButton}
      {...restProps}>
      {/** Body section of the drawer modal */}
      {
        <div className='agency-name w-full px-6'>
          {isLoading && <PageLoader size='xxs' />}
          <TextField
            className='pt-5'
            value={agencyProfileCardDetails.name}
            onChange={(e: any) => {
              setAgencyProfileCardDetails({
                ...agencyProfileCardDetails,
                name: e.target.value,
              })
            }}
            label='Agency Name'
            placeholder=''
            isErrored={isErrorField.name === '' ? true : false}
          />

          <TextField
            className='abn pt-5'
            value={agencyProfileCardDetails.abn}
            onChange={(e: any) => {
              setAgencyProfileCardDetails({
                ...agencyProfileCardDetails,
                abn: e.target.value,
              })
            }}
            label='ABN'
            placeholder=''
            isErrored={isErrorField.abn === '' ? true : false}
          />

          <TextField
            className='industry pt-5'
            value={agencyProfileCardDetails.industry}
            onChange={(e: any) => {
              setAgencyProfileCardDetails({
                ...agencyProfileCardDetails,
                industry: e.target.value,
              })
            }}
            label='Industry'
            placeholder=''
            isErrored={isErrorField.industry === '' ? true : false}
          />

          <TextField
            className='website pt-5'
            helperText='www.website.com'
            value={agencyProfileCardDetails.website}
            onChange={(e: any) => {
              setAgencyProfileCardDetails({
                ...agencyProfileCardDetails,
                website: e.target.value,
              })
            }}
            label='Website'
            placeholder=''
            isErrored={isErrorField.website === '' ? true : false}
          />

          <TextArea
            className='about pt-5 pb-10'
            value={agencyProfileCardDetails.description}
            onChange={(e: any) => {
              setAgencyProfileCardDetails({
                ...agencyProfileCardDetails,
                description: e.target.value,
              })
            }}
            label='About'
            placeholder=''
            isErrored={isErrorField.description === '' ? true : false}
            rows={5}
          />
        </div>
      }
    </RightSideModal>
  )
}

EditAgencyProfileInfo.defaultProps = {
  buttonTitle: undefined,
  footerCancelButton: undefined,
  footerSubmitButton: undefined,
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  isButtonDisabled: undefined,
  onOverlayClick: undefined,
}

export default EditAgencyProfileInfo
