/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import {
  useAddDocument,
  useAddDocumentType,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import {
  CalendarInput,
  CreatableInputSelect,
  PageLoader,
} from 'components/atoms'

export interface AddNewCandidateDocumentProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const AddNewCandidateDocument: FC<AddNewCandidateDocumentProps> = ({
  candidateId,
  otherDocumentCategoryList,
  getTableData,
  isActive,
  notify,
  setShowAddNewDocumentDrawer,
  getCandidateAuditDocumentIds,
  ...restProps
}: AddNewCandidateDocumentProps) => {
  const dispatch = useDispatch()

  const { documentCategory } = useSelector((state: any) => state.document)

  /** document detail states */
  const [documentDetails, setDocumentDetails] = useState<any>({
    title: '',
    category: '',
    categoryId: '',
    type: '',
    typeId: '',
    expiryDate: '',
    dueDate: '',
    notes: '',
    auditCheck: false,
  })

  const [hasExpiryDateEnable, setHasExpiryDateEnable] = useState(false)
  const [isReqDocCandidateEnable, setIsReqDocCandidateEnable] = useState(false)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [documentTypeList, setDocumentTypeList] = useState<any>()

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>([])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setAttachments([...attachments, ...acceptedFiles])
    },
    [attachments],
  )

  /** on file drop file size and type validate */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** remove uploaded file */
  const removeFile = (file) => () => {
    const newFiles = [...attachments]
    newFiles.splice(newFiles.indexOf(file), 1)
    setAttachments(newFiles)
  }

  const files = attachments.map((file) => (
    <li
      key={file.path}
      className='flex justify-between items-center bg-white rounded-md border border-Gray-200 w-full px-3 py-1'>
      <div className='flex justify-start items-center w-4/5'>
        <i className='ri-attachment-2 text-Gray-400 mr-1' />

        <span className='flex text-small text-Gray-800 font-Medium line-clamp-1 break-all'>
          {file.path}
        </span>
      </div>

      <span className='w-1/5 flex justify-end' onClick={removeFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentTypes?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )

          setDocumentTypeList(tempDocumentTypeList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    documentCategory && getDocumentTypeByCategoryId(documentCategory?.value)
  }, [documentCategory])

  /** APi call for the add new document type */
  const { mutate: addDocumentTypeMutate } = useAddDocumentType()

  /** Process the add new doc type */
  const addDocumentType = (categoryId, name) => {
    addDocumentTypeMutate(
      {
        categoryId,
        name,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set category id for send data */
          setDocumentDetails({
            ...documentDetails,
            type: successData?.documentType?.name,
            typeId: successData?.documentType?.id,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle doc type on Change */
  const handleDocTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addDocumentType(documentCategory?.id, actionMeta?.option?.label)
    } else {
      setDocumentDetails({
        ...documentDetails,
        type: newValue?.label,
        typeId: newValue?.value,
      })
    }
  }

  /** add new document */
  const { mutate: addDocumentMutate, isLoading: addDocumentIsLoading } =
    useAddDocument()

  /** Process the add new document */
  async function addDocument() {
    addDocumentMutate(
      {
        candidateId,
        documentDetails,
        attachments,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            status: 'Success',
          })
          getTableData()
          resetInputFields()
          setShowAddNewDocumentDrawer(false)
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          if (errData?.message?.includes('Infrastructure :')) {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message.split(':').slice(1),
              status: 'Error',
            })
          } else {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  /** handle add new doc*/
  const handleAddNewDocument = () => {
    addDocument()
  }

  /** reset input fields */
  const resetInputFields = () => {
    setDocumentDetails({
      ...documentDetails,
      title: '',
      category: '',
      categoryId: '',
      type: '',
      typeId: '',
      expiryDate: null,
      dueDate: null,
      notes: '',
      auditCheck: false,
    })
    setHasExpiryDateEnable(false)
    setIsReqDocCandidateEnable(false)

    setAttachments([])
  }

  /** handle primary button disable */
  useEffect(() => {
    const { title, category, type, dueDate, expiryDate } = documentDetails || {}

    title &&
    category &&
    type &&
    (!isReqDocCandidateEnable ||
      (isReqDocCandidateEnable && dueDate ? true : false)) &&
    (!hasExpiryDateEnable || (hasExpiryDateEnable && expiryDate ? true : false))
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [documentDetails])

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle='Create New Document'
      headerSubtitle='Add new document here'
      primaryButtonTitle='Save Document'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        setShowAddNewDocumentDrawer(false)
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        handleAddNewDocument()
      }}
      onFooterCancelButtonClick={() => {
        setShowAddNewDocumentDrawer(false)
        resetInputFields()
      }}
      {...restProps}>
      {/* loading state */}
      {addDocumentIsLoading && <PageLoader size='xxs' />}

      {/** Body section of the drawer modal */}
      {
        <div className='w-full px-6 pt-5'>
          {/* doc title section */}
          <TextField
            className='document-title'
            value={documentDetails.title}
            onChange={(e: any) => {
              setDocumentDetails({
                ...documentDetails,
                title: e.target.value,
              })
            }}
            label='Document Title'
            placeholder=''
          />

          {/* Document Category section */}
          <SimpleSelect
            label='Document Category'
            placeholder='Select Category'
            value={
              documentDetails?.category
                ? {
                    label: documentDetails?.category,
                    value: documentDetails?.categoryId,
                  }
                : null
            }
            className='w-full pt-5'
            onChange={(selectedItem: any) => {
              setDocumentDetails({
                ...documentDetails,
                category: selectedItem.label,
                categoryId: selectedItem.value,
                type: '',
                typeId: '',
              })

              dispatch(setDocumentCategory(selectedItem))
            }}
            options={otherDocumentCategoryList}
          />

          {/* document type section */}
          <CreatableInputSelect
            label='Document Type'
            className='document-type pt-5'
            placeholder='Ex: Graduation, Diploma, Secondary Education etc'
            value={
              documentDetails?.type
                ? {
                    label: documentDetails?.type,
                    value: documentDetails?.typeId,
                  }
                : null
            }
            options={documentTypeList}
            onChange={handleDocTypeChange}
            isDisabled={!documentDetails.category}
          />

          {/* expiry date section */}
          <Toggle
            label='Has Expiry Date'
            className='pt-5'
            setValue={hasExpiryDateEnable}
            onToggleClick={(e: any) => {
              setHasExpiryDateEnable(e.target.checked)

              e.target.checked === false &&
                setDocumentDetails({
                  ...documentDetails,
                  expiryDate: null,
                })
            }}
          />

          {hasExpiryDateEnable && (
            <div className='flex flex-col pt-3'>
              <span className='text-small text-Gray-800 font-Medium'>
                Expiry Date
                <span className='ml-1 text-Red-500'>*</span>
              </span>

              <CalendarInput
                defaultValue={documentDetails?.expiryDate}
                formatDate={(date) => format(date, 'dd/MM/yyyy')}
                parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                className='mt-1'
                onChange={(date) => {
                  if (date) {
                    setDocumentDetails({
                      ...documentDetails,
                      expiryDate: date,
                    })
                  }
                }}
              />
            </div>
          )}

          {/* Request Documents section */}
          <Toggle
            label='Request Documents from Candidate'
            className='pt-5'
            setValue={isReqDocCandidateEnable}
            onToggleClick={(e: any) => {
              setIsReqDocCandidateEnable(e.target.checked)

              e.target.checked === false &&
                setDocumentDetails({
                  ...documentDetails,
                  dueDate: null,
                })
            }}
          />

          {isReqDocCandidateEnable && (
            <div className='flex flex-col pt-3'>
              <span className='text-small text-Gray-800 font-Medium'>
                Due Date
                <span className='ml-1 text-Red-500'>*</span>
              </span>

              <CalendarInput
                defaultValue={documentDetails?.dueDate}
                formatDate={(date) => format(date, 'dd/MM/yyyy')}
                parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                className='mt-1'
                onChange={(date) => {
                  if (date) {
                    setDocumentDetails({
                      ...documentDetails,
                      dueDate: date,
                    })
                  }
                }}
              />

              <span className='text-small text-Gray-500 font-Regular pt-1'>
                Candidate will be notified to upload documents before the due
                date
              </span>
            </div>
          )}

          <div className='flex flex-col pt-5'>
            <span className='font-Medium text-small text-Gray-800'>
              Attachments
            </span>

            <span className='font-Regular text-extra-small text-Gray-600'>
              Max file size 5MB - supports docx, pdf, jpg, jpeg and png,
              multiple uploads
            </span>
          </div>

          {/* Upload Document section ---------------------------------------------------------------- */}
          <section className='container mt-4'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />

              <div className='flex flex-col'>
                <span className='text-small text-Gray-800 font-Medium'>
                  Upload Document
                </span>

                <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 mt-1 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                  <i className='ri-upload-2-line pr-2' />

                  <span className='text-small font-Medium'>Upload File</span>
                </div>
              </div>
            </div>

            {files.length > 0 && (
              <div className='flex w-full mt-2'>
                <ul className='flex flex-col w-full gap-2'>{files}</ul>
              </div>
            )}
          </section>

          {/* notes section */}
          <TextArea
            className='notes pt-5'
            value={documentDetails.notes}
            onChange={(e: any) => {
              setDocumentDetails({
                ...documentDetails,
                notes: e.target.value,
              })
            }}
            label='Notes'
            placeholder=''
            rows={4}
          />

          {/* audit check section */}
          <Toggle
            label='Audit Check'
            className='py-5'
            setValue={documentDetails?.auditCheck}
            onToggleClick={(e: any) => {
              setDocumentDetails({
                ...documentDetails,
                auditCheck: e.target.checked,
              })
            }}
          />
        </div>
      }
    </RightSideModal>
  )
}

AddNewCandidateDocument.defaultProps = {}

export default AddNewCandidateDocument
