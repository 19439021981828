const moment = require('moment-timezone')

export const timeToAESTWithSeconds = (incomingDate: any) => {
  const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateString = moment
    .utc(incomingDate)
    .tz(localtz)
    .format('DD/MM/YYYY h:mm:ss a')

  return dateString
}

export default timeToAESTWithSeconds
