import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'

export interface BankAccountDetailsCardProps {
  onEditClick: () => void
  onAddClick: () => void
  [x: string]: any
}

export const BankAccountDetailsCard: FC<BankAccountDetailsCardProps> = ({
  data,
  className,
  onEditClick,
  onAddClick,
  isLoading,
  ...restProps
}: BankAccountDetailsCardProps) => {
  const BankAccountDetailsCardClasses = CN(
    `px-5 py-4 bg-white border-[1px] bg-white border-Gray-200 rounded-md relative`,
    className,
  )

  return (
    <div className={BankAccountDetailsCardClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      {/** Heading and ADD Button =========================================>>>>> */}
      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Bank Account
        </span>

        {data && (
          <Button
            onClick={() => {
              onEditClick()
            }}
            textTheme='blue'
            size='sm'
            theme='white'>
            Edit
          </Button>
        )}
      </div>
      {!isLoading &&
        (!data ? (
          <div className='flex flex-col justify-center items-center mt-6'>
            <i className='ri-bank-line text-[20px] text-Gray-500' />
            <span className='text-[20px] text-Gray-800 mt-[10px] font-medium'>
              No Bank Account
            </span>
            <span className='text-[14px] text-Gray-600 mt-1 mb-2 font-normal'>
              Please Add the Bank Account
            </span>
            <Button
              onClick={() => {
                onAddClick()
              }}
              iconLeft={<i className='ri-add-line' />}
              size='sm'
              theme='cobalt'>
              Add Bank Account
            </Button>
          </div>
        ) : (
          <div className='flex flex-col mt-6'>
            <div className='flex mb-3 items-center'>
              <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
                Account Name
              </span>
              <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
                {data.name}
              </span>
            </div>
            <div className='flex mb-3 items-center'>
              <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
                Bank Name
              </span>
              <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
                {data.bankName}
              </span>
            </div>
            <div className='flex mb-3 items-center'>
              <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
                BSB No
              </span>
              <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
                {data.bsbNumber}
              </span>
            </div>
            <div className='flex mb-3 items-center'>
              <span className='text-[12px] w-1/2 text-Gray-600 font-medium'>
                Account No
              </span>
              <span className='text-[14px] w-1/2 text-Gray-base font-medium'>
                {data.accountNumber}
              </span>
            </div>
          </div>
        ))}
    </div>
  )
}

BankAccountDetailsCard.defaultProps = {}

export default BankAccountDetailsCard
