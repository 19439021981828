import React, { FC, useEffect, useState } from 'react'
import { RightSideModal, TextArea, TextField } from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import {
  useAddCriminalHistory,
  useEditCriminalHistory,
} from 'framework/api/methods'
import moment from 'moment'

import { CalendarInput, PageLoader } from 'components/atoms'

export interface AddEditCriminalHistoryProps {
  [x: string]: any
}

export const AddEditCriminalHistory: FC<AddEditCriminalHistoryProps> = ({
  candidateId,
  criminalHistoryDrawer,
  drawerMode,
  getCriminalHistoryLIstById,
  headerSubtitle,
  headerTitle,
  isActive,
  notify,
  primaryButtonTitle,
  selectedCriminalHistoryDetails,
  setCriminalHistoryDrawer,
}: AddEditCriminalHistoryProps) => {
  const [formData, setFormData] = useState<any>({
    criminalRecordId: '',
    name: '',
    chargedDate: '',
    note: '',
  })

  const [renderKeys, setRenderKeys] = useState<any>({
    chargedDate: 0,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)

  /** EDIT MODE : set selected data to form fields */
  useEffect(() => {
    setRenderKeys({
      ...renderKeys,
      chargedDate: Math.random(),
    })

    if (drawerMode === 'Edit') {
      const { id, chargedDate, recordName, notes } =
        selectedCriminalHistoryDetails || {}

      setFormData({
        ...formData,
        criminalRecordId: id,
        name: recordName,
        chargedDate: chargedDate && moment(chargedDate).format('DD/MM/y'),
        note: notes,
      })
    } else {
      setFormData({
        ...formData,
        criminalRecordId: '',
        name: '',
        chargedDate: '',
        note: '',
      })
    }
  }, [selectedCriminalHistoryDetails, drawerMode, isActive])

  /** APi call for the add criminal history */
  const {
    mutate: addCriminalHistoryMutate,
    isLoading: addCriminalHistoryIsLoading,
  } = useAddCriminalHistory()

  /** Process the add criminal history */
  const addCriminalHistory = () => {
    addCriminalHistoryMutate(
      {
        candidateId,
        formData,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          setCriminalHistoryDrawer({
            ...criminalHistoryDrawer,
            enable: false,
            drawerMode: '',
          })
          resetInputFields()
          getCriminalHistoryLIstById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit criminal history */
  const {
    mutate: editCriminalHistoryMutate,
    isLoading: editCriminalHistoryIsLoading,
  } = useEditCriminalHistory()

  /** Process the edit criminal history */
  const editCriminalHistory = () => {
    editCriminalHistoryMutate(
      {
        candidateId,
        formData,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          setCriminalHistoryDrawer({
            ...criminalHistoryDrawer,
            enable: false,
            drawerMode: '',
          })
          getCriminalHistoryLIstById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** reset form fields */
  const resetInputFields = () => {
    setFormData({
      ...formData,
      criminalRecordId: '',
      name: '',
      chargedDate: '',
      note: '',
    })

    setRenderKeys({
      ...renderKeys,
      chargedDate: Math.random(),
    })
  }

  /** handle primary button disable */
  useEffect(() => {
    const { name, chargedDate } = formData || {}
    name && chargedDate
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [formData])

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        setCriminalHistoryDrawer({
          ...criminalHistoryDrawer,
          enable: false,
        })
        drawerMode === 'Add' && resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        drawerMode === 'Add' && addCriminalHistory()
        drawerMode === 'Edit' && editCriminalHistory()
      }}
      onFooterCancelButtonClick={() => {
        setCriminalHistoryDrawer({
          ...criminalHistoryDrawer,
          enable: false,
        })
        drawerMode === 'Add' && resetInputFields()
      }}>
      {/* loader */}
      {(addCriminalHistoryIsLoading || editCriminalHistoryIsLoading) && (
        <PageLoader size='xxs' />
      )}

      {/* main containers */}
      <div className='flex w-full flex-col px-6 pt-5'>
        {/* criminal record name section */}
        <TextField
          className='criminal-record-name'
          value={formData?.name}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              name: e.target.value,
            })
          }}
          label='Criminal Record Name'
          placeholder=''
        />

        {/* Charged Date section */}
        <div className='flex flex-col pt-5'>
          <span className='text-small text-Gray-800 font-Medium'>
            Charged Date
          </span>

          <CalendarInput
            key={renderKeys?.chargedDate}
            defaultValue={formData?.chargedDate}
            formatDate={(date) => format(date, 'dd/MM/yyyy')}
            parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
            className='mt-1'
            disableFutureDates={true}
            onChange={(date) => {
              if (date) {
                setFormData({
                  ...formData,
                  chargedDate: date,
                })
              }
            }}
          />
        </div>

        {/* note section */}
        <TextArea
          className='notes pt-5'
          value={formData?.note}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              note: e.target.value,
            })
          }}
          label='Notes'
          placeholder=''
          rows={4}
        />
      </div>
    </RightSideModal>
  )
}

AddEditCriminalHistory.defaultProps = {}

export default AddEditCriminalHistory
