import React, { FC, useEffect, useState } from 'react'
import {
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useEditVisaInformation,
  useGetVisaTypeList,
} from 'framework/api/methods'

import { PageLoader } from 'components/atoms'

export interface VisaInformationProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const VisaInformation: FC<VisaInformationProps> = ({
  getVisaInformation,
  onDrawerClose,
  headerTitle,
  headerSubtitle,
  primaryButtonTitle,
  isActive,
  data,
  isAddMode,
  notify,
  candidateId,
  ...restProps
}: VisaInformationProps) => {
  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [formData, setFormData] = useState({
    visaTypeId: isAddMode ? null : data?.visaTypeId,
    note: isAddMode ? null : data?.note,
    workingHours: isAddMode ? null : data?.workingHours,
  })
  const [visaTypeList, setVisaTypeList] = useState([])

  const validateForm = () => {
    const { visaTypeId } = formData

    visaTypeId
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }

  useEffect(() => {
    validateForm()
  }, [formData])

  /** get visa type list on success */
  const onGetVisaTypeListSuccess = ({ data }: any) => {
    /** Backend issues: mapping visa list to drop down.*/
    const tempVisaTypeList = data?.workRights?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setVisaTypeList(tempVisaTypeList)
  }

  /** get visa type list on error */
  const onGetVisaTypeListError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: 'Error...!',
      alertBody: errData?.message
        ? errData?.message
        : 'Error getting visa type list!',
      status: 'Error',
    })
  }

  /** API Handling request for Get visa type list */
  const {
    isLoading: getVisaTypeListIsLoading,
    isFetching: getVisaTypeListIsFetching,
  } = useGetVisaTypeList({}, onGetVisaTypeListSuccess, onGetVisaTypeListError)

  /** update visa information */
  const {
    mutate: updateVisaInformationMutate,
    isLoading: updateVisaInformationIsLoading,
  } = useEditVisaInformation()

  /** Process the visa information */
  async function updateVisaInformation() {
    updateVisaInformationMutate(
      {
        visaInformation: formData,
        candidateId,
        candidateWorkRightId: data.candidateWorkRightId,
      },
      {
        onSuccess: () => {
          getVisaInformation()
          notify({
            alertBody: 'Visa Information changed successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating visa information!',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetInputFields = () => {
    setFormData({
      visaTypeId: null,
      workingHours: null,
      note: null,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        updateVisaInformation()
      }}
      onFooterCancelButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      {...restProps}>
      {(getVisaTypeListIsLoading ||
        getVisaTypeListIsFetching ||
        updateVisaInformationIsLoading) && <PageLoader size='xxs' />}
      <div className='px-6 py-5 flex-1'>
        <SimpleSelect
          isRequired
          label='Visa Type'
          className='mb-5'
          placeholder=''
          value={
            visaTypeList.find((data: any) => data.id === formData.visaTypeId) ||
            null
          }
          options={visaTypeList}
          onChange={(data) => {
            setFormData({
              ...formData,
              visaTypeId: data.value,
              workingHours: data.workingHours,
            })
          }}
        />
        <TextField
          isDisabled={true}
          className='mb-5'
          placeholder=''
          value={formData?.workingHours}
          label='Approved Working Hours Per Week'
          onChange={(e) => {
            setFormData({
              ...formData,
              workingHours: e.target.value,
            })
          }}
        />
        <TextArea
          className='mb-5'
          value={formData?.note}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              note: e.target.value,
            })
          }}
          label='Notes'
          placeholder=''
          rows={5}
        />
      </div>
    </RightSideModal>
  )
}

VisaInformation.defaultProps = {}

export default VisaInformation
