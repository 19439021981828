import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { FinancialProps } from 'static-data/audit/categoryTypes'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

export interface IFinancialDetailsProps {
  [x: string]: any
}

export const FinancialDetails: FC<IFinancialDetailsProps> = ({
  item,
  selectedCandidate,
  ...restProps
}: IFinancialDetailsProps) => {
  const dispatch = useDispatch()

  return (
    <div className='flex flex-col' {...restProps}>
      <div className='top-section flex justify-between items-center'>
        <span className='text-small font-SemiBold text-Gray-800'>
          Financials
        </span>

        <div className='flex'>
          <Button
            onClick={() => {
              if (item?.accountNo && item?.superProvider) {
                dispatch(
                  setEditDrawer({
                    mode: 'candidate financial',
                    isOpen: true,
                  }),
                )
              } else {
                window.open(
                  `/candidate-list/candidate-profile?&candidate_id=${selectedCandidate?.id}&candidate_name=${selectedCandidate?.name}`,
                )
              }
            }}
            className='ml-2'
            size='sm'
            textTheme='blue'
            theme='white'>
            {item?.accountNo && item?.superProvider
              ? `Edit`
              : `Edit in Candidate Profile`}
          </Button>
        </div>
      </div>

      <div className='data-section flex flex-col gap-y-2 mt-5'>
        {FinancialProps?.map((itm: any, idx: any) => (
          <div key={idx} className='grid grid-cols-3 w-full text-small'>
            <span className='w-full list-item ml-6 text-Gray-500 col-span-1'>
              {itm}:
            </span>

            <span className='text-Gray-800 col-span-2'>
              {itm === 'Account No' && (item?.accountNo || '-')}
              {itm === 'BBS No' && (item?.bbsNo || '-')}
              {itm === 'Account Name' && (item?.accountName || '-')}
              {itm === 'Bank Name' && (item?.bankName || '-')}
              {itm === 'Has Super' && (item?.hasSuper || '-')}
              {itm === 'Super Provider' && (item?.superProvider || '-')}
              {itm === 'Super Name' && (item?.superName || '-')}
              {itm === 'Membership No' && (item?.membershipNo || '-')}
              {itm === 'Super ABN' && (item?.superABN || '-')}
              {itm === 'Super USI' && (item?.superUSI || '-')}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

FinancialDetails.defaultProps = {}

export default FinancialDetails
