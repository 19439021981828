/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, ReactNode, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import { Button } from '@labourhub/labour-hub-ds'
import dragAndDropArea from 'assets/images/dragAndDropArea.svg'
import dragAndDropRectangleArea from 'assets/images/dragAndDropRectangleArea.svg'
import CN from 'classnames'
import { motion } from 'framer-motion'

import { getCroppedImg } from './ImageCropAndScale'

import './CustomizableEditProfilePictureModal.scss'

interface CustomizableEditProfilePictureModalProps {
  className?: string | undefined
  headerTitle?: string | undefined
  headerSubTitle?: string | undefined
  imageSource?: string
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  primaryButtonIsLoading?: boolean | undefined
  secondaryButtonTitle?: string
  leftButtonContent?: ReactNode | undefined
  isRectangleLogo?: boolean | undefined
  imageUrl: any
  setImageUrl: any
  setFiles: any
  [x: string]: any
}

export const CustomizableEditProfilePictureModal: FC<
  CustomizableEditProfilePictureModalProps
> = ({
  className,
  headerTitle,
  headerSubTitle,
  imageUrl,
  setImageUrl,
  isActive,
  isCustomStyles,
  setFiles,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  primaryButtonIsLoading,
  secondaryButtonTitle,
  leftButtonContent,
  isRectangleLogo,
}: CustomizableEditProfilePictureModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [newUploadPanel, setNewUploadPanel] = useState(true)
  const [isDisabledResetImageButton, setIsDisabledResetImageButton] =
    useState(true)
  const [warningMessage, setWarningMessage] = useState('')

  const [droppedFile, setDroppedFile] = useState<any>()

  useEffect(() => {
    setZoom(1)
    setNewUploadPanel(imageUrl ? false : true)
  }, [imageUrl])

  useEffect(() => {
    isActive && setCrop({ x: 0, y: 0 })
  }, [isActive])

  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-20',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const onCropChange = (crop) => {
    setCrop(crop)
  }

  const onCropComplete = async (_, croppedAreaPixels) => {
    if (zoom !== 1.01) {
      const croppedImage = await getCroppedImg(
        isRectangleLogo,
        imageUrl,
        croppedAreaPixels,
      )
      setFiles(croppedImage)
    }
  }

  const onZoomChange = (e: any) => {
    setIsDisabledResetImageButton(false)
    setZoom(e.target.value)
  }

  const onZoomChangeCrop = (zoom) => {
    setIsDisabledResetImageButton(false)
    setZoom(zoom)
  }

  /** handle image upload file on drop */
  const onDrop = (accepted) => {
    if (
      accepted[0].type === 'image/jpeg' ||
      accepted[0].type === 'image/png' ||
      accepted[0].type === 'image/jpg'
    ) {
      setDroppedFile(accepted)
      addFile(accepted)
      setWarningMessage('')
    } else {
      const message = 'Please submit valid file type (jpg, png, jpeg)'
      setWarningMessage(message)
    }
  }

  const addFile = (file: any) => {
    setNewUploadPanel(false)
    file.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setFiles(file)
      setImageUrl(file.preview)
    })
  }

  useEffect(() => {
    if (zoom === 1.01) {
      addFile(droppedFile)
    }
  }, [zoom])

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setZoom(1)
        setImageUrl('')
        onOverlayClick && onOverlayClick()
      }}>
      {/* Modal main container  */}
      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className='modal-container w-[600px] flex flex-col justify-between items-center h-auto bg-white shadow-lg rounded-md'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='modal-Header flex w-full items-center justify-between pl-6 py-[17px] pr-6 border-b-[1px] border-Gray-200'>
          <div className='header-title flex flex-col justify-center items-start'>
            <span className='text-heading-4 font-Medium text-Gray-700'>
              {headerTitle}
            </span>
            <span className='text-small font-Regular text-Gray-600 mt-1'>
              {headerSubTitle}
            </span>
          </div>

          <Button
            icon={<i className='ri-close-line' />}
            isIconOnly
            onClick={(e: any) => {
              e.stopPropagation()
              e.preventDefault()
              setZoom(1)
              setImageUrl('')
              onHeaderCloseButtonClick && onHeaderCloseButtonClick()
            }}
            textTheme='blue'
            theme='white'
          />
        </div>

        {/* Modal body */}
        {newUploadPanel ? (
          <div className='w-full'>
            <div className='flex flex-col w-full px-[118px] py-[50px]'>
              <Dropzone
                key={Math.random()}
                multiple={false}
                onDrop={(accepted) => {
                  onDrop(accepted)
                }}>
                {({ getRootProps, getInputProps }) => (
                  <div id={Math.random().toString()}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className={CN(
                          'flex flex-col justify-center items-center',
                        )}>
                        <img
                          src={
                            isRectangleLogo
                              ? dragAndDropRectangleArea
                              : dragAndDropArea
                          }
                          className={CN({
                            'w-[224px]': !isRectangleLogo,
                            'w-[360px] object-contain': isRectangleLogo,
                          })}
                          alt='drag-and-drop-area'
                        />

                        <span className='w-full text-center text-extra-small text-Gray-400 pt-3'>
                          {!isRectangleLogo
                            ? `To get the best results make sure your image is
                          squared one. width and height should be identical or
                          1:1 ratio.`
                            : `To get the best results make sure your image is rectangular one. 
                            Ratio for width and height should be 5:1 or similar.`}
                        </span>

                        <span className='text-small text-Gray-400 py-3'>
                          or
                        </span>
                        <span className='flex text-small text-Cobalt-600 font-Medium cursor-pointer'>
                          <i className='ri-upload-2-fill mr-2' />
                          Upload a photo
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>

            {warningMessage && (
              <div className='flex w-full bg-Red-50 justify-start items-center py-2'>
                <i className='ri-error-warning-line text-Red-500 mr-[11.6px] text-heading-4 ml-6' />
                <span className='text-small text-Red-600 font-Regular'>
                  {warningMessage}
                </span>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='relative mt-[100px]'>
              <div className='relative'>
                <div
                  className={CN(
                    'crop-container relative top-0 left-0 right-0',
                    {
                      'h-[130px] w-[360px]': isRectangleLogo,
                      'h-56 w-56 ': !isRectangleLogo,
                    },
                  )}>
                  {!newUploadPanel && (
                    <Cropper
                      image={imageUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={!isRectangleLogo ? 1 : 4 / 1}
                      cropShape={!isRectangleLogo ? 'round' : 'rect'}
                      showGrid={false}
                      restrictPosition={false}
                      onCropChange={onCropChange}
                      onCropComplete={onCropComplete}
                      onZoomChange={onZoomChangeCrop}
                    />
                  )}
                </div>
              </div>
              <div className='absolute top-0 right-[-42px]'>
                <Button
                  icon={<i className='ri-delete-bin-line text-Cobalt-600' />}
                  size='sm'
                  isIconOnly
                  className='mb-2'
                  onClick={() => {
                    setCrop({ x: 0, y: 0 })
                    setImageUrl('')
                    setNewUploadPanel(true)
                  }}
                  textTheme='blue'
                  theme='white'
                />
                <Button
                  icon={
                    <i
                      className={CN('ri-refresh-line', {
                        'text-Cobalt-600': !isDisabledResetImageButton,
                        'text-Gray-400': isDisabledResetImageButton,
                      })}
                    />
                  }
                  isDisabled={isDisabledResetImageButton}
                  size='sm'
                  isIconOnly
                  onClick={() => {
                    setCrop({ x: 0, y: 0 })
                    setZoom(1.01)
                    setIsDisabledResetImageButton(true)
                  }}
                  textTheme='blue'
                  theme='white'
                />
              </div>
            </div>

            {/* image scaler */}

            <div className='scaler flex mt-3 mb-[68px] w-[224px]'>
              <i className='ri-indeterminate-circle-fill text-Gray-400' />
              <input
                className='w-full mx-3'
                name='scale'
                type='range'
                onChange={onZoomChange}
                min='0.1'
                max='2'
                value={zoom}
                step='0.01'
              />
              <i className='ri-add-circle-fill text-Gray-400' />
            </div>
          </>
        )}

        {/* Modal footer */}
        <div
          className={CN(
            'modal-footer flex w-full py-5 px-6 items-center  border-t-[1px] border-Gray-200',
            {
              'justify-between': leftButtonContent,
              'justify-end': leftButtonContent === undefined,
            },
          )}>
          {/* password upload btn */}

          {leftButtonContent}

          <div className='flex'>
            {/*footer primary button*/}
            {primaryButtonTitle !== '' && (
              <Button
                theme='gradient'
                isLoading={primaryButtonIsLoading}
                className='mr-2 w-[88px]'
                isDisabled={newUploadPanel ? true : false}
                onClick={() => {
                  onClickPrimaryBtn && onClickPrimaryBtn()
                }}>
                {primaryButtonTitle}
              </Button>
            )}

            {/*footer secondary button*/}
            {secondaryButtonTitle !== '' && (
              <Button
                className='w-[88px]'
                textTheme='blue'
                theme='white'
                onClick={() => {
                  setZoom(1)
                  setCrop({ x: 0, y: 0 })
                  setImageUrl('')
                  onClickSecondaryBtn && onClickSecondaryBtn()
                }}>
                {secondaryButtonTitle}
              </Button>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default CustomizableEditProfilePictureModal

CustomizableEditProfilePictureModal.defaultProps = {
  className: undefined,
  headerTitle: undefined,
  headerSubTitle: undefined,
  isActive: true,
  isCustomStyles: false,
  onClickPrimaryBtn: undefined,
  onClickSecondaryBtn: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Save',
  primaryButtonIsLoading: false,
  secondaryButtonTitle: 'Cancel',
  leftButtonContent: undefined,
  isRectangleLogo: false,
}
