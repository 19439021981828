/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import CN from 'classnames'

export interface AuditPassFailButtonsProps {
  isActive?: boolean
  isDisabled?: boolean
  onClick?: any
  type: 'pass' | 'fail'
  [x: string]: any
}

export const AuditPassFailButtons: FC<AuditPassFailButtonsProps> = ({
  className,
  isActive,
  isDisabled,
  onClick,
  type,
  ...restProps
}: AuditPassFailButtonsProps) => {
  const AuditPassFailButtonsClasses = CN(
    `audit-pass-fail-buttons flex justify-between items-center border px-5 py-3 rounded-lg`,
    {
      'bg-Gray-200 border-Gray-300 hover:bg-Gray-200  cursor-not-allowed ':
        isDisabled,
      'bg-white border-Gray-300 hover:bg-Gray-50  cursor-pointer ':
        !isActive && !isDisabled,
      'bg-Green-50 border-Green-600  cursor-pointer ':
        isActive && type === 'pass' && !isDisabled,
      'bg-Red-50 border-Red-600  cursor-pointer ':
        isActive && type === 'fail' && !isDisabled,
    },
    className,
  )

  return (
    <div
      className={AuditPassFailButtonsClasses}
      {...restProps}
      onClick={() => {
        !isDisabled && onClick()
      }}>
      {type === 'pass' ? (
        <>
          <span
            className={CN('font-Medium', {
              'text-Gray-400': isDisabled,
              'text-Gray-800': !isActive && !isDisabled,
              'text-Green-600': isActive && !isDisabled,
            })}>
            Audit Passed
          </span>

          <i
            className={CN(
              'ri-check-line text-[16px] flex w-[26px] h-[26px] justify-center items-center rounded-full border ',
              {
                'text-Gray-300 bg-Gray-200 border-Gray-300': isDisabled,
                'text-Gray-300 border-Gray-300': !isActive && !isDisabled,
                'text-white bg-Green-600 border-Green-600':
                  isActive && !isDisabled,
              },
            )}
          />
        </>
      ) : (
        <>
          <span
            className={CN('font-Medium', {
              'text-Gray-400': isDisabled,
              'text-Gray-800': !isActive && !isDisabled,
              'text-Red-600': isActive && !isDisabled,
            })}>
            Audit Failed
          </span>

          <i
            className={CN(
              'ri-close-line text-[16px] flex w-[26px] h-[26px] justify-center items-center rounded-full border ',
              {
                'text-Gray-300 bg-Gray-200 border-Gray-300': isDisabled,
                'text-Gray-300 border-Gray-300': !isActive && !isDisabled,
                'text-white bg-Red-600 border-Red-600': isActive && !isDisabled,
              },
            )}
          />
        </>
      )}
    </div>
  )
}

AuditPassFailButtons.defaultProps = {
  type: undefined,
  onClick: undefined,
  isDisabled: false,
}

export default AuditPassFailButtons
