import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditVisaInformationProps {
  candidateWorkRightId: any
  visaInformation: any
  candidateId: any
}

export const useEditVisaInformation = () => {
  const mutate = useMutation(
    ({
      visaInformation,
      candidateWorkRightId,
      candidateId,
    }: EditVisaInformationProps) => {
      return putAPI(
        `/facade/Candidate/${candidateId}/WorkRights/${candidateWorkRightId}`,
        {
          workRightsId: visaInformation.visaTypeId,
          note: visaInformation.note,
        },
      )
    },
  )

  return mutate
}

export default useEditVisaInformation
