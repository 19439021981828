/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react'
import {
  Checkbox,
  Radio,
  SimpleSelect,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { Rate } from 'components/atoms'
import { EditQuestionaryBuilder } from 'components/Drawers/Questionnaire/EditQuestionaryBuilder'

export interface QuestionnaireComponentsProps {
  index?: number | undefined
  onDeleteClick?: any | undefined
  onDragClick?: any | undefined
  onEditClick?: any | undefined
  questionTitle?: string
  optionList?: any | undefined
  starCount?: number | undefined
  type:
    | 'Short Answer'
    | 'Long Answer'
    | 'Rating'
    | 'Multi Choice'
    | 'Yes/No'
    | 'Select Box'
    | 'Detailed Yes'
    | 'Detailed No'
  [x: string]: any
}

export const QuestionnaireComponents: FC<QuestionnaireComponentsProps> = ({
  type,
  onEditClick,
  onDeleteClick,
  onDragClick,
  index,
  questionTitle,
  isRequired,
  className,
  optionList,
  starCount,
  setEditableRow,
  ...restProps
}: QuestionnaireComponentsProps) => {
  /** text area and field states */
  const [textValue, setTextValue] = useState('')

  /** drop down states */
  const [dropDownValue, setDropDownValue] = useState({ value: '', label: '' })

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [questionTitleUpdated, setQuestionTitleUpdated] =
    useState(questionTitle)
  const [isRequiredUpdated, setIsRequiredUpdated] = useState(isRequired)
  const [options, setOptions] = useState<Array<any>>(optionList)
  const [starCountTemp, setStarCountTemp] = useState(starCount)

  /** check box states */
  const [checkedState, setCheckedState] = useState(
    new Array(optionList.length).fill(false),
  )

  /** rating states */
  const [rating, setRating] = useState('0')

  /**radio button states */
  const [radioButtonState, setRadioButtonState] = useState<boolean | undefined>(
    undefined,
  )

  /** main style class */
  const QuestionnaireComponentsClasses = CN(
    `group w-full h-fit flex flex-col justify-start items-center p-4 rounded-lg border border-dashed border-Gray-300 bg-white hover:border-Cobalt-200 cursor-pointer select-none`,
    className,
  )
  const ActionButtonClasses =
    'w-[30px] h-[30px] flex justify-center items-center rounded text-Gray-600 bg-Gray-50 border border-Gray-200'

  const onChange = (editableResults: any) => {
    setQuestionTitleUpdated(editableResults?.title)
    setIsRequiredUpdated(editableResults?.isRequiredUpdated)
    setOptions(editableResults?.options)
    setStarCountTemp(editableResults?.starCount)
    const starArrayTemp = Array.from(
      Array(Number(editableResults?.starCount)).keys(),
    )
    const starArray = starArrayTemp.map((item: number) => item + 1).map(String)

    setEditableRow({
      title: editableResults?.title,
      isRequired: editableResults?.isRequiredUpdated,
      meta:
        editableResults?.headerTitle === 'Rating'
          ? starArray
          : editableResults?.options.map(({ label }: any) => label) || [],
      index: index,
      type: type,
    })
  }

  return (
    <div className={QuestionnaireComponentsClasses} {...restProps}>
      {/* top index and tag section */}
      <div className='flex items-center justify-between w-full'>
        {/* index section */}
        <div className='flex items-center justify-center px-3 py-1 border rounded-full text-Cobalt-600 font-Medium text-extra-small border-Cobalt-100 bg-Cobalt-50'>
          Q{index}
        </div>

        {/* badge section */}
        <div className='flex items-center justify-center px-2 py-1 rounded-full text-Gray-50 font-Medium text-extra-small bg-Gray-400 group-hover:bg-Cobalt-400'>
          <i
            className={CN('text-[14px] mr-2 text-Gray-50', {
              'ri-text-spacing': type === 'Short Answer',
              'ri-text-wrap': type === 'Long Answer',
              'ri-star-line': type === 'Rating',
              'ri-checkbox-multiple-line': type === 'Multi Choice',
              'ri-radio-button-line': type === 'Yes/No',
              'ri-arrow-down-s-line': type === 'Select Box',
              'ri-checkbox-circle-line': type === 'Detailed Yes',
              'ri-close-circle-line': type === 'Detailed No',
            })}
          />

          {type}
        </div>
      </div>

      {/* title and action button section */}
      <div className='flex w-full justify-between items-center mt-2 mb-[10px]'>
        {/* title section */}
        <span
          className={CN('text-Gray-800 font-Medium', {
            "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
              isRequiredUpdated,
          })}>
          {questionTitleUpdated}
        </span>

        {/* button section */}
        <div className='flex invisible button-section gap-x-2 group-hover:visible'>
          {/* edit button */}
          <div
            className={CN(
              ActionButtonClasses,
              'hover:text-Cobalt-600 hover:bg-Cobalt-50 hover:border-Cobalt-200',
            )}
            onClick={() => {
              setIsEditDrawerOpen(true)
              onEditClick && onEditClick()
            }}>
            <i className='ri-pencil-line' />
          </div>

          {/* delete button */}
          <div
            className={CN(
              ActionButtonClasses,
              'hover:text-Red-600 hover:bg-Red-50 hover:border-Red-200',
            )}
            onClick={() => {
              onDeleteClick && onDeleteClick()
            }}>
            <i className='ri-delete-bin-line' />
          </div>

          {/* drag button */}
          <div
            className={CN(
              ActionButtonClasses,
              'hover:text-Cobalt-600 hover:bg-Cobalt-50 hover:border-Cobalt-200',
            )}
            onClick={() => {
              onDragClick && onDragClick()
            }}>
            <i className='ri-drag-move-2-fill' />
          </div>
        </div>
      </div>

      {/* bottom component section */}
      <div className='flex w-full mt-3'>
        {type === 'Short Answer' && (
          <TextField
            className='w-full'
            isDisabled={true}
            value={textValue}
            onChange={(e: any) => {
              setTextValue(e.target.value)
            }}
            placeholder=''
          />
        )}

        {type === 'Long Answer' && (
          <TextArea
            className='w-full'
            value={textValue}
            isDisabled={true}
            onChange={(e: any) => {
              setTextValue(e.target.value)
            }}
            placeholder=''
            rows={4}
          />
        )}

        {type === 'Rating' && (
          <Rate
            rating={rating}
            onRating={(rate) => setRating(rate)}
            count={starCountTemp}
          />
        )}

        {type === 'Select Box' && (
          <SimpleSelect
            className='w-full'
            value={dropDownValue?.label ? dropDownValue : null}
            onChange={(selectedItem: any) => {
              setDropDownValue({
                ...dropDownValue,
                value: selectedItem?.label,
                label: selectedItem?.label,
              })
            }}
            options={options}
            placeholder='Select Option'
          />
        )}

        {type === 'Multi Choice' && (
          <div className='flex flex-col gap-y-2'>
            {options?.map((item, idx) => (
              <Checkbox
                key={idx}
                id={`custom-checkbox-${idx}`}
                checked={checkedState[idx]}
                onChange={() => {
                  const updatedCheckedState = checkedState.map((item, index) =>
                    index === idx ? !item : item,
                  )
                  setCheckedState(updatedCheckedState)
                }}
                labelText={item?.label}
                labelTextClassName='font-Regular'
                checkboxClassName='ml-0'
              />
            ))}
          </div>
        )}

        {type === 'Yes/No' && (
          <div className='flex gap-6'>
            <Radio
              checked={radioButtonState}
              id='yes'
              className='cursor-pointer select-none'
              onChange={() => {
                setRadioButtonState(true)
              }}
              name='statusYes'
              helperText=''
              labelText='Yes'
            />

            <Radio
              checked={
                radioButtonState !== undefined ? !radioButtonState : undefined
              }
              className='cursor-pointer select-none'
              id='no'
              onChange={() => {
                setRadioButtonState(false)
              }}
              name='statusNo'
              helperText=''
              labelText='No'
            />
          </div>
        )}

        {type === 'Detailed No' && (
          <div className='flex flex-col w-full'>
            <div className='flex gap-6'>
              <Radio
                checked={radioButtonState}
                id='detailedNo-yes'
                className='cursor-pointer select-none'
                onChange={() => {
                  setRadioButtonState(true)
                }}
                name='detailedNo'
                helperText=''
                labelText='Yes'
              />

              <Radio
                checked={
                  radioButtonState !== undefined ? !radioButtonState : undefined
                }
                className='cursor-pointer select-none'
                id='detailedNo-no'
                onChange={() => {
                  setRadioButtonState(false)
                }}
                name='detailedNo'
                helperText=''
                labelText='No'
              />
            </div>

            {!radioButtonState && radioButtonState !== undefined && (
              <TextArea
                className='w-full mt-3'
                isDisabled={true}
                value={textValue}
                onChange={(e: any) => {
                  setTextValue(e.target.value)
                }}
                placeholder=''
                rows={4}
              />
            )}
          </div>
        )}

        {type === 'Detailed Yes' && (
          <div className='flex flex-col w-full'>
            <div className='flex gap-6'>
              <Radio
                checked={radioButtonState}
                id='detailedYes-yes'
                className='cursor-pointer select-none'
                onChange={() => {
                  setRadioButtonState(true)
                }}
                name='detailedYes'
                helperText=''
                labelText='Yes'
              />

              <Radio
                checked={
                  radioButtonState !== undefined ? !radioButtonState : undefined
                }
                className='cursor-pointer select-none'
                id='detailedYes-no'
                onChange={() => {
                  setRadioButtonState(false)
                }}
                name='detailedYes'
                helperText=''
                labelText='No'
              />
            </div>

            {radioButtonState && (
              <TextArea
                className='w-full mt-3'
                isDisabled={true}
                value={textValue}
                onChange={(e: any) => {
                  setTextValue(e.target.value)
                }}
                placeholder=''
                rows={4}
              />
            )}
          </div>
        )}
      </div>
      {/** drawer open */}
      <EditQuestionaryBuilder
        isActive={isEditDrawerOpen}
        setIsEditDrawerOpen={setIsEditDrawerOpen}
        headerTitle={type}
        questionTitle={questionTitleUpdated}
        isRequiredUpdated={isRequiredUpdated}
        onChange={onChange}
        optionList={options}
        starCount={starCountTemp}
      />
    </div>
  )
}

QuestionnaireComponents.defaultProps = {
  index: undefined,
  questionTitle: 'Question Title',
  onDeleteClick: undefined,
  onDragClick: undefined,
  onEditClick: undefined,
  optionList: [
    {
      label: 'Option 1',
    },
  ],
  starCount: 3,
}

export default QuestionnaireComponents
