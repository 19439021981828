import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetAuditCheckDocumentListInterface {
  candidateId: string
  documentIds: string[]
}

export const useGetAuditCheckDocumentList = () => {
  const mutate = useMutation(
    ({ candidateId, documentIds }: GetAuditCheckDocumentListInterface) => {
      return postAPI(`/facade/Candidate/${candidateId}/MultiView`, {
        documentIds,
      })
    },
  )

  return mutate
}

export default useGetAuditCheckDocumentList
