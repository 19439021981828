import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getHealthDeclarationTemplateList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `Questionnaire?Category=${reqBody.category}&IsActive=${reqBody.isActive}`,
  )
  return res.data
}

export const useGetHealthDeclarationList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(
    ['Health_Declaration_Template_List', reqBody],
    getHealthDeclarationTemplateList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
