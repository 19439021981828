import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { RightSideModal, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateNewRegPackTemplate } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'

export interface RegPackSaveAsNewTemplateProps {
  [x: string]: any
}

export const RegPackSaveAsNewTemplate: FC<RegPackSaveAsNewTemplateProps> = ({
  getRegPackList,
  isActive,
  setIsSaveAsTemplateDrawerOpen,
  setTemplateId,
}: RegPackSaveAsNewTemplateProps) => {
  const [templateName, setTemplateName] = useState('')

  const { templateDetails } = useSelector((state: any) => state.regPack)

  /** Reset name when close drawer */
  const resetFieldValues = () => {
    setTemplateName('')
  }

  /** APi call for the save new template */
  const {
    mutate: createNewRegPackTemplateMutate,
    isLoading: createNewRegPackTemplateIsLoading,
  } = useCreateNewRegPackTemplate()

  /** Process the ave new template  */
  const createNewRegPackTemplate = () => {
    createNewRegPackTemplateMutate(
      { name: templateName, templateDetails },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            status: 'Success',
          })
          setTemplateId(successData?.regPackTemplate?.id)
          setIsSaveAsTemplateDrawerOpen(false)
          getRegPackList()
          resetFieldValues()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error saving template.',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Save as Template'
      headerSubtitle='Save this as a template for future reference'
      primaryButtonTitle='Save as Template'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={!templateName}
      onFooterAddButtonClick={() => {
        createNewRegPackTemplate()
      }}
      onFooterCancelButtonClick={() => {
        setIsSaveAsTemplateDrawerOpen(false)
        resetFieldValues()
      }}
      onHeaderCloseButtonClick={() => {
        setIsSaveAsTemplateDrawerOpen(false)
        resetFieldValues()
      }}>
      {/* Loader */}
      {createNewRegPackTemplateIsLoading && <PageLoader size='xxs' />}

      {/* main body */}
      <div className='flex flex-col w-full px-6 pt-5'>
        <TextField
          className='template-name'
          value={templateName}
          onChange={(e: any) => {
            setTemplateName(e.target.value)
          }}
          label='Template Name'
          placeholder=''
        />
      </div>
    </RightSideModal>
  )
}

RegPackSaveAsNewTemplate.defaultProps = {}

export default RegPackSaveAsNewTemplate
