import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetStateListByCountyCodeProps {
  countryCode?: string
}

export const useGetStateListByCountyCode = () => {
  const mutate = useMutation(
    ({ countryCode }: GetStateListByCountyCodeProps) => {
      return get(`/State?CountryCode=${countryCode}`, {})
    },
  )

  return mutate
}

export default useGetStateListByCountyCode
