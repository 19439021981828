import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetMedicalIncidentByIdType {
  medicalIncidentId?: string | null
  candidateId?: string | null
  locationId?: string | null
  locationName?: string | null
  title?: string | null
  date?: any
  note?: string | null
  restrictionsCount: number
  restrictions?: string[] | undefined | null
  isWorkCover: boolean
  createdOn?: any
  updatedOn?: any
  attachmentCount: 0
  medicalStatus?: string | null
  documentByTypes?: any
}

export const useGetMedicalIncidentById = (
  candidateId: string,
  documentId: string,
) => {
  return useQuery(
    ['GET_MEDICAL_INCIDENT_BY_ID'],
    async () => {
      const res = await get(
        `/facade/Candidate/${candidateId}/Incident/${documentId}`,
      )
      return res.data.medicalIncidentDetails as GetMedicalIncidentByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
