import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateAgencyLocationProps {
  ID?: string
  LID?: string
  obj?: any
}

export const useUpdateAgencyLocation = () => {
  const mutate = useMutation(({ obj, ID, LID }: UpdateAgencyLocationProps) => {
    return putAPI(`/facade/AgencyProfile/${ID}/Location/${LID}`, {
      id: obj?.id,
      name: obj?.locationName,
      phoneNumber: obj?.phoneNo,
      email: obj?.email,
      address: {
        lineOne: obj?.streetName,
        lineTwo: '',
        city: obj?.suburb,
        state: obj?.state,
        stateCode: obj?.stateCode,
        suburb: obj?.suburb,
        postCode: obj?.postCode,
        latitude: obj?.latitude !== '' ? obj?.latitude : 0,
        longitude: obj?.longitude !== '' ? obj?.longitude : 0,
        country: obj?.country,
        countryCode: obj?.countryCode,
      },
    })
  })

  return mutate
}

export default useUpdateAgencyLocation
