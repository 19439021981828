import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditPoliceCheckDetailsProps {
  candidateId?: any
  policeCheckDetailsData?: any
}

export const useEditPoliceCheckDetails = () => {
  const mutate = useMutation(
    ({ candidateId, policeCheckDetailsData }: EditPoliceCheckDetailsProps) => {
      return putAPI(
        `/Candidate/${candidateId}/PoliceCheckDetail/${policeCheckDetailsData?.policeCheckId}`,
        {
          state: policeCheckDetailsData?.state,
          licenseNo: policeCheckDetailsData?.licenseNo,
          passportNo: policeCheckDetailsData?.passportNo,
          passportCountryId: policeCheckDetailsData?.passportCountryId
            ? policeCheckDetailsData?.passportCountryId
            : null,
          visaTypeId: policeCheckDetailsData?.visaTypeId
            ? policeCheckDetailsData?.visaTypeId
            : null,
        },
      )
    },
  )

  return mutate
}

export default useEditPoliceCheckDetails
