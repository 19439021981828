/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface VerticalTabProps {
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  defaultTabId?: any | undefined
  tabDetails?:
    | {
        id: any
        tabButtonContent: string | ReactNode
        tabContent: any
      }[]
    | undefined
  onChange?: (id: any) => void
  onHeaderBtnClick?: () => void
  isContentFooterNxtPrevBtnEnabled?: boolean | undefined
  [x: string]: any
}

export const VerticalTab: FC<VerticalTabProps> = ({
  className,
  defaultTabId,
  isActive,
  tabDetails,
  onChange,
  contentClassName,
  tabHeaderClassName,
  tabHeaderButtonClassName,
  onHeaderBtnClick,
  isContentFooterNxtPrevBtnEnabled,
  ...restProps
}: VerticalTabProps) => {
  const TabsClasses = CN(`tabs flex h-full w-full`, className)
  const [activeTab, setActiveTab] = useState<any>(defaultTabId)

  const [openTabId, setOpenTabId] = useState<any>(undefined)

  /** handle on tab change method */
  useEffect(() => {
    if (openTabId) {
      onChange && onChange(openTabId)
      setActiveTab(openTabId)
    } else {
      onChange && onChange(activeTab)
    }
  }, [activeTab, openTabId])

  /** set active tab */
  useEffect(() => {
    setActiveTab(defaultTabId)
  }, [defaultTabId, isActive])

  return (
    <div className={TabsClasses} {...restProps}>
      {/* tab header */}
      <div
        className={CN(
          'tabs-button flex flex-col border-l-Gray-200 text-left w-1/4 h-fit',
          tabHeaderClassName,
        )}>
        {tabDetails?.map((tabDetail, idx) => (
          <div
            key={tabDetail.id || idx}
            className={CN(
              'flex px-6 cursor-pointer',
              tabHeaderButtonClassName,
              {
                'text-Gray-700 border-l-4 border-l-Cobalt-400 font-SemiBold py-[22px] cursor-default':
                  activeTab === tabDetail.id,
                'text-Gray-400 hover:border-l-Gray-200 border-l-4 border-l-Gray-100 font-Medium text-small py-6 hover:text-Gray-600':
                  activeTab !== tabDetail.id,
              },
            )}
            onClick={() => {
              setActiveTab(tabDetail.id)
              onHeaderBtnClick && onHeaderBtnClick()
              setOpenTabId(undefined)
            }}>
            <div key={tabDetail.id || idx} className='text-left'>
              {tabDetail.tabButtonContent}
            </div>
          </div>
        ))}
      </div>

      {/* tab content */}

      <div
        className={CN(
          'tabs-content flex flex-col w-3/4 h-full overflow-y-auto styled-scroll',
        )}>
        <div
          className={CN(
            'flex overflow-y-auto styled-scroll',
            contentClassName,
            {
              'h-[calc(100%-64px)]': isContentFooterNxtPrevBtnEnabled,
              'h-full': !isContentFooterNxtPrevBtnEnabled,
            },
          )}>
          {
            tabDetails?.find((tabDetail) => tabDetail.id === activeTab)
              ?.tabContent
          }
        </div>

        {isContentFooterNxtPrevBtnEnabled && (
          <div
            className={CN('flex items-center h-[38px] mt-5 px-[2px]', {
              'justify-between':
                activeTab !== 1 || (tabDetails && tabDetails?.length == 1),
              'justify-end':
                activeTab == 1 || (tabDetails && tabDetails?.length > 1),
              'justify-start':
                activeTab == tabDetails?.length ||
                (tabDetails && tabDetails?.length > 1),
            })}>
            {activeTab !== 1 && (
              <Button
                iconLeft={<i className='ri-arrow-left-line' />}
                className='prev-btn h-[38px]'
                onClick={() => {
                  setOpenTabId(activeTab - 1)
                }}
                textTheme='blue'
                theme='white'>
                PREVIOUS
              </Button>
            )}

            {activeTab !== tabDetails?.length && (
              <Button
                iconRight={<i className='ri-arrow-right-line' />}
                className='next-btn h-[38px]'
                onClick={() => {
                  setOpenTabId(activeTab + 1)
                }}
                textTheme='blue'
                theme='white'>
                NEXT
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

VerticalTab.defaultProps = {
  children: undefined,
  className: undefined,
  tabHeaderButtonClassName: undefined,
  tabHeaderClassName: undefined,
  defaultTabId: undefined,
  tabDetails: undefined,
  onChange: undefined,
  onHeaderBtnClick: undefined,
  isContentFooterNxtPrevBtnEnabled: false,
}

export default VerticalTab
