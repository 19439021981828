import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { ProfileAvatar } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface ClientProfileDetailSectionProps {
  [x: string]: any
  onAddNewLogoClick?: () => void
  onDeleteLogoClick?: () => void
  onEditLogoClick?: () => void
}

export const ClientProfileDetailSection: FC<
  ClientProfileDetailSectionProps
> = ({
  className,
  clientData,
  onAddNewLogoClick,
  onDeleteLogoClick,
  onEditLogoClick,
  onClientDetailsEditButtonClick,
}: ClientProfileDetailSectionProps) => {
  const ClientProfileDetailSectionClasses = CN(
    `client-profile-detail-section flex bg-white border border-Gray-200 rounded-md pt-4 pr-5 pb-[22px]`,
    className,
  )

  return (
    <div className={ClientProfileDetailSectionClasses}>
      {/* left side profile picture section */}

      <ProfileAvatar
        isProfilePictureAvailable={clientData?.logoUrl ? true : false}
        imageSource={clientData?.logoUrl ? clientData?.logoUrl : undefined}
        isStatusDisable={!clientData?.isActive}
        isAnAgency={true}
        className='px-[34px] pt-1'
        imageContainerClassName='w-[132px] h-[132px]'
        onAddClick={onAddNewLogoClick}
        onDeleteClick={onDeleteLogoClick}
        onEditClick={onEditLogoClick}
      />

      {/* right side detail section */}
      <div className='flex flex-col w-full items-start'>
        {/* header client name and disable button section */}
        <div className='header-section flex w-full justify-between items-center'>
          <div className='flex items-center w-3/4'>
            <span
              className='text-heading-4 text-Gray-800 font-Bold line-clamp-1'
              data-tip={clientData?.name}
              data-for='client-name'>
              {clientData?.name}
            </span>

            <ReactTooltip
              id='client-name'
              place='top'
              effect='solid'
              textColor='#E5E7EB'
              backgroundColor='#1F2937'
            />

            {!clientData?.isActive && (
              <Badge size='large' theme='red' className='ml-4'>
                Disabled
              </Badge>
            )}
          </div>

          <Button
            size='sm'
            onClick={onClientDetailsEditButtonClick}
            textTheme='blue'
            theme='white'>
            Edit
          </Button>
        </div>

        {/* card body section */}
        <div className='body-section flex flex-col w-full divide-y divide-Gray-200 pt-6 pr-[13px]'>
          <div className='top-details-section pb-4 flex'>
            {/* ABN section */}
            <div className='flex flex-col'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                ABN
              </span>

              <span className='text-small font-SemiBold text-Gray-800'>
                {clientData?.abn ? clientData?.abn : 'N/A'}
              </span>
            </div>

            {/* Industry section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Industry
              </span>

              <span className='text-small font-SemiBold text-Gray-800'>
                {clientData?.industry ? clientData?.industry : 'N/A'}
              </span>
            </div>

            {/* Website section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Website
              </span>

              {clientData?.website ? (
                <a
                  href={`//${clientData?.website}`}
                  target='_blank'
                  className='text-small font-SemiBold text-Gray-800 hover:text-Cobalt-500 max-w-[250px] line-clamp-1'
                  data-tip={clientData?.website}
                  data-for='client-website'>
                  {clientData?.website}

                  <ReactTooltip
                    id='client-website'
                    place='bottom'
                    effect='solid'
                    textColor='#E5E7EB'
                    backgroundColor='#1F2937'
                  />
                </a>
              ) : (
                <span className='text-small font-SemiBold text-Gray-800'>
                  N/A
                </span>
              )}
            </div>

            {/* Created On section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Created On
              </span>

              <span className='text-small font-SemiBold text-Gray-800'>
                {clientData?.createdOn
                  ? dateToReadableString(clientData?.createdOn)
                  : 'N/A'}
              </span>
            </div>

            {/* source section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Source
              </span>

              <span className='text-small font-SemiBold text-Gray-800 max-w-[300px] line-clamp-2'>
                {clientData?.source ? clientData?.source : 'N/A'}
              </span>
            </div>

            {/* Account Size section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Account Size
              </span>

              <span className='text-small font-SemiBold text-Gray-800'>
                {clientData?.size ? clientData?.size : 'N/A'}
              </span>
            </div>

            {/* Consultants section */}
            <div className='flex flex-col ml-[44px]'>
              <span className='text-extra-small font-Medium text-Gray-600'>
                Consultants
              </span>

              <span className='text-small font-SemiBold text-Gray-800'>
                {clientData?.consultantsCount
                  ? clientData?.consultantsCount
                  : 'N/A'}
              </span>
            </div>
          </div>
          {clientData?.about && (
            <div className='about-section pt-4 text-small font-Regular text-Gray-800'>
              {clientData?.about}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ClientProfileDetailSection.defaultProps = {
  onEditButtonClick: undefined,
  onAddNewLogoClick: undefined,
  onClientDetailsEditButtonClick: undefined,
  onDeleteLogoClick: undefined,
  onEditLogoClick: undefined,
}

export default ClientProfileDetailSection
