import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

interface GetFinancialInformationByCandidateIdResponse {
  financialDetailsModel: CandidateFinancialInformationType
  messageTitle: string | null
  message: string
}

export interface CandidateFinancialInformationType {
  candidateId: string
  bankAccountId: string
  accountName: string
  bankName: string
  bsbNumber: string
  accountNumber: string
  hasSuper: boolean
  superFundId: string
  superProviderName: string
  superName: string
  membershipNumber: string
  abn: string
  usi: string
  bankId: string
  superProviderId: string
  superNameId: string
  country: string
  countryCode: string
}

export const useGetFinancialInformationByCandidateId = (
  candidateId: string,
) => {
  return useQuery(
    ['GET_Financial_Information_BY_CANDIDATE_ID', candidateId],
    async () => {
      if (candidateId !== '') {
        const data = await get(
          `facade/DocumentAudit/${candidateId}/FinacialDetails`,
        )
        return (
          (data?.data as GetFinancialInformationByCandidateIdResponse) || []
        )
      }
    },
    {
      onError: () => {
        Toast({
          alertHeader: 'Error fetching candidate financial information',
          status: 'Error',
        })
      },
    },
  )
}

export default useGetFinancialInformationByCandidateId
