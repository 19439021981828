import { FC } from 'react'
import CN from 'classnames'

export interface QuestionLibraryCardItemProps {
  iconName?: string | undefined
  itemName?: string | undefined
  onDoubleClick?: () => void
  [x: string]: any
}

export const QuestionLibraryCardItem: FC<QuestionLibraryCardItemProps> = ({
  className,
  iconName,
  itemName,
  onDoubleClick,
  ...restProps
}: QuestionLibraryCardItemProps) => {
  /** card item main style */
  const QuestionLibraryCardItemClasses = CN(
    `question-library-card-item flex justify-start items-center rounded-lg border-[1.5px] border-dashed border-Gray-300 bg-Gray-50 pl-4 py-[18px] active:bg-Cobalt-50 active:border-Cobalt-200 cursor-pointer select-none`,
    className,
  )

  return (
    <div
      onDoubleClick={() => {
        onDoubleClick && onDoubleClick()
      }}
      className={QuestionLibraryCardItemClasses}
      {...restProps}>
      {/* left icon */}
      <i className={CN(iconName, 'text-[18px] text-Gray-500')} />

      {/* name text */}
      <span className='font-Medium text-Gray-800 ml-2'>{itemName}</span>
    </div>
  )
}

QuestionLibraryCardItem.defaultProps = {
  iconName: undefined,
  itemName: undefined,
}

export default QuestionLibraryCardItem
