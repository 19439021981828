import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface deactivatedCandidateProps {
  candidateId?: any
}

export const useDeactivatedCandidate = () => {
  const mutate = useMutation(({ candidateId }: deactivatedCandidateProps) => {
    return postAPI(`/facade/Candidate/${candidateId}/Deactivate`, {})
  })

  return mutate
}

export default useDeactivatedCandidate
