import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetPrescriptionAndMedConditionByIdType {
  medicalConditionId?: string | null
  candidateId?: string | null
  documentId?: string | null
  title?: string | null
  dateOfIssue?: any
  notes?: string | null
  isWorkingAffected: true
  categoryId?: string | null
  category?: string | null
  typeId?: string | null
  type?: string | null
  expiryOn?: any
  dueOn?: any
  createdOn?: any
  updatedOn?: any
  status?: string | null
  auditRequired: boolean
  isArchived: boolean
  attachmentUrls?: string[] | undefined | null
  attachmentSignedUrls?: string[] | undefined | null
}

export const useGetPrescriptionAndMedConditionById = (
  candidateId: string,
  documentId: string,
) => {
  return useQuery(
    ['GET_PRESCRIPTION_AND_MEDICAL_CONDITION_BY_ID'],
    async () => {
      const res = await get(
        `/facade/Candidate/${candidateId}/MedicalCondition/${documentId}`,
      )
      return res.data
        .medicalConditionDetails as GetPrescriptionAndMedConditionByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
