/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { DocumentStatusIcon } from 'components/atoms'

export interface AccordionProps {
  [x: string]: any
}

export const Accordion: FC<AccordionProps> = ({
  items,
  onClickItem,
  defaultOpenIndex,
  ...restProps
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<any>(0)

  const handleClick = (index: number, item: any) => {
    setActiveIndex(index === activeIndex && activeIndex === null ? null : index)
    onClickItem && onClickItem(item)
  }

  useEffect(() => {
    setActiveIndex(defaultOpenIndex)
  }, [defaultOpenIndex])

  return (
    <div className='accordion w-full'>
      {items.map((item: any, index: number) => (
        <div key={index} className='pb-4'>
          <div
            onClick={() => handleClick(index, item)}
            className={CN(
              'w-full flex justify-between items-center py-2 border rounded font-Medium text-[16px] cursor-pointer',
              {
                'border-Cobalt-600 bg-Cobalt-50 text-Cobalt-500':
                  activeIndex === index,
                'border-Gray-200 text-Gray-800 bg-white': activeIndex !== index,
              },
            )}>
            <div className='pl-2 flex items-center'>
              {item?.title === 'Candidate Information' ? (
                <i
                  className={CN('ri-account-circle-line text-[20px]', {
                    'text-Cobalt-500': item?.title === 'Candidate Information',
                  })}
                />
              ) : (
                <div>{item?.sectionIndex}.</div>
              )}

              <div
                className={CN('pl-2', {
                  'text-Cobalt-500': item?.title === 'Candidate Information',
                })}>
                {item?.title}
              </div>

              {item?.attachmentCount ? (
                <div
                  className={CN(
                    'ml-2 px-2 py-[2px] text-extra-small rounded-[10px] flex justify-center items-center',
                    {
                      'bg-Gray-100 text-Gray-800': activeIndex !== index,
                      'bg-Cobalt-100 text-Cobalt-600 ': activeIndex === index,
                    },
                  )}>
                  <i className='ri-attachment-2 text-[12px] mr-1' />

                  {item?.attachmentCount < 10
                    ? `0${item?.attachmentCount}`
                    : item?.attachmentCount}
                </div>
              ) : null}

              {item?.auditStatus ? (
                <DocumentStatusIcon
                  status={item?.auditStatus}
                  auditRequired={true}
                />
              ) : null}
            </div>

            <div className='w-fit mr-5 text-heading-3 text-right bg-Cobalt-20'>
              <i
                className={CN('text-[20px] text-Gray-800', {
                  'ri-add-line': activeIndex !== index,
                  'ri-subtract-line': activeIndex === index,
                  'text-Cobalt-500': item?.title === 'Candidate Information',
                })}
              />
            </div>
          </div>

          <AnimatePresence>
            {activeIndex === index && (
              <motion.div
                initial={{ height: 0 }}
                animate={{
                  height: 'auto',
                }}
                exit={{ height: 0, opacity: -2 }}>
                <div className='py-4'>{item.content}</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  )
}

Accordion.defaultProps = {}

export default Accordion
