import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditSuperFundInterface {
  documentId: any
  superFundId: any
  candidateId: any
  superFundDetails: any
  attachments: any
}

export const useEditSuperFund = () => {
  const mutate = useMutation(
    ({
      documentId,
      superFundId,
      candidateId,
      superFundDetails,
      attachments,
    }: EditSuperFundInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('SuperProviderId', superFundDetails?.superProvider)
      bodyFormData.append('SuperNameId', superFundDetails?.superName)
      bodyFormData.append('MembershipNo', superFundDetails?.membershipNo)
      bodyFormData.append('Abn', superFundDetails?.superABN)
      bodyFormData.append('Usi', superFundDetails?.superUSI)
      bodyFormData.append('AuditRequired', superFundDetails?.auditCheck)
      bodyFormData.append('DocumentId', documentId)

      attachments?.map((item) => {
        bodyFormData.append('Attachment', item)
      })

      return putAPIwithFormData(
        `/facade/Candidate/${candidateId}/SuperFund/${superFundId}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditSuperFund
