import React, { FC, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface AgencyProfileLocationCardProps {
  locationDetails?: any
  onEditClick?: any
  [x: string]: any
}

export const AgencyProfileLocationCard: FC<AgencyProfileLocationCardProps> = ({
  className,
  locationDetails,
  onEditClick,
  ...restProps
}: AgencyProfileLocationCardProps) => {
  const [locationLink, setLocationLink] = useState('')

  const AgencyProfileLocationCardClasses = CN(
    `agency-profile-location-card flex w-full`,
    className,
  )

  /** set location link when check with latitude and longitude values availability */
  useEffect(() => {
    if (
      locationDetails?.address?.latitude &&
      locationDetails?.address?.longitude
    ) {
      setLocationLink(
        `https://maps.googleapis.com/maps/api/staticmap?center=${locationDetails?.address?.latitude},${locationDetails?.address?.longitude}&zoom=18&size=500x500&maptype=roadmap&markers=color:red%7Clabel:%7C${locationDetails?.address?.latitude},${locationDetails?.address?.longitude}&key=AIzaSyBlZPDoSassxMdBKJcEHOSzp-7gf3XJS2Q`,
      )
    } else {
      setLocationLink(
        `https://maps.googleapis.com/maps/api/staticmap?center=${locationDetails?.address?.lineOne},${locationDetails?.address?.city},${locationDetails?.address?.state},${locationDetails?.address?.country}&zoom=18&size=500x500&maptype=roadmap&markers=color:red%7Clabel:%7C${locationDetails?.address?.lineOne},${locationDetails?.address?.city},${locationDetails?.address?.state},${locationDetails?.address?.country}&key=AIzaSyBlZPDoSassxMdBKJcEHOSzp-7gf3XJS2Q`,
      )
    }
  }, [locationDetails])

  return (
    <div className={AgencyProfileLocationCardClasses} {...restProps}>
      <div className='card-map-viewer flex flex-grow w-1/3 h-auto bg-Gray-200 rounded-lg overflow-hidden max-h-[168px]'>
        <img
          src={locationLink}
          alt='agency-location'
          className='w-auto object-cover'
        />
      </div>

      <div className='flex w-2/3 flex-col ml-6 pt-[3px] justify-between'>
        <div>
          <div className='flex w-full items-center justify-between'>
            <span className='text-Gray-900 font-Bold w-full break-words line-clamp-1'>
              {locationDetails?.name}
            </span>

            <Button
              iconLeft={<i className='ri-edit-line text-[14px]' />}
              className='card-edit-button ml-7'
              size='sm'
              onClick={onEditClick && onEditClick}
              textTheme='blue'
              theme='white'>
              Edit
            </Button>
          </div>

          <span
            className='address-field flex w-full font-Regular text-Gray-800 pt-[7px] line-clamp-3'
            data-tip={`${locationDetails?.address?.lineOne} ${locationDetails?.address?.city} ${locationDetails?.address?.state} ${locationDetails?.address?.country}`}
            data-for='Address'>
            {locationDetails?.address?.lineOne &&
              `${locationDetails?.address?.lineOne},`}

            {locationDetails?.address?.city &&
              `${locationDetails?.address?.city},`}

            {locationDetails?.address?.state &&
              `${locationDetails?.address?.state},`}

            {locationDetails?.address?.postCode &&
              `${locationDetails?.address?.postCode},`}

            {locationDetails?.address?.country &&
              `${locationDetails?.address?.country}`}
          </span>
          <ReactTooltip
            place='top'
            id='Address'
            effect='solid'
            textColor='#FFFFFF'
            backgroundColor='#4B5563'
          />
        </div>
        <div>
          <div className='phone-number-field flex pt-1 text-Cobalt-500'>
            <i className='ri-phone-line text-[14px] mr-[9px]' />
            {locationDetails?.phoneNo ? (
              <a
                href={`tel:${locationDetails?.phoneNo}`}
                className='text-small font-Medium'>
                {locationDetails?.phoneNo}
              </a>
            ) : (
              `-`
            )}
          </div>

          <div className='email-field flex pt-1 text-Cobalt-500'>
            <i className='ri-mail-line text-[14px] mr-[9px]' />

            {locationDetails?.email ? (
              <>
                <a
                  href={`mailto:${locationDetails?.email}`}
                  className='text-small font-Medium line-clamp-1 break-words'
                  data-tip={locationDetails.email}
                  data-for='Email'>
                  {locationDetails?.email}
                </a>
                <ReactTooltip
                  place='right'
                  id='Email'
                  effect='solid'
                  textColor='#FFFFFF'
                  backgroundColor='#4B5563'
                />
              </>
            ) : (
              `-`
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

AgencyProfileLocationCard.defaultProps = {
  locationDetails: undefined,
  onEditClick: undefined,
}

export default AgencyProfileLocationCard
