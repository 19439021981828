import React, { FC, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, Modal, SimpleSelect } from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import CN from 'classnames'
import {
  CandidateProfileCardType,
  CandidateReferee,
  useAddRefCheck,
  useDeclineRefCheck,
  useGetDynamicForm,
  useGetQuestionnaireTemplateList,
} from 'framework/api/methods'

import { PageLoader, TextEditor } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

export interface PhoneRefCheckProps {
  referee: CandidateReferee
  candidate: CandidateProfileCardType
  isOpen: boolean
  hideModal: () => void
  getCandidateRefereesList: () => void
  [x: string]: any
}

export const PhoneRefCheck: FC<PhoneRefCheckProps> = ({
  className,
  candidate,
  referee,
  isOpen,
  hideModal,
  getCandidateRefereesList,
  ...restProps
}: PhoneRefCheckProps) => {
  const PhoneRefCheckClasses = CN(`phone-ref-check`, className)

  const [blankTemplateContent, setBlankTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({
    value: 'Blank',
    label: 'Blank',
  })
  const [isOpenRefCheckDecline, setIsOpenRefereeDecline] = useState(false)
  const [
    isShowNewTemplateNavigationConfirmationModal,
    setIsShowNewTemplateNavigationConfirmationModal,
  ] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const navigate = useNavigate()
  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)

  // Adding Phone ref check
  const { mutateAsync: addRefCheck, isLoading: isSavingRefCheck } =
    useAddRefCheck()

  // Fetching dynamic data for the template
  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicForm(
      selectedTemplate.value !== 'Blank' ? selectedTemplate.value : '',
    )

  // Fetching Questionnaire list
  const { data: questionnaireTemplates } = useGetQuestionnaireTemplateList()

  const { mutateAsync: declineRefCheck, isLoading: isLoadingDeclineRefCheck } =
    useDeclineRefCheck(candidate.id, referee.id)

  // Adding web ref check
  const addTemplatedFormCheck = (jsonData: string) => {
    addRefCheck({
      refereeId: referee.id,
      htmlResponse: blankTemplateContent,
      responseType: 'PHONE_CHECK',
      templateId:
        selectedTemplate.value !== 'Blank' ? selectedTemplate.value : null,
      jsonData,
    }).then(() => {
      hideModal()
      getCandidateRefereesList()
    })
  }

  return (
    <div className={PhoneRefCheckClasses} {...restProps}>
      {/* Decline ref check modal */}
      <Modal
        isActive={isOpenRefCheckDecline}
        onClickPrimaryBtn={() => {
          declineRefCheck().finally(() => {
            setIsOpenRefereeDecline(false)
            getCandidateRefereesList()
            hideModal()
          })
        }}
        onClickSecondaryBtn={() => {
          setIsOpenRefereeDecline(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: isLoadingDeclineRefCheck,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenRefereeDecline(false)
        }}
        modalProps={{
          style: { width: '466px', zIndex: 51 },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Confirm'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Referee declined the ref check?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            This means ref check status for this referee will be Failed. Are you
            sure to continue?
          </span>
        </div>
      </Modal>
      <Modal
        headerTitle='Phone Ref Check'
        customWidth='70%'
        customZIndex={10}
        isActive={isOpen}
        onHeaderCloseButtonClick={() => hideModal()}
        isFooterShow={false}>
        <div className='flex flex-col w-full p-8'>
          <div className='flex mb-6 pb-5 x w-full'>
            <div className='flex flex-col mr-4 gap-6 w-1/3'>
              {/* Referee details card */}
              <div className='max-h-[380px] overflow-y-auto styled-scroll'>
                <div className='w-[340px]'>
                  <div className='box-border flex p-4 gap-4 bg-Gray-50 border border-Gray-200 rounded-lg'>
                    <div className='relative flex-none w-min-[344px]'>
                      <Avatar
                        isHeader={false}
                        title={referee.firstName + ' ' + referee.lastName}
                        color={'four'}
                      />
                    </div>
                    <div className='w-fit'>
                      <div className='flex flex-column items-center'>
                        <span className='text-heading-5 text-Gray-800 mr-2 font-Medium'>
                          {referee.firstName + ' ' + referee.lastName}
                        </span>
                        <span className='flex items-center bg-Gray-200 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                          <span className='text-extra-small font-Medium text-Gray-800'>
                            Referee
                          </span>
                        </span>
                      </div>
                      <div className='mt-2 flex w-full'>
                        <div className='flex flex-col gap-2 flex-auto mr-8'>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-phone-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {referee.phone}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-mail-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {referee.email}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-suitcase-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {referee.jobTitle} at {referee.company}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidate details card */}
                <div className='w-[340px]'>
                  <div className='box-border flex p-4 gap-4 bg-Gray-50 border border-Gray-200 rounded-lg'>
                    <div className='relative flex-none w-min-[344px]'>
                      <Avatar
                        isHeader={false}
                        title={candidate.firstName + ' ' + candidate.lastName}
                        color={'four'}
                        imageURL={candidate.avatarUrl}
                      />
                    </div>
                    <div className='w-fit'>
                      <div className='flex flex-column items-center'>
                        <span className='text-heading-5 text-Gray-800 mr-2 font-Medium'>
                          {candidate.firstName + ' ' + candidate.lastName}
                        </span>
                        <span className='flex items-center bg-Gray-200 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                          <span className='text-extra-small font-Medium text-Gray-800'>
                            Candidate
                          </span>
                        </span>
                      </div>
                      <div className='mt-2 flex w-full'>
                        <div className='flex flex-col gap-2 flex-auto mr-8'>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-phone-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {candidate.phone}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-mail-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {candidate.email}
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-suitcase-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {referee.candidatePosition}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SimpleSelect
                label='Choose Template'
                options={[
                  { value: 'Blank', label: 'Blank' },
                  ...(questionnaireTemplates?.map((template) => ({
                    value: template.id,
                    label: template.name,
                  })) || []),
                ]}
                defaultValue={selectedTemplate}
                onChange={(selectedItem: any) => {
                  setSelectedTemplate(selectedItem)
                }}
              />
              <div className='flex items-center'>
                <hr className='flex-auto text-Gray-300' />
                <span className='text-small font-Medium text-Gray-400 mx-2'>
                  OR
                </span>
                <hr className='flex-auto text-Gray-300' />
              </div>
              <Button
                textTheme={'blue'}
                theme='white'
                onClick={() => {
                  setIsShowNewTemplateNavigationConfirmationModal(true)
                }}>
                Create New Template
              </Button>
            </div>
            <div className='w-2/3 h-[600px] overflow-y-auto styled-scroll'>
              {selectedTemplate.value === 'Blank' && (
                <div className='h-[500px]'>
                  <TextEditor
                    onContentChange={(content) => {
                      setBlankTemplateContent(content)
                    }}
                  />
                </div>
              )}
              {selectedTemplate.value !== 'Blank' &&
                !isLoadingDynamicForm &&
                dynamicForm && (
                  <div className='flex flex-col flex-auto justify-start p-5 min-h-[30vh]] overflow-y-auto styled-scroll'>
                    <FormBuilder
                      ref={dynamicFormRef}
                      uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
                      schema={JSON.parse(dynamicForm.jsonSchema)}
                      onSubmit={(data) => {
                        addTemplatedFormCheck(JSON.stringify(data?.formData))
                      }}
                      onFocus={() => {
                        setIsFormTouched(true)
                      }}
                    />
                  </div>
                )}
              {isLoadingDynamicForm && <PageLoader size='xxs' />}
            </div>
          </div>

          {/* Bottom Custom Footer */}
          <div className='flex fixed bottom-0 py-3 left-0 w-full justify-between bg-Cobalt-50'>
            <Button
              textTheme='terracotta'
              className={'ml-6'}
              theme='white'
              onClick={() => {
                setIsOpenRefereeDecline(true)
              }}>
              Decline Ref Check
            </Button>
            <div className='flex mr-6'>
              <Button
                theme={'cobalt'}
                className='mr-2'
                isLoading={isSavingRefCheck}
                isDisabled={
                  selectedTemplate.value === 'Blank'
                    ? blankTemplateContent == ''
                    : !isFormTouched
                }
                onClick={() => {
                  if (selectedTemplate.value === 'Blank') {
                    addRefCheck({
                      refereeId: referee.id,
                      htmlResponse: blankTemplateContent,
                      responseType: 'PHONE_CHECK',
                      templateId: null,
                      jsonData: '',
                    }).then(() => {
                      hideModal()
                      getCandidateRefereesList()
                    })
                  } else {
                    if (dynamicFormRef.current?.submit) {
                      dynamicFormRef?.current?.submit()
                    }
                  }
                }}>
                Save Ref Check
              </Button>
              <Button
                textTheme='blue'
                theme='white'
                onClick={() => {
                  hideModal()
                }}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isActive={isShowNewTemplateNavigationConfirmationModal}
        onClickPrimaryBtn={() => {
          navigate('/questionnaires/build-new-questionnaire')
        }}
        onClickSecondaryBtn={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Warning
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Please note you will be directed to the create questionnaire page
            and the content in this page will not be saved.
          </span>
        </div>
      </Modal>
    </div>
  )
}

PhoneRefCheck.defaultProps = {}

export default PhoneRefCheck
