import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useDeleteMedicalIncident,
  useDeleteVaccinationOrMedicalById,
  useGetCandidateMedicalConditionListById,
  useGetCandidateMedicalIncidentListById,
  useGetCandidateMedicalIncidentLocationList,
  useGetCandidateVaccinationListById,
  useGetDocumentSignedUrlsById,
  useGetIncidentCount,
  useGetIncidentSignedUrls,
  useGetRestrictionsCount,
  useGetVaccinationCount,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import { TabTileCard } from 'components/atoms'
import {
  AddNewMedicalCondition,
  AddNewMedicalIncident,
  AddNewVaccination,
  EditMedicalCondition,
  EditMedicalIncident,
  EditVaccination,
} from 'components/Drawers/Candidate'
import {
  CandidateDocumentsViewModal,
  CandidateMedicalConditionCard,
  CandidateMedicalIncidentCard,
  CandidateVaccinationCard,
} from 'components/molecules'

export interface MedicalHistoryTabViewProps {
  [x: string]: any
}

export const MedicalHistoryTabView: FC<MedicalHistoryTabViewProps> = ({
  candidateId,
  className,
  notify,
  getCandidateAuditDocumentIds,
  ...restProps
}: MedicalHistoryTabViewProps) => {
  const dispatch = useDispatch()

  const { isAuditModal } = useSelector((state: any) => state.audit)
  const { documentCategoriesList } = useSelector((state: any) => state.document)

  /** delete document states */
  const [isShowDocumentDeleteModal, setIsShowDocumentDeleteModal] =
    useState<any>({
      enable: false,
      docType: '',
    })

  const [signedUrlList, setSignedUrlList] = useState<any>([])
  const [isShowDocumentViewModal, setIsShowDocumentViewModal] = useState({
    enable: false,
    docType: '',
  })

  /** vaccination section states */
  const [
    selectedVaccinationDocumentDetails,
    setSelectedVaccinationDocumentDetails,
  ] = useState<any>()
  const [vaccinationList, setVaccinationList] = useState<any>()
  const [isShowVaccinationAddModal, setIsShowVaccinationAddModal] =
    useState(false)
  const [isShowVaccinationEditModal, setIsShowVaccinationEditModal] =
    useState(false)

  /** medical condition section states */
  const [
    selectedMedicalConditionDocumentDetails,
    setSelectedMedicalConditionDocumentDetails,
  ] = useState<any>()
  const [medicalConditionList, setMedicalConditionList] = useState<any>()
  const [isShowMedicalConditionAddModal, setIsShowMedicalConditionAddModal] =
    useState(false)
  const [isShowMedicalConditionEditModal, setIsShowMedicalConditionEditModal] =
    useState(false)

  /** add new incident drawer state */
  const [showAddNewMedicalIncidentDrawer, setShowAddNewMedicalIncidentDrawer] =
    useState(false)

  /** edit incident drawer state */
  const [showEditMedicalIncidentDrawer, setShowEditMedicalIncidentDrawer] =
    useState(false)

  /** candidate medical incident list states */
  const [candidateMedicalIncidentList, setCandidateMedicalIncidentList] =
    useState<any>([])

  /** delete document states */
  const [isShowIncidentDeleteModal, setIsShowIncidentDeleteModal] =
    useState(false)

  /** Medical Incident Location List States */
  const [medicalIncidentLocationList, setMedicalIncidentLocationList] =
    useState()

  /** handle more buttons */
  const { clickValue } = useSelector((state: any) => state.layout)
  const [showVaccineMoreButtonOption, setShowVaccineMoreButtonOption] =
    useState<any>({
      index: -1,
      isEnable: false,
    })

  const [showMedicalMoreButtonOption, setShowMedicalMoreButtonOption] =
    useState<any>({
      index: -1,
      isEnable: false,
    })

  const [showIncidentMoreButtonOption, setShowIncidentMoreButtonOption] =
    useState<any>({
      index: -1,
      isEnable: false,
    })

  useEffect(() => {
    setShowVaccineMoreButtonOption({
      index: -1,
      isEnable: false,
    })
    setShowMedicalMoreButtonOption({
      index: -1,
      isEnable: false,
    })
    setShowIncidentMoreButtonOption({
      index: -1,
      isEnable: false,
    })
  }, [clickValue])

  /** medical incident details states */
  const [selectedMedicalIncidentDetails, setSelectedMedicalIncidentDetails] =
    useState<any>()

  const [medicalIncidentCount, setMedicalIncidentCount] = useState<any>()
  const [restrictionsCount, setRestrictionsCount] = useState<any>()
  const [vaccinationCount, setVaccinationCount] = useState<any>()

  const [urlIncidentId, setUrlIncidentId] = useState<any>()

  /** API Call for get medical incident location list using react-query */
  const { mutate: getMedicalIncidentLocationListMutate } =
    useGetCandidateMedicalIncidentLocationList()

  /** Process get medical incident location list */
  async function getMedicalIncidentLocationList() {
    getMedicalIncidentLocationListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempLocationList = successData?.location?.map((item: any) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          setMedicalIncidentLocationList(tempLocationList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting medical incident location list.',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate medical incident list by id api */
  const {
    mutate: getCandidateMedicalIncidentsByIdMutate,
    isLoading: getCandidateMedicalIncidentsByIdIsLoading,
  } = useGetCandidateMedicalIncidentListById()

  /** Process the get candidate medical incidents */
  async function getCandidateMedicalIncidentsById() {
    getCandidateMedicalIncidentsByIdMutate(
      {
        Id: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateMedicalIncidentList(successData?.medicalIncidentDetails)
          getCandidateAuditDocumentIds()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate Medical Incidents',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for medical incident delete */
  const {
    mutate: deleteMedicalIncidentMutate,
    isLoading: deleteMedicalIncidentIsLoading,
  } = useDeleteMedicalIncident()

  /** Process for delete medical incidents */
  async function deleteMedicalIncident() {
    deleteMedicalIncidentMutate(
      {
        candidateId: candidateId,
        medicalIncidentId: selectedMedicalIncidentDetails?.medicalIncidentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowIncidentDeleteModal(false)

          getCandidateMedicalIncidentsById()

          getRestrictionsCountById()
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting medical incident!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate medical incident count by id api */
  const {
    mutate: getMedicalIncidentCountByIdMutate,
    isLoading: getMedicalIncidentCountByIdIsLoading,
  } = useGetIncidentCount()

  /** Process the get incident count */
  async function getMedicalIncidentCountById() {
    getMedicalIncidentCountByIdMutate(
      {
        candidateId: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setMedicalIncidentCount(successData)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting medical incident count',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate restrictions count by id api */
  const {
    mutate: getRestrictionsCountByIdMutate,
    isLoading: getRestrictionsCountByIdIsLoading,
  } = useGetRestrictionsCount()

  /** Process the get restrictions count */
  async function getRestrictionsCountById() {
    getRestrictionsCountByIdMutate(
      {
        candidateId: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRestrictionsCount(successData)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting restrictions count',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate vaccination count by id api */
  const {
    mutate: getVaccinationCountByIdMutate,
    isLoading: getVaccinationCountByIdIsLoading,
  } = useGetVaccinationCount()

  /** Process the get vaccination count */
  async function getVaccinationCountById() {
    getVaccinationCountByIdMutate(
      {
        candidateId: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setVaccinationCount(successData)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting vaccination count',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle get category Id for document type list */
  const handleDocumentTypeList = (type) => {
    const certificationAndLicenseObj = documentCategoriesList?.find((item) => {
      return item.label === type
    })

    dispatch(setDocumentCategory(certificationAndLicenseObj))
  }

  useEffect(() => {
    if (!isAuditModal?.isOpen) {
      candidateId && getMedicalIncidentCountById()
      candidateId && getRestrictionsCountById()
      candidateId && getVaccinationCountById()
    }
  }, [candidateId, isAuditModal?.isOpen])

  useEffect(() => {
    if (!isAuditModal?.isOpen) {
      getVaccinationList()
      getMedicalConditionList()
      getMedicalIncidentLocationList()
      getCandidateMedicalIncidentsById()
    }
  }, [candidateId, isAuditModal?.isOpen])

  /** vaccination List on success */
  const onGetVaccinationListSuccess = ({ data }: any) => {
    setVaccinationList(data?.vaccinationModel || {})
    getCandidateAuditDocumentIds()
  }

  /** vaccination List on error */
  const onGetVaccinationListError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting vaccination list',

      status: 'Error',
    })
  }

  /** API Handling request for Get All vaccination List */
  const {
    refetch: getVaccinationList,
    isLoading: getVaccinationListIsLoading,
    isFetching: getVaccinationListIsFetching,
  } = useGetCandidateVaccinationListById(
    {
      Id: candidateId,
    },
    onGetVaccinationListSuccess,
    onGetVaccinationListError,
  )

  /** on get incident signed urls success */
  const onGetIncidentSignedUrlsSuccess = (data: any) => {
    setSignedUrlList(data?.attachmentTypeSignedUrls || [])
  }

  /** get incident urls on error */
  const onGetIncidentSignedUrlsError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting incident signed urls',

      status: 'Error',
    })
  }

  /** API Handling request for Get Incident Urls */
  const { refetch: getIncidentSignedUrls } = useGetIncidentSignedUrls(
    {
      candidateId: candidateId,
      incidentId: urlIncidentId?.medicalIncidentId,
    },
    onGetIncidentSignedUrlsSuccess,
    onGetIncidentSignedUrlsError,
  )

  useEffect(() => {
    urlIncidentId?.medicalIncidentId && getIncidentSignedUrls()
  }, [urlIncidentId])

  /** API Handling request for vaccination document delete */
  const {
    mutate: deleteVaccinationOrMedicalByIdMutate,
    isLoading: deleteVaccinationOrMedicalByIdIsLoading,
  } = useDeleteVaccinationOrMedicalById()

  async function deleteVaccinationOrMedicalById() {
    let docTypeId = ''

    if (isShowDocumentDeleteModal?.docType === 'Vaccinations') {
      docTypeId = selectedVaccinationDocumentDetails?.id
    } else if (isShowDocumentDeleteModal?.docType === 'Medical') {
      docTypeId = selectedMedicalConditionDocumentDetails?.medicalConditionId
    }

    deleteVaccinationOrMedicalByIdMutate(
      {
        candidateId,
        Id: docTypeId,
        docType: isShowDocumentDeleteModal?.docType,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowDocumentDeleteModal({
            ...isShowDocumentDeleteModal,
            enable: false,
            docType: '',
          })

          isShowDocumentDeleteModal?.docType === 'Vaccinations' &&
            getVaccinationList()
          getVaccinationCountById()

          isShowDocumentDeleteModal?.docType === 'Medical' &&
            getMedicalConditionList()

          setSelectedVaccinationDocumentDetails(null)
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting document!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** medical condition List on success */
  const onGetMedicalConditionListSuccess = ({ data }: any) => {
    setMedicalConditionList(data?.medicalConditionDetails || {})
    getCandidateAuditDocumentIds()
  }

  /** medical condition List on error */
  const onGetMedicalConditionListError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting medical condition list.!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All medical condition List */
  const {
    refetch: getMedicalConditionList,
    isLoading: getMedicalConditionListIsLoading,
    isFetching: getMedicalConditionListIsFetching,
  } = useGetCandidateMedicalConditionListById(
    {
      Id: candidateId,
    },
    onGetMedicalConditionListSuccess,
    onGetMedicalConditionListError,
  )

  /** API Handling request for Get selected document signed url List */
  const {
    mutate: getDocumentSignedUrlsByIdMutate,
    isLoading: getDocumentSignedUrlsByIdIsLoading,
  } = useGetDocumentSignedUrlsById()

  async function getDocumentSignedUrlsById(documentId: any) {
    getDocumentSignedUrlsByIdMutate(
      {
        documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData && setSignedUrlList(successData?.attachmentTypeSignedUrls)
        },
      },
    )
  }

  const MedicalHistoryTabViewClasses = CN(`flex flex-col`, className)

  return (
    <div className={MedicalHistoryTabViewClasses} {...restProps}>
      <div className='flex gap-5'>
        <TabTileCard
          className='w-1/3 bg-gradient-to-r from-[#4191DC] to-[#1195C5]'
          title={'Medical Incidents'}
          isLoading={getMedicalIncidentCountByIdIsLoading}
          subTitle={`${medicalIncidentCount?.workCoverCount} ${
            medicalIncidentCount?.workCoverCount > 1
              ? ' work cover claims submitted'
              : ' work cover claim submitted'
          }`}
          count={medicalIncidentCount?.incidentCount}>
          <i className='ri-shield-cross-line text-[105px] text-Cobalt-600 opacity-20'></i>
        </TabTileCard>

        <TabTileCard
          className='w-1/3 bg-gradient-to-r from-[#E66962] to-[#DD7F0B]'
          title={'Work Restrictions'}
          isLoading={getRestrictionsCountByIdIsLoading}
          subTitle={
            restrictionsCount?.restriction?.length === 0 ? (
              'No Restrictions Here'
            ) : restrictionsCount?.restriction?.length === 1 ? (
              restrictionsCount?.restriction
            ) : (
              <>
                <span
                  className='font-Regular text-small text-[#FFFFFF] pt-1 z-[1]'
                  data-tip='restrictions'
                  data-for='restrictions'>
                  Restrictions
                </span>
                <ReactTooltip
                  id='restrictions'
                  place='bottom'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'>
                  <ul className='list-disc'>
                    {restrictionsCount?.restriction?.map(
                      (item: any, idx: any) => (
                        <li className='' key={idx}>
                          {item}
                        </li>
                      ),
                    )}
                  </ul>
                </ReactTooltip>
              </>
            )
          }
          count={restrictionsCount?.restrictionCount}>
          <i className='ri-close-circle-line text-[105px] text-Yellow-600 opacity-20'></i>
        </TabTileCard>

        <TabTileCard
          className='w-1/3 bg-gradient-to-r from-[#4B5563] to-[#374151]'
          title={'Vaccinations'}
          isLoading={getVaccinationCountByIdIsLoading}
          subTitle={
            vaccinationCount?.vaccinationName?.length === 1 ? (
              vaccinationCount?.vaccinationName
            ) : vaccinationCount?.vaccinationName?.length <= 3 ? (
              `${vaccinationCount?.vaccinationName}${' '}`
            ) : (
              <>
                <span
                  className='font-Regular text-small text-[#FFFFFF] pt-1 z-[1]'
                  data-tip='vaccinations'
                  data-for='vaccinations'>
                  Vaccinations
                </span>

                <ReactTooltip
                  id='vaccinations'
                  place='bottom'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'>
                  <ul className='list-disc'>
                    {vaccinationCount?.vaccinationName?.map(
                      (item: any, idx: any) => (
                        <li className='' key={idx}>
                          {item}
                        </li>
                      ),
                    )}
                  </ul>
                </ReactTooltip>
              </>
            )
          }
          count={vaccinationCount?.vaccinationCount}>
          <i className='ri-syringe-line text-[105px] text-Gray-500 opacity-20'></i>
        </TabTileCard>
      </div>

      <div className='flex gap-5'>
        <div className='flex flex-col pt-5 gap-5 w-[calc(33.33%-15px)]'>
          {/** Vaccination Card */}
          <CandidateVaccinationCard
            vaccinationList={vaccinationList}
            setSelectedDocumentDetails={setSelectedVaccinationDocumentDetails}
            getVaccinationListIsLoading={
              getVaccinationListIsLoading || getVaccinationListIsFetching
            }
            showMoreButtonOption={showVaccineMoreButtonOption}
            setShowMoreButtonOption={setShowVaccineMoreButtonOption}
            setShowIncidentMoreButtonOption={setShowIncidentMoreButtonOption}
            setShowMedicalMoreButtonOption={setShowMedicalMoreButtonOption}
            getDocumentSignedUrlsById={getDocumentSignedUrlsById}
            onAddNewClick={() => {
              setIsShowVaccinationAddModal(true)
              handleDocumentTypeList('Vaccinations')
            }}
            onEditClick={() => {
              setIsShowVaccinationEditModal(true)
              handleDocumentTypeList('Vaccinations')
            }}
            onDeleteClick={() => {
              setIsShowDocumentDeleteModal({
                ...isShowDocumentDeleteModal,
                enable: true,
                docType: 'Vaccinations',
              })
            }}
            onViewClick={() => {
              setIsShowDocumentViewModal({
                ...isShowDocumentViewModal,
                enable: true,
                docType: 'Vaccinations',
              })
            }}
          />

          {/** Prescriptions & Medical Conditions Card*/}
          <CandidateMedicalConditionCard
            medicalConditionList={medicalConditionList}
            getMedicalConditionListIsLoading={
              getMedicalConditionListIsLoading ||
              getMedicalConditionListIsFetching
            }
            showMoreButtonOption={showMedicalMoreButtonOption}
            setShowMoreButtonOption={setShowMedicalMoreButtonOption}
            setShowVaccineMoreButtonOption={setShowVaccineMoreButtonOption}
            setShowIncidentMoreButtonOption={setShowIncidentMoreButtonOption}
            setSelectedDocumentDetails={
              setSelectedMedicalConditionDocumentDetails
            }
            getDocumentSignedUrlsById={getDocumentSignedUrlsById}
            onAddNewClick={() => {
              setIsShowMedicalConditionAddModal(true)
              handleDocumentTypeList('Prescriptions/Medical Conditions')
            }}
            onEditClick={() => {
              setIsShowMedicalConditionEditModal(true)
              handleDocumentTypeList('Prescriptions/Medical Conditions')
            }}
            onDeleteClick={() => {
              setIsShowDocumentDeleteModal({
                ...isShowDocumentDeleteModal,
                enable: true,
                docType: 'Medical',
              })
            }}
            onViewClick={() => {
              setIsShowDocumentViewModal({
                ...isShowDocumentViewModal,
                enable: true,
                docType: 'M-Condition',
              })
            }}
          />
        </div>

        <div className='flex flex-col pt-5 gap-5 w-[calc(66.66%-6px)]'>
          {/** Medical Incidents card */}
          <CandidateMedicalIncidentCard
            candidateMedicalIncidentList={candidateMedicalIncidentList}
            showIncidentMoreButtonOption={showIncidentMoreButtonOption}
            setShowIncidentMoreButtonOption={setShowIncidentMoreButtonOption}
            setShowMedicalMoreButtonOption={setShowMedicalMoreButtonOption}
            setShowVaccineMoreButtonOption={setShowVaccineMoreButtonOption}
            getCandidateMedicalIncidentsByIdIsLoading={
              getCandidateMedicalIncidentsByIdIsLoading
            }
            onAddNewClick={() => {
              setShowAddNewMedicalIncidentDrawer(true)
              handleDocumentTypeList('Medical Incidents')
            }}
            onEditClick={() => {
              setShowEditMedicalIncidentDrawer(true)
              handleDocumentTypeList('Medical Incidents')
            }}
            getDocumentSignedUrlsById={setUrlIncidentId}
            onDeleteClick={() => {
              setIsShowIncidentDeleteModal(true)
            }}
            setSelectedMedicalIncidentDetails={
              setSelectedMedicalIncidentDetails
            }
            selectedMedicalIncidentDetails={selectedMedicalIncidentDetails}
            onViewClick={() => {
              setIsShowDocumentViewModal({
                ...isShowDocumentViewModal,
                enable: true,
                docType: 'Incident',
              })
            }}
          />
        </div>
      </div>

      <AddNewMedicalIncident
        isActive={showAddNewMedicalIncidentDrawer}
        headerTitle='Add Medical Incident'
        headerSubtitle='Add medical incident details here'
        primaryButtonTitle='Save Medical Incident'
        setShowAddNewMedicalIncidentDrawer={setShowAddNewMedicalIncidentDrawer}
        headerCloseButton={() => {
          setShowAddNewMedicalIncidentDrawer(false)
        }}
        footerCancelButton={() => {
          setShowAddNewMedicalIncidentDrawer(false)
        }}
        medicalIncidentLocationList={medicalIncidentLocationList}
        notify={notify}
        fetchData={getCandidateMedicalIncidentsById}
        candidateId={candidateId}
        getRestrictionsCountById={getRestrictionsCountById}
        getMedicalIncidentCountById={getMedicalIncidentCountById}
      />

      <EditMedicalIncident
        isActive={showEditMedicalIncidentDrawer}
        headerTitle='Edit Medical Incident'
        headerSubtitle='Edit medical incident details here'
        primaryButtonTitle='Edit Medical Incident'
        showEditMedicalIncidentDrawer={showEditMedicalIncidentDrawer}
        headerCloseButton={() => {
          setShowEditMedicalIncidentDrawer(false)
        }}
        footerCancelButton={() => {
          setShowEditMedicalIncidentDrawer(false)
        }}
        medicalIncidentLocationList={medicalIncidentLocationList}
        notify={notify}
        fetchData={getCandidateMedicalIncidentsById}
        selectedMedicalIncidentDetails={selectedMedicalIncidentDetails}
        setSelectedMedicalIncidentDetails={setSelectedMedicalIncidentDetails}
        candidateId={candidateId}
        setShowEditMedicalIncidentDrawer={setShowEditMedicalIncidentDrawer}
        getRestrictionsCountById={getRestrictionsCountById}
        getMedicalIncidentCountById={getMedicalIncidentCountById}
      />

      {/** incident delete confirmation Modal */}
      <Modal
        isActive={isShowIncidentDeleteModal}
        onClickPrimaryBtn={() => {
          deleteMedicalIncident()
        }}
        onClickSecondaryBtn={() => {
          setIsShowIncidentDeleteModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteMedicalIncidentIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowIncidentDeleteModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Document?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>

      {/* document view modal  */}
      <CandidateDocumentsViewModal
        isActive={isShowDocumentViewModal?.enable}
        docList={signedUrlList}
        headerTitle={
          isShowDocumentViewModal?.docType === 'Vaccinations'
            ? selectedVaccinationDocumentDetails?.name
            : isShowDocumentViewModal?.docType === 'M-Condition'
            ? selectedMedicalConditionDocumentDetails?.title
            : selectedMedicalIncidentDetails?.title
        }
        onHeaderCloseButtonClick={() => {
          setIsShowDocumentViewModal({
            ...isShowDocumentViewModal,
            enable: false,
            docType: '',
          })
          setSignedUrlList([])
        }}
        onClickCloseBtn={() => {
          setIsShowDocumentViewModal({
            ...isShowDocumentViewModal,
            enable: false,
            docType: '',
          })
          setSignedUrlList([])
        }}
        getDocumentSignedUrlsByIdIsLoading={getDocumentSignedUrlsByIdIsLoading}
      />

      {/* add new vaccination drawer */}
      <AddNewVaccination
        isActive={isShowVaccinationAddModal}
        notify={notify}
        fetchData={getVaccinationList}
        setIsShowVaccinationAddModal={setIsShowVaccinationAddModal}
        candidateId={candidateId}
        getVaccinationCountById={getVaccinationCountById}
      />

      {/* add new medical condition drawer  */}
      <AddNewMedicalCondition
        isActive={isShowMedicalConditionAddModal}
        notify={notify}
        fetchData={getMedicalConditionList}
        setIsShowMedicalConditionAddModal={setIsShowMedicalConditionAddModal}
        candidateId={candidateId}
      />

      {/* edit vaccination  */}
      <EditVaccination
        isActive={isShowVaccinationEditModal}
        notify={notify}
        setIsShowVaccinationEditModal={setIsShowVaccinationEditModal}
        selectedDocumentDetails={selectedVaccinationDocumentDetails}
        setSelectedDocumentDetails={setSelectedVaccinationDocumentDetails}
        fetchDocumentList={getVaccinationList}
        candidateId={candidateId}
        getVaccinationCountById={getVaccinationCountById}
      />

      {/* edit medical condition  */}
      <EditMedicalCondition
        isActive={isShowMedicalConditionEditModal}
        notify={notify}
        setIsShowMedicalConditionEditModal={setIsShowMedicalConditionEditModal}
        selectedDocumentDetails={selectedMedicalConditionDocumentDetails}
        setSelectedDocumentDetails={setSelectedMedicalConditionDocumentDetails}
        fetchDocumentList={getMedicalConditionList}
        candidateId={candidateId}
      />

      {/** document delete confirmation Modal */}
      <Modal
        isActive={isShowDocumentDeleteModal?.enable}
        onClickPrimaryBtn={() => {
          deleteVaccinationOrMedicalById()
        }}
        onClickSecondaryBtn={() => {
          setIsShowDocumentDeleteModal({
            ...isShowDocumentDeleteModal,
            enable: false,
            docType: '',
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteVaccinationOrMedicalByIdIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowDocumentDeleteModal({
            ...isShowDocumentDeleteModal,
            enable: false,
            docType: '',
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Document?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

MedicalHistoryTabView.defaultProps = {}

export default MedicalHistoryTabView
