import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  Checkbox,
  Modal,
  SimpleSelect,
  Toast,
  Toggle,
} from '@labourhub/labour-hub-ds'
import popoverCareerForm from 'assets/images/popover_career_form.svg'
import popoverStaffForm from 'assets/images/popover_staff_form.svg'
import popoverContent from 'assets/images/popoverContent.svg'
import CN from 'classnames'
import {
  useCreateWebRefEmail,
  useGetQuestionnaireFilterByCategory,
  useGetQuestionnaireTemplateList,
} from 'framework/api/methods'

import { PageLoader, TextEditor } from 'components/atoms'

import { WebRefCheckPreviewModal } from '../WebRefCheckPreviewModal'

export interface CandidateReferee {
  candidateCompany: string
  candidateId: string
  candidatePosition: string
  candidateRelation: string
  company: string
  createdOn: string
  email: string
  endDate: string
  firstName: string
  id: string
  jobTitle: string
  lastName: string
  phone: string
  responseDate: string | null
  sendDate: string | null
  startDate: 'string'
  state: 'string' | null
  status: null | 'Failed' | 'Incomplete' | 'Passed'
  templateId: string | null
  tenantId: string
  updatedOn: string
  formType: 'Web Ref Check' | 'Phone Ref Check'
}

export interface WebRefCheckProps {
  referee?: CandidateReferee[]
  candidateId?: string
  isOpen?: boolean

  [x: string]: any
}

export const WebRefCheck: FC<WebRefCheckProps> = ({
  className,
  isOpen,
  setIsWebRefCheckModal,
  candidateId,
  selectedRefereeId,
  selectAll,
  refereeList,
  getCandidateRefereesList,
  ...restProps
}: WebRefCheckProps) => {
  const navigate = useNavigate()

  const [referees, setReferees] = useState<Array<any>>(refereeList)
  const [selectedId, setSelectedId] = useState<any>(selectedRefereeId)
  const [blankTemplateContent, setBlankTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState<any>()

  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)
  const [isCareerInterest, setIsCareerInterest] = useState(false)
  const [isStaffRequest, setIsStaffRequest] = useState(false)

  const [isPrimaryBtnDisabled, setIsPrimaryBtnDisabled] = useState(false)
  const [
    isShowNewTemplateNavigationConfirmationModal,
    setIsShowNewTemplateNavigationConfirmationModal,
  ] = useState(false)

  /** set Referee List */
  useEffect(() => {
    setReferees(refereeList)
  }, [refereeList])

  /** check box states */
  const [checkedState, setCheckedState] = useState(
    selectAll
      ? new Array(refereeList?.length).fill(true)
      : new Array(refereeList?.length).fill(undefined),
  )

  /** handle selected referee ID*/
  useEffect(() => {
    !selectAll && setSelectedId(selectedRefereeId)

    selectAll && setCheckedState(new Array(referees?.length).fill(true))

    setIsCareerInterest(false)
    setIsStaffRequest(false)
  }, [isOpen, selectAll, selectedRefereeId])

  /** handle select all or already selected referee */
  useEffect(() => {
    if (selectAll) {
      // setCheckedState(new Array(referees?.length).fill(true))

      const filteredRefIds = referees.reduce((filteredRefIds, item) => {
        if (item.state === 'NOT_SENT') {
          filteredRefIds.push(true)
        } else {
          filteredRefIds.push(false)
        }
        return filteredRefIds
      }, [])

      setCheckedState(filteredRefIds)
    }

    if (!selectAll && selectedId) {
      const updatedCheckedState = referees.map((item) =>
        selectedId === item.id ? true : false,
      )
      setCheckedState(updatedCheckedState)
    }

    setSelectedId('')
  }, [selectedId, selectAll])

  /** handle on change list update */
  useEffect(() => {
    if (selectedId === '') {
      const updatedArray = referees?.map((item, idx) => {
        return { ...item, checked: checkedState[idx] }
      })

      setReferees(updatedArray)
    }
  }, [checkedState])

  // Fetching Questionnaire list
  const {
    data: questionnaireTemplates,
    isLoading: getQuestionnairesListIsLoading,
  } = useGetQuestionnaireFilterByCategory()

  /** APi call for the create web ref email */
  const {
    mutate: createWebRefEmailMutate,
    isLoading: createWebRefEmailIsLoading,
  } = useCreateWebRefEmail()

  /** Process the add new candidate */
  const createWebRefEmail = (refereeIdsList: string[]) => {
    createWebRefEmailMutate(
      {
        candidateId,
        customMessage: blankTemplateContent,
        refereeIds: refereeIdsList,
        templateId: selectedTemplate?.value,
        isStaffRequest,
        isCareerInterest,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })

          setSelectedTemplate('')
          setBlankTemplateContent('')
          getCandidateRefereesList()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }
  /** handle email ref check form btn */
  const handleEmailRefCheckFormBtn = () => {
    const selectedRefList = handleFilterSelectedRefIds()
    createWebRefEmail(selectedRefList)
  }

  /** handle selected referee list only */
  const handleFilterSelectedRefIds = () => {
    const refIds = referees.reduce((refIds, item) => {
      if (item.checked) {
        refIds.push(item.id)
      }
      return refIds
    }, [])

    return refIds
  }

  const WebRefCheckClasses = CN(`web-ref-check`, className)

  /** handle primary button disable */
  useEffect(() => {
    const selectedRefList = handleFilterSelectedRefIds()

    selectedRefList?.length > 0 && selectedTemplate
      ? setIsPrimaryBtnDisabled(false)
      : setIsPrimaryBtnDisabled(true)
  }, [referees, checkedState, selectedTemplate])

  return (
    <div className={WebRefCheckClasses} {...restProps}>
      {getQuestionnairesListIsLoading && <PageLoader size='xxs' />}

      <Modal
        headerTitle='Send Ref Check'
        customWidth='65%'
        isActive={isOpen}
        primaryButtonTheme={'cobalt'}
        primaryButtonTitle='Email Ref Check Form'
        onClickSecondaryBtn={() => {
          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })
          setSelectedTemplate('')
        }}
        primaryButtonProps={{
          isDisabled: isPrimaryBtnDisabled,
          isLoading: createWebRefEmailIsLoading,
        }}
        onClickPrimaryBtn={() => {
          handleEmailRefCheckFormBtn()
        }}
        onHeaderCloseButtonClick={() => {
          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })
          setSelectedTemplate('')
        }}
        secondaryButtonTitle='Close'>
        <div className='flex w-full px-8 pt-7 pb-10 gap-x-5 min-h-[630px]'>
          <div className='left-container flex flex-col w-1/3'>
            <span className='text-Gray-800 font-SemiBold pb-4'>Recipients</span>

            <div className='recipients-card-container flex flex-col gap-y-2 max-h-[330px] overflow-y-auto styled-scroll'>
              {referees?.map((item, idx) => (
                <div
                  className={CN(
                    'recipients-card rounded-lg py-2 px-3 relative',
                    {
                      'bg-white border border-Gray-100': !item.checked,
                      'bg-Cobalt-50 border border-Cobalt-100': item.checked,
                    },
                  )}
                  key={idx}>
                  <Checkbox
                    key={idx}
                    id={`custom-checkbox-${idx}`}
                    checked={item?.checked || false}
                    isDisabled={item.state !== 'NOT_SENT'}
                    onChange={() => {
                      const updatedCheckedState = checkedState.map(
                        (item, index) => (index === idx ? !item : item),
                      )

                      setCheckedState(updatedCheckedState)
                    }}
                    labelText={`${item?.firstName} ${item?.lastName}`}
                    labelTextClassName='font-Regular line-clamp-1'
                    checkboxClassName='ml-0'
                  />

                  <div className='flex flex-col ml-7 text-extra-small font-Regular text-Gray-500'>
                    <span className='leading-[18px] line-clamp-1 break-all'>
                      {item?.email}
                    </span>

                    <span className='line-clamp-1 break-all'>
                      {item?.company}
                    </span>
                  </div>

                  {item.state !== 'NOT_SENT' && (
                    <div className='bg-Gray-100 w-full h-full flex z-20 absolute top-0 right-0 left-0 opacity-30'></div>
                  )}
                </div>
              ))}
            </div>

            <span className='text-Gray-800 font-SemiBold mt-8'>
              Questionnaire
            </span>

            <SimpleSelect
              className='template-selector mt-4'
              label='Choose Template'
              isRequired
              options={[
                ...(questionnaireTemplates?.map((template) => ({
                  value: template.id,
                  label: template.name,
                })) || []),
              ]}
              value={
                selectedTemplate
                  ? {
                      label: selectedTemplate?.label,
                      value: selectedTemplate?.value,
                    }
                  : null
              }
              onChange={(selectedItem: any) => {
                setSelectedTemplate({
                  label: selectedItem?.label,
                  value: selectedItem?.value,
                })
              }}
            />

            <div className='flex items-center py-2'>
              <hr className='flex-auto text-Gray-300' />

              <span className='text-small font-Medium text-Gray-400 mx-2'>
                OR
              </span>

              <hr className='flex-auto text-Gray-300' />
            </div>

            <Button
              textTheme={'blue'}
              theme='white'
              className='create-new-template-btn'
              onClick={() => {
                setIsShowNewTemplateNavigationConfirmationModal(true)
              }}>
              Create New Template
            </Button>
          </div>

          <div className='right-container flex flex-col w-2/3'>
            <span className='text-Gray-800 font-SemiBold'>Preview</span>

            <Button
              textTheme={'blue'}
              theme='white'
              onClick={() => {
                setIsShowPreviewModal(true)
              }}
              isDisabled={!selectedTemplate?.value}
              className='pre-que-temp-btn mt-4 w-fit'>
              Preview Questionnaire Template
            </Button>

            <span className='text-Gray-800 font-SemiBold mt-8'>
              Lead Generation
            </span>

            <div className='flex justify-start items-start mt-4'>
              <Toggle
                className='mr-2'
                setValue={isStaffRequest}
                onToggleClick={(e: any) => {
                  setIsStaffRequest(e.target.checked)
                }}
              />
              <div className='flex flex-col'>
                <div>
                  <span className='text-Gray-800 font-Regular leading-[24px]'>
                    Attach staff request form
                  </span>

                  <i
                    className='ri-error-warning-line text-Gray-500 ml-2 text-[14px] relative'
                    data-tip='staff-form'
                    data-for='staff-form'
                  />

                  <ReactTooltip
                    place='right'
                    className='absolute w-auto'
                    id='staff-form'
                    effect='solid'
                    backgroundColor='rgb(255,255,255,0)'>
                    <img
                      src={popoverStaffForm}
                      alt=''
                      className='relative flex w-full object-contain h-auto top-[100px] flex-auto shadow-xl shadow-Gray-300 border border-Gray-200 rounded-sm'
                    />
                  </ReactTooltip>
                </div>

                <span className='text-extra-small text-Gray-600 tracking-[0.25px]'>
                  This will be in the thank you screen for the referee
                </span>
              </div>
            </div>

            <div className='flex justify-start items-start mt-3'>
              <Toggle
                className='mr-2'
                setValue={isCareerInterest}
                onToggleClick={(e: any) => {
                  setIsCareerInterest(e.target.checked)
                }}
              />
              <div className='flex flex-col'>
                <div>
                  <span className='text-Gray-800 font-Regular leading-[24px]'>
                    Attach career interest form
                  </span>

                  <i
                    className='ri-error-warning-line text-Gray-500 ml-2 text-[14px] relative'
                    data-tip='career-form'
                    data-for='career-form'
                  />

                  <ReactTooltip
                    place='right'
                    className='absolute w-auto'
                    id='career-form'
                    effect='solid'
                    backgroundColor='rgb(255,255,255,0)'>
                    <img
                      src={popoverCareerForm}
                      alt=''
                      className='relative flex w-full object-contain h-auto top-[100px] flex-auto shadow-xl shadow-Gray-300 border border-Gray-200 rounded-sm'
                    />
                  </ReactTooltip>
                </div>

                <span className='text-extra-small text-Gray-600 tracking-[0.25px]'>
                  This will be in the thank you screen for the referee
                </span>
              </div>
            </div>

            <div className='flex justify-start items-center mt-8'>
              <span className='text-Gray-800 font-SemiBold'>
                Custom Message
              </span>

              <i
                className='ri-error-warning-line text-Gray-500 ml-2 text-[14px] relative'
                data-tip='custom-message'
                data-for='custom-message'
              />

              <ReactTooltip
                place='right'
                className='absolute w-auto'
                id='custom-message'
                effect='solid'
                backgroundColor='rgb(255,255,255,0)'>
                <img
                  src={popoverContent}
                  alt=''
                  className='relative flex w-full object-contain h-[550px] top-[100px] flex-auto shadow-xl shadow-Gray-300 border border-Gray-200 rounded-sm'
                />
              </ReactTooltip>
            </div>

            <span className='text-extra-small text-Gray-500 mt-1'>
              You can add an optional custom message to the recipient along with
              the email request.
            </span>

            <div className='editor-container flex mt-5 w-full h-[160px] '>
              <TextEditor
                onContentChange={(content) => {
                  setBlankTemplateContent(content)
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/** Create new template confirmation modal */}
      <Modal
        isActive={isShowNewTemplateNavigationConfirmationModal}
        onClickPrimaryBtn={() => {
          navigate('/questionnaires/build-new-questionnaire')
        }}
        onClickSecondaryBtn={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Warning
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Please note you will be directed to the create questionnaire page
            and the content in this page will not be saved.
          </span>
        </div>
      </Modal>

      {isShowPreviewModal && (
        <WebRefCheckPreviewModal
          isActive={isShowPreviewModal}
          modalClose={() => {
            setIsShowPreviewModal(false)
          }}
          templateId={selectedTemplate?.value}
          templateName={selectedTemplate?.label}
        />
      )}
    </div>
  )
}

WebRefCheck.defaultProps = {}

export default WebRefCheck
