import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditMedicalIncidentInterface {
  candidateId: any
  incidentDetails: any
  incidentAttachments: any
  employeeClaimAttachments: any
  cocTypeIdAttachments: any
  medicalReportAttachments: any
  otherTypeIdtAttachments: any
  restrictions: any
}

export const useEditMedicalIncident = () => {
  const mutate = useMutation(
    ({
      candidateId,
      incidentDetails,
      incidentAttachments,
      employeeClaimAttachments,
      cocTypeIdAttachments,
      medicalReportAttachments,
      otherTypeIdtAttachments,
      restrictions,
    }: EditMedicalIncidentInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', incidentDetails?.categoryId)
      bodyFormData.append('LocationId', incidentDetails?.locationId)
      bodyFormData.append('Title', incidentDetails?.title)
      bodyFormData.append('IsWorkCover', incidentDetails?.isWorkCover)
      incidentDetails?.isWorkCover &&
        bodyFormData.append('IsNotify', incidentDetails?.isNotify)

      restrictions?.map((item: any) => {
        bodyFormData.append('Restrictions', item)
      })

      incidentDetails?.date &&
        bodyFormData.append('Date', incidentDetails?.date)

      {
        incidentDetails?.isWorkCover &&
          incidentDetails?.validFrom &&
          bodyFormData.append('ValidFrom', incidentDetails?.validFrom)
      }

      {
        incidentDetails?.isWorkCover &&
          incidentDetails?.expiryOn &&
          bodyFormData.append('ExpiryOn', incidentDetails?.expiryOn)
      }

      bodyFormData.append('Notes', incidentDetails?.notes)
      bodyFormData.append('AuditRequired', incidentDetails?.auditCheck)

      incidentAttachments?.map((item) => {
        const newIncidentAttachments = item?.isExistingFile ? null : item

        newIncidentAttachments &&
          bodyFormData.append('IncidentAttachments', item)
      })
      bodyFormData.append('IncidentTypeId', incidentDetails?.incidentTypeId)

      incidentDetails?.isWorkCover &&
        medicalReportAttachments?.length > 0 &&
        (bodyFormData.append(
          'MedicalReportTypeId',
          incidentDetails?.medicalReportTypeId,
        ),
        medicalReportAttachments?.map((item) => {
          const newMedicalReportAttachments = item?.isExistingFile ? null : item

          newMedicalReportAttachments &&
            bodyFormData.append('MedicalReportAttachments', item)
        }))

      incidentDetails?.isWorkCover &&
        employeeClaimAttachments?.length > 0 &&
        (bodyFormData.append(
          'EmployeeClaimTypeId',
          incidentDetails?.employeeClaimTypeId,
        ),
        employeeClaimAttachments?.map((item) => {
          const newEmployeeClaimAttachments = item?.isExistingFile ? null : item

          newEmployeeClaimAttachments &&
            bodyFormData.append('EmployeeClaimAttachments', item)
        }))

      incidentDetails?.isWorkCover &&
        otherTypeIdtAttachments?.length > 0 &&
        (bodyFormData.append('OtherTypeId', incidentDetails?.otherTypeId),
        otherTypeIdtAttachments?.map((item) => {
          const newOtherAttachments = item?.isExistingFile ? null : item

          newOtherAttachments && bodyFormData.append('OtherAttachments', item)
        }))

      incidentDetails?.isWorkCover &&
        cocTypeIdAttachments?.length > 0 &&
        (bodyFormData.append('COCTypeId', incidentDetails?.cocTypeId),
        cocTypeIdAttachments?.map((item) => {
          const newCocAttachments = item?.isExistingFile ? null : item

          newCocAttachments && bodyFormData.append('COCAttachments', item)
        }))

      return putAPIwithFormData(
        `/facade/Candidate/${candidateId}/Incident/${incidentDetails?.id}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditMedicalIncident
