import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetSuburbsByStateCodeInterface {
  stateCode?: any
}

export const useGetSuburbsByStateCode = () => {
  const mutate = useMutation(
    ({ stateCode }: GetSuburbsByStateCodeInterface) => {
      return get(`/Suburb?StateCode=${stateCode}`, {})
    },
  )

  return mutate
}

export default useGetSuburbsByStateCode
