import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useDeleteCriminalHistory,
  useDeletePoliceCheckReport,
  useGetCriminalHistoryLIstById,
  useGetDocumentSignedUrlsById,
  useGetPoliceCheckCard,
  useGetPoliceCheckDetails,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import { CandidateTabTileCard } from 'components/atoms'
import {
  AddEditCriminalHistory,
  AddEditPoliceCheckDetails,
  EditPoliceCheck,
} from 'components/Drawers/Candidate'
import {
  CandidateDocumentsViewModal,
  EligibilityCriminalHistoryCard,
  EligibilityPoliceCheckCard,
  EligibilityPoliceCheckDetailsCard,
} from 'components/molecules'

export interface EligibilityTabViewProps {
  [x: string]: any
}

export const EligibilityTabView: FC<EligibilityTabViewProps> = ({
  className,
  candidateId,
  notify,
  getCandidateAuditDocumentIds,
  ...restProps
}: EligibilityTabViewProps) => {
  const dispatch = useDispatch()
  const { documentCategoriesList } = useSelector((state: any) => state.document)

  const [policeCheckDetailsData, setPoliceCheckDetailsData] = useState<any>()
  const [policeCheckCardData, setPoliceCheckCardData] = useState<any>()
  const [criminalHistoryList, setCriminalHistoryList] = useState<any>()

  const [isShowDeleteModal, setIsShowDeleteModal] = useState({
    enable: false,
    type: '',
  })

  const [selectedCriminalHistoryDetails, setSelectedCriminalHistoryDetails] =
    useState<any>()

  const [policeCheckDetailsDrawer, setPoliceCheckDetailsDrawer] = useState({
    enable: false,
    drawerMode: '',
    headerTitle: '',
    headerSubtitle: '',
    primaryButtonTitle: '',
  })

  const [criminalHistoryDrawer, setCriminalHistoryDrawer] = useState({
    enable: false,
    drawerMode: '',
    headerTitle: '',
    headerSubtitle: '',
    primaryButtonTitle: '',
  })

  const [signedUrlList, setSignedUrlList] = useState<any>([])
  const [isShowDocumentViewModal, setIsShowDocumentViewModal] = useState(false)

  const [isShowEditPoliceCheck, setIsShowEditPoliceCheck] = useState(false)

  /** handle more buttons */
  const { clickValue } = useSelector((state: any) => state.layout)
  const { isAuditModal } = useSelector((state: any) => state.audit)

  const [
    showCriminalHistoryMoreButtonOption,
    setShowCriminalHistoryMoreButtonOption,
  ] = useState<any>({
    index: -1,
    isEnable: false,
  })
  const [showPoliceCheckMoreButtonOption, setPoliceCheckMoreButtonOption] =
    useState<any>(false)

  useEffect(() => {
    setShowCriminalHistoryMoreButtonOption({
      index: -1,
      isEnable: false,
    })

    setPoliceCheckMoreButtonOption(false)
  }, [clickValue])

  useEffect(() => {
    getPoliceCheckDetails()
    getCriminalHistoryLIstById()
    getPoliceCheckCard()
  }, [])

  useEffect(() => {
    if (!isAuditModal?.isOpen) {
      getPoliceCheckCard()
    }
  }, [isAuditModal?.isOpen])

  /** handle get category Id for document type list */
  const handleDocumentTypeList = (type) => {
    const certificationAndLicenseObj = documentCategoriesList?.find((item) => {
      return item.label === type
    })

    dispatch(setDocumentCategory(certificationAndLicenseObj))
  }

  /** get police check details on success */
  const onGetPoliceCheckDetailsSuccess = ({ data }: any) => {
    setPoliceCheckDetailsData(data?.policeCheckDetails)
  }

  /** get police check details on error */
  const onGetPoliceCheckDetailsError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting police check details.',

      status: 'Error',
    })
  }

  /** API Handling request for police check details */
  const {
    refetch: getPoliceCheckDetails,
    isLoading: getPoliceCheckDetailsIsLoading,
    isFetching: getPoliceCheckDetailsIsFetching,
  } = useGetPoliceCheckDetails(
    {
      Id: candidateId,
    },
    onGetPoliceCheckDetailsSuccess,
    onGetPoliceCheckDetailsError,
  )

  /** get police check card on success */
  const onGetPoliceCheckCardSuccess = ({ data }: any) => {
    setPoliceCheckCardData(data?.policeChecks)
    getCandidateAuditDocumentIds()
  }

  /** get police check card on error */
  const onGetPoliceCheckCardError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting police check.',

      status: 'Error',
    })
  }

  /** API Handling request for police check card */
  const {
    refetch: getPoliceCheckCard,
    isLoading: getPoliceCheckCardIsLoading,
    isFetching: getPoliceCheckCardIsFetching,
  } = useGetPoliceCheckCard(
    {
      Id: candidateId,
    },
    onGetPoliceCheckCardSuccess,
    onGetPoliceCheckCardError,
  )

  /** get criminal history list on success */
  const onGetCriminalHistoryLIstByIdSuccess = ({ data }: any) => {
    setCriminalHistoryList(data?.criminalHistoryModels || {})
  }

  /** get criminal history list on error */
  const onGetCriminalHistoryLIstByIdError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting criminal history list.',

      status: 'Error',
    })
  }

  /** API Handling request for get criminal history list */
  const {
    refetch: getCriminalHistoryLIstById,
    isLoading: getCriminalHistoryLIstByIdIsLoading,
    isFetching: getCriminalHistoryLIstByIdIsFetching,
  } = useGetCriminalHistoryLIstById(
    {
      Id: candidateId,
    },
    onGetCriminalHistoryLIstByIdSuccess,
    onGetCriminalHistoryLIstByIdError,
  )

  /** API Handling request for criminal history record delete */
  const {
    mutate: deleteCriminalHistoryMutate,
    isLoading: deleteCriminalHistoryIsLoading,
  } = useDeleteCriminalHistory()

  async function deleteCriminalHistory() {
    deleteCriminalHistoryMutate(
      { criminalHistoryId: selectedCriminalHistoryDetails?.id, candidateId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })

          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: false,
            type: '',
          })
          getCriminalHistoryLIstById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting criminal history record!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for delete police check report*/
  const {
    mutate: deletePoliceCheckReportMutate,
    isLoading: deletePoliceCheckReportIsLoading,
  } = useDeletePoliceCheckReport()

  async function deletePoliceCheckReport() {
    deletePoliceCheckReportMutate(
      { policeCheckId: policeCheckCardData?.policeCheckId, candidateId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })

          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: false,
            type: '',
          })
          getPoliceCheckCard()
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting police check report!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for Get selected document signed url List */
  const {
    mutate: getDocumentSignedUrlsByIdMutate,
    isLoading: getDocumentSignedUrlsByIdIsLoading,
  } = useGetDocumentSignedUrlsById()

  async function getDocumentSignedUrlsById(documentId: any) {
    getDocumentSignedUrlsByIdMutate(
      {
        documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData && setSignedUrlList(successData?.attachmentTypeSignedUrls)
        },
      },
    )
  }

  /** eligibility tab view style class */
  const EligibilityTabViewClasses = CN(
    `eligibility-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={EligibilityTabViewClasses} {...restProps}>
      {/* top tile cards */}
      <div className='flex gap-x-5'>
        {/* has a police check card */}
        <CandidateTabTileCard
          isLoading={
            getPoliceCheckCardIsLoading || getPoliceCheckCardIsFetching
          }
          className='w-1/4 bg-gradient-to-r from-[#4191DC] to-[#1195C5]'
          icon={
            <i className='ri-police-car-line text-[100px] text-Cobalt-600 opacity-20'></i>
          }
          leftElement={
            <span className='text-white font-Bold'>Has a Police Check</span>
          }
          rightElement={
            <span className='text-heading-3 text-white font-Bold z-[1]'>
              {policeCheckCardData?.isPoliceClearance ? `Yes` : `No`}
            </span>
          }
        />

        {/* criminal records card */}
        <CandidateTabTileCard
          isLoading={
            getCriminalHistoryLIstByIdIsLoading ||
            getCriminalHistoryLIstByIdIsFetching
          }
          className='w-1/4 bg-gradient-to-r from-[#E66962] to-[#DD7F0B]'
          icon={
            <i className='ri-article-line text-[100px] text-Yellow-600 opacity-20'></i>
          }
          leftElement={
            <span className='text-white font-Bold'>Criminal Records</span>
          }
          rightElement={
            <span className='text-heading-3 text-white font-Bold z-[1]'>
              {criminalHistoryList && criminalHistoryList?.length}
            </span>
          }
        />

        {/* police check details card */}
        <CandidateTabTileCard
          isLoading={
            getPoliceCheckDetailsIsLoading || getPoliceCheckDetailsIsFetching
          }
          className='w-1/4 bg-gradient-to-r from-[#4B5563] to-[#374151]'
          icon={
            <i className='ri-checkbox-circle-line text-[100px] text-Gray-800 opacity-20'></i>
          }
          leftElement={
            <span className='text-white font-Bold w-[102px]'>
              Police Check Details
            </span>
          }
          rightElement={
            <span
              className={CN('text-heading-3  font-Bold z-[1]', {
                'text-Green-500': policeCheckDetailsData?.status,
                'text-white': !policeCheckDetailsData?.status,
              })}>
              {policeCheckDetailsData?.status
                ? policeCheckDetailsData?.status
                : '-'}
            </span>
          }
        />
      </div>

      {/* police check section */}
      {/*----------- NOTE : development hold until finalize  APIs ----------------*/}
      <EligibilityPoliceCheckCard
        policeCheckCardData={policeCheckCardData}
        isLoading={getPoliceCheckCardIsLoading || getPoliceCheckCardIsFetching}
        onEditClick={() => {
          handleDocumentTypeList('Police Check')
          setIsShowEditPoliceCheck(true)
        }}
        setShowMoreButtonOption={setPoliceCheckMoreButtonOption}
        showMoreButtonOption={showPoliceCheckMoreButtonOption}
        getDocumentSignedUrlsById={getDocumentSignedUrlsById}
        setShowCriminalHistoryMoreButtonOption={
          setShowCriminalHistoryMoreButtonOption
        }
        onDeleteClick={() => {
          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: true,
            type: 'policeCheck',
          })
        }}
        onViewClick={() => {
          setIsShowDocumentViewModal(true)
        }}
      />

      {/* police check  details section */}
      <EligibilityPoliceCheckDetailsCard
        policeCheckDetailsData={policeCheckDetailsData}
        isLoading={
          getPoliceCheckDetailsIsLoading || getPoliceCheckDetailsIsFetching
        }
        onAddClick={() => {
          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            drawerMode: 'Add',
            enable: true,
            headerTitle: 'Add Police Check Details',
            headerSubtitle:
              'Add police check info which would be sent to 3rd party',
            primaryButtonTitle: 'Add Police Check Details',
          })
        }}
        onEditClick={() => {
          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            drawerMode: 'Edit',
            enable: true,
            headerTitle: 'Edit Police Check Details',
            headerSubtitle:
              'Edit police check info which would be sent to 3rd party',
            primaryButtonTitle: 'Edit Police Check Details',
          })
        }}
      />

      {/* criminal history section */}
      <EligibilityCriminalHistoryCard
        criminalHistoryList={criminalHistoryList}
        isLoading={
          getCriminalHistoryLIstByIdIsLoading ||
          getCriminalHistoryLIstByIdIsFetching
        }
        showMoreButtonOption={showCriminalHistoryMoreButtonOption}
        setShowMoreButtonOption={setShowCriminalHistoryMoreButtonOption}
        setSelectedDocumentDetails={setSelectedCriminalHistoryDetails}
        setPoliceCheckMoreButtonOption={setPoliceCheckMoreButtonOption}
        onAddClick={() => {
          setCriminalHistoryDrawer({
            ...criminalHistoryDrawer,
            enable: true,
            drawerMode: 'Add',
            headerTitle: 'Add Criminal Record',
            headerSubtitle: 'Add New Criminal History Here',
            primaryButtonTitle: 'Save Criminal Record',
          })
          setSelectedCriminalHistoryDetails({})
        }}
        onEditClick={() => {
          setCriminalHistoryDrawer({
            ...criminalHistoryDrawer,
            enable: true,
            drawerMode: 'Edit',
            headerTitle: 'Edit Criminal Record',
            headerSubtitle: selectedCriminalHistoryDetails?.recordName,
            primaryButtonTitle: 'Edit Criminal Record',
          })
        }}
        onDeleteClick={() => {
          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: true,
            type: 'criminal',
          })
        }}
      />

      {/* police check edit section */}
      <EditPoliceCheck
        isActive={isShowEditPoliceCheck}
        setIsShowEditPoliceCheck={setIsShowEditPoliceCheck}
        notify={notify}
        candidateId={candidateId}
        policeCheckCardData={policeCheckCardData}
        getPoliceCheckCard={getPoliceCheckCard}
      />

      {/* police check details add and edit section */}
      <AddEditPoliceCheckDetails
        isActive={policeCheckDetailsDrawer?.enable}
        candidateId={candidateId}
        notify={notify}
        headerTitle={policeCheckDetailsDrawer?.headerTitle}
        headerSubtitle={policeCheckDetailsDrawer?.headerSubtitle}
        primaryButtonTitle={policeCheckDetailsDrawer?.primaryButtonTitle}
        drawerMode={policeCheckDetailsDrawer?.drawerMode}
        getPoliceCheckDetails={getPoliceCheckDetails}
        policeCheckDetailsDrawer={policeCheckDetailsDrawer}
        setPoliceCheckDetailsDrawer={setPoliceCheckDetailsDrawer}
        existingPoliceCheckDetailsData={policeCheckDetailsData}
      />

      {/* criminal history add and edit section */}
      <AddEditCriminalHistory
        isActive={criminalHistoryDrawer?.enable}
        candidateId={candidateId}
        notify={notify}
        headerTitle={criminalHistoryDrawer?.headerTitle}
        headerSubtitle={criminalHistoryDrawer?.headerSubtitle}
        primaryButtonTitle={criminalHistoryDrawer?.primaryButtonTitle}
        drawerMode={criminalHistoryDrawer?.drawerMode}
        getCriminalHistoryLIstById={getCriminalHistoryLIstById}
        criminalHistoryDrawer={criminalHistoryDrawer}
        setCriminalHistoryDrawer={setCriminalHistoryDrawer}
        selectedCriminalHistoryDetails={selectedCriminalHistoryDetails}
      />

      {/* police check view modal  */}
      <CandidateDocumentsViewModal
        isActive={isShowDocumentViewModal}
        docList={signedUrlList}
        headerTitle={'Australian Police Check'}
        onHeaderCloseButtonClick={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        onClickCloseBtn={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        getDocumentSignedUrlsByIdIsLoading={getDocumentSignedUrlsByIdIsLoading}
      />

      {/** criminal history delete confirmation Modal */}
      <Modal
        isActive={isShowDeleteModal?.enable}
        onClickPrimaryBtn={() => {
          isShowDeleteModal?.type === 'criminal' && deleteCriminalHistory()
          isShowDeleteModal?.type === 'policeCheck' && deletePoliceCheckReport()
        }}
        onClickSecondaryBtn={() => {
          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: false,
            type: '',
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading:
            deleteCriminalHistoryIsLoading || deletePoliceCheckReportIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowDeleteModal({
            ...isShowDeleteModal,
            enable: false,
            type: '',
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            {isShowDeleteModal?.type === 'criminal'
              ? `Delete Criminal Record?`
              : `Delete Police Report`}
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

EligibilityTabView.defaultProps = {}

export default EligibilityTabView
