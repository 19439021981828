/** Users */
import { combineReducers } from 'redux'

import auditReducer from './audit/auditSlice'
import breadcrumbsReducer from './breadcrumbs/breadcrumbs'
import documentReducer from './documents/documentSlice'
import layOutReducer from './layout/layoutSlice'
import regPackSlice from './regPack/regPackSlice'
import mainSideMenuReducer from './side-menu/mainSideMenuReducer'
import userReducer from './user/userSlice'

const appReducer = combineReducers({
  user: userReducer,
  sideMenuList: mainSideMenuReducer,
  breadcrumbs: breadcrumbsReducer,
  layout: layOutReducer,
  document: documentReducer,
  regPack: regPackSlice,
  audit: auditReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}
