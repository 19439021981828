import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditPoliceCheckInterface {
  candidateId: any
  documentDetails: any
  attachments: any
  policeCheckIds: any
}

export const useEditPoliceCheck = () => {
  const mutate = useMutation(
    ({
      candidateId,
      documentDetails,
      attachments,
      policeCheckIds,
    }: EditPoliceCheckInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('CategoryId', policeCheckIds?.categoryId)
      bodyFormData.append('IsPoliceClearance', documentDetails?.status)

      {
        documentDetails?.status &&
          bodyFormData.append(
            'PoliceCheckTypeId',
            policeCheckIds?.policeCheckReportId,
          )
      }

      {
        documentDetails?.status &&
          bodyFormData.append('PoliceCheckAttachment', attachments[0])
      }

      {
        documentDetails?.expiryOn &&
          documentDetails?.hasExpiryDate &&
          bodyFormData.append('ExpiryOn', documentDetails?.expiryOn)
      }

      return putAPIwithFormData(
        `/facade/Candidate/${candidateId}/PoliceCheck/${documentDetails?.policeCheckId}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditPoliceCheck
