import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getSuperNameList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/SuperName?SuperProviderId=${reqBody.superProviderId}`)
  return res.data
}

export const useGetSuperName = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['SuperNameList', reqBody], getSuperNameList, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
