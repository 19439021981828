import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteMedicalIncidentRestrictionInterface {
  candidateId?: string
  medicalIncidentId?: string
  restrictionName?: string
}

export const useDeleteMedicalIncidentRestriction = () => {
  const mutate = useMutation(
    ({
      candidateId,
      medicalIncidentId,
      restrictionName,
    }: DeleteMedicalIncidentRestrictionInterface) => {
      return deleteAPI(
        `/facade/Candidate/${candidateId}/Incident/${medicalIncidentId}/Restriction/${restrictionName}`,
      )
    },
  )

  return mutate
}

export default useDeleteMedicalIncidentRestriction
