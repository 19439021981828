import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCandidateDocumentInterface {
  documentId?: string
}

export const useDeleteCandidateDocument = () => {
  const mutate = useMutation(
    ({ documentId }: DeleteCandidateDocumentInterface) => {
      return deleteAPI(`/Document/${documentId}`)
    },
  )

  return mutate
}

export default useDeleteCandidateDocument
