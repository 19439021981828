import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditCandidateProps {
  Id?: any
  candidateDetails?: any
}

export const useEditCandidate = () => {
  const mutate = useMutation(({ candidateDetails, Id }: EditCandidateProps) => {
    return putAPI(`/Candidate/${Id}`, {
      title: candidateDetails.title,
      firstName: candidateDetails.firstName,
      lastName: candidateDetails.lastName,
      middleName: candidateDetails.middleName,
      email: candidateDetails.email,
      phone: candidateDetails.phone,
      address: {
        lineOne: candidateDetails.streetName,
        lineTwo: '',
        city: '',
        suburb: candidateDetails.suburb,
        state: candidateDetails.state,
        stateCode: candidateDetails.stateCode,
        country: candidateDetails.country,
        countryCode: candidateDetails.countryCode,
        postCode: candidateDetails.postCode,
        latitude:
          candidateDetails?.latitude !== '' ? candidateDetails?.latitude : 0,
        longitude:
          candidateDetails?.longitude !== '' ? candidateDetails?.longitude : 0,
      },
      gender: candidateDetails.gender,
      dateOfBirth: candidateDetails.dateOfBirth || null,

      socialLinks: {
        facebook: candidateDetails.facebook,
        twitter: candidateDetails.twitter,
        linkedIn: candidateDetails.linkedin,
      },
      isActive: candidateDetails.isActive,
      hideRoster: candidateDetails.hideRoster,
      showMedical: candidateDetails.showMedical,
      emergencyContact: {
        firstname: candidateDetails.eContactFirstName,
        lastname: candidateDetails.eContactLastName,
        relationship: candidateDetails.eContactRelationship,
        phoneNo: candidateDetails.eContactMobilePhone,
        alternativePhoneNo: candidateDetails.eContactHomePhone,
        state: candidateDetails.eContactState,
      },
      mainClientId:
        candidateDetails?.mainClientId !== ''
          ? candidateDetails?.mainClientId
          : null,
    })
  })

  return mutate
}

export default useEditCandidate
