import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import './PoliceCheckDetailsAddressCard.scss'

export interface PoliceCheckDetailsAddressCardProps {
  onEditClick?: any | undefined
  onDeleteClick?: any | undefined
  [x: string]: any
}

export const PoliceCheckDetailsAddressCard: FC<
  PoliceCheckDetailsAddressCardProps
> = ({
  className,
  address,
  idx,
  onEditClick,
  onDeleteClick,
  ...restProps
}: PoliceCheckDetailsAddressCardProps) => {
  const PoliceCheckDetailsAddressCardClasses = CN(
    `police-check-details-address-card w-full border border-Gray-300 rounded-lg p-4`,
    className,
  )

  return (
    <div className={PoliceCheckDetailsAddressCardClasses} {...restProps}>
      {/* header section */}
      <div className='flex items-center justify-between'>
        <span className='text-extra-small font-Medium text-Gray-500 py-1'>
          ADDRESS {idx}
        </span>

        {/* action button section */}
        <div className='action-buttons hidden'>
          <Button
            icon={<i className='ri-pencil-line text-[12px]  text-Cobalt-600' />}
            isIconOnly
            onClick={onEditClick}
            className='h-5 w-5'
            textTheme='blue'
            theme='white'
          />

          <Button
            icon={<i className='ri-delete-bin-line text-[12px] text-Red-600' />}
            isIconOnly
            onClick={onDeleteClick}
            className='h-5 w-5 ml-2'
            textTheme='blue'
            theme='white'
          />
        </div>
      </div>

      {/* address body section */}
      <div className='text-small font-Medium text-Gray-800 mt-2 line-clamp-3 break-all'>
        {address?.lineOne && `${address?.lineOne}, `}
        {address?.suburb && `${address?.suburb}, `}
        {address?.state && `${address?.state}, `}
        {address?.postCode && `${address?.postCode}, `}
        {address?.country && `${address?.country}.`}
      </div>
    </div>
  )
}

PoliceCheckDetailsAddressCard.defaultProps = {
  address: undefined,
  idx: undefined,
  onEditClick: undefined,
  onDeleteClick: undefined,
}

export default PoliceCheckDetailsAddressCard
