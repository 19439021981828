import React, { FC, ReactNode } from 'react'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'

export interface TabTileCardProps {
  children?: ReactNode | undefined
  className?: string | undefined
  count?: any
  subTitle?: any | undefined
  title?: string | undefined
  [x: string]: any
}

export const TabTileCard: FC<TabTileCardProps> = ({
  children,
  className,
  count,
  subTitle,
  isLoading,
  title,
  ...restProps
}: TabTileCardProps) => {
  const TabTileCardClasses = CN(
    `w-full rounded-[6px] shadow-xl relative`,
    className,
  )
  return (
    <div className={TabTileCardClasses} {...restProps}>
      {isLoading && <PageLoader size='xxs' />}
      <div className='flex justify-between py-[30px] px-5'>
        <div className='flex flex-col'>
          <span className=' font-Bold text-[#FFFFFF]'>{title}</span>

          <span className='font-Regular text-small text-[#FFFFFF] pt-1 z-[1]'>
            {subTitle}
          </span>
        </div>

        <div className='flex absolute -top-6 right-4'>{children}</div>

        <span className='flex justify-center items-center font-Bold text-heading-3 text-[#FFFFFF] z-[1]'>
          {count}
        </span>
      </div>
    </div>
  )
}

TabTileCard.defaultProps = {
  children: undefined,
  className: undefined,
  count: undefined,
  subTitle: undefined,
  title: undefined,
}

export default TabTileCard
