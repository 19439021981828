import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCandidateDocumentAttachmentByIdInterface {
  documentId?: string
  attachmentUrl?: string
}

export const useDeleteCandidateDocumentAttachmentById = () => {
  const mutate = useMutation(
    ({
      documentId,
      attachmentUrl,
    }: DeleteCandidateDocumentAttachmentByIdInterface) => {
      return deleteAPI(
        `/Document/${documentId}/Attachment?AttachmentUrl=${attachmentUrl}`,
      )
    },
  )

  return mutate
}

export default useDeleteCandidateDocumentAttachmentById
