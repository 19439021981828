import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button, Checkbox, Modal, SimpleSelect } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { saveAs } from 'file-saver'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useDeleteCandidateDocument,
  useGetDocumentByCandidateId,
  useGetDocumentSignedUrlsById,
  useGetDocumentTypeByCategoryId,
  useSetArchiveDocumentById,
  useSetUnarchiveDocumentById,
} from 'framework/api/methods'
import { documentStateList } from 'static-data/candidates/documentState'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import { TableHeader, UITable } from 'components/common/UITable'
import {
  AddNewCandidateDocument,
  ViewDocumentHistory,
} from 'components/Drawers/Candidate'
import { CandidateDocumentsViewModal } from 'components/molecules'

import { DocumentsTabListColumn } from './DocumentsTabListColumn'

export interface DocumentsTabViewProps {
  [x: string]: any
}

export const DocumentsTabView: FC<DocumentsTabViewProps> = ({
  candidateId,
  className,
  documentCategoryList,
  notify,
  otherDocumentCategoryList,
  getCandidateAuditDocumentIds,
  ...restProps
}: DocumentsTabViewProps) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { isAuditModal } = useSelector((state: any) => state.audit)
  const candidateName: any = searchParams.get('candidate_name')

  const { documentCategory } = useSelector((state: any) => state.document)

  /** document view modal states */
  const [signedUrlList, setSignedUrlList] = useState<any>([])
  const [isShowDocumentViewModal, setIsShowDocumentViewModal] = useState(false)

  /** document download states */
  const [signedUrlListForDownload, setSignedUrlListForDownload] = useState<any>(
    [],
  )
  const [isDownloadEnable, setIsDownloadEnable] = useState(false)

  /** document history modal states */
  const [isShowDocumentHistoryDrawer, setIsShowDocumentHistoryDrawer] =
    useState(false)

  /** document archive modal states */
  const [isShowDocumentArchiveModal, setIsShowDocumentArchiveModal] = useState({
    enable: false,
    type: '',
  })

  /** document delete modal states */
  const [isShowDocumentDeleteModal, setIsShowDocumentDeleteModal] =
    useState(false)
  const [selectedDocument, setSelectedDocument] = useState<any>()

  /** add document drawer states */
  const [showAddNewDocumentDrawer, setShowAddNewDocumentDrawer] =
    useState(false)

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [candidateDocumentTableColumn, setCandidateDocumentTableColumn] =
    useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    Id: candidateId || '',
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })
  const [filterVariables, setFilterVariables] = useState<any>({
    type: '',
    category: '',
    state: '',
    archived: '',
  })

  const [pageNumber, setPageNumber] = useState(1)

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [documentTypeList, setDocumentTypeList] = useState<any>({})
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState<any>(
    {
      categoryName: '',
      categoryId: '',
      type: '',
      typeId: '',
    },
  )
  const [selectedDocumentState, setSelectedDocumentState] = useState<any>()
  const [isArchivedFilter, setIsArchivedFilter] = useState(false)

  /** candidate's document tab List on success */
  const onSuccess = (data: any) => {
    const toBeUpdateTableData = {
      ...data,
      documentDetails: data?.documentDetails?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }

    setTableData(toBeUpdateTableData || {})
  }

  // /** More button DropDown handling states */
  const { clickValue } = useSelector((state: any) => state.layout)

  useEffect(() => {
    const toBeUpdateTableData = {
      ...tableData,
      documentDetails: tableData?.documentDetails?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }
    setTableData(toBeUpdateTableData)
  }, [clickValue])

  /** candidate's document tab List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All candidates List */
  const {
    refetch: getTableData,
    isLoading: getCandidateDocumentListIsLoading,
    isFetching: getCandidateDocumentListIsFetching,
  } = useGetDocumentByCandidateId(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      candidateId: paginationVariables.Id,
      type: filterVariables?.type || '',
      category: filterVariables?.category || '',
      state: filterVariables?.state || '',
      archived: filterVariables?.archived || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentTypes?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )

          setDocumentTypeList(tempDocumentTypeList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    documentCategory && getDocumentTypeByCategoryId(documentCategory?.value)
  }, [documentCategory])

  useEffect(() => {
    getTableData()
  }, [paginationVariables])

  /** for refetch table list when close audit check modal */
  useEffect(() => {
    !isAuditModal?.isOpen && getTableData()
  }, [isAuditModal])

  /** API Handling request for Get selected document signed url List */
  const {
    mutate: getDocumentSignedUrlsByIdMutate,
    isLoading: getDocumentSignedUrlsByIdIsLoading,
  } = useGetDocumentSignedUrlsById()

  async function getDocumentSignedUrlsById(documentId: any) {
    getDocumentSignedUrlsByIdMutate(
      {
        documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData && setSignedUrlList(successData?.attachmentTypeSignedUrls)

          successData &&
            setSignedUrlListForDownload(successData?.attachmentTypeSignedUrls)
        },
      },
    )
  }

  /** candidate's document tab list table column handling */
  useEffect(() => {
    if (tableData?.documentDetails && tableData?.documentDetails?.length > 0) {
      const columns: any = DocumentsTabListColumn(
        getDocumentSignedUrlsById,
        setIsDownloadEnable,
        setIsShowDocumentArchiveModal,
        isShowDocumentArchiveModal,
        setIsShowDocumentDeleteModal,
        setIsShowDocumentHistoryDrawer,
        setIsShowDocumentViewModal,
        setSelectedDocument,
        useSelector,
        useState,
        useEffect,
        tableData,
        setTableData,
        dispatch,
        candidateName,
        candidateId,
      )
      setCandidateDocumentTableColumn(columns)
    }
  }, [tableData])

  const applyFilter = () => {
    setFilterActive(true)
    setIsFilterMenuOpen(false)

    if (
      (selectedDocumentCategory?.type === '' ||
        selectedDocumentCategory?.type === undefined) &&
      (selectedDocumentCategory?.categoryName === '' ||
        selectedDocumentCategory?.categoryName === undefined) &&
      selectedDocumentState?.value === undefined &&
      isArchivedFilter === false
    ) {
      setFilterActive(false)
    }

    setFilterVariables({
      type: selectedDocumentCategory?.type || '',
      category: selectedDocumentCategory?.categoryName || '',
      state: selectedDocumentState?.value || '',
      archived: isArchivedFilter ? isArchivedFilter : '',
    })
  }

  const resetFilter = () => {
    setSearchKeyWord('')
    setSelectedDocumentCategory('')
    setSelectedDocumentState('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)
    setIsArchivedFilter(false)

    setFilterVariables({
      type: '',
      category: '',
      state: '',
      archived: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  /** API Handling request for document archive */
  const {
    mutate: setArchiveDocumentByIdMutate,
    isLoading: setArchiveDocumentByIdIsLoading,
  } = useSetArchiveDocumentById()

  async function setArchiveDocumentById() {
    setArchiveDocumentByIdMutate(
      {
        selectedDocumentId: selectedDocument?.documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowDocumentArchiveModal({
            ...isShowDocumentArchiveModal,
            enable: false,
            type: '',
          })
          getTableData()
          getCandidateAuditDocumentIds()
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error archive document!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for document unarchive */
  const {
    mutate: setUnarchiveDocumentByIdMutate,
    isLoading: setUnarchiveDocumentByIdIsLoading,
  } = useSetUnarchiveDocumentById()

  async function setUnarchiveDocumentById() {
    setUnarchiveDocumentByIdMutate(
      {
        selectedDocumentId: selectedDocument?.documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowDocumentArchiveModal({
            ...isShowDocumentArchiveModal,
            enable: false,
            type: '',
          })
          getTableData()
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error unarchive document!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle download attachments of selected document*/
  useEffect(() => {
    if (signedUrlListForDownload && isDownloadEnable) {
      signedUrlListForDownload.forEach(function (e) {
        fetch(e.attachmentSignedUrls)
          .then((res) => res.blob())
          .then((blob) => {
            if (e.type === 'doc') {
              saveAs(e?.attachmentSignedUrls, 'Attachments.doc')
            } else if (e.type === 'docx') {
              saveAs(e?.attachmentSignedUrls, 'Attachments.docx')
            } else {
              saveAs(blob, 'Attachments')
            }
          })
      })
    }
  }, [signedUrlListForDownload])

  /** API Handling request for document delete */
  const {
    mutate: deleteCandidateDocumentMutate,
    isLoading: deleteCandidateDocumentIsLoading,
  } = useDeleteCandidateDocument()

  async function deleteCandidateDocument() {
    deleteCandidateDocumentMutate(
      {
        documentId: selectedDocument?.documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowDocumentDeleteModal(false)
          getTableData()
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting document!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** document tab main styles */
  const DocumentsTabViewClasses = CN(`documents-tab-view w-full`, className)

  return (
    <div className={DocumentsTabViewClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={'Search document'}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}>
          {/** Adding candidate button */}
          <Button
            className='ml-2'
            onClick={() => {
              setShowAddNewDocumentDrawer(true)
            }}
            iconLeft={<i className='ri-add-line' />}
            theme='gradient'>
            New Document
          </Button>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex flex-col justify-center items-start px-4 pt-3 pb-4 z-[10]'>
                <div className='flex justify-center items-end'>
                  <SimpleSelect
                    label='By Category'
                    placeholder='All'
                    value={{
                      label: selectedDocumentCategory?.categoryName,
                      value: selectedDocumentCategory?.categoryId,
                    }}
                    className='w-[330px] mr-5'
                    onChange={(selectedItem: any) => {
                      setSelectedDocumentCategory({
                        ...selectedDocumentCategory,
                        categoryId: selectedItem.id,
                        categoryName: selectedItem.name,
                        type: '',
                        typeId: '',
                      })
                      getDocumentTypeByCategoryId(selectedItem.id)
                      dispatch(setDocumentCategory(selectedItem))
                    }}
                    options={documentCategoryList}
                  />

                  <SimpleSelect
                    label='By Type'
                    placeholder='All'
                    value={{
                      label: selectedDocumentCategory?.type,
                      value: selectedDocumentCategory?.typeId,
                    }}
                    className='w-[330px] mr-5'
                    onChange={(selectedItem: any) => {
                      setSelectedDocumentCategory({
                        ...selectedDocumentCategory,
                        typeId: selectedItem.id,
                        type: selectedItem.name,
                      })
                    }}
                    options={documentTypeList}
                    isDisabled={!selectedDocumentCategory?.categoryName}
                  />

                  <SimpleSelect
                    label='By Document Status'
                    placeholder='All'
                    value={selectedDocumentState}
                    className='w-[330px] mr-5'
                    onChange={(selectedItem: any) => {
                      setSelectedDocumentState(selectedItem)
                    }}
                    options={documentStateList}
                  />

                  <Checkbox
                    className='mr-5'
                    checked={isArchivedFilter}
                    helperText=''
                    onChange={(e) => {
                      setIsArchivedFilter(e.target.checked)
                    }}
                    labelText='Archived docs only'
                  />
                </div>

                <div className='flex mt-3'>
                  <Button
                    className='h-[38px] w-[139px]'
                    isDisabled={
                      !selectedDocumentCategory?.type &&
                      !selectedDocumentCategory?.categoryName &&
                      !selectedDocumentState?.value &&
                      !isArchivedFilter &&
                      isArchivedFilter
                    }
                    onClick={() => {
                      setPageNumber(1)
                      applyFilter()
                    }}>
                    Apply Filter
                  </Button>

                  <Button
                    className='ml-2 h-[38px] w-[100px]'
                    theme='white'
                    textTheme='blue'
                    onClick={() => {
                      resetFilter()
                    }}>
                    Reset
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.documentDetails || []}
            isLoading={
              getCandidateDocumentListIsLoading ||
              getCandidateDocumentListIsFetching
            }
            className=''
            columns={candidateDocumentTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {/* add new document */}
      <AddNewCandidateDocument
        candidateId={candidateId}
        isActive={showAddNewDocumentDrawer}
        setShowAddNewDocumentDrawer={setShowAddNewDocumentDrawer}
        otherDocumentCategoryList={otherDocumentCategoryList}
        notify={notify}
        getTableData={getTableData}
        getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
      />

      {/* view documents modal */}
      <CandidateDocumentsViewModal
        isActive={isShowDocumentViewModal}
        isViewHistoryButton={true}
        docList={signedUrlList}
        headerTitle={selectedDocument?.title}
        onHeaderCloseButtonClick={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        onClickCloseBtn={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        getDocumentSignedUrlsByIdIsLoading={getDocumentSignedUrlsByIdIsLoading}
        onClickViewHistoryButton={() => {
          setIsShowDocumentViewModal(false)
          setIsShowDocumentHistoryDrawer(true)
        }}
      />

      {/** document archive confirmation Modal */}
      <Modal
        isActive={isShowDocumentArchiveModal?.enable}
        onClickPrimaryBtn={() => {
          isShowDocumentArchiveModal?.type === 'archive' &&
            setArchiveDocumentById()

          isShowDocumentArchiveModal?.type === 'unarchive' &&
            setUnarchiveDocumentById()
        }}
        onClickSecondaryBtn={() => {
          setIsShowDocumentArchiveModal({
            ...isShowDocumentArchiveModal,
            enable: false,
            type: '',
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading:
            setArchiveDocumentByIdIsLoading ||
            setUnarchiveDocumentByIdIsLoading,
          theme: 'cobalt',
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowDocumentArchiveModal({
            ...isShowDocumentArchiveModal,
            enable: false,
            type: '',
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Gray-100'>
            <i className='ri-archive-line text-Gray-600 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            {isShowDocumentArchiveModal?.type === 'archive'
              ? ` Move to Archive?`
              : `Remove from Archive`}
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>

      {/* document history drawer */}
      <ViewDocumentHistory
        isActive={isShowDocumentHistoryDrawer}
        setIsShowDocumentHistoryDrawer={setIsShowDocumentHistoryDrawer}
        headerSubtitle={selectedDocument?.title}
        selectedDocumentId={selectedDocument?.documentId}
      />

      {/** document delete confirmation Modal */}
      <Modal
        isActive={isShowDocumentDeleteModal}
        onClickPrimaryBtn={() => {
          deleteCandidateDocument()
        }}
        onClickSecondaryBtn={() => {
          setIsShowDocumentDeleteModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateDocumentIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowDocumentDeleteModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Document?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

DocumentsTabView.defaultProps = {}

export default DocumentsTabView
