import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { useGetDocumentInformationViewData } from 'framework/api/methods'
import {
  EducationProps,
  LicenseCertificateProps,
  MedicalIncidentProps,
  OtherProps,
  PoliceCheckProps,
  PrescriptionsMedicalConditionsProps,
  SuperFundProps,
  VaccinationProps,
  VisaProps,
} from 'static-data/audit/categoryTypes'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import { RequestDocumentModal } from 'components/molecules/RequestDocumentModal'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface detailSectionProps {
  [x: string]: any
}

export const DetailsSection: FC<detailSectionProps> = ({
  item,
  idx,
}: detailSectionProps) => {
  const dispatch = useDispatch()

  const [isShowRequestDocumentModal, setIsShowRequestDocumentModal] =
    useState(false)

  const { selectedSection } = useSelector((state: any) => state.audit)

  const onSuccess = () => {
    // setInformationView(data)
  }

  /** API Handling for get section details */
  const { refetch: getDocumentInformationViewData, data: informationView } =
    useGetDocumentInformationViewData(
      selectedSection?.candidateId,
      selectedSection?.documentId,
      selectedSection?.categoryId,
      onSuccess,
    )

  useEffect(() => {
    selectedSection && getDocumentInformationViewData()
  }, [selectedSection])

  return (
    <div className='flex pl-4 pt-6 flex-col' key={idx}>
      <div className='top-section flex justify-between items-center relative'>
        <span className='text-small font-SemiBold text-Gray-800'>
          More Information
        </span>

        {item?.category === 'Other' && item?.type === 'Employment Contract' ? (
          <Button
            onClick={() => {
              dispatch(
                setEditDrawer({
                  mode: item?.type,
                  isOpen: true,
                }),
              )
            }}
            size='sm'
            textTheme='blue'
            theme='white'>
            Upload Document
          </Button>
        ) : (
          <div className='flex'>
            <Button
              onClick={() => {
                setIsShowRequestDocumentModal(true)
              }}
              size='sm'
              textTheme='blue'
              theme='white'>
              Request Documents
            </Button>

            <Button
              onClick={() => {
                dispatch(
                  setEditDrawer({
                    mode: item?.category,
                    isOpen: true,
                  }),
                )
              }}
              className='ml-2'
              size='sm'
              textTheme='blue'
              theme='white'>
              Edit
            </Button>
          </div>
        )}
      </div>

      <RequestDocumentModal
        isActive={isShowRequestDocumentModal}
        setIsActive={setIsShowRequestDocumentModal}
      />

      {/* --------- For Certifications/License category ----------- */}
      <div className='data-section flex flex-col gap-y-2 mt-5'>
        {item?.category === 'Certifications/License' &&
          LicenseCertificateProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'License Name' && (informationView?.name || '-')}
                {itm === 'Certification Type' && (informationView?.type || '-')}
                {itm === 'Expiry Date' &&
                  ((informationView?.expireDate &&
                    dateToReadableString(informationView?.expireDate)) ||
                    '-')}
              </span>
            </div>
          ))}

        {/* --------- For Education ----------- */}
        {item?.category === 'Education' &&
          EducationProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Certification Name' && (informationView?.name || '-')}
                {itm === 'Certification Type' && (informationView?.type || '-')}
                {itm === 'Expiry Date' &&
                  ((informationView?.expireDate &&
                    dateToReadableString(informationView?.expireDate)) ||
                    '-')}
                {itm === 'Request Documents' &&
                  ((informationView?.requestDate &&
                    dateToReadableString(informationView?.requestDate)) ||
                    '-')}
                {itm === 'Notes' && (informationView?.note || '-')}
              </span>
            </div>
          ))}

        {/* --------- For Vaccinations ----------- */}
        {item?.category === 'Vaccinations' &&
          VaccinationProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Vaccination Type' && (informationView?.type || '-')}
                {itm === 'Vaccination Name' && (informationView?.name || '-')}
                {itm === 'Expiry Date' &&
                  ((informationView?.expireDate &&
                    dateToReadableString(informationView?.expireDate)) ||
                    '-')}
              </span>
            </div>
          ))}

        {/* --------- For Medical Incidents ----------- */}
        {item?.category === 'Medical Incidents' &&
          MedicalIncidentProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Incident Name' && (informationView?.name || '-')}
                {itm === 'Incident happened on' &&
                  ((informationView?.incidentHappenedOn &&
                    dateToReadableString(
                      informationView?.incidentHappenedOn,
                    )) ||
                    '-')}

                {itm === 'Restrictions' && (
                  <div className='w-full flex flex-wrap gap-x-1'>
                    {(informationView?.restrictions?.length > 0 &&
                      informationView?.restrictions?.map(
                        (restrictionItem: any, index: any) => (
                          <div>
                            {restrictionItem}
                            {index < informationView?.restrictions.length - 1
                              ? `, `
                              : ``}
                          </div>
                        ),
                      )) ||
                      '-'}
                  </div>
                )}

                {itm === 'Work Cover Claim' &&
                  ((informationView?.isWorkCover ? `Yes` : `No`) || '-')}

                {itm === 'Incident Date' &&
                  ((informationView?.incidentDate &&
                    dateToReadableString(informationView?.incidentDate)) ||
                    '-')}

                {itm === 'COC Valid From' &&
                  ((informationView?.validFrom &&
                    dateToReadableString(informationView?.validFrom)) ||
                    '-')}

                {itm === 'COC Expires On' &&
                  ((informationView?.expireOn &&
                    dateToReadableString(informationView?.expireOn)) ||
                    '-')}

                {itm === 'Notify on COC expire' &&
                  ((informationView?.isNotify ? `Yes` : `No`) || '-')}
              </span>
            </div>
          ))}

        {/* --------- For Prescriptions/Medical Conditions ----------- */}
        {item?.category === 'Prescriptions/Medical Conditions' &&
          PrescriptionsMedicalConditionsProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Prescriptions Title' &&
                  (informationView?.title || '-')}
                {itm === 'Date of Issue' &&
                  ((informationView?.dateOfIssue &&
                    dateToReadableString(informationView?.dateOfIssue)) ||
                    '-')}
                {itm === 'Medical document Type' &&
                  (informationView?.type || '-')}
              </span>
            </div>
          ))}

        {/* --------- For super fund ----------- */}
        {item?.category === 'Super Fund' &&
          SuperFundProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Super Provider' &&
                  (informationView?.superProvider || '-')}
                {itm === 'Super Name' && (informationView?.superName || '-')}
                {itm === 'Membership No' &&
                  (informationView?.membershipNo || '-')}
                {itm === 'Super ABN' && (informationView?.superABN || '-')}
                {itm === 'Super USI' && (informationView?.superUSI || '-')}
              </span>
            </div>
          ))}

        {/* --------- For Police Check ----------- */}
        {item?.category === 'Police Check' &&
          PoliceCheckProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Document Name' && (informationView?.name || '-')}
                {itm === 'Document Type' && (informationView?.type || '-')}
                {informationView?.expireDate &&
                  itm === 'Expiry Date' &&
                  (dateToReadableString(informationView?.expireDate) || '-')}
              </span>
            </div>
          ))}

        {/* --------- For Visa ----------- */}
        {item?.category === 'Visa' &&
          VisaProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Visa Document Name' && (informationView?.name || '-')}
                {itm === 'Visa Document Type' && (informationView?.type || '-')}
                {itm === 'Expiry Date' &&
                  ((informationView?.expireDate &&
                    dateToReadableString(informationView?.expireDate)) ||
                    '-')}
              </span>
            </div>
          ))}

        {/* --------- For Other ----------- */}
        {item?.category === 'Other' &&
          OtherProps?.map((itm: any, idx: any) => (
            <div
              key={idx}
              className='grid grid-cols-3 w-full text-small gap-x-2'>
              <span className='list-item ml-6 text-Gray-500 col-span-1'>
                {itm}:
              </span>

              <span className='text-Gray-800 col-span-2'>
                {itm === 'Document Name' && (informationView?.name || '-')}
                {itm === 'Document Type' && (informationView?.type || '-')}
                {itm === 'Expiry Date' &&
                  ((informationView?.expireDate &&
                    dateToReadableString(informationView?.expireDate)) ||
                    '-')}
              </span>
            </div>
          ))}
      </div>
    </div>
  )
}

DetailsSection.defaultProps = {}

export default DetailsSection
