import React, { FC, useEffect, useState } from 'react'
import {
  Modal,
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddClientContactAvatar,
  useDeleteClientContactAvatar,
  useEditClientContact,
} from 'framework/api/methods'

import { PageLoader, PhoneInputField, ProfileAvatar } from 'components/atoms'
import { CustomizableEditProfilePictureModal } from 'components/molecules'
import { isEmail, isLetters } from 'utils'

export interface EditClientContactProps {
  [x: string]: any
}

export const EditClientContact: FC<EditClientContactProps> = ({
  clientId,
  clientLocations,
  getClientContactsList,
  getClientLocationsById,
  getPrimaryConsultantList,
  isActive,
  notify,
  primaryConsultantList,
  selectedContactItem,
  setIsOpenEditClientContactDrawer,
}: EditClientContactProps) => {
  /** client details and error fields states */
  const [contactDetails, setContactDetails] = useState<any>({
    avatarUrl: '',
    firstName: '',
    lastName: '',
    position: '',
    workLocation: '',
    workLocationId: null,
    consultant: '',
    consultantId: null,
    phoneNo: '',
    email: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    position: false,
    email: false,
  })

  const [workLocationList, setWorkLocationList] = useState<any>()

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** image upload and delete states */
  const [imageUrl, setImageUrl] = useState<any>('')
  const [files, setFiles] = useState<any>()
  const [showAddAvatarModal, setShowAddAvatarModal] = useState(false)
  const [showDeleteAvatarModal, setShowDeleteAvatarModal] = useState(false)

  /** APi call for the edit client contact */
  const {
    mutate: editClientContactMutate,
    isLoading: editClientContactIsLoading,
  } = useEditClientContact()

  /** Process the edit client contact */
  const editClientContact = () => {
    editClientContactMutate(
      {
        contactDetails,
        clientId,
        contactId: selectedContactItem?.contactId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Contact updated successfully',
            alertBody: '',
            status: 'Success',
          })
          getClientContactsList()
          getClientLocationsById()
          setIsOpenEditClientContactDrawer(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** mapping work location list to drop down */
  useEffect(() => {
    const tempWorkLocationList = clientLocations?.map((item: any) => {
      return {
        ...item,
        value: item?.clientLocationId,
        label: item?.name,
      }
    })
    setWorkLocationList(tempWorkLocationList)
  }, [clientLocations])

  /** set selected item data to fields */
  useEffect(() => {
    getPrimaryConsultantList()

    const {
      avatarUrl,
      consultantId,
      consultantName,
      email,
      firstName,
      lastName,
      phoneNo,
      position,
      workLocationId,
      workLocationName,
    } = selectedContactItem || {}

    setContactDetails({
      ...contactDetails,
      avatarUrl: avatarUrl,
      firstName: firstName,
      lastName: lastName,
      position: position,
      workLocation: workLocationName,
      workLocationId: workLocationId ? workLocationId : null,
      consultant: consultantName,
      consultantId: consultantId ? consultantId : null,
      phoneNo: phoneNo,
      email: email,
    })
  }, [selectedContactItem])

  /** front end validation for input fields */
  const validateInputs = () => {
    const { firstName, lastName, phoneNo, email } = contactDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      phoneNo: phoneNo ? false : true,
      email: isEmail(email) ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) && isLetters(lastName) && phoneNo && isEmail(email)

    return isAllValidFields
  }

  const handleEditClient = () => {
    const valid = validateInputs()
    if (valid) {
      editClientContact()
    }
  }

  /** upload client contact avatar */
  const {
    mutate: addClientContactAvatarMutate,
    isLoading: addClientContactAvatarIsLoading,
  } = useAddClientContactAvatar()

  /** Process the upload client contact avatar */
  async function uploadClientContactAvatar(avatarObject: any) {
    addClientContactAvatarMutate(
      {
        Id: selectedContactItem?.contactId,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: 'Contact avatar saved successfully.',
            alertBody: '',
            status: 'Success',
          })

          setContactDetails({
            ...contactDetails,
            avatarUrl: successData?.contact?.avatarUrl,
          })

          setImageUrl('')
          setShowAddAvatarModal(false)
          getClientContactsList()
          getClientLocationsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowAddAvatarModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for delete client contact avatar using react-query */
  const {
    mutate: deleteClientContactAvatarMutate,
    isLoading: deleteClientContactAvatarIsLoading,
  } = useDeleteClientContactAvatar()

  /** Process the Delete contact avatar  */
  async function deleteClientContactAvatar() {
    deleteClientContactAvatarMutate(
      { Id: selectedContactItem?.contactId },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Contact avatar successfully deleted',
            alertBody: '',
            status: 'Success',
          })
          setContactDetails({
            ...contactDetails,
            avatarUrl: '',
          })

          getClientContactsList()
          getClientLocationsById()
          setShowDeleteAvatarModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
          setShowDeleteAvatarModal(false)
        },
      },
    )
  }

  /** handle primary button disable */
  useEffect(() => {
    const { firstName, lastName, consultantId, phoneNo, email } =
      contactDetails || {}

    ;(firstName && lastName && consultantId && phoneNo && email ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [contactDetails])

  return (
    <>
      <RightSideModal
        isActive={isActive}
        headerTitle={`Edit Contact - ${selectedContactItem?.firstName} ${selectedContactItem?.lastName}`}
        headerSubtitle='Edit contact information'
        primaryButtonTitle='Update'
        secondaryButtonTitle='Cancel'
        isPrimaryButtonDisable={isPrimaryButtonDisabled}
        onFooterAddButtonClick={() => {
          handleEditClient()
        }}
        onHeaderCloseButtonClick={() => {
          setIsOpenEditClientContactDrawer(false)
        }}
        onFooterCancelButtonClick={() => {
          setIsOpenEditClientContactDrawer(false)
        }}>
        {editClientContactIsLoading && <PageLoader size='xxs' />}

        <div className='flex flex-col w-full px-6 pt-5 pb-2'>
          {/* profile picture section */}
          <div className='flex w-full justify-center items-center'>
            <ProfileAvatar
              isProfilePictureAvailable={
                contactDetails?.avatarUrl ? true : false
              }
              imageSource={
                contactDetails?.avatarUrl
                  ? contactDetails?.avatarUrl
                  : undefined
              }
              className='px-[34px] pt-1 w-fit'
              imageContainerClassName='w-[132px] h-[132px]'
              onAddClick={() => {
                setShowAddAvatarModal(true)
              }}
              onDeleteClick={() => {
                setShowDeleteAvatarModal(true)
              }}
              onEditClick={() => {
                setShowAddAvatarModal(true)
              }}
            />
          </div>

          {/* name section */}
          <div className='flex gap-x-5 mt-5'>
            <TextField
              label='First Name'
              className='w-1/2'
              value={contactDetails?.firstName}
              onChange={(e: any) => {
                setContactDetails({
                  ...contactDetails,
                  firstName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.firstName}
            />

            <TextField
              label='Last Name'
              className='w-1/2'
              value={contactDetails?.lastName}
              onChange={(e: any) => {
                setContactDetails({
                  ...contactDetails,
                  lastName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.lastName}
            />
          </div>

          {/* position section */}
          <TextField
            label='Position'
            className='mt-5'
            value={contactDetails?.position}
            onChange={(e: any) => {
              setContactDetails({
                ...contactDetails,
                position: e.target.value,
              })
            }}
            placeholder=''
          />

          {/* work location section */}
          <SimpleSelect
            label='Work Location'
            value={{
              label: contactDetails?.workLocation,
              value: contactDetails?.workLocationId,
            }}
            className='mt-5'
            onChange={(selectedItem: any) => {
              setContactDetails({
                ...contactDetails,
                workLocation: selectedItem.label,
                workLocationId: selectedItem.value,
              })
            }}
            options={workLocationList}
          />

          {/* primary consultant section */}
          <SimpleSelect
            label='Primary Consultant'
            value={{
              label: contactDetails?.consultant,
              value: contactDetails?.consultantId,
            }}
            className='mt-5'
            onChange={(selectedItem: any) => {
              setContactDetails({
                ...contactDetails,
                consultant: selectedItem.label,
                consultantId: selectedItem.value,
              })
            }}
            options={primaryConsultantList}
          />

          {/** Phone number section */}
          <PhoneInputField
            label='Phone No'
            value={contactDetails?.phoneNo}
            onChange={(number: any) =>
              setContactDetails({
                ...contactDetails,
                phoneNo: number,
              })
            }
            className='mt-5'
            isErrored={isErrorField?.phoneNo}
          />

          {/* email section */}
          <TextField
            label='Email'
            className='mt-5'
            value={contactDetails.email}
            onChange={(e: any) => {
              setContactDetails({
                ...contactDetails,
                email: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField?.email}
          />
        </div>
      </RightSideModal>

      {/* add/update new avatar section */}
      <CustomizableEditProfilePictureModal
        imageSource={contactDetails?.avatarUrl}
        isActive={showAddAvatarModal}
        onHeaderCloseButtonClick={() => {
          setShowAddAvatarModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowAddAvatarModal(false)
        }}
        onOverlayClick={() => {
          setShowAddAvatarModal(false)
        }}
        onClickPrimaryBtn={() => {
          uploadClientContactAvatar(files)
        }}
        primaryButtonIsLoading={addClientContactAvatarIsLoading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={'Add Contact Profile Photo'}
        headerSubTitle='This is optional. You can skip this if you like'
      />

      {/* delete client contact avatar confirmation modal */}
      <Modal
        isActive={showDeleteAvatarModal}
        onClickPrimaryBtn={() => {
          deleteClientContactAvatar()
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteAvatarModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteClientContactAvatarIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteAvatarModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Profile Picture?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </>
  )
}

EditClientContact.defaultProps = {}

export default EditClientContact
