import React, { FC } from 'react'
import CN from 'classnames'
import { documentHistoryStatusTitle } from 'static-data/candidates/documentState'

import { dateAndTimeToReadableString } from 'utils'

export interface DocumentHistoryDetailCardProps {
  [x: string]: any
}

export const DocumentHistoryDetailCard: FC<DocumentHistoryDetailCardProps> = ({
  cardItem,
  className,
  ...restProps
}: DocumentHistoryDetailCardProps) => {
  const { title, updatedOn, note, userRole, userName } = cardItem

  /** document history card styles */
  const DocumentHistoryDetailCardClasses = CN(
    `document-history-detail-card flex w-full h-full`,
    className,
  )

  /** destructure document history status titles */
  const {
    AuditFailed,
    AuditPassed,
    DocumentActivated,
    DocumentArchived,
    DocumentExpired,
    DocumentRemoved,
    DocumentUnArchived,
    NewDocumentCreated,
    PendingAuditCheck,
    RequestedNewDocuments,
    ReuploadedTheDocument,
  } = documentHistoryStatusTitle || {}

  return (
    <div className={DocumentHistoryDetailCardClasses} {...restProps}>
      {/* left container */}
      <div className='flex flex-col h-auto items-center z-[2]'>
        <div className='eclipse h-7 w-7 flex justify-center items-center rounded-full bg-Cobalt-100'>
          <i
            className={CN('text-[14px] text-Cobalt-600 font-Medium', {
              'ri-upload-2-fill':
                title === NewDocumentCreated || title === ReuploadedTheDocument,
              'ri-indeterminate-circle-line': title === PendingAuditCheck,
              'ri-close-line': title === AuditFailed,
              'ri-time-line ': title === RequestedNewDocuments,
              'ri-checkbox-circle-line':
                title === AuditPassed || title === DocumentActivated,
              'ri-close-circle-line': title === DocumentExpired,
              'ri-delete-bin-line': title === DocumentRemoved,
              'ri-archive-line':
                title === DocumentArchived || title === DocumentUnArchived,
            })}
          />
        </div>
      </div>

      {/* right container */}
      <div
        className={CN(
          'h-auto flex flex-col w-full rounded-lg border ml-5 mb-8 p-4',
          {
            'bg-Red-50 border-Red-500':
              title === AuditFailed ||
              title === DocumentExpired ||
              title === DocumentRemoved,

            'bg-Green-50 border-Green-500':
              title === AuditPassed || title === DocumentActivated,
            'bg-white border-Gray-200':
              title === NewDocumentCreated ||
              title === PendingAuditCheck ||
              title === RequestedNewDocuments ||
              title === ReuploadedTheDocument ||
              title === DocumentArchived ||
              title === DocumentUnArchived,
          },
        )}>
        {/* updated date section */}
        <span className=' text-extra-small font-Regular text-Gray-400'>
          {dateAndTimeToReadableString(updatedOn)}
        </span>

        {/* status title section */}
        <span>{title}</span>

        {/* notes section */}
        {note && <span className='text-extra-small text-Gray-700'>{note}</span>}

        {/* bottom section */}
        <div className='bottom-section flex pt-1'>
          <div className='flex justify-center items-center'>
            <div
              className={CN('eclipse h-2 w-2 rounded-full', {
                'bg-Green-500': userRole === 'Consultant',
                'bg-Cobalt-500': userRole === 'Candidate',
                'bg-Gray-500':
                  userRole === 'Administrator' || userRole === 'Manager',
                'bg-Yellow-500': userRole === 'System',
              })}></div>

            <span className='flex text-extra-small text-Gray-500 font-Medium ml-1'>
              {userRole === 'Consultant' ||
              userRole === 'Candidate' ||
              userRole === 'Administrator' ||
              userRole === 'Manager'
                ? userName
                : `System`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

DocumentHistoryDetailCard.defaultProps = {}

export default DocumentHistoryDetailCard
