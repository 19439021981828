/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isAuditModal: {
    isOpen: false,
    isFooterShow: false,
    isActiveButton: false,
    tab: null,
  },
  selectedSection: null,
  selectedCandidate: null,
  documentIdsList: null,
  documentList: null,
  editDrawer: null,
  selectedAccordionIndex: 0,
  selectedDocumentId: null,
}

const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false),
        (state.isAuditModal = {
          isOpen: false,
          isFooterShow: false,
          isActiveButton: false,
          tab: null,
        }),
        (state.selectedSection = null),
        (state.documentList = null),
        (state.editDrawer = null),
        (state.selectedCandidate = null),
        (state.documentIdsList = null),
        (state.selectedAccordionIndex = 0),
        (state.selectedDocumentId = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setIsAuditModalState(state, { payload }) {
      state.isAuditModal = payload
    },

    setSelectedSectionState(state, { payload }) {
      state.selectedSection = payload
    },

    setDocumentListState(state, { payload }) {
      state.documentList = payload
    },

    setEditDrawerState(state, { payload }) {
      state.editDrawer = payload
    },

    setSelectedCandidateState(state, { payload }) {
      state.selectedCandidate = payload
    },

    setDocumentIdsListState(state, { payload }) {
      state.documentIdsList = payload
    },

    setSelectedAccordionIndexState(state, { payload }) {
      state.selectedAccordionIndex = payload
    },

    setSelectedDocumentIdState(state, { payload }) {
      state.selectedDocumentId = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setIsAuditModalState,
  setSelectedSectionState,
  setDocumentListState,
  setEditDrawerState,
  setSelectedCandidateState,
  setDocumentIdsListState,
  setSelectedAccordionIndexState,
  setSelectedDocumentIdState,
} = auditSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setIsAuditModal =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsAuditModalState(payload))
  }

export const setSelectedSection =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setSelectedSectionState(payload))
  }

export const setDocumentList =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentListState(payload))
  }

export const setEditDrawer =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setEditDrawerState(payload))
  }

export const setSelectedCandidate =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setSelectedCandidateState(payload))
  }

export const setDocumentIdsList =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDocumentIdsListState(payload))
  }

export const setSelectedAccordionIndex =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setSelectedAccordionIndexState(payload))
  }

export const setSelectedDocumentId =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setSelectedDocumentIdState(payload))
  }

export default auditSlice.reducer
