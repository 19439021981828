// eslint-disable-next-line simple-import-sort/imports
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import {
  Alerts,
  Button,
  ProgressBar,
  TextField,
} from '@labourhub/labour-hub-ds'

import { passwordStrengthCalculate } from 'utils/passwordStrengthCalculate'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResetPassword } from 'framework/api/methods'

export interface ResetPasswordProps {
  [x: string]: any
}

export const ResetPassword: FC<ResetPasswordProps> = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [helperText, setHelperText] = useState(
    'Your password must be at least 8 characters',
  )
  const [dataObject, setDataObject] = useState({
    username: '',
    resetToken: '',
    password: '',
    confirmPassword: '',
  })

  /** get email from route params */
  useEffect(() => {
    const username: any = searchParams.get('username')
    const resetToken: any = searchParams.get('resetToken')

    /** exit from UI when there is no token or username */
    if (
      username === null ||
      resetToken === null ||
      username === '' ||
      resetToken == ''
    ) {
      navigate('/')
    }

    setDataObject({
      ...dataObject,
      username: username,
      resetToken: resetToken,
    })
  }, [searchParams])

  /** handle new password field on change */
  const newPasswordHandle = (e: any) => {
    const value = e.target.value
    setDataObject({ ...dataObject, password: value })

    /** Set helper text value,password strength bar visibility according to new password field value. */
    if (value !== '') {
      setHelperText('')
      setShowProgressBar(true)
      setPasswordStrength(passwordStrengthCalculate(value))
    } else {
      setHelperText('Your password must be at least 8 characters')
      setShowProgressBar(false)
    }
  }

  /** Reset password User API Call ------> Mutate */
  const { mutate: resetPasswordMutate, isLoading: resetPasswordIsLoading } =
    useResetPassword()

  /** Process the reset password submission */
  async function resetPassword(dataObj: any) {
    resetPasswordMutate(dataObj, {
      onSuccess: () => {
        setShowSuccessScreen(true)
      },
      onError: ({ response: { data: errData } }: any) => {
        setAlertDetails({
          ...alertDetails,
          isActive: true,
          messageHeader: errData?.messageTitle
            ? errData?.messageTitle
            : 'Error!',
          messageBody: errData?.message,
        })
      },
    })
  }

  /** handle reset password button */
  const handleResetPasswordButton = () => {
    resetPassword(dataObject)
  }

  return (
    <div className='flex flex-col main-container'>
      {/* logo */}

      {!showSuccessScreen && (
        <div className='reset-password-form w-[380px] flex items-center flex-col relative pt-16'>
          {/* header */}
          <span className='pb-3 text-heading-2 font-Medium text-Gray-800'>
            Reset account password
          </span>

          {/* header sub text */}
          <span className='pb-10 text-small font-Regular text-Gray-700'>
            For the account {searchParams.get('username')}
          </span>

          {/* New password form field with progress bar */}
          <div
            className={CN('w-full', {
              'pb-4': dataObject.password !== '',
              'pb-6': dataObject.password === '',
            })}>
            {/* text field */}
            <TextField
              type={showNewPassword ? 'text' : 'password'}
              label='New password'
              placeholder='••••••••'
              value={dataObject.password}
              helperText={helperText}
              iconAfter={
                dataObject.password !== '' ? (
                  <i
                    className={CN('hover:cursor-pointer', {
                      'ri-eye-off-line': showNewPassword == false,
                      'ri-eye-line': showNewPassword == true,
                    })}
                  />
                ) : (
                  <></>
                )
              }
              iconAfterOnClick={() => {
                setShowNewPassword(!showNewPassword)
              }}
              onChange={newPasswordHandle}
            />

            {/* password strength bar */}
            {showProgressBar && (
              <ProgressBar progressCount={passwordStrength} />
            )}
          </div>

          {/* Confirm password form field */}
          <div className='w-full pb-6'>
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              label='Confirm password'
              placeholder='••••••••'
              value={dataObject.confirmPassword}
              iconAfter={
                dataObject.confirmPassword !== '' ? (
                  <i
                    className={CN('hover:cursor-pointer', {
                      'ri-eye-off-line': showConfirmPassword == false,
                      'ri-eye-line': showConfirmPassword == true,
                    })}
                  />
                ) : (
                  <></>
                )
              }
              iconAfterOnClick={() => {
                setShowConfirmPassword(!showConfirmPassword)
              }}
              onChange={(e: any) => {
                setDataObject({
                  ...dataObject,
                  confirmPassword: e.target.value,
                })
              }}
            />
          </div>

          {/* Reset password button */}

          <Button
            isBlock
            className='mb-6'
            isLoading={resetPasswordIsLoading}
            onClick={handleResetPasswordButton}
            isDisabled={
              dataObject.password === '' ||
              dataObject.confirmPassword === '' ||
              dataObject.password !== dataObject.confirmPassword ||
              passwordStrength < 81
                ? true
                : false
            }
            theme='gradient'>
            Reset password
          </Button>

          <Button
            iconLeft={<i className='ri-arrow-left-line text-[14px]' />}
            onClick={() => {
              navigate('/')
            }}
            textTheme='blue'
            theme='link'>
            Back to log in
          </Button>

          {/* error alert */}
          {alertDetails.isActive && (
            <Alerts
              alertBody={alertDetails.messageBody}
              alertHeader={alertDetails.messageHeader}
              className='bottom-[-120px] absolute'
              onCloseIconClick={() => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
              }}
              status='Error'
            />
          )}
        </div>
      )}
      {showSuccessScreen && (
        <div className='flex flex-col items-center pt-28'>
          {/* success circle */}
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Green-300 border-[6px] border-Green-100'>
            <i className='ri-check-fill text-white text-[22px]'></i>
          </div>

          <span className='pt-8 text-heading-2 font-Medium text-Gray-800'>
            Successful password reset
          </span>

          <span className='pt-3 text-small font-Regular text-Gray-700'>
            Your password has been changed successfully
          </span>

          <Button
            iconLeft={<i className='ri-arrow-left-line text-[14px]' />}
            onClick={() => {
              navigate('/')
            }}
            className='mt-8'
            textTheme='blue'
            theme='link'>
            Back to log in
          </Button>
        </div>
      )}
    </div>
  )
}

ResetPassword.defaultProps = {}

export default ResetPassword
