import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditCriminalHistoryProps {
  candidateId?: any
  formData?: any
}

export const useEditCriminalHistory = () => {
  const mutate = useMutation(
    ({ candidateId, formData }: EditCriminalHistoryProps) => {
      return putAPI(
        `Candidate/${candidateId}/CriminalHistory/${formData?.criminalRecordId}`,
        {
          recordName: formData?.name,
          chargedDate: formData?.chargedDate,
          notes: formData?.note,
        },
      )
    },
  )

  return mutate
}

export default useEditCriminalHistory
