import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface RequestRefereesProps {
  candidateId?: any
  refereeCount?: any
}

export const useRequestReferees = () => {
  const mutate = useMutation(
    ({ candidateId, refereeCount }: RequestRefereesProps) => {
      return postAPI(
        `/Candidate/${candidateId}/RequestReferee/${refereeCount}`,
        {},
      )
    },
  )

  return mutate
}

export default useRequestReferees
