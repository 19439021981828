import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteAudioClipInterface {
  refereeId?: string
}

export const useDeleteAudioClip = () => {
  const mutate = useMutation(({ refereeId }: DeleteAudioClipInterface) => {
    return deleteAPI(`/referee/${refereeId}/deleteaudio`)
  })

  return mutate
}

export default useDeleteAudioClip
