import React, { FC } from 'react'
import {
  Badge,
  Button,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { questionnaireTemplateCategory } from 'static-data/questionnaires/questionnaireTemplateCategory'

import { dateAndTimeToReadableString } from 'utils'

export interface QuestionnaireBuilderHeaderSectionProps {
  onPreviewClick?: () => void | undefined
  onSaveClick?: any | undefined
  onPublishClick?: any | undefined
  [x: string]: any
}

export const QuestionnaireBuilderHeaderSection: FC<
  QuestionnaireBuilderHeaderSectionProps
> = ({
  className,
  formData,
  editableFields,
  lastUpdatedBy,
  onPreviewClick,
  onPublishClick,
  onSaveClick,
  setFormData,
  destinationColumn,
  isSaveBtnEnabled,
  isDraft,
  isLoading,
  questionnaireId,
  ...restProps
}: QuestionnaireBuilderHeaderSectionProps) => {
  const QuestionnaireBuilderHeaderSectionClasses = CN(
    `questionnaire-builder-header-section flex flex-col w-full bg-white rounded-lg border border-Gray-200 p-5 gap-y-2`,
    className,
  )

  return (
    <div className={QuestionnaireBuilderHeaderSectionClasses} {...restProps}>
      <div className='flex flex-row w-full'>
        <div className='flex items-end justify-start w-7/12 mr-5 gap-x-5'>
          <TextField
            className='w-2/3 name'
            isRequired
            value={formData?.name}
            onChange={(e: any) => {
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }}
            label='Questionnaire Name'
            placeholder='Forklift Driver Ref Check Name'
          />

          <SimpleSelect
            className='w-1/3 questionnaire-category'
            isRequired
            value={
              formData?.category
                ? {
                    value: formData?.category,
                    label:
                      formData?.category === 'WEB_CHECK'
                        ? 'Web Ref Check'
                        : formData?.category === 'PHONE_CHECK'
                        ? 'Phone Ref Check'
                        : formData?.category === 'HEALTH_DECLARATION'
                        ? 'Health Declaration'
                        : formData?.category === 'REG_PACK'
                        ? 'Reg Pack'
                        : undefined,
                  }
                : ''
            }
            onChange={(selectedItem: any) => {
              setFormData({
                ...formData,
                category: selectedItem?.value,
              })
            }}
            label='Questionnaire Category'
            options={questionnaireTemplateCategory}
            placeholder='Select...'
          />
        </div>

        <div className='flex items-end justify-end w-5/12'>
          <Button
            className='w-1/3 h-10'
            textTheme='blue'
            iconRight={<i className='ri-external-link-line' />}
            theme='white'
            isDisabled={
              questionnaireId === undefined || questionnaireId === null
            }
            onClick={() => {
              onPreviewClick && onPreviewClick()
            }}>
            Preview
          </Button>

          <Button
            className='w-1/3 h-10 ml-2'
            textTheme='blue'
            isDisabled={
              !formData.name ||
              !formData.category ||
              destinationColumn.length === 0 ||
              isLoading ||
              (isSaveBtnEnabled &&
                editableFields?.name === formData?.name &&
                editableFields.category === formData?.category)
            }
            iconRight={<i className='ri-save-line' />}
            theme='white'
            onClick={() => {
              onSaveClick && onSaveClick()
            }}>
            Save
          </Button>
          <Button
            className='w-1/3 h-10 ml-2'
            textTheme='white'
            iconRight={<i className='ri-share-forward-box-line' />}
            theme='cobalt'
            isDisabled={
              !formData.name ||
              !formData.category ||
              destinationColumn.length === 0 ||
              isLoading ||
              (isSaveBtnEnabled && isDraft)
            }
            onClick={() => {
              onPublishClick && onPublishClick()
            }}>
            Publish
          </Button>
        </div>
      </div>
      {lastUpdatedBy && (
        <div className='flex items-center gap-x-2'>
          <span className='text-Gray-500 font-Regular text-[12px]'>
            {`Last edited on: ${dateAndTimeToReadableString(lastUpdatedBy)}`}
          </span>
          <Badge size='small' theme={!isDraft ? 'red' : 'green'} type='basic'>
            {!isDraft ? 'Draft' : 'Published'}
          </Badge>
        </div>
      )}
    </div>
  )
}

QuestionnaireBuilderHeaderSection.defaultProps = {
  onPreviewClick: undefined,
  onSaveClick: undefined,
  onPublishClick: undefined,
}

export default QuestionnaireBuilderHeaderSection
