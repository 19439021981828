import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getBankList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/Bank?CountryCode=${reqBody.countryCode}`)
  return res.data
}

export const useGetBank = ({ ...reqBody }, onSuccess: any, onError: any) => {
  return useQuery(['BankList', reqBody], getBankList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
