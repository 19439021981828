import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { PersonalDetailsProps } from 'static-data/audit/categoryTypes'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import { dateToReadableString } from 'utils/dateToReadableString'

export interface IPersonalDetailsProps {
  [x: string]: any
}

export const PersonalDetails: FC<IPersonalDetailsProps> = ({
  item,
  ...restProps
}: IPersonalDetailsProps) => {
  const dispatch = useDispatch()
  return (
    <div className='flex flex-col' {...restProps}>
      <div className='top-section flex justify-between items-center'>
        <span className='text-small font-SemiBold text-Gray-800'>
          Personal Information
        </span>

        <div className='flex'>
          <Button
            onClick={() => {
              dispatch(
                setEditDrawer({
                  mode: 'candidate personal',
                  isOpen: true,
                }),
              )
            }}
            className='ml-2'
            size='sm'
            textTheme='blue'
            theme='white'>
            Edit
          </Button>
        </div>
      </div>

      <div className='data-section flex flex-col gap-y-2 mt-5'>
        {PersonalDetailsProps?.map((itm: any, idx: any) => (
          <div key={idx} className='grid grid-cols-3  w-full text-small'>
            <span className='list-item ml-6 text-Gray-500 col-span-1'>
              {itm}:
            </span>

            <span className='text-Gray-800 col-span-2 line-clamp-1 break-all'>
              {itm === 'Name' && (item?.name || '-')}
              {itm === 'Email Address' && (item?.email || '-')}
              {itm === 'Phone' && (item?.phone || '-')}
              {itm === 'Gender' && (item?.gender || '-')}
              {itm === 'Birthday' &&
                (item?.birthDate ? dateToReadableString(item?.birthDate) : '-')}
              {itm === 'Address' && (item?.address || '-')}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

PersonalDetails.defaultProps = {}

export default PersonalDetails
