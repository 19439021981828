import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddBankProps {
  bankData?: any
}

export const useAddBank = () => {
  const mutate = useMutation(({ bankData }: AddBankProps) => {
    return postAPI(`/Bank`, {
      name: bankData.name,
      countryCode: bankData.countryCode,
    })
  })

  return mutate
}

export default useAddBank
