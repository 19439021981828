import React, { FC, useEffect, useState } from 'react'
import { ActionMeta, OnChangeValue } from 'react-select'
import { RightSideModal } from '@labourhub/labour-hub-ds'
import {
  useCreateCandidateTag,
  useDeleteCandidateTag,
  useGetTagList,
} from 'framework/api/methods'

import { CreatableInputSelect, PageLoader } from 'components/atoms'

export interface EditCandidateTagsProps {
  [x: string]: any
}

export const EditCandidateTags: FC<EditCandidateTagsProps> = ({
  setSelectedTags,
  selectedTags,
  candidateId,
  getCandidateTagsById,
  isActive,
  notify,
  setIsEditTagsDrawerOpen,
}: EditCandidateTagsProps) => {
  const [TagList, setTagList] = useState<any>()

  /** Get tag list api */
  const { mutate: getTagListMutate, isLoading: getTagListIsLoading } =
    useGetTagList()

  /** Process the get tags */
  async function getTagList() {
    getTagListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempTagList = successData?.systemTags?.map((item: any) => {
            return {
              value: item.name,
              label: item.name,
            }
          })

          setTagList(tempTagList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting tags!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** call get tag list when drawer load */
  useEffect(() => {
    getTagList()
  }, [])

  /** add candidate tag */
  const { mutate: createCandidateTagMutate } = useCreateCandidateTag()

  /** Process the add candidate tag */
  async function addCandidateTag(tagName) {
    createCandidateTagMutate(
      {
        Id: candidateId,
        tagName,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** remove candidate tag */
  const { mutate: deleteCandidateTagMutate } = useDeleteCandidateTag()

  /** Process the remove candidate tag */
  async function deleteCandidateTag(TagName: any) {
    deleteCandidateTagMutate(
      {
        CandidateId: candidateId,
        TagName,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle creatable select onchange */
  const handleChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>,
  ) => {
    setSelectedTags(newValue)

    /** create-option for new label create action,
     * select-option for label select from drop down action,
     *  remove-value for delete selected label  action */

    if (
      actionMeta.action == 'create-option' ||
      actionMeta.action == 'select-option'
    ) {
      addCandidateTag(actionMeta?.option?.label)
    } else if (actionMeta.action == 'remove-value') {
      deleteCandidateTag(actionMeta?.removedValue?.label)
    }
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Edit Tags'
      headerSubtitle='Add new or edit existing tags'
      isFooterShow={false}
      primaryButtonTitle=''
      secondaryButtonTitle=''
      onHeaderCloseButtonClick={() => {
        setIsEditTagsDrawerOpen(false)
        getCandidateTagsById(candidateId)
      }}>
      {/* Loader */}
      {getTagListIsLoading && <PageLoader size='xxs' />}

      {/* main body */}
      <div className='flex flex-col w-full pl-5 pr-6 pt-5'>
        {/* select input */}
        <CreatableInputSelect
          label='Tags'
          isAutoHeight
          value={selectedTags}
          isMulti
          options={TagList}
          onChange={handleChange}
        />

        {/* empty state here */}
        {selectedTags?.length <= 0 && (
          <div className='w-full flex flex-col justify-center items-center mt-11'>
            <span className='text-heading-4 font-Medium text-Gray-800'>
              No Tags Found
            </span>

            <span className='text-small font-Regular text-Gray-600 text-center pt-2'>
              Tags help to filter out candidates. You can add them above
            </span>
          </div>
        )}
      </div>
    </RightSideModal>
  )
}

EditCandidateTags.defaultProps = {}

export default EditCandidateTags
