import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

export interface RequestRequiredDocumentsProps {
  openDrawer: () => void
  openEditView: (
    document: RequiredDocumentType,
    selectedDocumentIndex?: number,
  ) => void
  [x: string]: any
}

export interface RequiredDocumentType {
  name: string
  documentCategoryId: string
  category: string
  documentTypeId: string
  type: string
  notes?: string
  auditRequired: boolean
}

export const RequestRequiredDocuments: FC<RequestRequiredDocumentsProps> = ({
  className,
  openDrawer,
  openEditView,
  ...restProps
}: RequestRequiredDocumentsProps) => {
  const RequestRequiredDocumentsClasses = CN(
    `request-required-documents`,
    className,
  )

  const [isOpenDocumentDelete, setIsOpenDocumentDelete] = useState(false)

  const { templateDetails } = useSelector((state: any) => state?.regPack)
  const dispatch = useDispatch()

  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<number>()

  const deleteDocument = (index: number) => {
    const documentCopy = [...templateDetails.reqDocuments]
    documentCopy.splice(index, 1)
    dispatch(
      setTemplateDetails({ ...templateDetails, reqDocuments: documentCopy }),
    )
  }

  return (
    <div className={RequestRequiredDocumentsClasses} {...restProps}>
      <Modal
        isActive={isOpenDocumentDelete}
        onClickPrimaryBtn={() => {
          if (selectedDocumentIndex != undefined) {
            deleteDocument(selectedDocumentIndex)
            setIsOpenDocumentDelete(false)
          }
        }}
        onClickSecondaryBtn={() => {
          setIsOpenDocumentDelete(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Referee?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to delete referee?
          </span>
        </div>
      </Modal>
      <div className='p-5'>
        <div className='text-heading-4 text-Medium text-Gray-800'>
          Request Required Documents
        </div>
        <div className='text-small text-Gray-500'>
          Here you can ask specific required documents from the candidate
        </div>

        <div className='flex gap-5 flex-wrap'>
          {templateDetails?.reqDocuments?.map(
            (document: RequiredDocumentType, index) => (
              <div
                key={index}
                className='flex flex-col min-h-[91px] border border-Gray-200  w-[244px] rounded-lg mt-8 p-4'>
                <div className='flex justify-between'>
                  <span>{String(index + 1).padStart(2, '0')}</span>
                  <div className='flex gap-1'>
                    <button
                      className='flex justify-center shadow-sm rounded-md border border-[#D1D5DB] w-6 h-6'
                      onClick={() => {
                        openEditView(document, index)
                        openDrawer()
                      }}>
                      <i className='ri-pencil-line text-Cobalt-600 text-small' />
                    </button>
                    <button
                      className='flex justify-center shadow-sm rounded-md border border-[#D1D5DB] w-6 h-6'
                      onClick={() => {
                        setSelectedDocumentIndex(index)
                        setIsOpenDocumentDelete(true)
                      }}>
                      <i className='ri-delete-bin-line text-Red-600 text-small' />
                    </button>
                  </div>
                </div>
                <div className='text-small font-Medium text-Gray-800 mt-2'>
                  {document?.name}
                </div>
                <div className='text-extra-small font-Medium text-Gray-500'>
                  {document?.type}
                </div>
              </div>
            ),
          )}
          {/* Document Create Section */}
          <button
            className='flex flex-col justify-center items-center py-4 border border-Blue-500 bg-Blue-50 w-[244px] rounded-lg mt-8'
            onClick={() => {
              openDrawer()
            }}>
            <i className='ri-add-line text-[32px] text-Blue-600' />
            <span className='text-small text-Blue-600 font-Medium'>
              Request New Document
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

RequestRequiredDocuments.defaultProps = {}

export default RequestRequiredDocuments
