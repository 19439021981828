import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddSuburb,
  useEditClientLocation,
  useGetStateListByCountyCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail } from 'utils'

export interface EditClientLocationProps {
  [x: string]: any
}

export const EditClientLocation: FC<EditClientLocationProps> = ({
  isShowEditClientLocationDrawer,
  setIsShowEditClientLocationDrawer,
  setShowDeleteClientLocationModal,
  getClientLocationsById,
  getClientContactsList,
  contactItemList,
  clientId,
  notify,
  selectedLocationItem,
  countryList,
}: EditClientLocationProps) => {
  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** states for update client location details */
  const [clientLocationDetails, setClientLocationDetails] = useState<any>({
    name: '',
    streetName: '',
    state: '',
    stateCode: '',
    suburb: '',
    postCode: '',
    latitude: '',
    longitude: '',
    country: 'Australia',
    countryCode: 'AU',
    primaryContactPerson: null,
    phoneNumber: '',
    email: '',
  })
  const [isErrorField, setIsErrorField] = useState({
    email: false,
  })
  const [primaryContactItem, setPrimaryContactItem] = useState<any>()

  /** stateList and country list states */
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()
  const [primaryContactItemList, setPrimaryContactItemList] = useState<any>()

  /** Get client state list by country code */
  const { mutate: getStateListByCountyCodeMutate } =
    useGetStateListByCountyCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              label: item.name,
              value: item.stateCode,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onSuccess: () => {
          // notify({
          //   alertHeader: 'New suburb added',
          //   alertBody: '',
          //   status: 'Success',
          // })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** set selected client location data to fields */
  useEffect(() => {
    const {
      name,
      address,
      phoneNo,
      email,
      primaryContactId,
      firstName,
      lastName,
    } = selectedLocationItem || {}

    setClientLocationDetails({
      name: name ? name : '',
      streetName: address?.lineOne ? address?.lineOne : '',
      suburb: address?.suburb ? address?.suburb : '',
      postCode: address?.postCode ? address?.postCode : '',
      latitude: address?.latitude ? address?.latitude : '',
      longitude: address?.longitude ? address?.longitude : '',
      phoneNumber: phoneNo ? phoneNo : '',
      email: email ? email : '',
      state: address?.state ? address?.state : '',
      stateCode: address?.stateCode ? address?.stateCode : '',
      country: address?.country ? address?.country : '',
      countryCode: address?.countryCode ? address?.countryCode : '',
      primaryContactPerson: primaryContactId ? primaryContactId : null,
    })

    setPrimaryContactItem({
      label: primaryContactId ? `${firstName} ${lastName}` : '',
      vale: primaryContactId ? primaryContactId : '',
    })

    getSuburbsByStateCode(address?.stateCode)
    getStateListByCountryCode(address?.countryCode)
  }, [selectedLocationItem])

  /** Backend issue: use full client contact list api for getting this primary contact person drop down. refused to give new endpoint by BE */
  useEffect(() => {
    const tempContactPersonList = contactItemList?.map((item: any) => {
      return {
        ...item,
        value: item?.clientContactId,
        label: `${item?.firstName} ${item?.lastName} `,
      }
    })
    setPrimaryContactItemList(tempContactPersonList)
  }, [contactItemList])

  /** APi call for the edit location */
  const {
    mutate: editClientLocationMutate,
    isLoading: editClientLocationIsLoading,
  } = useEditClientLocation()

  /** Process the edit client location */
  const editClientLocation = () => {
    editClientLocationMutate(
      {
        clientLocationDetails,
        clientId,
        LocationId: selectedLocationItem?.locationId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Client location successfully updated ',
            alertBody: '',
            status: 'Success',
          })

          setIsShowEditClientLocationDrawer(false)

          getClientLocationsById()
          getClientContactsList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation for name and account size input fields */
  const validateInputs = () => {
    const { email } = clientLocationDetails

    setIsErrorField({
      ...isErrorField,
      email: isEmail(email) || email === '' ? false : true,
    })

    const isAllValidFields = isEmail(email) || email === ''
    return isAllValidFields
  }

  /** handle edit location button */
  const handleEditLocation = () => {
    const valid = validateInputs()

    if (valid) {
      editClientLocation()
    } else {
      notify({
        alertHeader: 'Invalid input type!',
        alertBody: '',
        status: 'Warning',
      })
    }
  }

  /** handle primary button disable */
  useEffect(() => {
    const { name, streetName, suburb, state } = clientLocationDetails || {}
    ;(name && streetName && state && suburb ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [clientLocationDetails])

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(clientLocationDetails.stateCode, actionMeta?.option?.label)
    }

    // setSuburbItem(newValue)

    setClientLocationDetails({
      ...clientLocationDetails,
      suburb: newValue?.label,
    })
  }

  return (
    <RightSideModal
      isActive={isShowEditClientLocationDrawer}
      headerTitle='Edit Client Location'
      headerSubtitle='Edit address information'
      primaryButtonTitle='Update Location'
      secondaryButtonTitle='Cancel'
      isCustomFooterLeftButton={true}
      footerLeftButtonTitle='Delete'
      footerLeftButtonIcon={<i className='ri-delete-bin-6-line' />}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onFooterLeftButtonClick={() => {
        setIsShowEditClientLocationDrawer(false)
        setShowDeleteClientLocationModal(true)
      }}
      onFooterAddButtonClick={() => {
        handleEditLocation()
      }}
      onHeaderCloseButtonClick={() => {
        setIsShowEditClientLocationDrawer(false)
      }}
      onFooterCancelButtonClick={() => {
        setIsShowEditClientLocationDrawer(false)
      }}>
      {editClientLocationIsLoading && <PageLoader size='xxs' />}

      <div className='flex flex-col h-fit w-full px-6 my-6'>
        {/* location name section */}
        <TextField
          label='Name of this location'
          value={clientLocationDetails.name}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              name: e.target.value,
            })
          }}
          placeholder=''
        />

        {/** Phone number section */}
        <PhoneInputField
          label='Phone No'
          value={clientLocationDetails.phoneNumber}
          onChange={(number: any) =>
            setClientLocationDetails({
              ...clientLocationDetails,
              phoneNumber: number,
            })
          }
          className='mt-5'
        />

        {/* email section */}
        <TextField
          label='Email'
          className='mt-5'
          value={clientLocationDetails.email}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.email}
        />

        {/* street name section */}
        <TextField
          label='Street Name'
          className='mt-5'
          value={clientLocationDetails.streetName}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              streetName: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* state section */}
        <SimpleSelect
          label='State'
          value={
            clientLocationDetails.state
              ? {
                  label: clientLocationDetails.state,
                  value: clientLocationDetails.stateCode,
                }
              : ''
          }
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (clientLocationDetails?.stateCode !== selectedItem?.value) {
              setClientLocationDetails({
                ...clientLocationDetails,
                state: selectedItem.label,
                stateCode: selectedItem.value,
                suburb: '',
              })

              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          options={stateList}
        />

        {/* suburb and section */}
        <div className='flex w-full mt-5 gap-5'>
          {/* suburb and section */}
          <CreatableInputSelect
            label='Suburb'
            className='w-2/3'
            value={
              clientLocationDetails.suburb
                ? {
                    label: clientLocationDetails.suburb,
                    value: clientLocationDetails.suburb,
                  }
                : ''
            }
            options={suburbList}
            onChange={handleSuburbChange}
            isDisabled={!clientLocationDetails.stateCode}
          />

          <TextField
            label='Post Code'
            className='w-1/3'
            value={clientLocationDetails.postCode}
            onChange={(e: any) => {
              setClientLocationDetails({
                ...clientLocationDetails,
                postCode: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>

        {/* latitude section */}
        <TextField
          label='Latitude (Optional)'
          className='mt-5'
          value={clientLocationDetails.latitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setClientLocationDetails({
                ...clientLocationDetails,
                latitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* longitude section */}
        <TextField
          label='Longitude (Optional)'
          className='mt-5'
          value={clientLocationDetails.longitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setClientLocationDetails({
                ...clientLocationDetails,
                longitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* country section */}
        <SimpleSelect
          label='Country'
          value={{
            label: clientLocationDetails.country,
            value: clientLocationDetails.countryCode,
          }}
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (clientLocationDetails?.countryCode !== selectedItem?.value) {
              setClientLocationDetails({
                ...clientLocationDetails,
                country: selectedItem.label,
                countryCode: selectedItem.value,
                state: '',
                stateCode: '',
                suburb: '',
              })

              getStateListByCountryCode(selectedItem.value)
            }
          }}
          options={countryList}
        />

        {/* primary contact person section */}
        <SimpleSelect
          label='Set Primary Contact Person'
          className='mt-5'
          value={primaryContactItem}
          onChange={(selectedItem: any) => {
            setPrimaryContactItem(selectedItem)
            setClientLocationDetails({
              ...clientLocationDetails,
              primaryContactPerson: selectedItem.value,
            })
          }}
          options={primaryContactItemList}
        />
      </div>
    </RightSideModal>
  )
}

EditClientLocation.defaultProps = {}

export default EditClientLocation
