/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  Modal,
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddAddressForPoliceCheckDetails,
  useAddPoliceCheckDetailsWithAddresses,
  useAddSuburb,
  useDeletePoliceCheckDetailAddress,
  useEditAddressForPoliceCheckDetails,
  useEditPoliceCheckDetails,
  useGetCountryList,
  useGetStateListByCountyCode,
  useGetSuburbsByStateCode,
  useGetVisaTypeList,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PoliceCheckDetailsAddressCard,
} from 'components/atoms'

export interface AddEditPoliceCheckDetailsProps {
  [x: string]: any
}

export const AddEditPoliceCheckDetails: FC<AddEditPoliceCheckDetailsProps> = ({
  candidateId,
  drawerMode,
  headerSubtitle,
  headerTitle,
  isActive,
  notify,

  primaryButtonTitle,
  getPoliceCheckDetails,
  policeCheckDetailsDrawer,
  setPoliceCheckDetailsDrawer,
  existingPoliceCheckDetailsData,
  ...restProps
}: AddEditPoliceCheckDetailsProps) => {
  const [policeCheckDetailsData, setPoliceCheckDetailsData] = useState({
    policeCheckId: '',
    state: '',
    licenseNo: '',
    passportNo: '',
    passportCountry: '',
    passportCountryCode: '',
    passportCountryId: '',
    visaType: '',
    visaTypeId: '',
  })

  /** country list state */
  const [countryList, setCountryItemList] = useState<any>()
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()

  /** visa type list state */
  const [visaTypeList, setVisaTypeList] = useState<any>()

  /** address states */
  const [isAddressModalOpen, setIsAddressModalOpen] = useState({
    enable: false,
    modalMode: '',
  })
  const [addressData, setAddressData] = useState<any>({
    lineOne: '',
    lineTwo: '',
    city: '',
    suburb: '',
    state: '',
    stateCode: '',
    country: 'Australia',
    countryCode: 'AU',
    postCode: '',
    latitude: 0,
    longitude: 0,
  })
  const [addressList, setAddressList] = useState<any>([])

  const [isAddressWarningShow, setIsAddressWarningShow] = useState(false)

  /** button disable states */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [
    isAddressModalPrimaryButtonDisabled,
    setIsAddressModalPrimaryButtonDisabled,
  ] = useState(true)

  useEffect(() => {
    /** set address list empty when add drawer open */
    drawerMode === 'Add' && setAddressList([])

    /** call get country list and visa type list apis */
    getCountryList()
    getVisaTypeList()
  }, [drawerMode])

  /** EDIT DRAWER DATA SET : set current data to fields when edit drawer mode  */
  useEffect(() => {
    if (drawerMode === 'Edit') {
      setPoliceCheckDetailsData({
        ...policeCheckDetailsData,
        policeCheckId: existingPoliceCheckDetailsData?.id,
        state: existingPoliceCheckDetailsData?.state,
        licenseNo: existingPoliceCheckDetailsData?.licenseNo,
        passportNo: existingPoliceCheckDetailsData?.passportNo,
        passportCountry: existingPoliceCheckDetailsData?.passportCountry,
        visaType: existingPoliceCheckDetailsData?.visaType,
      })

      existingPoliceCheckDetailsData?.candidateAddress?.map((item, idx) => {
        addressList.push({
          addressId: item?.id,
          idx: idx,
          lineOne: item?.address?.lineOne,
          lineTwo: item?.address?.lineTwo,
          city: item?.address?.city,
          suburb: item?.address?.suburb,
          state: item?.address?.state,
          stateCode: item?.address?.stateCode,
          country: item?.address?.country,
          countryCode: item?.address?.countryCode,
          postCode: item?.address?.postCode,
          latitude: 0,
          longitude: 0,
        })
      })
    }
  }, [existingPoliceCheckDetailsData, drawerMode])

  /** Get client country list */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** get visa type list on success */
  const onGetVisaTypeListSuccess = ({ data }: any) => {
    /** Backend issues: mapping visa list to drop down.*/
    const tempVisaTypeList = data?.workRights?.map((item: any) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      }
    })
    setVisaTypeList(tempVisaTypeList)
  }

  /** get visa type list on error */
  const onGetVisaTypeListError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: 'Error...!',
      alertBody: errData?.message
        ? errData?.message
        : 'Error getting visa type list!',
      status: 'Error',
    })
  }

  /** API Handling request for Get visa type list */
  const { refetch: getVisaTypeList } = useGetVisaTypeList(
    {},
    onGetVisaTypeListSuccess,
    onGetVisaTypeListError,
  )

  /** Get state list by country code */
  const { mutate: getStateListByCountyCodeMutate } =
    useGetStateListByCountyCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** address modal state dropdown set with selected country code */
  useEffect(() => {
    getStateListByCountryCode(addressData?.countryCode)
  }, [addressData?.countryCode])

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(addressData.stateCode, actionMeta?.option?.label)
    }

    setAddressData({
      ...addressData,
      suburb: newValue?.label,
    })
  }

  /** APi call for the add police check details */
  const {
    mutate: addPoliceCheckDetailsWithAddressesMutate,
    isLoading: addPoliceCheckDetailsWithAddressesIsLoading,
  } = useAddPoliceCheckDetailsWithAddresses()

  /** Process the add police check details */
  const addPoliceCheckDetails = () => {
    addPoliceCheckDetailsWithAddressesMutate(
      {
        addressList,
        candidateId,
        policeCheckDetailsData,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            enable: false,
          })
          setAddressList([])
          getPoliceCheckDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit police check details without addresses */
  const {
    mutate: editPoliceCheckDetailsMutate,
    isLoading: editPoliceCheckDetailsIsLoading,
  } = useEditPoliceCheckDetails()

  /** Process the edit police check details without addresses */
  const editPoliceCheckDetails = () => {
    editPoliceCheckDetailsMutate(
      {
        candidateId,
        policeCheckDetailsData,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            enable: false,
          })

          getPoliceCheckDetails()

          setAddressList([])
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add address for existing police check details */
  const {
    mutate: addAddressForPoliceCheckDetailsMutate,
    isLoading: addAddressForPoliceCheckDetailsIsLoading,
  } = useAddAddressForPoliceCheckDetails()

  /** Process the add address for existing  police check details */
  const addAddressForPoliceCheckDetails = () => {
    addAddressForPoliceCheckDetailsMutate(
      {
        addressData,
        candidateId,
        policeCheckId: policeCheckDetailsData?.policeCheckId,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          const updatedAddressData = {
            ...addressData,
            addressId: successData?.candidateAddress?.id,
          }

          const index = addressData?.idx
          addressList.splice(index, 1, updatedAddressData)
          setAddressList(addressList)

          setIsAddressModalOpen({
            ...isAddressModalOpen,
            enable: false,
            modalMode: '',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit address for existing police check details */
  const {
    mutate: editAddressForPoliceCheckDetailsMutate,
    isLoading: editAddressForPoliceCheckDetailsIsLoading,
  } = useEditAddressForPoliceCheckDetails()

  /** Process the edit address for existing  police check details */
  const editAddressForPoliceCheckDetails = () => {
    editAddressForPoliceCheckDetailsMutate(
      {
        addressData,
        candidateId,
        policeCheckId: policeCheckDetailsData?.policeCheckId,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          const index = addressData?.idx
          addressList.splice(index, 1, addressData)
          setAddressList(addressList)

          setIsAddressModalOpen({
            ...isAddressModalOpen,
            enable: false,
            modalMode: '',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the delete address for existing police check details */
  const {
    mutate: deletePoliceCheckDetailAddressMutate,
    isLoading: deletePoliceCheckDetailAddressIsLoading,
  } = useDeletePoliceCheckDetailAddress()

  /** Process the delete address for existing  police check details */
  const deletePoliceCheckDetailAddress = (selectedData) => {
    deletePoliceCheckDetailAddressMutate(
      {
        addressId: selectedData?.addressId,
        candidateId,
        policeCheckId: policeCheckDetailsData?.policeCheckId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          if (addressList?.length > 1) {
            const newArray = [...addressList]
            newArray.splice(newArray.indexOf(selectedData), 1)
            setAddressList(newArray)
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle primary button disable for police check details drawer*/
  useEffect(() => {
    const { state, licenseNo, passportNo, passportCountry, visaType } =
      policeCheckDetailsData || {}
    state &&
    licenseNo &&
    passportNo &&
    passportCountry &&
    visaType &&
    addressList?.length > 0
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [addressList, policeCheckDetailsData, isAddressModalOpen])

  /** handle primary button disable for police check details drawer*/
  useEffect(() => {
    const { lineOne, suburb, state, stateCode, country, countryCode } =
      addressData || {}
    lineOne && suburb && state && stateCode && country && countryCode
      ? setIsAddressModalPrimaryButtonDisabled(false)
      : setIsAddressModalPrimaryButtonDisabled(true)
  }, [addressData])

  return (
    <>
      <RightSideModal
        isActive={isActive}
        className='w-full'
        headerTitle={headerTitle}
        headerSubtitle={headerSubtitle}
        primaryButtonTitle={primaryButtonTitle}
        isPrimaryButtonDisable={isPrimaryButtonDisabled}
        onHeaderCloseButtonClick={() => {
          drawerMode === 'Edit' && getPoliceCheckDetails()
          setAddressList([])

          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            enable: false,
            drawerMode: '',
          })
        }}
        onFooterAddButtonClick={() => {
          drawerMode === 'Add' && addPoliceCheckDetails()
          drawerMode === 'Edit' && editPoliceCheckDetails()
        }}
        onFooterCancelButtonClick={() => {
          drawerMode === 'Edit' && getPoliceCheckDetails()
          setAddressList([])
          setPoliceCheckDetailsDrawer({
            ...policeCheckDetailsDrawer,
            enable: false,
            drawerMode: '',
          })
        }}
        {...restProps}>
        {/* drawer loader */}
        {addPoliceCheckDetailsWithAddressesIsLoading &&
          editPoliceCheckDetailsIsLoading && <PageLoader size='xxs' />}

        <div className='w-full px-6 py-5 h-fit'>
          {/* address section */}
          <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2'>
            05 Years Previous Address
          </div>

          {/* address list mapping section */}
          <div className='grid grid-cols-2 gap-x-5 gap-y-4 mt-5 relative'>
            {(deletePoliceCheckDetailAddressIsLoading ||
              addAddressForPoliceCheckDetailsIsLoading ||
              editAddressForPoliceCheckDetailsIsLoading) && (
              <PageLoader size='xxs' />
            )}

            {addressList?.length > 0 &&
              addressList?.map((item: any, idx) => (
                <PoliceCheckDetailsAddressCard
                  key={idx}
                  idx={idx + 1}
                  address={item}
                  onEditClick={() => {
                    setIsAddressModalOpen({
                      ...isAddressModalOpen,
                      enable: true,
                      modalMode: 'Edit',
                    })
                    setAddressData(item)
                  }}
                  onDeleteClick={() => {
                    if (addressList?.length > 1) {
                      if (drawerMode === 'Edit') {
                        deletePoliceCheckDetailAddress(item)
                      } else {
                        const newArray = [...addressList]
                        newArray.splice(newArray.indexOf(item), 1)
                        setAddressList(newArray)
                      }
                    } else {
                      setIsAddressWarningShow(true)

                      setTimeout(() => {
                        setIsAddressWarningShow(false)
                      }, 3000)
                    }
                  }}
                />
              ))}

            {/* add new address button */}
            <div
              className='police-check-details-add-new-card flex flex-col min-h-[124px] justify-center items-center w-full border border-Cobalt-300 bg-Cobalt-50 hover:bg-Cobalt-100 rounded-lg p-4'
              onClick={() => {
                setIsAddressModalOpen({
                  ...isAddressModalOpen,
                  enable: true,
                  modalMode: 'Add',
                })

                setAddressData({
                  idx: addressList?.length,
                  lineOne: '',
                  lineTwo: '',
                  city: '',
                  suburb: '',
                  state: '',
                  stateCode: '',
                  country: 'Australia',
                  countryCode: 'AU',
                  postCode: '',
                  latitude: 0,
                  longitude: 0,
                })
              }}>
              <i className='ri-add-line text-[28px] h-8 w-8 text-Cobalt-600' />

              <span className='text-small font-Medium text-Cobalt-600 pt-2'>
                Add New Address
              </span>
            </div>
          </div>

          {/* address warning  */}
          {isAddressWarningShow && (
            <div className='w-full flex justify-start items-center rounded-lg bg-Red-50 text-Red-700  font-Medium p-4 mt-4'>
              <i className='ri-close-circle-fill text-[16px] text-Red-500 mr-2' />
              <span>Atleast one address should be present</span>
            </div>
          )}

          {/* address section */}
          <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-10'>
            Other Details
          </div>

          {/* drivers license no section */}
          <TextField
            className='drivers-license-no pt-5'
            value={policeCheckDetailsData?.licenseNo}
            onChange={(e: any) => {
              setPoliceCheckDetailsData({
                ...policeCheckDetailsData,
                licenseNo: e.target.value,
              })
            }}
            label='Australian Drivers License No'
            placeholder=''
          />

          {/* state territory section */}
          <TextField
            className='state-territory pt-5'
            value={policeCheckDetailsData?.state}
            onChange={(e: any) => {
              setPoliceCheckDetailsData({
                ...policeCheckDetailsData,
                state: e.target.value,
              })
            }}
            label='State/Territory'
            placeholder=''
          />

          {/* passport number section */}
          <TextField
            className='passport-no pt-5'
            value={policeCheckDetailsData?.passportNo}
            onChange={(e: any) => {
              setPoliceCheckDetailsData({
                ...policeCheckDetailsData,
                passportNo: e.target.value,
              })
            }}
            label='Passport No'
            placeholder=''
          />

          {/* passport country section */}
          <SimpleSelect
            className='country pt-5'
            value={{
              label: policeCheckDetailsData?.passportCountry,
              value: policeCheckDetailsData?.passportCountryCode,
            }}
            onChange={(selectedItem: any) => {
              setPoliceCheckDetailsData({
                ...policeCheckDetailsData,
                passportCountry: selectedItem.label,
                passportCountryCode: selectedItem.value,
                passportCountryId: selectedItem?.id,
              })
            }}
            label='Passport Country'
            options={countryList}
            placeholder='Country'
          />

          {/* visa type section */}
          <SimpleSelect
            className='visa-type pt-5'
            value={{
              label: policeCheckDetailsData?.visaType,
              value: policeCheckDetailsData?.visaTypeId,
            }}
            onChange={(selectedItem: any) => {
              setPoliceCheckDetailsData({
                ...policeCheckDetailsData,
                visaType: selectedItem.label,
                visaTypeId: selectedItem.value,
              })
            }}
            label='Visa Type'
            options={visaTypeList}
            placeholder='Type'
          />
        </div>
      </RightSideModal>

      {/** document delete confirmation Modal */}
      <Modal
        isActive={isAddressModalOpen?.enable}
        headerTitle={
          isAddressModalOpen?.modalMode == 'Add'
            ? 'Add 5 years previous address'
            : 'Edit 5 years previous address'
        }
        onClickPrimaryBtn={() => {
          if (
            drawerMode === 'Edit' &&
            isAddressModalOpen?.modalMode === 'Add'
          ) {
            addAddressForPoliceCheckDetails()
          } else if (
            drawerMode === 'Edit' &&
            isAddressModalOpen?.modalMode === 'Edit'
          ) {
            editAddressForPoliceCheckDetails()
          } else {
            const index = addressData?.idx
            addressList.splice(index, 1, addressData)
            setAddressList(addressList)

            setIsAddressModalOpen({
              ...isAddressModalOpen,
              enable: false,
              modalMode: '',
            })
          }
        }}
        onClickSecondaryBtn={() => {
          setIsAddressModalOpen({
            ...isAddressModalOpen,
            enable: false,
            modalMode: '',
          })
        }}
        onHeaderCloseButtonClick={() => {
          setIsAddressModalOpen({
            ...isAddressModalOpen,
            enable: false,
            modalMode: '',
          })
        }}
        modalProps={{
          style: { width: '500px' },
        }}
        primaryButtonTitle={
          isAddressModalOpen?.modalMode == 'Add'
            ? 'Save Address'
            : 'Edit Address'
        }
        primaryButtonProps={{
          isDisabled: isAddressModalPrimaryButtonDisabled,
          isLoading:
            addAddressForPoliceCheckDetailsIsLoading &&
            editAddressForPoliceCheckDetailsIsLoading,
        }}
        secondaryButtonTitle='Cancel'
        footerButtonAlignment='right'
        primaryButtonTheme='cobalt'>
        <div className='flex flex-col w-full px-6 py-5'>
          <span className='text-Gray-800 font-SemiBold'>
            ADDRESS {addressData?.idx + 1}
          </span>

          <TextField
            className='street-name pt-4'
            value={addressData?.lineOne || ''}
            onChange={(e: any) => {
              setAddressData({
                ...addressData,
                lineOne: e.target.value,
              })
            }}
            label='Street Name'
            placeholder=''
          />

          <SimpleSelect
            className='state pt-5'
            value={{
              label: addressData?.state,
              value: addressData?.stateCode,
            }}
            onChange={(selectedItem: any) => {
              if (addressData?.stateCode !== selectedItem?.value) {
                setAddressData({
                  ...addressData,
                  state: selectedItem?.label,
                  stateCode: selectedItem?.value,
                  suburb: '',
                })
                getSuburbsByStateCode(selectedItem.value)
              }
            }}
            label='State'
            options={stateList}
            placeholder='State'
          />

          <div className='flex w-full mt-5 gap-5'>
            <CreatableInputSelect
              label='Suburb'
              className='w-2/3'
              value={{
                label: addressData.suburb,
                value: addressData.suburb,
              }}
              options={suburbList}
              onChange={handleSuburbChange}
              isDisabled={!addressData?.stateCode}
            />

            <TextField
              className='post-code w-1/3'
              value={addressData?.postCode}
              onChange={(e: any) => {
                setAddressData({
                  ...addressData,
                  postCode: e.target.value,
                })
              }}
              label='Zip Code'
              placeholder=''
            />
          </div>

          <SimpleSelect
            className='country pt-5'
            value={{
              label: addressData?.country,
              value: addressData?.countryCode,
            }}
            onChange={(selectedItem: any) => {
              setAddressData({
                ...addressData,
                country: selectedItem?.label,
                countryCode: selectedItem?.value,
                state: '',
                stateCode: '',
                suburb: '',
              })

              getStateListByCountryCode(selectedItem?.value)
            }}
            label='Country'
            options={countryList}
            placeholder='Country'
          />
        </div>
      </Modal>
    </>
  )
}

AddEditPoliceCheckDetails.defaultProps = {
  headerTitle: undefined,
  headerSubtitle: undefined,
  primaryButtonTitle: undefined,
}

export default AddEditPoliceCheckDetails
