import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddVaccinationInterface {
  candidateId: string
  documentDetails: any
  attachments: any
}

export const useAddVaccination = () => {
  const mutate = useMutation(
    ({
      candidateId,
      documentDetails,
      attachments,
    }: AddVaccinationInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Name', documentDetails?.name)
      bodyFormData.append('CategoryId', documentDetails?.categoryId)
      bodyFormData.append('TypeId', documentDetails?.typeId)

      documentDetails?.expiryDate &&
        bodyFormData.append('ExpiryOn', documentDetails?.expiryDate)
      documentDetails?.dueDate &&
        bodyFormData.append('DueOn', documentDetails?.dueDate)

      bodyFormData.append('Notes', documentDetails?.notes)
      bodyFormData.append('AuditRequired', documentDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachments', item)
      })

      return postAPIwithFormData(
        `/facade/Candidate/${candidateId}/Vaccination`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddVaccination
