import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface DynamicFormType {
  jsonSchema: string
  jsonUiSchema: string
}

export const useGetDynamicForm = (templateId: string) => {
  return useQuery(
    ['GET_DYNAMIC_FORM', templateId],
    async () => {
      if (templateId && templateId !== '') {
        const res = await get(
          `facade/Questionnaire/${templateId}/GetDynamicForm`,
        )
        return res.data as DynamicFormType
      }
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching dynamic form',
          status: 'Error',
        })
      },
    },
  )
}
