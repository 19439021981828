import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface createCandidateProps {
  candidateDetails?: any
}

export const useCreateCandidate = () => {
  const mutate = useMutation(({ candidateDetails }: createCandidateProps) => {
    /** Backend Issue : sending non usable attribute line two and suburb. refuse to remove . told send empty string to lineTwo
     * and city.
     * and told mainClientId send as null for now. because value will assign in future.
     */

    return postAPI(`/Candidate`, {
      title: candidateDetails.title,
      firstName: candidateDetails.firstName,
      lastName: candidateDetails.lastName,
      middleName: candidateDetails.middleName,
      email: candidateDetails.email,
      phone: candidateDetails.phone,
      address: {
        lineOne: candidateDetails.streetName,
        lineTwo: '',
        city: '',
        suburb: candidateDetails.suburb,
        state: candidateDetails.state,
        stateCode: candidateDetails.stateCode,
        country: candidateDetails.country,
        countryCode: candidateDetails.countryCode,
        postCode: candidateDetails.postCode,
        latitude:
          candidateDetails?.latitude !== '' ? candidateDetails?.latitude : 0,
        longitude:
          candidateDetails?.longitude !== '' ? candidateDetails?.longitude : 0,
      },
      gender: candidateDetails.gender,
      dateOfBirth: candidateDetails.dateOfBirth || null,

      socialLinks: {
        facebook: candidateDetails.facebook,
        twitter: candidateDetails.twitter,
        linkedIn: candidateDetails.linkedin,
      },
      hideRoster: candidateDetails.hideRoster,
      showMedical: candidateDetails.showMedical,
      emergencyContact: {
        firstname: candidateDetails.eContactFirstName,
        lastname: candidateDetails.eContactLastName,
        relationship: candidateDetails.eContactRelationship,
        phoneNo: candidateDetails.eContactMobilePhone,
        alternativePhoneNo: candidateDetails.eContactHomePhone,
        state: candidateDetails.eContactState,
      },
      mainClientId:
        candidateDetails?.mainClientId !== ''
          ? candidateDetails?.mainClientId
          : null,
    })
  })

  return mutate
}

export default useCreateCandidate
