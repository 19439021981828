import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface useRequestRefereesReminderProps {
  candidateId?: any
}

export const useRequestRefereesReminder = () => {
  const mutate = useMutation(
    ({ candidateId }: useRequestRefereesReminderProps) => {
      return postAPI(`/Candidate/${candidateId}/Reminder`, {})
    },
  )

  return mutate
}

export default useRequestRefereesReminderProps
