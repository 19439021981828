import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RightSideModal, TextField } from '@labourhub/labour-hub-ds'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

export interface NoOfRefereesDrawerProps {
  headerCloseButton?: () => void | undefined
  headerSubtitle: string
  headerTitle: string
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const NoOfRefereesDrawer: FC<NoOfRefereesDrawerProps> = ({
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  onOverlayClick,
  refereeCount,
  regPackSections,
  selectedRefereesTile,
  setRefereeCount,
  setSelectedRefereesTile,
  showNoOfRefereesDrawer,
  ...restProps
}: NoOfRefereesDrawerProps) => {
  const dispatch = useDispatch()

  const { templateDetails } = useSelector((state: any) => state.regPack)

  return (
    <RightSideModal
      isActive={showNoOfRefereesDrawer}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      onHeaderCloseButtonClick={() => {
        headerCloseButton && headerCloseButton()
        setSelectedRefereesTile(true)
        regPackSections?.length > 0 &&
          regPackSections?.map((item: any) => {
            if (item?.title === 'Referees') {
              dispatch(
                setTemplateDetails({
                  ...templateDetails,
                  sectionDetails: [
                    ...templateDetails.sectionDetails,

                    {
                      regPackSectionId: item?.id,
                      title: item?.title,
                      isMandatory: true,
                      metaData: {
                        noOfReferees: refereeCount,
                      },
                    },
                  ],
                }),
              )
            }
          })
      }}
      onOverlayClick={onOverlayClick}
      {...restProps}>
      {
        <TextField
          label='No of Referees'
          className='w-full mx-6 py-5'
          type='number'
          value={refereeCount}
          onChange={(e: any) => {
            if (e.target.value > 0 && e.target.value < 7) {
              setRefereeCount(e.target.value)
            }

            setSelectedRefereesTile(!selectedRefereesTile)
          }}
          placeholder=''
          helperText='Maximum 6 referees'
        />
      }
    </RightSideModal>
  )
}

NoOfRefereesDrawer.defaultProps = {
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  onOverlayClick: undefined,
}

export default NoOfRefereesDrawer
