import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCandidateTagsByIdGProps {
  Id?: any
}

export const useGetCandidateTagsById = () => {
  const mutate = useMutation(({ Id }: GetCandidateTagsByIdGProps) => {
    return get(`/Candidate/${Id}/Tag`, {})
  })

  return mutate
}

export default useGetCandidateTagsById
