import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePoliceCheckDetailAddressInterface {
  addressId?: any
  candidateId?: any
  policeCheckId?: any
}

export const useDeletePoliceCheckDetailAddress = () => {
  const mutate = useMutation(
    ({
      addressId,
      candidateId,
      policeCheckId,
    }: DeletePoliceCheckDetailAddressInterface) => {
      return deleteAPI(
        `Candidate/${candidateId}/PoliceCheckDetail/${policeCheckId}/Address/${addressId}`,
      )
    },
  )

  return mutate
}

export default useDeletePoliceCheckDetailAddress
