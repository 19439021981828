/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import {
  useAddDocumentType,
  useDeleteCandidateDocumentAttachmentById,
  useEditVaccination,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'

import {
  CalendarInput,
  CreatableInputSelect,
  PageLoader,
} from 'components/atoms'

export interface EditVaccinationProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const EditVaccination: FC<EditVaccinationProps> = ({
  candidateId,
  fetchDocumentList,
  getVaccinationCountById,
  isActive,
  notify,
  selectedDocumentDetails,
  setIsShowVaccinationEditModal,
  setSelectedDocumentDetails,
  ...restProps
}: EditVaccinationProps) => {
  /** document detail states */
  const [documentDetails, setDocumentDetails] = useState<any>({
    id: '',
    documentId: '',
    name: '',
    categoryId: '',
    type: '',
    typeId: '',
    expiryDate: null,
    dueDate: null,
    notes: '',
    auditCheck: false,
  })

  const [hasExpiryDateEnable, setHasExpiryDateEnable] = useState(false)
  const [isReqDocCandidateEnable, setIsReqDocCandidateEnable] = useState(false)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [docTypeList, setDocTypeList] = useState<any>()

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>([])

  /** set selected document details to field */
  useEffect(() => {
    const {
      id,
      documentId,
      name,
      categoryId,
      type,
      typeId,
      expiryOn,
      dueOn,
      notes,
      auditRequired,
      attachmentUrls,
    } = selectedDocumentDetails || {}

    setDocumentDetails({
      ...documentDetails,
      id: id,
      documentId: documentId,
      name: name,
      categoryId: categoryId,
      type: type,
      typeId: typeId,
      expiryDate: expiryOn,
      dueDate: dueOn,
      notes: notes ? notes : '',
      auditCheck: auditRequired,
    })

    expiryOn ? setHasExpiryDateEnable(true) : setHasExpiryDateEnable(false)
    dueOn ? setIsReqDocCandidateEnable(true) : setIsReqDocCandidateEnable(false)

    categoryId && getDocumentTypeByCategoryId(categoryId)

    /** map selected document files array and set to my files */
    if (attachmentUrls && attachmentUrls?.length > 0) {
      const tempArray = attachmentUrls?.map((item) => {
        return { path: item, isExistingFile: true }
      })

      setAttachments(tempArray)
    }
  }, [selectedDocumentDetails])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setAttachments([...attachments, ...acceptedFiles])
    },
    [attachments],
  )

  /** on file drop file size and type validate */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** APi call for the remove existing attachment */
  const { mutate: deleteCandidateDocumentAttachmentByIdMutate } =
    useDeleteCandidateDocumentAttachmentById()

  /** Process the delete existing attachment */
  const deleteCandidateDocumentAttachmentById = (documentId, attachmentUrl) => {
    deleteCandidateDocumentAttachmentByIdMutate(
      {
        documentId,
        attachmentUrl,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })

          fetchDocumentList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** remove uploaded file */
  const removeFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(
        documentDetails?.documentId,
        file?.path,
      )
    }

    const newFiles = [...attachments]
    newFiles.splice(newFiles.indexOf(file), 1)
    setAttachments(newFiles)
  }

  /** uploaded file list item  */
  const files = attachments.map((file) => (
    <li
      key={file.path}
      className='flex justify-between items-center  rounded-md border bg-white border-Gray-200 w-full px-3 py-1'>
      <div className='flex justify-start items-center w-4/5'>
        <i className='ri-attachment-2 text-Gray-400 mr-1' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span className='w-1/5 flex justify-end' onClick={removeFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentTypes?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )

          setDocTypeList(tempDocumentTypeList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new document type */
  const { mutate: addDocumentTypeMutate } = useAddDocumentType()

  /** Process the add new doc type */
  const addDocumentType = (categoryId, name) => {
    addDocumentTypeMutate(
      {
        categoryId,
        name,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set category id for send data */
          setDocumentDetails({
            ...documentDetails,
            type: successData?.documentType?.name,
            typeId: successData?.documentType?.id,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle doc type on Change */
  const handleDocumentTypeChange = (
    newValue: any,
    actionMeta: ActionMeta<any>,
  ) => {
    if (actionMeta.action == 'create-option') {
      addDocumentType(documentDetails?.categoryId, actionMeta?.option?.label)
    } else {
      setDocumentDetails({
        ...documentDetails,
        type: newValue?.label,
        typeId: newValue?.value,
      })
    }
  }

  /** handle primary button disable */
  useEffect(() => {
    const { name, typeId, dueDate, expiryDate } = documentDetails || {}
    name &&
    typeId &&
    (!isReqDocCandidateEnable ||
      (isReqDocCandidateEnable && dueDate ? true : false)) &&
    (!hasExpiryDateEnable || (hasExpiryDateEnable && expiryDate ? true : false))
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [documentDetails])

  /** edit document */
  const { mutate: editVaccinationMutate, isLoading: editVaccinationIsLoading } =
    useEditVaccination()

  /** Process the edit document */
  async function handleEditVaccinationDocuments() {
    editVaccinationMutate(
      { candidateId, documentDetails: documentDetails, attachments },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            status: 'Success',
          })
          getVaccinationCountById()
          fetchDocumentList()
          setAttachments([])
          setIsShowVaccinationEditModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          if (errData?.message?.includes('Infrastructure :')) {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message.split(':').slice(1),
              status: 'Error',
            })
          } else {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle='Edit Vaccination'
      headerSubtitle='Edit vaccination details here'
      primaryButtonTitle='Edit Vaccination'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        setSelectedDocumentDetails(null)
        setIsShowVaccinationEditModal(false)
        setAttachments([])
      }}
      onFooterAddButtonClick={() => {
        handleEditVaccinationDocuments()
      }}
      onFooterCancelButtonClick={() => {
        setSelectedDocumentDetails(null)
        setIsShowVaccinationEditModal(false)
        setAttachments([])
      }}
      {...restProps}>
      {/** drawer loader */}
      {editVaccinationIsLoading && <PageLoader size='xxs' />}

      {/** Body section of the drawer modal */}
      <div className='w-full px-6 pt-5'>
        {/* doc type section */}
        <CreatableInputSelect
          label='Vaccination Type'
          className='document-type'
          placeholder='Ex: Covid - 19, Monkeypox, Tetanus etc.'
          value={
            documentDetails?.type
              ? {
                  label: documentDetails?.type,
                  value: documentDetails?.typeId,
                }
              : null
          }
          options={docTypeList}
          onChange={handleDocumentTypeChange}
        />

        {/* document Name section */}
        <TextField
          className='document-name pt-5'
          value={documentDetails.name}
          onChange={(e: any) => {
            setDocumentDetails({
              ...documentDetails,
              name: e.target.value,
            })
          }}
          label='Vaccination Name'
          placeholder='Ex: Pfizer, Covishield, Tetanus, etc'
        />

        {/* expiry date section */}
        <Toggle
          label='Has Expiry Date'
          className='pt-5'
          setValue={hasExpiryDateEnable}
          onToggleClick={(e: any) => {
            setHasExpiryDateEnable(e.target.checked)

            e.target.checked === false &&
              setDocumentDetails({
                ...documentDetails,
                expiryDate: null,
              })
          }}
        />

        {hasExpiryDateEnable && (
          <div className='flex flex-col pt-3'>
            <span className='text-small text-Gray-800 font-Medium'>
              Expiry Date
              <span className='ml-1 text-Red-600'>*</span>
            </span>

            <CalendarInput
              value={documentDetails?.expiryDate}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setDocumentDetails({
                    ...documentDetails,
                    expiryDate: date,
                  })
                }
              }}
            />
          </div>
        )}

        {/* Request Documents section */}
        <Toggle
          label='Request Documents from Candidate'
          className='pt-5'
          setValue={isReqDocCandidateEnable}
          onToggleClick={(e: any) => {
            setIsReqDocCandidateEnable(e.target.checked)

            e.target.checked === false &&
              setDocumentDetails({
                ...documentDetails,
                dueDate: null,
              })
          }}
        />

        {isReqDocCandidateEnable && (
          <div className='flex flex-col pt-3'>
            <span className='text-small text-Gray-800 font-Medium'>
              Due Date
              <span className='ml-1 text-Red-600'>*</span>
            </span>

            <CalendarInput
              value={documentDetails?.dueDate}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setDocumentDetails({
                    ...documentDetails,
                    dueDate: date,
                  })
                }
              }}
            />

            <span className='text-small text-Gray-500 font-Regular pt-1'>
              Candidate will be notified to upload documents before the due date
            </span>
          </div>
        )}

        <div className='flex flex-col pt-5'>
          <span className='font-Medium text-small text-Gray-800'>
            Attachments
          </span>

          <span className='font-Regular text-extra-small text-Gray-600'>
            Max file size 5MB - supports docx, pdf, jpg, jpeg and png, multiple
            uploads
          </span>
        </div>

        {/* Upload Document section ---------------------------------------------------------------- */}
        <section className='container mt-3'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <div className='flex flex-col'>
              <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                <i className='ri-upload-2-line pr-2' />

                <span className='text-small font-Medium'>Upload File</span>
              </div>
            </div>
          </div>

          {files.length > 0 && (
            <div className='flex w-full mt-2'>
              <ul className='flex flex-col w-full gap-2'>{files}</ul>
            </div>
          )}
        </section>

        {/* notes section */}
        <TextArea
          className='notes pt-5'
          value={documentDetails.notes}
          onChange={(e: any) => {
            setDocumentDetails({
              ...documentDetails,
              notes: e.target.value,
            })
          }}
          label='Add a Note'
          placeholder=''
          rows={4}
        />

        {/* audit check section */}
        <Toggle
          label='Audit Check'
          className='py-5'
          setValue={documentDetails?.auditCheck}
          onToggleClick={(e: any) => {
            setDocumentDetails({
              ...documentDetails,
              auditCheck: e.target.checked,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

EditVaccination.defaultProps = {}

export default EditVaccination
