import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface activatedCandidateProps {
  candidateId?: any
}

export const useActivatedCandidate = () => {
  const mutate = useMutation(({ candidateId }: activatedCandidateProps) => {
    return postAPI(`/facade/Candidate/${candidateId}/Activate`, {})
  })

  return mutate
}

export default useActivatedCandidate
