/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useDeleteVisaDocument,
  useGetVisaDetailsList,
  useGetWorkRights,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import { CandidateTabTileCard } from 'components/atoms'
import { VisaDocument, VisaInformation } from 'components/Drawers/Candidate'
import { CandidateDocumentsViewModal } from 'components/molecules'
import { VisaAttachmentsCard } from 'components/molecules/VisaAttachmentsCard'

export interface VisaTabViewProps {
  [x: string]: any
}

export const VisaTabView: FC<VisaTabViewProps> = ({
  className,
  candidateId,
  notify,
  getCandidateAuditDocumentIds,
  ...restProps
}: VisaTabViewProps) => {
  const dispatch = useDispatch()
  const { documentCategoriesList } = useSelector((state: any) => state.document)
  const { isAuditModal } = useSelector((state: any) => state.audit)

  const VisaTabViewClasses = CN(`visa-tab-view`, className)

  const [
    showVisaAttachmentsMoreButtonOption,
    setShowVisaAttachmentsMoreButtonOption,
  ] = useState<any>({
    index: -1,
    isEnable: false,
  })
  const [visaInformationDrawerData, setVisaInformationDrawerData] = useState({
    isAddMode: true,
    isShowVisaInformationDrawer: false,
    headerTitle: 'Edit Visa Information',
    headerSubtitle: 'You can edit visa details here',
    primaryButtonTitle: 'Edit Visa Information',
    onDrawerClose: () => {
      setVisaInformationDrawerData({
        ...visaInformationDrawerData,
        isShowVisaInformationDrawer: false,
      })
    },
  })
  const [visaDocumentDrawerData, setVisaDocumentDrawerData] = useState({
    isAddMode: true,
    isShowVisaDocumentDrawer: false,
    headerTitle: 'Add Visa Document',
    headerSubtitle: 'Add new visa documents here',
    primaryButtonTitle: 'Add Document',
    onDrawerClose: () => {
      setVisaDocumentDrawerData({
        ...visaDocumentDrawerData,
        isShowVisaDocumentDrawer: false,
      })
    },
    data: null,
  })
  const [documentViewModalData, setDocumentViewModalData] = useState({
    isShowDocumentViewModal: false,
    signedUrlList: [],
    headerTitle: 'Visa Document',
  })
  const [visaInfoDeleteModalData, setVisaInfoDeleteModalData] = useState({
    isShowVisaInfoDeleteModal: false,
    visaInfoId: '',
    type: '',
  })

  /** API Handling request for Get Visa Documents List */
  const {
    data: visaDocDetails,
    refetch: getVisaDocDetails,
    isLoading: getVisaDocDetailsIsLoading,
    isFetching: getVisaDocDetailsIsFetching,
  } = useGetVisaDetailsList({
    candidateId: candidateId,
  })

  /** API Handling request for Get Candidate Work Rights */
  const {
    data: workRights,
    refetch: getWorkRights,
    isLoading: getWorkRightsIsLoading,
    isFetching: getWorkRightsIsFetching,
  } = useGetWorkRights({
    candidateId: candidateId,
  })

  /** handle get category Id for document type list */
  const handleDocumentTypeList = (type) => {
    const visaDocObj = documentCategoriesList?.find((item) => {
      return item.label === type
    })

    dispatch(setDocumentCategory(visaDocObj))
  }

  /** APi call for the remove existing attachment */
  const {
    mutate: deleteVisaDocumentByIdMutate,
    isLoading: deleteVisaDocumentIsLoading,
  } = useDeleteVisaDocument()

  /** Process delete existing attachment */
  const deleteVisaDocumentById = (visaId: string) => {
    deleteVisaDocumentByIdMutate(
      {
        candidateId,
        visaId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          getVisaDocDetails()
          setVisaInfoDeleteModalData({
            ...visaInfoDeleteModalData,
            isShowVisaInfoDeleteModal: false,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (!isAuditModal?.isOpen) {
      getVisaDocDetails()
    }
  }, [isAuditModal?.isOpen])

  useEffect(() => {
    getCandidateAuditDocumentIds()
  }, [visaDocDetails])

  return (
    <div className={VisaTabViewClasses} {...restProps}>
      <div className='flex'>
        <CandidateTabTileCard
          isLoading={getWorkRightsIsLoading || getWorkRightsIsFetching}
          className='group w-[300px] bg-gradient-to-r from-[#4191DC] to-[#1195C5] mr-5'
          leftElement={
            <div className='flex flex-col'>
              <span className='text-white text-small'>Visa Type</span>
              <span className='text-white font-Bold'>
                {workRights?.workRightsDetails?.name
                  ? workRights?.workRightsDetails?.name
                  : '--'}
              </span>
            </div>
          }
          rightElement={
            <div
              className='absolute top-2 right-2 w-6 h-6 bg-white flex justify-center items-center rounded-lg z-10 cursor-pointer invisible group-hover:visible'
              onClick={() => {
                setVisaInformationDrawerData({
                  ...visaInformationDrawerData,
                  isAddMode: false,
                  isShowVisaInformationDrawer: true,
                  headerTitle: 'Edit Visa Information',
                  headerSubtitle: 'You can edit visa details here',
                  primaryButtonTitle: 'Edit Visa Information',
                })
              }}>
              <i className='ri-pencil-line text-[16px] text-Cobalt-600'></i>
            </div>
          }
          icon={
            <i className='ri-passport-line text-[90px] text-Cobalt-600 opacity-20'></i>
          }
        />
        <CandidateTabTileCard
          isLoading={getWorkRightsIsLoading || getWorkRightsIsFetching}
          className='w-[300px] bg-gradient-to-r from-[#E66962] to-[#DD7F0B]'
          leftElement={
            <div className='flex flex-col'>
              <span className='text-white text-small'>
                Approved Working Hours
              </span>
              <span className='text-white font-Bold'>
                {workRights?.workRightsDetails?.workingHours
                  ? workRights?.workRightsDetails?.workingHours
                  : '--'}
              </span>
            </div>
          }
          icon={
            <i className='ri-time-line text-[90px] text-Yellow-600 opacity-20'></i>
          }
        />
      </div>
      <div className='mt-5'>
        <VisaAttachmentsCard
          isLoading={
            getVisaDocDetailsIsLoading ||
            getVisaDocDetailsIsFetching ||
            deleteVisaDocumentIsLoading
          }
          visaDetails={visaDocDetails?.visaDetails}
          onAddNewClick={() => {
            handleDocumentTypeList('Visa')
            setVisaDocumentDrawerData({
              ...visaDocumentDrawerData,
              isAddMode: true,
              isShowVisaDocumentDrawer: true,
              headerTitle: 'Add Visa Document',
              headerSubtitle: 'Add new visa documents here',
              primaryButtonTitle: 'Add Document',
              data: null,
            })
          }}
          onDeleteClick={(data) => {
            setVisaInfoDeleteModalData({
              isShowVisaInfoDeleteModal: true,
              visaInfoId: data?.visaId,
              type: data?.type,
            })
          }}
          onEditClick={(data) => {
            handleDocumentTypeList('Visa')
            setVisaDocumentDrawerData({
              ...visaDocumentDrawerData,
              isAddMode: false,
              isShowVisaDocumentDrawer: true,
              headerTitle: 'Edit Visa Document',
              headerSubtitle: 'Edit visa documents here',
              primaryButtonTitle: 'Edit Document',
              data: data,
            })
          }}
          onViewAttachmentClick={(data) => {
            setDocumentViewModalData({
              isShowDocumentViewModal: true,
              signedUrlList: data?.attachmentTypeSignedUrls,
              headerTitle: data?.type,
            })
          }}
          showVisaAttachmentsMoreButtonOption={
            showVisaAttachmentsMoreButtonOption
          }
          setShowVisaAttachmentsMoreButtonOption={
            setShowVisaAttachmentsMoreButtonOption
          }
        />
      </div>

      {/* view documents modal */}
      <CandidateDocumentsViewModal
        isActive={documentViewModalData.isShowDocumentViewModal}
        docList={documentViewModalData.signedUrlList}
        headerTitle={documentViewModalData.headerTitle}
        onHeaderCloseButtonClick={() => {
          setDocumentViewModalData({
            ...documentViewModalData,
            isShowDocumentViewModal: false,
            signedUrlList: [],
          })
        }}
        onClickCloseBtn={() => {
          setDocumentViewModalData({
            ...documentViewModalData,
            isShowDocumentViewModal: false,
            signedUrlList: [],
          })
        }}
      />

      {visaInformationDrawerData?.isShowVisaInformationDrawer && (
        <VisaInformation
          getVisaInformation={getWorkRights}
          candidateId={candidateId}
          notify={notify}
          data={workRights?.workRightsDetails}
          isAddMode={visaInformationDrawerData.isAddMode}
          isActive={visaInformationDrawerData.isShowVisaInformationDrawer}
          onDrawerClose={visaInformationDrawerData.onDrawerClose}
          headerTitle={visaInformationDrawerData.headerTitle}
          primaryButtonTitle={visaInformationDrawerData.primaryButtonTitle}
          headerSubtitle={visaInformationDrawerData.headerSubtitle}
        />
      )}
      {visaDocumentDrawerData?.isShowVisaDocumentDrawer && (
        <VisaDocument
          getVisaDocumentList={getVisaDocDetails}
          candidateId={candidateId}
          notify={notify}
          data={visaDocumentDrawerData.data}
          isAddMode={visaDocumentDrawerData.isAddMode}
          isActive={visaDocumentDrawerData.isShowVisaDocumentDrawer}
          onDrawerClose={visaDocumentDrawerData.onDrawerClose}
          headerTitle={visaDocumentDrawerData.headerTitle}
          primaryButtonTitle={visaDocumentDrawerData.primaryButtonTitle}
          headerSubtitle={visaDocumentDrawerData.headerSubtitle}
        />
      )}
      {/** visa document delete confirmation Modal */}
      <Modal
        isActive={visaInfoDeleteModalData.isShowVisaInfoDeleteModal}
        onClickPrimaryBtn={() => {
          deleteVisaDocumentById(visaInfoDeleteModalData.visaInfoId)
        }}
        onClickSecondaryBtn={() => {
          setVisaInfoDeleteModalData({
            ...visaInfoDeleteModalData,
            isShowVisaInfoDeleteModal: false,
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteVisaDocumentIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setVisaInfoDeleteModalData({
            ...visaInfoDeleteModalData,
            isShowVisaInfoDeleteModal: false,
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete {visaInfoDeleteModalData.type}?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

VisaTabView.defaultProps = {}

export default VisaTabView
