import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TeamCard, Toast } from '@labourhub/labour-hub-ds'
import { useGetUserRoles } from 'framework/api/methods'

export interface UserGroupsProps {
  [x: string]: any
}

export const UserGroups: FC<UserGroupsProps> = ({
  ...restProps
}: UserGroupsProps) => {
  const notify = (props: any) => Toast(props)
  const [userGroupDetails, setUserGroupDetails] = useState([])

  const navigate = useNavigate()

  /** API Call for get user roles */
  const { mutate: userRolesMutate } = useGetUserRoles()

  /** Process the get User roles */
  async function getUserRoles() {
    userRolesMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setUserGroupDetails(successData?.roleDetails)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'API Data Fetch Error',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    getUserRoles()
  }, [])

  return (
    <div className='user-groups' {...restProps}>
      {/** Team cards */}
      <div className='flex flex-wrap'>
        {userGroupDetails.map((itm: any, idx: any) => (
          <div
            id={`${idx}-${itm.roleName}s`}
            key={idx}
            className='flex w-1/4 mb-6'>
            {/** Team Card Component */}
            <TeamCard
              avatarList={itm.userList.map((user: any) => {
                return {
                  ...user,
                  title: `${user.firstName} ${user.lastName}`,
                  imageUrl: user?.avatar ? user?.avatar : undefined,
                }
              })}
              memberTitle={`${itm.roleName}s`}
              memberCount={itm.userCount}
              avatarRestProps={{ isIcon: false }}
              text={` You can see all ${itm.roleName} members here. `}
              onViewAllClick={() => {
                navigate(
                  `/user-groups/user-list?user_role=${itm.roleName}&role_id=${itm.roleId}`,
                )
              }}
              style={{ backgroundColor: '#ffffff' }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

UserGroups.defaultProps = {}

export default UserGroups
