import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAssignUserInterface {
  searchValue?: any
}

export const useGetAssignUser = () => {
  const mutate = useMutation(({ searchValue }: GetAssignUserInterface) => {
    return get(`/UserProfile/ListByFilters?Search=${searchValue}`)
  })

  return mutate
}

export default useGetAssignUser
