import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetUserProfileList } from 'framework/api/methods'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'

import { UITable } from 'components/common/UITable'
import { AssignNewUser, InviteNewUser } from 'components/Drawers/Users'

import { UserListColumn } from './UserListColumn'

export interface UserListProps {
  [x: string]: any
}

export const UserList: FC<UserListProps> = ({
  className,
  ...restProps
}: UserListProps) => {
  const [addUserModalShow, setAddUserModalShow] = useState(false)
  const [inviteUserModalShow, setInviteUserModalShow] = useState(false)
  const [tableData, setTableData] = useState<any>({})
  const [userTableColumn, setUserTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)

  const Location: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  /** Start BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */
  const { menuItems } = useSelector((state: any) => state.sideMenuList)
  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [Location, menuItems])
  /** End BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  /** Get Product Employee List */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  const onError = () => {
    notify({
      alertHeader: 'Oops...!',
      alertBody: 'Something Went Wrong. Please Try Again!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All Uniform List */
  const {
    refetch: getTableData,
    isLoading: getUserProfileListIsLoading,
    isFetching: getUserProfileListIsFetching,
  } = useGetUserProfileList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      roleName: query.get('user_role'),
      search: paginationVariables.search,
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  /** Cart Table Column Handling */
  useEffect(() => {
    if (tableData?.userProfiles && tableData?.userProfiles?.length > 0) {
      const columns: any = UserListColumn(navigate)
      setUserTableColumn(columns)
    }
  }, [tableData])

  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      searchKeyWord &&
        setPaginationVariables({
          ...paginationVariables,
          skip: 0,
          search: searchKeyWord,
        })
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  const UserListClasses = CN('user-list w-full h-full', className, {})

  return (
    <div className={UserListClasses} {...restProps}>
      <span className='font-Medium text-heading-4 text-Gray-800 pb-2'>
        {Location?.state?.roleName}
      </span>
      <div className='flex items-center justify-between py-2 px-3 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <div className='pl-[13px] w-2/3 relative'>
          <input
            onKeyUp={(e: any) => {
              if (e.keyCode == 13) {
                setPaginationVariables({
                  ...paginationVariables,
                  search: searchKeyWord,
                })
              }
              if (searchKeyWord.length === 0) {
                setPaginationVariables({
                  ...paginationVariables,
                  search: searchKeyWord,
                })
              }
            }}
            onChange={(e: any) => {
              setSearchKeyWord(e.target.value)
            }}
            type='text'
            className='w-full font-Regular py-[6px] pl-10 text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50'
            placeholder='Search user with name or email'
          />
          <i className='absolute left-[26px] top-[5px] ri-search-line text-Gray-400'></i>
        </div>
        <div className='flex items-center'>
          <>
            <Button
              isDisabled={false}
              className='ml-2'
              onClick={() => setInviteUserModalShow(true)}
              size='sm'
              textTheme='blue'
              iconLeft={<i className='ri-user-shared-line' />}
              theme='white'>
              Invite new user
            </Button>
            <Button
              className='ml-2'
              onClick={() => setAddUserModalShow(true)}
              size='sm'
              textTheme='white'
              iconLeft={<i className='ri-user-shared-line' />}
              theme='gradient'>
              Assign user
            </Button>
          </>
        </div>
      </div>
      <div className='w-full h-[calc(90vh-100px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.userProfiles || []}
            isLoading={
              getUserProfileListIsLoading || getUserProfileListIsFetching
            }
            className=''
            columns={userTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {/** Assign new user drawer modal */}
      <AssignNewUser
        notify={notify}
        addUserModalShow={addUserModalShow}
        setAddUserModalShow={setAddUserModalShow}
        onClickInviteNewUser={() => {
          setAddUserModalShow(false)
          setInviteUserModalShow(true)
        }}
        headerTitle='Add Users'
        headerSubtitle='Here you can search and add internal users.'
        isButtonDisabled
        buttonTitle='Add as a new user'
        headerCloseButton={() => {
          setAddUserModalShow(false)
        }}
        footerCancelButton={() => {
          setAddUserModalShow(false)
        }}
        roleName={query.get('user_role')}
        roleId={query.get('role_id')}
        getTableData={getTableData}
      />

      {/** Invite new user drawer modal */}
      <InviteNewUser
        notify={notify}
        inviteUserModalShow={inviteUserModalShow}
        setInviteUserModalShow={setInviteUserModalShow}
        headerTitle='Invite User'
        headerSubtitle='Here you can Invite new users.'
        isButtonDisabled
        buttonTitle='Invite'
        roleId={query.get('role_id')}
        onOverlayClick={() => {
          setInviteUserModalShow(false)
        }}
        headerCloseButton={() => {
          setInviteUserModalShow(false)
        }}
        footerCancelButton={() => {
          setInviteUserModalShow(false)
        }}
        getTableData={getTableData}
      />
    </div>
  )
}

UserList.defaultProps = {}

export default UserList
