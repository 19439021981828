import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getVisaDetailsList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`facade/Candidate/${reqBody.candidateId}/Visa`)
  return res.data
}

export const useGetVisaDetailsList = ({ ...reqBody }) => {
  return useQuery(['VisaDetailsList', reqBody], getVisaDetailsList, {
    enabled: true,
    refetchOnWindowFocus: false,
  })
}
