import React, { FC } from 'react'
import emptyStateVector from 'assets/images/emptyStateVector.svg'
import CN from 'classnames'

export interface DashboardTabViewProps {
  [x: string]: any
}

export const DashboardTabView: FC<DashboardTabViewProps> = ({
  className,
  ...restProps
}: DashboardTabViewProps) => {
  const DashboardTabViewClasses = CN(
    `dashboard-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={DashboardTabViewClasses} {...restProps}>
      {/* dashboard header */}
      <span className='text-heading-5 text-Gray-800 font-Medium'>
        Client Dashboard
      </span>

      {/* dashboard sub header */}
      <span className='text-small text-Gray-500 font-Regular'>
        At a glance statistics about the client
      </span>

      {/* dashboard card */}
      <div className='w-full flex flex-col justify-center items-center rounded-md bg-white border border-Gray-200 pt-9 pb-[35px] mt-8'>
        <img src={emptyStateVector} alt='empty_state_image' />

        <span className='text-heading-4 text-Gray-800 font-Medium pt-10'>
          Dashboard is not available
        </span>

        <span className='text-Gray-600 font-Regular pt-4'>
          Dashboard statistics will be enabled once the client is signed
        </span>
      </div>
    </div>
  )
}

DashboardTabView.defaultProps = {}

export default DashboardTabView
