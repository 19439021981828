import React, { FC, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Avatar, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface ClientProfileLocationCardProps {
  [x: string]: any
}

export const ClientProfileLocationCard: FC<ClientProfileLocationCardProps> = ({
  className,
  clientLocationsItem,
  onEditLocationCLick,
  notify,
  setOpenTab,
  ...restProps
}: ClientProfileLocationCardProps) => {
  const [locationLink, setLocationLink] = useState('')

  /** set location link when check with latitude and longitude values availability */
  useEffect(() => {
    if (
      clientLocationsItem?.address?.latitude !== 0 &&
      clientLocationsItem?.address?.longitude !== 0
    ) {
      setLocationLink(
        `https://maps.googleapis.com/maps/api/staticmap?center=${clientLocationsItem?.address?.latitude},${clientLocationsItem?.address?.longitude}&zoom=12&size=1440x168&maptype=roadmap&markers=color:red%7Clabel:%7C${clientLocationsItem?.address?.latitude},${clientLocationsItem?.address?.longitude}&key=AIzaSyBlZPDoSassxMdBKJcEHOSzp-7gf3XJS2Q`,
      )
    } else {
      setLocationLink(
        `https://maps.googleapis.com/maps/api/staticmap?center=${clientLocationsItem?.address?.lineOne},${clientLocationsItem?.address?.city},${clientLocationsItem?.address?.state},${clientLocationsItem?.address?.country}&zoom=12&size=1440x168&maptype=roadmap&markers=color:red%7Clabel:%7C${clientLocationsItem?.address?.lineOne},${clientLocationsItem?.address?.city},${clientLocationsItem?.address?.state},${clientLocationsItem?.address?.country}&key=AIzaSyBlZPDoSassxMdBKJcEHOSzp-7gf3XJS2Q`,
      )
    }
  }, [clientLocationsItem])

  const ClientProfileLocationCardClasses = CN(
    `client-profile-location-card flex flex-col w-full rounded-md bg-white border border-Gray-200`,
    className,
  )

  return (
    <div className={ClientProfileLocationCardClasses} {...restProps}>
      {/* map section */}
      <div className='card-map-viewer flex w-full h-[168px] overflow-hidden rounded-t-md '>
        <img src={locationLink} alt='g-map' className='w-full object-cover' />
      </div>

      {/* detail section */}
      <div className='flex flex-col py-[15px] px-5'>
        <div className='w-full flex justify-between items-center'>
          <span
            className='text-Gray-900 font-Bold line-clamp-1'
            data-tip={clientLocationsItem?.name}
            data-for='location-name'>
            {clientLocationsItem?.name}
          </span>

          <ReactTooltip
            id='location-name'
            place='top'
            effect='solid'
            textColor='#E5E7EB'
            backgroundColor='#1F2937'
          />

          <Button
            onClick={onEditLocationCLick}
            size='sm'
            textTheme='blue'
            theme='white'>
            Edit
          </Button>
        </div>

        {clientLocationsItem?.address && (
          <span className='text-Gray-800 font-Regular'>
            {clientLocationsItem?.address?.lineOne &&
              `${clientLocationsItem?.address?.lineOne}, `}

            {clientLocationsItem?.address?.city &&
              `${clientLocationsItem?.address?.city}, `}

            {clientLocationsItem?.address?.state &&
              `${clientLocationsItem?.address?.state}, `}

            {clientLocationsItem?.address?.postCode &&
              `${clientLocationsItem?.address?.postCode}, `}

            {clientLocationsItem?.address?.country &&
              `${clientLocationsItem?.address?.country}.`}
          </span>
        )}

        <div className='flex pt-2 pb-4'>
          <div className='phone-number-field flex text-Cobalt-500'>
            <i className='ri-phone-line text-[14px] mr-[9px]' />
            {clientLocationsItem?.phoneNo ? (
              <a
                href={`tel:${clientLocationsItem?.phoneNo}`}
                className='text-small font-Medium'>
                {clientLocationsItem?.phoneNo}
              </a>
            ) : (
              `-`
            )}
          </div>

          <div className='email-field flex text-Cobalt-500 ml-8'>
            <i className='ri-mail-line text-[14px] mr-[9px]' />

            {clientLocationsItem?.email ? (
              <a
                href={`mailto:${clientLocationsItem?.email}`}
                className='text-small font-Medium'>
                {clientLocationsItem?.email}
              </a>
            ) : (
              `-`
            )}
          </div>
        </div>
        <div className='w-full flex justify-between items-center border-t border-t-Gray-200 pt-4'>
          {clientLocationsItem?.primaryContactId ? (
            <Avatar
              size='sm'
              title={`${clientLocationsItem?.firstName} ${clientLocationsItem?.lastName}`}
              tag={`Primary Consultant: ${
                clientLocationsItem?.consultantName
                  ? clientLocationsItem?.consultantName
                  : 'N/A'
              }`}
              imageURL={
                clientLocationsItem?.avatarUrl
                  ? clientLocationsItem?.avatarUrl
                  : undefined
              }
              className='cursor-pointer'
              onClick={() => {
                setOpenTab(3)
              }}
            />
          ) : (
            <span className='text-small text-Gray-500 font-Regular'>
              No Primary Contact
            </span>
          )}

          <Button
            onClick={() => {
              notify({
                alertHeader: 'working in progress...',
                status: 'Warning',
              })
            }}
            size='sm'
            textTheme='blue'
            theme='white'>
            View Signed Rates
          </Button>
        </div>
      </div>
    </div>
  )
}

ClientProfileLocationCard.defaultProps = {}

export default ClientProfileLocationCard
