import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  RightSideModal,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'

export interface EditQuestionaryBuilderProps {
  [x: string]: any
}

export const EditQuestionaryBuilder: FC<EditQuestionaryBuilderProps> = ({
  isActive,
  setIsEditDrawerOpen,
  headerTitle,
  questionTitle,
  isRequiredUpdated,
  onChange,
  optionList,
  starCount,
  ...restProps
}: EditQuestionaryBuilderProps) => {
  const [questionTitleTemp, setQuestionTitleTemp] =
    useState<string>(questionTitle)
  const [isRequiredUpdatedTemp, setIsRequiredUpdatedTemp] =
    useState(isRequiredUpdated)
  const [options, setOptions] = useState<any>(
    optionList || { label: 'Option 1' },
  )
  const [disabledButton, setDisabledButton] = useState<boolean>(true)
  const [starCountTemp, setStarCountTemp] = useState<number>(starCount)

  const handleChange = (i, e) => {
    // eslint-disable-next-line prefer-const
    let formValues = [...options]
    formValues[i].label = e.target.value
    setOptions(formValues)
    options.some((options) => options.label === '')
      ? setDisabledButton(true)
      : setDisabledButton(false)
  }

  const addFormFields = () => {
    setOptions([...options, { label: '' }])
    setDisabledButton(true)
  }

  const removeFormFields = (i) => {
    const formValues = [...options]
    formValues.splice(i, 1)
    setOptions(formValues)
  }

  const handleModal = () => {
    setIsEditDrawerOpen(false)
  }

  const handleCancelModal = () => {
    if (!questionTitleTemp || questionTitle !== questionTitleTemp)
      setQuestionTitleTemp(questionTitle)
    setIsEditDrawerOpen(false)
  }

  useEffect(() => {
    questionTitleTemp === ''
      ? setDisabledButton(true)
      : setDisabledButton(false)

    if (headerTitle === 'Rating' && (starCountTemp < 1 || starCountTemp > 10)) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [questionTitleTemp, options, starCountTemp])

  useEffect(() => {
    const valueArr = options.map((option: any) => option.label.toLowerCase())
    const isDuplicate = valueArr.some((item: any, idx: number) => {
      return valueArr.indexOf(item) != idx
    })
    const isEmptyExist = valueArr.some((item: string) => {
      return item === ''
    })

    if (isDuplicate || isEmptyExist) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }, [options])

  return (
    <RightSideModal
      {...restProps}
      isActive={isActive}
      headerTitle={`Edit ${headerTitle}`}
      headerSubtitle={'Change your question title and other properties'}
      primaryButtonTitle={'Add'}
      isPrimaryButtonDisable={disabledButton}
      onHeaderCloseButtonClick={() => handleCancelModal()}
      onFooterAddButtonClick={() => {
        onChange &&
          onChange({
            title: questionTitleTemp,
            isRequiredUpdated: isRequiredUpdatedTemp,
            options: options,
            starCount: starCountTemp,
            headerTitle: headerTitle,
          })
        handleModal()
      }}
      onFooterCancelButtonClick={() => handleCancelModal()}>
      <div className='flex flex-col w-full px-6 py-5 h-fit gap-y-5'>
        {/* title section */}
        <div>
          <span className="text-Gray-800 font-Medium after:content-['*'] after:ml-0.5 after:text-TerraCotta-400">
            Question Title
          </span>
          <TextArea
            className='w-full'
            value={questionTitleTemp}
            onChange={(e: any) => {
              setQuestionTitleTemp(e.target.value)
            }}
            placeholder=''
            rows={4}
          />
        </div>
        <div>
          <Checkbox
            labelText='Required'
            checked={isRequiredUpdatedTemp}
            labelTextClassName='font-Regular'
            checkboxClassName='ml-0'
            onChange={(e: any) => {
              setIsRequiredUpdatedTemp(e.target.checked)
            }}
          />
        </div>
        {/** options */}
        {(headerTitle === 'Multi Choice' || headerTitle === 'Select Box') && (
          <div className='flex flex-col gap-y-5'>
            <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
              <span>Options</span>
            </div>
            <div className='flex flex-col w-full gap-y-3'>
              {options?.map((option: any, idx: number) => {
                return (
                  <div className='flex items-center gap-x-3' key={idx}>
                    <TextField
                      value={option.label}
                      onChange={(e: any) => handleChange(idx, e)}
                    />
                    {idx !== 0 && (
                      <Button
                        view='outline'
                        theme='terracotta'
                        isIconOnly
                        size='sm'
                        className='h-fit'
                        onClick={() => removeFormFields(idx)}
                        icon={
                          <i className='ri-delete-bin-line text-TerraCotta-500'></i>
                        }></Button>
                    )}
                  </div>
                )
              })}

              <Button
                className='w-fit'
                theme='link'
                textTheme='blue'
                iconLeft={<i className='ri-add-line'></i>}
                onClick={() => addFormFields()}>
                Add Option
              </Button>
            </div>
          </div>
        )}
        {headerTitle === 'Rating' && (
          <div className='flex flex-col gap-y-5'>
            <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
              <span>Star Count</span>
            </div>
            <div className='flex flex-col w-full gap-y-3'>
              <TextField
                value={starCountTemp}
                placeholder='Max star count 10'
                onChange={(e: any) => setStarCountTemp(e.target.value)}
                type='number'
              />
            </div>
          </div>
        )}
      </div>
    </RightSideModal>
  )
}

EditQuestionaryBuilder.defaultProps = {}

export default EditQuestionaryBuilder
