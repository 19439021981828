/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Avatar, Badge } from '@labourhub/labour-hub-ds'

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'
import { dateToReadableString } from 'utils/dateToReadableString'
import { timeDifference } from 'utils/timeDifference'

export const UserListColumn = (navigate: any) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'displayName',
      forSorting: 'firstname',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[650px]',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Avatar
              imageURL={original?.avatarUrl ? original?.avatarUrl : undefined}
              titleClassName='max-w-[200px] line-clamp-1'
              size='xs'
              title={`${original?.firstName} ${original?.lastName}`}
              tag={original?.email}
            />
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'isactive',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[140px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const { status } = original || {}

        let theme
        switch (status) {
          case 'DEACTIVE':
            theme = 'red'
            break
          case 'ACTIVE':
            theme = 'green'
            break
          case 'PENDING':
            theme = 'yellow'
            break

          default:
            theme = 'green'
            break
        }

        return (
          <div>
            <Badge size='large' theme={theme}>
              {capitalizeFirstLetter(original?.status)}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'DATE ADDED',
      accessor: 'createdOn',
      forSorting: 'createdon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{dateToReadableString(original?.createdOn)}</div>
      },
    },
    {
      Header: 'LAST LOGGED IN',
      accessor: 'updatedOn',
      forSorting: 'updatedon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[130px]',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{timeDifference(new Date(), original?.updatedOn)}</div>
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[64px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            onClick={() => {
              navigate(`user-permission?userId=${original?.id}`)
            }}>
            <i
              className='ri-lock-2-line text-heading-5 text-Gray-500 cursor-pointer'
              data-tip='Permission'
              data-for='Permission'
            />
            <ReactTooltip
              place='top'
              id='Permission'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
            />
          </div>
        )
      },
    },
  ]

  return COLUMNS
}

export default UserListColumn
