import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddSuperFundInterface {
  candidateId: string
  superFundDetails: any
  attachments: any
}

export const useAddSuperFund = () => {
  const mutate = useMutation(
    ({ candidateId, superFundDetails, attachments }: AddSuperFundInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('ProviderId', superFundDetails?.superProvider)
      bodyFormData.append('SuperId', superFundDetails?.superName)
      bodyFormData.append('MembershipNo', superFundDetails?.membershipNo)
      bodyFormData.append('Abn', superFundDetails?.superABN)
      bodyFormData.append('Usi', superFundDetails?.superUSI)
      bodyFormData.append('CategoryId', superFundDetails?.categoryId)
      bodyFormData.append('TypeId', superFundDetails?.typeId)
      bodyFormData.append('AuditRequired', superFundDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachment', item)
      })

      return postAPIwithFormData(
        `/facade/Candidate/${candidateId}/SuperFund`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddSuperFund
