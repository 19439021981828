/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { RightSideModal } from '@labourhub/labour-hub-ds'
import { useGetDocumentHistoryById } from 'framework/api/methods'

import { DocumentHistoryDetailCard, PageLoader } from 'components/atoms'

export interface ViewDocumentHistoryProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const ViewDocumentHistory: FC<ViewDocumentHistoryProps> = ({
  isActive,
  headerSubtitle,
  setIsShowDocumentHistoryDrawer,
  selectedDocumentId,
  ...restProps
}: ViewDocumentHistoryProps) => {
  const [historyDetails, setHistoryDetails] = useState<any>([])

  /** API Handling request for Get selected document history */
  const {
    mutate: getDocumentHistoryByIdMutate,
    isLoading: getDocumentHistoryByIdIsLoading,
  } = useGetDocumentHistoryById()

  async function getDocumentHistoryById() {
    getDocumentHistoryByIdMutate(
      {
        documentId: selectedDocumentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setHistoryDetails(successData?.documentHistory)
        },
      },
    )
  }

  useEffect(() => {
    selectedDocumentId && getDocumentHistoryById()
  }, [selectedDocumentId, isActive])

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle='Document History'
      isFooterShow={false}
      headerSubtitle={headerSubtitle}
      onHeaderCloseButtonClick={() => {
        setIsShowDocumentHistoryDrawer(false)
      }}
      onOverlayClick={() => {
        setIsShowDocumentHistoryDrawer(false)
      }}
      {...restProps}>
      {getDocumentHistoryByIdIsLoading && <PageLoader size='xxs' />}

      {/* main containers */}
      <div className='flex w-full flex-col h-full pl-5 pt-5'>
        {/* header color eclipse section */}
        <div className='flex w-full gap-x-8'>
          {/* consultant eclipse section */}
          <div className='flex justify-center items-center'>
            <div className='eclipse h-[10px] w-[10px] rounded-full bg-Green-500'></div>
            <span className='flex text-extra-small text-Gray-500 font-Medium ml-1'>
              Consultant
            </span>
          </div>

          {/* candidate eclipse section */}
          <div className='flex justify-center items-center'>
            <div className='eclipse h-[10px] w-[10px] rounded-full bg-Cobalt-500'></div>
            <span className='flex text-extra-small text-Gray-500 font-Medium ml-1'>
              Candidate
            </span>
          </div>

          {/* Admin/Manager eclipse section */}
          <div className='flex justify-center items-center'>
            <div className='eclipse h-[10px] w-[10px] rounded-full bg-Gray-500'></div>
            <span className='flex text-extra-small text-Gray-500 font-Medium ml-1'>
              Admin/Manager
            </span>
          </div>

          {/* system eclipse section */}
          <div className='flex justify-center items-center'>
            <div className='eclipse h-[10px] w-[10px] rounded-full bg-Yellow-500'></div>
            <span className='flex text-extra-small text-Gray-500 font-Medium ml-1'>
              System
            </span>
          </div>
        </div>

        {/* history details section */}
        <div className='flex flex-col mt-5 pr-[92px] overflow-y-auto styled-scroll'>
          {!getDocumentHistoryByIdIsLoading &&
            historyDetails?.map((item: any, idx: any) => (
              <div
                key={idx}
                className='flex w-full history-detail-card relative mb-1'>
                {/* detail card */}
                <DocumentHistoryDetailCard cardItem={item} />

                {/* vertical line */}
                {historyDetails.length - 1 !== idx && (
                  <div className='absolute left-[14px] z-[1] w-[1px] h-[calc(100%-34px)] mt-8 bg-Gray-200'></div>
                )}
              </div>
            ))}
        </div>
      </div>
    </RightSideModal>
  )
}

ViewDocumentHistory.defaultProps = {}

export default ViewDocumentHistory
