import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'
import { TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, isValid, parse } from 'date-fns'

import './DatePickerWithInput.scss'

export interface DatePickerWithInputProps {
  isCustomStyles?: boolean
  onChange?: (e: any) => void | undefined
  selectedDate?: string | undefined
  dateFormat?: string | undefined
  disableFutureDates?: boolean | undefined
  disablePastDates?: boolean | undefined
  [x: string]: any
}

export const DatePickerWithInput: FC<DatePickerWithInputProps> = ({
  className,
  disableFutureDates,
  disablePastDates,
  isCustomStyles,
  selectedDate,
  dateFormat = 'y-MM-dd',
  onChange,
  value,
  ...restProps
}: DatePickerWithInputProps) => {
  const [selected, setSelected] = useState<Date>()
  const [inputValue, setInputValue] = useState<any>()
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  )

  /** date picker main styles */
  const datePickerClassName = !isCustomStyles
    ? CN('datePicker-parentClass', className)
    : className

  /** popper main styles */
  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  })

  /** handle close popper */
  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }

  /** handle input change */
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value)
  }

  /** useEffect to set selected date with verify date */
  useEffect(() => {
    const date = parse(inputValue, dateFormat, new Date())
    if (isValid(date)) {
      setSelected(date)
    } else {
      setSelected(undefined)
    }
  }, [inputValue])

  useEffect(() => {
    if (selectedDate) {
      setInputValue(selectedDate)
    }
  }, [selectedDate])

  useEffect(() => {
    value && setInputValue(value)
  }, [value])

  /** handle button click */
  const handleButtonClick = () => {
    setIsPopperOpen(!isPopperOpen)
  }

  /** handle day select */
  const handleDaySelect = (date: Date) => {
    setSelected(date)
    if (date) {
      setInputValue(format(date, dateFormat))
      closePopper()
    } else {
      setInputValue('')
    }
  }

  /** handling date format on change  */
  useEffect(() => {
    onChange && selected && onChange(format(selected, dateFormat))
    onChange && selected === undefined && onChange(undefined)
  }, [selected])

  /** set disable days after and before today */
  const disabledDays =
    (disableFutureDates && [{ after: new Date() }]) ||
    (disablePastDates && [{ before: new Date() }]) ||
    []

  return (
    <div className={datePickerClassName} {...restProps}>
      <div ref={popperRef} className='relative'>
        <TextField
          placeholder={format(new Date(), dateFormat)}
          onChange={handleInputChange}
          value={inputValue}
          onClick={handleButtonClick}
          iconAfter={
            <i className='ri-calendar-line text-[16px] text-Gray-500' />
          }
          iconAfterOnClick={handleButtonClick}
        />
      </div>

      {isPopperOpen && (
        <div
          tabIndex={-1}
          style={popper.styles.popper}
          className='dialog-sheet bg-white left-[20px] rounded-[3px] shadow-lg shadow-Gray-200 m-1 z-[1]'
          {...popper.attributes.popper}
          ref={setPopperElement}
          role='dialog'>
          <DayPicker
            captionLayout='dropdown'
            disabled={disabledDays}
            initialFocus={isPopperOpen}
            mode='single'
            defaultMonth={selected}
            selected={selected}
            onSelect={(date: any) => {
              handleDaySelect(date)
            }}
          />
        </div>
      )}
    </div>
  )
}

DatePickerWithInput.defaultProps = {
  disableFutureDates: false,
  disablePastDates: false,
  dateFormat: 'y-MM-dd',
}

export default DatePickerWithInput
