/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface CandidateMedicalConditionCardProps {
  className?: string | undefined
  note?: string | undefined
  onAddNewClick?: any | undefined
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  onViewClick?: any | undefined
  title?: string | undefined
  uploadedDate?: string | undefined
  [x: string]: any
}

export const CandidateMedicalConditionCard: FC<
  CandidateMedicalConditionCardProps
> = ({
  className,
  getDocumentSignedUrlsById,
  getMedicalConditionListIsLoading,
  medicalConditionList,
  onAddNewClick,
  onDeleteClick,
  onEditClick,
  onViewClick,
  setSelectedDocumentDetails,
  setShowIncidentMoreButtonOption,
  setShowMoreButtonOption,
  setShowVaccineMoreButtonOption,
  showMoreButtonOption,
  ...restProps
}: CandidateMedicalConditionCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  const PrescriptionCardClasses = CN(
    `w-full h-fit border-[1px] bg-white border-Gray-200 rounded-md pb-9 relative`,
    className,
  )
  return (
    <div className={PrescriptionCardClasses} {...restProps}>
      {getMedicalConditionListIsLoading && <PageLoader size='xxs' />}
      <div className='flex justify-between items-center pt-[19px] pl-5 pr-4'>
        <span className='font-Bold text-heading-5 text-Gray-800 w-2/3'>
          Prescriptions & Medical Conditions
        </span>

        <Button
          onClick={onAddNewClick}
          textTheme='blue'
          size='sm'
          theme='white'>
          Add New
        </Button>
      </div>

      <div className='flex flex-col'>
        {medicalConditionList?.length > 0 &&
          medicalConditionList?.map((itm: any, index: any) => (
            <div
              className='flex justify-between items-start px-6 pt-4'
              key={index}>
              <div className='flex'>
                <i className='ri-attachment-2 flex text-[20px] w-10 h-10 text-Cobalt-200 bg-Cobalt-50 justify-center items-center p-2 rounded-full'></i>

                <div className='pl-[22px]'>
                  <div className='flex flex-col'>
                    <div className='flex'>
                      {/** Prescription title */}
                      <span className='font-SemiBold text-Gray-800 max-w-[300px] line-clamp-1'>
                        {itm?.type}
                      </span>

                      {/** Status indicate icons */}
                      <div className='flex items-center ml-2'>
                        {/* medical shield when is working affected */}
                        {itm?.isWorkingAffected && (
                          <i className='ri-shield-cross-fill w-5 h-5 flex justify-center items-center text-[16px] text-Cobalt-600 p-1 mr-2' />
                        )}

                        {itm?.status && (
                          <DocumentStatusIcon
                            status={itm?.status}
                            auditRequired={itm?.auditRequired}
                          />
                        )}
                      </div>
                    </div>

                    {/** note, if exists */}
                    {itm?.isWorkingAffected && (
                      <Badge className='mt-2'>Working ability affected</Badge>
                    )}

                    {/** uploaded date  */}
                    <span className='font-Medium text-extra-small text-Gray-500 pt-1'>
                      Uploaded:{' '}
                      {itm?.updatedOn
                        ? dateToReadableString(itm?.updatedOn)
                        : `N/A`}
                    </span>
                  </div>
                </div>
              </div>

              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()

                    setShowVaccineMoreButtonOption({
                      index: index,
                    })

                    setShowIncidentMoreButtonOption({
                      index: index,
                    })

                    setShowMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showMoreButtonOption.index
                          ? !showMoreButtonOption.isEnable
                          : true,
                    })

                    /** set selected item details to state for edit and delete*/
                    setSelectedDocumentDetails(itm)
                  }}
                />

                {/** More button drop down options */}
                {showMoreButtonOption.isEnable &&
                  index === showMoreButtonOption.index && (
                    <div className='flex flex-col absolute right-[0px] top-[42px] w-[169px] py-1 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40'>
                      <span
                        className={CN('font-Regular text-Gray-800 pl-4 py-2', {
                          'opacity-50 hover:bg-Gray-50':
                            itm?.attachmentSignedUrls?.length === 0,
                          'hover:bg-Gray-50':
                            itm?.attachmentSignedUrls?.length > 0,
                        })}
                        onClick={(e: any) => {
                          if (itm?.attachmentSignedUrls?.length > 0) {
                            e.stopPropagation()
                            setShowMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })
                            getDocumentSignedUrlsById(itm?.documentId)
                            onViewClick && onViewClick()
                          }
                        }}>
                        View Attachments
                      </span>
                      <span
                        className='font-Regular text-Gray-800 pl-4 py-2 hover:bg-Gray-50'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          onEditClick && onEditClick()
                          setShowMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Edit
                      </span>
                      <span
                        className={CN('font-Regular text-Gray-800 pl-4 py-2', {
                          'hover:bg-Gray-100':
                            (itm?.auditRequired &&
                              itm?.status === 'Request_Submission') ||
                            itm?.status === 'Pending_Audit',

                          'opacity-50 hover:bg-Gray-white':
                            !itm?.auditRequired ||
                            itm?.status === 'Active' ||
                            itm?.status === 'Audit_Failed' ||
                            itm?.status === 'Expired' ||
                            itm?.status === 'Archived' ||
                            (!itm?.auditRequired &&
                              itm?.status === 'Request_Submission'),
                        })}
                        onClick={(e: any) => {
                          if (
                            (itm?.auditRequired &&
                              itm?.status === 'Request_Submission') ||
                            itm?.status === 'Pending_Audit'
                          ) {
                            e.stopPropagation()

                            setShowMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })

                            dispatch(
                              setSelectedDocumentId(itm?.medicalConditionId),
                            )

                            /** selected candidate states set to store */
                            dispatch(
                              setSelectedCandidate({
                                name: candidateName,
                                id: candidateId,
                              }),
                            )

                            /** audit check modal open handle from state */
                            dispatch(
                              setIsAuditModal({
                                isOpen: true,
                                isFooterShow: false,
                                isActiveButton: false,
                                tab: null,
                              }),
                            )
                          }
                        }}>
                        Perform Audit
                      </span>

                      <span
                        className='font-Regular text-Red-500 pl-4 py-2 hover:bg-Gray-50'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          onDeleteClick && onDeleteClick()
                          setShowMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}

        {medicalConditionList?.length <= 0 && (
          <div className='flex flex-col justify-center items-center'>
            <i className='ri-file-search-line text-[100px] text-Gray-300'></i>

            <span className='font-Medium text-heading-3 text-Gray-500'>
              Not Documents Found.
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

CandidateMedicalConditionCard.defaultProps = {
  className: undefined,
  onAddNewClick: undefined,
  onDeleteClick: undefined,
  onViewClick: undefined,
  onEditClick: undefined,
}

export default CandidateMedicalConditionCard
