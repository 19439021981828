import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useDeleteCandidateDocument,
  useGetCandidateEducationListById,
  useGetDocumentSignedUrlsById,
} from 'framework/api/methods'
import { setDocumentCategory } from 'store/reducers/documents/documentSlice'

import {
  AddNewQualificationTabDocuments,
  EditQualificationTabDocuments,
} from 'components/Drawers/Candidate'
import {
  CandidateDocumentsViewModal,
  CandidateEducationCard,
  CandidateLicenseAndCertificationsCard,
} from 'components/molecules'

export interface QualificationTabViewProps {
  [x: string]: any
}

export const QualificationTabView: FC<QualificationTabViewProps> = ({
  candidateId,
  className,
  notify,
  getCandidateAuditDocumentIds,
  ...restProps
}: QualificationTabViewProps) => {
  const dispatch = useDispatch()

  const { isAuditModal } = useSelector((state: any) => state.audit)
  const { documentCategoriesList } = useSelector((state: any) => state.document)

  const [selectedDocumentDetails, setSelectedDocumentDetails] = useState<any>()

  /** add and edit document states */
  const [showAddNewDocumentDrawer, setShowAddNewDocumentDrawer] =
    useState(false)
  const [showEditDocumentDrawer, setShowEditDocumentDrawer] = useState(false)
  const [addEditDrawerProps, setAddEditDrawerProps] = useState<any>({
    headerTitle: '',
    headerSubtitle: '',
    primaryButtonTitle: '',
    documentTitleLabel: '',
    documentTypeLabel: '',
    documentTypePlaceholder: '',
  })
  const [selectedDocType, setSelectedDocType] = useState('')

  /** delete document states */
  const [isShowDocumentDeleteModal, setIsShowDocumentDeleteModal] =
    useState(false)

  /** document view modal states */
  const [signedUrlList, setSignedUrlList] = useState<any>([])
  const [isShowDocumentViewModal, setIsShowDocumentViewModal] = useState(false)

  /** candidate education and certification/licenses list states */
  const [candidateCertificationList, setCandidateCertificationList] =
    useState<any>([])
  const [candidateEducationList, setCandidateEducationList] = useState<any>([])

  /** handle get category Id for document type list */
  const handleDocumentTypeList = (type) => {
    const certificationAndLicenseObj = documentCategoriesList?.find((item) => {
      return item.label === type
    })

    dispatch(setDocumentCategory(certificationAndLicenseObj))
  }
  const { clickValue } = useSelector((state: any) => state.layout)
  const [showLicenseMoreButtonOption, setShowLicenseMoreButtonOption] =
    useState<any>({
      index: -1,
      isEnable: false,
    })

  const [showEducationMoreButtonOption, setShowEducationMoreButtonOption] =
    useState<any>({
      index: -1,
      isEnable: false,
    })

  useEffect(() => {
    setShowLicenseMoreButtonOption({
      index: -1,
      isEnable: false,
    })
    setShowEducationMoreButtonOption({
      index: -1,
      isEnable: false,
    })
  }, [clickValue])

  /** getting states of the title of attachment */
  const [documentTitles, setDocumentTitles] = useState({
    license: '',
    education: '',
  })

  /** Get candidate certification/license by id api */
  const {
    mutate: getCandidateCertificationsByIdMutate,
    isLoading: getCandidateCertificationsByIdIsLoading,
  } = useGetCandidateEducationListById()

  /** Process the get certification/license */
  async function getCandidateCertificationsById() {
    getCandidateCertificationsByIdMutate(
      {
        Id: candidateId,
        categoryName: 'Certifications/License',
        isArchived: false,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateCertificationList(
            successData?.documentDetailsWithAttachments,
          )
          getCandidateAuditDocumentIds()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate Certifications/License!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate education list by id api */
  const {
    mutate: getCandidateEEducationListByIdMutate,
    isLoading: getCandidateEEducationListByIdIsLoading,
  } = useGetCandidateEducationListById()

  /** Process the get education certifications */
  async function getCandidateEducationListById() {
    getCandidateEEducationListByIdMutate(
      {
        Id: candidateId,
        categoryName: 'Education',
        isArchived: false,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateEducationList(successData?.documentDetailsWithAttachments)
          getCandidateAuditDocumentIds()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate education documents',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for Get selected document signed url List */
  const {
    mutate: getDocumentSignedUrlsByIdMutate,
    isLoading: getDocumentSignedUrlsByIdIsLoading,
  } = useGetDocumentSignedUrlsById()

  async function getDocumentSignedUrlsById(documentId: any) {
    getDocumentSignedUrlsByIdMutate(
      {
        documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData && setSignedUrlList(successData?.attachmentTypeSignedUrls)
        },
      },
    )
  }

  /** API Handling request for document delete */
  const {
    mutate: deleteCandidateDocumentMutate,
    isLoading: deleteCandidateDocumentIsLoading,
  } = useDeleteCandidateDocument()

  async function deleteCandidateDocument() {
    deleteCandidateDocumentMutate(
      {
        documentId: selectedDocumentDetails?.documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setIsShowDocumentDeleteModal(false)

          selectedDocType === 'Certification'
            ? getCandidateCertificationsById()
            : getCandidateEducationListById()

          setSelectedDocumentDetails(null)
          getCandidateAuditDocumentIds()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error deleting document!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (!isAuditModal?.isOpen) {
      candidateId && getCandidateCertificationsById()
      candidateId && getCandidateEducationListById()
    }
  }, [candidateId, isAuditModal?.isOpen])

  const QualificationTabViewClasses = CN(
    `qualification-tab-view flex gap-5`,
    className,
  )

  return (
    <div className={QualificationTabViewClasses} {...restProps}>
      {/* license and certification card list */}
      <CandidateLicenseAndCertificationsCard
        candidateCertificationList={candidateCertificationList}
        setIsShowDocumentViewModal={setIsShowDocumentViewModal}
        getDocumentSignedUrlsById={getDocumentSignedUrlsById}
        setDocumentTitles={setDocumentTitles}
        isLoading={getCandidateCertificationsByIdIsLoading}
        showLicenseMoreButtonOption={showLicenseMoreButtonOption}
        setShowLicenseMoreButtonOption={setShowLicenseMoreButtonOption}
        setShowEducationMoreButtonOption={setShowEducationMoreButtonOption}
        onAddNewClick={() => {
          setShowAddNewDocumentDrawer(true)
          setSelectedDocType('Certification')

          setAddEditDrawerProps({
            ...addEditDrawerProps,
            headerTitle: 'Add Certification/License',
            headerSubtitle: 'Add new certification/license of the candidate',
            primaryButtonTitle: 'Save Certification/License',
            documentTitleLabel: 'Certification/License Name',
            documentTypeLabel: 'Certification Type',
            documentTypePlaceholder:
              'Ex: Forklift Drivers License, White Card Holder etc.',
          })
          handleDocumentTypeList('Certifications/License')
        }}
        onEditClick={() => {
          setShowEditDocumentDrawer(true)
          setSelectedDocType('Certification')
          setAddEditDrawerProps({
            ...addEditDrawerProps,
            headerTitle: 'Edit Certification/License',
            headerSubtitle: 'Edit certification/license of the candidate',
            primaryButtonTitle: 'Edit Certification/License',
            documentTitleLabel: 'Certification/License Name',
            documentTypeLabel: 'Certification Type',
            documentTypePlaceholder:
              'Ex: Forklift Drivers License, White Card Holder etc.',
          })
          handleDocumentTypeList('Certifications/License')
        }}
        onDeleteClick={() => {
          setIsShowDocumentDeleteModal(true)
          setSelectedDocType('Certification')
        }}
        setSelectedDocumentDetails={setSelectedDocumentDetails}
      />

      {/* education and qualification card list */}
      <CandidateEducationCard
        candidateEducationList={candidateEducationList}
        setIsShowDocumentViewModal={setIsShowDocumentViewModal}
        getDocumentSignedUrlsById={getDocumentSignedUrlsById}
        setDocumentTitles={setDocumentTitles}
        isLoading={getCandidateEEducationListByIdIsLoading}
        showEducationMoreButtonOption={showEducationMoreButtonOption}
        setShowEducationMoreButtonOption={setShowEducationMoreButtonOption}
        setShowLicenseMoreButtonOption={setShowLicenseMoreButtonOption}
        onAddNewClick={() => {
          setShowAddNewDocumentDrawer(true)
          setSelectedDocType('Educational')
          setAddEditDrawerProps({
            ...addEditDrawerProps,
            headerTitle: 'Add Educational Qualification',
            headerSubtitle:
              'Add a new educational qualification of the candidate',
            primaryButtonTitle: 'Save Qualification',
            documentTitleLabel: 'Education Qualification Name',
            documentTypeLabel: 'Qualification Type',
            documentTypePlaceholder:
              'Ex: Graduation, Diploma, Secondary Education etc.',
          })
          handleDocumentTypeList('Education')
        }}
        onEditClick={() => {
          setShowEditDocumentDrawer(true)
          setSelectedDocType('Educational')
          setAddEditDrawerProps({
            ...addEditDrawerProps,
            headerTitle: 'Edit Educational Qualification',
            headerSubtitle: 'Edit educational qualifications of the candidate',
            primaryButtonTitle: 'Edit Qualification',
            documentTitleLabel: 'Education Qualification Name',
            documentTypeLabel: 'Qualification Type',
            documentTypePlaceholder:
              'Ex: Graduation, Diploma, Secondary Education etc.',
          })
          handleDocumentTypeList('Education')
        }}
        onDeleteClick={() => {
          setSelectedDocType('Educational')
          setIsShowDocumentDeleteModal(true)
        }}
        setSelectedDocumentDetails={setSelectedDocumentDetails}
      />

      {/* view documents modal */}
      <CandidateDocumentsViewModal
        isActive={isShowDocumentViewModal}
        docList={signedUrlList}
        headerTitle={documentTitles.education || documentTitles.license}
        onHeaderCloseButtonClick={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        onClickCloseBtn={() => {
          setIsShowDocumentViewModal(false)
          setSignedUrlList([])
        }}
        getDocumentSignedUrlsByIdIsLoading={getDocumentSignedUrlsByIdIsLoading}
      />

      {/* add new document drawer for license/certification and educational qualification documents */}
      <AddNewQualificationTabDocuments
        isActive={showAddNewDocumentDrawer}
        setShowAddNewDocumentDrawer={setShowAddNewDocumentDrawer}
        candidateId={candidateId}
        notify={notify}
        fetchData={
          selectedDocType === 'Certification'
            ? getCandidateCertificationsById
            : getCandidateEducationListById
        }
        headerTitle={addEditDrawerProps?.headerTitle}
        headerSubtitle={addEditDrawerProps?.headerSubtitle}
        primaryButtonTitle={addEditDrawerProps?.primaryButtonTitle}
        documentTitleLabel={addEditDrawerProps?.documentTitleLabel}
        documentTypeLabel={addEditDrawerProps?.documentTypeLabel}
        documentTypePlaceholder={addEditDrawerProps?.documentTypePlaceholder}
      />

      {/* edit drawer for license/certification and educational qualification documents */}
      <EditQualificationTabDocuments
        isActive={showEditDocumentDrawer}
        setShowEditDocumentDrawer={setShowEditDocumentDrawer}
        selectedDocumentDetails={selectedDocumentDetails}
        setSelectedDocumentDetails={setSelectedDocumentDetails}
        notify={notify}
        fetchDocumentList={
          selectedDocType === 'Certification'
            ? getCandidateCertificationsById
            : getCandidateEducationListById
        }
        headerTitle={addEditDrawerProps?.headerTitle}
        headerSubtitle={addEditDrawerProps?.headerSubtitle}
        primaryButtonTitle={addEditDrawerProps?.primaryButtonTitle}
        documentTitleLabel={addEditDrawerProps?.documentTitleLabel}
        documentTypeLabel={addEditDrawerProps?.documentTypeLabel}
        documentTypePlaceholder={addEditDrawerProps?.documentTypePlaceholder}
        getCandidateCertificationsById={getCandidateCertificationsById}
      />

      {/** document delete confirmation Modal */}
      <Modal
        isActive={isShowDocumentDeleteModal}
        onClickPrimaryBtn={() => {
          deleteCandidateDocument()
        }}
        onClickSecondaryBtn={() => {
          setIsShowDocumentDeleteModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateDocumentIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowDocumentDeleteModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Document?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

QualificationTabView.defaultProps = {}

export default QualificationTabView
