import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface SendRegPackProps {
  candidateId: string
  templateId: string
}

export const useSendRegPack = () => {
  const mutate = useMutation(
    ({ candidateId, templateId }: SendRegPackProps) => {
      return postAPI(`/candidate/${candidateId}/sendRegPackEmail`, {
        templateId,
      })
    },
  )

  return mutate
}

export default useSendRegPack
