import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Accordion, Modal, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import ProfileIcon from 'assets/images/Profile-Icon.png'
import CN from 'classnames'
import {
  useGetAvailableUserRoles,
  useGetUserPermissionList,
  useGetUserProfileDetailsById,
  useUserAdd,
} from 'framework/api/methods'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import { UserPermissionSection } from 'components/molecules'

export interface UserPermissionProps {
  [x: string]: any
}

export const UserPermission: FC<UserPermissionProps> = ({
  className,
}: UserPermissionProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )
  const notify = (props: any) => Toast(props)

  const [userData, setUserData] = useState<any>()
  const [roleList, setRoleList] = useState([])
  const [selectedUserGroup, setSelectedUserGroup] = useState<any>([])
  const [showUserGroupChangeConfirmModal, setShowUserGroupChangeConfirmModal] =
    useState(false)
  const [permissionList, setPermissionList] = useState<any>()

  /** Start BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [])

  /** End BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  /** Get user ID from url when page visit */
  useEffect(() => {
    const userId: any = searchParams.get('userId')
    userId && getUserProfileDetailsById(userId)

    /** get available user role for select input (drop down data) */
    getAvailableUserGroups()

    /** get user's permission list */
    getUserPermissionList(userId)
  }, [searchParams])

  /** Get user groups api */
  const {
    mutate: getAvailableUserRolesMutate,
    isLoading: getAvailableUserRolesMutateIsLoading,
  } = useGetAvailableUserRoles()

  /** Process the get user user groups */
  async function getAvailableUserGroups() {
    getAvailableUserRolesMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** set role object for select option list */
          const tempGroupList = successData?.roles?.map((item: any) => {
            return {
              ...item,
              value: item.normalizedName,
              label: item.name,
            }
          })

          setRoleList(tempGroupList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting user group list.',
            status: 'Error',
          })
        },
      },
    )
  }

  /** set user current user group in drop down */
  const setUserCurrentGroup = () => {
    const selectedUserRole = roleList?.find(
      (item: any) => item.label === userData?.roleName,
    )

    setSelectedUserGroup(selectedUserRole || [])
  }

  /** set user group on load */
  useEffect(() => {
    setUserCurrentGroup()
  }, [roleList, userData])

  /** Get user details api */
  const {
    mutate: getUserProfileDetailsByIdMutate,
    isLoading: getUserProfileDetailsByIdIsLoading,
  } = useGetUserProfileDetailsById()

  /** Process the get user details */
  async function getUserProfileDetailsById(Id: any) {
    getUserProfileDetailsByIdMutate(
      {
        Id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setUserData(successData?.userProfile)
          getUserPermissionList(successData?.userProfile?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting user details!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the assign new user role */
  const { mutate: userAssignRoleMutate, isLoading: userAssignRoleIsLoading } =
    useUserAdd()

  /** Process the get assign new user role */
  const userAssignNewRole = () => {
    const userDetails = {
      userId: userData?.id,
      roleId: selectedUserGroup?.id,
      roleName: selectedUserGroup?.name,
    }
    userAssignRoleMutate(
      {
        userDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: '',
            alertBody: successData?.message,
            status: 'Success',
          })
          getUserProfileDetailsById(userData?.id)
          getUserPermissionList(userData?.id)
          setShowUserGroupChangeConfirmModal(false)

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
          setShowUserGroupChangeConfirmModal(false)
        },
      },
    )
  }

  /** APi call for get user permission list */
  const {
    mutate: getUserPermissionListMutate,
    isLoading: getUserPermissionListIsLoading,
  } = useGetUserPermissionList()

  /** Process the get user permission list */
  const getUserPermissionList = (Id: any) => {
    getUserPermissionListMutate(
      {
        Id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setPermissionList(successData?.permissionList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting user permission list!',
            status: 'Error',
          })
        },
      },
    )
  }

  const UserPermissionClasses = CN(
    `user-permission flex flex-col w-[972px] h-auto`,
    className,
  )

  return (
    <div className={UserPermissionClasses}>
      {/* page loader */}
      {getUserProfileDetailsByIdIsLoading &&
        getAvailableUserRolesMutateIsLoading &&
        getUserPermissionListIsLoading && <PageLoader size='xxs' />}

      {/* user detail and role section */}
      <div className='flex w-full bg-white px-6 rounded-md border border-Gray-200 divide-x divide-Gray-200'>
        {/* left side */}
        <div className='flex flex-col py-[26px] pr-[55px]'>
          <span className='text-small font-Medium text-Gray-800'>
            User Group
          </span>

          <SimpleSelect
            value={selectedUserGroup}
            className='w-[480px] pt-2'
            onChange={(selectedItem: any) => {
              setSelectedUserGroup(selectedItem)
              setShowUserGroupChangeConfirmModal(true)
            }}
            options={roleList}
          />

          <div className='flex items-center justify-start pt-3 text-Gray-500'>
            <i className='ri-error-warning-line text-small pr-2'></i>

            <span className='text-extra-small  font-Regular'>
              The permission list will change while selecting the user group.
            </span>
          </div>
        </div>

        {/* right side */}
        <div className='flex py-6 pl-8'>
          {/* avatar section */}
          <img
            src={userData?.avatarUrl ? userData?.avatarUrl : ProfileIcon}
            alt='man'
            className={CN(
              'w-[100px] h-[100px] rounded-full object-cover overflow-hidden',
              {
                'pt-5 bg-Gray-100': !userData?.avatarUrl,
              },
            )}
          />

          {/* user detail section */}
          <div className='flex flex-col ml-8 justify-center'>
            <span className='text-Gray-800 font-Medium'>
              {userData && `${userData?.firstName} ${userData?.lastName}`}
            </span>

            <span className='text-small text-Gray-500 font-Regular pt-1'>
              {userData && userData?.email}
            </span>
          </div>
        </div>
      </div>

      {/* user permission accordion */}
      <div className='flex flex-col w-full bg-white rounded-md border border-Gray-200 divide-y divide-Gray-200 px-5 my-4'>
        {/* user permission accordion section for Candidate documents*/}
        <Accordion heading='Candidate documents' initialState={true}>
          <div className='flex flex-col w-full'>
            {permissionList?.candidateDocuments?.map((item: any, idx: any) => (
              <UserPermissionSection
                item={item}
                userId={userData?.identityGuid}
                key={idx}
                notify={notify}
              />
            ))}

            {((permissionList &&
              permissionList?.candidateDocuments === undefined) ||
              (permissionList &&
                permissionList?.candidateDocuments?.length === 0)) && (
              <div className='text-center text-Gray-500 pb-6'>
                No data found.
              </div>
            )}
          </div>
        </Accordion>

        {/* user permission accordion section for Auditing*/}
        <Accordion heading='Auditing'>
          <div className='flex flex-col w-full'>
            {permissionList?.auditing?.map((item: any, idx: any) => (
              <UserPermissionSection
                item={item}
                userId={userData?.identityGuid}
                key={idx}
                notify={notify}
              />
            ))}

            {((permissionList && permissionList?.auditing === undefined) ||
              (permissionList && permissionList?.auditing?.length === 0)) && (
              <div className='text-center text-Gray-500 pb-6'>
                No data found.
              </div>
            )}
          </div>
        </Accordion>

        {/* user permission accordion section for Job Posting*/}
        <Accordion heading='Job posting'>
          <div className='flex flex-col w-full'>
            {permissionList?.jobPosting?.map((item: any, idx: any) => (
              <UserPermissionSection
                item={item}
                userId={userData?.identityGuid}
                key={idx}
                notify={notify}
              />
            ))}

            {((permissionList && permissionList?.jobPosting === undefined) ||
              (permissionList && permissionList?.jobPosting?.length === 0)) && (
              <div className='text-center text-Gray-500 pb-6'>
                No data found.
              </div>
            )}
          </div>
        </Accordion>
      </div>

      {/* change user group confirmation modal */}
      <Modal
        isActive={showUserGroupChangeConfirmModal}
        onClickPrimaryBtn={() => {
          userAssignNewRole()
        }}
        onClickSecondaryBtn={() => {
          setShowUserGroupChangeConfirmModal(false)
          setUserCurrentGroup()
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: userAssignRoleIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowUserGroupChangeConfirmModal(false)
          setUserCurrentGroup()
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <span className='text-Gray-900 text-heading-5 font-Medium'>
            Change User Group?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

UserPermission.defaultProps = {}

export default UserPermission
