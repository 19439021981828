import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetUserPermissionListInterface {
  Id: any
}

export const useGetUserPermissionList = () => {
  const mutate = useMutation(({ Id }: GetUserPermissionListInterface) => {
    return get(`/facade/permissions/${Id}`)
  })

  return mutate
}

export default useGetUserPermissionList
