import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tabs, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useActivatedCandidate,
  useDeactivatedCandidate,
  useGetAuditCheckDocumentList,
  useGetDocumentInformationViewData,
  useSetAuditPassed,
} from 'framework/api/methods'
import {
  ActivatedTab,
  AuditFailedTab,
  AuditQueueTab,
  WaitingForActivationTab,
  WaitingForDocumentTab,
} from 'static-data/audit/auditTabs'
import {
  setDocumentList,
  setEditDrawer,
  setIsAuditModal,
  setSelectedAccordionIndex,
  setSelectedDocumentId,
  setSelectedSection,
} from 'store/reducers/audit/auditSlice'

import { AuditPassFailButtons, PageLoader } from 'components/atoms'

import { Modal, ModalFooter } from '../../../molecules/Modal'
import { AuditEditDrawers } from '../auditEditDrawers/AuditEditDrawers'

import { DocumentsPreviewSection } from './DocumentsPreviewSection/DocumentsPreviewSection'
import { Accordion } from './Accordion'
import { AuditFailModal } from './AuditFailModal'
import { DetailsSection } from './DetailsSection'
import { FinancialDetails, PersonalDetails } from './personalTabSections'

export interface AuditCheckModalProps {
  isActive?: boolean
  isActiveButton?: boolean
  isFooterShow?: boolean
  [x: string]: any
}

export const AuditCheckModal: FC<AuditCheckModalProps> = ({
  isActive,
  isActiveButton,
  isFooterShow,
  ...restProps
}: AuditCheckModalProps) => {
  const dispatch = useDispatch()

  const {
    selectedSection,
    selectedCandidate,
    documentIdsList,
    documentList,
    isAuditModal,
    selectedAccordionIndex,
    selectedDocumentId,
  } = useSelector((state: any) => state.audit)

  const [sectionList, setSectionList] = useState<any>()
  const [isActivateBtnDisable, setIsActivateBtnDisable] = useState(false)

  const [attachmentsList, setAttachmentsList] = useState([])

  /** open confirmation modal state */
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState({
    mode: '',
    isActive: false,
  })

  /** audit fail props */
  const [isOpenAuditFailModal, setIsOpenAuditFailModal] = useState(false)

  /** APi call for activated candidate */
  const {
    mutate: activatedCandidateMutate,
    isLoading: activatedCandidateIsLoading,
  } = useActivatedCandidate()

  /** Process the activated candidate */
  const activatedCandidate = () => {
    activatedCandidateMutate(
      {
        candidateId: selectedCandidate?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle,
            alertBody: successData?.message ? successData.message : '',
            status: 'Success',
          })
          setIsOpenConfirmationModal({
            ...isOpenConfirmationModal,
            isActive: false,
          })

          dispatch(
            setIsAuditModal({
              ...isAuditModal,
              isOpen: false,
            }),
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for deactivated candidate */
  const {
    mutate: deactivatedCandidateMutate,
    isLoading: deactivatedCandidateIsLoading,
  } = useDeactivatedCandidate()

  /** Process the deactivated candidate */
  const deactivatedCandidate = () => {
    deactivatedCandidateMutate(
      {
        candidateId: selectedCandidate?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle,
            alertBody: successData?.message ? successData.message : '',
            status: 'Success',
          })

          setIsOpenConfirmationModal({
            ...isOpenConfirmationModal,
            isActive: false,
          })

          dispatch(
            setIsAuditModal({
              ...isAuditModal,
              isOpen: false,
            }),
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const confirmationModalPrimaryButtonAction = () => {
    if (isOpenConfirmationModal?.mode === 'Activate') {
      activatedCandidate()
    } else {
      deactivatedCandidate()
    }
  }

  /** get audit check modal documents list API ------> Mutate */
  const {
    mutate: getAuditCheckDocumentListMutate,
    isLoading: getAuditCheckDocumentListIsLoading,
  } = useGetAuditCheckDocumentList()

  /** Process the get audit check modal documents list   */
  async function getAuditCheckDocumentList() {
    getAuditCheckDocumentListMutate(
      {
        candidateId: selectedCandidate?.id,
        documentIds: documentIdsList,
      },
      {
        onSuccess: ({ data }: any) => {
          dispatch(setDocumentList(data || null))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** get documents list from given ids */
  useEffect(() => {
    isActive && getAuditCheckDocumentList()
  }, [isActive])

  /** get section details on success */
  const onSuccess = (data: any) => {
    setAttachmentsList(data?.attachements || [])
  }

  /** API Handling for get section details */
  const {
    refetch: getDocumentInformationViewData,
    isFetching: getDocumentInformationViewDataIsFetching,
  } = useGetDocumentInformationViewData(
    selectedSection?.candidateId,
    selectedSection?.documentId,
    selectedSection?.categoryId,
    onSuccess,
  )

  useEffect(() => {
    selectedSection && getDocumentInformationViewData()
  }, [selectedSection])

  /** audit Pass API ------> Mutate */
  const { mutate: setAuditPassedMutate, isLoading: setAuditPassedIsLoading } =
    useSetAuditPassed()

  /** Process the audit Pass  */
  async function setAuditPassed() {
    setAuditPassedMutate(
      {
        candidateId: selectedSection?.candidateId,
        documentId: selectedSection?.documentId,
        categoryId: selectedSection?.categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            status: 'Success',
          })
          setAttachmentsList([])
          getAuditCheckDocumentList()
          isAuditModal?.tab === AuditQueueTab &&
            dispatch(setSelectedSection(null))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** set accordion section list from document list */
  useEffect(() => {
    if (documentList) {
      let tempFilteredList = documentList?.documents

      if (
        isAuditModal?.tab === AuditQueueTab ||
        isAuditModal?.tab === WaitingForDocumentTab
      ) {
        tempFilteredList = documentList?.documents?.filter(
          (obj) => obj.auditStatus !== 'Active',
        )
      }

      const tempDataList = tempFilteredList?.map((item: any, idx: number) => {
        return {
          ...item,
          sectionIndex: idx + 1,
          title: item?.title,
          content: <DetailsSection item={item} idx={idx} />,
        }
      })

      if (isAuditModal?.tab) {
        setSectionList([
          {
            sectionIndex: 0,
            title: 'Candidate Information',
            content: (
              <>
                <Tabs
                  className='h-full'
                  contentClassName='h-full pb-1 overflow-y-auto px-4 pt-6'
                  defaultTabId={1}
                  tabDetails={[
                    {
                      id: 1,
                      tabButtonContent: 'Personal Details',
                      tabContent: (
                        <PersonalDetails
                          item={documentList?.personalInformation || {}}
                        />
                      ),
                    },
                    {
                      id: 2,
                      tabButtonContent: 'Financials',
                      tabContent: (
                        <FinancialDetails
                          item={documentList?.financialInformation || {}}
                          selectedCandidate={selectedCandidate}
                        />
                      ),
                    },
                  ]}
                />
              </>
            ),
          },
          ...tempDataList,
        ])
      } else {
        setSectionList([...tempDataList])
      }
    }
  }, [documentList, isAuditModal?.tab])

  /** for disable active candidate btn when audit failed doc in waiting for activation tab modal  */
  useEffect(() => {
    if (isAuditModal?.tab === WaitingForActivationTab) {
      const isContainAuditFailed = documentList?.documents?.some(
        (item: any) => item?.auditStatus === 'Audit_Failed',
      )
      setIsActivateBtnDisable(isContainAuditFailed)
    }
  }, [isAuditModal?.tab, documentList])

  /** when changing document list update the selected section with new details */
  useEffect(() => {
    if (selectedSection) {
      const updatedSelectedSection = documentList?.documents?.find(
        (item: any) => item.documentId == selectedSection?.documentId,
      )

      dispatch(setSelectedSection(updatedSelectedSection))
    }
  }, [documentList])

  useEffect(() => {
    if (selectedDocumentId) {
      /** set section index according to the selected Document Id */
      const selectedSectionIndex = sectionList?.findIndex(
        (item: any) =>
          item?.sectionIndex !== 0 && item.documentId == selectedDocumentId,
      )
      dispatch(setSelectedAccordionIndex(selectedSectionIndex))

      /** set section details when selected Document Id change */
      const selectedSection = sectionList?.find(
        (item: any) =>
          item?.sectionIndex !== 0 && item.documentId == selectedDocumentId,
      )
      dispatch(setSelectedSection(selectedSection))
    }
  }, [sectionList, selectedDocumentId])

  useEffect(() => {
    isActive && setAttachmentsList([])
  }, [isActive])
  return (
    <>
      <Modal
        isActive={isActive}
        headerTitle={`Audit Check - ${selectedCandidate?.name}`}
        isFooterShow={false}
        isCustomHeaderCloseButton={true}
        onHeaderCloseButtonClick={() => {
          dispatch(
            setIsAuditModal({
              ...isAuditModal,
              isOpen: false,
            }),
          )

          dispatch(setSelectedAccordionIndex(0))
          dispatch(setSelectedDocumentId(null))
          dispatch(setSelectedSection(null))
        }}
        modalProps={{
          style: { width: '95vw' },
        }}>
        <div
          className={CN('flex w-full ', {
            'h-[80vh]': isFooterShow,
            'h-[87vh]': !isFooterShow,
          })}>
          {(getAuditCheckDocumentListIsLoading || setAuditPassedIsLoading) && (
            <PageLoader size='xxs' />
          )}

          <div className='w-2/5 px-[26px] py-6 overflow-y-auto styled-scroll'>
            <Accordion
              items={sectionList || []}
              onClickItem={(item: any) => {
                if (item?.title === 'Candidate Information') {
                  dispatch(setSelectedSection(null))
                  dispatch(setSelectedAccordionIndex(0))
                  dispatch(setSelectedDocumentId(null))
                  setAttachmentsList([])
                } else {
                  dispatch(setSelectedSection(item))
                  dispatch(setSelectedDocumentId(item?.documentId))
                }
                dispatch(setEditDrawer(null))
              }}
              defaultOpenIndex={selectedAccordionIndex}
            />
          </div>

          <div className='flex flex-col w-3/5 bg-Gray-200 justify-start items-start'>
            {selectedSection &&
              selectedSection?.title !== 'Candidate Information' && (
                <div className='flex w-full px-5 py-[19px] bg-white'>
                  <div className='flex flex-col h-full w-[45%]'>
                    <span className='text-heading-5 font-Medium text-Gray-800 line-clamp-1 pr-2'>
                      {selectedSection?.title}
                    </span>

                    <div className='flex pt-1 text-small'>
                      <span className='font-Medium text-Gray-500'>
                        Category:
                      </span>

                      <span className='ml-1 font-Medium text-Gray-800 line-clamp-1 break-all'>
                        {selectedSection?.category}
                      </span>
                    </div>
                  </div>

                  {isAuditModal?.tab !== ActivatedTab && (
                    <div className='flex justify-end items-center h-full w-[55%] gap-x-5'>
                      <AuditPassFailButtons
                        type='pass'
                        className='w-2/3'
                        isActive={selectedSection?.auditStatus === 'Active'}
                        onClick={() => {
                          selectedSection?.auditStatus !== 'Active' &&
                            setAuditPassed()
                        }}
                        isDisabled={
                          selectedSection?.auditStatus === 'Request_Submission'
                        }
                      />

                      <AuditPassFailButtons
                        type='fail'
                        className='w-2/3'
                        isActive={
                          selectedSection?.auditStatus === 'Audit_Failed'
                        }
                        onClick={() => {
                          selectedSection?.auditStatus !== 'Audit_Failed' &&
                            setIsOpenAuditFailModal(true)
                        }}
                        isDisabled={
                          selectedSection?.auditStatus ===
                            'Request_Submission' ||
                          isAuditModal?.tab === AuditFailedTab
                        }
                      />
                    </div>
                  )}
                </div>
              )}

            <div className='flex w-full h-full overflow-y-auto justify-center items-center px-2 relative'>
              <DocumentsPreviewSection
                isFooterShow={isFooterShow}
                docList={attachmentsList}
                getDocumentSignedUrlsByIdIsLoading={
                  getDocumentInformationViewDataIsFetching
                }
              />
            </div>
          </div>
        </div>

        {isFooterShow && (
          <ModalFooter className='py-3'>
            <div className='flex items-center justify-end'>
              <Button
                onClick={() => {
                  dispatch(
                    setIsAuditModal({
                      ...isAuditModal,
                      isOpen: false,
                    }),
                  )
                  dispatch(setSelectedAccordionIndex(0))
                  dispatch(setSelectedSection(null))
                  dispatch(setSelectedDocumentId(null))
                }}
                textTheme='black'
                theme='white'>
                Cancel
              </Button>
              {isActiveButton ? (
                <Button
                  onClick={() => {
                    setIsOpenConfirmationModal({
                      mode: 'Activate',
                      isActive: true,
                    })
                  }}
                  className='ml-2'
                  isDisabled={isActivateBtnDisable}
                  theme='cobalt'>
                  Activate Candidate
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpenConfirmationModal({
                      mode: 'Deactivate',
                      isActive: true,
                    })
                  }}
                  className='ml-2'
                  theme='white'
                  textTheme='terracotta'>
                  Deactivate Candidate
                </Button>
              )}
            </div>
          </ModalFooter>
        )}
      </Modal>

      <AuditFailModal
        isActive={isOpenAuditFailModal}
        setIsOpenAuditFailModal={setIsOpenAuditFailModal}
        getAuditCheckDocumentList={getAuditCheckDocumentList}
        setAttachmentsList={setAttachmentsList}
      />

      <AuditEditDrawers getAuditCheckDocumentList={getAuditCheckDocumentList} />

      {/** Activated and Deactivated Confirmation Modal */}
      <Modal
        isActive={isOpenConfirmationModal?.isActive}
        onOverlayClick={() => {
          setIsOpenConfirmationModal({
            ...isOpenConfirmationModal,
            isActive: false,
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        {(deactivatedCandidateIsLoading || activatedCandidateIsLoading) && (
          <PageLoader size='sm' />
        )}
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Gray-100'>
            <i
              className={CN('text-heading-3', {
                'ri-error-warning-line text-Red-500':
                  isOpenConfirmationModal?.mode === 'Deactivate',
                'ri-check-line text-black':
                  isOpenConfirmationModal?.mode === 'Activate',
              })}></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            {isOpenConfirmationModal?.mode === 'Activate'
              ? 'Activate Candidate?'
              : 'Deactivate Candidate?'}
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>

        <ModalFooter className='flex justify-center'>
          <Button
            onClick={() => {
              confirmationModalPrimaryButtonAction()
            }}
            className='ml-2 w-[204px]'
            theme={
              isOpenConfirmationModal?.mode === 'Deactivate'
                ? 'gradient'
                : 'cobalt'
            }
            textTheme='white'>
            Yes, I'm Sure
          </Button>

          <Button
            onClick={() => {
              setIsOpenConfirmationModal({
                ...isOpenConfirmationModal,
                isActive: false,
              })
            }}
            className='ml-2 w-[204px]'
            theme='white'
            textTheme='black'>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

AuditCheckModal.defaultProps = {
  isActive: false,
  isActiveButton: false,
  isFooterShow: false,
}

export default AuditCheckModal
