import React, { FC } from 'react'
import CN from 'classnames'

import { AuditQueueList } from './AuditQueueList'

export interface AuditQueueTabViewProps {
  [x: string]: any
}

export const AuditQueueTabView: FC<AuditQueueTabViewProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  managingConsultantList,
  ...restProps
}: AuditQueueTabViewProps) => {
  const ActivatedTabViewClasses = CN(
    `auditQueue-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={ActivatedTabViewClasses} {...restProps}>
      <span className='text-Gray-800 font-Medium'>Audit Queue</span>

      <span className='font-Regular text-small text-Gray-800 mt-1'>
        Here, every audit request for documents is listed down and grouped by
        users. You can start the audit process by clicking on the play button.
      </span>

      <div className='pt-8'>
        <AuditQueueList
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          managingConsultantList={managingConsultantList}
        />
      </div>
    </div>
  )
}

AuditQueueTabView.defaultProps = {}

export default AuditQueueTabView
