import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Modal, Tabs, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  CandidateProfileCardType,
  useCreateCandidateAvatar,
  useDeleteCandidateAvatar,
  useDeleteCandidateNote,
  useGetCandidateDetailsById,
  useGetCandidateDocumentIds,
  useGetCandidateEducationListById,
  useGetCandidateMedicalConditionListById,
  useGetCandidateMedicalIncidentListById,
  useGetCandidateNotes,
  useGetDocumentCategoryList,
  useGetOtherDocumentCategoryList,
  useGetRefereesByCandidate,
  useSendRegPack,
} from 'framework/api/methods'
import { setDocumentIdsList } from 'store/reducers/audit/auditSlice'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'
import { setDocumentCategoriesList } from 'store/reducers/documents/documentSlice'
import { setBackFunction } from 'store/reducers/layout/layoutSlice'

import { PageLoader } from 'components/atoms'
import {
  AddDeleteCandidateNotes,
  EditCandidate,
} from 'components/Drawers/Candidate'
import {
  CandidateProfileDetailSection,
  CustomizableEditProfilePictureModal,
} from 'components/molecules'
import dateToReadableString from 'utils/dateToReadableString'

import { VisaTabView } from './candidateProfileTabs/VisaTabView'
import {
  DashboardTabView,
  DocumentsTabView,
  EligibilityTabView,
  MedicalHistoryTabView,
  PayrollTabView,
  QualificationTabView,
  RefereesTabView,
} from './candidateProfileTabs'
import RegistrationPackModal from './RegistrationPackModal'

export interface CandidateProfileProps {
  [x: string]: any
}

export const CandidateProfile: FC<CandidateProfileProps> = ({
  className,
  ...restProps
}: CandidateProfileProps) => {
  const [searchParams] = useSearchParams()
  const Location: any = useLocation()
  const notify = (props: any) => Toast(props)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userDetails }: any = useSelector<any>((state) => state.user)

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/candidate-list')
      }),
    )
  }, [])

  /** tab header icon list states */
  const [workClaimIcon, setWorkClaimIcon] = useState<any>([
    {
      icon: '',
      color: '',
    },
  ])

  const [workingAffectedIcon, setWorkingAffectedIcon] = useState<any>([
    {
      icon: '',
      color: '',
    },
  ])

  const [documentExpiredIcon, setDocumentExpiredIcon] = useState<any>([
    {
      icon: '',
      color: '',
    },
  ])

  /** candidate detail states */
  const [candidateDetails, setCandidateDetails] =
    useState<CandidateProfileCardType>()

  /** candidate notes states */
  const [candidateNotes, setCandidateNotes] = useState<any>()

  /** candidate send reg pack modal states */
  const [isOpenRegPackModal, setIsOpenRegPackModal] = useState(false)

  /** image upload states */
  const [imageUrl, setImageUrl] = useState<any>('')
  const [files, setFiles] = useState<any>()
  const [showEditPhotoModal, setShowEditPhotoModal] = useState(false)
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false)
  const [showResendRegPackModal, setShowResendRegPackModal] = useState(false)

  /** notes states */
  const [isOpenNotesDrawer, setIsOpenNotesDrawer] = useState(false)
  const [deleteNoteModal, setDeleteNoteModal] = useState({
    show: false,
    selectedNoteId: '',
  })

  /** edit candidate states */
  const [showEditCandidateDrawer, setShowEditCandidateDrawer] = useState(false)

  /** documents category states */
  const [documentCategoryList, setDocumentCategoryList] = useState<any>({})
  const [otherDocumentCategoryList, setOtherDocumentCategoryList] =
    useState<any>({})

  /** Start BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */
  const { menuItems } = useSelector((state: any) => state.sideMenuList)
  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [Location, menuItems])
  /** End BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  /** Get candidate ID from url when page visit */
  useEffect(() => {
    const candidateId: any = searchParams.get('candidate_id')
    if (candidateId) {
      getCandidateDetailsById(candidateId)
      getCandidateNotes(candidateId)
    }

    // Get document category list
    getDocumentCategoryList()

    // Get other document category list
    getOtherDocumentCategoryList()
  }, [searchParams])

  /** Get candidate card details api */
  const {
    mutate: getCandidateDetailsByIdMutate,
    isLoading: getCandidateDetailsByIdIsLoading,
  } = useGetCandidateDetailsById()

  /** Process the get candidate details */
  async function getCandidateDetailsById(Id: any) {
    getCandidateDetailsByIdMutate(
      {
        Id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setImageUrl('')
          setCandidateDetails(successData?.profileCard)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate details!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate notes api */
  const { mutate: getCandidateNotesMutate } = useGetCandidateNotes()

  /** Process the get notes */
  async function getCandidateNotes(Id: any) {
    getCandidateNotesMutate(
      {
        Id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateNotes(successData?.candidateNotes)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate details!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** upload candidate photo api */
  const {
    mutate: createCandidateAvatarMutate,
    isLoading: createCandidateAvatarIsLoading,
  } = useCreateCandidateAvatar()

  /** Process the upload candidate photo */
  async function uploadCandidatePhoto(Id: any, avatarObject: any) {
    createCandidateAvatarMutate(
      {
        Id,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message
              ? successData.message
              : 'Photo updated!',
            alertBody: '',
            status: 'Success',
          })
          setShowEditPhotoModal(false)
          getCandidateDetailsById(candidateDetails?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle upload candidate photo button */
  const handleUploadPhoto = (files: any) => {
    uploadCandidatePhoto(candidateDetails?.id, files)
  }

  /** API Call for delete candidate photo using react-query */
  const {
    mutate: deleteCandidateAvatarMutate,
    isLoading: deleteCandidateAvatarIsLoading,
  } = useDeleteCandidateAvatar()

  /** Process the Delete candidate photo */
  async function deleteCandidatePhoto(Id: string) {
    deleteCandidateAvatarMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Photo was removed!',
            alertBody: '',
            status: 'Success',
          })
          getCandidateDetailsById(candidateDetails?.id)
          setShowDeletePhotoModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** delete candidate note */
  const {
    mutate: deleteCandidateNoteMutate,
    isLoading: deleteCandidateNoteIsLoading,
  } = useDeleteCandidateNote()

  /** Process the delete candidate note */
  async function deleteCandidateNote(NoteId: any) {
    deleteCandidateNoteMutate(
      {
        NoteId,
        CandidateId: candidateDetails?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })

          setDeleteNoteModal({
            ...deleteNoteModal,
            show: false,
          })

          getCandidateNotes(candidateDetails?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get candidate certification/license by id api */
  const { mutate: getCandidateCertificationsByIdMutate } =
    useGetCandidateEducationListById()

  /** Process the get certification/license */
  async function getCandidateCertificationsById() {
    getCandidateCertificationsByIdMutate(
      {
        Id: candidateDetails?.id,
        categoryName: 'Certifications/License',
        isArchived: false,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const found = successData?.documentDetailsWithAttachments.some(
            (itm) => itm.status === 'Expired',
          )
          if (found) {
            setDocumentExpiredIcon({
              ...documentExpiredIcon,
              icon: 'ri-error-warning-fill',
              color: 'text-Red-500',
            })
          }
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate Certifications/License!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** medical condition List on success */
  const onGetMedicalConditionListSuccess = ({ data }: any) => {
    const found = data?.medicalConditionDetails.some(
      (itm) => itm.isWorkingAffected === true,
    )

    if (found) {
      setWorkingAffectedIcon({
        ...workingAffectedIcon,
        icon: 'ri-shield-cross-fill',
        color: 'text-Cobalt-600',
      })
    }
  }

  /** medical condition List on error */
  const onGetMedicalConditionListError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error getting medical condition list.!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All medical condition List */
  const { refetch: getMedicalConditionList } =
    useGetCandidateMedicalConditionListById(
      {
        Id: candidateDetails?.id,
      },
      onGetMedicalConditionListSuccess,
      onGetMedicalConditionListError,
    )

  /** Get candidate medical incident list by id api */
  const { mutate: getCandidateMedicalIncidentsByIdMutate } =
    useGetCandidateMedicalIncidentListById()

  /** Process the get candidate medical incidents */
  async function getCandidateMedicalIncidentsById() {
    getCandidateMedicalIncidentsByIdMutate(
      {
        Id: candidateDetails?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const found = successData?.medicalIncidentDetails.some(
            (itm) => itm.isWorkCover === true,
          )
          if (found) {
            setWorkClaimIcon({
              ...workClaimIcon,
              icon: 'ri-error-warning-fill',
              color: 'text-Red-500',
            })
          }
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate Medical Incidents',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for  get audit document ids */
  const {
    refetch: getCandidateAuditDocumentIds,
    isFetching: getCandidateAuditDocumentIdsIsLoading,
    data: documentIdList,
  } = useGetCandidateDocumentIds(candidateDetails?.id || '')

  useEffect(() => {
    candidateDetails?.id && getCandidateCertificationsById()
    candidateDetails?.id && getCandidateMedicalIncidentsById()
    candidateDetails?.id && getMedicalConditionList()
  }, [candidateDetails?.id])

  /** get candidate Audit Documents Id list */
  useEffect(() => {
    candidateDetails?.id && getCandidateAuditDocumentIds()
  }, [candidateDetails?.id])

  useEffect(() => {
    dispatch(setDocumentIdsList(documentIdList))
  }, [documentIdList])

  /**Fetch referees for the candidate */
  const {
    data: candidateReferees,
    isLoading: getCandidateRefereesLoading,
    refetch: getCandidateRefereesList,
  } = useGetRefereesByCandidate(candidateDetails?.id)

  /** get candidate Audit Documents Id list */
  useEffect(() => {
    candidateDetails?.id && getCandidateRefereesList()
  }, [candidateDetails?.id])

  /** candidate profile tab details */
  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Dashboard',
      tabContent: (
        <DashboardTabView candidateId={candidateDetails?.id} notify={notify} />
      ),
    },
    {
      id: 2,
      tabContent: (
        <QualificationTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
      tabButtonContent: (
        <div className='flex justify-center items-center'>
          <span>Qualifications</span>
        </div>
      ),
      tabHeaderIconList: [documentExpiredIcon],
    },

    {
      id: 3,
      tabButtonContent: (
        <div className='flex justify-center items-center'>
          <span>Medical History</span>
        </div>
      ),
      tabHeaderIconList: [workClaimIcon, workingAffectedIcon],
      tabContent: (
        <MedicalHistoryTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
    },
    {
      id: 4,
      tabButtonContent: 'Documents',
      tabContent: (
        <DocumentsTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          otherDocumentCategoryList={otherDocumentCategoryList}
          documentCategoryList={documentCategoryList}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
    },
    {
      id: 5,
      tabButtonContent: 'Payroll',
      tabContent: (
        <PayrollTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
    },
    {
      id: 6,
      tabButtonContent: 'Eligibility',
      tabContent: (
        <EligibilityTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
    },
    {
      id: 7,
      tabButtonContent: (
        <div className='flex justify-center items-center'>
          <span>Referees</span>
        </div>
      ),
      tabHeaderIconList:
        candidateReferees && candidateReferees.referees.length > 0
          ? [
              {
                content: candidateReferees.referees.length,
                color: 'bg-Cobalt-500',
              },
            ]
          : [],

      tabContent: candidateDetails && (
        <RefereesTabView
          candidate={candidateDetails}
          candidateReferees={candidateReferees}
          getCandidateRefereesList={getCandidateRefereesList}
        />
      ),
    },
    {
      id: 8,
      tabButtonContent: 'Visa',
      tabContent: (
        <VisaTabView
          candidateId={candidateDetails?.id}
          notify={notify}
          getCandidateAuditDocumentIds={getCandidateAuditDocumentIds}
        />
      ),
    },
  ]

  /** API Call for get document category list for filtering using react-query */
  const { mutate: getDocumentCategoryListMutate } = useGetDocumentCategoryList()

  /** Process get document category list for filtering */
  async function getDocumentCategoryList() {
    getDocumentCategoryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentCategory?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )
          setDocumentCategoryList(tempDocumentTypeList)

          dispatch(setDocumentCategoriesList(tempDocumentTypeList))
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting document category list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get other document category list for filtering using react-query */
  const { mutate: getOtherDocumentCategoryListMutate } =
    useGetOtherDocumentCategoryList()

  /** Process get other document category list for filtering */
  async function getOtherDocumentCategoryList() {
    getOtherDocumentCategoryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentCategory?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )
          setOtherDocumentCategoryList(tempDocumentTypeList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting document category list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for resend reg pack */
  const { mutate: reSendRegPackMutate, isLoading: resendRegPackIsLoading } =
    useSendRegPack()

  /** Process the send reg pack */
  const resendRegPack = (candidateId, templateId) => {
    reSendRegPackMutate(
      { candidateId, templateId },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            status: 'Success',
          })
          setShowResendRegPackModal(false)
          getCandidateDetailsById(candidateDetails?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error Resending Template.',
            status: 'Error',
          })
        },
      },
    )
  }

  const CandidateProfileClasses = CN(`candidate-profile`, className)

  return (
    <div className={CandidateProfileClasses} {...restProps}>
      {(getCandidateDetailsByIdIsLoading ||
        getCandidateRefereesLoading ||
        getCandidateAuditDocumentIdsIsLoading) && <PageLoader size='xxs' />}
      {/* candidate profile top details section ------------------------------------- */}
      {candidateDetails && (
        <CandidateProfileDetailSection
          candidateDetails={candidateDetails}
          noteCount={candidateNotes?.length}
          onCandidateDetailsEditButtonClick={() => {
            setShowEditCandidateDrawer(true)
          }}
          onAddNewPhotoClick={() => {
            setShowEditPhotoModal(true)
          }}
          onEditPhotoClick={() => {
            setShowEditPhotoModal(true)
          }}
          onDeletePhotoClick={() => {
            setShowDeletePhotoModal(true)
          }}
          contactButtonOnClick={() => {
            notify({
              alertHeader: 'Working in progress',
              status: 'Warning',
            })
          }}
          onNotesClick={() => {
            setIsOpenNotesDrawer(true)
          }}
          onSendRegistrationPackBtnClick={() => {
            setIsOpenRegPackModal(true)
          }}
          setShowResendRegPackModal={setShowResendRegPackModal}
        />
      )}

      {/* candidates notes */}
      <AddDeleteCandidateNotes
        isActive={isOpenNotesDrawer}
        setIsOpenNotesDrawer={setIsOpenNotesDrawer}
        candidateNoteData={candidateNotes}
        candidateDetails={candidateDetails}
        loggedUserId={userDetails?.id}
        notify={notify}
        getCandidateNotes={getCandidateNotes}
        deleteNoteModal={deleteNoteModal}
        setDeleteNoteModal={setDeleteNoteModal}
        deleteCandidateNoteIsLoading={deleteCandidateNoteIsLoading}
      />

      {/* add new photo section */}
      <CustomizableEditProfilePictureModal
        imageSource={candidateDetails?.avatarUrl}
        isActive={showEditPhotoModal}
        onHeaderCloseButtonClick={() => {
          setShowEditPhotoModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditPhotoModal(false)
        }}
        onOverlayClick={() => {
          setShowEditPhotoModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadPhoto(files)
        }}
        leftButtonContent={
          <Button
            textTheme='blue'
            theme='white'
            onClick={() => {
              notify({
                alertHeader: 'Working in progress',
                status: 'Warning',
              })
            }}>
            Import from passport
          </Button>
        }
        primaryButtonIsLoading={createCandidateAvatarIsLoading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={
          candidateDetails?.avatarUrl
            ? 'Change candidate photo'
            : 'Add new candidate photo'
        }
        headerSubTitle='Change candidate profile picture'
      />

      {/* delete candidate Avatar confirmation modal */}
      <Modal
        isActive={showDeletePhotoModal}
        onClickPrimaryBtn={() => {
          if (candidateDetails?.id) {
            deleteCandidatePhoto(candidateDetails.id)
          }
        }}
        onClickSecondaryBtn={() => {
          setShowDeletePhotoModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateAvatarIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeletePhotoModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Photo?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>

      {/* delete candidate note confirmation modal */}
      <Modal
        isActive={deleteNoteModal.show}
        onClickPrimaryBtn={() => {
          deleteCandidateNote(deleteNoteModal?.selectedNoteId)
        }}
        onClickSecondaryBtn={() => {
          setDeleteNoteModal({
            ...deleteNoteModal,
            show: false,
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateNoteIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setDeleteNoteModal({
            ...deleteNoteModal,
            show: false,
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Note?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>

      {/* candidate profile bottom tabs section ------------------------------------- */}
      <Tabs
        className='h-auto py-5'
        tabHeaderButtonClassName='text-small text-Gray-500 py-3'
        contentClassName='h-full pt-5 pb-1'
        defaultTabId={1}
        tabDetails={tabDetails}
      />

      {/* edit candidate drawer */}
      <EditCandidate
        isActive={showEditCandidateDrawer}
        notify={notify}
        setShowEditCandidateDrawer={setShowEditCandidateDrawer}
        candidateCurrentDetails={candidateDetails}
        getCandidateDetailsById={getCandidateDetailsById}
      />

      {/* registration pack modal */}
      <RegistrationPackModal
        isActive={isOpenRegPackModal}
        onHeaderCloseButtonClick={() => {
          setIsOpenRegPackModal(false)
        }}
        setIsOpenRegPackModal={setIsOpenRegPackModal}
        candidateDetails={candidateDetails}
        getCandidateDetailsById={getCandidateDetailsById}
      />

      {/* resend reg pack confirmation modal */}
      <Modal
        isActive={showResendRegPackModal}
        onClickPrimaryBtn={() => {
          resendRegPack(
            candidateDetails?.id,
            candidateDetails?.regPackTempalteId,
          )
        }}
        onClickSecondaryBtn={() => {
          setShowResendRegPackModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: resendRegPackIsLoading,
        }}
        primaryButtonTheme='cobalt'
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowResendRegPackModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Confirm & Send'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Cobalt-50'>
            <i className='ri-error-warning-line text-Cobalt-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Resend Reg Pack?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            You have already sent the reg pack to this candidate on{' '}
            {dateToReadableString(candidateDetails?.regPackSendOn)}. Are you
            sure you want to send the reg pack again?
          </span>
        </div>
      </Modal>
    </div>
  )
}

CandidateProfile.defaultProps = {}

export default CandidateProfile
