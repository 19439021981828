import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface QuestionnaireFilterByCategoryType {
  id?: string | undefined
  createdOn?: any | undefined
  updatedOn?: any | undefined
  tenantId?: string | undefined
  name?: string | undefined
  type?: string | undefined
  isActive?: boolean | undefined
  questions: [] | undefined | null
}

export const useGetQuestionnaireFilterByCategory = () => {
  return useQuery(
    ['GET_QUESTIONNAIRE_TEMPLATE_LIST_FILTER_BY_CATEGORY'],
    async () => {
      const res = await get(`facade/Questionnaire/FilterByCategory`)
      return res.data.questionnaires as QuestionnaireFilterByCategoryType[]
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching template list',
          status: 'Error',
        })
      },
    },
  )
}
