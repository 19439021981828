import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

export interface AddRefCheckType {
  refereeId: string
  htmlResponse: string
  responseType: 'PHONE_CHECK' | 'WEB_CHECK'
  templateId: string | null
  jsonData: string | null
}

export const useAddRefCheck = () => {
  return useMutation(
    ({
      refereeId,
      htmlResponse,
      responseType,
      templateId,
      jsonData,
    }: AddRefCheckType) => {
      return postAPI(`facade/referee/${refereeId}/response/refcheck`, {
        htmlResponse,
        ResponseType: responseType,
        templateId,
        jsonData,
      })
    },
    {
      onSuccess: () => {
        Toast({
          alertHeader: `Ref check saved successfully`,
          status: 'Success',
        })
      },
      onError: () => {
        Toast({
          alertHeader: `Failure in saving ref check`,
          status: 'Error',
        })
      },
    },
  )
}

export default useAddRefCheck
