import { FC } from 'react'
import logo from 'assets/images/labourHubLogo.svg'

import { Footer } from 'components/common/Footer'

export interface AuthLayoutFullProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayoutFull: FC<AuthLayoutFullProps> = ({
  children,
}: AuthLayoutFullProps) => {
  return (
    <div className='flex absolute h-full w-full back-drop'>
      <div className='flex flex-col h-screen w-full items-center justify-center pb-[100px] '>
        {/** Labour Hub Logo */}
        <div className='flex justify-center'>
          <img src={logo} alt='' />
        </div>
        {children} <Footer />
      </div>
    </div>
  )
}

AuthLayoutFull.defaultProps = {
  className: undefined,
}

export default AuthLayoutFull
