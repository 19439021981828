import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAuditQueueList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/DocumentAudit/AuditQueue?Take=${reqBody.take}&Skip=${reqBody.skip}&DocumentCategory=${reqBody.documentCategory}&Search=${reqBody.search}&DocumentType=${reqBody.documentType}&AuditStatus=${reqBody.auditStatus}&SubmittedFromDate=${reqBody.submittedFromDate}&SubmittedToDate=${reqBody.submittedToDate}&ManagingConsultant=${reqBody.managingConsultant}&CandidateStatus=${reqBody.candidateStatus}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetAuditQueueList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['AuditQueue', reqBody], getAuditQueueList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
