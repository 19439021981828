/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { Radio, RightSideModal, TextField } from '@labourhub/labour-hub-ds'
import {
  useAddTaxInformation,
  useEditTaxInformation,
} from 'framework/api/methods'
import { taxInformationQuizList } from 'static-data/candidates/taxInformationQuiz'

import { PageLoader } from 'components/atoms'

export interface TaxInformationProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const TaxInformation: FC<TaxInformationProps> = ({
  getTaxInformation,
  onDrawerClose,
  headerTitle,
  headerSubtitle,
  primaryButtonTitle,
  isActive,
  data,
  isAddMode,
  notify,
  candidateId,
  ...restProps
}: TaxInformationProps) => {
  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [formData, setFormData] = useState({
    taxFileNo: isAddMode ? null : data?.taxFileNo,
    onPaid: isAddMode ? null : data?.onPaid,
    taxResidencyTaxPurposes: isAddMode ? null : data?.taxResidencyTaxPurposes,
    taxThresholdClaim: isAddMode ? null : data?.taxThresholdClaim,
    taxThresholdHigherEducation: isAddMode
      ? null
      : data?.taxThresholdHigherEducation,
  })

  const validateForm = () => {
    const {
      taxFileNo,
      onPaid,
      taxResidencyTaxPurposes,
      taxThresholdClaim,
      taxThresholdHigherEducation,
    } = formData
    if (
      taxFileNo &&
      onPaid &&
      taxResidencyTaxPurposes &&
      taxThresholdClaim &&
      taxThresholdHigherEducation
    ) {
      setIsPrimaryButtonDisabled(false)
    } else {
      setIsPrimaryButtonDisabled(true)
    }
  }

  useEffect(() => {
    validateForm()
  }, [formData])

  const {
    mutate: addTaxInformationMutate,
    isLoading: addTaxInformationIsLoading,
  } = useAddTaxInformation()

  /** add tax information */
  async function addTaxInformation() {
    addTaxInformationMutate(
      {
        candidateId: candidateId,
        taxInformation: formData,
      },
      {
        onSuccess: () => {
          getTaxInformation()
          notify({
            alertBody: 'Tax details added successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding tax information!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** update tax information */
  const {
    mutate: updateTaxInformationMutate,
    isLoading: updateTaxInformationIsLoading,
  } = useEditTaxInformation()

  /** Process the tax information */
  async function updateTaxInformation() {
    updateTaxInformationMutate(
      {
        candidateId: candidateId,
        id: data.id,
        taxInformation: formData,
      },
      {
        onSuccess: () => {
          getTaxInformation()
          notify({
            alertBody: 'Tax details updated successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating tax information!',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetInputFields = () => {
    setFormData({
      taxFileNo: null,
      onPaid: null,
      taxResidencyTaxPurposes: null,
      taxThresholdClaim: null,
      taxThresholdHigherEducation: null,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        if (isAddMode) {
          addTaxInformation()
        } else {
          updateTaxInformation()
        }
      }}
      onFooterCancelButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      {...restProps}>
      {(addTaxInformationIsLoading || updateTaxInformationIsLoading) && (
        <PageLoader size='xxs' />
      )}
      <div className='px-6 py-5 flex-1'>
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData?.taxFileNo}
          label='Tax File Number'
          onChange={(e) => {
            setFormData({
              ...formData,
              taxFileNo: e.target.value,
            })
          }}
        />
        {taxInformationQuizList.map((quiz, index) => (
          <div key={index} className='flex-col mb-7'>
            <span className='font-Medium text-Gray-800'>
              {index + 1}. {quiz.quiz}
            </span>
            <div className='flex-col border-b-Gray-200 border-b pb-5'>
              {quiz?.options.map((option, optionIndex) => (
                <Radio
                  className='mt-3'
                  checked={formData[quiz.formValueKey] === option}
                  id={`option${index}${optionIndex}`}
                  labelText={option}
                  helperText=''
                  name={`option${index}${optionIndex}`}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      [quiz.formValueKey]: option,
                    })
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </RightSideModal>
  )
}

TaxInformation.defaultProps = {}

export default TaxInformation
