import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetRegPackTemplateListResponse {
  message: string
  messageTitle: string | null
  regPackTemplate: RegPackTemplateListTypes[]
}

export interface RegPackTemplateListTypes {
  id: string
  name: string
  section: SectionObjectTypes[]
  reqDocuments: ReqDocumentsObjectTypes[]
  customQuestionnaireRequired: boolean
  customQuestionnaireId: string
}

export interface SectionObjectTypes {
  regPackSectionId: string
  metaData?: any
}

export interface ReqDocumentsObjectTypes {
  name: string
  documentCategoryId: string
  documentTypeId: string
  notes: string
  auditRequired: boolean
}

export const useGetRegPackTemplateList = (onSuccess) => {
  return useQuery(
    ['GET_REG_PACK_TEMPLATE'],
    async () => {
      const data = await get(`/RegPackTemplate`)
      return (data?.data as GetRegPackTemplateListResponse) || []
    },
    {
      onError: () => {
        Toast({
          alertHeader: 'Error getting registration pack template list.',
          status: 'Error',
        })
      },
      onSuccess,
      enabled: false,
    },
  )
}
