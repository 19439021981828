/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { TextField } from '@labourhub/labour-hub-ds'
import SearchVector from 'assets/images/search-vector.svg'
import CN from 'classnames'
import { motion } from 'framer-motion'

interface SearchMenuProps {
  index: number
  isActive: boolean
  setToggleMenu: any
  title: string
}

/** search menu component */
const SearchMenu: FC<SearchMenuProps> = ({
  isActive,
  title,
  setToggleMenu,
}) => {
  const dispatch = useDispatch()

  const motionVariantsTitle = {
    initialParam: {
      x: -20,
    },
    animateParam: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
    exitParam: {
      opacity: 0,
    },
  }

  return (
    <motion.div
      className={CN('absolute top-0 h-full z-10 bg-white shadow', {
        'left-0 w-0 p-0 ': !isActive,
        'right-[-204px] w-[204px] px-3 pt-4': isActive,
      })}
      initial='initialParam'
      animate='animateParam'
      exit='exitParam'
      variants={motionVariantsTitle}>
      <div className='flex h-6 justify-start items-center mb-2'>
        <i
          className='ri-arrow-left-s-line w-6 h-6 flex justify-center items-center text-small text-Gray-500 bg-white border-[1px] border-Gray-300 rounded-[7px] absolute right-[-10px] cursor-pointer'
          onClick={(e: any) => {
            e.stopPropagation()
            dispatch(setToggleMenu(false))
          }}
        />
        <span className='flex text-extra-small font-[800] text-Gray-500'>
          {title.toUpperCase()}
        </span>
      </div>

      <TextField
        iconAfter={<i className='ri-search-2-line text-small' />}
        placeholder='Search menu items '
        className='text-small'
        onChange={() => {
          // on change
        }}
        onKeyPress={() => {
          // on key press
        }}
      />
      <div className='empty-search flex flex-col  text-center justify-center items-center mt-9'>
        <img src={SearchVector} alt='search-img' />
        <span className='text-small font-Medium text-Gray-800 mt-4'>
          Having a hard time navigating?
        </span>
        <span className='text-extra-small font-Regular text-Gray-400 mt-1'>
          Search menu items here
        </span>
      </div>
    </motion.div>
  )
}

export default SearchMenu
