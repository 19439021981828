/** questionnaires category list */
export const questionnaireTemplateCategory = [
  {
    value: 'WEB_CHECK',
    label: 'Web Ref Check',
  },
  {
    value: 'PHONE_CHECK',
    label: 'Phone Ref Check',
  },
  {
    value: 'REG_PACK',
    label: 'Reg Pack',
  },
  {
    value: 'HEALTH_DECLARATION',
    label: 'Health Declaration',
  },
]

export const questionsLibraryItemList = [
  {
    id: 'short-answer',
    iconName: 'ri-text-spacing',
    itemName: 'Short Answer',
    isRequired: true,
    type: 'text',
    title: 'Question Title',
    meta: [],
  },
  {
    id: 'long-answer',
    iconName: 'ri-text-wrap',
    itemName: 'Long Answer',
    isRequired: true,
    type: 'text_area',
    title: 'Question Title',
    meta: [],
  },
  {
    id: 'rating',
    iconName: 'ri-star-line',
    itemName: 'Rating',
    isRequired: true,
    type: 'rating',
    title: 'Question Title',
    meta: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  },
  {
    id: 'multi-choice',
    iconName: 'ri-checkbox-multiple-line',
    itemName: 'Multi Choice',
    isRequired: true,
    type: 'checkbox',
    title: 'Question Title',
    meta: ['Option 1'],
  },
  {
    id: 'yes-no',
    iconName: 'ri-radio-button-line',
    itemName: 'Yes/No',
    isRequired: true,
    type: 'radio',
    title: 'Question Title',
    meta: ['Yes', 'No'],
  },
  {
    id: 'select-box',
    iconName: 'ri-arrow-down-s-line',
    itemName: 'Select Box',
    isRequired: true,
    type: 'select',
    title: 'Question Title',
    meta: ['Option 1'],
  },
  {
    id: 'detailed-yes',
    iconName: 'ri-checkbox-circle-line',
    itemName: 'Detailed Yes',
    isRequired: true,
    type: 'radio_pos_detail',
    title: 'Question Title',
    meta: ['Yes', 'No'],
  },
  {
    id: 'detailed-no',
    iconName: 'ri-close-circle-line',
    itemName: 'Detailed No',
    isRequired: true,
    type: 'radio_neg_detail',
    title: 'Question Title',
    meta: ['Yes', 'No'],
  },
]
