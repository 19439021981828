import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UseSignUpInterface {
  userName: string
  emailToken: string
  displayName: string
  fullName: string
  jobTitle: string
  phoneNumber: string
  password: string
}

export const useSignUp = () => {
  const mutate = useMutation(
    ({
      userName,
      emailToken,
      displayName,
      fullName,
      jobTitle,
      phoneNumber,
      password,
    }: UseSignUpInterface) => {
      return putAPI('/facade/Onboard/User', {
        userName: userName,
        emailToken: emailToken,
        displayName: displayName,
        fullName: fullName,
        jobTitle: jobTitle,
        phoneNumber: phoneNumber,
        password: password,
      })
    },
  )

  return mutate
}

export default useSignUp
