/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Avatar, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { CandidateReferee, useUpdateRefereeStatus } from 'framework/api/methods'

import { TextEditor } from 'components/atoms'

import { FormBuilder } from '../FormBuilder'
import { Modal, ModalFooter } from '../Modal'

export interface RefereeReportViewModalProps {
  [x: string]: any
  referee: CandidateReferee
  refereeIndex: number | undefined
  isDownload?: boolean
  getCandidateRefereesList: () => void
  isActive: boolean
  setIsActive: () => void
}

export const RefereeReportViewModal: FC<RefereeReportViewModalProps> = ({
  getCandidateById,
  getCandidateRefereesList,
  isActive,
  isDownload,
  referee,
  refereeIndex,
  setIsActive,
}: RefereeReportViewModalProps) => {
  const componentRef = useRef(null)

  const [status, setStatus] = useState<
    null | 'Incomplete' | 'Passed' | 'Failed'
  >(referee?.status)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Referee ${
      refereeIndex !== undefined &&
      (refereeIndex < 8 ? `0${refereeIndex + 1}` : refereeIndex + 1)
    } Detail Report`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      if (isDownload) {
        setIsActive()
      }
    },
  })

  useEffect(() => {
    if (isActive && componentRef.current && isDownload) {
      handlePrint()
    }
  }, [])

  /** APi call for the update status */
  const { mutate: updateStatusMutate } = useUpdateRefereeStatus()

  const updateStatus = (status: 'Incomplete' | 'Passed' | 'Failed') => {
    updateStatusMutate(
      {
        candidateId: referee?.candidateId,
        refereeId: referee?.id,
        status,
      },
      {
        onSuccess: () => {
          getCandidateById()
          getCandidateRefereesList()
        },
      },
    )
  }

  const RefereeResponseReport = React.forwardRef((props, ref) => {
    return (
      <div ref={ref as React.RefObject<HTMLDivElement>}>
        <div className='flex'>
          <div className='flex items-start p-4 bg-Gray-50 rounded-lg border border-Gray-200'>
            <Avatar
              title={`${referee.firstName} ${referee.lastName}`}
              isHeader={false}
              color='four'
              className='mr-4'
            />
            <div className='flex flex-col'>
              <div className='flex items-center mb-1'>
                <span className='text-Gray-800 font-Medium text-[18px] mr-2'>
                  {`${referee?.firstName} ${referee?.lastName}`}
                </span>
                <span className='text-Gray-800 text-extra-small font-Medium bg-Gray-200 py-[2px] px-[10px] rounded-lg'>
                  Referee
                </span>
              </div>
              <div className='flex items-center'>
                <div className='flex items-center mb-1 mr-5'>
                  <i className='ri-phone-line text-Gray-500 mr-[10px] text-extra-small' />
                  <span className='text-Gray-600 font-Medium '>
                    {referee?.phone}
                  </span>
                </div>
                <div className='flex items-center mb-1'>
                  <i className='ri-mail-line text-Gray-500 mr-[10px] text-extra-small' />
                  <span className='text-Gray-600 font-Medium '>
                    {referee?.email}
                  </span>
                </div>
              </div>

              <div className='flex items-center mb-1'>
                <i className='ri-suitcase-line text-Gray-500 mr-[10px] text-extra-small' />
                <span className='text-Gray-600 font-Medium '>
                  {referee?.jobTitle} at {referee?.company}
                </span>
              </div>

              {referee?.refereeResponse?.ipAddress && (
                <div className='flex items-center mb-1'>
                  <i className='ri-global-line text-Gray-500 mr-[10px] text-extra-small' />
                  <span
                    className={CN('font-Medium', {
                      'text-Cobalt-600':
                        !referee?.refereeResponse?.fraudDetectedDate,
                      'text-Red-600':
                        referee?.refereeResponse?.fraudDetectedDate,
                    })}>
                    {referee?.refereeResponse?.ipAddress} -{' '}
                    {referee?.refereeResponse?.city},{' '}
                    {referee?.refereeResponse?.region},{' '}
                    {referee?.refereeResponse?.country}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col items-end justify-center flex-1'>
            <span className='text-Gray-800 text-[16px] mb-2'>
              Set the status of the ref check
            </span>
            <div className='flex flex-row ml-4 text-Gray-600'>
              <div
                onClick={() => {
                  setStatus('Passed')
                  updateStatus('Passed')
                }}
                className={CN(
                  'py-[9px] px-4 flex flex-row items-center border border-Gray-300 rounded-l-[6px] cursor-pointer',
                  {
                    'bg-Green-600': status === 'Passed',
                  },
                )}>
                <i
                  className={CN(
                    'ri-checkbox-circle-line mr-[10px] text-[20px]',
                    {
                      'text-white': status === 'Passed',
                    },
                  )}
                />
                <span
                  className={CN('font-Medium', {
                    'text-white': status === 'Passed',
                  })}>
                  Passed
                </span>
              </div>
              <div
                onClick={() => {
                  setStatus('Failed')
                  updateStatus('Failed')
                }}
                className={CN(
                  'py-[9px] px-4 flex flex-row items-center border border-Gray-300 cursor-pointer',
                  {
                    'bg-Red-600': status === 'Failed',
                  },
                )}>
                <i
                  className={CN('ri-close-circle-line mr-[10px] text-[20px]', {
                    'text-white': status === 'Failed',
                  })}
                />
                <span
                  className={CN(' font-Medium', {
                    'text-white': status === 'Failed',
                  })}>
                  Failed
                </span>
              </div>
              <div
                onClick={() => {
                  setStatus('Incomplete')
                  updateStatus('Incomplete')
                }}
                className={CN(
                  'py-[9px] px-4 flex flex-row items-center border border-Gray-300 rounded-r-[6px] cursor-pointer',
                  {
                    'bg-Gray-600': status === 'Incomplete',
                  },
                )}>
                <i
                  className={CN(
                    'ri-indeterminate-circle-line mr-[10px] text-[20px]',
                    {
                      'text-white': status === 'Incomplete',
                    },
                  )}
                />
                <span
                  className={CN('font-Medium', {
                    'text-white': status === 'Incomplete',
                  })}>
                  Incomplete
                </span>
              </div>
            </div>
          </div>
        </div>

        {referee?.refereeResponse?.fraudDetectedDate && (
          <div className='mt-4 w-full flex flex-row p-4 bg-Red-50 border-Red-300 border rounded items-center'>
            <i className='ri-close-circle-fill text-Red-500 text-[16px] mr-[10px]' />
            <span className='text-Red-800 font-Medium text-base'>
              Fraud Detected. Candidate IP and referee IP are identical.
            </span>
          </div>
        )}

        <div className='mt-2 p-4'>
          {referee?.refereeResponse?.jsonSchema &&
            referee?.refereeResponse?.jsonData &&
            referee?.refereeResponse?.jsonUiSchema && (
              <FormBuilder
                schema={JSON.parse(referee?.refereeResponse?.jsonSchema)}
                formData={JSON.parse(referee?.refereeResponse?.jsonData)}
                uiSchema={JSON.parse(referee?.refereeResponse?.jsonUiSchema)}
                readonly={true}
              />
            )}
          {referee?.refereeResponse?.htmlResponse && (
            <TextEditor
              readOnly={true}
              value={referee?.refereeResponse?.htmlResponse}
              hideToolbar={true}
            />
          )}
        </div>
      </div>
    )
  })

  return (
    <Modal
      className={CN('mt-0', {
        'mt-[-1000px]': isDownload,
      })}
      headerTitle={`Referee ${
        refereeIndex !== undefined &&
        (refereeIndex < 8 ? `0${refereeIndex + 1}` : refereeIndex + 1)
      } Detailed Report`}
      customWidth='1125px'
      isFooterShow={false}
      size='lg'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive()
      }}
      onOverlayClick={() => {
        setIsActive()
      }}>
      <div className='flex flex-col w-full justify-start p-5 max-h-[720px] overflow-y-auto'>
        <RefereeResponseReport ref={componentRef} />
      </div>
      <ModalFooter>
        <div className='flex justify-start flex-1'>
          <Button
            iconLeft={<i className='ri-download-line text-[15px]' />}
            onClick={() => {
              handlePrint()
            }}
            className='mr-2'
            theme='cobalt'>
            Download
          </Button>
          <Button
            onClick={() => {
              setIsActive()
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

RefereeReportViewModal.defaultProps = {}

export default RefereeReportViewModal
