export const LicenseCertificateProps = [
  'License Name',
  'Certification Type',
  'Expiry Date',
]

export const EducationProps = [
  'Certification Name',
  'Certification Type',
  'Expiry Date',
  'Request Documents',
  'Notes',
]

export const VaccinationProps = [
  'Vaccination Type',
  'Vaccination Name',
  'Expiry Date',
]

export const MedicalIncidentProps = [
  'Incident Name',
  'Incident happened on',
  'Restrictions',
  'Work Cover Claim',
  'Incident Date',
  'COC Valid From',
  'COC Expires On',
  'Notify on COC expire',
]

export const PrescriptionsMedicalConditionsProps = [
  'Prescriptions Title',
  'Date of Issue',
  'Medical document Type',
]

export const SuperFundProps = [
  'Super Provider',
  'Super Name',
  'Membership No',
  'Super ABN',
  'Super USI',
]

export const PoliceCheckProps = [
  'Document Name',
  'Document Type',
  'Expiry Date',
]

export const VisaProps = [
  'Visa Document Name',
  'Visa Document Type',
  'Expiry Date',
]

export const OtherProps = ['Document Name', 'Document Type', 'Expiry Date']

export const PersonalDetailsProps = [
  'Name',
  'Email Address',
  'Phone',
  'Gender',
  'Birthday',
  'Address',
]

export const FinancialProps = [
  'Account No',
  'BBS No',
  'Account Name',
  'Bank Name',
  'Has Super',
  'Super Provider',
  'Super Name',
  'Membership No',
  'Super ABN',
  'Super USI',
]
