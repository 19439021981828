/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode } from 'react'
import CN from 'classnames'

export interface RegPackSectionTileProps {
  children?: ReactNode | string | number | undefined
  index?: string | undefined
  isMandatory?: boolean | undefined
  isSelected?: boolean | undefined
  onSelectionClick?: (e: any) => void | undefined
  sectionBody?: string | undefined
  sectionHeader?: string | undefined
  [x: string]: any
}

export const RegPackSectionTile: FC<RegPackSectionTileProps> = ({
  children,
  index,
  isMandatory,
  isSelected,
  onSelectionClick,
  sectionBody,
  sectionHeader,
  ...restProps
}: RegPackSectionTileProps) => {
  return (
    <div className='flex flex-col' {...restProps}>
      <div
        className={CN(
          'flex flex-row w-[276px] border-[2px] border-Gray-200 rounded-lg bg-white',
          { 'opacity-50': isMandatory, 'cursor-pointer': !isMandatory },
          { 'border-Cobalt-400': isSelected },
        )}
        onClick={() => {
          onSelectionClick && onSelectionClick(isSelected)
        }}>
        {/** section body */}
        <div className='flex flex-col pl-5 py-3'>
          <div className='flex gap-1'>
            <span className='font-SemiBold text-small text-Gray-800'>
              {index}
            </span>

            <span className='font-SemiBold text-small text-Gray-800'>
              {sectionHeader}
            </span>
          </div>

          <div className='w-[175px] pl-7 font-Regular text-extra-small text-Gray-500 pt-1'>
            {sectionBody}
          </div>
        </div>

        <div className='flex items-center justify-center'>
          <span
            className={CN(
              'flex justify-center items-center w-7 h-7 ml-7 rounded-full border-[1px] border-Gray-200',
              { 'bg-Cobalt-400': isSelected },
            )}>
            <i className='ri-check-fill text-white text-[22px]'></i>
          </span>
        </div>
      </div>

      <div className='flex justify-end pr-2'>{children}</div>
    </div>
  )
}

RegPackSectionTile.defaultProps = {}

export default RegPackSectionTile
