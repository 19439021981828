/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAgencyProfileLocationsInterface {}

export const useGetAgencyProfileLocations = () => {
  const mutate = useMutation(({}: GetAgencyProfileLocationsInterface) => {
    return get(`/facade/AgencyProfile`)
  })

  return mutate
}

export default useGetAgencyProfileLocations
