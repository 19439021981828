import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddBankAccountProps {
  bankAccountData?: any
  candidateId?: any
}

export const useAddBankAccount = () => {
  const mutate = useMutation(
    ({ bankAccountData, candidateId }: AddBankAccountProps) => {
      return postAPI(`/Candidate/${candidateId}/BankAccount`, {
        name: bankAccountData.name,
        accountNumber: bankAccountData.bankAccountNumber,
        bankId: bankAccountData.bankId,
        countryCode: bankAccountData.countryCode,
        bsbNumber: bankAccountData.bsbNumber,
      })
    },
  )

  return mutate
}

export default useAddBankAccount
