import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deleteCandidateTagInterface {
  TagName?: string
  CandidateId?: string
}

export const useDeleteCandidateTag = () => {
  const mutate = useMutation(
    ({ TagName, CandidateId }: deleteCandidateTagInterface) => {
      return deleteAPI(`/Candidate/${CandidateId}/Tag/${TagName}`)
    },
  )

  return mutate
}

export default useDeleteCandidateTag
