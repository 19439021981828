import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addNewLocationProps {
  id?: any
  locationDetails?: any
}

export const useAddNewLocation = () => {
  const mutate = useMutation(({ id, locationDetails }: addNewLocationProps) => {
    return postAPI(`/facade/AgencyProfile/${id}/Location`, {
      name: locationDetails?.locationName,
      address: {
        lineOne: locationDetails?.streetName,
        suburb: locationDetails?.suburb,
        state: locationDetails?.state,
        country: locationDetails.country,
        city: locationDetails?.suburb,
        lineTwo: '',
        stateCode: locationDetails?.stateCode,
        countryCode: locationDetails?.countryCode,
        postCode: locationDetails?.postCode,
        latitude:
          locationDetails?.latitude !== '' ? locationDetails?.latitude : 0,
        longitude:
          locationDetails?.longitude !== '' ? locationDetails?.longitude : 0,
      },
      phoneNumber: locationDetails?.phoneNo,
      email: locationDetails?.email,
    })
  })

  return mutate
}

export default useAddNewLocation
