/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetCandidateList,
  useGetCandidateStateList,
  useGetCSVList,
  useGetPrimaryClientList,
  useGetSuburbList,
  useGetTagList,
} from 'framework/api/methods'
import { statusCodeList } from 'static-data/candidates/statusCode'

import { TableHeader, UITable } from 'components/common/UITable'
import { AddNewCandidate } from 'components/Drawers/Candidate'

import { CandidateListColumn } from './CandidateListColumn'

export interface CandidateListProps {
  [x: string]: any
}

export const CandidateList: FC<CandidateListProps> = ({
  className,
  ...restProps
}: CandidateListProps) => {
  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [candidateTableColumn, setCandidateTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<any>()
  const [filterActive, setFilterActive] = useState(false)
  const [suburbList, setSuburbList] = useState<any>({})
  const [selectedSuburb, setSelectedSuburb] = useState<any>()
  const [stateList, setStateList] = useState<any>({})
  const [selectedState, setSelectedState] = useState<any>()
  const [primaryClientList, setPrimaryClientList] = useState<any>({})
  const [selectedPrimaryClient, setSelectedPrimaryClient] = useState<any>()
  const [tagList, setTagList] = useState<any>({})
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [pageNumber, setPageNumber] = useState(1)

  /** CSV Export  states & Variables */
  const [showCSVExport, setShowCSVExport] = useState(false)
  const [csvCandidateList, setCsvCandidateList] = useState([])

  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  /** Add new candidate drawer state */
  const [isAddCandidateDrawerOpen, setIsAddCandidateDrawerOpen] =
    useState(false)

  const csvLinkElement = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  /** Since filter By Last WorkPlace is not implemented yet by BE team, here used temporary option list */
  const lastWorkedPlace = [
    {
      value: 'Dole',
      label: 'Dole',
    },
    {
      value: 'Harvey Norman',
      label: 'Harvey Norman',
    },
  ]

  /** candidate List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** candidate List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All candidates List */
  const {
    refetch: getTableData,
    isLoading: getCandidateListIsLoading,
    isFetching: getCandidateListIsFetching,
  } = useGetCandidateList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      status: selectedStatus?.value || '',
      suburb: selectedSuburb?.value || '',
      state: selectedState?.value || '',
      primaryClient: selectedPrimaryClient?.value || '',
      tags: selectedTags || [],
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  /** API Call for get suburb list for filtering using react-query */
  const { mutate: getSuburbListMutate } = useGetSuburbList()

  /** Process get suburb list for filtering */
  async function getSuburbList() {
    getSuburbListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting suburb list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get state list for filtering using react-query */
  const { mutate: getStateListMutate } = useGetCandidateStateList()

  /** Process get state list for filtering */
  async function getStateList() {
    getStateListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting state list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get primary client list for filtering using react-query */
  const { mutate: getPrimaryClientListMutate } = useGetPrimaryClientList()

  /** Process get state list for filtering */
  async function getPrimaryClientList() {
    getPrimaryClientListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempPrimaryClientList = successData?.client?.map(
            (item: any) => {
              return {
                ...item,
                value: item.name,
                label: item.name,
              }
            },
          )
          setPrimaryClientList(tempPrimaryClientList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting primary client list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get tag list for filtering using react-query */
  const { mutate: getTagListMutate } = useGetTagList()

  /** Process get tag list for filtering */
  async function getTagList() {
    getTagListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempTagList = successData?.systemTags?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setTagList(tempTagList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting tag list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    getTableData()
    getSuburbList()
    getStateList()
    getPrimaryClientList()
    getTagList()
  }, [paginationVariables])

  /** candidate list table column handling */
  useEffect(() => {
    if (tableData?.candidates && tableData?.candidates?.length > 0) {
      const columns: any = CandidateListColumn(navigate)
      setCandidateTableColumn(columns)
    }
  }, [tableData])

  /** API Call for getting candidates export as CSV using react-query */
  const { mutate: getCSVListMutate } = useGetCSVList()

  /** Process the candidate list export as CSV */
  async function getCSVList(
    suburb: string,
    state: string,
    primaryClient: string,
    search: string,
    status: string,
    tags: any,
  ) {
    getCSVListMutate(
      {
        suburb: suburb,
        state: state,
        primaryClient: primaryClient,
        search: search,
        status: status,
        tags: tags,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set data to csv document */
          setCsvCandidateList(successData)

          /** trigger csv element */
          csvLinkElement?.current?.link.click()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : '',
            alertBody: errData.message ? errData.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  const handleExportCSV = () => {
    getCSVList(
      selectedSuburb?.value || '',
      selectedState?.name || '',
      selectedPrimaryClient?.value || '',
      paginationVariables?.search,
      selectedStatus?.value || '',
      selectedTags || [],
    )
    setShowCSVExport(!showCSVExport)
  }

  const applyFilter = () => {
    setFilterActive(true)
    getTableData()
    setIsFilterMenuOpen(false)
  }

  const resetFilter = () => {
    setSelectedStatus('')
    setSelectedSuburb('')
    setSelectedState('')
    setSelectedPrimaryClient('')
    setSelectedTags([])
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const CandidateListClasses = CN(`candidate-list w-full h-full`, className)

  return (
    <div className={CandidateListClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={'Search by name, email or phone'}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}>
          {/** Adding candidate button */}
          <Button
            className='ml-2'
            onClick={() => {
              setIsAddCandidateDrawerOpen(true)
            }}
            iconLeft={<i className='ri-user-add-line' />}
            theme='gradient'>
            Add Candidate
          </Button>

          {/** CSV  Export button*/}
          <div className='pl-2 relative'>
            <Button
              icon={<i className='ri-more-2-fill' />}
              isIconOnly
              textTheme='blue'
              theme='white'
              onClick={() => {
                setShowCSVExport(!showCSVExport)
              }}
            />
          </div>

          {showCSVExport && (
            <div
              className='flex justify-start w-[140px] shadow-xl border-[2px] border-Gray-200 rounded-md py-3 pl-3 cursor-pointer absolute top-[152px] right-[54px] z-40 bg-white'
              onClick={handleExportCSV}>
              CSV Export
            </div>
          )}

          {/* for csv export */}
          <CSVLink
            filename='candidate-list.csv'
            data={csvCandidateList}
            ref={csvLinkElement}
          />
        </TableHeader>
        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex justify-center items-end px-4 w-full pt-3 pb-4 z-[10]'>
                <SimpleSelect
                  label='By Status'
                  placeholder='All'
                  value={selectedStatus}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedStatus(selectedItem)
                  }}
                  options={statusCodeList}
                />

                <SimpleSelect
                  label='By Suburb'
                  placeholder='All'
                  value={selectedSuburb}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedSuburb(selectedItem)
                  }}
                  options={suburbList}
                />

                <SimpleSelect
                  label='By State'
                  placeholder='All'
                  value={selectedState}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedState(selectedItem)
                  }}
                  options={stateList}
                />

                <SimpleSelect
                  label='By Main Employer'
                  placeholder='All'
                  value={selectedPrimaryClient}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedPrimaryClient(selectedItem)
                  }}
                  options={primaryClientList}
                />
              </div>

              <div className='flex justify-start items-end pl-3 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='By Last WorkPlace'
                  placeholder='All'
                  className='w-1/4 pr-5'
                  options={lastWorkedPlace}
                />

                <div className='flex flex-col w-1/2 max-w-[740px] pr-5'>
                  <span className='font-Medium text-small text-Gray-800'>
                    By Tag
                  </span>

                  <Select
                    isMulti
                    value={selectedTags}
                    placeholder='All'
                    className=''
                    onChange={(selectedItem: any) => {
                      setSelectedTags(selectedItem)
                    }}
                    options={tagList}
                  />
                </div>

                <Button
                  className='mr-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedStatus?.value &&
                    !selectedSuburb?.value &&
                    !selectedState?.value &&
                    !selectedPrimaryClient?.value &&
                    selectedTags?.length <= 0
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.candidates || []}
            isLoading={getCandidateListIsLoading || getCandidateListIsFetching}
            className=''
            columns={candidateTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {/** Add candidate drawer */}
      <AddNewCandidate
        notify={notify}
        getTableData={getTableData}
        isActive={isAddCandidateDrawerOpen}
        setIsAddCandidateDrawerOpen={setIsAddCandidateDrawerOpen}
        primaryClientList={primaryClientList}
      />
    </div>
  )
}

CandidateList.defaultProps = {}

export default CandidateList
