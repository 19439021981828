/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Avatar, Badge } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'
import { AuditQueueTab } from 'static-data/audit/auditTabs'
import {
  setDocumentIdsList,
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedSection,
} from 'store/reducers/audit/auditSlice'

import { dateToReadableString } from 'utils/dateToReadableString'

export const AuditQueueListColumn = (dispatch: any) => {
  const COLUMNS = [
    {
      Header: 'USER',
      accessor: 'user',
      forSorting: 'firstname',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[212px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='w-full flex'>
            <Avatar
              imageURL={
                original?.imgUrl ? original?.imgUrl : candidateDefaultLogo
              }
              titleClassName='flex max-w-[200px] line-clamp-1'
              size='xs'
              color='one'
              title={original?.candidateName}
              tag={original?.candidateEmail}
            />

            {/* Showing link to candidate profile icons */}
            <div
              className='link-icon cursor-pointer'
              onClick={() => {
                window.open(
                  `/candidate-list/candidate-profile?&candidate_id=${original.candidateId}&candidate_name=${original.candidateName}`,
                )
              }}>
              <i className='ri-external-link-fill text-Cobalt-600 pl-1'></i>
            </div>
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[112px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge
              size='small'
              theme={
                original?.status === 'Active'
                  ? 'green'
                  : original?.status === 'Deactive'
                  ? 'red'
                  : undefined
              }>
              {original?.status === 'Deactive' ? 'Inactive' : original?.status}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'TOTAL DOCS',
      accessor: 'totalDocs',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[119px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.documentTotalCount}</div>
      },
    },
    {
      Header: 'PENDING AUDIT',
      accessor: 'pendingAudit',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[124px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.pendingCount}</div>
      },
    },
    {
      Header: 'WAITING FOR DOC',
      accessor: 'waitingForDoc',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[124px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.waitingDocumentCount}</div>
      },
    },
    {
      Header: 'AUDIT FAILED',
      accessor: 'auditFailed',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[107px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.auditFailedCount}</div>
      },
    },
    {
      Header: 'LAST SUBMITTED ON',
      accessor: 'lastSubmittedOn',
      forSorting: 'updatedon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[171px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.lastSubmittedOn
              ? dateToReadableString(original?.lastSubmittedOn)
              : `-`}
          </div>
        )
      },
    },
    {
      Header: 'MANAGING CONSULTANT',
      accessor: 'managingConsultant',
      forSorting: 'managingconsultant',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[157px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.managingConsultant ? original?.managingConsultant : '-'}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[64px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div
            onClick={() => {
              /** selected candidate states set to store */
              dispatch(
                setSelectedCandidate({
                  name: original?.candidateName,
                  id: original?.candidateId,
                }),
              )

              /** selected document id list  set to store */
              dispatch(setDocumentIdsList(original?.documentIds))

              /** clear selected section from state */
              dispatch(setSelectedSection(null))

              /** audit check modal open handle from state */
              dispatch(
                setIsAuditModal({
                  isOpen: true,
                  isFooterShow: false,
                  isActiveButton: false,
                  tab: AuditQueueTab,
                }),
              )
            }}>
            <i
              className='ri-play-circle-line text-heading-5 text-Cobalt-600 cursor-pointer'
              data-tip='Start Audit'
              data-for='Start Audit'
            />

            <ReactTooltip
              place='top'
              id='Start Audit'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
            />
          </div>
        )
      },
    },
  ]

  return COLUMNS
}

export default AuditQueueListColumn
