/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface CandidateMedicalIncidentCardProps {
  className?: string | undefined
  onAddNewClick?: any | undefined
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  onViewClick?: any | undefined
  [x: string]: any
}

export const CandidateMedicalIncidentCard: FC<
  CandidateMedicalIncidentCardProps
> = ({
  candidateMedicalIncidentList,
  className,
  getDocumentSignedUrlsById,
  getCandidateMedicalIncidentsByIdIsLoading,
  onAddNewClick,
  onDeleteClick,
  onEditClick,
  onViewClick,
  setSelectedMedicalIncidentDetails,
  setShowIncidentMoreButtonOption,
  setShowMedicalMoreButtonOption,
  setShowVaccineMoreButtonOption,
  showIncidentMoreButtonOption,
  ...restProps
}: CandidateMedicalIncidentCardProps) => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  const PrescriptionCardClasses = CN(
    `w-full h-fit border-[1px] bg-white border-Gray-200 rounded-md pb-[18px] relative`,
    className,
  )

  return (
    <div className={PrescriptionCardClasses} {...restProps}>
      {getCandidateMedicalIncidentsByIdIsLoading && <PageLoader size='xxs' />}
      <div className='flex justify-between pt-[19px] pl-5 pr-4'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Medical Incidents
        </span>

        <Button
          onClick={onAddNewClick}
          textTheme='blue'
          size='sm'
          theme='white'>
          Add New Incident
        </Button>
      </div>

      <div className='flex flex-col divide-y-[1px] divide-Gray-200'>
        {candidateMedicalIncidentList?.length > 0 &&
          candidateMedicalIncidentList?.map((item: any, index: any) => (
            <div
              className='flex justify-between items-start px-6 py-4'
              key={index}>
              <div className='flex'>
                <div className='flex flex-col'>
                  {/** Incident name and status */}
                  <div className='flex'>
                    <span className='font-SemiBold text-Gray-800 max-w-[500px] line-clamp-1'>
                      {item?.title}
                    </span>

                    {item?.isWorkCover && (
                      <>
                        <div className='flex items-center pl-2'>
                          <i className='ri-error-warning-fill text-small text-TerraCotta-500'></i>
                        </div>

                        <div className='pl-[6px]'>
                          <Badge theme='primary'>Work Cover Claim</Badge>
                        </div>
                      </>
                    )}
                  </div>

                  {/** Incident Location and Date */}
                  <div className='flex pt-[10px]'>
                    <span className='font-Regular text-small text-Gray-600'>
                      Incident happened on:
                    </span>

                    <span className='font-Medium text-small text-Gray-800 pl-2'>
                      {item?.locationName ? item?.locationName : ' - '} on{' '}
                      {item?.date ? dateToReadableString(item?.date) : ' - '}
                    </span>
                  </div>

                  {/** Set of Restrictions  */}
                  <div className='flex pt-2'>
                    <span className='font-Regular text-small text-Gray-600'>
                      Restrictions:
                    </span>

                    <span className='font-Medium text-small text-Gray-800 pl-2 max-w-[500px] line-clamp-1'>
                      {item.restrictions.length > 0
                        ? `${item.restrictions} `
                        : ' - '}
                    </span>
                  </div>

                  {/** Attachment Count and View Button */}
                  <div className='flex pt-2'>
                    <span className='font-Regular text-small text-Gray-600'>
                      Attachments:
                    </span>

                    <span className='font-Medium text-small text-Gray-800 px-2'>
                      {item.attachmentCount ? item.attachmentCount : ' 0 '}
                    </span>

                    <Button
                      className={CN('underline', {
                        'opacity-30 cursor-auto': item?.attachmentCount === 0,
                        '': item?.attachmentCount > 0,
                      })}
                      onClick={(e: any) => {
                        if (item?.attachmentCount > 0) {
                          e.stopPropagation()
                          setSelectedMedicalIncidentDetails(item)
                          getDocumentSignedUrlsById(item)

                          onViewClick && onViewClick()
                        }
                      }}
                      textTheme='blue'
                      theme='link'>
                      View
                    </Button>
                  </div>

                  {/** Medical Review status */}
                  <div className='pt-[10px]'>
                    <Badge
                      theme={
                        item.medicalStatus === 'Medical Review Pending'
                          ? 'yellow'
                          : item.medicalStatus === 'Medical Review Done'
                          ? 'green'
                          : undefined
                      }>
                      {item.medicalStatus}
                    </Badge>
                  </div>
                </div>
              </div>

              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowMedicalMoreButtonOption({
                      index: index,
                    })

                    setShowVaccineMoreButtonOption({
                      index: index,
                    })

                    setShowIncidentMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showIncidentMoreButtonOption.index
                          ? !showIncidentMoreButtonOption.isEnable
                          : true,
                    })
                    setSelectedMedicalIncidentDetails(item)
                  }}
                />

                {/** More button drop down options */}
                {showIncidentMoreButtonOption.isEnable &&
                  index === showIncidentMoreButtonOption.index && (
                    <div className='flex flex-col absolute right-[30px] top-[42px] w-[140px] pt-2 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40'>
                      <span
                        className='font-Regular text-Gray-800 pl-3 mb-4 hover:bg-Gray-50'
                        onClick={() => {
                          onEditClick && onEditClick()
                          setShowIncidentMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Edit
                      </span>

                      <span
                        className={CN('font-Regular text-Gray-800 pl-3 mb-4', {
                          'hover:bg-Gray-50':
                            item?.medicalStatus === 'Medical Review Pending',

                          'opacity-50 hover:bg-Gray-white':
                            item?.medicalStatus !== 'Medical Review Pending',
                        })}
                        onClick={(e: any) => {
                          if (
                            item?.medicalStatus === 'Medical Review Pending'
                          ) {
                            e.stopPropagation()

                            setShowIncidentMoreButtonOption({
                              index: -1,
                              isEnable: false,
                            })

                            dispatch(
                              setSelectedDocumentId(item?.medicalIncidentId),
                            )

                            /** selected candidate states set to store */
                            dispatch(
                              setSelectedCandidate({
                                name: candidateName,
                                id: candidateId,
                              }),
                            )

                            /** audit check modal open handle from state */
                            dispatch(
                              setIsAuditModal({
                                isOpen: true,
                                isFooterShow: false,
                                isActiveButton: false,
                                tab: null,
                              }),
                            )
                          }
                        }}>
                        Perform Audit
                      </span>

                      <span
                        className='font-Regular text-Red-500 pl-3 mb-4 hover:bg-Gray-50'
                        onClick={() => {
                          onDeleteClick && onDeleteClick()
                          setShowIncidentMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}

        {candidateMedicalIncidentList?.length <= 0 && (
          <div className='flex flex-col justify-center items-center'>
            <i className='ri-shield-cross-line text-[100px] text-Gray-300'></i>

            <span className='font-Medium text-heading-3 text-Gray-500'>
              Not Incident Found
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

CandidateMedicalIncidentCard.defaultProps = {
  className: undefined,
  onAddNewClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
  onViewClick: undefined,
}

export default CandidateMedicalIncidentCard
