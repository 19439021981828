/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetActivatedList } from 'framework/api/methods'
import moment from 'moment'

import { CalendarRangePicker } from 'components/atoms/CalendarRangePicker'
import { TableHeader, UITable } from 'components/common/UITable'

import { ActivatedListColumn } from './ActivatedListColumn'

export interface ActivatedListProps {
  [x: string]: any
}

export const ActivatedList: FC<ActivatedListProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  auditorsList,
  ...restProps
}: ActivatedListProps) => {
  const dispatch = useDispatch()
  const { isAuditModal } = useSelector((state: any) => state.audit)

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [activatedTableColumn, setActivatedTableColumn] = useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState<any>(
    {
      categoryName: '',
      categoryId: '',
    },
  )

  const [selectedDocumentType, setSelectedDocumentType] = useState<any>({
    typeName: '',
    typeId: '',
  })

  const [selectedSubmittedDateRange, setSelectedSubmittedDateRange] =
    useState<any>({
      from: '',
      to: '',
    })

  const [selectedAuditCompletedDateRange, setSelectedAuditCompletedDateRange] =
    useState<any>({
      from: '',
      to: '',
    })

  const [selectedAuditedPerson, setSelectedAuditedPerson] = useState<any>({
    name: '',
    id: '',
  })

  const [filterVariables, setFilterVariables] = useState<any>({
    documentCategory: '',
    documentType: '',
    auditedPerson: '',
    submittedFromDate: '',
    submittedToDate: '',
    auditCompletedFromDate: '',
    auditCompletedToDate: '',
  })

  const notify = (props: any) => Toast(props)

  /** Activated List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** Activated List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All activated List */
  const {
    refetch: getTableData,
    isLoading: getActivatedListIsLoading,
    isFetching: getActivatedListIsFetching,
  } = useGetActivatedList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      documentCategory: filterVariables?.documentCategory || '',
      search: paginationVariables.search,
      documentType: filterVariables?.documentType || '',
      auditedPerson: filterVariables?.auditedPerson || '',
      submittedFromDate: filterVariables?.submittedFromDate || '',
      submittedToDate: filterVariables?.submittedToDate || '',
      auditCompletedFromDate: filterVariables?.auditCompletedFromDate || '',
      auditCompletedToDate: filterVariables?.auditCompletedToDate || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    getTableData()
  }, [paginationVariables])

  /** for refetch table list when close audit check modal */
  useEffect(() => {
    !isAuditModal?.isOpen && getTableData()
  }, [isAuditModal])

  /** Activated list table column handling */
  useEffect(() => {
    if (
      tableData?.auditPassedModels &&
      tableData?.auditPassedModels?.length > 0
    ) {
      const columns: any = ActivatedListColumn(dispatch)
      setActivatedTableColumn(columns)
    }
  }, [tableData])

  const applyFilter = () => {
    setFilterActive(true)
    getTableData()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      documentCategory: selectedDocumentCategory?.categoryName || '',
      documentType: selectedDocumentType?.typeName || '',
      auditedPerson: selectedAuditedPerson?.name || '',
      submittedFromDate: selectedSubmittedDateRange?.from || '',
      submittedToDate: selectedSubmittedDateRange?.to || '',
      auditCompletedFromDate: selectedAuditCompletedDateRange?.from || '',
      auditCompletedToDate: selectedAuditCompletedDateRange?.to || '',
    })
  }

  const resetFilter = () => {
    setSelectedDocumentCategory('')
    setSelectedDocumentType('')
    setSelectedAuditedPerson('')
    setSelectedSubmittedDateRange('')
    setSelectedAuditCompletedDateRange('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setFilterVariables({
      documentCategory: '',
      documentType: '',
      auditedPerson: '',
      submittedFromDate: '',
      submittedToDate: '',
      auditCompletedFromDate: '',
      auditCompletedToDate: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const ActivatedListClasses = CN(`activated-list w-full h-full`, className)

  return (
    <div className={ActivatedListClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={'Search by candidate and email'}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}></TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex items-end px-4 w-full pt-3 pb-4 z-[10]'>
                <SimpleSelect
                  label='By Document Category'
                  placeholder='All'
                  value={
                    selectedDocumentCategory?.categoryName
                      ? {
                          label: selectedDocumentCategory?.categoryName,
                          value: selectedDocumentCategory?.categoryId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentCategory({
                      ...selectedDocumentCategory,
                      categoryId: selectedItem.id,
                      categoryName: selectedItem.name,
                    })
                  }}
                  options={documentCategoryList}
                />

                <SimpleSelect
                  label='By Document Type'
                  placeholder='All'
                  value={
                    selectedDocumentType?.typeName
                      ? {
                          label: selectedDocumentType?.typeName,
                          value: selectedDocumentType?.typeId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentType({
                      ...selectedDocumentType,
                      typeId: selectedItem.id,
                      typeName: selectedItem.name,
                    })
                  }}
                  options={documentTypeList}
                />

                <div className='flex flex-col items-start justify-center'>
                  <span className='text-Gray-800 font-Medium text-small mb-1'>
                    Documents Submitted On
                  </span>

                  <CalendarRangePicker
                    className='mr-5'
                    defaultValue={
                      selectedSubmittedDateRange?.from
                        ? [
                            new Date(selectedSubmittedDateRange?.from),
                            new Date(selectedSubmittedDateRange?.to),
                          ]
                        : undefined
                    }
                    onChange={(selectedRange) => {
                      if (
                        selectedRange[0] !== null &&
                        selectedRange[1] !== null
                      ) {
                        setSelectedSubmittedDateRange({
                          ...selectedSubmittedDateRange,
                          from: moment(selectedRange[0])
                            .format('YYYY-MM-DD')
                            .toString(),
                          to: moment(selectedRange[1])
                            .format('YYYY-MM-DD')
                            .toString(),
                        })
                      }
                    }}
                  />
                </div>

                <SimpleSelect
                  label='By Audit Completed'
                  placeholder='All'
                  value={
                    selectedAuditedPerson?.name
                      ? {
                          label: selectedAuditedPerson?.name,
                          value: selectedAuditedPerson?.id,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedAuditedPerson({
                      ...selectedAuditedPerson,
                      name: selectedItem?.label,
                      id: selectedItem?.value,
                    })
                  }}
                  options={auditorsList}
                />
              </div>

              <div className='flex justify-start items-end pl-3 pt-3 pb-4 z-[4]'>
                <div className='flex flex-col items-start justify-center'>
                  <span className='text-Gray-800 font-Medium text-small mb-1'>
                    Audit Completed on
                  </span>

                  <CalendarRangePicker
                    className='mr-5'
                    defaultValue={
                      selectedAuditCompletedDateRange?.from
                        ? [
                            new Date(selectedAuditCompletedDateRange?.from),
                            new Date(selectedAuditCompletedDateRange?.to),
                          ]
                        : undefined
                    }
                    onChange={(selectedRange) => {
                      if (
                        selectedRange[0] !== null &&
                        selectedRange[1] !== null
                      ) {
                        setSelectedAuditCompletedDateRange({
                          ...selectedAuditCompletedDateRange,
                          from: moment(selectedRange[0])
                            .format('YYYY-MM-DD')
                            .toString(),
                          to: moment(selectedRange[1])
                            .format('YYYY-MM-DD')
                            .toString(),
                        })
                      }
                    }}
                  />
                </div>

                <Button
                  className='mr-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedDocumentCategory?.categoryName &&
                    !selectedDocumentType?.typeName &&
                    !selectedAuditedPerson?.name &&
                    !selectedSubmittedDateRange?.from &&
                    !selectedSubmittedDateRange?.to &&
                    !selectedAuditCompletedDateRange?.from &&
                    !selectedAuditCompletedDateRange?.to
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(64vh-70px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.auditPassedModels || []}
            isLoading={getActivatedListIsLoading || getActivatedListIsFetching}
            className=''
            columns={activatedTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}

ActivatedList.defaultProps = {}

export default ActivatedList
