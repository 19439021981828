import React, { FC, useEffect, useState } from 'react'
import { Tabs, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useGetAuditorsList,
  useGetDocumentCategoryList,
  useGetDocumentTypeList,
  useGetManagingConsultantList,
} from 'framework/api/methods'

import {
  ActivatedTabView,
  AuditFailedTabView,
  AuditQueueTabView,
  WaitingForActivationTabView,
  WaitingForDocumentTabView,
} from './auditTabs'

export interface AuditProps {
  [x: string]: any
}

export const Audit: FC<AuditProps> = ({
  className,
  ...restProps
}: AuditProps) => {
  const AuditClasses = CN('audit ', className, {})

  /** documents category states */
  const [documentCategoryList, setDocumentCategoryList] = useState<any>({})

  /** documents type states */
  const [documentTypeList, setDocumentTypeList] = useState<any>({})

  /** API Call for get document category list for filtering using react-query */
  const { mutate: getDocumentCategoryListMutate } = useGetDocumentCategoryList()

  /** Process get document category list for filtering */
  async function getDocumentCategoryList() {
    getDocumentCategoryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentCategoryList = successData?.documentCategory?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )
          setDocumentCategoryList(tempDocumentCategoryList)
        },
        onError: () => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: 'Error getting document category list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get document type list for filtering using react-query */
  const { mutate: getDocumentTypeListMutate } = useGetDocumentTypeList()

  /** Process get document type list for filtering */
  async function getDocumentTypeList() {
    getDocumentTypeListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentTypes?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )
          setDocumentTypeList(tempDocumentTypeList)
        },
        onError: () => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: 'Error getting document type list. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Handling request for managing consultant List*/
  const { data: managingConsultantList } = useGetManagingConsultantList()

  /** API Handling request for auditors List*/
  const { data: auditorsList } = useGetAuditorsList()

  /** audit queue tab details */
  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Audit Queue',
      tabContent: (
        <AuditQueueTabView
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          managingConsultantList={managingConsultantList}
        />
      ),
    },
    {
      id: 2,
      tabButtonContent: 'Waiting for Document',
      tabContent: (
        <WaitingForDocumentTabView
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
        />
      ),
    },
    {
      id: 3,
      tabButtonContent: 'Waiting for Activation',
      tabContent: (
        <WaitingForActivationTabView
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      ),
    },
    {
      id: 4,
      tabButtonContent: 'Activated',
      tabContent: (
        <ActivatedTabView
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      ),
    },
    {
      id: 5,
      tabButtonContent: 'Audit Failed',
      tabContent: (
        <AuditFailedTabView
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      ),
    },
  ]

  useEffect(() => {
    // Get document category list
    getDocumentCategoryList()

    // Get document type list
    getDocumentTypeList()
  }, [])

  return (
    <div className={AuditClasses} {...restProps}>
      <Tabs
        className='h-auto pb-7'
        tabHeaderButtonClassName='text-small text-Gray-500 py-3'
        contentClassName='h-full pt-8 pb-1 overflow-y-auto'
        defaultTabId={1}
        tabDetails={tabDetails}
      />
    </div>
  )
}

Audit.defaultProps = {}

export default Audit
