import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetVaccinationByIdType {
  id?: string | null
  documentId?: string | null
  candidateId?: string | null
  name?: string | null
  categoryId?: string | null
  category?: string | null
  typeId?: string | null
  type?: string | null
  expiryOn?: any
  dueOn?: any
  attachmentUrls?: string[] | undefined | null
  preSignedUrls?: string[] | undefined | null
  notes?: string | null
  auditRequired: true
  status?: string | null
  isArchived: true
  createdOn?: any
  updatedOn?: any
}

export const useGetVaccinationById = (
  candidateId: string,
  documentId: string,
) => {
  return useQuery(
    ['GET_VACCINATION_BY_ID'],
    async () => {
      const res = await get(
        `/facade/Candidate/${candidateId}/Vaccination/${documentId}`,
      )
      return res.data.vaccinationDTO as GetVaccinationByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
