import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'

export interface EligibilityPoliceCheckDetailsCardProps {
  onEditClick?: () => void
  onAddClick?: () => void
  [x: string]: any
}

export const EligibilityPoliceCheckDetailsCard: FC<
  EligibilityPoliceCheckDetailsCardProps
> = ({
  className,
  isLoading,
  policeCheckDetailsData,
  onAddClick,
  onEditClick,
  ...restProps
}: EligibilityPoliceCheckDetailsCardProps) => {
  const EligibilityPoliceCheckDetailsCardClasses = CN(
    `eligibility-police-check-details-card border bg-white border-Gray-200 rounded-md pb-8 pt-4 px-5 mt-5 relative min-h-[200px]`,
    className,
  )

  return (
    <div className={EligibilityPoliceCheckDetailsCardClasses} {...restProps}>
      {/* loader */}
      {isLoading && <PageLoader size='xxs' />}

      {/* top header */}
      <div className='flex justify-between'>
        {/* header */}
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Police Check Details
        </span>

        {/* edit button */}

        <Button
          onClick={() => {
            policeCheckDetailsData
              ? onEditClick && onEditClick()
              : onAddClick && onAddClick()
          }}
          textTheme='blue'
          size='sm'
          theme='white'>
          {policeCheckDetailsData ? 'Edit' : 'Add'}
        </Button>
      </div>

      {policeCheckDetailsData && (
        <div className='flex flex-col pt-5'>
          {/* status section */}
          <div className='flex flex-col'>
            <span className='text-small text-Gray-700 font-Medium'>Status</span>

            <span
              className={CN('font-Medium pt-1', {
                'text-Green-500': policeCheckDetailsData?.status === 'Passed',
                'text-Red-500': policeCheckDetailsData?.status === 'Failed',
                'text-white': !policeCheckDetailsData?.status,
              })}>
              {policeCheckDetailsData?.status
                ? policeCheckDetailsData?.status
                : 'N/A'}
            </span>
          </div>

          {/* address section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              5 Years Previous Address
            </span>

            <div className='grid grid-cols-5 gap-x-8 pt-1 gap-y-4'>
              {policeCheckDetailsData?.candidateAddress?.map(
                (item: any, idx) => (
                  <div className='flex flex-col w-full' key={idx}>
                    <span className='text-extra-small text-Gray-500 font-Medium'>
                      ADDRESS {idx + 1}
                    </span>

                    <span className='w-full text-Gray-800 font-Medium pt-1'>
                      {item?.address?.lineOne && `${item?.address?.lineOne}, `}
                      {item?.address?.suburb && `${item?.address?.suburb}, `}
                      {item?.address?.state && `${item?.address?.state}, `}
                      {item?.address?.postCode &&
                        `${item?.address?.postCode}, `}
                      {item?.address?.country && `${item?.address?.country}.`}
                    </span>
                  </div>
                ),
              )}
            </div>
          </div>

          {/* license No section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              Australian Drivers License No
            </span>

            <span className='text-Gray-800 font-Medium pt-1'>
              {policeCheckDetailsData?.licenseNo
                ? policeCheckDetailsData?.licenseNo
                : '-'}
            </span>
          </div>

          {/* state section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              State/Territory
            </span>

            <span className='text-Gray-800 font-Medium pt-1'>
              {policeCheckDetailsData?.state
                ? policeCheckDetailsData?.state
                : '-'}
            </span>
          </div>

          {/* passport No section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              Passport No
            </span>

            <span className='text-Gray-800 font-Medium pt-1'>
              {policeCheckDetailsData?.passportNo
                ? policeCheckDetailsData?.passportNo
                : '-'}
            </span>
          </div>

          {/* passport Country section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              Passport Country
            </span>

            <span className='text-Gray-800 font-Medium pt-1'>
              {policeCheckDetailsData?.passportCountry
                ? policeCheckDetailsData?.passportCountry
                : '-'}
            </span>
          </div>

          {/* Visa Type section */}
          <div className='flex flex-col mt-5'>
            <span className='text-small text-Gray-700 font-Medium'>
              Visa Type
            </span>

            <span className='text-Gray-800 font-Medium pt-1'>
              {policeCheckDetailsData?.visaType
                ? policeCheckDetailsData?.visaType
                : '-'}
            </span>
          </div>
        </div>
      )}

      {/* empty state */}
      {!isLoading && !policeCheckDetailsData && (
        <div className='flex flex-col justify-center items-center mb-6'>
          <i className='ri-police-car-line text-[24px] text-Gray-500' />

          <span className='text-heading-4 font-Medium text-Gray-800 pt-2'>
            No Police Check Details
          </span>

          <span className='text-small font-Regular text-Gray-800 pt-1'>
            Please add the police check details
          </span>

          <Button
            iconLeft={<i className='ri-add-line' />}
            onClick={onAddClick}
            className='mt-2'
            textTheme='white'
            theme='cobalt'>
            Add Police Check Details
          </Button>
        </div>
      )}
    </div>
  )
}

EligibilityPoliceCheckDetailsCard.defaultProps = {
  onAddClick: undefined,
  onEditClick: undefined,
  isLoading: false,
}

export default EligibilityPoliceCheckDetailsCard
