interface InnerListProps {
  id: number
  title: string
  link: string
  isActive: boolean
}

export type InnerListType = InnerListProps[]

interface SubMenuProps {
  id: number
  title: string
  link: string
  hasSubMenu?: boolean
  isActive: boolean
  subMenu?: InnerListType | []
}

export type SubMenuType = SubMenuProps[]

interface MainMenuProps {
  id: number
  menuPosition: string
  title: string
  icon: string
  link: string
  isActive: boolean
  hasSubMenu?: boolean
  subMenu?: SubMenuType | []
}

export type MainMenuTypes = MainMenuProps[]

export const menuItems: MainMenuTypes = [
  {
    id: 1,
    menuPosition: 'left',
    title: 'Search Menu',
    icon: 'ri-search-2-line',
    link: '#',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    id: 2,
    menuPosition: 'left',
    title: 'Dashboard',
    icon: 'ri-dashboard-line',
    link: '/',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    id: 3,
    menuPosition: 'left',
    title: 'Candidates',
    icon: 'ri-team-line',
    link: '/candidate-list',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    id: 4,
    menuPosition: 'left',
    title: 'Audit',
    icon: 'ri-file-search-line',
    link: '/audit',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    id: 5,
    menuPosition: 'left',
    title: 'Clients',
    icon: 'ri-group-2-line',
    link: '/client-list',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  {
    id: 6,
    menuPosition: 'left',
    title: 'Questionnaires',
    icon: 'ri-star-smile-line',
    link: '/questionnaires',
    isActive: false,
    hasSubMenu: false,
    subMenu: [],
  },
  // {
  //   id: 3,
  //   menuPosition: 1,
  //   title: 'Applicants',
  //   icon: 'ri-team-line',
  //   link: '/applicants',
  //   isActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 3.1,
  //       title: 'All Applicants',
  //       link: '/all-applicants',
  //       hasSubMenu: true,
  //       isActive: false,
  //       subMenu: [
  //         {
  //           id: 1,
  //           title: 'Applied',
  //           link: '/applicants/all-applicants/applied',
  //           isActive: false,
  //         },
  //         {
  //           id: 2,
  //           title: 'Phone screened',
  //           link: '/applicants/all-applicants/phone-screened',
  //           isActive: false,
  //         },
  //         {
  //           id: 3,
  //           title: 'Interviewed',
  //           link: '/applicants/all-applicants/interviewed',
  //           isActive: false,
  //         },
  //       ],
  //     },
  //     {
  //       id: 3.2,
  //       title: 'Sub menu item 02',
  //       link: '/sub-02',
  //       hasSubMenu: true,
  //       isActive: false,
  //       subMenu: [
  //         {
  //           id: 1,
  //           title: 'Test one',
  //           link: '/applicants/sub-02/test-01',
  //           isActive: false,
  //         },
  //         {
  //           id: 2,
  //           title: 'Test two',
  //           link: '/applicants/sub-02/test-02',
  //           isActive: false,
  //         },
  //         {
  //           id: 3,
  //           title: 'Test three',
  //           link: '/forgot-password',
  //           isActive: false,
  //         },
  //       ],
  //     },
  //     {
  //       id: 3.3,
  //       title: 'Sub menu item 03',
  //       link: '/applicants/sub-03',
  //       hasSubMenu: false,
  //       isActive: false,
  //     },
  //     {
  //       id: 3.4,
  //       title: 'Sub menu item 04',
  //       link: '/applicants/sub-04',
  //       hasSubMenu: false,
  //       isActive: false,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   menuPosition: 1,
  //   title: 'ATS',
  //   icon: 'ri-file-text-line',
  //   link: '/ats',
  //   isActive: false,
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       id: 4.1,
  //       title: 'ATS-one',
  //       link: '/forgot-password',
  //       hasSubMenu: false,
  //       isActive: false,
  //     },
  //     {
  //       id: 4.2,
  //       title: 'ATS-two',
  //       link: '/ats-02',
  //       hasSubMenu: false,
  //       isActive: false,
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   menuPosition: 1,
  //   title: 'Clients',
  //   icon: 'ri-group-2-line',
  //   link: '/clients',
  //   isActive: false,
  //   hasSubMenu: false,
  // },
  // {
  //   id: 6,
  //   menuPosition: 1,
  //   title: 'Roster',
  //   icon: 'ri-map-pin-time-line',
  //   link: '/roster',
  //   isActive: false,
  //   hasSubMenu: false,
  // },
]

export default menuItems
