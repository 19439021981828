/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import CN from 'classnames'
import { setMenu as acSetMenu } from 'store/reducers/side-menu/mainSideMenuReducer'

import SearchMenu from 'components/common/Sidebar/SearchMenu'
import SubMenu from 'components/common/Sidebar/SubMenu'

interface MenuItemProps {
  menuItems: any
  item: any | []
  index: number
  currentRoute: string
  setToggleMenu: any
  toggleMenu: any
}

/** this component for handle main side bar items actions and sub menu items action */
const MenuItem: FC<MenuItemProps> = ({
  item,
  index,
  menuItems,
  setToggleMenu,
  toggleMenu,
}) => {
  const { id, title, icon, subMenu, hasSubMenu, isActive } = item

  const navigate = useNavigate()
  const dispatch = useDispatch()

  /** handle main menu items click */
  const handleMainMenuItemClick = (index: number) => {
    const toBeUpdatedMenuItems = menuItems.map((menuItem: any) => {
      if (menuItem.id !== index) {
        return { ...menuItem, isActive: false }
      } else {
        hasSubMenu && dispatch(setToggleMenu(true))

        !hasSubMenu && navigate(menuItem.link)
        return { ...menuItem, isActive: true }
      }
    })
    dispatch(acSetMenu(toBeUpdatedMenuItems))
  }

  return (
    <div
      key={index}
      // to={!hasSubMenu && link}
      onClick={(e) => {
        e.stopPropagation()
        handleMainMenuItemClick(id)
      }}
      className='cursor-pointer mt-3'>
      <div
        className={CN(
          'h-10 w-10 flex justify-center items-center rounded-lg hover:bg-[#F3F3F3] text-heading-4',
          {
            'bg-gradient-to-r from-[#FC141C] to-[#F36E2A] text-white': isActive,
            'bg-white text-gray-600': !isActive,
          },
        )}
        data-tip={title}
        data-for={title}>
        <ReactTooltip
          id={title}
          place='right'
          effect='solid'
          textColor='#FFFFFF'
          backgroundColor='#4B5563'
          className='text-extra-small rounded-full'
        />
        <i className={icon} />
      </div>
      {isActive && hasSubMenu && toggleMenu && (
        <SubMenu
          menuItems={menuItems}
          title={title}
          index={Math.random()}
          subMenu={subMenu}
          isActive={isActive}
          setToggleMenu={setToggleMenu}
          toggleMenu={toggleMenu}
        />
      )}
      {isActive && title === 'Search Menu' && toggleMenu && (
        <SearchMenu
          index={Math.random()}
          isActive={isActive}
          title={title}
          setToggleMenu={setToggleMenu}
        />
      )}
    </div>
  )
}

export default MenuItem
