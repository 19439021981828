import React, { FC, ReactNode } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'

export interface CandidateTabTileCardProps {
  icon?: ReactNode | undefined
  isEditButtonEnable?: boolean | undefined
  onEditClick?: any
  leftElement?: ReactNode | string | number | undefined
  rightElement?: ReactNode | string | number | undefined
  [x: string]: any
}

export const CandidateTabTileCard: FC<CandidateTabTileCardProps> = ({
  className,
  icon,
  isEditButtonEnable,
  onEditClick,
  isLoading,
  leftElement,
  rightElement,
  ...restProps
}: CandidateTabTileCardProps) => {
  /** main style class */
  const CandidateTabTileCardClasses = CN(
    `candidate-tab-tile-card flex justify-between items-center h-[100px] rounded-md shadow-xl pl-5 pr-4 relative`,
    className,
  )

  return (
    <div className={CandidateTabTileCardClasses} {...restProps}>
      {/* card loader */}
      {isLoading && <PageLoader size='xxs' />}

      {/* edit button for visa tab */}
      {isEditButtonEnable && (
        <div className='flex absolute right-2 top-2 z-10'>
          <Button
            icon={<i className='ri-pencil-line text-[16px]' />}
            isIconOnly
            onClick={() => {
              onEditClick && onEditClick()
            }}
            className='h-6 w-6'
            textTheme='blue'
            theme='white'
          />
        </div>
      )}

      {/* left side element  */}
      {leftElement && leftElement}

      {/* right side element  */}
      {rightElement && rightElement}

      {/* bg icon */}
      <div className='flex absolute right-4'>{icon}</div>
    </div>
  )
}

CandidateTabTileCard.defaultProps = {
  icon: undefined,
  isEditButtonEnable: false,
  onEditClick: undefined,
  leftElement: undefined,
  rightElement: undefined,
}

export default CandidateTabTileCard
