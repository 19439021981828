import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteMedicalIncidentInterface {
  candidateId?: string
  medicalIncidentId?: string
}

export const useDeleteMedicalIncident = () => {
  const mutate = useMutation(
    ({ candidateId, medicalIncidentId }: DeleteMedicalIncidentInterface) => {
      return deleteAPI(
        `/facade/Candidate/${candidateId}/Incident/${medicalIncidentId}`,
      )
    },
  )

  return mutate
}

export default useDeleteMedicalIncident
