import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditAddressForPoliceCheckDetailsProps {
  addressData?: any
  candidateId?: any
  policeCheckId?: any
}

export const useEditAddressForPoliceCheckDetails = () => {
  const mutate = useMutation(
    ({
      addressData,
      candidateId,
      policeCheckId,
    }: EditAddressForPoliceCheckDetailsProps) => {
      return putAPI(
        `Candidate/${candidateId}/PoliceCheckDetail/${policeCheckId}/Address/${addressData?.addressId}`,
        {
          address: {
            lineOne: addressData?.lineOne,
            lineTwo: '',
            city: '',
            suburb: addressData?.suburb,
            state: addressData?.state,
            stateCode: addressData?.stateCode,
            country: addressData?.country,
            countryCode: addressData?.countryCode,
            postCode: addressData?.postCode ? addressData?.postCode : '',
            latitude: 0,
            longitude: 0,
          },
        },
      )
    },
  )

  return mutate
}

export default useEditAddressForPoliceCheckDetails
