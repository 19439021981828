import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getClientById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/Client/${reqBody.Id}`)
  return res
}

export const useGetClientById = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['ClientDetails', reqBody], getClientById, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
