import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface SetAuditPassedInterface {
  candidateId: string
  documentId: string
  categoryId: string
}

export const useSetAuditPassed = () => {
  const mutate = useMutation(
    ({ candidateId, documentId, categoryId }: SetAuditPassedInterface) => {
      return postAPI(`/facade/DocumentAudit/AuditPassed`, {
        candidateId,
        documentId,
        categoryId,
      })
    },
  )

  return mutate
}

export default useSetAuditPassed
