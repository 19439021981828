import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getPoliceCheckCard = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/facade/Candidate/${reqBody.Id}/PoliceCheck`)
  return res
}

export const useGetPoliceCheckCard = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['policeCheckCard', reqBody], getPoliceCheckCard, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
