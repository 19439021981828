import React, { FC } from 'react'
import PhoneInput from 'react-phone-input-2'
import CN from 'classnames'

import './PhoneInput.scss'

interface PhoneInputFieldProps {
  [x: string]: any
  className?: string | undefined
  countryCodeEditable?: boolean
  description?: string | undefined
  helperText?: string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string | undefined
  textFiledClassName?: string | undefined

  value?: string
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
  className,
  countryCodeEditable,
  description,
  helperText,
  isCustomStyles,
  isDisabled,
  isErrored,
  isRequired,
  label,
  onChange,
  onKeyPress,
  placeholder,
  textFiledClassName,
  value,
  ...restProps
}: PhoneInputFieldProps) => {
  /** text field main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN('number-input-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })

  /** text input style */
  const containerClassName = CN(
    'input-container flex w-full items-center text-Gray-800 rounded-md border shadow-sm focus:outline-Cobalt-400 py-0',
    textFiledClassName,
    {
      'border-Gray-300': !isErrored,
      'border-Red-500': isErrored,
    },
    {
      'input-container--is-disabled': isDisabled,
    },
  )

  return (
    <div className={textFieldMainClassName} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container text-small pb-1'>
          <div className='label flex text-Gray-800 font-Medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-Red-500 pl-[2px]'>*</span>}
          </div>

          {/* description */}
          {description && (
            <span className='text-Gray-500 font-Regular pt-1'>
              ({description})
            </span>
          )}
        </div>
      )}

      {/* phone input field*/}
      <PhoneInput
        country={'au'}
        value={value}
        onChange={(value, country, e, formattedValue) => {
          onChange && onChange(formattedValue === '+' ? value : formattedValue)
        }}
        onKeyDown={onKeyPress}
        placeholder={placeholder}
        countryCodeEditable={countryCodeEditable}
        disabled={isDisabled}
        containerClass={containerClassName}
        buttonStyle={{
          border: 'none',
          marginLeft: '4px',
          backgroundColor: 'white',
        }}
      />

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

export default PhoneInputField

PhoneInputField.defaultProps = {
  className: undefined,
  countryCodeEditable: true,
  description: undefined,
  helperText: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: '000 000 0000',
  value: undefined,
}
