import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface sendRequestDocumentProps {
  requestDocumentDetails?: any
  documentId?: any
}

export const useSendRequestDocument = () => {
  const mutate = useMutation(
    ({ requestDocumentDetails, documentId }: sendRequestDocumentProps) => {
      return postAPI(`/facade/DocumentAudit/${documentId}/RequsetDocument`, {
        candidateId: requestDocumentDetails?.candidateId,
        categoryId: requestDocumentDetails?.categoryId,
        isConsultant: requestDocumentDetails?.isConsultant,
        isCandidate: requestDocumentDetails?.isCandidate,
        dueDate: requestDocumentDetails?.dueDate,
        note: requestDocumentDetails?.note,
      })
    },
  )

  return mutate
}

export default useSendRequestDocument
