/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetRestrictionsCountInterface {
  candidateId?: string
}

export const useGetRestrictionsCount = () => {
  const mutate = useMutation(
    ({ candidateId }: useGetRestrictionsCountInterface) => {
      return get(`/facade/Candidate/${candidateId}/Restriction`, {})
    },
  )

  return mutate
}

export default useGetRestrictionsCount
