import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface addClientContactAvatarInterface {
  Id: string
  avatarObject: string
}

export const useAddClientContactAvatar = () => {
  const mutate = useMutation(
    ({ Id, avatarObject }: addClientContactAvatarInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Avatar', avatarObject)

      return postAPIwithFormData(`/Contact/${Id}/Avatar`, bodyFormData, {})
    },
  )

  return mutate
}

export default useAddClientContactAvatar
