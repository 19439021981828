import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, Toast } from '@labourhub/labour-hub-ds'
import {
  useCreateAgencyProfileLogo,
  useCreateAgencySecondaryLogo,
  useDeleteAgencyLocation,
  useGetAgencyProfileLocations,
  useGetCountryList,
  useUpdateAgencyLocation,
  useUpdateAgencyProfileDetails,
} from 'framework/api/methods'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { AgencyProfileLocationCard, PageLoader } from 'components/atoms'
import {
  AddNewAgencyLocation,
  EditAgencyProfileInfo,
  EditAndDeleteAgencyLocation,
} from 'components/Drawers/AgencyProfile'
import {
  AgencyProfileAbout,
  AgencyProfileCard,
  CustomizableEditProfilePictureModal,
} from 'components/molecules'

export interface AgencyProfileProps {
  [x: string]: any
}

export const AgencyProfile: FC<AgencyProfileProps> = ({
  ...restProps
}: AgencyProfileProps) => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)
  const { isHeaderBarDetailsUpdated, headerBarDetails }: any = useSelector<any>(
    (state) => state.user,
  )

  const [files, setFiles] = useState<any>()

  const [isSecondaryLogoModalOpen, setIsSecondaryLogoModalOpen] = useState(true)

  const [showChangeAgencyProfileModal, setShowChangeAgencyProfileModal] =
    useState(false)

  const [showEditAgencyBasicInfoDrawer, setShowEditAgencyBasicInfoDrawer] =
    useState(false)

  const [showAddAgencyLocationDrawer, setShowAddAgencyLocationDrawer] =
    useState<any>(false)

  const [showEditAndDeleteAgencyLocation, setShowEditAndDeleteAgencyLocation] =
    useState(false)

  const [imageUrl, setImageUrl] = useState<any>('')

  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  const [selectedLocationCard, setSelectedLocationCard] = useState<any>({})

  const [showDeleteLocationDrawer, setShowDeleteLocationDrawer] =
    useState(false)

  const [agencyProfileCardDetails, setAgencyProfileCardDetails] = useState<any>(
    { name: '', abn: '', industry: '', website: '', description: '' },
  )

  const [countryItemList, setCountryItemList] = useState()

  const [locationDetails, setLocationDetails] = useState<any>({
    locationId: '',
    locationName: '',
    streetName: '',
    suburb: '',
    state: '',
    stateCode: '',
    postCode: '',
    latitude: '',
    longitude: '',
    country: '',
    countryCode: '',
    phoneNo: '',
    email: '',
  })

  /** API Call for get agency profile location using react-query */
  const {
    mutate: getAgencyProfileLocationsMutate,
    isLoading: getAgencyProfileLocationsIsLoading,
  } = useGetAgencyProfileLocations()

  /** Process get agency profile details */
  async function getAgencyProfileDetails() {
    getAgencyProfileLocationsMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setImageUrl('')
          setAgencyProfileDetails(successData.profileWithLocations)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting agency profile details. ',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get country list using react-query */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process get Country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting country list.',
            status: 'Error',
          })
        },
      },
    )
  }

  /** upload agency profile logo api */
  const { mutate: UploadAgencyProfileLogoMutate } = useCreateAgencyProfileLogo()

  /** Process the upload profile picture */
  async function uploadAgencyProfileLogo(Id: any, logoObject: any) {
    UploadAgencyProfileLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Primary agency logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setShowChangeAgencyProfileModal(false)
          getAgencyProfileDetails()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowChangeAgencyProfileModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** upload agency secondary logo api */
  const { mutate: createAgencySecondaryLogoMutate } =
    useCreateAgencySecondaryLogo()

  /** Process the upload secondary picture */
  async function createAgencySecondaryLogo(Id: any, logoObject: any) {
    createAgencySecondaryLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Secondary agency logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setShowChangeAgencyProfileModal(false)
          getAgencyProfileDetails()

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowChangeAgencyProfileModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for Update agency profile details */
  const {
    mutate: updateAgencyProfileDetailsMutate,
    isLoading: updateAgencyProfileDetailsIsLoading,
  } = useUpdateAgencyProfileDetails()

  /** Process update agency profile data API call */
  async function updateAgencyProfileDetails(id: any) {
    updateAgencyProfileDetailsMutate(
      {
        obj: agencyProfileCardDetails,
        id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? ''
              : 'Agency basic information successfully updated. ',
            alertBody: '',
            status: 'Success',
          })
          setShowEditAgencyBasicInfoDrawer(false)
          getAgencyProfileDetails()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for Update agency location */
  const {
    mutate: updateAgencyLocationMutate,
    isLoading: updateAgencyLocationIsLoading,
  } = useUpdateAgencyLocation()

  /** Process update agency location API call */
  async function updateAgencyLocation(agencyID: any, agencyLocationID: any) {
    updateAgencyLocationMutate(
      {
        ID: agencyID,
        LID: agencyLocationID,
        obj: locationDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? ''
              : 'Agency location updated successfully. ',
            alertBody: '',
            status: 'Success',
          })
          setShowEditAndDeleteAgencyLocation(false)
          getAgencyProfileDetails()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for delete agency location using react-query */
  const { mutate: deleteAgencyLocationMutate } = useDeleteAgencyLocation()

  /** Process the Delete Agency location*/
  async function deleteAgencyLocation(agencyID: any, agencyLocationID: any) {
    deleteAgencyLocationMutate(
      {
        Id: agencyID,
        LID: agencyLocationID,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? ''
              : 'Agency location was deleted successfully. ',
            alertBody: '',
            status: 'Success',
          })
          getAgencyProfileDetails()
          setShowEditAndDeleteAgencyLocation(false)
          setShowDeleteLocationDrawer(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
          setShowDeleteLocationDrawer(false)
        },
      },
    )
  }

  useEffect(() => {
    getAgencyProfileDetails()
    getCountryList()
  }, [])

  return (
    <div className='agency-profile flex gap-4 w-full' {...restProps}>
      {getAgencyProfileLocationsIsLoading && <PageLoader size='xxs' />}

      {/** Agency Profile Card */}
      <AgencyProfileCard
        logoUrl={agencyProfileDetails.logoUrl}
        isImage={agencyProfileDetails.logoUrl ? true : false}
        name={agencyProfileDetails.name}
        abn={agencyProfileDetails.abn}
        industry={agencyProfileDetails.industry}
        website={agencyProfileDetails.website}
        noOfClients={agencyProfileDetails.clientCount}
        onChangePhotoClick={() => {
          setShowChangeAgencyProfileModal(true)
        }}
        onBasicInfoEditClick={() => {
          setShowEditAgencyBasicInfoDrawer(true)
        }}
        isSecondaryImage={
          headerBarDetails?.userProfile?.avatarUrl ? true : false
        }
        agencyProfileDetails={agencyProfileDetails}
        setIsSecondaryLogoModalOpen={setIsSecondaryLogoModalOpen}
      />

      {/** Edit Agency Profile Basic Info Modal */}
      <EditAgencyProfileInfo
        isActive={showEditAgencyBasicInfoDrawer}
        agencyProfileDetails={agencyProfileDetails}
        agencyProfileCardDetails={agencyProfileCardDetails}
        setAgencyProfileCardDetails={setAgencyProfileCardDetails}
        buttonTitle='Update'
        headerSubtitle={'Edit basic information here'}
        headerTitle={'Edit Agency’s Basic Information'}
        isButtonDisabled={false}
        footerCancelButton={() => {
          setShowEditAgencyBasicInfoDrawer(false)
        }}
        headerCloseButton={() => {
          setShowEditAgencyBasicInfoDrawer(false)
        }}
        footerSubmitButton={() => {
          updateAgencyProfileDetails(agencyProfileDetails.id)
        }}
        isLoading={updateAgencyProfileDetailsIsLoading}
      />

      {/** Edit Agency Logo Modal */}
      <CustomizableEditProfilePictureModal
        isRectangleLogo={isSecondaryLogoModalOpen}
        isActive={showChangeAgencyProfileModal}
        onHeaderCloseButtonClick={() => {
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
        }}
        onClickSecondaryBtn={() => {
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
        }}
        onOverlayClick={() => {
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
        }}
        onClickPrimaryBtn={() => {
          if (!isSecondaryLogoModalOpen) {
            /** for primary logo */
            uploadAgencyProfileLogo(agencyProfileDetails.id, files)
          } else {
            /** for secondary logo */
            createAgencySecondaryLogo(agencyProfileDetails.id, files)
          }
        }}
        imageSource={
          !isSecondaryLogoModalOpen
            ? agencyProfileDetails?.logoUrl
            : headerBarDetails?.userProfile?.avatarUrl
        }
        setImageUrl={setImageUrl}
        imageUrl={imageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={
          isSecondaryLogoModalOpen
            ? 'Change secondary agency logo'
            : 'Change profile photo'
        }
        headerSubTitle={
          isSecondaryLogoModalOpen
            ? 'This is the agency logo displayed in the header bar'
            : 'Update your profile photo here.'
        }
      />

      <div className='flex flex-col w-full'>
        {/** Agency Profile about section */}
        <AgencyProfileAbout description={agencyProfileDetails.description} />

        {/* agency location card */}
        <div className='agency-profile bg-white rounded-md border border-Gray-200 px-5 pt-[22px] pb-7 mt-5 mb-[30px]'>
          <div className='flex items-center justify-between'>
            <span className='text-heading-4 font-Medium text-Gray-800'>
              Locations
            </span>
            <Button
              iconLeft={<i className='ri-add-circle-line' />}
              onClick={() => {
                setShowAddAgencyLocationDrawer(true)
                getCountryList()
              }}
              size='sm'
              textTheme='blue'
              theme='white'>
              Add New Location
            </Button>
          </div>

          {/** Show add new location drawer */}
          <AddNewAgencyLocation
            notify={notify}
            agencyProfileId={agencyProfileDetails?.id}
            isActive={showAddAgencyLocationDrawer}
            getAgencyProfileDetails={getAgencyProfileDetails}
            buttonTitle='Save Location'
            headerSubtitle={'Add new location here'}
            headerTitle={'Add New Agency Location'}
            countryItemList={countryItemList}
            showAddAgencyLocationDrawer={showAddAgencyLocationDrawer}
            setShowAddAgencyLocationDrawer={setShowAddAgencyLocationDrawer}
            footerCancelButton={() => {
              setShowAddAgencyLocationDrawer(false)
            }}
            headerCloseButton={() => {
              setShowAddAgencyLocationDrawer(false)
            }}
            agencyProfileDetails={agencyProfileDetails}
          />

          {/** Show edit and delete location drawer*/}
          <EditAndDeleteAgencyLocation
            isActive={showEditAndDeleteAgencyLocation}
            agencyProfileId={agencyProfileDetails.id}
            selectedLocationCard={selectedLocationCard}
            setLocationDetails={setLocationDetails}
            locationDetails={locationDetails}
            notify={notify}
            buttonTitle='Update Location'
            headerTitle={'Edit Agency Location'}
            countryItemList={countryItemList}
            footerCancelButton={() => {
              setShowEditAndDeleteAgencyLocation(false)
            }}
            headerCloseButton={() => {
              setShowEditAndDeleteAgencyLocation(false)
            }}
            footerUpdateButton={() => {
              updateAgencyLocation(
                agencyProfileDetails.id,
                selectedLocationCard?.id,
              )
            }}
            footerDeleteButton={() => {
              setShowDeleteLocationDrawer(true)
            }}
            isLoading={updateAgencyLocationIsLoading}
          />

          {/** Showing delete location confirmation modal */}
          <Modal
            isActive={showDeleteLocationDrawer}
            onClickPrimaryBtn={() => {
              deleteAgencyLocation(
                agencyProfileDetails?.id,
                selectedLocationCard?.id,
              )
            }}
            onClickSecondaryBtn={() => {
              setShowDeleteLocationDrawer(false)
            }}
            primaryButtonProps={{
              style: { width: '100%', marginRight: '12px' },
            }}
            secondaryButtonProps={{
              style: { width: '100%', color: '#1F2937' },
            }}
            onOverlayClick={() => {
              setShowDeleteLocationDrawer(false)
            }}
            modalProps={{
              style: { width: '466px' },
            }}
            footerProps={{
              style: {
                justifyContent: 'space-between',
                paddingLeft: '23px',
                paddingRight: '23px',
              },
            }}
            isHeaderShow={false}
            primaryButtonTitle='Yes, I’m Sure'
            secondaryButtonTitle='Cancel'>
            <div className='flex flex-col justify-center items-center p-6'>
              <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
                <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
              </div>

              <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
                Delete Location?
              </span>

              <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
                Are you sure you want to proceed?
              </span>
            </div>
          </Modal>

          {/** Location card */}
          <div className='grid grid-cols-2 gap-8 mt-7'>
            {agencyProfileDetails &&
              agencyProfileDetails?.locations?.map((itm: any, idx: any) => (
                <AgencyProfileLocationCard
                  key={idx}
                  locationDetails={itm}
                  onEditClick={() => {
                    setSelectedLocationCard(itm)
                    setShowEditAndDeleteAgencyLocation(true)
                  }}
                />
              ))}

            {!agencyProfileDetails && <>-</>}
          </div>
        </div>
      </div>
    </div>
  )
}

AgencyProfile.defaultProps = {}

export default AgencyProfile
