import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCandidateNoteInterface {
  NoteId?: string
  CandidateId?: string
}

export const useDeleteCandidateNote = () => {
  const mutate = useMutation(
    ({ NoteId, CandidateId }: DeleteCandidateNoteInterface) => {
      return deleteAPI(`/candidate/${CandidateId}/Note/${NoteId}`)
    },
  )

  return mutate
}

export default useDeleteCandidateNote
