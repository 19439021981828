/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface EligibilityCriminalHistoryCardProps {
  onAddClick?: () => void
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  [x: string]: any
}

export const EligibilityCriminalHistoryCard: FC<
  EligibilityCriminalHistoryCardProps
> = ({
  className,
  isLoading,
  onAddClick,
  criminalHistoryList,
  onDeleteClick,
  showMoreButtonOption,
  onEditClick,
  setShowMoreButtonOption,
  setPoliceCheckMoreButtonOption,
  setSelectedDocumentDetails,
  ...restProps
}: EligibilityCriminalHistoryCardProps) => {
  const EligibilityCriminalHistoryCardClasses = CN(
    `eligibility-criminal-history-card border bg-white border-Gray-200 rounded-md py-4 px-5 mt-5 relative`,
    className,
  )

  return (
    <div className={EligibilityCriminalHistoryCardClasses} {...restProps}>
      {/* loader */}
      {isLoading && <PageLoader size='xxs' className='h-full min-h-[200px]' />}

      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Criminal History
        </span>

        <Button onClick={onAddClick} textTheme='blue' size='sm' theme='white'>
          Add
        </Button>
      </div>

      <div className='flex flex-col mt-5'>
        {criminalHistoryList?.length > 0 &&
          criminalHistoryList?.map((itm: any, index: any) => (
            <div className='flex justify-between items-start mb-5' key={index}>
              <div className='flex flex-col'>
                {/** criminal record name */}
                <span className='font-Medium text-small text-Gray-700'>
                  {itm?.recordName}
                </span>

                {/** charged date */}
                <span className='font-Medium text-extra-small text-Gray-500 pt-1'>
                  Charged on{' '}
                  {itm?.chargedDate
                    ? dateToReadableString(itm?.chargedDate)
                    : `N/A`}
                </span>
              </div>

              <div className='relative'>
                <MoreButton
                  onClick={(e: any) => {
                    e.stopPropagation()

                    setShowMoreButtonOption({
                      index: index,
                      isEnable:
                        index === showMoreButtonOption.index
                          ? !showMoreButtonOption.isEnable
                          : true,
                    })

                    setPoliceCheckMoreButtonOption(false)

                    /** set selected item details to state for edit and delete*/
                    setSelectedDocumentDetails(itm)
                  }}
                />

                {/** More button drop down options */}
                {showMoreButtonOption.isEnable &&
                  index === showMoreButtonOption.index && (
                    <div
                      className={CN(
                        'flex flex-col absolute w-[169px] py-1 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40',
                        {
                          'top-[0px] right-[42px]':
                            index === criminalHistoryList?.length - 1,

                          'top-[42px] right-[0px]':
                            index !== criminalHistoryList?.length - 1,
                        },
                      )}>
                      <span
                        className='font-Regular text-Gray-800 pl-4 py-2 hover:bg-Gray-50'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          onEditClick && onEditClick()
                          setShowMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Edit
                      </span>
                      <span
                        className='font-Regular text-Red-500 pl-4 py-2 hover:bg-Gray-50'
                        onClick={(e: any) => {
                          e.stopPropagation()
                          onDeleteClick && onDeleteClick()
                          setShowMoreButtonOption({
                            index: -1,
                            isEnable: false,
                          })
                        }}>
                        Delete
                      </span>
                    </div>
                  )}
              </div>
            </div>
          ))}

        {criminalHistoryList?.length <= 0 && (
          <div className='flex flex-col justify-center items-center mb-6'>
            <i className='ri-government-line text-[24px] text-Gray-500' />

            <span className='text-heading-4 font-Medium text-Gray-800 pt-2'>
              No Criminal Records Found
            </span>

            <span className='text-small font-Regular text-Gray-800 pt-1'>
              You can add by clicking below button
            </span>

            <Button
              iconLeft={<i className='ri-add-line' />}
              onClick={onAddClick}
              className='mt-2'
              textTheme='white'
              theme='cobalt'>
              Add Criminal Record
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

EligibilityCriminalHistoryCard.defaultProps = {
  onAddClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default EligibilityCriminalHistoryCard
