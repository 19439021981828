/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPrimaryConsultantListProps {}

export const useGetPrimaryConsultantList = () => {
  const mutate = useMutation(({}: GetPrimaryConsultantListProps) => {
    return get(`/UserProfile/List/UsersInRole`, {})
  })

  return mutate
}

export default useGetPrimaryConsultantList
