/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Alerts, Button, Checkbox, TextField } from '@labourhub/labour-hub-ds'
import certificateIcon from 'assets/images/CertificationIcon.svg'
import CN from 'classnames'
import {
  setIsAuditModal,
  setSelectedCandidate,
  setSelectedDocumentId,
} from 'store/reducers/audit/auditSlice'

import { DocumentStatusIcon, MoreButton, PageLoader } from 'components/atoms'
import { dateToReadableString } from 'utils/dateToReadableString'

export interface EligibilityPoliceCheckCardProps {
  onDeleteClick?: () => void
  onEditClick?: () => void
  onViewClick?: () => void
  [x: string]: any
}

export const EligibilityPoliceCheckCard: FC<
  EligibilityPoliceCheckCardProps
> = ({
  className,
  getDocumentSignedUrlsById,
  onDeleteClick,
  onEditClick,
  onViewClick,
  isLoading,
  policeCheckCardData,
  setShowCriminalHistoryMoreButtonOption,
  setShowMoreButtonOption,
  showMoreButtonOption,
  ...restProps
}: EligibilityPoliceCheckCardProps) => {
  const EligibilityPoliceCheckCardClasses = CN(
    `eligibility-police-check-card border bg-white border-Gray-200 rounded-md pt-4 pb-5 px-5 mt-[22px] relative`,
    className,
  )

  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const candidateId: any = searchParams.get('candidate_id')
  const candidateName: any = searchParams.get('candidate_name')

  /** destructure police card data */
  const {
    isPoliceClearance,
    statutoryUrl,
    policeDocumentId,
    status,
    auditRequired,
    jobTitle,
    isCriminalAustralia,
    isCriminalOther,
    updatedOn,
    createdOn,
  } = policeCheckCardData || {}

  return (
    <div className={EligibilityPoliceCheckCardClasses} {...restProps}>
      {/* loader */}
      {isLoading && <PageLoader size='xxs' />}

      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Police Check
        </span>

        <Button onClick={onEditClick} textTheme='blue' size='sm' theme='white'>
          Edit
        </Button>
      </div>

      <div className='flex flex-col ml-[7px] pt-[21px]'>
        <span className='text-small text-Gray-800 font-Regular'>
          Do you have an Australian police clearance?
        </span>

        <span className='text-Cobalt-600 font-Regular pt-[7px] pb-4'>
          {isPoliceClearance ? `Yes` : `No`}
        </span>

        {/* when police check yes */}
        {isPoliceClearance && (
          <div className='flex justify-between items-start'>
            <div className='flex'>
              <img src={certificateIcon} alt='' className='w-10 h-10' />

              <div className='pl-4'>
                <div className='flex flex-col'>
                  <div className='flex'>
                    <span className='font-SemiBold text-Gray-800'>
                      Australian Police Check
                    </span>

                    <DocumentStatusIcon
                      status={status}
                      auditRequired={auditRequired}
                    />
                  </div>

                  <span className='font-Medium text-extra-small text-Gray-500 pt-[6px]'>
                    Uploaded:{' '}
                    {updatedOn ? dateToReadableString(updatedOn) : `N/A`}
                  </span>
                </div>
              </div>
            </div>

            <div className='relative'>
              <MoreButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  setShowMoreButtonOption(!showMoreButtonOption)
                  setShowCriminalHistoryMoreButtonOption(false)
                  getDocumentSignedUrlsById(policeDocumentId)
                }}
              />

              {/** More button drop down options */}
              {showMoreButtonOption && (
                <div className='flex flex-col absolute top-[42px] right-[0px] w-[169px] py-1 bg-white border border-Gray-200 shadow-lg shadow-Gray-200 rounded-md cursor-pointer z-40'>
                  <div
                    className='flex font-Regular text-Gray-800 pl-4 py-2 hover:bg-Gray-50'
                    onClick={(e: any) => {
                      e.stopPropagation()
                      onViewClick && onViewClick()
                      setShowMoreButtonOption(false)
                    }}>
                    <span>View</span>
                  </div>

                  <div
                    className={CN('flex font-Regular text-Gray-800 pl-4 py-2', {
                      'hover:bg-Gray-50':
                        (policeCheckCardData?.auditRequired &&
                          policeCheckCardData?.status ===
                            'Request_Submission') ||
                        policeCheckCardData?.status === 'Pending_Audit',

                      'opacity-50 hover:bg-Gray-white':
                        !policeCheckCardData?.auditRequired ||
                        policeCheckCardData?.status === 'Active' ||
                        policeCheckCardData?.status === 'Audit_Failed' ||
                        policeCheckCardData?.status === 'Expired' ||
                        policeCheckCardData?.status === 'Archived' ||
                        (!policeCheckCardData?.auditRequired &&
                          policeCheckCardData?.status === 'Request_Submission'),
                    })}
                    onClick={(e: any) => {
                      e.stopPropagation()

                      setShowMoreButtonOption(false)

                      dispatch(
                        setSelectedDocumentId(
                          policeCheckCardData?.policeCheckId,
                        ),
                      )

                      /** selected candidate states set to store */
                      dispatch(
                        setSelectedCandidate({
                          name: candidateName,
                          id: candidateId,
                        }),
                      )

                      /** audit check modal open handle from state */
                      dispatch(
                        setIsAuditModal({
                          isOpen: true,
                          isFooterShow: false,
                          isActiveButton: false,
                          tab: null,
                        }),
                      )
                    }}>
                    <span>Perform Audit</span>
                  </div>

                  <div
                    className='flex font-Regular text-Red-500 pl-4 py-2 hover:bg-Gray-50'
                    onClick={(e: any) => {
                      e.stopPropagation()
                      onDeleteClick && onDeleteClick()
                      setShowMoreButtonOption(false)
                    }}>
                    <span>Delete</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* when no police check,only statutory declaration */}
        {policeCheckCardData && !isPoliceClearance && statutoryUrl !== null && (
          <div className='flex flex-col'>
            <span className='font-SemiBold text-Gray-800'>
              Statutory Declaration
            </span>

            <div className='flex flex-col mt-5'>
              <div className='flex justify-start items-end'>
                <span className='font-Regular text-Gray-800'>I, of </span>

                <TextField
                  className='mx-2'
                  value={jobTitle}
                  onChange={() => {
                    //
                  }}
                  placeholder=''
                  isDisabled
                />

                <span className='font-Regular text-Gray-800'>
                  , do solemnly and sincerely declare that:
                </span>
              </div>

              <Checkbox
                labelText='I have never been convicted of a criminal offense in Australia'
                isDisabled
                defaultChecked={isCriminalAustralia}
                className='mt-1'
                labelTextClassName='font-Regular'
                checkboxClassName='ml-0'
              />

              <Checkbox
                labelText='I have never been convicted of a criminal offense and/or sentenced to imprisonment in any country other than Australia'
                isDisabled
                defaultChecked={isCriminalOther}
                className='mt-1'
                labelTextClassName='font-Regular'
                checkboxClassName='ml-0'
              />

              <span className='mt-2 font-Regular text-Gray-800'>
                I acknowledge that this declaration is true and correct, and I
                make it with the understanding and belief that a person who
                makes a false declaration is liable to the penalties of perjury.
              </span>

              <span className='mt-4 text-extra-small font-Medium text-Gray-800'>
                Candidate Signature
              </span>

              {/*sign image */}
              <img
                src={statutoryUrl}
                alt='sign'
                className='w-[105px] h-11 object-contain mt-1'
              />

              <div className='flex flex-col mt-4 font-Medium text-Gray-500'>
                <span className='text-extra-small'>Declared at,</span>

                <span className='text-small'>
                  Level 9, 10 Queen St, Melbourne
                </span>

                <span className='text-small'>
                  {createdOn ? dateToReadableString(createdOn) : `N/A`}
                </span>
              </div>
            </div>
          </div>
        )}

        {/* when no police check or statutory declaration */}
        {!isPoliceClearance && statutoryUrl === null && (
          <div className='flex flex-col'>
            <span className='font-SemiBold text-Gray-800'>
              Statutory Declaration
            </span>

            <Alerts
              alertBody='Candidate has not yet given the consent to the statutory declaration'
              alertHeader='Warning'
              isCloseIcon={false}
              isIcon={false}
              status='Warning'
              className='w-[450px] mt-5'
            />
          </div>
        )}
      </div>
    </div>
  )
}

EligibilityPoliceCheckCard.defaultProps = {
  onDeleteClick: undefined,
  onEditClick: undefined,
  onViewClick: undefined,
}

export default EligibilityPoliceCheckCard
