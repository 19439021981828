import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getWaitingForActivationList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/DocumentAudit/AuditActivation?Take=${reqBody.take}&Skip=${reqBody.skip}&Category=${reqBody.documentCategory}&Search=${reqBody.search}&Type=${reqBody.documentType}&AuditedBy=${reqBody.auditedPerson}&SubmittedFrom=${reqBody.submittedFromDate}&SubmittedTo=${reqBody.submittedToDate}&AuditCompletedFrom=${reqBody.auditCompletedFromDate}&AuditCompletedTo=${reqBody.auditCompletedToDate}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetWaitingForActivationList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(
    ['WaitingForActivation', reqBody],
    getWaitingForActivationList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
