import React, { FC, ReactNode } from 'react'
import CreatableSelect from 'react-select/creatable'
import CN from 'classnames'

export interface CreatableInputSelectProps {
  className?: string | undefined
  defaultValue?: any
  description?: string | undefined
  helperText?: ReactNode | string | undefined
  isAutoHeight?: boolean | undefined
  isClearable?: boolean | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isMulti?: boolean | undefined
  isRequired?: boolean
  label?: string | undefined
  onChange?: any
  options?: any
  value?: any | undefined
  placeholder?: string | undefined
  [x: string]: any
}

export const CreatableInputSelect: FC<CreatableInputSelectProps> = ({
  className,
  defaultValue,
  description,
  helperText,
  isAutoHeight,
  isClearable,
  isCustomStyles,
  isDisabled,
  isErrored,
  isMulti,
  isRequired,
  label,
  onChange,
  options,
  placeholder,
  value,
}: CreatableInputSelectProps) => {
  /** simple select main class name */
  const simpleSelectMainClassName = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })

  /** select input class name */
  const inputSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: 42,
      height: isAutoHeight ? 'auto' : 42,
      borderRadius: 6,
      border: isErrored && '1px solid red',
    }),
    option: (styles: any, { isSelected }: any) => {
      return {
        ...styles,
        color: isSelected ? 'black' : 'black',
      }
    },
  }

  return (
    <div className={simpleSelectMainClassName}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container text-small pb-1'>
          <div className='label flex text-Gray-800 font-Medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-Red-500 pl-[2px]'>*</span>}
          </div>

          {/* description */}
          {description && (
            <span className='text-Gray-500 font-Regular pt-1'>
              ({description})
            </span>
          )}
        </div>
      )}

      <CreatableSelect
        styles={inputSelectStyles}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
        value={value}
        placeholder={placeholder}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: '#6B7280',
            primary25: '#F3F4F6', // option Hover color
            neutral80: '#1F2937', // typing Inside the select
            neutral20: '#D1D5DB', // Outline color
            primary75: '#1F2937',
            primary: '#F3F4F6',
            neutral90: '#F3F4F6',
          },
        })}
      />

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

CreatableInputSelect.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  description: undefined,
  helperText: undefined,
  isAutoHeight: false,
  isClearable: false,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isMulti: false,
  isRequired: false,
  label: undefined,
  onChange: undefined,
  options: undefined,
  value: undefined,
  placeholder: undefined,
}

export default CreatableInputSelect
