import React, { FC, useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetCandidateTagsById } from 'framework/api/methods'

import { EditCandidateTags } from 'components/Drawers/Candidate'
import { CandidateTagCard } from 'components/molecules'
import { RefereeReportViewModal } from 'components/molecules/RefereeReportViewModal'

export interface DashboardTabViewProps {
  [x: string]: any
}

export const DashboardTabView: FC<DashboardTabViewProps> = ({
  className,
  candidateId,
  notify,
  ...restProps
}: DashboardTabViewProps) => {
  const [candidateTagList, setCandidateTagList] = useState<any>()
  const [isEditTagsDrawerOpen, setIsEditTagsDrawerOpen] = useState(false)
  const [selectedTags, setSelectedTags] = useState<any>([])

  /** Get candidate tags by id api */
  const {
    mutate: getCandidateTagsByIdMutate,
    isLoading: getCandidateTagsByIdIsLoading,
  } = useGetCandidateTagsById()

  /** Process the get tags */
  async function getCandidateTagsById() {
    getCandidateTagsByIdMutate(
      {
        Id: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateTagList(successData?.tags)

          const tempCandidateTagList: any = []
          successData?.tags?.map((item: any) => {
            tempCandidateTagList.push({
              value: item,
              label: item,
            })
          })
          setSelectedTags(tempCandidateTagList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate tags!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    candidateId && getCandidateTagsById()
  }, [candidateId])

  const DashboardTabViewClasses = CN(`dashboard-tab-view`, className)

  return (
    <div className={DashboardTabViewClasses} {...restProps}>
      {/* candidate tag card */}
      <CandidateTagCard
        isLoading={getCandidateTagsByIdIsLoading}
        candidateTagList={candidateTagList}
        onEditClick={() => {
          setIsEditTagsDrawerOpen(true)
        }}
      />

      {/*edit candidate tag drawer */}
      <EditCandidateTags
        isActive={isEditTagsDrawerOpen}
        setIsEditTagsDrawerOpen={setIsEditTagsDrawerOpen}
        notify={notify}
        getCandidateTagsById={getCandidateTagsById}
        candidateId={candidateId}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
    </div>
  )
}

DashboardTabView.defaultProps = {}

export default DashboardTabView
