import React, { FC } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { dateAndTimeToReadableString } from 'utils'

export interface CandidateNoteDetailsCardProps {
  onDeleteClick?: () => any
  [x: string]: any
}

export const CandidateNoteDetailsCard: FC<CandidateNoteDetailsCardProps> = ({
  className,
  onDeleteClick,
  noteDetails,
  ...restProps
}: CandidateNoteDetailsCardProps) => {
  const CandidateNoteDetailsCardClasses = CN(
    `candidate-note-details-card flex flex-col w-full bg-Gray-50 rounded-lg`,
    className,
  )

  return (
    <div className={CandidateNoteDetailsCardClasses} {...restProps}>
      <p className='flex w-full font-Regular text-Gray-800 pt-4 pl-4 pr-[7px] break-all'>
        {noteDetails?.body}
      </p>

      <div className='flex items-center justify-between pt-2 pb-4 px-4'>
        <span className='text-small text-Gray-500 font-Regular pr-2'>
          {`${noteDetails?.consultantName} on ${dateAndTimeToReadableString(
            noteDetails?.createdOn,
          )}`}
        </span>

        <Button
          onClick={() => {
            onDeleteClick && onDeleteClick()
          }}
          textTheme='terracotta'
          theme='terracotta'
          isIconOnly
          className='py-[7px]'
          icon={<i className='ri-delete-bin-line text-extra-small'></i>}
          size='sm'
        />
      </div>
    </div>
  )
}

CandidateNoteDetailsCard.defaultProps = {
  onDeleteClick: undefined,
}

export default CandidateNoteDetailsCard
