/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useDeleteQuestionary,
  useGetQuestionnairesList,
} from 'framework/api/methods'
import { questionnairesTemplateStatusList } from 'static-data/questionnaires/questionnairesTemplateStatus'
import { questionnaireTemplateCategory } from 'static-data/questionnaires/questionnaireTemplateCategory'

import { TableHeader, UITable } from 'components/common/UITable'
import { WebRefCheckPreviewModal } from 'components/molecules'

import { QuestionnairesListColumn } from './QuestionnairesListColumn'

export interface QuestionnairesListProps {
  [x: string]: any
}

export const QuestionnairesList: FC<QuestionnairesListProps> = ({
  className,
  ...restProps
}: QuestionnairesListProps) => {
  const navigate = useNavigate()

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [questionnairesTableColumn, setQuestionnairesTableColumn] =
    useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<any>()
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [filterActive, setFilterActive] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    status: '',
    category: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedTemplate, setSelectedTemplate] = useState<any>()

  const [showDeleteQuestionaryModal, setShowDeleteQuestionaryModal] =
    useState(false)

  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)

  const notify = (props: any) => Toast(props)

  // /** More button DropDown handling states */
  const { clickValue } = useSelector((state: any) => state.layout)

  useEffect(() => {
    const toBeUpdateTableData = {
      ...tableData,
      questionnaires: tableData?.questionnaires?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }
    setTableData(toBeUpdateTableData)
  }, [clickValue])

  /** questionnaires List on success */
  const onSuccess = (data: any) => {
    const toBeUpdateTableData = {
      ...data,
      documentDetails: data?.documentDetails?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }

    setTableData(toBeUpdateTableData || {})
  }

  /** questionnaires List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All questionnaires List */
  const {
    refetch: getQuestionnairesListLoading,
    isLoading: getQuestionnairesListIsLoading,
    isFetching: getQuestionnairesListIsFetching,
  } = useGetQuestionnairesList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      status: filterVariables?.status || '',
      search: paginationVariables.search,
      category: filterVariables?.category || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  /** API Call for delete candidate photo using react-query */
  const {
    mutate: deleteQuestionaryMutate,
    isLoading: deleteQuestionaryIsLoading,
  } = useDeleteQuestionary()

  /** Process the Delete candidate photo */
  async function deleteQuestionary(Id: string) {
    deleteQuestionaryMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Questionnaire template deleted successfully',
            alertBody: '',
            status: 'Success',
          })
          getQuestionnairesListLoading()
          setShowDeleteQuestionaryModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  const applyFilter = () => {
    setFilterActive(true)
    setIsFilterMenuOpen(false)

    setFilterVariables({
      status: selectedStatus?.value || '',
      category: selectedCategory?.value || '',
    })
  }

  /** questionnaires list table column handling */
  useEffect(() => {
    if (tableData?.questionnaires && tableData?.questionnaires?.length > 0) {
      const columns: any = QuestionnairesListColumn(
        tableData,
        setTableData,
        navigate,
        setIsShowPreviewModal,
        setSelectedTemplate,
        setShowDeleteQuestionaryModal,
      )
      setQuestionnairesTableColumn(columns)
    }
  }, [tableData])

  const resetFilter = () => {
    setSearchKeyWord('')
    setSelectedCategory('')
    setSelectedStatus('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setFilterVariables({
      status: '',
      category: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const QuestionnairesListClasses = CN(
    `questionnaires-list w-full h-full`,
    className,
  )

  return (
    <div className={QuestionnairesListClasses} {...restProps}>
      <div className='flex flex-col w-full px-4 bg-white border rounded-t-lg outline-none border-Gray-100'>
        {/* table header */}
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          searchFieldPlaceholder={'Search template'}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}>
          <Button
            className='ml-2'
            onClick={() => {
              navigate('/questionnaires/build-new-questionnaire')
            }}
            iconLeft={<i className='ri-add-line' />}
            theme='gradient'>
            Build New Questionnaire
          </Button>
        </TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex w-full bg-white'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              <div className='flex justify-center items-end px-4 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='By Category'
                  value={selectedCategory}
                  className='w-[301px] mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedCategory(selectedItem)
                  }}
                  placeholder='All'
                  options={questionnaireTemplateCategory}
                />

                <SimpleSelect
                  label='By Status'
                  value={selectedStatus}
                  className='w-[302px]'
                  onChange={(selectedItem: any) => {
                    setSelectedStatus(selectedItem)
                  }}
                  placeholder='All'
                  options={questionnairesTemplateStatusList}
                />

                <Button
                  className='ml-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedStatus?.value && !selectedCategory?.value
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.questionnaires || []}
            isLoading={
              getQuestionnairesListIsLoading || getQuestionnairesListIsFetching
            }
            className=''
            columns={questionnairesTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
      {isShowPreviewModal && (
        <WebRefCheckPreviewModal
          isActive={isShowPreviewModal}
          modalClose={() => {
            setIsShowPreviewModal(false)
          }}
          templateId={selectedTemplate?.value}
          templateName={selectedTemplate?.label}
          notify={notify}
        />
      )}

      {/* delete user profile picture modal */}
      <Modal
        isActive={showDeleteQuestionaryModal}
        onClickPrimaryBtn={() => {
          deleteQuestionary(selectedTemplate.value)
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteQuestionaryModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteQuestionaryIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteQuestionaryModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            {`Delete ${selectedTemplate?.label}?`}
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            {`Are you sure you want to proceed?`}
          </span>
        </div>
      </Modal>
    </div>
  )
}

QuestionnairesList.defaultProps = {}

export default QuestionnairesList
