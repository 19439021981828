import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddSuperNameProps {
  superNameData?: any
}

export const useAddSuperName = () => {
  const mutate = useMutation(({ superNameData }: AddSuperNameProps) => {
    return postAPI(`/SuperName`, {
      superProviderId: superNameData.superProviderId,
      name: superNameData.name,
    })
  })

  return mutate
}

export default useAddSuperName
