/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  TextArea,
  TextField,
  Toggle,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import {
  useAddDocumentType,
  useAddVisaDocument,
  useDeleteCandidateDocumentAttachmentById,
  useEditVisaDocument,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'

import {
  CalendarInput,
  CreatableInputSelect,
  PageLoader,
} from 'components/atoms'

export interface VisaDocumentProps {
  footerCancelButton?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  [x: string]: any
}

export const VisaDocument: FC<VisaDocumentProps> = ({
  getVisaDocumentList,
  onDrawerClose,
  headerTitle,
  headerSubtitle,
  primaryButtonTitle,
  isActive,
  data,
  isAddMode,
  notify,
  candidateId,
  ...restProps
}: VisaDocumentProps) => {
  const { documentCategory } = useSelector((state: any) => state.document)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)
  const [visaDocTypes, setVisaDocTypes] = useState<any[]>([])
  const [formData, setFormData] = useState({
    docName: isAddMode ? undefined : data?.title,
    docType: isAddMode ? null : data?.typeId,
    expiryOn: isAddMode ? null : data?.expiryOn && data?.expiryOn,
    hasExpiryDate: isAddMode ? false : data?.expiryOn ? true : false,

    dueOn: isAddMode ? null : data?.dueOn && data?.dueOn,
    hasDueDate: isAddMode ? false : data?.dueOn ? true : false,
    notes: isAddMode ? '' : data?.notes ? data.notes : '',
    categoryId: documentCategory?.id,
    auditCheck: isAddMode ? false : data?.auditRequired ? true : false,
  })

  const validateForm = () => {
    const { docName, docType, expiryOn, dueOn, hasExpiryDate, hasDueDate } =
      formData

    if (!docName || !docType) {
      setIsPrimaryButtonDisabled(true)
      return
    }

    if (hasExpiryDate && !expiryOn) {
      setIsPrimaryButtonDisabled(true)
      return
    }

    if (hasDueDate && !dueOn) {
      setIsPrimaryButtonDisabled(true)
      return
    }

    setIsPrimaryButtonDisabled(false)
  }

  useEffect(() => {
    validateForm()
  }, [formData])

  /** update visa document */
  const { mutate: updateVisaDocMutate, isLoading: updateVisaDocIsLoading } =
    useEditVisaDocument()

  /** Process the visa document update */
  async function updateVisaDocument() {
    updateVisaDocMutate(
      {
        candidateId,
        visaId: data.visaId,
        visaDocDetails: formData,
        attachments,
      },
      {
        onSuccess: () => {
          getVisaDocumentList()
          notify({
            alertBody: 'Visa document updated successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating visa document!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** add visa document */
  const { mutate: addVisaDocMutate, isLoading: addVisaDocIsLoading } =
    useAddVisaDocument()

  /** Process the visa document update */
  async function addVisaDocument() {
    addVisaDocMutate(
      {
        candidateId,
        visaDocDetails: formData,
        attachments,
      },
      {
        onSuccess: () => {
          getVisaDocumentList()
          notify({
            alertBody: 'Visa document added successfully',
            status: 'Success',
          })
          onDrawerClose()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error adding visa document!',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetInputFields = () => {
    setFormData({
      docName: undefined,
      docType: null,
      expiryOn: null,
      hasExpiryDate: false,
      dueOn: null,
      hasDueDate: false,
      notes: undefined,
      categoryId: documentCategory?.id,
      auditCheck: false,
    })
  }

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>([])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setAttachments([...attachments, ...acceptedFiles])
    },
    [attachments],
  )

  useEffect(() => {
    if (data?.attachmentUrls) {
      const tempAttachments = data?.attachmentUrls?.map((item: any) => {
        return {
          path: item,
          isExistingFile: true,
        }
      })
      setAttachments(tempAttachments)
    }
  }, [])

  /** on file drop file size and type validate */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** APi call for the remove existing attachment */
  const { mutate: deleteCandidateDocumentAttachmentByIdMutate } =
    useDeleteCandidateDocumentAttachmentById()

  /** Process the delete existing attachment */
  const deleteCandidateDocumentAttachmentById = (documentId, attachmentUrl) => {
    deleteCandidateDocumentAttachmentByIdMutate(
      {
        documentId,
        attachmentUrl,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** remove uploaded file */
  const removeFile = (file) => () => {
    if (file?.isExistingFile) {
      deleteCandidateDocumentAttachmentById(data?.documentId, file?.path)
    }

    const newFiles = [...attachments]
    newFiles.splice(newFiles.indexOf(file), 1)
    setAttachments(newFiles)
  }

  /** uploaded file list item  */
  const files = attachments.map((file) => (
    <li
      key={file.path}
      className='flex justify-between items-center  rounded-md border bg-white border-Gray-200 w-full px-3 py-1'>
      <div className='flex justify-start items-center w-4/5'>
        <i className='ri-attachment-2 text-Gray-400 mr-1' />

        <span
          className={CN('flex text-small font-Medium line-clamp-1 break-all', {
            'text-Gray-800 ': !file?.isExistingFile,
            'text-Green-600': file?.isExistingFile,
          })}>
          {file.isExistingFile
            ? file.path.split('/')[2].split('_').slice(1)
            : file.path}
        </span>
      </div>

      <span className='w-1/5 flex justify-end' onClick={removeFile(file)}>
        <i className='ri-close-line text-Gray-400 hover:text-Gray-600' />
      </span>
    </li>
  ))

  /** APi call for the get document type by category Id */
  const {
    mutate: getDocumentTypeByCategoryIdMutate,
    isLoading: getDocTypeIsLoading,
  } = useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const docTypes = successData?.documentTypes.map((item: any) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          setVisaDocTypes(docTypes)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    documentCategory && getDocumentTypeByCategoryId(documentCategory?.value)
  }, [documentCategory, isActive])

  /** APi call for the add new document type */
  const { mutate: addDocumentTypeMutate } = useAddDocumentType()

  /** Process the add new doc type */
  const addDocumentType = (categoryId, name) => {
    addDocumentTypeMutate(
      {
        categoryId,
        name,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setVisaDocTypes([
            ...visaDocTypes,
            {
              ...successData?.documentType,
              value: successData?.documentType.id,
              label: successData?.documentType.name,
            },
          ])
          setFormData({
            ...formData,
            docType: successData?.documentType.id,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle super provider change */
  const handleVisaDocTypeChange = (data: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addDocumentType(documentCategory?.value, data.label)
    } else {
      setFormData({
        ...formData,
        docType: data.value,
      })
    }
  }

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      primaryButtonTitle={primaryButtonTitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      onFooterAddButtonClick={() => {
        isAddMode ? addVisaDocument() : updateVisaDocument()
      }}
      onFooterCancelButtonClick={() => {
        onDrawerClose()
        resetInputFields()
      }}
      {...restProps}>
      {(updateVisaDocIsLoading ||
        getDocTypeIsLoading ||
        addVisaDocIsLoading) && <PageLoader size='xxs' />}
      <div className='px-6 py-5 flex-1'>
        <TextField
          isRequired
          className='mb-5'
          placeholder=''
          value={formData?.docName}
          label='Visa Document Name'
          onChange={(e) => {
            setFormData({
              ...formData,
              docName: e.target.value,
            })
          }}
        />
        <CreatableInputSelect
          isRequired
          label='Visa Document Type'
          className='mb-5'
          placeholder='Ex: Passport Scan, Citizenship Certificate, etc.'
          value={
            visaDocTypes?.find((item) => item.value === formData?.docType) ||
            null
          }
          options={visaDocTypes}
          onChange={handleVisaDocTypeChange}
        />

        <div className='flex flex-col'>
          <span className='font-Medium text-small text-Gray-800'>
            Attachments
          </span>

          <span className='font-Regular text-extra-small text-Gray-600'>
            Max file size 5MB - supports docx, pdf, jpg, jpeg and png, multiple
            uploads
          </span>
        </div>

        {/* Upload Document section ---------------------------------------------------------------- */}
        <section className='container mt-3 mb-5'>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <div className='flex flex-col'>
              <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                <i className='ri-upload-2-line pr-2' />

                <span className='text-small font-Medium'>Upload File</span>
              </div>
            </div>
          </div>

          {files.length > 0 && (
            <div className='flex w-full mt-2'>
              <ul className='flex flex-col w-full gap-2'>{files}</ul>
            </div>
          )}
        </section>

        <Toggle
          label='Has Expiry Date'
          className={CN('', {
            'mb-5': !formData?.hasExpiryDate,
            'mb-3': formData?.hasExpiryDate,
          })}
          setValue={formData?.hasExpiryDate}
          onToggleClick={(e: any) => {
            setFormData({
              ...formData,
              hasExpiryDate: e.target.checked,
            })
          }}
        />
        {formData.hasExpiryDate && (
          <div className='flex flex-col mb-5'>
            <span className='text-small text-Gray-800 font-Medium'>
              Expiry Date
              <span className='ml-1 text-Red-600'>*</span>
            </span>

            <CalendarInput
              value={formData?.expiryOn}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setFormData({
                    ...formData,
                    expiryOn: date,
                  })
                }
              }}
            />
          </div>
        )}

        <Toggle
          label='Request Documents from Candidate'
          className={CN('', {
            'mb-5': !formData?.hasDueDate,
            'mb-3': formData?.hasDueDate,
          })}
          setValue={formData?.hasDueDate}
          onToggleClick={(e: any) => {
            setFormData({
              ...formData,
              hasDueDate: e.target.checked,
            })
          }}
        />
        {formData.hasDueDate && (
          <div className='flex flex-col mb-5'>
            <span className='text-small text-Gray-800 font-Medium'>
              Due Date
              <span className='ml-1 text-Red-600'>*</span>
            </span>

            <CalendarInput
              value={formData?.dueOn}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setFormData({
                    ...formData,
                    dueOn: date,
                  })
                }
              }}
            />

            <span className='text-small text-Gray-500 font-Regular pt-1'>
              Candidate will be notified to upload documents before the due date
            </span>
          </div>
        )}

        <TextArea
          className='mb-5'
          value={formData?.notes}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              notes: e.target.value,
            })
          }}
          label='Notes'
          placeholder=''
          rows={5}
        />

        {/* audit check section */}
        <Toggle
          label='Audit Check'
          className='mb-5'
          setValue={formData?.auditCheck}
          onToggleClick={(e: any) => {
            setFormData({
              ...formData,
              auditCheck: e.target.checked,
            })
          }}
        />
      </div>
    </RightSideModal>
  )
}

VisaDocument.defaultProps = {}

export default VisaDocument
