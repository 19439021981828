import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Radio, TextArea, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import { useSendRequestDocument } from 'framework/api/methods'

import { CalendarInput, PageLoader } from 'components/atoms'
import { Modal, ModalFooter } from 'components/molecules'

export interface RequestDocumentModalProps {
  isActive?: boolean
  documentType?: string
  setIsActive: (value: boolean) => void
  [x: string]: any
}

export const RequestDocumentModal: FC<RequestDocumentModalProps> = ({
  className,
  isActive,
  setIsActive,
  ...restProps
}: RequestDocumentModalProps) => {
  const RequestDocumentModalClasses = CN(`request-document-modal`, className)

  const [renderKeys, setRenderKeys] = useState<any>({ receiver: 0, due: 0 })

  const { selectedSection } = useSelector((state: any) => state.audit)

  /** request document details state */
  const [requestDocumentDetails, setRequestDocumentDetails] = useState<any>({
    candidateId: selectedSection?.candidateId,
    categoryId: selectedSection?.categoryId,
    isConsultant: false,
    isCandidate: false,
    dueDate: '',
    note: '',
  })

  /** modal footer primary button disabled  state*/
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)

  /** APi call for send request document */
  const {
    mutate: sendRequestDocumentMutate,
    isLoading: sendRequestDocumentIsLoading,
  } = useSendRequestDocument()

  /** Process the send request document */
  const sendRequestDocument = () => {
    sendRequestDocumentMutate(
      {
        requestDocumentDetails,
        documentId: selectedSection?.documentId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle,
            alertBody: successData?.message ? successData.message : '',
            status: 'Success',
          })
          resetInputFields()
          setIsActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** reset input fields */
  const resetInputFields = () => {
    setRequestDocumentDetails({
      ...requestDocumentDetails,
      isConsultant: false,
      isCandidate: false,
      dueDate: '',
      note: '',
    })

    setRenderKeys({
      ...renderKeys,
      due: Math.random(),
    })
  }

  /** handle modal footer primary button disable */
  useEffect(() => {
    const { isConsultant, isCandidate, dueDate } = requestDocumentDetails || {}
    ;(isCandidate && isConsultant) || dueDate
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [requestDocumentDetails])

  return (
    <div className={RequestDocumentModalClasses} {...restProps}>
      <Modal
        headerTitle={`Request Documents - ${selectedSection?.type}`}
        size='md'
        isFooterShow={true}
        isActive={isActive}
        onHeaderCloseButtonClick={() => {
          setIsActive(false)
        }}
        onOverlayClick={() => {
          setIsActive(false)
        }}
        footerButtonAlignment='right'
        primaryButtonTitle='Request Document'
        secondaryButtonTitle='Cancel'>
        {sendRequestDocumentIsLoading && <PageLoader size='xxs' />}

        <div className='flex flex-col p-6 w-full'>
          <div className='flex flex-col w-full mb-6'>
            <div className='flex flex-col text-small text-Gray-500'>
              <span className='w-full font-Medium text-small text-Gray-800 mb-3'>
                To whom should this be sent?
              </span>

              <div className='flex gap-x-5' key={renderKeys?.receiver}>
                <Radio
                  checked={requestDocumentDetails?.isConsultant}
                  id='Consultant'
                  labelText='Consultant'
                  helperText=''
                  name='receiver'
                  onChange={() => {
                    setRequestDocumentDetails({
                      ...requestDocumentDetails,
                      isConsultant: true,
                      isCandidate: false,
                    })
                  }}
                />

                <Radio
                  checked={requestDocumentDetails?.isCandidate}
                  id='Candidate'
                  labelText='Candidate'
                  helperText=''
                  name='receiver'
                  onChange={() => {
                    setRequestDocumentDetails({
                      ...requestDocumentDetails,
                      isConsultant: false,
                      isCandidate: true,
                    })
                  }}
                />
              </div>
            </div>

            <div className='flex flex-col text-small text-Gray-500 w-full'>
              <span className='flex mt-8 text-small text-Gray-800 font-Medium'>
                Due Date
              </span>

              <CalendarInput
                key={renderKeys?.due}
                defaultValue={requestDocumentDetails?.dueDate}
                formatDate={(date) => format(date, 'dd/MM/yyyy')}
                parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                className='mt-1 w-full'
                disableFutureDates={false}
                onChange={(date) => {
                  if (date) {
                    setRequestDocumentDetails({
                      ...requestDocumentDetails,
                      dueDate: date,
                    })
                  }
                }}
              />
            </div>
          </div>

          <TextArea
            className='notes pt-5'
            value={requestDocumentDetails?.note}
            onChange={(e: any) => {
              setRequestDocumentDetails({
                ...requestDocumentDetails,
                note: e.target.value,
              })
            }}
            label='Note'
            placeholder='write something here'
            rows={4}
          />
        </div>

        <ModalFooter>
          <div className='flex justify-between flex-1'>
            <Button
              onClick={() => {
                sendRequestDocument()
              }}
              isDisabled={isPrimaryButtonDisabled}
              className='mr-2'
              theme='cobalt'>
              Request Document
            </Button>

            <Button
              onClick={() => {
                setIsActive(false)
              }}
              textTheme='black'
              theme='white'>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

RequestDocumentModal.defaultProps = {}

export default RequestDocumentModal
