/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { MoreButton } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'

export const QuestionnairesListColumn = (
  tableData,
  setTableData,
  navigate,
  setIsShowPreviewModal,
  setSelectedTemplate,
  setShowDeleteQuestionaryModal,
) => {
  const COLUMNS = [
    {
      Header: 'QUESTIONNAIRE',
      accessor: 'name',
      forSorting: 'name',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[300px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='max-w-[300px] break-all line-clamp-1'>
            {original?.name}
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'isActive',
      forSorting: 'isactive',
      isSortable: true,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[140px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge size='large' theme={original?.isActive ? 'primary' : 'gray'}>
              {original?.isActive ? 'Published' : 'Draft'}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'QUESTIONS',
      accessor: 'questionCount',
      forSorting: 'questionCount',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[110px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.questionCount}</div>
      },
    },
    {
      Header: 'CATEGORY',
      accessor: 'type',
      forSorting: 'type',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[210px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div>
            {original?.type === 'WEB_CHECK'
              ? 'Web Ref Check'
              : original?.type === 'PHONE_CHECK'
              ? 'Phone Ref Check'
              : original?.type === 'HEALTH_DECLARATION'
              ? 'Health Declaration'
              : original?.type === 'REG_PACK'
              ? 'Reg Pack'
              : undefined}
          </div>
        )
      },
    },
    {
      Header: 'CREATED ON',
      accessor: 'createdOn',
      forSorting: 'createdon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[236px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateString = timeToAESTWithSeconds(original?.createdOn)

        return <div>{createdDateString}</div>
      },
    },
    {
      Header: 'LAST EDITED ON',
      accessor: 'updatedOn',
      forSorting: 'updateon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[236px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const updatedDateString = timeToAESTWithSeconds(original?.updatedOn)

        return <div>{updatedDateString}</div>
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[65px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row,
          data,
          row: { original },
        } = props || {}

        const { showMore } = original

        const toggleShowMore = (rowData: any) => {
          const toBeUpdateTableData = {
            ...tableData,
            questionnaires: tableData?.questionnaires?.map((item: any) => {
              if (item?.id === rowData?.id) {
                return {
                  ...item,
                  showMore: !item?.showMore,
                }
              } else {
                return {
                  ...item,
                  showMore: false,
                }
              }
            }),
          }
          setTableData(toBeUpdateTableData)
        }

        return (
          <>
            <div className='relative'>
              <MoreButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  toggleShowMore(original)
                }}
                className={CN({
                  'bg-Gray-100': showMore,
                })}
              />
            </div>

            {showMore && (
              <div
                className={CN(
                  'absolute  right-[55px] z-[999999] flex flex-col justify-start w-[140px] shadow-xl border-[1px] border-Gray-200 rounded-md py-3 cursor-pointer bg-white',
                  {
                    'top-[25px]':
                      row.index < data.length - 5 && data.length > 6,
                    'top-[-90px]': row.index >= data.length - 5,
                    'top-[24px]': data.length <= 6,
                  },
                )}
                key={original.id}>
                <span
                  className='flex items-center py-2 pl-4 font-Regular text-Gray-800 hover:bg-Gray-100'
                  onClick={() => {
                    setIsShowPreviewModal(true)
                    setSelectedTemplate({
                      value: original.id,
                      label: original.name,
                    })

                    toggleShowMore(original)
                  }}>
                  Preview
                </span>

                <span
                  className='flex items-center py-2 pl-4 font-Regular text-Gray-800 hover:bg-Gray-100'
                  onClick={() => {
                    navigate(
                      `/questionnaires/edit-questionnaire/${original.id}`,
                    )
                    toggleShowMore(original)
                  }}>
                  Edit
                </span>

                <span
                  className='flex items-center py-2 pl-4 font-Regular text-Red-600 hover:bg-Gray-100'
                  onClick={() => {
                    setSelectedTemplate({
                      value: original.id,
                      label: original.name,
                    })
                    setShowDeleteQuestionaryModal(true)
                    toggleShowMore(original)
                  }}>
                  Delete
                </span>
              </div>
            )}
          </>
        )
      },
    },
  ]

  return COLUMNS
}

export default QuestionnairesListColumn
