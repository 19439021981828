/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetStateListInterface {
  countryCode?: string
}

export const useGetStateList = () => {
  const mutate = useMutation(({ countryCode }: useGetStateListInterface) => {
    return get(`/State?CountryCode=${countryCode}`, {})
  })

  return mutate
}

export default useGetStateList
