import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteClientLocationInterface {
  clientId?: any
  LocationId?: any
}

export const useDeleteClientLocation = () => {
  const mutate = useMutation(
    ({ clientId, LocationId }: DeleteClientLocationInterface) => {
      return deleteAPI(`/facade/Client/${clientId}/Location/${LocationId}`)
    },
  )

  return mutate
}

export default useDeleteClientLocation
