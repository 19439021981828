/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetCandidateMedicalIncidentLocationListInterface {}

export const useGetCandidateMedicalIncidentLocationList = () => {
  const mutate = useMutation(
    ({}: useGetCandidateMedicalIncidentLocationListInterface) => {
      return get(`/Location/List`, {})
    },
  )

  return mutate
}

export default useGetCandidateMedicalIncidentLocationList
