import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addClientLocationProps {
  clientLocationDetails?: any
  clientId?: any
}

export const useAddClientLocation = () => {
  const mutate = useMutation(
    ({ clientLocationDetails, clientId }: addClientLocationProps) => {
      /** Backend Issue : sending non usable attribute line two and city. refuse to remove . told send empty string to lineTwo
       * and use suburb value for city.
       */

      return postAPI(`/facade/Client/${clientId}/Location`, {
        primaryContactId: clientLocationDetails?.primaryContactPerson,
        name: clientLocationDetails?.name,
        address: {
          lineOne: clientLocationDetails?.streetName,
          lineTwo: '',
          city: clientLocationDetails?.suburb,
          suburb: clientLocationDetails?.suburb,
          state: clientLocationDetails?.state,
          stateCode: clientLocationDetails?.stateCode,
          country: clientLocationDetails?.country,
          countryCode: clientLocationDetails?.countryCode,
          postCode: clientLocationDetails?.postCode,
          latitude:
            clientLocationDetails?.latitude !== ''
              ? clientLocationDetails?.latitude
              : 0,
          longitude:
            clientLocationDetails?.longitude !== ''
              ? clientLocationDetails?.longitude
              : 0,
        },
        phoneNumber: clientLocationDetails?.phoneNumber,
        email: clientLocationDetails?.email,
      })
    },
  )

  return mutate
}

export default useAddClientLocation
