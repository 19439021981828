/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import CN from 'classnames'
import { resetToInitial } from 'store/reducers/side-menu/mainSideMenuReducer'

interface HeaderMoreButtonDropDownProps {
  className?: string | undefined
  isCustomStyles?: boolean
  onAgencyProfileClick?: any | undefined
  onUserGroupClick?: any | undefined
  [x: string]: any
}

export const HeaderMoreButtonDropDown: FC<HeaderMoreButtonDropDownProps> = ({
  className,
  isCustomStyles,
  onAgencyProfileClick,
  onUserGroupClick,
  ...restProps
}: HeaderMoreButtonDropDownProps) => {
  const dispatch = useDispatch()
  /** user drop down main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN(
        'absolute top-2 right-[20px] flex flex-col w-[224px] rounded-[8px] z-20 bg-white shadow',
        className,
      )
    : className

  return (
    <div className={textFieldMainClassName} {...restProps}>
      <div className='flex pl-[14px] pt-[13px] cursor-pointer text-Gray-500 hover:text-Gray-900'>
        <i className='ri-user-settings-line text-heading-5' />

        <span
          className='font-Regular pl-[10px] text-Gray-500 hover:text-Gray-900'
          onClick={() => {
            dispatch(resetToInitial())
            onUserGroupClick && onUserGroupClick()
          }}>
          User Groups
        </span>
      </div>

      <div className='flex pl-[14px] pt-4 pb-3 cursor-pointer text-Gray-500 hover:text-Gray-900'>
        <i className='ri-profile-line text-heading-5' />

        <span
          className='font-Regular pl-[10px] text-Gray-500 hover:text-Gray-900'
          onClick={() => {
            dispatch(resetToInitial())
            onAgencyProfileClick && onAgencyProfileClick()
          }}>
          Agency Profile
        </span>
      </div>
    </div>
  )
}

export default HeaderMoreButtonDropDown

HeaderMoreButtonDropDown.defaultProps = {
  className: undefined,
  isCustomStyles: false,
  onAgencyProfileClick: undefined,
  onUserGroupClick: undefined,
}
