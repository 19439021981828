/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { motion } from 'framer-motion'
import { setMenu as acSetMenu } from 'store/reducers/side-menu/mainSideMenuReducer'

interface SubMenuProps {
  menuItems: any
  index: number
  subMenu: any | []
  isActive: boolean
  setToggleMenu: any
  toggleMenu: boolean
  title: string
}

/** sub menu component */
const SubMenu: FC<SubMenuProps> = ({
  menuItems,
  subMenu,
  isActive,
  setToggleMenu,
  title,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const motionVariantsTitle = {
    initialParam: {
      x: -20,
    },
    animateParam: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
    exitParam: {
      opacity: 0,
    },
  }

  const motionVariantsSubTitle = {
    initialParam: {
      opacity: 0,
      y: -10,
    },
    animateParam: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
    exitParam: {
      opacity: 0,
    },
  }

  /** handle main menu object when click sub list item's inside items  */
  const handleSubMenuItemClick = (selectedId: any) => {
    const tempSideMenu = menuItems.map((menuItem: any) => {
      if (menuItem.isActive) {
        const temp = menuItem.subMenu.map((subMenuItem: any) => {
          if (subMenuItem.id == selectedId) {
            if (!subMenuItem.isActive) {
              if (!subMenuItem.hasSubMenu) {
                navigate(subMenuItem.link)
                dispatch(setToggleMenu(false))
              }
              return { ...subMenuItem, isActive: true }
            } else {
              return { ...subMenuItem, isActive: false }
            }
          } else {
            return { ...subMenuItem, isActive: false }
          }
        })
        return { ...menuItem, subMenu: temp }
      } else {
        return { ...menuItem, isActive: false }
      }
    })
    dispatch(acSetMenu(tempSideMenu))
  }

  /** handle Inner List Item click   */
  const handleInnerListClick = (selectedId: any) => {
    const tempSideMenu = menuItems.map((menuItem: any) => {
      if (menuItem.isActive) {
        const tempSubMenuItems = menuItem.subMenu.map((subMenuItem: any) => {
          if (subMenuItem.isActive) {
            const tempInnerItems = subMenuItem.subMenu.map((innerItem: any) => {
              if (innerItem.id === selectedId) {
                if (!innerItem.isActive) {
                  navigate(innerItem.link)
                  return { ...innerItem, isActive: true }
                } else {
                  return { ...innerItem, isActive: false }
                }
              } else {
                return { ...innerItem, isActive: false }
              }
            })
            return { ...subMenuItem, subMenu: tempInnerItems }
          } else {
            return { ...subMenuItem, isActive: false }
          }
        })
        return { ...menuItem, subMenu: tempSubMenuItems }
      } else {
        return { ...menuItem, isActive: false }
      }
    })
    dispatch(acSetMenu(tempSideMenu))
  }

  return (
    <motion.div
      className={CN('absolute top-0 h-full z-20 bg-white shadow', {
        'left-0 w-0 p-0 ': !isActive,
        'right-[-204px] w-[204px] px-3 pt-4': isActive,
      })}
      initial='initialParam'
      animate='animateParam'
      exit='exitParam'
      variants={motionVariantsTitle}>
      <div className='flex h-6 justify-start items-center mb-2'>
        <i
          className='ri-arrow-left-s-line w-6 h-6 flex justify-center items-center text-small text-Gray-500 bg-white border-[1px] border-Gray-300 rounded-[7px] absolute right-[-10px] cursor-pointer'
          onClick={(e: any) => {
            e.stopPropagation()
            dispatch(setToggleMenu(false))
          }}
        />
        <span className='flex text-extra-small font-[800] text-Gray-500'>
          {title.toUpperCase()}
        </span>
      </div>

      {subMenu?.map((item: any, index: number) => (
        <div
          key={index}
          // to={!item.hasSubMenu && item.link}
          className='flex cursor-pointer'
          onClick={(e) => {
            e.stopPropagation()
            handleSubMenuItemClick(item.id)
          }}>
          <div className='w-full flex flex-col items-center justify-between cursor-pointer mb-2'>
            <div className='w-full flex flex-col '>
              <motion.div
                className={CN('flex flex-col w-full')}
                initial='initialParam'
                animate='animateParam'
                exit='exitParam'
                variants={motionVariantsTitle}>
                <div
                  className={CN(
                    'w-full flex justify-between rounded-md py-2 px-2 text-small text-[#333333] hover:bg-Orangezest-right/10 hover:text-Orangezest-right hover:font-SemiBold',
                    {
                      'bg-Orangezest-right/10 text-Orangezest-right font-SemiBold mb-1':
                        item?.isActive,
                    },
                  )}>
                  <span>{item.title}</span>
                  {item.isActive && item.hasSubMenu && (
                    <i className='ri-arrow-down-s-line' />
                  )}
                  {!item.isActive && item.hasSubMenu && (
                    <i className='ri-arrow-up-s-line' />
                  )}
                </div>

                {/* sub menu item list */}
                {item?.hasSubMenu &&
                  item?.isActive &&
                  item?.subMenu?.map((innerItem: any, key: number) => (
                    <div
                      // to={i.link}
                      key={key}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleInnerListClick(innerItem.id)
                      }}
                      className='ml-2 border-l-2 border-l-Gray-200'>
                      <motion.div
                        className={CN(
                          'w-full flex-col pl-4 text-extra-small rounded-md py-[7px] hover:text-Orangezest-right hover:font-SemiBold',
                          {
                            'font-SemiBold text-Orangezest-right':
                              innerItem.isActive,
                            'text-[#999999]': !innerItem.isActive,
                          },
                        )}
                        initial='initialParam'
                        animate='animateParam'
                        exit='exitParam'
                        variants={motionVariantsSubTitle}>
                        {innerItem.title}
                      </motion.div>
                    </div>
                  ))}
              </motion.div>
            </div>
          </div>
        </div>
      ))}
    </motion.div>
  )
}

export default SubMenu
