/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { RightSideModal, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetEmailValidation, useUserInvite } from 'framework/api/methods'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { isEmail, isLetters } from 'utils'

export interface InviteNewUserProps {
  buttonTitle: string
  footerCancelButton?: (e: any) => void | undefined
  onOverlayClick?: (e: any) => void | undefined
  headerCloseButton?: () => void | undefined
  headerSubtitle: string
  headerTitle: string
  isButtonDisabled: boolean
  [x: string]: any
}

export const InviteNewUser: FC<InviteNewUserProps> = ({
  buttonTitle,
  footerCancelButton,
  getTableData,
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  inviteUserModalShow,
  notify,
  onOverlayClick,
  roleId,
}: InviteNewUserProps) => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    displayName: '',
    jobTitle: '',
    email: '',
    telNo: '',
  })

  /** email and phone number validation */
  const [isErrorField, setIsErrorField] = useState<any>({
    fullName: false,
    displayName: false,
    jobTitle: false,
    email: false,
    telNo: false,
  })

  /** API call for the invite new user */
  const { mutate: addNewInvite, isLoading: isLoadingNewUserInvite } =
    useUserInvite()

  /** Process the invite new User  */
  async function newInvite() {
    addNewInvite(
      {
        email: userDetails.email,
        roleId: roleId,
        displayName: userDetails.displayName,
        fullName: userDetails.fullName,
        jobTitle: userDetails.jobTitle,
        phoneNumber: userDetails.telNo,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setShowSuccessScreen(true)
          notify({
            alertHeader: successData.message ? successData.message : 'Success!',
            alertBody: '',
            status: 'Success',
          })
          getTableData()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get user email validation */
  const { mutate: userListByNameMutate } = useGetEmailValidation()

  /** Process the get User Email validation */
  async function getUserEmailValidation(email: any) {
    userListByNameMutate(
      { userEmail: email },
      {
        onSuccess: () => {
          setEmailValid(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { fullName, displayName, jobTitle, email, telNo } = userDetails

    setIsErrorField({
      ...isErrorField,
      fullName: isLetters(fullName) ? false : true,
      displayName: displayName ? false : true,
      jobTitle: jobTitle ? false : true,
      email: isEmail(email) ? false : true,
      telNo: telNo ? false : true,
    })

    const isAllValidFields =
      isEmail(email) && telNo && isLetters(fullName) && displayName && jobTitle

    return isAllValidFields
  }

  /** After submission clearing the records. */
  const resetFieldValues = () => {
    setUserDetails({
      fullName: '',
      displayName: '',
      jobTitle: '',
      email: '',
      telNo: '',
    })
  }

  /** user email onchange function */
  const handleChange = (e: any) => {
    setEmailValid(false)
    setUserDetails({ ...userDetails, email: e.target.value })
  }

  useEffect(() => {
    isEmail(userDetails.email)
      ? getUserEmailValidation(userDetails.email)
      : setEmailValid(false)
  }, [userDetails.email])

  /** Process of posting user details */
  const handleSubmit = () => {
    const valid = validateInputs()

    if (valid) {
      newInvite()
    }
  }

  return (
    <RightSideModal
      isFooterShow={showSuccessScreen ? false : true}
      isActive={inviteUserModalShow}
      className='w-full'
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      isPrimaryButtonDisable={
        userDetails.fullName &&
        userDetails.displayName &&
        userDetails.jobTitle &&
        userDetails.email &&
        userDetails.telNo &&
        emailValid
          ? false
          : true
      }
      primaryButtonTitle={buttonTitle}
      onHeaderCloseButtonClick={() => {
        resetFieldValues()
        setShowSuccessScreen(false)
        setEmailValid(false)
        headerCloseButton && headerCloseButton()
      }}
      onOverlayClick={onOverlayClick}
      onFooterCancelButtonClick={footerCancelButton}
      onFooterAddButtonClick={handleSubmit}>
      {/** Body section of the drawer modal */}
      {!showSuccessScreen ? (
        <>
          {isLoadingNewUserInvite && <PageLoader size='xxs' />}
          {
            <div className='flex flex-col justify-between w-full h-full'>
              <div className='px-6'>
                <TextField
                  className='full-name pt-6 w-[412px]'
                  label='Full name'
                  value={userDetails.fullName}
                  onChange={(e: any) =>
                    setUserDetails({ ...userDetails, fullName: e.target.value })
                  }
                  placeholder='Full name'
                  isErrored={isErrorField.fullName}
                />

                <TextField
                  className='display-name pt-6 w-[412px]'
                  label='Display name'
                  value={userDetails.displayName}
                  onChange={(e: any) =>
                    setUserDetails({
                      ...userDetails,
                      displayName: e.target.value,
                    })
                  }
                  placeholder='Display name'
                  isErrored={isErrorField.displayName}
                />

                <TextField
                  className='job-title pt-6 w-[412px]'
                  label='Job title'
                  value={userDetails.jobTitle}
                  onChange={(e: any) =>
                    setUserDetails({
                      ...userDetails,
                      jobTitle: e.target.value,
                    })
                  }
                  placeholder='Job title'
                  isErrored={isErrorField.jobTitle}
                />

                <div className='flex justify-between items-center'>
                  <TextField
                    className='username pt-6 w-[412px]'
                    label='Email'
                    value={userDetails.email}
                    onChange={(e: any) => handleChange(e)}
                    placeholder='Email'
                    isErrored={isErrorField.email}
                  />

                  <i
                    className={CN(
                      'ri-checkbox-circle-fill pl-[10px] pt-[60px] text-heading-4 text-Gray-300 pb-2',
                      {
                        'text-Green-400': emailValid,
                      },
                    )}
                  />
                </div>

                {/** Phone number field */}

                <PhoneInputField
                  label='Phone number'
                  value={userDetails.telNo}
                  onChange={(number: any) =>
                    setUserDetails({ ...userDetails, telNo: number })
                  }
                  className='pt-6 w-[412px]'
                  isErrored={isErrorField.telNo}
                />
              </div>

              <div className='flex items-center bg-Cobalt-50 py-6'>
                <i className='ri-error-warning-fill text-Gray-500 pl-6 pr-[10px]'></i>

                <span className='font-Regular text-small text-Gray-600 pr-1'>
                  Learn more about users
                </span>

                <a href='#' className='font-Medium text-small text-Cobalt-600'>
                  here
                </a>
              </div>
            </div>
          }
        </>
      ) : (
        /** Email sent informing screen */
        <div className='flex flex-col pt-[295px]'>
          <div className='flex justify-center items-center w-12 h-12 ml-[220px] rounded-full bg-Green-300 border-[6px] border-Green-100'>
            <i className='ri-mail-send-line text-white text-[22px]'></i>
          </div>

          <span className='font-Medium text-heading-5 text-Gray-800 pl-[192px] pt-6'>
            Successfully sent!
          </span>

          <span className='font-Regular text-small text-Gray-600 pl-[132px] pt-1'>
            {` Sent a request to ${userDetails.email} `}
          </span>
        </div>
      )}
    </RightSideModal>
  )
}

InviteNewUser.defaultProps = {
  buttonTitle: undefined,
  footerCancelButton: undefined,
  onOverlayClick: undefined,
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
  isButtonDisabled: undefined,
}

export default InviteNewUser
