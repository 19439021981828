/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  useAddAudioClip,
  useDeleteAudioClip,
  useGetAudioClipByRefereeId,
} from 'framework/api/methods'

import { PageLoader } from 'components/atoms'
import { Modal, ModalFooter } from 'components/molecules'

export interface callRecordingModalProps {
  isActive: boolean
  setIsActive: (value: boolean) => void
  onRequestRefereeClick: () => void
  [x: string]: any
}

export const CallRecordingModal: FC<callRecordingModalProps> = ({
  isActive,
  selectedRefereeDetails,
  setIsActive,
}: callRecordingModalProps) => {
  const [audioFile, setAudioFile] = useState<File>()

  const [audioClipDetails, setAudioClipDetails] = useState<any>({
    audioUrl: '',
    audioSignUrl: '',
  })

  const notify = (props: any) => Toast(props)

  const [selectedFiles, setSelectedFiles] = useState<any>([])

  useEffect(() => {
    setSelectedFiles([])
  }, [selectedRefereeDetails])

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setSelectedFiles(acceptedFiles)
      setAudioFile(acceptedFiles[0])
    },
    [selectedFiles],
  )

  const removeFile = (index: number) => {
    const newFiles = [...selectedFiles]
    newFiles.splice(index, 1)
    setSelectedFiles(newFiles)
    setAudioFile(newFiles[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 100 * 1024 * 1024,
    accept: {
      'audio/mp3': ['.m4a', '.flac', '.mp3', '.wav', '.wma', '.aac', '.ogg'],
    },
    onDrop,
  })

  /** Get audio clip api */
  const { mutate: getAudioClipMutate, isLoading: getAudioClipIsLoading } =
    useGetAudioClipByRefereeId()

  /** Process the get audio clip */
  async function getAudioClip(refereeId: any) {
    getAudioClipMutate(
      {
        refereeId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setAudioClipDetails({
            ...audioClipDetails,
            audioUrl: successData?.audioUrl,
            audioSignUrl: successData?.audioSignUrl,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting audio clip',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the remove existing audio clip */
  const { mutate: deleteAudioClipMutate } = useDeleteAudioClip()

  /** Process the delete existing audio clip */
  const deleteAudioClip = (refereeId) => {
    deleteAudioClipMutate(
      {
        refereeId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })

          setSelectedFiles([])
          getAudioClip(selectedRefereeDetails?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** add new audio clip */
  const { mutate: addAudioClipMutate, isLoading: addAudioClipIsLoading } =
    useAddAudioClip()

  async function addAudioClip() {
    addAudioClipMutate(
      {
        refereeId: selectedRefereeDetails?.id,
        audioFile,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            status: 'Success',
          })

          setIsActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    isActive && getAudioClip(selectedRefereeDetails?.id)
  }, [isActive])

  return (
    <Modal
      headerTitle='Upload Call Recording'
      isFooterShow={true}
      size='md'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}
      onOverlayClick={() => {
        setIsActive(false)
      }}>
      {(addAudioClipIsLoading || getAudioClipIsLoading) && (
        <PageLoader size='xxs' />
      )}

      <div className='flex flex-col p-6 w-full'>
        <span className='font-Medium text-small text-Gray-800'>Attachment</span>

        <ul className='font-Regular text-extra-small text-Gray-600 ml-5 list-disc'>
          <li>Max file size 100MB - supports mp3, ogg and wav file types.</li>
          <li>No multiple uploads.</li>
          <li>Uploading a new one will replace the existing one.</li>
          <li>Click on the file name to play the audio in a new tab.</li>
        </ul>

        <span className='font-Medium text-small text-Gray-800 mt-4'>
          Upload Call Recording
        </span>

        <section className=''>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <Button
              textTheme='blue'
              size='xl'
              theme='white'
              className='w-full h-[38px] mt-2'>
              <i className='ri-upload-2-line text-small' />
              <span className='font-Medium text-Cobalt-600 ml-2'>Upload</span>
            </Button>
          </div>

          <div className='py-2 gap-2'>
            {!audioClipDetails?.audioUrl ? (
              selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className='flex items-center justify-between pl-2 w-full  box-border border border-Gray-200 rounded-md'>
                  <div>
                    <i className='ri-attachment-2 text-Gray-400 pr-2' />
                    <span className='text-small font-Medium text-Gray-800'>
                      {file.name}
                    </span>
                  </div>

                  <span
                    onClick={() => {
                      removeFile(index)
                    }}>
                    <i className='ri-close-line text-Gray-400 pr-2 cursor-pointer' />
                  </span>
                </div>
              ))
            ) : (
              <div className='flex items-center justify-between pl-2 w-full  box-border border border-Gray-200 rounded-md'>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    window.open(audioClipDetails?.audioSignUrl)
                  }}>
                  <i className='ri-attachment-2 text-Gray-400 pr-2' />
                  <span className='text-small font-Medium text-Gray-800'>
                    {audioClipDetails?.audioUrl
                      .split('/')[2]
                      .split('_')
                      .slice(1)}
                  </span>
                </div>

                <span
                  onClick={() => {
                    deleteAudioClip(selectedRefereeDetails?.id)
                  }}>
                  <i className='ri-close-line text-Gray-400 pr-2 cursor-pointer' />
                </span>
              </div>
            )}
          </div>
        </section>
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              addAudioClip()
            }}
            className='mr-2'
            isDisabled={
              selectedFiles?.length > 0 || audioClipDetails?.audioUrl
                ? false
                : true
            }
            theme='cobalt'>
            Save Recording
          </Button>

          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

CallRecordingModal.defaultProps = {}

export default CallRecordingModal
