import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, Tabs, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useCreateClientLogo,
  useDeleteClientLogo,
  useGetClientById,
  useGetClientContactList,
  useGetClientLocationsById,
} from 'framework/api/methods'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'
import {
  setBackFunction,
  setIsLoading,
} from 'store/reducers/layout/layoutSlice'

import { EditClient } from 'components/Drawers/client'
import {
  ClientProfileDetailSection,
  CustomizableEditProfilePictureModal,
} from 'components/molecules'

import {
  ContactTabView,
  DashboardTabView,
  LocationTabView,
} from './clientProfileTabs'

export interface ClientProfileProps {
  [x: string]: any
}

export const ClientProfile: FC<ClientProfileProps> = ({
  className,
  ...restProps
}: ClientProfileProps) => {
  const Location: any = useLocation()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/client-list')
      }),
    )
  }, [])

  /** image upload states */
  const [imageUrl, setImageUrl] = useState<any>('')
  const [files, setFiles] = useState<any>()
  const [showEditLogoModal, setShowEditLogoModal] = useState(false)
  const [showDeleteLogoModal, setShowDeleteLogoModal] = useState(false)

  /** client overview states */
  const [isOpenEditClientDrawer, setIsOpenEditClientDrawer] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState<any>()
  const [clientData, setClientData] = useState<any>()

  /** location tab states */
  const [clientLocations, setClientLocations] = useState<any>([])

  /** client tab states */
  const [contactList, setContactList] = useState<any>([])

  /** state for open contact tab when click on location card contact details */
  const [openTab, setOpenTab] = useState<any>()

  /** Start BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */
  const { menuItems } = useSelector((state: any) => state.sideMenuList)
  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [Location, menuItems])
  /** End BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  /** Get Client By ID API Call */
  const getClientByIdOnSuccess = ({ data: successData }: any) => {
    setImageUrl('')
    setClientData(successData?.client)
  }
  const getClientByIdOnError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: 'Error...!',
      alertBody: errData?.message
        ? errData?.message
        : 'Error getting client details!',
      status: 'Error',
    })
  }

  const { refetch: getClientByIdRefetch, isFetching: getClientByIdIsFetching } =
    useGetClientById(
      {
        Id: selectedClientId,
      },
      getClientByIdOnSuccess,
      getClientByIdOnError,
    )

  /** Get Client Locations by ID API Call */
  const getClientLocationsOnSuccess = ({ data: successData }: any) => {
    setClientLocations(successData?.clientLocations)
  }
  const getClientLocationsOnError = ({ response: { data: errData } }: any) => {
    /** Alert only display when error happens */
    if (errData?.message === 'Client location not exist') {
      setClientLocations([])
    } else {
      notify({
        alertHeader: 'Error...!',
        alertBody: errData?.message
          ? errData?.message
          : 'Error getting client details!',
        status: 'Error',
      })
    }
  }

  const {
    refetch: getClientLocationsByIdRefetch,
    isFetching: getClientLocationsByIdIsFetching,
  } = useGetClientLocationsById(
    {
      Id: selectedClientId,
    },
    getClientLocationsOnSuccess,
    getClientLocationsOnError,
  )

  /** Get Client Contact List by ID API Call */
  const getClientContactListOnSuccess = ({ data: successData }: any) => {
    setContactList(successData?.clientContacts)
  }
  const getClientContactListOnError = ({
    response: { data: errData },
  }: any) => {
    /** Alert only display when error happens */
    if (errData?.message === 'Client contact not exist') {
      setClientLocations([])
    } else {
      notify({
        alertHeader: 'Error...!',
        alertBody: errData?.message
          ? errData?.message
          : 'Error getting client details!',
        status: 'Error',
      })
    }
  }

  const {
    refetch: getClientContactListRefetch,
    isFetching: getClientContactListIsFetching,
  } = useGetClientContactList(
    {
      Id: selectedClientId,
    },
    getClientContactListOnSuccess,
    getClientContactListOnError,
  )

  /** API Call for delete client logo using react-query */
  const {
    mutate: deleteClientLogoMutate,
    isLoading: deleteClientLogoIsLoading,
  } = useDeleteClientLogo()

  /** Process the Delete client logo */
  async function deleteClientLogo(Id: string) {
    deleteClientLogoMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Logo was removed!',
            alertBody: '',
            status: 'Success',
          })
          getClientByIdRefetch()
          setShowDeleteLogoModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
          setShowDeleteLogoModal(false)
        },
      },
    )
  }

  /** upload client logo api */
  const {
    mutate: createClientLogoMutate,
    isLoading: createClientLogoIsLoading,
  } = useCreateClientLogo()

  /** Process the upload client logo */
  async function UploadClientLogo(Id: any, avatarObject: any) {
    createClientLogoMutate(
      {
        Id,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message
              ? successData.message
              : 'Logo updated!',
            alertBody: '',
            status: 'Success',
          })
          setShowEditLogoModal(false)
          getClientByIdRefetch()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowEditLogoModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get user ID from url when page visit */
  useEffect(() => {
    const clientId: any = searchParams.get('client_id')
    if (clientId) {
      setSelectedClientId(clientId)
    }
  }, [searchParams])

  useEffect(() => {
    if (selectedClientId?.length > 0) {
      getClientByIdRefetch()
      getClientLocationsByIdRefetch()
      getClientContactListRefetch()
    }
  }, [selectedClientId])

  /** handle upload client logo button */
  const handleUploadLogo = (files: any) => {
    UploadClientLogo(clientData?.id, files)
  }

  /** client profile tab details */
  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Dashboard',
      tabContent: <DashboardTabView />,
    },
    {
      id: 2,
      tabButtonContent: 'Locations',
      tabContent: (
        <LocationTabView
          clientId={clientData?.id}
          clientLocations={clientLocations}
          notify={notify}
          contactItemList={contactList}
          getClientLocationsById={getClientLocationsByIdRefetch}
          getClientContactsList={getClientContactListRefetch}
          setOpenTab={setOpenTab}
        />
      ),
    },
    {
      id: 3,
      tabButtonContent: 'Contacts',
      tabContent: (
        <ContactTabView
          notify={notify}
          clientId={clientData?.id}
          contactItemList={contactList}
          getClientContactsList={getClientContactListRefetch}
          getClientLocationsById={getClientLocationsByIdRefetch}
          clientLocations={clientLocations}
        />
      ),
    },
  ]

  /** handle Main Loading Status */
  useEffect(() => {
    if (
      getClientByIdIsFetching ||
      getClientLocationsByIdIsFetching ||
      getClientContactListIsFetching
    ) {
      dispatch(setIsLoading(true))
    } else {
      dispatch(setIsLoading(false))
    }
  }, [
    getClientByIdIsFetching,
    getClientLocationsByIdIsFetching,
    getClientContactListIsFetching,
  ])

  /** client profile main style */
  const ClientProfileClasses = CN(`client-profile`, className)

  return (
    <div className={ClientProfileClasses} {...restProps}>
      {/* {getClientByIdIsLoading &&
        getClientLocationsByIdIsLoading &&
        getClientContactListIsLoading && <PageLoader size='xxs' />} */}

      {clientData && (
        <ClientProfileDetailSection
          clientData={clientData}
          onClientDetailsEditButtonClick={() => {
            setIsOpenEditClientDrawer(true)
          }}
          onAddNewLogoClick={() => {
            setShowEditLogoModal(true)
          }}
          onDeleteLogoClick={() => {
            setShowDeleteLogoModal(true)
          }}
          onEditLogoClick={() => {
            setShowEditLogoModal(true)
          }}
        />
      )}

      {clientData && (
        <Tabs
          onHeaderBtnClick={() => {
            setOpenTab(undefined)
          }}
          openTabId={openTab}
          className='h-auto pt-6 pb-7'
          tabHeaderButtonClassName='text-small text-Gray-500 py-3'
          contentClassName='h-full pt-8 pb-1 overflow-y-auto'
          defaultTabId={1}
          tabDetails={tabDetails}
        />
      )}

      {/* add new logo section */}
      <CustomizableEditProfilePictureModal
        imageSource={clientData?.logoUrl}
        isActive={showEditLogoModal}
        onHeaderCloseButtonClick={() => {
          setShowEditLogoModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditLogoModal(false)
        }}
        onOverlayClick={() => {
          setShowEditLogoModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadLogo(files)
        }}
        primaryButtonIsLoading={createClientLogoIsLoading}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={clientData?.logoUrl ? 'Change Logo' : 'Add New Logo'}
        headerSubTitle='Update your logo here.'
      />

      {/* edit new client panel */}
      <EditClient
        isActive={isOpenEditClientDrawer}
        setIsOpenEditClientDrawer={setIsOpenEditClientDrawer}
        clientData={clientData}
        clientName={clientData?.name}
        getClientById={getClientByIdRefetch}
        notify={notify}
      />

      {/* delete client logo confirmation modal */}
      <Modal
        isActive={showDeleteLogoModal}
        onClickPrimaryBtn={() => {
          deleteClientLogo(clientData?.id)
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteLogoModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteClientLogoIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteLogoModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Logo?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

ClientProfile.defaultProps = {}

export default ClientProfile
