import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface EditBankAccountProps {
  id?: any
  bankAccountData?: any
  candidateId?: any
}

export const useEditBankAccount = () => {
  const mutate = useMutation(
    ({ bankAccountData, id, candidateId }: EditBankAccountProps) => {
      return putAPI(`/Candidate/${candidateId}/BankAccount/${id}`, {
        name: bankAccountData.name,
        accountNumber: bankAccountData.bankAccountNumber,
        bankId: bankAccountData.bankId,
        countryCode: bankAccountData.countryCode,
        bsbNumber: bankAccountData.bsbNumber,
      })
    },
  )

  return mutate
}

export default useEditBankAccount
