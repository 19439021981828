import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddPoliceCheckDetailsWithAddressesProps {
  addressList?: any
  candidateId?: any
  policeCheckDetailsData?: any
}

export const useAddPoliceCheckDetailsWithAddresses = () => {
  const mutate = useMutation(
    ({
      addressList,
      candidateId,
      policeCheckDetailsData,
    }: AddPoliceCheckDetailsWithAddressesProps) => {
      let tempAddressList = []

      tempAddressList = addressList?.map((item: any) => {
        return {
          lineOne: item?.lineOne,
          lineTwo: '',
          city: '',
          suburb: item?.suburb,
          state: item?.state,
          stateCode: item?.stateCode,
          country: item?.country,
          countryCode: item?.countryCode,
          postCode: item?.postCode ? item?.postCode : '',
          latitude: 0,
          longitude: 0,
        }
      })

      return postAPI(`/facade/Candidate/${candidateId}/PoliceCheckDetail`, {
        addresses: tempAddressList,
        state: policeCheckDetailsData?.state,
        licenseNo: policeCheckDetailsData?.licenseNo,
        passportNo: policeCheckDetailsData?.passportNo,
        passportCountryId: policeCheckDetailsData?.passportCountryId,
        visaTypeId: policeCheckDetailsData?.visaTypeId,
      })
    },
  )

  return mutate
}

export default useAddPoliceCheckDetailsWithAddresses
