import React, { FC, useState } from 'react'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useRequestReferees } from 'framework/api/methods'

import { Modal, ModalFooter } from 'components/molecules'

export interface RequestRefereeModalProps {
  isActive: boolean
  setIsActive: (value: boolean) => void
  [x: string]: any
}

export const RequestRefereeModal: FC<RequestRefereeModalProps> = ({
  isActive,
  setIsActive,
  candidateId,
  getCandidateRefereesList,
}: RequestRefereeModalProps) => {
  const [refereeCount, setRefereeCount] = useState('')

  const { mutate: requestRefereesMutate, isLoading: requestRefereesIsLoading } =
    useRequestReferees()

  /** request referees */
  async function requestReferees() {
    requestRefereesMutate(
      {
        candidateId,
        refereeCount,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle
              ? successData?.messageTitle
              : 'Referee request sent successfully',
            alertBody: '',
            status: 'Success',
          })

          getCandidateRefereesList()
          setIsActive(false)
        },

        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error requesting referees!',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      subHeaderTitle='Send form to candidate asking all the referee information'
      headerTitle='Request Referee'
      isFooterShow={true}
      size='md'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}>
      <div className='flex flex-col p-6 w-full'>
        <TextField
          helperText='Please add the no of referees you wish to request from the candidate'
          label='No of Referees Needed'
          type='number'
          value={refereeCount}
          onChange={(e: any) => {
            if (e.target.value > 0) {
              setRefereeCount(e.target.value)
            }
          }}
          placeholder='Eg: 4'
        />
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              requestReferees()
            }}
            className='mr-2'
            isDisabled={refereeCount ? false : true}
            isLoading={requestRefereesIsLoading}
            theme='cobalt'>
            Send Request
          </Button>

          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

RequestRefereeModal.defaultProps = {}

export default RequestRefereeModal
