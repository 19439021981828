/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface getCSVListInterface {
  search?: string
  status?: string
  primaryClient?: string
  suburb?: string
  state?: string
  tags?: any
}

export const useGetCSVList = () => {
  const mutate = useMutation(
    ({
      search,
      status,
      suburb,
      state,
      primaryClient,
      tags,
    }: getCSVListInterface) => {
      let tempSelectedTags: any = []

      tags?.map((item: any) => {
        tempSelectedTags.push(item.name)
      })
      return postAPI(`/Candidate/CSVList`, {
        search: search,
        status: status,
        suburb: suburb,
        state: state,
        primaryClient: primaryClient,
        tags: tempSelectedTags,
      })
    },
  )

  return mutate
}

export default useGetCSVList
