import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UseForgetPasswordInterface {
  username: string
}

export const useForgetPassword = () => {
  const mutate = useMutation(({ username }: UseForgetPasswordInterface) => {
    return postAPI('/identity/ResetToken', {
      username: username,
    })
  })

  return mutate
}

export default useForgetPassword
