import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

import { RegPackSectionTile } from 'components/atoms'

export interface ViewSectionsTabViewProps {
  [x: string]: any
}

export const ViewSectionsTabView: FC<ViewSectionsTabViewProps> = ({
  regPackSections,
  selectedMedicalHistoryTile,
  selectedQualificationTile,
  selectedRefereesTile,
  setSelectedMedicalHistoryTile,
  setSelectedQualificationTile,
  setSelectedRefereesTile,
  setShowHealthDeclarationTemplateDrawer,
  setShowNoOfRefereesDrawer,
  ...restProps
}: ViewSectionsTabViewProps) => {
  const dispatch = useDispatch()

  const { templateDetails } = useSelector((state: any) => state.regPack)

  useEffect(() => {
    templateDetails?.sectionDetails?.find((itm) => {
      if (itm?.title === 'Qualifications' && itm?.isSelected) {
        return setSelectedQualificationTile(true)
      }

      if (itm?.title === 'Medical History' && itm?.isSelected) {
        return setSelectedMedicalHistoryTile(true)
      }

      if (itm?.title === 'Referees' && itm?.isSelected) {
        return setSelectedRefereesTile(true)
      }
    })
  }, [templateDetails?.sectionDetails])

  return (
    <div className='regPack_sectionView' {...restProps}>
      <div className='flex flex-col'>
        <span className='font-Medium text-heading-4 text-Gray-800'>
          View Sections
        </span>

        <span className='font-Regular text-small text-Gray-500 pt-1'>
          The following sections will be displayed for the candidate. Grayed out
          sections are mandatory
        </span>

        <span className='font-Medium text-small text-Cobalt-600 pt-8'>
          Mandatory Sections
        </span>

        <div className='grid grid-cols-3 gap-7 mt-4'>
          {regPackSections?.length > 0 &&
            regPackSections?.map(
              (item: any, index: any) =>
                item?.isMandatory && (
                  <RegPackSectionTile
                    isMandatory
                    isSelected
                    key={index}
                    sectionHeader={item?.title}
                    sectionBody={item?.description}
                    index={`0${item?.index}.`}
                  />
                ),
            )}
        </div>

        <span className='font-Medium text-small text-Cobalt-600 pt-11'>
          Optional Sections
        </span>

        <div className='grid grid-cols-3 gap-7 mt-4 mb-6'>
          {regPackSections?.length > 0 &&
            regPackSections?.map(
              (item: any, index: any) =>
                !item?.isMandatory && (
                  <RegPackSectionTile
                    key={index}
                    sectionHeader={item?.title}
                    sectionBody={item?.description}
                    index={`0${item?.index}.`}
                    onSelectionClick={(selected) => {
                      /** handling the opening the health declaration template and no of referees drawers */
                      if (!selected) {
                        /**handling the onClick function of edit button below medical history and referees tiles */
                        if (item?.title === 'Medical History') {
                          setShowHealthDeclarationTemplateDrawer(true)
                        }

                        if (item?.title === 'Referees') {
                          setShowNoOfRefereesDrawer(true)
                        }

                        if (item?.title === 'Qualifications') {
                          dispatch(
                            setTemplateDetails({
                              ...templateDetails,
                              sectionDetails: [
                                ...templateDetails.sectionDetails,

                                {
                                  regPackSectionId: item?.id,
                                  index: item?.index,
                                  title: item?.title,
                                  description: item?.description,
                                  isMandatory: false,
                                  metaData: {},
                                  isSelected: true,
                                },
                              ],
                            }),
                          )
                        }
                      } else {
                        const newSectionDetails =
                          templateDetails?.sectionDetails?.filter(
                            (x) => x.title !== item?.title,
                          )

                        dispatch(
                          setTemplateDetails({
                            ...templateDetails,
                            sectionDetails: newSectionDetails,
                          }),
                        )
                      }

                      /** handling the selecting the Reg Pack section tile by clicking */
                      {
                        item?.title === 'Qualifications' &&
                          setSelectedQualificationTile(
                            !selectedQualificationTile,
                          )

                        item?.title === 'Medical History' &&
                          setSelectedMedicalHistoryTile(
                            !selectedMedicalHistoryTile,
                          )

                        item?.title === 'Referees' &&
                          setSelectedRefereesTile(!selectedRefereesTile)
                      }
                    }}
                    isSelected={
                      (item?.title === 'Qualifications' &&
                        selectedQualificationTile) ||
                      (item?.title === 'Medical History' &&
                        selectedMedicalHistoryTile) ||
                      (item?.title === 'Referees' && selectedRefereesTile)
                    }>
                    {item?.title === 'Medical History' && (
                      <Button
                        className='mt-2'
                        onClick={() => {
                          setShowHealthDeclarationTemplateDrawer(true)
                        }}
                        textTheme='blue'
                        theme='link'
                        iconLeft={
                          <i className='ri-edit-line text-Cobalt-500 text-[16px]' />
                        }>
                        Health Declaration
                      </Button>
                    )}

                    {item?.title === 'Referees' && (
                      <Button
                        className='mt-2'
                        onClick={() => {
                          setShowNoOfRefereesDrawer(true)
                        }}
                        textTheme='blue'
                        theme='link'
                        iconLeft={
                          <i className='ri-edit-line text-Cobalt-500 text-[16px]' />
                        }>
                        No of Referees
                      </Button>
                    )}
                  </RegPackSectionTile>
                ),
            )}
        </div>
      </div>
    </div>
  )
}

ViewSectionsTabView.defaultProps = {}

export default ViewSectionsTabView
