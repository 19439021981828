import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getClientList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `Client?Take=${reqBody.take}&Skip=${reqBody.skip}&Status=${reqBody.status}&Search=${reqBody.search}&Size=${reqBody.size}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetClientList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Clients', reqBody], getClientList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
