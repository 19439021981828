import React, { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddClientLocation,
  useAddSuburb,
  useGetStateListByCountyCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail } from 'utils'

export interface AddNewClientLocationProps {
  [x: string]: any
}

export const AddNewClientLocation: FC<AddNewClientLocationProps> = ({
  isShowAddNewClientLocationDrawer,
  setIsShowAddNewClientLocationDrawer,
  getClientLocationsById,
  getClientContactsList,
  contactItemList,
  clientId,
  countryList,
  notify,
}: AddNewClientLocationProps) => {
  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** client location details handling states */
  const [clientLocationDetails, setClientLocationDetails] = useState<any>({
    name: '',
    streetName: '',
    state: '',
    stateCode: '',
    suburb: '',
    postCode: '',
    latitude: '',
    longitude: '',
    country: 'Australia',
    countryCode: 'AU',
    primaryContactPerson: null,
    phoneNumber: '',
    email: '',
  })
  const [isErrorField, setIsErrorField] = useState({
    email: false,
  })

  /** dropdown items handling states */
  const [stateItem, setStateItem] = useState<any>()
  const [suburbItem, setSuburbItem] = useState<any>()
  const [countryItem, setCountryItem] = useState<any>({
    label: 'Australia',
    value: 'AU',
  })
  const [primaryContactItem, setPrimaryContactItem] = useState<any>()

  /** dropdown list mapping states */
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()
  const [primaryContactItemList, setPrimaryContactItemList] = useState<any>()

  /** Get client state list by country code */
  const { mutate: getStateListByCountyCodeMutate } =
    useGetStateListByCountyCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    getStateListByCountryCode(countryItem?.value)
  }, [countryItem])

  /** Backend issue: use full client contact list api for getting this primary contact person drop down. refused to give new endpoint by BE */
  useEffect(() => {
    const tempContactPersonList = contactItemList?.map((item: any) => {
      return {
        ...item,
        value: item?.clientContactId,
        label: `${item?.firstName} ${item?.lastName} `,
      }
    })
    setPrimaryContactItemList(tempContactPersonList)
  }, [contactItemList])

  /** APi call for the add new location */
  const {
    mutate: addClientLocationMutate,
    isLoading: addClientLocationIsLoading,
  } = useAddClientLocation()

  /** Process the add new client location */
  const addClientLocation = () => {
    addClientLocationMutate(
      {
        clientLocationDetails,
        clientId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'New client location successfully added',
            alertBody: '',
            status: 'Success',
          })

          setIsShowAddNewClientLocationDrawer(false)

          getClientLocationsById()
          getClientContactsList()

          resetInputFields()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onSuccess: () => {
          // notify({
          //   alertHeader: 'New suburb added',
          //   alertBody: '',
          //   status: 'Success',
          // })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation for name and account size input fields */
  const validateInputs = () => {
    const { email } = clientLocationDetails

    setIsErrorField({
      ...isErrorField,
      email: isEmail(email) || email === '' ? false : true,
    })

    const isAllValidFields = isEmail(email) || email === ''
    return isAllValidFields
  }

  /** handle save location button */
  const handleSaveLocation = () => {
    const valid = validateInputs()

    if (valid) {
      addClientLocation()
    } else {
      notify({
        alertHeader: 'Invalid input type!',
        alertBody: '',
        status: 'Warning',
      })
    }
  }

  /** reset input fields */
  const resetInputFields = () => {
    setClientLocationDetails({
      ...clientLocationDetails,
      name: '',
      streetName: '',
      state: '',
      stateCode: '',
      suburb: '',
      postCode: '',
      latitude: '',
      longitude: '',
      country: 'Australia',
      countryCode: 'AU',
      primaryContactPerson: null,
      phoneNumber: '',
      email: '',
    })
    setCountryItem({
      label: 'Australia',
      value: 'AU',
    })
    setPrimaryContactItem('')
    setStateItem('')
    setSuburbItem('')
  }

  /** handle primary button disable */
  useEffect(() => {
    const { name, streetName, suburb, state } = clientLocationDetails || {}
    ;(name && streetName && state && suburb ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [clientLocationDetails])

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(clientLocationDetails.stateCode, actionMeta?.option?.label)
    }

    setSuburbItem(newValue)

    setClientLocationDetails({
      ...clientLocationDetails,
      suburb: newValue?.label,
    })
  }
  return (
    <RightSideModal
      isActive={isShowAddNewClientLocationDrawer}
      headerTitle='Add New Client Location'
      headerSubtitle='Add new location here'
      primaryButtonTitle='Save Location'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onFooterAddButtonClick={() => {
        handleSaveLocation()
      }}
      onHeaderCloseButtonClick={() => {
        setIsShowAddNewClientLocationDrawer(false)
        resetInputFields()
      }}
      onFooterCancelButtonClick={() => {
        setIsShowAddNewClientLocationDrawer(false)
        resetInputFields()
      }}>
      {addClientLocationIsLoading && <PageLoader size='xxs' />}

      <div className='flex flex-col h-fit w-full px-6 my-6'>
        {/* location name section */}
        <TextField
          label='Name of this location'
          value={clientLocationDetails.name}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              name: e.target.value,
            })
          }}
          placeholder=''
        />

        {/** Phone number section */}
        <PhoneInputField
          label='Phone No'
          value={clientLocationDetails.phoneNumber}
          onChange={(number: any) =>
            setClientLocationDetails({
              ...clientLocationDetails,
              phoneNumber: number,
            })
          }
          className='mt-5'
        />

        {/* email section */}
        <TextField
          label='Email'
          className='mt-5'
          value={clientLocationDetails.email}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.email}
        />

        {/* street name section */}
        <TextField
          label='Street Name'
          className='mt-5'
          value={clientLocationDetails.streetName}
          onChange={(e: any) => {
            setClientLocationDetails({
              ...clientLocationDetails,
              streetName: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* state section */}
        <SimpleSelect
          label='State'
          value={stateItem}
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (clientLocationDetails?.stateCode !== selectedItem?.value) {
              setStateItem(selectedItem)
              setSuburbItem('')
              setClientLocationDetails({
                ...clientLocationDetails,
                state: selectedItem.label,
                stateCode: selectedItem.value,
                suburb: '',
              })

              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          isDisabled={!countryItem?.value}
          options={stateList}
        />

        <div className='flex w-full mt-5 gap-5'>
          {/* suburb and section */}
          <CreatableInputSelect
            label='Suburb'
            className='w-2/3'
            value={suburbItem}
            options={suburbList}
            onChange={handleSuburbChange}
            isDisabled={!clientLocationDetails.stateCode}
          />

          <TextField
            label='Post Code'
            className='w-1/3'
            value={clientLocationDetails.postCode}
            onChange={(e: any) => {
              setClientLocationDetails({
                ...clientLocationDetails,
                postCode: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>

        {/* latitude section */}
        <TextField
          label='Latitude (Optional)'
          className='mt-5'
          value={clientLocationDetails.latitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setClientLocationDetails({
                ...clientLocationDetails,
                latitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* longitude section */}
        <TextField
          label='Longitude (Optional)'
          className='mt-5'
          value={clientLocationDetails.longitude}
          onChange={(e: any) => {
            const re = /^[-+0-9.]+$/

            // if value is not blank, then test the regex
            if (e.target.value === '' || re.test(e.target.value)) {
              setClientLocationDetails({
                ...clientLocationDetails,
                longitude: e.target.value,
              })
            }
          }}
          placeholder=''
        />

        {/* country section */}
        <SimpleSelect
          label='Country'
          value={countryItem}
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (clientLocationDetails?.countryCode !== selectedItem?.value) {
              setCountryItem(selectedItem)
              setStateItem('')
              setSuburbItem('')
              setClientLocationDetails({
                ...clientLocationDetails,
                country: selectedItem.label,
                countryCode: selectedItem.value,
                state: '',
                stateCode: '',
                suburb: '',
              })
            }
          }}
          options={countryList}
        />

        {/* primary contact person section */}
        <SimpleSelect
          label='Set Primary Contact Person'
          className='mt-5'
          value={primaryContactItem}
          onChange={(selectedItem: any) => {
            setPrimaryContactItem(selectedItem)
            setClientLocationDetails({
              ...clientLocationDetails,
              primaryContactPerson: selectedItem.value,
            })
          }}
          options={primaryContactItemList}
        />
      </div>
    </RightSideModal>
  )
}

AddNewClientLocation.defaultProps = {}

export default AddNewClientLocation
