import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export const useGetDocumentInformationViewData = (
  candidateId: string,
  documentId: string,
  categoryId: string,
  onSuccess,
) => {
  return useQuery(
    ['GET_INFORMATION_VIEW'],
    async () => {
      const res = await get(
        `/facade/DocumentAudit/InformationView?CandidateId=${candidateId}&DocumentId=${documentId}&CategoryId=${categoryId}`,
      )
      return res.data.information?.InfromationView
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error getting section details',
          status: 'Error',
        })
      },
    },
  )
}
