/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { RightSideModal, TextField, Toast } from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import { useAddReferee, useEditReferee } from 'framework/api/methods'

import { CalendarInput, PageLoader, PhoneInputField } from 'components/atoms'
import { isEmail, isLetters } from 'utils'

export interface AddEditRefereeProps {
  [x: string]: any
}

export const AddEditReferee: FC<AddEditRefereeProps> = ({
  candidateId,
  drawerDetails,
  setDrawerDetails,
  getCandidateRefereesList,
  selectedRefereeDetails,
  setSelectedRefereeDetails,
  ...restProps
}: AddEditRefereeProps) => {
  /** destructure drawer details */
  const { isActive, drawerMode } = drawerDetails || {}

  const [refereeDetails, setRefereeDetails] = useState<any>({
    refId: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
    jobTitle: '',
    relationshipToCandidate: '',
    preCompanyName: '',
    preCandidatePosition: '',
    preJobStartDate: '',
    preJobFinishDate: '',
  })

  const [renderKeys, setRenderKeys] = useState<any>({
    jobStartDate: 0,
    jobFinishDate: 0,
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)

  /** set selected referee details to fields when open edit drawer */
  useEffect(() => {
    if (drawerMode === 'Edit') {
      const {
        id,
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        candidateRelation,
        candidateCompany,
        candidatePosition,
        startDate,
        endDate,
      } = selectedRefereeDetails || {}

      setRefereeDetails({
        ...refereeDetails,
        refId: id,
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        relationshipToCandidate: candidateRelation,
        preCompanyName: candidateCompany,
        preCandidatePosition: candidatePosition,
        preJobStartDate: startDate,
        preJobFinishDate: endDate,
      })
    }
  }, [selectedRefereeDetails, isActive])

  /** front end validation input fields */
  const validateInputs = () => {
    const { firstName, lastName, email } = refereeDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      email: isEmail(email) ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) && isLetters(lastName) && isEmail(email)

    return isAllValidFields
  }

  /** handle add referee */
  const onAddEditReferee = () => {
    const valid = validateInputs()

    if (valid) {
      drawerMode === 'Add' && addReferee()
      drawerMode === 'Edit' && editReferee()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** APi call for the add referee */
  const { mutate: addRefereeMutate, isLoading: addRefereeIsLoading } =
    useAddReferee()

  /** Process the add new candidate */
  const addReferee = () => {
    addRefereeMutate(
      {
        candidateId,
        refereeData: refereeDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          resetInputFields()

          getCandidateRefereesList()

          setDrawerDetails({
            ...drawerDetails,
            isActive: false,
            drawerMode: '',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit referee */
  const { mutate: editRefereeMutate, isLoading: editRefereeIsLoading } =
    useEditReferee()

  /** Process the edit existing candidate */
  const editReferee = () => {
    editRefereeMutate(
      {
        candidateId,
        refereeData: refereeDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          resetInputFields()

          getCandidateRefereesList()
          setSelectedRefereeDetails(null)

          setDrawerDetails({
            ...drawerDetails,
            isActive: false,
            drawerMode: '',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const resetInputFields = () => {
    setRefereeDetails({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      company: '',
      jobTitle: '',
      relationshipToCandidate: '',
      preCompanyName: '',
      preCandidatePosition: '',
      preJobStartDate: '',
      preJobFinishDate: '',
    })

    setRenderKeys({
      ...renderKeys,
      preJobStartDate: Math.random(),
      preJobFinishDate: Math.random(),
    })
  }

  /** handle primary button disable */
  useEffect(() => {
    const {
      firstName,
      lastName,
      phone,
      email,
      company,
      jobTitle,
      relationshipToCandidate,
      preCompanyName,
      preCandidatePosition,
      preJobStartDate,
      preJobFinishDate,
    } = refereeDetails || {}
    firstName &&
    lastName &&
    phone &&
    email &&
    company &&
    jobTitle &&
    relationshipToCandidate &&
    preCompanyName &&
    preCandidatePosition &&
    preJobStartDate &&
    preJobFinishDate
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [refereeDetails])

  return (
    <RightSideModal
      isActive={isActive}
      className='w-full'
      headerTitle={drawerMode === 'Add' ? `Add Referee` : `Referee Details`}
      headerSubtitle={
        drawerMode === 'Add' ? `Add new Reference` : `Edit Referee Details`
      }
      primaryButtonTitle={
        drawerMode === 'Add' ? `Add Referee` : `Update Referee`
      }
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        resetInputFields()
        setDrawerDetails({
          ...drawerDetails,
          isActive: false,
          drawerMode: '',
        })
      }}
      onFooterAddButtonClick={() => {
        onAddEditReferee()
      }}
      onFooterCancelButtonClick={() => {
        resetInputFields()
        setDrawerDetails({
          ...drawerDetails,
          isActive: false,
          drawerMode: '',
        })
      }}
      {...restProps}>
      {/** drawer loader */}
      {(addRefereeIsLoading || editRefereeIsLoading) && (
        <PageLoader size='xxs' />
      )}

      {/** Body section of the drawer modal */}
      <div className='w-full h-fit py-5 px-6'>
        {/* referee details section ------------------------------------------------------------------------------*/}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2'>
          Referee details
        </div>

        {/* first name section */}
        <TextField
          label='First Name'
          className='mt-5'
          value={refereeDetails?.firstName}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              firstName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.firstName}
        />

        {/* last name section */}
        <TextField
          label='Last Name'
          className='mt-5'
          value={refereeDetails?.lastName}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              lastName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.lastName}
        />

        {/** Phone number section */}
        <PhoneInputField
          label='Phone'
          value={refereeDetails?.phone}
          onChange={(number: any) =>
            setRefereeDetails({
              ...refereeDetails,
              phone: number,
            })
          }
          className='mt-5'
        />

        {/* email section */}
        <TextField
          label='Email'
          className='mt-5'
          value={refereeDetails?.email}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField?.email}
        />

        {/* company section */}
        <TextField
          label='Company'
          className='mt-5'
          value={refereeDetails?.company}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              company: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* job title section */}
        <TextField
          label='Job Title'
          className='mt-5'
          value={refereeDetails?.jobTitle}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              jobTitle: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* relationship to candidate section */}
        <TextField
          label='Relationship to Candidate'
          className='mt-5'
          value={refereeDetails?.relationshipToCandidate}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              relationshipToCandidate: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* Candidate role details of the previous company section ------------------------------------------------------------------------------*/}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-10'>
          Candidate role details of the previous company
        </div>

        {/* company name section */}
        <TextField
          label='Company Name'
          className='mt-5'
          value={refereeDetails?.preCompanyName}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              preCompanyName: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* company position section */}
        <TextField
          label='Company Position'
          className='mt-5'
          value={refereeDetails?.preCandidatePosition}
          onChange={(e: any) => {
            setRefereeDetails({
              ...refereeDetails,
              preCandidatePosition: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* Job Start Date section */}
        <div className='flex flex-col pt-5'>
          <span className='text-small text-Gray-800 font-Medium'>
            Job Start Date
          </span>

          <CalendarInput
            key={renderKeys?.preJobStartDate}
            value={refereeDetails?.preJobStartDate}
            formatDate={(date) => format(date, 'dd/MM/yyyy')}
            parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
            className='mt-1'
            disableFutureDates={true}
            onChange={(date) => {
              if (date) {
                setRefereeDetails({
                  ...refereeDetails,
                  preJobStartDate: date,
                })
              }
            }}
          />
        </div>

        {/* Job Finish Date section */}
        <div className='flex flex-col pt-5'>
          <span className='text-small text-Gray-800 font-Medium'>
            Job Finish Date
          </span>

          <CalendarInput
            key={renderKeys?.preJobFinishDate}
            value={refereeDetails?.preJobFinishDate}
            formatDate={(date) => format(date, 'dd/MM/yyyy')}
            parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
            className='mt-1'
            onChange={(date) => {
              if (date) {
                setRefereeDetails({
                  ...refereeDetails,
                  preJobFinishDate: date,
                })
              }
            }}
          />
        </div>
      </div>
    </RightSideModal>
  )
}

AddEditReferee.defaultProps = {}

export default AddEditReferee
