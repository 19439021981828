import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Avatar, Button, Modal, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { motion } from 'framer-motion'
import {
  useGetHealthDeclarationList,
  useGetQuestionnairesList,
  useGetRegPackSections,
  useGetRegPackTemplateList,
  useSendRegPack,
} from 'framework/api/methods'
import {
  setOriginalTemplateDetails,
  setTemplateDetails,
} from 'store/reducers/regPack/regPackSlice'

import { VerticalTab } from 'components/atoms'
import {
  AddRequiredDocument,
  RegPackSaveAsNewTemplate,
} from 'components/Drawers/Candidate'
import {
  HealthDeclarationTemplateDrawer,
  NoOfRefereesDrawer,
} from 'components/Drawers/RegPack'

import {
  CustomQuestionnaireTabView,
  RequestRequiredDocuments,
  RequiredDocumentType,
  SelectRegPackTemplateTabView,
  ViewSectionsTabView,
} from './registrationPackTabs'

export interface RegistrationPackModalProps {
  className?: string | undefined
  isActive?: boolean | undefined
  isCustomStyles?: boolean | undefined
  onHeaderCloseButtonClick?: () => void
  [x: string]: any
}

export const RegistrationPackModal: FC<RegistrationPackModalProps> = ({
  candidateDetails,
  className,
  isActive,
  isCustomStyles,
  onHeaderCloseButtonClick,
  setIsOpenRegPackModal,
  getCandidateDetailsById,
}: RegistrationPackModalProps) => {
  const [isSaveAsNewTemplateBtnDisabled, setIsSaveAsNewTemplateBtnDisabled] =
    useState(false)

  const [tabId, setTabId] = useState(1)

  /** selected health declaration template state */
  const [
    selectedHealthDeclarationTemplate,
    setSelectedHealthDeclarationTemplate,
  ] = useState({
    label: '',
    value: '',
  })

  /** optional section selection states */
  const [selectedMedicalHistoryTile, setSelectedMedicalHistoryTile] =
    useState(false)

  const [selectedQualificationTile, setSelectedQualificationTile] =
    useState(false)

  const [selectedRefereesTile, setSelectedRefereesTile] = useState(false)

  /** custom questionnaire tab states  */
  const [qTemplateList, setQTemplateList] = useState<Array<any>>([])
  const [selectedQTemplate, setSelectedQTemplate] = useState<any>()
  const [isCustomQuestionnaireEnable, setIsCustomQuestionnaireEnable] =
    useState(false)

  const [isSendTemplateBtnDisabled, setIsSendTemplateBtnDisabled] =
    useState(false)

  const [isShowSendRegButtonModal, setIsShowSendRegButtonModal] =
    useState(false)

  const [templateList, setTemplateList] = useState([
    {
      value: '',
      label: '',
    },
  ])
  const [document, setDocument] = useState<RequiredDocumentType>()
  const [documentIndex, setDocumentIndex] = useState<number>()
  const [templateId, setTemplateId] = useState('')

  const [healthDeclarationTemplateList, setHealthDeclarationTemplateList] =
    useState([
      {
        value: '',
        label: '',
      },
    ])

  const [selectedTemplate, SetSelectedTemplate] = useState({
    label: '',
    value: '',
  })

  const [refereeCount, setRefereeCount] = useState('')

  const notify = (props: any) => Toast(props)

  const dispatch = useDispatch()

  /** regPack sections storing states */
  const [regPackSections, setRegPackSections] = useState([])

  /** set default tab id to vertical tab section*/
  useEffect(() => {
    if (isActive) {
      setTabId(1)
    }
  }, [isActive])

  /** Get reg pack sections on success */
  const getRegPackSectionsOnSuccess = ({ data: successData }: any) => {
    setRegPackSections(successData?.regPackSections)
  }

  /** Get reg pack sections on error */
  const getRegPackSectionsOnError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API call for getting reg pack sections */
  const { refetch: getRegPackSectionsRefetch } = useGetRegPackSections(
    {},
    getRegPackSectionsOnSuccess,
    getRegPackSectionsOnError,
  )

  useEffect(() => {
    getRegPackSectionsRefetch()

    {
      regPackSections?.length > 0 &&
        regPackSections?.map((item: any) =>
          item?.isMandatory
            ? dispatch(
                setTemplateDetails({
                  ...templateDetails,
                  sectionDetails: [
                    ...templateDetails.sectionDetails,

                    {
                      regPackSectionId: item?.id,
                      title: item?.title,
                    },
                  ],
                }),
              )
            : setTemplateDetails({
                ...templateDetails,
                sectionDetails: [
                  ...templateDetails.sectionDetails,
                  {
                    regPackSectionId: item?.id,
                    title: item?.title,
                    isSelected: true,
                  },
                ],
              }),
        )
    }
  }, [])

  /** Health declaration template drawer showing states*/
  const [
    showHealthDeclarationTemplateDrawer,
    setShowHealthDeclarationTemplateDrawer,
  ] = useState(false)

  /** No of referees drawer showing states*/
  const [showNoOfRefereesDrawer, setShowNoOfRefereesDrawer] = useState(false)

  const { originalTemplateDetails, templateDetails } = useSelector(
    (state: any) => state.regPack,
  )

  /** Get health declaration template on success */
  const getHealthDeclarationTemplateOnSuccess = (data: any) => {
    const tempList = data?.questionnaires?.map((item: any) => {
      return {
        value: item?.id,
        label: item.name,
      }
    })
    setHealthDeclarationTemplateList(tempList)
  }

  /** Get health declaration template on error */
  const getHealthDeclarationTemplateOnError = ({
    response: { data: errData },
  }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API call for getting health declaration templates */
  const { refetch: getHealthDeclarationTemplatesRefetch } =
    useGetHealthDeclarationList(
      { category: 'HEALTH_DECLARATION', isActive: true },
      getHealthDeclarationTemplateOnSuccess,
      getHealthDeclarationTemplateOnError,
    )

  useEffect(() => {
    isActive && getRegPackList()
  }, [isActive])

  /** handle save as new template button disable state */

  useEffect(() => {
    if (originalTemplateDetails && templateDetails) {
      const updatedOriginalTemplateDetails = { ...originalTemplateDetails }
      const inner = originalTemplateDetails?.sectionDetails

      const tempObj = inner.map((item) => {
        return {
          regPackSectionId: item?.regPackSectionId,
          index: item?.index,
          title: item?.title,
          description: item?.description,
          isMandatory: item?.isMandatory,
          metaData: item?.metaData,
          isSelected: true,
        }
      })

      updatedOriginalTemplateDetails.sectionDetails = tempObj

      const isTemplateDetailsChanged =
        JSON.stringify(templateDetails?.sectionDetails).includes(
          JSON.stringify(updatedOriginalTemplateDetails?.sectionDetails),
        ) &&
        JSON.stringify(templateDetails?.reqDocuments).includes(
          JSON.stringify(updatedOriginalTemplateDetails?.reqDocuments),
        ) &&
        templateDetails?.customQuestionnaireId ===
          updatedOriginalTemplateDetails?.customQuestionnaireId

      if (isTemplateDetailsChanged) {
        setIsSaveAsNewTemplateBtnDisabled(true)
        setIsSendTemplateBtnDisabled(false)
        setTemplateId(originalTemplateDetails?.regPackTemplateId)
      } else {
        setTemplateId('')
        setIsSaveAsNewTemplateBtnDisabled(false)
        setIsSendTemplateBtnDisabled(true)
      }
    } else {
      setIsSaveAsNewTemplateBtnDisabled(true)
      setIsSendTemplateBtnDisabled(true)
    }
  }, [originalTemplateDetails, templateDetails, isActive])

  useEffect(() => {
    if (templateId) {
      setIsSaveAsNewTemplateBtnDisabled(true)
      setIsSendTemplateBtnDisabled(false)
    }
  }, [templateId])

  /** get reg pack list on success */
  const onSuccessGetRegPackList = (data) => {
    const tempList = data?.regPackTemplate?.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
    setTemplateList(tempList)
  }

  /**Fetch reg pack list */
  const { refetch: getRegPackList } = useGetRegPackTemplateList(
    onSuccessGetRegPackList,
  )

  /** save as new template drawer states */
  const [isSaveAsTemplateDrawerOpen, setIsSaveAsTemplateDrawerOpen] =
    useState(false)
  const [isOpenAddDocumentDrawer, setIsOpenAddDocumentDrawer] = useState(false)

  /** modal overlay style */
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-20',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  useEffect(() => {
    showHealthDeclarationTemplateDrawer &&
      getHealthDeclarationTemplatesRefetch()
  }, [showHealthDeclarationTemplateDrawer])

  /** APi call for send reg pack */
  const { mutate: sendRegPackMutate, isLoading: sendRegPackIsLoading } =
    useSendRegPack()

  /** Process the send reg pack */
  const sendRegPack = () => {
    sendRegPackMutate(
      { candidateId: candidateDetails?.id, templateId },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            status: 'Success',
          })
          setIsShowSendRegButtonModal(false)
          setIsOpenRegPackModal(false)
          getCandidateDetailsById(candidateDetails?.id)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error Sending Template.',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    setRefereeCount('2')
  }, [])

  useEffect(() => {
    healthDeclarationTemplateList?.find((item) => {
      if (item?.label === 'Health Declaration - Blue Collar') {
        setSelectedHealthDeclarationTemplate({
          ...selectedHealthDeclarationTemplate,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [healthDeclarationTemplateList])

  useEffect(() => {
    templateList?.find((item) => {
      if (item?.label === 'Standard') {
        SetSelectedTemplate({
          ...selectedTemplate,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [templateList])

  /** questionnaires List on success */
  const getQuestionnairesListOnSuccess = (data: any) => {
    const tempQuestionnairesList = data?.questionnaires?.map((item: any) => {
      return {
        ...item,
        value: item.code,
        label: item.name,
      }
    })
    setQTemplateList(tempQuestionnairesList)
  }

  /** questionnaires List on error */
  const getQuestionnairesListOnError = ({
    response: { data: errData },
  }: any) => {
    Toast({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get web ref check questionnaires List */
  const {
    refetch: getQuestionnairesList,
    isLoading: getQuestionnairesListIsLoading,
  } = useGetQuestionnairesList(
    {
      take: '',
      skip: '',
      status: true,
      search: '',
      category: 'REG_PACK',
    },
    getQuestionnairesListOnSuccess,
    getQuestionnairesListOnError,
  )

  useEffect(() => {
    if (
      templateDetails?.regPackTemplateId !== selectedTemplate?.value ||
      isActive
    ) {
      getQuestionnairesList()
      setIsCustomQuestionnaireEnable(false)
      setSelectedQTemplate(null)
    }
  }, [selectedTemplate?.value, isActive])

  /** reset data when modal close */
  const resetData = () => {
    SetSelectedTemplate({
      ...selectedTemplate,
      label: '',
      value: '',
    })

    dispatch(setTemplateDetails(null))
    dispatch(setOriginalTemplateDetails(null))

    /** clear tiles when  closing modal */
    setSelectedQualificationTile(false)
    setSelectedMedicalHistoryTile(false)
    setSelectedRefereesTile(false)
  }

  return (
    <>
      <div className={OverlayClassName}>
        {/* Modal main container  */}
        <motion.div
          animate={isActive ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
            closed: { opacity: 1, y: '-100%' },
          }}
          className='modal-container w-[96vw] h-[98vh] flex flex-col justify-between items-center bg-white shadow-lg rounded-[2px]'>
          {/* Modal Header  */}
          <div className='modal-Header flex w-full items-center justify-between pl-6 py-3 pr-2 border-b-[1px] border-Gray-200'>
            <div className='header-title flex justify-center items-center'>
              <span className='text-[18px] font-Medium text-Gray-700'>
                Send Registration Pack
              </span>

              {/* warning and tooltip */}
              <i
                className='ri-error-warning-line text-[16px] text-Cobalt-600 ml-3'
                data-tip='reg-pack-tips'
                data-for='reg-pack-tips'
              />

              <ReactTooltip
                id='reg-pack-tips'
                place='right'
                effect='solid'
                textColor='#1F2937'
                backgroundColor='#FFFFFF'
                border={true}
                borderColor='rgb(0,0,0,0.1)'>
                <div className='flex flex-col w-[450px]'>
                  <div className='flex justify-start items-center'>
                    <i className='ri-error-warning-line text-[20px] text-Gray-800 mr-3' />

                    <span className='font-Medium text-small text-Gray-800'>
                      What can you do before you send the reg pack?
                    </span>
                  </div>

                  <ul className='list-disc mt-3 ml-4'>
                    <li>
                      Here you can customize the registration pack content as
                      you see fit.
                    </li>

                    <li>
                      Also you can select a pre-defined reg pack template or you
                      can save this as a new template if you need to use it in
                      the future.
                    </li>
                  </ul>
                </div>
              </ReactTooltip>
            </div>

            <Button
              icon={<i className='ri-close-line text-heading-4' />}
              isIconOnly
              onClick={(e: any) => {
                e.stopPropagation()
                e.preventDefault()
                resetData()
                onHeaderCloseButtonClick && onHeaderCloseButtonClick()
              }}
              textTheme='black'
              theme='link'
            />
          </div>

          {/* modal body */}
          <div className='flex flex-col w-full px-6 py-5 h-full overflow-y-auto styled-scroll'>
            {/* top section */}
            <div className='flex w-full justify-between items-center'>
              <Avatar
                title={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
                tag={
                  candidateDetails?.email ? candidateDetails?.email : undefined
                }
                imageURL={
                  candidateDetails?.avatarUrl
                    ? candidateDetails?.avatarUrl
                    : undefined
                }
                size='sm'
              />

              <div className='flex gap-x-3'>
                <Button
                  className='save-as-new-template-btn'
                  onClick={() => {
                    setIsSaveAsTemplateDrawerOpen(true)
                  }}
                  textTheme='blue'
                  isDisabled={isSaveAsNewTemplateBtnDisabled}
                  theme='white'>
                  Save as New Template
                </Button>

                <Button
                  className='send-reg-pack-btn'
                  onClick={() => {
                    setIsShowSendRegButtonModal(true)
                  }}
                  textTheme='white'
                  isDisabled={isSendTemplateBtnDisabled}
                  theme='cobalt'>
                  Send Reg Pack
                </Button>
              </div>
            </div>

            {/* tab content section */}
            <VerticalTab
              className='h-full w-full mt-5'
              contentClassName='border rounded-lg border-Gray-200 h-[68vh]'
              isActive={isActive}
              defaultTabId={tabId}
              isContentFooterNxtPrevBtnEnabled={true}
              tabDetails={[
                {
                  id: 1,
                  tabButtonContent: 'Select Reg Pack Template',
                  tabContent: (
                    <div className='pl-5 pt-5'>
                      <SelectRegPackTemplateTabView
                        templateList={templateList}
                        selectedTemplate={selectedTemplate}
                        SetSelectedTemplate={SetSelectedTemplate}
                        templateDetails={templateDetails}
                        setTemplateId={setTemplateId}
                        setSelectedQualificationTile={
                          setSelectedQualificationTile
                        }
                        setSelectedMedicalHistoryTile={
                          setSelectedMedicalHistoryTile
                        }
                        setSelectedRefereesTile={setSelectedRefereesTile}
                      />
                    </div>
                  ),
                },
                {
                  id: 2,
                  tabButtonContent: 'View Sections',
                  tabContent: (
                    <div className='pl-5 pt-5'>
                      <ViewSectionsTabView
                        setShowHealthDeclarationTemplateDrawer={
                          setShowHealthDeclarationTemplateDrawer
                        }
                        setShowNoOfRefereesDrawer={setShowNoOfRefereesDrawer}
                        healthDeclarationTemplateList={
                          healthDeclarationTemplateList
                        }
                        refereeCount={refereeCount}
                        regPackSections={regPackSections}
                        setRegPackSections={setRegPackSections}
                        selectedHealthDeclarationTemplate={
                          selectedHealthDeclarationTemplate
                        }
                        setSelectedQualificationTile={
                          setSelectedQualificationTile
                        }
                        setSelectedMedicalHistoryTile={
                          setSelectedMedicalHistoryTile
                        }
                        setSelectedRefereesTile={setSelectedRefereesTile}
                        selectedMedicalHistoryTile={selectedMedicalHistoryTile}
                        selectedQualificationTile={selectedQualificationTile}
                        selectedRefereesTile={selectedRefereesTile}
                      />
                    </div>
                  ),
                },
                {
                  id: 3,
                  tabButtonContent: 'Request Required Documents',
                  tabContent: (
                    <RequestRequiredDocuments
                      openDrawer={() => {
                        setIsOpenAddDocumentDrawer(true)
                      }}
                      openEditView={(
                        documentData: RequiredDocumentType,
                        index?: number,
                      ) => {
                        if (documentData) {
                          setDocument(documentData)
                        }
                        if (index !== undefined) {
                          setDocumentIndex(index)
                        }
                      }}
                    />
                  ),
                },
                {
                  id: 4,
                  tabButtonContent: 'Custom Questionnaire',
                  tabContent: (
                    <CustomQuestionnaireTabView
                      getQuestionnairesListIsLoading={
                        getQuestionnairesListIsLoading
                      }
                      qTemplateList={qTemplateList}
                      selectedQTemplate={selectedQTemplate}
                      setSelectedQTemplate={setSelectedQTemplate}
                      isCustomQuestionnaireEnable={isCustomQuestionnaireEnable}
                      setIsCustomQuestionnaireEnable={
                        setIsCustomQuestionnaireEnable
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        </motion.div>
      </div>

      {/* save as new template modal */}
      <RegPackSaveAsNewTemplate
        isActive={isSaveAsTemplateDrawerOpen}
        setIsSaveAsTemplateDrawerOpen={setIsSaveAsTemplateDrawerOpen}
        setTemplateId={setTemplateId}
        getRegPackList={getRegPackList}
      />

      <HealthDeclarationTemplateDrawer
        showHealthDeclarationTemplateDrawer={
          showHealthDeclarationTemplateDrawer
        }
        headerTitle='Health Declaration Template'
        headerSubtitle='Select preferred health declaration template'
        headerCloseButton={() => {
          setShowHealthDeclarationTemplateDrawer(false)
        }}
        isFooterShow={false}
        healthDeclarationTemplateList={healthDeclarationTemplateList}
        selectedHealthDeclarationTemplate={selectedHealthDeclarationTemplate}
        setSelectedHealthDeclarationTemplate={
          setSelectedHealthDeclarationTemplate
        }
        regPackSections={regPackSections}
        selectedMedicalHistoryTile={selectedMedicalHistoryTile}
        setSelectedMedicalHistoryTile={setSelectedMedicalHistoryTile}
      />

      <NoOfRefereesDrawer
        showNoOfRefereesDrawer={showNoOfRefereesDrawer}
        headerTitle='No of Referees'
        headerSubtitle='Change no of referees'
        headerCloseButton={() => {
          setShowNoOfRefereesDrawer(false)
        }}
        isFooterShow={false}
        refereeCount={refereeCount}
        setRefereeCount={setRefereeCount}
        regPackSections={regPackSections}
        selectedRefereesTile={selectedRefereesTile}
        setSelectedRefereesTile={setSelectedRefereesTile}
      />

      {/* Add Required documents modal */}
      <AddRequiredDocument
        isActive={isOpenAddDocumentDrawer}
        documentData={document}
        hide={() => {
          setIsOpenAddDocumentDrawer(false)
        }}
        documentIndex={documentIndex}
        resetDocumentIndex={() => setDocumentIndex(undefined)}
      />

      {/** send reg pack button confirm Modal */}
      <Modal
        isActive={isShowSendRegButtonModal}
        onClickPrimaryBtn={() => {
          sendRegPack()
        }}
        onClickSecondaryBtn={() => {
          setIsShowSendRegButtonModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: sendRegPackIsLoading,
          theme: 'cobalt',
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowSendRegButtonModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Confirm & Send'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Cobalt-50'>
            <i className='ri-error-warning-line text-Cobalt-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Send Registration Invite
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Please make sure everything is in order before sending.
          </span>
        </div>
      </Modal>
    </>
  )
}

RegistrationPackModal.defaultProps = {
  isCustomStyles: false,
}

export default RegistrationPackModal
