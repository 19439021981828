import { FC } from 'react'
import { Badge, Button } from '@labourhub/labour-hub-ds'

import { PageLoader } from 'components/atoms'

export interface CandidateTagCardProps {
  onEditClick?: () => void
  [x: string]: any
}

export const CandidateTagCard: FC<CandidateTagCardProps> = ({
  candidateTagList,
  onEditClick,
  isLoading,
  ...restProps
}: CandidateTagCardProps) => {
  return (
    <div
      className='flex flex-col w-1/4 bg-white rounded-md border-[1px] border-Gray-200 min-h-[300px] relative'
      {...restProps}>
      {isLoading && <PageLoader size='xxs' />}

      <div className='flex justify-between pt-4 pl-5 pr-4'>
        <span className='font-Bold text-heading-5 text-Gray-800'>Tags</span>

        <Button onClick={onEditClick} textTheme='blue' size='sm' theme='white'>
          {candidateTagList?.length > 0 ? `Edit` : `Add`}
        </Button>
      </div>

      <div className='flex flex-wrap gap-4 my-5 mx-5 max-h-[350px] overflow-y-auto styled-scroll'>
        {/* tags card mapping */}
        {candidateTagList?.length > 0 &&
          candidateTagList?.map((item: any, idx: any) => (
            <Badge
              size='large'
              type='rounded'
              key={idx}
              className='h-[32px] max-w-full'
              childrenClassName='max-w-full  break-words line-clamp-1'>
              {item}
            </Badge>
          ))}

        {/* not tags view */}
        {candidateTagList?.length <= 0 && (
          <div className='w-full flex flex-col justify-center items-center my-5'>
            <i className='ri-price-tag-3-line text-heading-2 text-Gray-500' />

            <span className='text-heading-4 font-Medium text-Gray-800 mt-2'>
              No Tags Found
            </span>

            <span className='text-small font-Regular text-Gray-600 text-center pt-2'>
              Tags help to filter out candidates.
            </span>

            <Button
              iconLeft={<i className='ri-add-line' />}
              onClick={onEditClick}
              className='mt-6'
              textTheme='white'
              size='sm'
              theme='cobalt'>
              Add Tags
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

CandidateTagCard.defaultProps = {
  onEditClick: undefined,
}

export default CandidateTagCard
