import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCandidateMedicalIncidentListProps {
  Id?: any
}

export const useGetCandidateMedicalIncidentListById = () => {
  const mutate = useMutation(({ Id }: GetCandidateMedicalIncidentListProps) => {
    return get(`/facade/Candidate/${Id}/Incident`, {})
  })

  return mutate
}

export default useGetCandidateMedicalIncidentListById
