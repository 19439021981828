import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { documentCategoryName } from 'static-data/candidates/documentState'

import {
  EditEducationalQualificationDocs,
  EditEmploymentContractDoc,
  EditLicenseCertificateDocs,
  EditMedicalIncidentDocs,
  EditOtherDocs,
  EditPoliceCheckDocs,
  EditPrescriptionAndMedicalConditionDocs,
  EditSuperFundDocs,
  EditVaccinationDocs,
  EditVisaDocs,
} from './categoryEditDrawers'
import {
  EditFinancialInformation,
  EditPersonalInformation,
} from './personalTabEditDrawers'

export interface AuditEditDrawersProps {
  [x: string]: any
}

export const AuditEditDrawers: FC<AuditEditDrawersProps> = ({
  getAuditCheckDocumentList,
  ...restProps
}: AuditEditDrawersProps) => {
  const { editDrawer } = useSelector((state: any) => state.audit)

  const {
    CertificationsAndLicense,
    Education,
    Other,
    Vaccinations,
    MedicalIncidents,
    PrescriptionsAndMedicalConditions,
    SuperFund,
    Visa,
    PoliceCheck,
    Contract,
  } = documentCategoryName || {}

  return (
    <>
      {editDrawer?.mode === CertificationsAndLicense && (
        <EditLicenseCertificateDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === Education && (
        <EditEducationalQualificationDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === Vaccinations && (
        <EditVaccinationDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === MedicalIncidents && (
        <EditMedicalIncidentDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === PrescriptionsAndMedicalConditions && (
        <EditPrescriptionAndMedicalConditionDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === SuperFund && (
        <EditSuperFundDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === Visa && (
        <EditVisaDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === Other && (
        <EditOtherDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === PoliceCheck && (
        <EditPoliceCheckDocs
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === 'candidate personal' && (
        <EditPersonalInformation
          isActive={editDrawer?.isOpen}
          getAuditCheckDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === 'candidate financial' && (
        <EditFinancialInformation
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}

      {editDrawer?.mode === Contract && (
        <EditEmploymentContractDoc
          isActive={editDrawer?.isOpen}
          fetchDocumentList={getAuditCheckDocumentList}
        />
      )}
    </>
  )
}

AuditEditDrawers.defaultProps = {
  isActive: false,
}

export default AuditEditDrawers
