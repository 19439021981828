import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAuditFailedList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/DocumentAudit/AuditFail?Take=${reqBody.take}&Skip=${reqBody.skip}&Category=${reqBody.documentCategory}&Search=${reqBody.search}&Type=${reqBody.documentType}&AuditedBy=${reqBody.auditedPerson}&SubmittedFrom=${reqBody.submittedFromDate}&SubmittedTo=${reqBody.submittedToDate}&AuditFailedFrom=${reqBody.auditFailedFromDate}&AuditFailedTo=${reqBody.auditFailedToDate}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetAuditFailedList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['AuditFailed', reqBody], getAuditFailedList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
