/** client list status code */
export const Active = 'Active'
export const Disable = 'Disable'

/** backend issue (variable name) :  true false values send through api for get filter result from status filtering.
 *  because of that code list value and label are different */
export const statusCodeList = [
  {
    value: 'true',
    label: 'Active',
  },
  {
    value: 'false',
    label: 'Disable',
  },
]
