import React, { FC } from 'react'
import CN from 'classnames'

export interface RefereeStatusBadgeProps {
  refereeStatus?: string | null
  [x: string]: any
}

export const RefereeStatusBadge: FC<RefereeStatusBadgeProps> = ({
  refereeStatus,
  ...restProps
}: RefereeStatusBadgeProps) => {
  return (
    <div className='referee-status-badge' {...restProps}>
      <span
        className={CN(
          'flex items-center justify-center gap-[6px] h-[40px] rounded-lg border-[1px] py-[2px] pr-4 pl-2 ml-4',
          {
            'bg-Yellow-50 border-Yellow-600 text-Yellow-600':
              refereeStatus === 'Incomplete',
            'bg-Red-50 border-Red-600 text-Red-600': refereeStatus === 'Failed',
            'bg-Gray-50 border-Gray-600 text-Gray-600':
              refereeStatus === 'NotSent',
            'bg-Green-50 border-Green-500 text-Green-500':
              refereeStatus === 'Passed',
          },
        )}>
        <i
          className={CN('text-small', {
            'ri-indeterminate-circle-line': refereeStatus === 'Incomplete',
            'ri-close-circle-line': refereeStatus === 'Failed',
            'ri-mail-close-line': refereeStatus === 'NotSent',
            'ri-checkbox-circle-line': refereeStatus === 'Passed',
          })}
        />
        <span className='text-small font-SemiBold'>
          {refereeStatus === 'NotSent' ? 'Not Sent' : refereeStatus}
        </span>
      </span>
    </div>
  )
}

RefereeStatusBadge.defaultProps = {}

export default RefereeStatusBadge
