import React, { FC, useEffect, useState } from 'react'
import { Button, Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useDeleteClientLocation,
  useGetCountryList,
} from 'framework/api/methods'

import {
  AddNewClientLocation,
  EditClientLocation,
} from 'components/Drawers/client'
import { ClientProfileLocationCard } from 'components/molecules/ClientProfileLocationCard'

export interface LocationTabViewProps {
  [x: string]: any
}

export const LocationTabView: FC<LocationTabViewProps> = ({
  className,
  clientLocations,
  contactItemList,
  getClientLocationsById,
  getClientContactsList,
  clientId,
  setOpenTab,
  notify,
  ...restProps
}: LocationTabViewProps) => {
  const [
    isShowAddNewClientLocationDrawer,
    setIsShowAddNewClientLocationDrawer,
  ] = useState(false)

  const [selectedLocationItem, setSelectedLocationItem] = useState<any>()

  const [isShowEditClientLocationDrawer, setIsShowEditClientLocationDrawer] =
    useState(false)

  const [showDeleteClientLocationModal, setShowDeleteClientLocationModal] =
    useState(false)

  const [countryList, setCountryItemList] = useState<any>()

  /** Get client country list */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
  }, [])

  /** APi call for the delete location */
  const {
    mutate: deleteClientLocationMutate,
    isLoading: deleteClientLocationIsLoading,
  } = useDeleteClientLocation()

  /** Process the delete client location */
  const deleteClientLocation = () => {
    deleteClientLocationMutate(
      {
        clientId,
        LocationId: selectedLocationItem?.locationId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Client location successfully deleted',
            alertBody: '',
            status: 'Success',
          })
          !deleteClientLocationIsLoading &&
            setIsShowEditClientLocationDrawer(false)

          getClientLocationsById()
          getClientContactsList()
          setShowDeleteClientLocationModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowDeleteClientLocationModal(false)
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle delete location button */
  const handleDeleteLocation = () => {
    deleteClientLocation()
  }

  /** location tab styles */
  const LocationTabViewClasses = CN(
    `location-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={LocationTabViewClasses} {...restProps}>
      {/* location tab header */}
      <div className='w-full flex justify-between items-center'>
        <div className='flex flex-col'>
          <span className='text-heading-5 text-Gray-800 font-Medium'>
            Client Locations
          </span>

          {/* location tab sub header */}
          <span className='text-small text-Gray-500 font-Regular'>
            Find all the client locations
          </span>
        </div>

        <Button
          iconLeft={<i className='ri-add-line' />}
          onClick={() => {
            setIsShowAddNewClientLocationDrawer(true)
          }}
          theme='gradient'>
          Add New Location
        </Button>
      </div>

      <div className='mt-8 grid grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-[30px]'>
        {clientLocations?.map((clientLocationsItem: any, idx: any) => (
          <ClientProfileLocationCard
            key={idx}
            clientLocationsItem={clientLocationsItem}
            notify={notify}
            onEditLocationCLick={() => {
              setSelectedLocationItem(clientLocationsItem)
              setIsShowEditClientLocationDrawer(true)
            }}
            setOpenTab={setOpenTab}
          />
        ))}

        {clientLocations?.length <= 0 && <>No locations found.</>}
      </div>

      <AddNewClientLocation
        isShowAddNewClientLocationDrawer={isShowAddNewClientLocationDrawer}
        setIsShowAddNewClientLocationDrawer={
          setIsShowAddNewClientLocationDrawer
        }
        getClientContactsList={getClientContactsList}
        countryList={countryList}
        contactItemList={contactItemList}
        clientId={clientId}
        notify={notify}
        getClientLocationsById={getClientLocationsById}
      />

      <EditClientLocation
        isShowEditClientLocationDrawer={isShowEditClientLocationDrawer}
        setIsShowEditClientLocationDrawer={setIsShowEditClientLocationDrawer}
        setShowDeleteClientLocationModal={setShowDeleteClientLocationModal}
        contactItemList={contactItemList}
        clientId={clientId}
        countryList={countryList}
        selectedLocationItem={selectedLocationItem}
        notify={notify}
        getClientLocationsById={getClientLocationsById}
        getClientContactsList={getClientContactsList}
      />

      {/* delete client location confirmation modal */}
      <Modal
        isActive={showDeleteClientLocationModal}
        onClickPrimaryBtn={() => {
          handleDeleteLocation()
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteClientLocationModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteClientLocationIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteClientLocationModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Location?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

LocationTabView.defaultProps = {}

export default LocationTabView
