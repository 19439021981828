import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateNewRegPackTemplateProps {
  name: string
  templateDetails: TemplateDetailsProps
}
interface TemplateDetailsProps {
  sectionDetails?: object[]
  reqDocuments?: object[]
  customQuestionnaireId?: string | null
}

export const useCreateNewRegPackTemplate = () => {
  const mutate = useMutation(
    ({ name, templateDetails }: CreateNewRegPackTemplateProps) => {
      const { sectionDetails, reqDocuments, customQuestionnaireId } =
        templateDetails || {}

      return postAPI(`/RegPackTemplate`, {
        createModel: {
          templateName: name,
          templateSectionAdd: sectionDetails,
          reqDocumentAdd: reqDocuments,
          customQuestionnaireId: customQuestionnaireId || null,
        },
      })
    },
  )

  return mutate
}

export default useCreateNewRegPackTemplate
