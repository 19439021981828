/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { RightSideModal, Toast, Toggle } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import {
  useEditPoliceCheck,
  useGetPoliceCheckById,
} from 'framework/api/methods'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import { CalendarInput, PageLoader } from 'components/atoms'

export interface EditPoliceCheckDocsProps {
  isActive?: boolean
  [x: string]: any
}

export const EditPoliceCheckDocs: FC<EditPoliceCheckDocsProps> = ({
  isActive,
  fetchDocumentList,
}: EditPoliceCheckDocsProps) => {
  const dispatch = useDispatch()
  const { selectedSection } = useSelector((state: any) => state.audit)

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** police check details state */
  const [policeCheckDetails, setPoliceCheckDetails] = useState<any>({
    status: true,
    policeCheckId: '',
    statutoryUrl: '',
    expiryOn: null,
    hasExpiryDate: false,
  })

  const [policeCheckIds, setPoliceCheckIds] = useState<any>({
    categoryId: '',
    policeCheckReportId: '',
  })

  /** upload file states and functions */
  const [attachments, setAttachments] = useState<any>()

  /** API Handling for get vaccination document list*/
  const {
    refetch: getDocumentDetailsById,
    data: policeCheckCardData,
    isFetching: getDocumentDetailsByIdIsFetching,
  } = useGetPoliceCheckById(
    selectedSection?.candidateId,
    selectedSection?.documentId,
  )

  useEffect(() => {
    isActive && getDocumentDetailsById()
  }, [isActive, selectedSection?.documentId])

  /** set police check card data to drawer */
  useEffect(() => {
    setAttachments(null)

    const {
      isPoliceClearance,
      statutoryUrl,
      attachmentUrls,
      policeCheckId,
      typeId,
      expiryOn,
    } = policeCheckCardData || {}

    setPoliceCheckDetails({
      ...policeCheckDetails,
      status: isPoliceClearance,
      statutoryUrl: statutoryUrl,
      policeCheckId: policeCheckId,
      expiryOn,
      hasExpiryDate: expiryOn ? true : false,
    })

    setPoliceCheckIds({
      ...policeCheckIds,
      categoryId: selectedSection?.categoryId,
      policeCheckReportId: typeId,
    })

    /** map selected document files array and set to my files */
    if (attachmentUrls) {
      const tempObj = { path: attachmentUrls, isExistingFile: true }

      setAttachments(tempObj)
    }
  }, [policeCheckCardData, isActive])

  /** handle on drop method */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setAttachments({ ...acceptedFiles })
    },
    [attachments],
  )

  /** on file drop file size and type validate */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg', '.pdf', '.docx', '.doc'],
    },
    maxSize: 5000000,
  })

  /** edit police check */
  const { mutate: editPoliceCheckMutate, isLoading: editPoliceCheckIsLoading } =
    useEditPoliceCheck()

  /** Process the edit police check */
  async function editPoliceCheck() {
    editPoliceCheckMutate(
      {
        candidateId: selectedSection?.candidateId,
        documentDetails: policeCheckDetails,
        attachments,
        policeCheckIds,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            status: 'Success',
          })
          fetchDocumentList()
          dispatch(setEditDrawer(null))
        },
        onError: ({ response: { data: errData } }: any) => {
          if (errData?.message?.includes('Infrastructure :')) {
            Toast({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message.split(':').slice(1),
              status: 'Error',
            })
          } else {
            Toast({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  /** handle primary button disable */
  useEffect(() => {
    const { status, hasExpiryDate, expiryOn } = policeCheckDetails || {}

    ;((status && attachments) || !status) &&
    (!hasExpiryDate || (hasExpiryDate && expiryOn ? true : false))
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [policeCheckDetails, attachments])

  const resetFields = () => {
    setAttachments(null)
    setPoliceCheckDetails({
      ...policeCheckDetails,
      status: policeCheckCardData?.isPoliceClearance,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Edit Police Check Status'
      headerSubtitle='Edit police check status of the candidate'
      primaryButtonTitle='Edit Police Check Status'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      secondaryButtonTitle='Cancel'
      onHeaderCloseButtonClick={() => {
        dispatch(setEditDrawer(null))
        resetFields()
      }}
      onFooterAddButtonClick={() => {
        editPoliceCheck()
      }}
      onFooterCancelButtonClick={() => {
        dispatch(setEditDrawer(null))
        resetFields()
      }}>
      {/* Loader */}
      {(editPoliceCheckIsLoading || getDocumentDetailsByIdIsFetching) && (
        <PageLoader size='xxs' />
      )}

      {/* main body */}
      <div className='flex w-full flex-col h-fit py-[22px] px-6'>
        {/* expiry date section */}
        <Toggle
          label='Has Expiry Date'
          setValue={policeCheckDetails?.hasExpiryDate}
          onToggleClick={(e: any) => {
            setPoliceCheckDetails({
              ...policeCheckDetails,
              hasExpiryDate: e.target.checked,
              expiryOn:
                e.target.checked === false
                  ? null
                  : policeCheckDetails?.expiryOn,
            })
          }}
        />

        {policeCheckDetails?.hasExpiryDate && (
          <div className='flex flex-col pt-3'>
            <span className='text-small text-Gray-800 font-Medium'>
              Expiry Date
              <span className='ml-1 text-Red-600'>*</span>
            </span>

            <CalendarInput
              value={policeCheckDetails?.expiryOn}
              formatDate={(date) => format(date, 'dd/MM/yyyy')}
              parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
              className='mt-1'
              onChange={(date) => {
                if (date) {
                  setPoliceCheckDetails({
                    ...policeCheckDetails,
                    expiryOn: date,
                  })
                }
              }}
            />
          </div>
        )}

        <div className='flex flex-col mt-5'>
          <div className='flex flex-col'>
            <span className='font-Medium text-small text-Gray-800'>
              Attachments
            </span>

            <span className='font-Regular text-extra-small text-Gray-600'>
              Max file size 5MB - supports docx, pdf, jpg, jpeg and png,
              multiple uploads
            </span>
          </div>

          {/* Upload Document section ---------------------------------------------------------------- */}
          <section className='container mt-4'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />

              <div className='flex flex-col'>
                <span className='text-small text-Gray-800 font-Medium'>
                  Upload Document
                </span>

                <div className='flex bg-white text-Cobalt-600 justify-center items-center py-2 mt-1 rounded-md border border-Gray-300 hover:bg-Gray-50 active:bg-Gray-50'>
                  <i className='ri-upload-2-line pr-2' />

                  <span className='text-small font-Medium'>Upload File</span>
                </div>
              </div>
            </div>

            {attachments && (
              <div className='flex w-full mt-2'>
                <ul className='flex flex-col w-full gap-2'>
                  <li className='flex justify-between items-center bg-white rounded-md border border-Gray-200 w-full px-3 py-1'>
                    <div className='flex justify-start items-center w-4/5'>
                      <i className='ri-attachment-2 text-Gray-400 mr-1' />

                      <span
                        className={CN(
                          'flex text-small font-Medium line-clamp-1 break-all',
                          {
                            'text-Green-500': attachments?.isExistingFile,
                            'text-Gray-800': !attachments?.isExistingFile,
                          },
                        )}>
                        {attachments?.isExistingFile
                          ? attachments?.path.split('/')[2].split('_').slice(1)
                          : attachments[0]?.path}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </section>
        </div>
      </div>
    </RightSideModal>
  )
}

EditPoliceCheckDocs.defaultProps = {
  isActive: false,
}

export default EditPoliceCheckDocs
