import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useUpdateUserProfileDetails } from 'framework/api/methods'
import moment from 'moment'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { UserDetailViewAndEditSection } from 'components/molecules'
import { isLetters } from 'utils'

export interface PersonalTabViewProps {
  [x: string]: any
  userData?: any
}

export const PersonalTabView: FC<PersonalTabViewProps> = ({
  className,
  getUserProfileDetailsById,
  userData,
  notify,
  refetch,
  setRefetch,
}: PersonalTabViewProps) => {
  const dispatch = useDispatch()
  const { userDetails, isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )
  const [userPersonalDetails, setUserPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    displayName: '',
    jobTitle: '',
    dob: '',
    gender: '',
  })

  const [fullNameEditMode, setFullNameEditMode] = useState(false)

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
  })

  const PersonalTabViewClasses = CN(`personal-tab-view px-6`, className)

  useEffect(() => {
    setUserPersonalDetails({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      displayName: userData?.displayName,
      jobTitle: userData?.jobTitle,
      dob: userData?.dob,
      gender: userData?.gender,
    })
  }, [userData])

  /** full Name Variables */
  const [fullNameVariables, setFullNameVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** Display Name Variables */
  const [displayVariables, setDisplayVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** job title Variables */
  const [jobTitleVariables, setJobTitleVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** Date of birth Variables */
  const [dobVariables, setDobVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** Gender Variables */
  const [genderVariables, setGenderVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** API Call for Update user profile data */
  const { mutate: updateUserProfileDetailsMutate } =
    useUpdateUserProfileDetails()

  /** Process update user data API call */
  async function updateUserProfileDetails(method: any) {
    handleIsLoading(true, method)
    updateUserProfileDetailsMutate(
      { obj: { ...userPersonalDetails, id: userDetails.id }, method },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })

          handleIsLoading(false, method)

          handleKey(method)

          setFullNameEditMode(false)

          method === 'jobTitle' && setRefetch(!refetch)

          /** full name edit mode still on when other get edited and saved */
          method !== 'fullName' && fullNameEditMode && setFullNameEditMode(true)

          /** updated header bar details */
          method == 'fullName' &&
            dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))

          userDetails.id && getUserProfileDetailsById(userDetails.id)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
          handleIsLoading(false, method)
        },
      },
    )
  }

  /** handle key */
  const handleKey = (method: any) => {
    method === 'fullName' &&
      setFullNameVariables({ ...fullNameVariables, key: Math.random() })
    method === 'displayName' &&
      setDisplayVariables({ ...displayVariables, key: Math.random() })
    method === 'jobTitle' &&
      setJobTitleVariables({ ...jobTitleVariables, key: Math.random() })
    method === 'dob' && setDobVariables({ ...dobVariables, key: Math.random() })
    method === 'gender' &&
      setGenderVariables({ ...genderVariables, key: Math.random() })
  }

  /** handle is loading */
  const handleIsLoading = (isLoading: boolean, method: any) => {
    method === 'fullName' &&
      setFullNameVariables({ ...fullNameVariables, isLoading: isLoading })
    method === 'displayName' &&
      setDisplayVariables({ ...displayVariables, isLoading: isLoading })
    method === 'jobTitle' &&
      setJobTitleVariables({ ...jobTitleVariables, isLoading: isLoading })
    method === 'dob' &&
      setDobVariables({ ...dobVariables, isLoading: isLoading })
    method === 'gender' &&
      setGenderVariables({ ...genderVariables, isLoading: isLoading })
  }

  /** validate full name fields */
  const validateFullNameInputs = () => {
    const { firstName, lastName } = userPersonalDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
    })

    const isAllValidFields = isLetters(firstName) && isLetters(lastName)

    return isAllValidFields
  }

  /** handle save button click */
  const onSaveButtonClick = (method: any) => {
    if (method === 'fullName') {
      const valid = validateFullNameInputs()
      valid && updateUserProfileDetails(method)
    } else {
      updateUserProfileDetails(method)
    }
  }

  return (
    <div className={PersonalTabViewClasses}>
      {/* tab container */}
      <div className='divide-y divide-Gray-100'>
        {/* full name section */}
        {!fullNameEditMode ? (
          <div
            className='user-detail-view-and-edit-section w-full flex items-center justify-between py-5 bg-white'
            key={fullNameVariables?.key}>
            <div className='flex flex-col w-full'>
              <span className='text-small font-Medium text-Gray-500'>
                Full name
              </span>

              <span className='w-11/12 text-Gray-800 font-Regular pt-2 break-all'>
                {userData?.firstName &&
                  userData?.lastName &&
                  `${userData?.firstName} ${userData?.lastName}`}
              </span>
            </div>

            <Button
              onClick={() => {
                setFullNameEditMode(true)
              }}
              textTheme='blue'
              theme='link'>
              Change
            </Button>
          </div>
        ) : (
          <div
            className='user-detail-view-and-edit-section w-full flex items-end justify-between py-5 bg-white'
            key={fullNameVariables?.key}>
            <div className='flex'>
              <TextField
                label='First name'
                className='w-[200px] mr-4'
                onChange={(e: any) => {
                  setIsErrorField({
                    ...isErrorField,
                    firstName: false,
                  })
                  setUserPersonalDetails({
                    ...userPersonalDetails,
                    firstName: e.target.value,
                  })
                }}
                value={
                  userPersonalDetails?.firstName &&
                  userPersonalDetails?.firstName
                }
                placeholder='Jane'
                isErrored={isErrorField.firstName}
              />

              <TextField
                label='Last name'
                className='w-[200px]'
                onChange={(e: any) => {
                  setIsErrorField({
                    ...isErrorField,
                    lastName: false,
                  })
                  setUserPersonalDetails({
                    ...userPersonalDetails,
                    lastName: e.target.value,
                  })
                }}
                value={
                  userPersonalDetails?.lastName && userPersonalDetails?.lastName
                }
                placeholder='Doe'
                isErrored={isErrorField.lastName}
              />
            </div>

            <div className='flex'>
              <Button
                className='mr-2'
                onClick={() => {
                  setUserPersonalDetails({
                    ...userPersonalDetails,
                    firstName: userData?.firstName,
                    lastName: userData?.lastName,
                  })
                  setIsErrorField({
                    ...isErrorField,
                    firstName: false,
                    lastName: false,
                  })
                  setFullNameEditMode(false)
                }}
                textTheme='blue'
                theme='white'>
                Cancel
              </Button>

              <Button
                onClick={() => {
                  onSaveButtonClick('fullName')
                }}
                isDisabled={
                  userPersonalDetails.firstName && userPersonalDetails.lastName
                    ? false
                    : true
                }
                isLoading={fullNameVariables.isLoading}
                theme='gradient'>
                Save
              </Button>
            </div>
          </div>
        )}
        {/* display name section */}
        <UserDetailViewAndEditSection
          sectionTitle='Display name'
          placeholder='Jane Doe'
          key={displayVariables?.key}
          sectionValue={userPersonalDetails?.displayName}
          textFieldLabel='Display name'
          helperText='This is how other users would identify you'
          inputFieldValue={
            userPersonalDetails?.displayName && userPersonalDetails?.displayName
          }
          onChangeInputField={(e: any) => {
            setUserPersonalDetails({
              ...userPersonalDetails,
              displayName: e.target.value,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('displayName')
          }}
          onCancel={() => {
            setDisplayVariables({ ...displayVariables, key: Math.random() })
            setUserPersonalDetails({
              ...userPersonalDetails,
              displayName: userData?.displayName,
            })
          }}
          saveIsLoading={displayVariables.isLoading}
          saveButtonIsDisabled={userPersonalDetails.displayName ? false : true}
        />

        {/* job title section */}
        <UserDetailViewAndEditSection
          sectionTitle='Job title'
          placeholder='Manager'
          key={jobTitleVariables?.key}
          sectionValue={userPersonalDetails?.jobTitle}
          textFieldLabel='Job title'
          inputFieldValue={
            userPersonalDetails?.jobTitle && userPersonalDetails?.jobTitle
          }
          onChangeInputField={(e: any) => {
            setUserPersonalDetails({
              ...userPersonalDetails,
              jobTitle: e.target.value,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('jobTitle')
          }}
          onCancel={() => {
            setJobTitleVariables({ ...displayVariables, key: Math.random() })
            setUserPersonalDetails({
              ...userPersonalDetails,
              jobTitle: userData?.jobTitle,
            })
          }}
          saveIsLoading={jobTitleVariables?.isLoading}
        />

        {/* date of birth section */}
        <UserDetailViewAndEditSection
          sectionTitle='Date of birth'
          key={dobVariables?.key}
          sectionValue={
            userPersonalDetails?.dob &&
            moment(userPersonalDetails?.dob).format('YYYY-MM-DD')
          }
          type='date'
          selectedDate={
            userPersonalDetails?.dob &&
            moment(userPersonalDetails?.dob).format('YYYY-MM-DD')
          }
          onChangeDatePicker={(date) => {
            setUserPersonalDetails({
              ...userPersonalDetails,
              dob: date,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('dob')
          }}
          saveIsLoading={dobVariables?.isLoading}
          onCancel={() => {
            setDobVariables({ ...dobVariables, key: Math.random() })
            setUserPersonalDetails({
              ...userPersonalDetails,
              dob: userData?.dob,
            })
          }}
        />

        {/* gender section */}
        <UserDetailViewAndEditSection
          sectionTitle='Gender'
          key={genderVariables?.key}
          sectionValue={userPersonalDetails?.gender}
          type='radio'
          defaultCheckedGender={
            userPersonalDetails?.gender && userPersonalDetails?.gender
          }
          onRadioGroupValueChange={(e: any) => {
            setUserPersonalDetails({
              ...userPersonalDetails,
              gender: e.target.id,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('gender')
          }}
          onCancel={() => {
            setGenderVariables({ ...genderVariables, key: Math.random() })
            setUserPersonalDetails({
              ...userPersonalDetails,
              gender: userData?.gender,
            })
          }}
          saveIsLoading={genderVariables?.isLoading}
        />
      </div>
    </div>
  )
}

PersonalTabView.defaultProps = {}

export default PersonalTabView
