/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { CandidateProfileCardType } from 'framework/api/methods'
import { GetRefereesByCandidateResponse } from 'framework/api/methods/candidates/useGetRefereesByCandidate'

import { RefereeStatusBadge } from 'components/atoms'
import { AddEditReferee } from 'components/Drawers/Candidate'
import {
  CallRecordingModal,
  CandidateRefereeTabEmptyState,
  RefereeReportViewModal,
  RequestRefereeModal,
  RequestRefereeReminderModal,
  WebRefCheck,
} from 'components/molecules'
import { CandidateRefereeCard } from 'components/molecules/CandidateRefereeCard'

export interface RefereesTabViewProps {
  [x: string]: any
  candidateReferees: GetRefereesByCandidateResponse | undefined
  candidate: CandidateProfileCardType
  getCandidateRefereesList: () => void
}

export const RefereesTabView: FC<RefereesTabViewProps> = ({
  className,
  candidate,
  candidateReferees,
  getCandidateRefereesList,
  ...restProps
}: RefereesTabViewProps) => {
  const [isWebRefCheckModal, setIsWebRefCheckModal] = useState<any>({
    enable: false,
    selectAll: false,
    selectedRefereeId: '',
  })

  const [refereeAddEditDrawerDetails, setRefereeAddEditDrawerDetails] =
    useState({
      isActive: false,
      drawerMode: '',
    })
  const [refereeViewReportModalData, setRefereeViewReportModalData] = useState({
    isActive: false,
    isDownload: false,
  })

  const [isOpenRequestRefereeModal, setIsOpenRequestRefereeModal] =
    useState(false)

  const [
    isOpenRequestRefereeReminderModal,
    setIsOpenRequestRefereeReminderModal,
  ] = useState(false)

  const RefereesTabViewClasses = CN(
    `Referees-tab-view flex flex-col gap-[22px]`,
    className,
  )

  const [selectedRefereeDetails, setSelectedRefereeDetails] = useState<any>()

  const [callRecordingModalIsActive, setCallRecordingModalIsActive] =
    useState(false)

  useEffect(() => {
    getCandidateRefereesList()
  }, [])

  return (
    <div className={RefereesTabViewClasses} {...restProps}>
      <div className='flex justify-between'>
        <div className='flex items-center justify-center'>
          <span className='font-Medium text-heading-4 text-[#000000]'>
            Referees
          </span>

          {/** Referee Status Badge */}
          {candidateReferees?.refereeStatus && (
            <RefereeStatusBadge
              refereeStatus={candidateReferees?.refereeStatus}
            />
          )}
        </div>

        <div className='flex gap-2'>
          {/** Add Referee Button */}
          <Button
            iconLeft={<i className='ri-add-fill' />}
            theme='gradient'
            onClick={() => {
              setRefereeAddEditDrawerDetails({
                ...refereeAddEditDrawerDetails,
                isActive: true,
                drawerMode: 'Add',
              })
            }}>
            Add Referee
          </Button>

          {/** Send Ref Check Button */}
          <Button
            iconLeft={<i className='ri-send-plane-line' />}
            isDisabled={
              !(candidateReferees && candidateReferees?.referees?.length > 0)
            }
            textTheme='blue'
            theme='white'
            onClick={() => {
              setIsWebRefCheckModal({
                ...isWebRefCheckModal,
                selectedRefereeId: '',
                selectAll: true,
                enable: true,
              })
            }}>
            Send Ref Check for All
          </Button>

          {/** Request Referee Button */}
          {candidateReferees?.referees?.length === 0 &&
            (candidateReferees?.requestRefereeSentOn !== null ? (
              <Button
                iconLeft={<i className='ri-alarm-line' />}
                textTheme='blue'
                theme='white'
                onClick={() => {
                  setIsOpenRequestRefereeReminderModal(true)
                }}>
                Remind to Submit Referees
              </Button>
            ) : (
              <Button
                iconLeft={<i className='ri-team-line' />}
                textTheme='blue'
                theme='white'
                onClick={() => {
                  setIsOpenRequestRefereeModal(true)
                }}>
                Request Referees
              </Button>
            ))}
        </div>
      </div>
      {/** Empty State */}
      {candidateReferees && candidateReferees?.referees?.length > 0 ? (
        candidateReferees.referees.map((candidateReferee, index) => (
          <CandidateRefereeCard
            key={index}
            referee={candidateReferee}
            refereeCount={candidateReferees?.referees?.length || 0}
            getCandidateRefereesList={getCandidateRefereesList}
            refereeIndex={index + 1}
            candidate={candidate}
            setSelectedRefereeDetails={setSelectedRefereeDetails}
            onEditClick={() => {
              setRefereeAddEditDrawerDetails({
                ...refereeAddEditDrawerDetails,
                isActive: true,
                drawerMode: 'Edit',
              })
            }}
            onCallRecordingsClick={() => {
              setCallRecordingModalIsActive(true)
            }}
            onClickWebRefCheck={() => {
              setIsWebRefCheckModal({
                ...isWebRefCheckModal,
                selectedRefereeId: candidateReferee?.id,
                selectAll: false,
                enable: true,
              })
            }}
            onViewReportClick={() => {
              setRefereeViewReportModalData({
                isActive: true,
                isDownload: false,
              })
            }}
            onDownloadReportClick={() => {
              setRefereeViewReportModalData({
                isActive: true,
                isDownload: true,
              })
            }}
          />
        ))
      ) : (
        <CandidateRefereeTabEmptyState
          setIsOpenRequestRefereeModal={setIsOpenRequestRefereeModal}
          candidateReferees={candidateReferees}
          onClickAddNewReferee={() => {
            setRefereeAddEditDrawerDetails({
              ...refereeAddEditDrawerDetails,
              isActive: true,
              drawerMode: 'Add',
            })
          }}
        />
      )}
      {refereeViewReportModalData.isActive && (
        <RefereeReportViewModal
          refereeIndex={candidateReferees?.referees.findIndex(
            (data) => data.id === selectedRefereeDetails.id,
          )}
          getCandidateById={getCandidateRefereesList}
          referee={selectedRefereeDetails}
          isActive={refereeViewReportModalData.isActive}
          setIsActive={() => {
            setRefereeViewReportModalData({
              isActive: false,
              isDownload: false,
            })
          }}
          getCandidateRefereesList={getCandidateRefereesList}
          isDownload={refereeViewReportModalData.isDownload}
        />
      )}
      {/** add or edit referee State */}
      <AddEditReferee
        drawerDetails={refereeAddEditDrawerDetails}
        setDrawerDetails={setRefereeAddEditDrawerDetails}
        candidateId={candidate.id}
        getCandidateRefereesList={getCandidateRefereesList}
        selectedRefereeDetails={selectedRefereeDetails}
        setSelectedRefereeDetails={setSelectedRefereeDetails}
      />
      {/** Call recording Modal */}
      <CallRecordingModal
        isActive={callRecordingModalIsActive}
        setIsActive={setCallRecordingModalIsActive}
        selectedRefereeDetails={selectedRefereeDetails}
        onRequestRefereeClick={() => {
          //requestReferees()
        }}
      />
      {/* web ref check modal  */}
      {candidateReferees && candidateReferees?.referees?.length > 0 && (
        <WebRefCheck
          isOpen={isWebRefCheckModal?.enable}
          setIsWebRefCheckModal={setIsWebRefCheckModal}
          selectedRefereeId={isWebRefCheckModal?.selectedRefereeId}
          selectAll={isWebRefCheckModal?.selectAll}
          candidateId={candidate?.id}
          refereeList={candidateReferees?.referees}
          getCandidateRefereesList={getCandidateRefereesList}
        />
      )}

      {/* request referee modal */}
      <RequestRefereeModal
        isActive={isOpenRequestRefereeModal}
        setIsActive={setIsOpenRequestRefereeModal}
        candidateId={candidate?.id}
        getCandidateRefereesList={getCandidateRefereesList}
      />

      {/* request referee reminder modal */}
      <RequestRefereeReminderModal
        isActive={isOpenRequestRefereeReminderModal}
        setIsActive={setIsOpenRequestRefereeReminderModal}
        candidateId={candidate?.id}
        getCandidateRefereesList={getCandidateRefereesList}
        candidateReferees={candidateReferees}
      />
    </div>
  )
}

export default RefereesTabView
