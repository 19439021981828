import React, { FC, useEffect, useState } from 'react'
import { Toggle } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useAddUserPermissions,
  useDeleteUserPermissions,
} from 'framework/api/methods'

export interface UserPermissionSectionProps {
  className?: string | undefined
  item: any
  [x: string]: any
}

export const UserPermissionSection: FC<UserPermissionSectionProps> = ({
  className,
  item,
  notify,
  userId,
  ...restProps
}: UserPermissionSectionProps) => {
  const [permissionState, setPermissionState] = useState(item.enabled)

  useEffect(() => {
    setPermissionState(item.enabled)
  }, [item])

  /** user permission section style */
  const UserPermissionSectionClasses = CN(
    `user-permission-section w-full flex pb-6 justify-between items-center`,
    className,
  )

  /** APi call for add user permission */
  const {
    mutate: addUserPermissionsMutate,
    isLoading: addUserPermissionsIsLoading,
  } = useAddUserPermissions()

  /** Process the add user permission  */
  const assUserPermission = () => {
    addUserPermissionsMutate(
      {
        Id: userId,
        permission: item?.claim,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.messageTitle
              ? successData?.messageTitle
              : '',
            alertBody: 'Successfully changed the permission.',
            status: 'Success',
          })

          setPermissionState(!permissionState)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating user permission!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for delete user permission */
  const {
    mutate: deleteUserPermissionsMutate,
    isLoading: deleteUserPermissionsIsLoading,
  } = useDeleteUserPermissions()

  /** Process the delete user permission  */
  const deleteUserPermission = () => {
    deleteUserPermissionsMutate(
      {
        Id: userId,
        permission: item?.claim,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.messageTitle
              ? successData?.messageTitle
              : '',
            alertBody: 'Successfully changed the permission.',
            status: 'Success',
          })

          setPermissionState(!permissionState)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating user permission!',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className={UserPermissionSectionClasses} {...restProps}>
      {/* permission section left side */}
      <div className='flex flex-col w-[638px]'>
        <span className='font-Medium text-Gray-700'>{item.name}</span>

        <span className='text-small font-Regular text-Gray-500 pt-2'>
          {item.description}
        </span>
      </div>

      {/* permission section right side */}
      <Toggle
        setValue={permissionState}
        isDisabled={
          item.readOnly ||
          deleteUserPermissionsIsLoading ||
          addUserPermissionsIsLoading
        }
        onToggleClick={(e: any) => {
          e.target.checked === true
            ? assUserPermission()
            : deleteUserPermission()
        }}
      />
    </div>
  )
}

UserPermissionSection.defaultProps = {
  className: undefined,
}

export default UserPermissionSection
