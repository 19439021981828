import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddSuperProviderProps {
  superProviderData?: any
}

export const useAddSuperProvider = () => {
  const mutate = useMutation(({ superProviderData }: AddSuperProviderProps) => {
    return postAPI(`/SuperProvider`, {
      name: superProviderData.name,
    })
  })

  return mutate
}

export default useAddSuperProvider
