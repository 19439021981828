import { FC } from 'react'

export interface AgencyProfileAboutProps {
  description?: string | undefined
  [x: string]: any
}

export const AgencyProfileAbout: FC<AgencyProfileAboutProps> = ({
  description,
  ...restProps
}: AgencyProfileAboutProps) => {
  return (
    /** About section of agency profile */
    <div className='agencyProfile-about' {...restProps}>
      <div className='flex flex-col w-full h-auto overflow-y-auto border-[1px] border-Gray-200 rounded-md bg-white pb-4'>
        <span className='font-Medium text-heading-4 text-Gray-800 pl-5 pt-5'>
          About
        </span>

        <span className='font-Regular text-Gray-800 pt-2 pl-4'>
          {description}
        </span>
      </div>
    </div>
  )
}

AgencyProfileAbout.defaultProps = {
  description: undefined,
}

export default AgencyProfileAbout
