import { FC } from 'react'
import logo from 'assets/images/labourHubLogo.svg'

import { Footer } from 'components/common/Footer'

export interface AuthLayoutHalfProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayoutHalf: FC<AuthLayoutHalfProps> = ({
  children,
}: AuthLayoutHalfProps) => {
  return (
    <div className='flex absolute h-full w-full'>
      <div className='left-side w-2/5 h-full login-left-background-image'>
        <img src={logo} alt='' className='ml-[60px] mt-10' />
      </div>
      <div className='right-side w-3/5 flex items-center justify-center pb-[100px]'>
        {children} <Footer />
      </div>
    </div>
  )
}

AuthLayoutHalf.defaultProps = {
  className: undefined,
}

export default AuthLayoutHalf
