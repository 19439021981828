/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetAvailableUserRolesInterface {}

export const useGetAvailableUserRoles = () => {
  const mutate = useMutation(({}: GetAvailableUserRolesInterface) => {
    return get(`/identity/roles`)
  })

  return mutate
}

export default useGetAvailableUserRoles
