import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  Radio,
  RightSideModal,
  SimpleSelect,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import {
  useAddSuburb,
  useEditCandidate,
  useGetCandidateDetailsById,
  useGetCountryList,
  useGetStateListByCountyCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'
import { setEditDrawer } from 'store/reducers/audit/auditSlice'

import {
  CalendarInput,
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail, isLetters, isURL } from 'utils'

export interface EditPersonalInformationProps {
  isActive?: boolean
  [x: string]: any
}

export const EditPersonalInformation: FC<EditPersonalInformationProps> = ({
  getAuditCheckDocumentList,
  isActive,
}: EditPersonalInformationProps) => {
  const dispatch = useDispatch()
  const { selectedCandidate } = useSelector((state: any) => state.audit)

  const [candidateCurrentDetails, setCandidateCurrentDetails] = useState<any>()

  /** candidate details and error fields state */
  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    gender: '',
    mainClientId: '',
    mainClientName: '',
    streetName: '',
    suburb: '',
    state: '',
    stateCode: '',
    postCode: '',
    country: 'Australia',
    countryCode: 'AU',
    latitude: '',
    longitude: '',
    isActive: null,
    hideRoster: false,
    showMedical: false,
    eContactFirstName: '',
    eContactLastName: '',
    eContactRelationship: '',
    eContactState: '',
    eContactMobilePhone: '',
    eContactHomePhone: '',
    linkedin: '',
    facebook: '',
    twitter: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    middleName: false,
    lastName: false,
    email: false,
    eContactFirstName: false,
    eContactLastName: false,
    eContactRelationship: false,
    linkedin: false,
    facebook: false,
    twitter: false,
  })

  const [selectedGender, setSelectedGender] = useState({
    male: false,
    female: false,
    other: false,
    key: 0,
  })

  /** dropdown lists */
  const [countryList, setCountryItemList] = useState<any>()
  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** Get candidate card details api */
  const {
    mutate: getCandidateDetailsByIdMutate,
    isLoading: getCandidateDetailsByIdIsLoading,
  } = useGetCandidateDetailsById()

  /** Process the get candidate details */
  async function getCandidateDetailsById(Id: any) {
    getCandidateDetailsByIdMutate(
      {
        Id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setCandidateCurrentDetails(successData?.profileCard)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting candidate details!',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    isActive && getCandidateDetailsById(selectedCandidate?.id)
  }, [isActive, selectedCandidate])

  /** Get country list */
  const { mutate: getCountryListMutate, isLoading: getCountryListIsLoading } =
    useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping country list to drop down. refused to send label and value attributes */
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
  }, [])

  /** Get state list by country code */
  const {
    mutate: getStateListByCountyCodeMutate,
    isLoading: getStateListByCountyCodeIsLoading,
  } = useGetStateListByCountyCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit candidate */
  const { mutate: editCandidateMutate, isLoading: editCandidateIsLoading } =
    useEditCandidate()

  /** Process the edit candidate */
  const editCandidate = () => {
    editCandidateMutate(
      {
        candidateDetails,
        Id: candidateCurrentDetails?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          getAuditCheckDocumentList()
          dispatch(setEditDrawer(null))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** set client data in edit drawer */
  useEffect(() => {
    const {
      title,
      firstName,
      middleName,
      lastName,
      email,
      phone,
      dateOfBirth,
      gender,
      mainClientId,
      mainClient,
      address,
      status,
      hideRoster,
      showMedical,
      emergencyContact,
      socialLinks,
    } = candidateCurrentDetails || {}

    setCandidateDetails({
      ...candidateDetails,
      title: title ? title : '',
      firstName: firstName ? firstName : '',
      middleName: middleName ? middleName : '',
      lastName: lastName ? lastName : '',
      email: email ? email : '',
      phone: phone ? phone : '',
      dateOfBirth: dateOfBirth,
      gender: gender ? gender : '',
      mainClientId: mainClientId ? mainClientId : '',
      mainClientName: mainClient ? mainClient : '',
      streetName: address?.lineOne ? address?.lineOne : '',
      suburb: address?.suburb ? address?.suburb : '',
      state: address?.state ? address?.state : '',
      stateCode: address?.stateCode ? address?.stateCode : '',
      postCode: address?.postCode ? address?.postCode : '',
      country: address?.country ? address?.country : '',
      countryCode: address?.countryCode ? address?.countryCode : '',
      latitude: address?.latitude !== 0 ? address?.latitude : '',
      longitude: address?.longitude !== 0 ? address?.longitude : '',
      isActive: null,
      hideRoster: hideRoster ? true : false,
      showMedical: showMedical ? true : false,
      eContactFirstName: emergencyContact?.firstname
        ? emergencyContact?.firstname
        : '',
      eContactLastName: emergencyContact?.lastname
        ? emergencyContact?.lastname
        : '',
      eContactRelationship: emergencyContact?.relationship
        ? emergencyContact?.relationship
        : '',
      eContactState: emergencyContact?.state ? emergencyContact?.state : '',
      eContactMobilePhone: emergencyContact?.phoneNo
        ? emergencyContact?.phoneNo
        : '',
      eContactHomePhone: emergencyContact?.alternativePhoneNo
        ? emergencyContact?.alternativePhoneNo
        : '',
      linkedin: socialLinks?.linkedIn ? socialLinks?.linkedIn : '',
      facebook: socialLinks?.facebook ? socialLinks?.facebook : '',
      twitter: socialLinks?.twitter ? socialLinks?.twitter : '',
    })

    if (gender === 'Male') {
      setSelectedGender({
        ...selectedGender,
        key: Math.random(),
        male: true,
      })
    } else if (gender === 'Female') {
      setSelectedGender({
        ...selectedGender,
        key: Math.random(),
        female: true,
      })
    } else if (gender === 'Other') {
      setSelectedGender({
        ...selectedGender,
        key: Math.random(),
        other: true,
      })
    } else {
      setSelectedGender({
        ...selectedGender,
        male: false,
        female: false,
        other: false,
        key: Math.random(),
      })
    }

    getStateListByCountryCode(address?.countryCode)
    getSuburbsByStateCode(address?.stateCode)
  }, [candidateCurrentDetails])

  /** front end validation input fields */
  const validateInputs = () => {
    const {
      firstName,
      middleName,
      lastName,
      email,
      eContactFirstName,
      eContactLastName,
      eContactRelationship,
      linkedin,
      facebook,
      twitter,
    } = candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      middleName: isLetters(middleName) || middleName === '' ? false : true,
      lastName: isLetters(lastName) || lastName === '' ? false : true,
      email: isEmail(email) ? false : true,
      eContactFirstName:
        isLetters(eContactFirstName) || eContactFirstName === '' ? false : true,
      eContactLastName:
        isLetters(eContactLastName) || eContactLastName === '' ? false : true,
      eContactRelationship:
        isLetters(eContactRelationship) || eContactRelationship === ''
          ? false
          : true,
      linkedin: isURL(linkedin) || linkedin === '' ? false : true,
      facebook: isURL(facebook) || facebook === '' ? false : true,
      twitter: isURL(twitter) || twitter === '' ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      (isLetters(middleName) || middleName === '') &&
      (isLetters(lastName) || lastName === '') &&
      isEmail(email) &&
      (isLetters(eContactFirstName) || eContactFirstName === '') &&
      (isLetters(eContactLastName) || eContactLastName === '') &&
      (isLetters(eContactRelationship) || eContactRelationship === '') &&
      (isURL(linkedin) || linkedin === '') &&
      (isURL(facebook) || facebook === '') &&
      (isURL(twitter) || twitter === '')

    return isAllValidFields
  }

  /** handle edit candidate button */
  const onEditCandidate = () => {
    const valid = validateInputs()

    if (valid) {
      editCandidate()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** handle primary button disable */
  useEffect(() => {
    const { title, firstName, email } = candidateDetails || {}
    title && firstName && email
      ? setIsPrimaryButtonDisabled(false)
      : setIsPrimaryButtonDisabled(true)
  }, [candidateDetails])

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(candidateDetails.stateCode, actionMeta?.option?.label)
    }

    setCandidateDetails({
      ...candidateDetails,
      suburb: newValue?.label,
    })
  }

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Edit Candidate'
      headerSubtitle='Edit basic details of candidate'
      primaryButtonTitle='Update Candidate'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onFooterAddButtonClick={() => {
        onEditCandidate()
      }}
      onHeaderCloseButtonClick={() => {
        dispatch(setEditDrawer(null))
      }}
      onFooterCancelButtonClick={() => {
        dispatch(setEditDrawer(null))
      }}>
      {(getCountryListIsLoading ||
        getStateListByCountyCodeIsLoading ||
        editCandidateIsLoading ||
        getCandidateDetailsByIdIsLoading) && <PageLoader size='xxs' />}

      <div className='w-full h-fit py-5 px-6'>
        {/* personal details section ------------------------------------------------------------------------------*/}

        {/* title section */}
        {/* <SimpleSelect
          label='Title'
          isRequired
          value={{
            label: candidateDetails?.title,
            value: candidateDetails?.title,
          }}
          className='w-1/2'
          onChange={(selectedItem: any) => {
            setCandidateDetails({
              ...candidateDetails,
              title: selectedItem?.value,
            })
          }}
          options={[
            { label: 'Mr', value: 'Mr' },
            { label: 'Mrs', value: 'Mrs' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Ms', value: 'Ms' },
          ]}
        /> */}

        {/* first name section */}
        <TextField
          label='First Name'
          isRequired
          value={candidateDetails?.firstName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              firstName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.firstName}
        />

        {/* middle name section */}
        <TextField
          label='Middle Name'
          className='mt-5'
          value={candidateDetails?.middleName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              middleName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.middleName}
        />

        {/* last name section */}
        <TextField
          label='Last Name'
          className='mt-5'
          value={candidateDetails?.lastName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              lastName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.lastName}
        />

        {/* email section */}
        <TextField
          label='Email'
          isRequired
          isDisabled
          className='mt-5'
          value={candidateDetails?.email}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.email}
        />

        {/** Phone number section */}
        <PhoneInputField
          label='Phone'
          value={candidateDetails?.phone}
          onChange={(number: any) =>
            setCandidateDetails({
              ...candidateDetails,
              phone: number,
            })
          }
          className='mt-5'
        />

        {/** gender section */}
        <div className='flex flex-col mt-5'>
          <span className='flex text-Gray-800 font-Medium text-small pb-1'>
            Gender
          </span>

          <div className='flex gap-5' key={selectedGender.key}>
            <Radio
              id='Male'
              labelText='Male'
              helperText=''
              className='select-none cursor-pointer'
              name='candidate-gender'
              defaultChecked={selectedGender.male}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />

            <Radio
              id='Female'
              labelText='Female'
              helperText=''
              className='select-none cursor-pointer'
              name='candidate-gender'
              defaultChecked={selectedGender.female}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />

            <Radio
              id='Other'
              labelText='Other'
              helperText=''
              className='select-none cursor-pointer'
              name='candidate-gender'
              defaultChecked={selectedGender.other}
              onChange={(e: any) => {
                setCandidateDetails({
                  ...candidateDetails,
                  gender: e.target.id,
                })
              }}
            />
          </div>
        </div>

        {/** dob section */}
        <span className='flex mt-5 text-small text-Gray-800 font-Medium'>
          Date of Birth
        </span>

        <CalendarInput
          value={candidateDetails?.dateOfBirth}
          formatDate={(date) => format(date, 'dd/MM/yyyy')}
          parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
          className='mt-1'
          disableFutureDates={true}
          onChange={(date) => {
            if (date) {
              setCandidateDetails({
                ...candidateDetails,
                dateOfBirth: date,
              })
            }
          }}
        />

        {/* address details section ------------------------------------------------------------------------------ */}
        <div className='w-full rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold px-4 py-2 mt-5'>
          Address
        </div>

        {/* street name section */}
        <TextField
          label='Street Name'
          className='mt-5'
          value={candidateDetails?.streetName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              streetName: e.target.value,
            })
          }}
          placeholder=''
        />

        {/* state section */}
        <SimpleSelect
          label='State'
          value={{
            label: candidateDetails?.state,
            value: candidateDetails?.stateCode,
          }}
          className='mt-5'
          onChange={(selectedItem: any) => {
            if (candidateDetails?.stateCode !== selectedItem?.value) {
              setCandidateDetails({
                ...candidateDetails,
                state: selectedItem?.label,
                stateCode: selectedItem?.value,
                suburb: '',
              })
              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          options={stateList}
        />

        {/* state and post code section */}
        <div className='flex w-full mt-5 gap-5'>
          <CreatableInputSelect
            label='Suburb'
            className='w-2/3'
            value={{
              label: candidateDetails.suburb,
              value: candidateDetails.suburb,
            }}
            options={suburbList}
            onChange={handleSuburbChange}
            isDisabled={!candidateDetails.stateCode}
            isErrored={isErrorField.state === '' ? true : false}
          />

          <TextField
            label='Zip Code'
            className='w-1/3'
            value={candidateDetails.postCode}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                postCode: e.target.value,
              })
            }}
            placeholder=''
          />
        </div>
      </div>
    </RightSideModal>
  )
}

EditPersonalInformation.defaultProps = {
  isActive: false,
}

export default EditPersonalInformation
