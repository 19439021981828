/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetWaitingForDocumentList } from 'framework/api/methods'

import { TableHeader, UITable } from 'components/common/UITable'

import { WaitingForDocumentListColumn } from './WaitingForDocumentListColumn'

export interface WaitingForDocumentListProps {
  [x: string]: any
}

export const WaitingForDocumentList: FC<WaitingForDocumentListProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  ...restProps
}: WaitingForDocumentListProps) => {
  const dispatch = useDispatch()
  const { isAuditModal } = useSelector((state: any) => state.audit)

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [waitingForDocumentTableColumn, setWaitingForDocumentTableColumn] =
    useState<any>([])
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedDocumentCategory, setSelectedDocumentCategory] = useState<any>(
    {
      categoryName: '',
      categoryId: '',
    },
  )

  const [selectedDocumentType, setSelectedDocumentType] = useState<any>({
    typeName: '',
    typeId: '',
  })

  const [filterVariables, setFilterVariables] = useState<any>({
    documentCategory: '',
    documentType: '',
  })

  /** waiting for document List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** waiting for document List on error */
  const onError = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All waiting for document List */
  const {
    refetch: getTableData,
    isLoading: getWaitingForDocumentListIsLoading,
    isFetching: getWaitingForDocumentListIsFetching,
  } = useGetWaitingForDocumentList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      documentCategory: filterVariables?.documentCategory || '',
      search: paginationVariables.search,
      documentType: filterVariables?.documentType || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    getTableData()
  }, [paginationVariables])

  /** for refetch table list when close audit check modal */
  useEffect(() => {
    !isAuditModal?.isOpen && getTableData()
  }, [isAuditModal])

  /** waiting for document list table column handling */
  useEffect(() => {
    if (
      tableData?.waitingDocuments &&
      tableData?.waitingDocuments?.length > 0
    ) {
      const columns: any = WaitingForDocumentListColumn(dispatch)
      setWaitingForDocumentTableColumn(columns)
    }
  }, [tableData])

  /** apply filtering function */
  const applyFilter = () => {
    setFilterActive(true)
    getTableData()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      documentCategory: selectedDocumentCategory?.categoryName || '',
      documentType: selectedDocumentType?.typeName || '',
    })
  }

  /** filter reset function */
  const resetFilter = () => {
    setSelectedDocumentCategory('')
    setSelectedDocumentType('')
    setIsFilterMenuOpen(false)
    setFilterActive(false)

    setFilterVariables({
      documentCategory: '',
      documentType: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  const WaitingForDocumentListClasses = CN(
    `waiting-for-document-list w-full h-full`,
    className,
  )

  return (
    <div className={WaitingForDocumentListClasses} {...restProps}>
      <div className='flex w-full flex-col px-4 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          searchFieldPlaceholder={'Search by candidate and email'}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}></TableHeader>

        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex flex-col bg-white w-full'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              {/** Input field of filtering section */}

              <div className='flex items-end px-4 w-full pt-3 pb-4 z-[10]'>
                <SimpleSelect
                  label='By Document Category'
                  placeholder='All'
                  value={
                    selectedDocumentCategory?.categoryName
                      ? {
                          label: selectedDocumentCategory?.categoryName,
                          value: selectedDocumentCategory?.categoryId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentCategory({
                      ...selectedDocumentCategory,
                      categoryId: selectedItem.id,
                      categoryName: selectedItem.name,
                    })
                  }}
                  options={documentCategoryList}
                />

                <SimpleSelect
                  label='By Document Type'
                  placeholder='All'
                  value={
                    selectedDocumentType?.typeName
                      ? {
                          label: selectedDocumentType?.typeName,
                          value: selectedDocumentType?.typeId,
                        }
                      : null
                  }
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedDocumentType({
                      ...selectedDocumentType,
                      typeId: selectedItem.id,
                      typeName: selectedItem.name,
                    })
                  }}
                  options={documentTypeList}
                />

                <Button
                  className='mr-4 h-[38px] w-[139px]'
                  isDisabled={
                    !selectedDocumentCategory?.categoryName &&
                    !selectedDocumentType?.typeName
                  }
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>

                <Button
                  className='ml-2 h-[38px] w-[100px]'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='w-full h-[calc(64vh-70px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.waitingDocuments || []}
            isLoading={
              getWaitingForDocumentListIsLoading ||
              getWaitingForDocumentListIsFetching
            }
            className=''
            columns={waitingForDocumentTableColumn || []}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}

WaitingForDocumentList.defaultProps = {}

export default WaitingForDocumentList
