import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

interface EditMedicalConditionInterface {
  candidateId: any
  documentDetails: any
  attachments: any
}

export const useEditMedicalCondition = () => {
  const mutate = useMutation(
    ({
      candidateId,
      documentDetails,
      attachments,
    }: EditMedicalConditionInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Title', documentDetails?.title)
      bodyFormData.append('CategoryId', documentDetails?.categoryId)
      bodyFormData.append('TypeId', documentDetails?.typeId)
      bodyFormData.append('DateOfIssue', documentDetails?.dateOfIssue)

      documentDetails?.expiryDate &&
        bodyFormData.append('ExpiryOn', documentDetails?.expiryDate)

      documentDetails?.dueDate &&
        bodyFormData.append('DueOn', documentDetails?.dueDate)

      bodyFormData.append('Notes', documentDetails?.notes)
      bodyFormData.append(
        'isWorkingAffected',
        documentDetails?.isWorkingAffected,
      )
      bodyFormData.append('AuditRequired', documentDetails?.auditCheck)
      attachments?.map((item) => {
        const newAttachments = item?.isExistingFile ? null : item

        newAttachments && bodyFormData.append('Attachments', item)
      })

      return putAPIwithFormData(
        `/facade/Candidate/${candidateId}/MedicalCondition/${documentDetails?.id}`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useEditMedicalCondition
