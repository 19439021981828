/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-empty-pattern */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetDocumentTypeListInterface {}

export const useGetDocumentTypeList = () => {
  const mutate = useMutation(({}: useGetDocumentTypeListInterface) => {
    return get(`/Type`, {})
  })

  return mutate
}

export default useGetDocumentTypeList
