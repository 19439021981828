/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from '@labourhub/labour-hub-ds'
import EmptyEvents from 'assets/images/EmptyEvents.svg'
import CN from 'classnames'
import {
  useDeleteClientContact,
  useGetPrimaryConsultantList,
} from 'framework/api/methods'

import {
  AddNewClientContact,
  EditClientContact,
} from 'components/Drawers/client'
import { ClientProfileContactCard } from 'components/molecules'

export interface ContactTabViewProps {
  [x: string]: any
}

export const ContactTabView: FC<ContactTabViewProps> = ({
  className,
  clientId,
  clientLocations,
  contactItemList,
  getClientContactsList,
  getClientLocationsById,
  notify,
  ...restProps
}: ContactTabViewProps) => {
  const ContactTabViewClasses = CN(`contact-tab-view`, className)
  const { clickValue } = useSelector((state: any) => state.layout)
  const [isOpenAddNewClientContactDrawer, setIsOpenAddNewClientContactDrawer] =
    useState(false)

  const [isOpenEditClientContactDrawer, setIsOpenEditClientContactDrawer] =
    useState(false)

  const [isOpenDeleteClientContactModal, setIsOpenDeleteClientContactModal] =
    useState(false)

  const [primaryConsultantList, setPrimaryConsultantList] = useState<any>()

  const [selectedContactItem, setSelectedContactItem] = useState<any>()
  const [isMoreOptionOpen, setIsMoreOptionOpen] = useState<any>({
    index: -1,
    isEnable: false,
  })

  useEffect(() => {
    setIsMoreOptionOpen({
      index: -1,
      isEnable: false,
    })
  }, [clickValue])

  /** Get primary consultants list */
  const { mutate: getPrimaryConsultantListMutate } =
    useGetPrimaryConsultantList()

  /** Process the get primary consultants list */
  async function getPrimaryConsultantList() {
    getPrimaryConsultantListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setPrimaryConsultantList(successData?.usersInRole)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting primary consultant list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the delete contact */
  const {
    mutate: deleteClientContactMutate,
    isLoading: deleteClientContactIsLoading,
  } = useDeleteClientContact()

  /** Process the delete client contact */
  const deleteClientContact = () => {
    deleteClientContactMutate(
      {
        clientId,
        contactId: selectedContactItem?.contactId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Contact successfully deleted.',
            alertBody: '',
            status: 'Success',
          })

          getClientContactsList()
          getClientLocationsById()
          setIsOpenDeleteClientContactModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className={ContactTabViewClasses} {...restProps}>
      {/* contact tab header */}
      <div className='w-full flex justify-between items-center'>
        <div className='flex flex-col'>
          <span className='text-heading-5 text-Gray-800 font-Medium'>
            Contacts
          </span>

          {/* contact tab sub header */}
          <span className='text-small text-Gray-500 font-Regular'>
            People who you should KIT
          </span>
        </div>

        <Button
          iconLeft={<i className='ri-add-line' />}
          onClick={() => {
            setIsOpenAddNewClientContactDrawer(true)
          }}
          theme='gradient'>
          Add New Contact
        </Button>
      </div>

      <div className='flex mt-8 gap-x-[21px]'>
        <div className='flex flex-col w-3/4'>
          {contactItemList?.map((contactItem: any, idx: any) => (
            <ClientProfileContactCard
              key={idx}
              index={idx}
              contactItem={contactItem}
              onEditClick={() => {
                setIsOpenEditClientContactDrawer(true)
                setSelectedContactItem(contactItem)
              }}
              onRemoveClick={() => {
                setIsOpenDeleteClientContactModal(true)
                setSelectedContactItem(contactItem)
              }}
              notify={notify}
              isMoreOptionOpen={isMoreOptionOpen}
              setIsMoreOptionOpen={setIsMoreOptionOpen}
            />
          ))}

          {contactItemList?.length <= 0 && <>No contacts found.</>}
        </div>

        <div className='flex flex-col justify-center items-center w-1/4 h-fit bg-white rounded-md border border-Gray-200 p-4'>
          <div className='w-full flex justify-between items-start'>
            <span>Upcoming Events</span>

            {/* add event button section */}
            <div
              className='flex items-center justify-center bg-Cobalt-50 hover:bg-Cobalt-100 active:bg-Cobalt-200 text-Cobalt-600 rounded py-[5.5px] px-[7.5px]'
              onClick={() => {
                notify({
                  alertHeader: 'working in progress...',
                  status: 'Warning',
                })
              }}>
              <i className='ri-calendar-event-fill text-small mr-2' />

              <span className='text-extra-small font-Medium'>Add Event</span>
            </div>
          </div>

          <div className='mt-[49px] px-[88px]'>
            <img src={EmptyEvents} alt='empty_Events' />
          </div>

          <span className='text-heading-4 text-Gray-800 font-Medium pt-[15px]'>
            No upcoming events
          </span>

          <span className='text-Gray-600 font-Regular pt-1'>
            You can start by adding new events
          </span>

          <Button
            iconLeft={<i className='ri-add-fill text-[14px]' />}
            size='sm'
            className='mt-6 mb-[82px]'
            onClick={() => {
              //
            }}>
            Add Event
          </Button>
        </div>
      </div>

      {/* add client drawer */}
      <AddNewClientContact
        isActive={isOpenAddNewClientContactDrawer}
        setIsOpenAddNewClientContactDrawer={setIsOpenAddNewClientContactDrawer}
        notify={notify}
        clientId={clientId}
        getClientContactsList={getClientContactsList}
        clientLocations={clientLocations}
        primaryConsultantList={primaryConsultantList}
        getPrimaryConsultantList={getPrimaryConsultantList}
      />

      {/* edit client drawer */}
      <EditClientContact
        isActive={isOpenEditClientContactDrawer}
        setIsOpenEditClientContactDrawer={setIsOpenEditClientContactDrawer}
        notify={notify}
        clientId={clientId}
        getClientContactsList={getClientContactsList}
        getClientLocationsById={getClientLocationsById}
        clientLocations={clientLocations}
        selectedContactItem={selectedContactItem}
        primaryConsultantList={primaryConsultantList}
        getPrimaryConsultantList={getPrimaryConsultantList}
      />

      {/* delete client contact confirmation modal */}
      <Modal
        isActive={isOpenDeleteClientContactModal}
        onClickPrimaryBtn={() => {
          deleteClientContact()
        }}
        onClickSecondaryBtn={() => {
          setIsOpenDeleteClientContactModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteClientContactIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenDeleteClientContactModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col justify-center items-center p-6'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='text-Gray-900 text-heading-5 font-Medium pt-5'>
            Delete Contact?
          </span>

          <span className='text-Gray-500 text-small text-center font-Regular pt-2'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}

ContactTabView.defaultProps = {}

export default ContactTabView
