import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextArea,
  TextField,
  Toast,
  Toggle,
} from '@labourhub/labour-hub-ds'
import {
  useAddDocumentType,
  useGetDocumentTypeByCategoryId,
} from 'framework/api/methods'
import { setTemplateDetails } from 'store/reducers/regPack/regPackSlice'

import { CreatableInputSelect } from 'components/atoms'
import { RequiredDocumentType } from 'components/pages/candidates/CandidateProfile/registrationPackTabs'

export interface AddRequiredDocumentProps {
  documentData?: RequiredDocumentType
  documentIndex?: number
  hide: () => void
  isActive: boolean
  resetDocumentIndex: () => void
  [x: string]: any
}

export const AddRequiredDocument: FC<AddRequiredDocumentProps> = ({
  isActive,
  hide,
  documentData,
  documentIndex,
  resetDocumentIndex,
}: AddRequiredDocumentProps) => {
  const [document, setDocument] = useState<RequiredDocumentType>({
    auditRequired: documentData?.auditRequired || false,
    name: documentData?.name || '',
    documentCategoryId: documentData?.documentCategoryId || '',
    category: documentData?.category || '',
    documentTypeId: documentData?.documentTypeId || '',
    type: documentData?.type || '',
    notes: documentData?.notes || '',
  })
  const [documentTypeList, setDocumentTypeList] = useState<any>()
  const { documentCategoriesList } = useSelector((state: any) => state.document)
  const { templateDetails } = useSelector((state: any) => state?.regPack)
  const dispatch = useDispatch()

  const resetForm = () => {
    setDocument({
      auditRequired: false,
      name: '',
      documentCategoryId: '',
      category: '',
      type: '',
      documentTypeId: '',
      notes: '',
    })
  }

  const addToDocumentList = (document: RequiredDocumentType) => {
    const updatedDetails = { ...templateDetails }
    if (updatedDetails.reqDocuments) {
      updatedDetails.reqDocuments = [...updatedDetails.reqDocuments, document]
    } else {
      updatedDetails.reqDocuments = [document]
    }
    dispatch(setTemplateDetails(updatedDetails))
    resetForm()
  }

  const editDocument = () => {
    if (documentIndex !== undefined) {
      const updatedDetails = { ...templateDetails }
      const updatedDocs = [...updatedDetails.reqDocuments]
      updatedDocs[documentIndex] = document
      updatedDetails.reqDocuments = updatedDocs
      dispatch(setTemplateDetails(updatedDetails))
      resetDocumentIndex()
    }
    resetForm()
  }

  /** APi call for the add new document type */
  const { mutate: addDocumentTypeMutate } = useAddDocumentType()

  /** Process the add new doc type */
  const addDocumentType = (categoryId, name) => {
    addDocumentTypeMutate(
      {
        categoryId,
        name,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set category id for send data */
          Toast({
            alertHeader: 'Success...!',
            alertBody: 'Document type created',
            status: 'Success',
          })
          setDocument({
            ...document,
            documentTypeId: successData?.documentType?.id,
            type: successData?.documentType?.name,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the get document type by category Id */
  const { mutate: getDocumentTypeByCategoryIdMutate } =
    useGetDocumentTypeByCategoryId()

  /** Process the get doc type by category id */
  const getDocumentTypeByCategoryId = (categoryId) => {
    getDocumentTypeByCategoryIdMutate(
      {
        categoryId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempDocumentTypeList = successData?.documentTypes?.map(
            (item: any) => {
              return {
                ...item,
                value: item.id,
                label: item.name,
              }
            },
          )

          setDocumentTypeList(tempDocumentTypeList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (document.documentCategoryId) {
      getDocumentTypeByCategoryId(document.documentCategoryId)
    }
  }, [document.documentCategoryId])

  useEffect(() => {
    if (documentData) {
      setDocument(documentData)
    }
  }, [documentData])

  return (
    <RightSideModal
      isActive={isActive}
      headerTitle='Request Required Document'
      headerSubtitle='When you need required documents from the candidate'
      primaryButtonTitle='Request Document'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={false}
      onFooterAddButtonClick={() => {
        hide()
        if (documentIndex !== undefined) {
          editDocument()
        } else {
          addToDocumentList(document)
        }
        resetForm()
      }}
      onHeaderCloseButtonClick={() => {
        resetForm()
        hide()
      }}
      onFooterCancelButtonClick={() => {
        resetForm()
        hide()
      }}>
      {/* main body */}
      <div className='flex flex-col w-full px-6 py-5 gap-6'>
        <TextField
          label='Document Name'
          value={document.name}
          onChange={(e: any) => {
            setDocument({
              ...document,
              name: e.target.value,
            })
          }}
          placeholder=''
        />
        {/* title section */}
        <SimpleSelect
          label='Document Category'
          isRequired
          value={{
            label: document.category,
            value: document.category,
          }}
          onChange={(selectedItem: any) => {
            setDocument({
              ...document,
              documentCategoryId: selectedItem.value,
              category: selectedItem.label,
            })
          }}
          options={documentCategoriesList.filter(
            (category) => category.name === 'Other',
          )}
        />

        {/* document type section */}
        <CreatableInputSelect
          label='Document Type'
          className='document-type pt-5'
          placeholder='Ex: Forklift Drivers License, White Card Holder etc.'
          value={
            document.documentTypeId
              ? {
                  label: document.type,
                  value: document.documentTypeId,
                }
              : null
          }
          options={documentTypeList || []}
          onChange={(selectedItem: any, actionMeta: ActionMeta<any>) => {
            if (actionMeta.action == 'create-option') {
              addDocumentType(document?.documentCategoryId, selectedItem?.value)
            } else {
              setDocument({
                ...document,
                documentTypeId: selectedItem?.value,
                type: selectedItem?.label,
              })
            }
          }}
          isDisabled={!document.documentCategoryId}
        />

        <TextArea
          value={document.notes}
          onChange={(e: any) => {
            setDocument({
              ...document,
              notes: e.target.value,
            })
          }}
          label='Notes'
          placeholder=''
          rows={4}
        />
        <Toggle
          label='Audit Check'
          setValue={document.auditRequired}
          onToggleClick={(e: any) => {
            setDocument({ ...document, auditRequired: e.target.checked })
          }}
        />
      </div>
    </RightSideModal>
  )
}

AddRequiredDocument.defaultProps = {}

export default AddRequiredDocument
