import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCandidateNotesProps {
  Id?: any
}

export const useGetCandidateNotes = () => {
  const mutate = useMutation(({ Id }: GetCandidateNotesProps) => {
    return get(`/Candidate/${Id}/Note`, {})
  })

  return mutate
}

export default useGetCandidateNotes
