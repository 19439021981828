import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface createCandidateNoteProps {
  noteDetails?: any
  Id?: any
}

export const useCreateCandidateNote = () => {
  const mutate = useMutation(
    ({ noteDetails, Id }: createCandidateNoteProps) => {
      return postAPI(`/Candidate/${Id}/Note`, {
        consultantId: noteDetails?.consultantId,
        body: noteDetails?.body,
      })
    },
  )

  return mutate
}

export default useCreateCandidateNote
