import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddRefereeProps {
  refereeData?: any
  candidateId?: any
}

export const useAddReferee = () => {
  const mutate = useMutation(
    ({ refereeData, candidateId }: AddRefereeProps) => {
      return postAPI(`/Candidate/${candidateId}/Referee`, {
        firstName: refereeData?.firstName,
        lastName: refereeData?.lastName,
        phone: refereeData?.phone,
        email: refereeData?.email,
        company: refereeData?.company,
        jobTitle: refereeData?.jobTitle,
        candidateRelation: refereeData?.relationshipToCandidate,
        candidateCompany: refereeData?.preCompanyName,
        candidatePosition: refereeData?.preCandidatePosition,
        startDate: refereeData?.preJobStartDate,
        endDate: refereeData?.preJobFinishDate,
      })
    },
  )

  return mutate
}

export default useAddReferee
