import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SimpleSelect } from '@labourhub/labour-hub-ds'
import { useGetRegPackTemplateById } from 'framework/api/methods'
import {
  setOriginalTemplateDetails,
  setTemplateDetails,
} from 'store/reducers/regPack/regPackSlice'

export interface SelectRegPackTemplateTabViewProps {
  [x: string]: any
}

export const SelectRegPackTemplateTabView: FC<
  SelectRegPackTemplateTabViewProps
> = ({
  SetSelectedTemplate,
  selectedTemplate,
  setSelectedMedicalHistoryTile,
  setSelectedQualificationTile,
  setSelectedRefereesTile,
  setTemplateId,
  templateList,
  ...restProps
}: SelectRegPackTemplateTabViewProps) => {
  const dispatch = useDispatch()
  const { originalTemplateDetails } = useSelector(
    (state: any) => state?.regPack,
  )

  /** get reg pack section by id on success */
  const onSuccessGetRegPackSectionById = (data) => {
    dispatch(setOriginalTemplateDetails(data?.templateDetails))
    dispatch(setTemplateDetails(data?.templateDetails))

    const templateSections = {
      ...data?.templateDetails,
      sectionDetails: data?.templateDetails?.sectionDetails?.map(
        (section: any) => {
          return {
            ...section,
            isSelected: true,
          }
        },
      ),
    }
    dispatch(setTemplateDetails(templateSections))
  }

  /**Fetch reg pack template by id */
  const { refetch: getRegPackTemplateDetailsById } = useGetRegPackTemplateById(
    selectedTemplate?.value,
    onSuccessGetRegPackSectionById,
  )

  useEffect(() => {
    if (
      !originalTemplateDetails ||
      (selectedTemplate?.value &&
        originalTemplateDetails?.regPackTemplateId &&
        selectedTemplate?.value !== originalTemplateDetails?.regPackTemplateId)
    ) {
      getRegPackTemplateDetailsById()
      setTemplateId(selectedTemplate?.value)
    }
  }, [selectedTemplate?.value, originalTemplateDetails])

  return (
    <div className='flex flex-col w-full' {...restProps}>
      {/** Reg pack template selection body */}
      <div className='flex flex-col'>
        <span className='font-Medium text-heading-4 text-Gray-800'>
          Select the Registration Pack Template
        </span>

        <span className='font-Regular text-small text-Gray-500 pt-1'>
          You can select pre-defined reg pack templates for each job position
        </span>

        <SimpleSelect
          label='Reg Pack Template'
          value={templateList?.filter((item: any) => {
            return item?.value === selectedTemplate?.value
          })}
          className='w-[330px] mt-8'
          onChange={(selectedItem: any) => {
            setTemplateId(selectedItem?.value)
            SetSelectedTemplate({
              ...selectedTemplate,
              label: selectedItem?.label,
              value: selectedItem?.value,
            })
            setSelectedQualificationTile(false)
            setSelectedMedicalHistoryTile(false)
            setSelectedRefereesTile(false)
          }}
          options={templateList}
        />
      </div>
    </div>
  )
}

SelectRegPackTemplateTabView.defaultProps = {}

export default SelectRegPackTemplateTabView
