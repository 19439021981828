import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface deleteVisaDocumentInterface {
  visaId?: any
  candidateId?: string
}

export const useDeleteVisaDocument = () => {
  const mutate = useMutation(
    ({ candidateId, visaId }: deleteVisaDocumentInterface) => {
      return deleteAPI(`/facade/Candidate/${candidateId}/Visa/${visaId}`)
    },
  )

  return mutate
}

export default useDeleteVisaDocument
