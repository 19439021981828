import React, { FC } from 'react'
import CN from 'classnames'

import './ModalFooter.scss'

interface ModalFooterProps {
  children?: any
  className?: string | undefined
  isCustomStyles?: boolean
}

export const ModalFooter: FC<ModalFooterProps> = ({
  children,
  className,
  isCustomStyles,
  ...restProps
}: ModalFooterProps) => {
  const ModalFooterClasses = !isCustomStyles
    ? CN('modal__footer border-t-[1px] border-[#E5E7EB]', className, {})
    : className

  return (
    <div className={ModalFooterClasses} {...restProps}>
      {children}
    </div>
  )
}

ModalFooter.defaultProps = {
  children: undefined,
  className: undefined,
  isCustomStyles: false,
}

export default ModalFooter
