/* Layouts */
import { AuthLayoutFull, AuthLayoutHalf } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import { AgencyProfile } from 'components/pages/AgencyProfile'
import { Audit } from 'components/pages/Audit'
/* Page components */
import { ForgotPassword } from 'components/pages/auth/ForgotPassword'
import { LogInForm } from 'components/pages/auth/LogIn'
import { ResetPassword } from 'components/pages/auth/ResetPassword'
import { SignUp } from 'components/pages/auth/SignUp'
import { CandidateList } from 'components/pages/candidates/CandidateList'
import { CandidateProfile } from 'components/pages/candidates/CandidateProfile'
import { ClientList } from 'components/pages/clients/ClientList'
import { ClientProfile } from 'components/pages/clients/ClientProfile'
import { Dashboard } from 'components/pages/Dashboard'
import { Error404 } from 'components/pages/Error404'
import { QuestionnaireBuilder } from 'components/pages/questionnaires/QuestionnaireBuilder'
import { QuestionnairesList } from 'components/pages/questionnaires/QuestionnairesList'
import { UserList } from 'components/pages/UserList'
import { UserGroups } from 'components/pages/users/UserGroups'
import { UserPermission } from 'components/pages/users/UserPermission'
import { UserProfile } from 'components/pages/users/UserProfile'

interface Route {
  path: string
  component?: any
  heading?: string
  subHeading?: string
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
  isBackButton?: string
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: LogInForm,
    layout: AuthLayoutHalf,
    isAuthScreen: true,
  },
  {
    path: '/auth/reset-password',
    component: ResetPassword,
    layout: AuthLayoutFull,
    isAuthScreen: true,
  },
  {
    path: '/auth/forgot-password',
    component: ForgotPassword,
    layout: AuthLayoutFull,
    isAuthScreen: true,
  },
  {
    path: '/auth/sign-up',
    component: SignUp,
    layout: AuthLayoutFull,
    isAuthScreen: true,
  },
  {
    path: '/',
    component: Dashboard,
    layout: MainLayout,
    heading: 'Dashboard',
    subHeading: 'Welcome to your dashboard',
    isSecured: true,
  },
  {
    path: '/user-profile',
    component: UserProfile,
    layout: MainLayout,
    heading: 'User profile',
    subHeading:
      'Personal info and options to manage it. You can also see a summary of your profiles.',
    isSecured: true,
  },
  {
    path: '/user-groups',
    component: UserGroups,
    layout: MainLayout,
    heading: 'User groups',
    subHeading: 'Manage your team members and their account permissions here.',
    isSecured: true,
  },
  {
    path: '/user-groups/user-list',
    component: UserList,
    layout: MainLayout,
    heading: 'User groups',
    subHeading: 'Manage your team members and their account permissions here.',
    isSecured: true,
  },
  {
    path: '/agency-profile',
    component: AgencyProfile,
    layout: MainLayout,
    heading: 'Agency Profile',
    isSecured: true,
  },
  {
    path: '/user-groups/user-list/user-permission',
    component: UserPermission,
    layout: MainLayout,
    heading: 'Permissions',
    subHeading: 'You can manage all permissions here.',
    isSecured: true,
  },
  {
    path: '/client-list',
    component: ClientList,
    layout: MainLayout,
    heading: 'Clients',
    subHeading: 'Manage your already signed clients and not signed (prospects)',
    isSecured: true,
  },
  {
    path: '/client-list/client-profile',
    component: ClientProfile,
    layout: MainLayout,
    heading: 'Client Profile',
    isBackButton: 'Back to Client List',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/candidate-list',
    component: CandidateList,
    layout: MainLayout,
    heading: 'Candidates',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/candidate-list/candidate-profile',
    component: CandidateProfile,
    layout: MainLayout,
    heading: 'Candidate Profile',
    isBackButton: 'Back to Candidate List',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/audit',
    component: Audit,
    layout: MainLayout,
    heading: 'Audit',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/questionnaires',
    component: QuestionnairesList,
    layout: MainLayout,
    heading: 'Questionnaires',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/questionnaires/build-new-questionnaire',
    component: QuestionnaireBuilder,
    layout: MainLayout,
    heading: 'Build New Questionnaire',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/questionnaires/edit-questionnaire/:questionnaireId',
    component: QuestionnaireBuilder,
    layout: MainLayout,
    heading: 'Edit Questionnaire',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '*',
    component: Error404,
    isSecured: true,
  },
]

export default routes
