import { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import {
  RightSideModal,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import {
  useAddNewLocation,
  useAddSuburb,
  useGetStateList,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { isEmail } from 'utils'

export interface AddNewAgencyLocationProps {
  buttonTitle: string
  footerAddButton?: () => void | undefined
  footerCancelButton?: () => void | undefined
  headerCloseButton?: () => void | undefined
  headerSubtitle: string
  headerTitle: string
  [x: string]: any
}

export const AddNewAgencyLocation: FC<AddNewAgencyLocationProps> = ({
  agencyProfileId,
  buttonTitle,
  countryItemList,
  footerCancelButton,
  getAgencyProfileDetails,
  headerCloseButton,
  headerSubtitle,
  headerTitle,
  notify,
  setShowAddAgencyLocationDrawer,
  showAddAgencyLocationDrawer,
  ...restProps
}: AddNewAgencyLocationProps) => {
  const [locationDetails, setLocationDetails] = useState<any>({
    locationName: '',
    streetName: '',
    suburb: '',
    state: '',
    stateCode: '',
    postCode: '',
    latitude: '',
    longitude: '',
    country: 'Australia',
    countryCode: 'AU',
    phoneNo: '',
    email: '',
  })

  const [countryItem, setCountryItem] = useState<any>({
    label: 'Australia',
    value: 'AU',
  })

  const [stateItem, setStateItem] = useState<any>()
  const [suburbItem, setSuburbItem] = useState<any>()

  const [stateList, setStateList] = useState<any>()
  const [suburbList, setSuburbList] = useState<any>()

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [isErrorField, setIsErrorField] = useState<any>({
    locationName: false,
    streetName: false,
    suburb: false,
    state: false,
    postCode: false,
    country: false,
    phoneNo: false,
    email: false,
  })

  /** After submission clearing the records. */
  const resetFieldValues = () => {
    setLocationDetails({
      locationName: '',
      streetName: '',
      suburb: '',
      state: '',
      postCode: '',
      latitude: '',
      longitude: '',
      country: 'Australia',
      countryCode: 'AU',
      phoneNo: '',
      email: '',
    })

    setStateItem('')
    setSuburbItem('')
  }

  /** front end validation for all input fields */
  const validateInputs = () => {
    const {
      locationName,
      streetName,
      suburb,
      state,
      postCode,
      country,
      phoneNo,
      email,
    } = locationDetails || {}

    setIsErrorField({
      ...isErrorField,
      locationName: locationName ? false : true,
      streetName: streetName ? false : true,
      suburb: suburb ? false : true,
      state: state ? false : true,
      postCode: postCode ? false : true,
      country: country ? false : true,
      email: isEmail(email) ? false : true,
      phoneNo: phoneNo ? false : true,
    })

    const isAllValidFields =
      isEmail(email) &&
      locationName &&
      streetName &&
      suburb &&
      state &&
      postCode &&
      country &&
      phoneNo

    return isAllValidFields
  }

  /** API Call for get state list by country code using react-query */
  const {
    mutate: getStateListByCountryCodeMutate,
    isLoading: getStateListByCountryCodeIsLoading,
  } = useGetStateList()

  /** Process get state list */
  async function getStateListByCountryCode(countryCode: any) {
    getStateListByCountryCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Error getting state list.',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for add new agency location using react-query */
  const {
    mutate: addNewAgencyLocationMutate,
    isLoading: addNewAgencyLocationIsLoading,
  } = useAddNewLocation()

  /** Process for add new agency location */
  async function addNewAgencyLocation() {
    addNewAgencyLocationMutate(
      { locationDetails, id: agencyProfileId },
      {
        onSuccess: ({ data: successData }: any) => {
          setShowAddAgencyLocationDrawer(false)
          getAgencyProfileDetails()
          notify({
            alertHeader: successData.messageTitle
              ? ''
              : 'New agency location added successfully. ',
            alertBody: '',
            status: 'Success',
          })

          resetFieldValues()
        },
        onError: () => {
          notify({
            alertHeader: 'Oops...!',
            alertBody: 'Something went wrong.',
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    getStateListByCountryCode(countryItem?.value)
  }, [countryItem])

  const handleAddLocation = () => {
    const valid = validateInputs()

    if (valid) {
      addNewAgencyLocation()
    }
  }

  useEffect(() => {
    const {
      locationName,
      streetName,
      suburb,
      state,
      country,
      postCode,
      email,
      phoneNo,
    } = locationDetails || {}
    ;(
      locationName &&
      streetName &&
      state &&
      suburb &&
      postCode &&
      country &&
      phoneNo &&
      isEmail(email)
        ? false
        : true
    )
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [locationDetails])

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: any) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          /** Backend issues: mapping state list to drop down. refused to send label and value attributes */
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onSuccess: () => {
          // notify({
          //   alertHeader: 'New suburb added',
          //   alertBody: '',
          //   status: 'Success',
          // })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(locationDetails.stateCode, actionMeta?.option?.label)
    }

    setSuburbItem(newValue)

    setLocationDetails({
      ...locationDetails,
      suburb: newValue?.label,
    })
  }

  return (
    <RightSideModal
      isActive={showAddAgencyLocationDrawer}
      className='w-full'
      headerTitle={headerTitle}
      locationDetails={locationDetails}
      setLocationDetails={setLocationDetails}
      headerSubtitle={headerSubtitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      primaryButtonTitle={buttonTitle}
      onHeaderCloseButtonClick={() => {
        resetFieldValues()
        headerCloseButton && headerCloseButton()
      }}
      onFooterAddButtonClick={handleAddLocation}
      onFooterCancelButtonClick={() => {
        resetFieldValues()
        footerCancelButton && footerCancelButton()
      }}
      {...restProps}>
      {/** Body section of the drawer modal */}
      {
        <div className='w-full px-6'>
          {addNewAgencyLocationIsLoading ||
            (getStateListByCountryCodeIsLoading && <PageLoader size='xxs' />)}
          <TextField
            className='agency-location pt-5'
            value={locationDetails.locationName}
            onChange={(e: any) => {
              setLocationDetails({
                ...locationDetails,
                locationName: e.target.value,
              })
            }}
            label='Name of this location'
            placeholder='Eg: Headquarters'
            isErrored={isErrorField.locationName === '' ? true : false}
          />

          <TextField
            className='street-name pt-5'
            value={locationDetails.streetName}
            onChange={(e: any) => {
              setLocationDetails({
                ...locationDetails,
                streetName: e.target.value,
              })
            }}
            label='Street Name'
            placeholder=''
            isErrored={isErrorField.streetName === '' ? true : false}
          />

          <SimpleSelect
            className='state pt-5'
            value={stateItem}
            onChange={(selectedItem: any) => {
              if (locationDetails?.stateCode !== selectedItem?.value) {
                setStateItem(selectedItem)
                setLocationDetails({
                  ...locationDetails,
                  state: selectedItem.label,
                  stateCode: selectedItem.value,
                  suburb: '',
                })
                setSuburbItem('')
                getSuburbsByStateCode(selectedItem.value)
              }
            }}
            label='State'
            options={stateList}
            placeholder='Select State'
            isErrored={isErrorField.suburb === '' ? true : false}
          />

          <div className='flex w-full mt-5 gap-5'>
            <CreatableInputSelect
              label='Suburb'
              className='w-2/3'
              value={suburbItem}
              options={suburbList}
              onChange={handleSuburbChange}
              isDisabled={!locationDetails.stateCode}
              isErrored={isErrorField.state === '' ? true : false}
            />

            <TextField
              className='post-code w-1/3'
              value={locationDetails.postCode}
              onChange={(e: any) => {
                setLocationDetails({
                  ...locationDetails,
                  postCode: e.target.value,
                })
              }}
              label='Post Code'
              placeholder=''
              isErrored={isErrorField.postCode === '' ? true : false}
            />
          </div>

          <TextField
            className='latitude pt-5'
            value={locationDetails.latitude}
            onChange={(e: any) => {
              const re = /^[-+0-9.]+$/

              // if value is not blank, then test the regex
              if (e.target.value === '' || re.test(e.target.value)) {
                setLocationDetails({
                  ...locationDetails,
                  latitude: e.target.value,
                })
              }
            }}
            label='Latitude (optional)'
            placeholder=''
            isErrored={isErrorField.latitude === '' ? true : false}
          />

          <TextField
            className='longitude pt-5'
            value={locationDetails.longitude}
            onChange={(e: any) => {
              const re = /^[-+0-9.]+$/

              // if value is not blank, then test the regex
              if (e.target.value === '' || re.test(e.target.value)) {
                setLocationDetails({
                  ...locationDetails,
                  longitude: e.target.value,
                })
              }
            }}
            label='Longitude (optional)'
            placeholder=''
            isErrored={isErrorField.longitude === '' ? true : false}
          />

          <SimpleSelect
            className='country pt-5'
            value={countryItem}
            onChange={(selectedItem: any) => {
              if (locationDetails?.countryCode !== selectedItem?.value) {
                setCountryItem(selectedItem)
                setStateItem('')
                setSuburbItem('')
                setLocationDetails({
                  ...locationDetails,
                  country: selectedItem.label,
                  countryCode: selectedItem.value,
                  state: '',
                  stateCode: '',
                  suburb: '',
                })
              }
            }}
            label='Country'
            options={countryItemList}
            placeholder='Country'
            isErrored={isErrorField.country === '' ? true : false}
          />

          <PhoneInputField
            className='phone-number pt-6'
            value={locationDetails.phoneNo}
            onChange={(number: any) => {
              setLocationDetails({
                ...locationDetails,
                phoneNo: number,
              })
            }}
            label='Phone number'
            isErrored={isErrorField.phoneNo === '' ? true : false}
          />

          <TextField
            className='email pt-5 pb-3'
            value={locationDetails.email}
            onChange={(e: any) => {
              setLocationDetails({
                ...locationDetails,
                email: e.target.value,
              })
            }}
            label='Email'
            placeholder=''
            isErrored={isErrorField.email === '' ? true : false}
          />
        </div>
      }
    </RightSideModal>
  )
}

AddNewAgencyLocation.defaultProps = {
  buttonTitle: undefined,
  footerAddButton: undefined,
  footerCancelButton: undefined,
  headerCloseButton: undefined,
  headerSubtitle: undefined,
  headerTitle: undefined,
}

export default AddNewAgencyLocation
