import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export interface GetPoliceCheckByIdType {
  policeCheckId?: string | null
  candidateId?: string | null
  statutoryUrl?: string | null
  policeDocumentId?: string | null
  isPoliceClearance?: boolean
  jobTitle?: string | null
  isCriminalAustralia?: boolean
  isCriminalOther?: boolean
  categoryId?: string | null
  category?: string | null
  typeId?: string | null
  type?: string | null
  createdOn?: any
  updatedOn?: any
  status?: string | null
  auditRequired?: boolean
  isArchived?: boolean
  attachmentUrls?: string | null
  expiryOn?: any
}

export const useGetPoliceCheckById = (
  candidateId: string,
  documentId: string,
) => {
  return useQuery(
    ['GET_POLICE_CHECK_BY_ID'],
    async () => {
      const res = await get(
        `/facade/Candidate/${candidateId}/PoliceCheck/${documentId}`,
      )
      return res.data.policeChecks as GetPoliceCheckByIdType
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error getting document details',
          status: 'Error',
        })
      },
    },
  )
}
