/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import docxIcon from 'assets/images/docxIcon.svg'
import CN from 'classnames'
import { saveAs } from 'file-saver'

import { PageLoader, SimpleSlider } from 'components/atoms'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export interface DocumentsPreviewSectionProps {
  [x: string]: any
  isFooterShow?: boolean
}

export const DocumentsPreviewSection: FC<DocumentsPreviewSectionProps> = ({
  docList,
  getDocumentSignedUrlsByIdIsLoading,
  isFooterShow,
}: DocumentsPreviewSectionProps) => {
  const [sliderData, setSliderData] = useState<any>()

  /** loading state*/
  const [isLoadState, setIsLoadState] = useState(true)

  /** current slide count state*/
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<any>(0)

  /** creating new plugin instance*/
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  useEffect(() => {
    const sliderContent = docList.map((item: any, idx: any) => {
      return {
        id: idx,
        sliderContent:
          item.type == 'jpg' || item.type == 'png' || item.type == 'jpeg' ? (
            <div className='flex h-full w-full justify-center items-center select-none'>
              <img
                src={item.attachmentSignedUrl}
                alt='img'
                className='h-full object-contain '
              />
            </div>
          ) : item.type == 'pdf' ? (
            <div
              className='w-full h-full flex justify-center items-center text-center select-none'
              key={selectedSlideIndex}>
              <Worker
                workerUrl={
                  'https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js'
                }>
                <Viewer
                  fileUrl={item.attachmentSignedUrl}
                  plugins={[defaultLayoutPluginInstance]}></Viewer>
              </Worker>
            </div>
          ) : item.type == 'doc' || item.type == 'docx' ? (
            <div className='h-full flex flex-col w-full justify-center items-center px-[138px] select-none'>
              <img src={docxIcon} alt='docx' />

              <span className='text-heading-4 text-Gray-800 font-Medium pt-3'>
                You are viewing a MS Word document!
              </span>

              <span className='text-Gray-600 font-Regular pt-2 text-center'>
                Due to technical limitations you can only download this file.
                Download and open this with MS word application.
              </span>

              <Button
                iconLeft={<i className='ri-download-2-line text-[20px]' />}
                className='mt-3'
                onClick={() => {
                  saveAs(docList[selectedSlideIndex]?.attachmentSignedUrl)
                }}
                target='_blank'
                download>
                Download File
              </Button>
            </div>
          ) : null,
      }
    })

    setSliderData(sliderContent)
  }, [docList, selectedSlideIndex])

  /** handle loading state */
  useEffect(() => {
    setIsLoadState(true)

    !getDocumentSignedUrlsByIdIsLoading && setIsLoadState(false)
  }, [getDocumentSignedUrlsByIdIsLoading])

  return (
    <div className='flex min-h-[35px] h-full w-full overflow-y-auto justify-center pt-6 select-none'>
      {isLoadState && <PageLoader size='xxs' />}
      {docList?.length > 0 ? (
        <SimpleSlider
          sliderData={sliderData}
          contentClassName={CN('flex', {
            'h-[54vh]': isFooterShow,
            'h-[60vh]': !isFooterShow,
          })}
          setSelectedSlideIndex={setSelectedSlideIndex}
        />
      ) : (
        <div
          className={CN(
            'flex w-full justify-center items-center text-heading-4 text-Gray-500 font-Medium p-20',
            {
              'h-[62vh]': isFooterShow,
              'h-[70vh]': !isFooterShow,
            },
          )}>
          {!isLoadState && `No Preview Available`}
        </div>
      )}
    </div>
  )
}

DocumentsPreviewSection.defaultProps = {
  isViewHistoryButton: false,
  isFooterShow: false,
}

export default DocumentsPreviewSection
