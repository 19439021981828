import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getWorkRights = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/facade/Candidate/${reqBody.candidateId}/WorkRights`)
  return res.data
}

export const useGetWorkRights = ({ ...reqBody }) => {
  return useQuery(['WorkRights', reqBody], getWorkRights, {
    enabled: true,
    refetchOnWindowFocus: false,
  })
}
