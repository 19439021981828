import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

import { RegPackTemplateListTypes } from './useGetRegPackTemplateList'

export const useGetRegPackTemplateById = (Id: string | null, onSuccess) => {
  return useQuery(
    ['GET_REG_PACK_TEMPLATE_BY_ID', Id],
    async () => {
      const data = await get(`/RegPackTemplate/${Id}`)
      return (data?.data as RegPackTemplateListTypes) || []
    },
    {
      onError: () => {
        Toast({
          alertHeader:
            'Error getting selected registration pack template data.',
          status: 'Error',
        })
      },
      onSuccess,
      enabled: false,
    },
  )
}
