import { dateToReadableString } from 'utils/dateToReadableString'

export const timeDifference = (latest: any, old: any) => {
  const dateNew: any = new Date(latest)
  const dateOld: any = new Date(old)

  let seconds = Math.floor((dateNew - dateOld) / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  hours = hours - days * 24
  minutes = minutes - days * 24 * 60 - hours * 60
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

  if (days > 3) {
    return dateToReadableString(old)
  } else if (days > 0 && days <= 3) {
    return `${days} days ago`
  } else if (hours > 0) {
    return `${hours} hours ago`
  } else if (minutes > 0) {
    return `${minutes} minutes ago`
  } else {
    return `${seconds} seconds ago`
  }
}
//   return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
// }

export default timeDifference
