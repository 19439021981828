import React, { FC } from 'react'
import CN from 'classnames'

import { AuditFailedList } from './AuditFailedList'

export interface AuditFailedTabViewProps {
  [x: string]: any
}

export const AuditFailedTabView: FC<AuditFailedTabViewProps> = ({
  className,
  documentCategoryList,
  documentTypeList,
  auditorsList,
  ...restProps
}: AuditFailedTabViewProps) => {
  const ActivatedTabViewClasses = CN(
    `auditFailed-tab-view flex flex-col`,
    className,
  )

  return (
    <div className={ActivatedTabViewClasses} {...restProps}>
      <span className='text-Gray-800 font-Medium'>Audit Failed</span>

      <span className='font-Regular text-small text-Gray-800 mt-1'>
        Candidates who failed the audit check are listed below. Only admins can
        change the audit status and activate them.
      </span>

      <div className='pt-8'>
        <AuditFailedList
          documentCategoryList={documentCategoryList}
          documentTypeList={documentTypeList}
          auditorsList={auditorsList}
        />
      </div>
    </div>
  )
}

AuditFailedTabView.defaultProps = {}

export default AuditFailedTabView
