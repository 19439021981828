/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from 'react'
import clientLogo from 'assets/images/clientDefaultLogo.svg'
import profileLogo from 'assets/images/Profile-Icon.png'
import CN from 'classnames'

export interface ProfileAvatarProps {
  className?: string | undefined
  imageContainerClassName?: string | undefined
  imageSource?: string | undefined
  isAnAgency?: boolean | undefined
  isProfilePictureAvailable?: boolean | undefined
  isStatusDisable?: boolean | undefined
  onAddClick?: () => void
  onDeleteClick?: () => void
  onEditClick?: () => void
  [x: string]: any
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  className,
  imageContainerClassName,
  imageSource,
  isAnAgency,
  isProfilePictureAvailable,
  isStatusDisable,
  onAddClick,
  onDeleteClick,
  onEditClick,
  ...restProps
}: ProfileAvatarProps) => {
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const ProfileAvatarClasses = CN(`profile-avatar`, className)

  const ImageContainerClasses = CN(
    `flex items-center justify-center rounded-full overflow-hidden border border-Gray-200`,
    imageContainerClassName,
    {
      'bg-Gray-100 object-contain': imageSource === undefined,
      'object-fill': imageSource !== undefined,
      'outline outline-[3px] outline-offset-4 outline-Red-600': isStatusDisable,
    },
  )

  return (
    <div className={ProfileAvatarClasses} {...restProps}>
      <div className='flex flex-col items-center relative'>
        <div className={ImageContainerClasses}>
          {imageSource === undefined ? (
            <img
              src={isAnAgency ? clientLogo : profileLogo}
              alt=''
              className='object-contain pt-8'
            />
          ) : (
            <img
              src={imageSource}
              alt=''
              className='flex w-full h-auto object-contain rounded-full'
            />
          )}
        </div>

        {/** add new button for upload profile picture */}
        {!isProfilePictureAvailable && (
          <div className='absolute bottom-0 left-2/3'>
            <div className='bg-white rounded-full shadow-md overflow-hidden'>
              <i
                className='ri-add-fill flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-Cobalt-600 hover:bg-Gray-50 hover:text-Cobalt-700 cursor-pointer'
                onClick={onAddClick}
              />
            </div>
          </div>
        )}

        {/** add new button for upload profile picture */}
        {isProfilePictureAvailable && (
          <>
            <div className='absolute bottom-0 left-2/3'>
              <div className='bg-white rounded-full shadow-md overflow-hidden'>
                <i
                  className='ri-pencil-line flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-Cobalt-600 hover:bg-Gray-50 hover:text-Cobalt-700 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(!showDropDownMenu)
                  }}
                />
              </div>
            </div>
            {showDropDownMenu && (
              <div className='flex absolute w-[160px] flex-col bottom-[-92px] justify-end right-[-10px] bg-white d py-1 rounded-md shadow z-10'>
                <span
                  className='text-Gray-800 px-3 py-2 hover:bg-Gray-100 active:bg-Gray-200 border-b border-b-Gray-100 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(false)
                    onEditClick && onEditClick()
                  }}>
                  Change photo
                </span>
                <span
                  className='text-Red-500 px-3 py-2 hover:bg-Gray-100 active:bg-Gray-200 cursor-pointer'
                  onClick={() => {
                    setShowDropDownMenu(false)
                    onDeleteClick && onDeleteClick()
                  }}>
                  Remove photo
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ProfileAvatar.defaultProps = {
  imageContainerClassName: 'w-[150px] h-[150px]',
  imageSource: undefined,
  isAnAgency: false,
  isProfilePictureAvailable: true,
  isStatusDisable: false,
  onAddClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default ProfileAvatar
