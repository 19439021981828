import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getDocumentHistoryByIdInterface {
  documentId?: any
}

export const useGetDocumentHistoryById = () => {
  const mutate = useMutation(
    ({ documentId }: getDocumentHistoryByIdInterface) => {
      return get(`/Document/${documentId}/Histories`, {})
    },
  )

  return mutate
}

export default useGetDocumentHistoryById
