import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getUserProfileList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `UserProfile/List?Take=${reqBody.take}&Skip=${reqBody.skip}&RoleName=${reqBody.roleName}&Search=${reqBody.search}&sortColumnName=${reqBody.sortColumnName}&sortDirection=${reqBody.sortDirection}`,
  )
  return res.data
}

export const useGetUserProfileList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Users', reqBody], getUserProfileList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
