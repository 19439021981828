import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCandidateEducationListProps {
  Id?: any
  categoryName?: any
  isArchived?: any
}

export const useGetCandidateEducationListById = () => {
  const mutate = useMutation(
    ({ Id, categoryName, isArchived }: GetCandidateEducationListProps) => {
      return get(
        `/Document/Category?Category=${categoryName}&CandidateId=${Id}&IsArchived=${isArchived}`,
        {},
      )
    },
  )

  return mutate
}

export default useGetCandidateEducationListById
