import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface useDeleteVaccinationOrMedicalByIdInterface {
  candidateId?: string
  Id?: string
  docType?: string
}

export const useDeleteVaccinationOrMedicalById = () => {
  const mutate = useMutation(
    ({
      candidateId,
      Id,
      docType,
    }: useDeleteVaccinationOrMedicalByIdInterface) => {
      return deleteAPI(
        `/facade/Candidate/${candidateId}/${
          docType === 'Vaccinations' ? `Vaccination` : `MedicalCondition`
        }/${Id}`,
      )
    },
  )

  return mutate
}

export default useDeleteVaccinationOrMedicalById
