/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  selectedTemplateId: null,
  originalTemplateDetails: null,
  templateDetails: null,
}

const regPackSlice = createSlice({
  name: 'regPack',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false),
        (state.selectedTemplateId = null),
        (state.originalTemplateDetails = null),
        (state.templateDetails = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setSelectedTemplateIdState(state, { payload }) {
      state.selectedTemplateId = payload
    },

    setOriginalTemplateDetailsState(state, { payload }) {
      state.originalTemplateDetails = payload
    },

    setTemplateDetailsState(state, { payload }) {
      state.templateDetails = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setSelectedTemplateIdState,
  setOriginalTemplateDetailsState,
  setTemplateDetailsState,
} = regPackSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setSelectedTemplateId =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setSelectedTemplateIdState(payload))
  }

export const setOriginalTemplateDetails =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setOriginalTemplateDetailsState(payload))
  }

export const setTemplateDetails =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setTemplateDetailsState(payload))
  }

export default regPackSlice.reducer
