import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Avatar, Button, TextField } from '@labourhub/labour-hub-ds'
import LogoMini from 'assets/images/logo-mini.svg'
import CN from 'classnames'

import { HeaderMoreButtonDropDown, UserDropDown } from 'components/atoms'

export interface HeaderProps {
  className?: string | undefined
  onMessageClick?: () => void
  onNotificationClick?: () => void
  [x: string]: any
}

export const Header: FC<HeaderProps> = ({
  className,
  onMessageClick,
  onNotificationClick,
  ...restProps
}: HeaderProps) => {
  const { clickValue } = useSelector((state: any) => state.layout)
  const { headerBarDetails, isHeaderBarDetailsUpdated } = useSelector(
    (state: any) => state.user,
  )

  const [profileData, setProfileData] = useState<any>({
    profileAvatar: null,
    agencyLogo: null,
    userName: '',
    userRole: '',
  })

  const [showUserDropDown, setShowUserDropDown] = useState(false)
  const [showHeaderMoreButtonDropDown, setShowHeaderMoreButtonDropDown] =
    useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setShowUserDropDown(false)
    setShowHeaderMoreButtonDropDown(false)
  }, [clickValue])

  /** update toolbar user and agency details when update */
  useEffect(() => {
    const { secondaryLogoUrl, userProfile } = headerBarDetails || {}
    setProfileData({
      ...setProfileData,
      profileAvatar: userProfile?.avatarUrl,
      agencyLogo: secondaryLogoUrl,
      userName: userProfile?.firstName + ` ` + userProfile?.lastName,
      userRole: userProfile?.roleName ? userProfile?.roleName : '',
    })
  }, [headerBarDetails, isHeaderBarDetailsUpdated])

  const HeaderClasses = CN(
    'flex justify-between items-center w-full border-b-[1px] border-[#E5E7EB] py-[10px] relative',
    className,
    {},
  )

  return (
    <div className={HeaderClasses} {...restProps}>
      <div className='flex'>
        {/** LabourHub Logo */}
        <img
          src={LogoMini}
          alt=''
          className='flex justify-center items-center w-[72px] px-4'
        />

        {/** Input Filed */}
        <div className='pl-4'>
          <TextField
            iconBefore={<i className='ri-search-line' />}
            placeholder='Global Search'
            className='w-[320px] bg-Gray-100'
          />
        </div>
      </div>

      <div className='flex items-center pr-4 divide-x divide-Gray-200'>
        {/** Agency Logo */}
        {profileData?.agencyLogo && (
          <img
            src={profileData?.agencyLogo}
            alt=''
            className='mr-5 h-[34px] rounded-lg border border-Gray-200'
          />
        )}

        <div className='flex gap-5 items-center'>
          {/** Message Icon */}
          <div className='message-icon pl-5'>
            <Button
              icon={
                <>
                  <i
                    className='ri-message-3-line text-heading-5 text-Gray-600'
                    data-tip='Messages'
                    data-for='Messages'
                  />

                  <ReactTooltip
                    place='bottom'
                    id='Messages'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </>
              }
              textTheme='blue'
              theme='link'
              isIconOnly
              onClick={() => {
                onMessageClick
              }}
            />
          </div>

          <div className='notification-icon'>
            {/** Notification  Icon */}
            <Button
              icon={
                <>
                  <i
                    className='ri-notification-2-line text-heading-5 text-Gray-600'
                    data-tip='Notification'
                    data-for='Notification'
                  />

                  <ReactTooltip
                    place='bottom'
                    id='Notification'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </>
              }
              textTheme='blue'
              theme='link'
              isIconOnly
              onClick={() => {
                onNotificationClick
              }}
            />
          </div>

          {/** Profile Picture */}
          <div className=''>
            <Avatar
              isHeader={false}
              imageURL={
                profileData?.profileAvatar
                  ? profileData?.profileAvatar
                  : undefined
              }
              className='cursor-pointer'
              size='sm'
              title={profileData?.userName}
              onClick={() => {
                setShowUserDropDown(!showUserDropDown)
                setShowHeaderMoreButtonDropDown(false)
              }}
            />
            {showUserDropDown && (
              <div className='absolute top-[50px] right-0'>
                <UserDropDown
                  onMyAccountClick={() => {
                    navigate('/user-profile')
                    setShowUserDropDown(false)
                  }}
                  imageUrl={
                    profileData?.profileAvatar
                      ? profileData?.profileAvatar
                      : undefined
                  }
                  userName={profileData?.userName}
                  jobRole={profileData?.userRole}
                  onLogoutClick={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('refreshToken')
                    navigate('/auth/login')
                    setShowUserDropDown(false)
                  }}
                />
              </div>
            )}
          </div>

          {/** header more icon */}
          <div className='moreButton'>
            <Button
              icon={
                <>
                  <i
                    className='ri-more-2-line text-heading-5 text-Gray-600'
                    data-tip='More Option'
                    data-for='More Option'
                  />

                  <ReactTooltip
                    place='left'
                    id='More Option'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </>
              }
              textTheme='blue'
              theme='link'
              isIconOnly
              onClick={() => {
                setShowHeaderMoreButtonDropDown(!showHeaderMoreButtonDropDown)
                setShowUserDropDown(false)
              }}
            />

            {/** header more icon drop down */}
            {showHeaderMoreButtonDropDown && (
              <div className='absolute top-[50px] right-[0]'>
                <HeaderMoreButtonDropDown
                  onAgencyProfileClick={() => {
                    navigate('/agency-profile')
                    setShowHeaderMoreButtonDropDown(false)
                  }}
                  onUserGroupClick={() => {
                    navigate('/user-groups')
                    setShowHeaderMoreButtonDropDown(false)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Header.defaultProps = {
  className: undefined,
  onMessageClick: undefined,
  onNotificationClick: undefined,
}

export default Header
