import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getClientLocationsById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/facade/Client/${reqBody.Id}/Locations`)
  return res
}

export const useGetClientLocationsById = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['ClientLocation', reqBody], getClientLocationsById, {
    enabled: false,
    retry: 2,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
