export const taxInformationQuizList = [
  {
    quiz: 'On what basis are you paid?',
    options: [
      'Full-time employment',
      'Part-time employment',
      'Superannuation or annuity income stream',
      'Casual employment',
      'Labour Hire',
    ],
    formValueKey: 'onPaid',
  },
  {
    quiz: 'Tax Residency - Are you a resident for tax purposes?',
    options: [
      'Australian resident for tax purposes',
      'A foreign resident for tax purposes',
      'A working holiday maker',
    ],
    formValueKey: 'taxResidencyTaxPurposes',
  },
  {
    quiz: 'Tax Threshold - Do you want to claim the tax-free threshold from this payer?',
    options: ['Yes', 'No'],
    formValueKey: 'taxThresholdClaim',
  },
  {
    quiz: 'Tax Threshold - Do you have a Higher Education Loan Program (HELP), VET Student Loan (VSL), Financial Supplement (FS), Student Start-up Loan (SSL) or Trade Support Loan (TSL) debt?',
    options: ['Yes', 'No'],
    formValueKey: 'taxThresholdHigherEducation',
  },
]
