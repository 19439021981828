import { FC, useEffect, useState } from 'react'
import { Modal } from '@labourhub/labour-hub-ds'
import { UiSchema } from '@rjsf/utils'
import { useGetDynamicForm } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'

import { FormBuilder } from '../FormBuilder'

export interface QuestionnairePreviewModalProps {
  [x: string]: any
}

export const QuestionnairePreviewModal: FC<QuestionnairePreviewModalProps> = ({
  isActive,
  modalClose,
  templateId,
  templateName,
}: QuestionnairePreviewModalProps) => {
  /** get dynamic form */
  const {
    data: dynamicForm,
    refetch: getDynamicForm,
    isLoading: getDynamicFormIsLoading,
  } = useGetDynamicForm(templateId)

  /** call get dynamic form when template id updated */
  useEffect(() => {
    templateId && isActive && getDynamicForm()
  }, [isActive, templateId])

  return (
    <Modal
      headerTitle={`Preview -${templateName}`}
      customWidth='70%'
      isActive={isActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={modalClose}
      onHeaderCloseButtonClick={modalClose}
      onOverlayClick={modalClose}
      secondaryButtonTitle='Close'>
      {getDynamicFormIsLoading && <PageLoader size='xxs' />}

      <div className='flex flex-col w-full justify-start p-5 min-h-[30vh] max-h-[80vh] overflow-y-auto'>
        {/* form builder */}
        {dynamicForm && (
          <FormBuilder
            schema={JSON.parse(dynamicForm.jsonSchema)}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
          />
        )}
      </div>
    </Modal>
  )
}

QuestionnairePreviewModal.defaultProps = {}

export default QuestionnairePreviewModal
