/* eslint-disable */
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable,
  useSortBy,
  useMountedLayoutEffect,
} from 'react-table'
import { UITableTDCell, UITableTHCell } from './UITableParts'
import { Button, Checkbox } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import './UITable.scss'
import { PageLoader } from 'components/atoms'
import EmptyTable from 'assets/images/emptyTable.svg'

export interface UITableProps {
  onChangePage?: any
  defaultSortBy?: any
  allowRowSelection?: boolean
  isPaginated?: boolean
  isFetching?: boolean
  selectedRowsIds?: any
  [x: string]: any
}
export const UITable: FC<UITableProps> = ({
  className,
  columns: userColumns,
  data,
  isLoading,
  isFetching,
  handleSortByChange,
  isManualSortBy,
  isSorted,
  onChangeSelectedRowsId,
  selectedRowsIds,
  allowRowSelection,
  defaultSortBy,
  isPaginated,
  disableInnerLoading,
  disableInnerNoItemFound,
  forceSelectedRowIndexes,
  hiddenColumns,
  isManualSort,
  hasFooter,
  paginationVariables,
  setPaginationVariables,
  totalRowCount,
  onRowSelect,
  pageNumber,
  setPageNumber,
  hasCheckBox,
  isExpandable,
  isActiveRowHighlights,
  isHeader,
  isFilterEnabled,
  filterExtraButton,
  filterContent,
  fixedHighlightBody,
  ...restProps
}: UITableProps) => {
  const UITableClasses = CN(`ui-table bg-white`, className)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    pageOptions,
    setHiddenColumns,
    toggleRowSelected,
    toggleAllRowsSelected,
    state: { pageIndex, selectedRowIds },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: ['editCostCentres'],
        selectedRowIds: selectedRowsIds,
      },
      // defaultCanSort: true,
      manualSortBy: isManualSortBy,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      allowRowSelection &&
        hooks.visibleColumns.push((cols) => [
          {
            id: 'selection',
            customWidth: '44px',
            isSortable: false,
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <Checkbox
                helperText=''
                labelText=''
                {...getToggleAllPageRowsSelectedProps()}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                helperText=''
                labelText=''
                {...row.getToggleRowSelectedProps()}
              />
            ),
          },
          ...cols,
        ])
    },
  )
  const filterRef = useRef<HTMLDivElement>(null)
  const [selectedRows, setSelectedRows] = React.useState<any[]>([])

  React.useEffect(() => {
    setHiddenColumns(
      userColumns
        .filter((column: any) => !column.isVisible)
        .map((column: any) => column.accessor),
    )
  }, [setHiddenColumns, userColumns])

  useEffect(() => {
    data &&
      data.length > 0 &&
      forceSelectedRowIndexes &&
      forceSelectedRowIndexes.length > 0 &&
      forceSelectedRowIndexes.map((idx: any) => {
        toggleRowSelected(idx.toString(), true)
      })
  }, [forceSelectedRowIndexes, data])

  /* Pass selected rows to outside */
  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      const filterOriginals = selectedFlatRows?.map((d) => d.original)
      onRowSelect && onRowSelect(filterOriginals)
    } else {
      onRowSelect && onRowSelect([])
    }
  }, [selectedFlatRows])

  //const [pageNumber, setPageNumber] = useState(1)

  const [showFilters, setShowFilters] = useState(false)
  const [pageNumberVariables, setPageNumberVariables] = useState({
    start:
      Number(paginationVariables.take) * Number(pageNumber) -
      Number(paginationVariables.take) +
      1,
    end:
      Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
        ? totalRowCount
        : Number(paginationVariables.take) * Number(pageNumber),
  })

  /** Pagination Functions */
  useEffect(() => {
    setPageNumberVariables({
      ...pageNumberVariables,
      start:
        Number(paginationVariables.take) * Number(pageNumber) -
        Number(paginationVariables.take) +
        1,
      end:
        Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(paginationVariables.take) * Number(pageNumber),
    })
  }, [totalRowCount, pageNumber, paginationVariables])

  useEffect(() => {
    paginationVariables.search && setPageNumber(1)
  }, [paginationVariables.search])

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(paginationVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  // if (data.length === 0 && !isLoading) return <PlaceHolder />

  const PlaceholderComponent = () => (
    <div className='flex items-center justify-center flex-col'>
      <img src={EmptyTable} alt='empty-table' className='h-[122px]' />

      <div className='flex flex-col items-center pt-2'>
        <span className='text-heading-4 text-Gray-800 font-Medium'>
          No Results Found
        </span>

        <span className='font-Regular text-Gray-600'>
          We’re sorry, we could not find any results for you.
        </span>
      </div>
    </div>
  )

  return (
    <div
      className={CN(`h-auto ui-table__wrapper`, {
        'bg-white': data?.length > 0,
      })}>
      <div className={CN(UITableClasses, 'flex')} {...restProps}>
        <table {...getTableProps()}>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              background: '#F8FBFD',
              zIndex: 1,
            }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className=''>
                {headerGroup.headers.map((column: any, idx: any) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        if (column.id !== 'selection' && column.isSortable) {
                          column.toggleSortBy()
                          toggleAllRowsSelected(false)
                          let sort
                          {
                            column.isSorted
                              ? column.isSortedDesc
                                ? (sort = '')
                                : (sort = 'desc')
                              : (sort = 'asc')
                          }
                          isSorted &&
                            isManualSortBy &&
                            setPaginationVariables({
                              ...paginationVariables,
                              sortColumnName:
                                sort === '' ? '' : column.forSorting,
                              sortDirection: sort,
                            })
                        }
                      }}
                      className={CN({
                        'cursor-pointer select-none': column.isSortable,
                      })}
                      width={column.customWidth}
                      style={{ width: column?.customWidth }}>
                      <UITableTHCell
                        className={column.commonClassName}
                        icon={
                          column.isSortable
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? 'ri-arrow-down-s-line'
                                : 'ri-arrow-up-s-line'
                              : 'ri-arrow-up-down-line'
                            : ''
                        }
                        columnId={column.id}
                        headerClassName={column.headerClassName}
                        arrayPosition={idx}
                        arrayLength={headerGroup?.headers?.length}
                        isSorted={column?.isSorted}
                        hasFilter={column.hasFilter}>
                        {column.render('Header')}
                        {column.hasFilter && (
                          <div ref={filterRef}>{column.dropdownFilter}</div>
                        )}
                      </UITableTHCell>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          {(isFetching || isLoading) && !disableInnerLoading && (
            <div
              className={CN(
                'absolute top-[45px] left-0 flex items-center justify-center bg-Gray-500 bg-opacity-20 w-full h-[calc(100%-100px)] cursor-not-allowed z-[3]',
                {
                  'h-[calc(100%-100px)]': isPaginated,
                  'h-[calc(100%-45px)]': !isPaginated,
                },
              )}>
              <PageLoader size='xxs' className='bg-opacity-100' />
            </div>
          )}
          {data.length === 0 && !disableInnerNoItemFound && !isLoading && (
            <div
              className={CN(
                'absolute top-[45px] left-0 flex items-center justify-center bg-white w-full h-[calc(100%-106px)] z-[2]',
                {
                  'h-[calc(100%-106px)]': isPaginated,
                  'h-[calc(100%-45px)]': !isPaginated,
                },
              )}>
              <PlaceholderComponent />
            </div>
          )}
          <tbody {...getTableBodyProps()} className='relative'>
            {rows.map((row: any, i) => {
              prepareRow(row)
              return (
                <tr
                  key={i}
                  className={CN('border-b border-Gray-100 hover:bg-Cobalt-50', {
                    'bg-white': row?.depth === 0,
                    'bg-Gray-300': row?.depth === 1,
                    'bg-Gray-100': row?.depth === 2,
                  })}
                  {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        className={CN('relative', {
                          'bg-Y-200': Object.keys(selectedRowIds).includes(
                            i.toString(),
                          ),
                        })}
                        {...cell.getCellProps()}>
                        {UITableTDCell(cell)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {hasFooter && (
        <div className='absolute bottom-0 left-0 bg-white flex items-center justify-between w-full rounded-b-lg px-8 py-[9px] border-t border-Gray-200'>
          <div className='flex items-center w-1/2'>
            <span className='text-Gray-800 font-Regular'>Items per page</span>
            <div className='border border-Gray-300 rounded-md py-[5px] pr-[10px] ml-2'>
              <select
                className='outline-none px-2'
                value={paginationVariables?.take || 10}
                onChange={(e) => {
                  setPageNumber(1)
                  setPaginationVariables({
                    ...paginationVariables,
                    take: e.target.value,
                  })
                }}>
                {[10, 20, 30, 50].map((pageSizeValue) => (
                  <option key={pageSizeValue} value={pageSizeValue}>
                    {pageSizeValue}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex items-center'>
            <Button
              size='sm'
              isIconOnly
              theme='white'
              isDisabled={pageNumber <= 1}
              onClick={() => {
                setPageNumber(1)
              }}
              icon={
                <i className='ri-arrow-left-s-fill text-Gray-500 text-heading-4'></i>
              }></Button>
            <span className='mx-3'>
              {pageNumberVariables.start} - {pageNumberVariables.end} of{' '}
              {totalRowCount}
            </span>
            <Button
              className='mr-3'
              size='sm'
              isIconOnly
              theme='white'
              isDisabled={pageNumber <= 1}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
              icon={
                <i className='ri-arrow-left-s-line text-Gray-500 text-heading-4'></i>
              }></Button>
            <Button
              size='sm'
              isIconOnly
              theme='white'
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              isDisabled={
                Number(pageNumber) * Number(paginationVariables.take) >=
                Number(totalRowCount)
              }
              icon={
                <i className='ri-arrow-right-s-line text-Gray-500 text-heading-4'></i>
              }></Button>
          </div>
        </div>
      )}
    </div>
  )
}
UITable.defaultProps = {
  isManualSortBy: false,
  handleSortByChange: undefined,
  isPaginated: true,
  isSorted: true,
  disableInnerLoading: false,
  disableInnerNoItemFound: false,
  selectedRowsIds: {},
  isManualSort: false,
  hasFooter: true,
  hasCheckBox: true,
  isExpandable: false,
  isActiveRowHighlights: false,
  isHeader: false,
  isFilterEnabled: false,
  filterExtraButton: undefined,
  filterContent: undefined,
  fixedHighlightBody: false,
}
export default UITable
