import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddMedicalConditionInterface {
  candidateId: string
  documentDetails: any
  attachments: any
}

export const useAddMedicalCondition = () => {
  const mutate = useMutation(
    ({
      candidateId,
      documentDetails,
      attachments,
    }: AddMedicalConditionInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Title', documentDetails?.title)
      bodyFormData.append('CategoryId', documentDetails?.categoryId)
      bodyFormData.append('TypeId', documentDetails?.typeId)
      bodyFormData.append('DateOfIssue', documentDetails?.dateOfIssue)

      documentDetails?.expiryDate &&
        bodyFormData.append('ExpiryOn', documentDetails?.expiryDate)
      documentDetails?.dueDate &&
        bodyFormData.append('DueOn', documentDetails?.dueDate)

      bodyFormData.append('Notes', documentDetails?.notes)
      bodyFormData.append(
        'isWorkingAffected',
        documentDetails?.isWorkingAffected,
      )
      bodyFormData.append('AuditRequired', documentDetails?.auditCheck)

      attachments?.map((item) => {
        bodyFormData.append('Attachments', item)
      })

      return postAPIwithFormData(
        `/facade/Candidate/${candidateId}/MedicalCondition`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddMedicalCondition
